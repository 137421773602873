import { Card } from "@aws-amplify/ui-react";
import React, { forwardRef } from "react";

export interface IRaisedCardProps extends React.ComponentProps<typeof Card> {
  thickness?: string;
}

const RaisedCard = forwardRef<HTMLDivElement, IRaisedCardProps>((props, ref) => {
  const { thickness = '4px', ...rest } = props;

  return (
    <Card
      ref={ref}
      style={{ outline: 'none', border: 'none' }}
      boxShadow={`0 -${thickness} inset rgba(0, 0, 0, 0.2), 0 2px 5px -2px rgba(0, 0, 0, .4)`}
      // boxShadow={`0 -${thickness} inset rgba(0, 0, 0, 0.2), 0px 2px 6px -3px rgba(0, 0, 0, 1)`} // experiment
      {...rest}
    />
  );
});

export default RaisedCard;