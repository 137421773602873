import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export default function useUrl() {
    const location = useLocation();
    const routePath = useMemo(() => location.pathname, [location.pathname]);
    const href = useMemo(() => window.location.href, [window.location.href]);
    const hash = useMemo(() => location.hash, [location.hash]);
    const root = useMemo(() => href.replace(routePath, ``), [routePath, href]);

    return { root, routePath, href, hash }
}