import { Grid, ScrollView, View } from "@aws-amplify/ui-react";
import React from "react";
import SideNavbar from "./SideNavbar";
import TopNavbar from "./TopNavbar";
import useMediaQuery from "../hooks/useMediaQuery";

export default function CreateQuizLayout({
  children,
  useAuthentication = true,
}: {
  children?: React.ReactNode;
  useAuthentication?: boolean;
}) {
  const isSmall = useMediaQuery("(max-width: 992px)");

  return (

    <>

      <ScrollView height={"100dvh"} minHeight={"100vh"}>
        <Grid
          height={"100dvh"}
          minHeight={"100vh"}
          columnGap="0"
          rowGap="0"
          templateColumns={"auto minmax(0, 1fr)"}
          templateRows={"auto minmax(0, 1fr)"}
          templateAreas={`
                "header header" 
                    "sidebar content"`}
        >
          <TopNavbar useAuthentication={useAuthentication} area={"header"} />

          <SideNavbar area={"sidebar"} hidden={isSmall} />

          <View backgroundColor={"#fbfbfb"} area={"content"}>
            {children}
          </View>
        </Grid>
      </ScrollView>


    </>


  );
}
