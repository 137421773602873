import React from "react";
import { Button } from "@aws-amplify/ui-react";
//import ExpandIcon from "../components/icons/ExpandIcon";
import QuitIcon from "../components/icons/QuitIcon";
import useModal from "../hooks/useModal";
import PreventExit from "../features/game/components/PreventExit";
import { useTranslationContext } from "../context/TranslationContextProvider";

export default function ExitButton({
  onExit,
}: {
  onExit: () => void | Promise<void>;
}) {
  const { translations } = useTranslationContext();

  const [ExitModal, setShowModal] = useModal(
    {
      title: translations.are_you_sure,
      ReactComponent: () => (
        <PreventExit
          onStay={() => {
            setShowModal(false);
          }}
          onLeave={() => {
            setShowModal(false);
            onExit?.();
          }}
        />
      ),
      hasExitButton: true,
    },
    []
  );

  return (
    <>
      <ExitModal />
      <Button
        size="large"
        variation={"link"}
        padding={"0"}
        color="white"
        onClick={() => setShowModal(true)}
      >
        <QuitIcon />
      </Button>
    </>
  );
}
