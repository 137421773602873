import { GraphQLQuery, generateClient } from "aws-amplify/api";
import { UpdateQuizInput, UpdateQuizMutation } from "../../../API";

export const updateQuiz_query = /* GraphQL */ `
  mutation UpdateQuiz(
    $input: UpdateQuizInput!
    $condition: ModelQuizConditionInput
  ) {
    updateQuiz(input: $input, condition: $condition) {
      id
    }
  }
`;
export async function updateQuiz(input: UpdateQuizInput) {
  const client = generateClient();

  await client.graphql<GraphQLQuery<UpdateQuizMutation>>({
    query: updateQuiz_query,
    variables: { input },
  });
}
