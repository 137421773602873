import React from "react";
import { Button, Link, Grid } from "@aws-amplify/ui-react";
import { toast } from "react-toastify";
import { FaCopy, FaEnvelope } from "react-icons/fa";
import { QuizContext } from "./QuizContextProvider";
import useUrl from "../../../hooks/useUrl";
import ShareToClassroomButton from "./ShareToClassroomButton";
import ShareToCanvasButton from "./ShareToCanvasButton";
import { FaXTwitter } from "react-icons/fa6";

export default function ShareQuizButtons({ quizId }: { quizId?: string }) {
  const { root } = useUrl();

  const copy = async () => {
    await navigator.clipboard.writeText(`${root}/game/${quizId}`);
    toast.success("Link copied! 👍");
  };

  const { quiz } = React.useContext(QuizContext);

  return (
    <Grid
      textAlign={"center"}
      templateColumns={{
        base: "1fr",
        small: "1fr 1fr",
        large: "1fr 1fr 1fr",
        xl: "1fr 1fr 1fr 1fr",
      }}
      padding={"large"}
      columnGap="1rem"
      rowGap="1rem"
    >
      <Link
        isExternal
        href={`https://twitter.com/intent/tweet?&ref_src=twsrc%5Etfw&text=I%20just%20created%20this%20gamified%20quiz%20in%20minutes%20using%20+@GibblyCo%20🤯%0D%0A%0D%0APlay%20it%20here!%20-%20www.gibbly.co/game/${quizId}%0A%0A&hashtags=QuizGenerator,TeachersWhoAI,TeachersofTwitter`}
      >
        <Button
          id={"raised-btn"}
          backgroundColor={"teal.60"}
          isFullWidth
          gap={"small"}
          color={"white"}
          variation={"primary"}
        >
          <FaXTwitter/> Post
        </Button>
      </Link>

      <Link
        isExternal
        href={`mailto:?subject=Check out this AI quiz generator!&body=Hey!%0D%0A%0DI just made this quiz in minutes with Gibbly's AI quiz generator. You should check it out!%0D%0A%0Dhttps://www.gibbly.co/game/${quizId}`}
      >
        <Button
          id={"raised-btn"}
          isFullWidth
          backgroundColor={"orange.60"}
          gap={"small"}
          color={"white"}
          variation={"primary"}
        >
          <FaEnvelope /> Email
        </Button>
      </Link>
      <Button
        id={"raised-btn"}
        backgroundColor={"purple.60"}
        whiteSpace={"nowrap"}
        gap={"small"}
        color={"white"}
        variation={"primary"}
        onClick={copy}
        data-attr="share-or-play"
        data-ph-capture-attribute-type={"game-link-quiz-edit"}
        data-ph-capture-attribute-quiz-id={quiz?.id}
        data-ph-capture-attribute-quiz-title={quiz?.title}
        data-ph-capture-attribute-quiz-creator={quiz?.creator}
        data-ph-capture-attribute-number-of-questions={
          quiz?.Questions?.items.length
        }
        data-ph-capture-attribute-quiz-privacy={quiz?.allowReadTo}
        data-ph-capture-attribute-quiz-language={quiz?.lang}
        data-ph-capture-attribute-quiz-created-at={quiz?.createdAt}
        data-ph-capture-attribute-quiz-updated-at={quiz?.updatedAt}
      >
        <FaCopy /> Game Link
      </Button>
      <ShareToClassroomButton />
      <ShareToCanvasButton />
    </Grid>
  );
}
