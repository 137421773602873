import RestAPI from "../../util/RestAPI";

export async function updateContactReferralCode(
  email: string,
  referralCode: string
) {
  try {
    await RestAPI.put(`/mailchimp/${email}/referralCode/${referralCode}`, {});
  } catch (e) {

    throw new Error("Failed to update mailchimp contact");
  }
}
