import { DynamoDBItem } from "../../types/DynamoDBTypes";
import RestAPI from "../../util/RestAPI";

export async function deleteDatabaseItem<T extends DynamoDBItem, K>(
  endpoint: string,
  keys: Pick<T, Extract<keyof T, keyof K>>,
): Promise<boolean> {
  const keyString = Object.values(keys).join("/");
  try {
    await RestAPI.del(`${endpoint}/object/${keyString}`);
    return true;
  } catch (err) {

    return false;
  }
}

// Usage:
// const isDeleted = await deleteDatabaseItem<StudentKey>("/students", { classId, studentId });
// const isDeleted = await deleteDatabaseItem<ClassroomKey>("/classrooms", { teacherId, id });
