import { Button, Flex, Loader, View } from "@aws-amplify/ui-react";
import * as React from "react";
import { TooltipView } from "../../../../components/TooltipView";
import { Slide } from "../../types/slideTypes";
import { useEffect, useRef } from "react";
import DeleteIcon from "../../../../components/icons/DeleteIcon";
import AddIcon from "../../../../components/icons/AddIcon";
import { Draggable } from "../../../../components/Draggable";
import { LessonContext } from "../LessonContextProvider";
import { SlidesContext } from "../SlidesContextProvider";
import SlideView from "./SlideView";
import { v4 } from "uuid";
import { deepClone } from "../../../../util/deepClone";
import { FaCopy } from "react-icons/fa";
import { getTemplatedElements, SlideElementsTemplate } from "../../util";
import { Placeholder } from "react-bootstrap";
import { useSlideQuery } from "../../hooks/useSlideQuery";
import { useDeleteSlide } from "../../hooks/useDeleteSlide";
import { useAddSlide } from "../../hooks/useAddSlide";

export interface ITimelineItemProps {
  slideId: string;
  i: number;
  setFocusedSlide?: (slide: Slide | undefined) => void;
  onDragStart?: (e: React.DragEvent<HTMLDivElement>) => void | Promise<void>;
  onDrag?: (e: React.DragEvent<HTMLDivElement>) => void | Promise<void>;
  onDragEnd?: (e: React.DragEvent<HTMLDivElement>) => void | Promise<void>;
}

export default function TimelineSlide(props: ITimelineItemProps) {
  const { slideId, i, setFocusedSlide, onDrag, onDragStart, onDragEnd } = props;

  const { isOwner, lesson } = React.useContext(LessonContext);

  const addSlideMutation = useAddSlide();

  const { slideIds, slideId: selectedSlideId } =
    React.useContext(SlidesContext);

  const { data: slide, isStale } = useSlideQuery({
    lessonId: lesson.id,
    slideId,
  });

  const isSelected = React.useMemo(
    () => selectedSlideId === slideId,
    [selectedSlideId, slideId]
  );

  const [showTooltip, setShowTooltip] = React.useState(false);

  const componentRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      componentRef.current &&
      !componentRef.current.contains(event.target as Node) &&
      tooltipRef.current &&
      !tooltipRef.current.contains(event.target as Node)
    ) {
      setShowTooltip(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    document.addEventListener("contextmenu", handleClickOutside);

    // Cleanup: remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.addEventListener("contextmenu", handleClickOutside);
    };
  }, []);

  // const addSlideMutation = useMutation({
  //   mutationKey: ["Add Slide", slide?.id],
  //   mutationFn: async ({
  //     slide,
  //   }: {
  //     slide: Omit<Slide, "id" | "createdAt" | "updatedAt">;
  //   }) => {
  //     await createSlide(slide);
  //   },
  //   onSettled: () => {
  //     queryClient.invalidateQueries(["slides"]);
  //   },
  // });
  const removeSlideMutation = useDeleteSlide({
    lessonId: lesson.id,
    slideId,
  });

  if (!slide) return <Placeholder />;

  if (isOwner && !slide.temp)
    return (
      <TooltipView
        tooltipProps={{ id: "slide-tooltip-" + slide?.id }}
        show={showTooltip}
        tooltipChildren={
          <Flex
            ref={tooltipRef}
            direction={"column"}
            gap={"xxs"}
            padding={"xxs"}
          >
            <Button
              gap={"small"}
              size="small"
              id="raised-btn"
              padding={"xxs"}
              backgroundColor={"#1a90ff"}
              isLoading={addSlideMutation.isLoading}
              color={"white"}
              onClick={() => {
                if (!slide) return;
                const slideIndex = slideIds.findIndex((id) => id === slide.id);
                addSlideMutation.mutate({
                  slide: {
                    lessonId: lesson.id,
                    elements: getTemplatedElements(
                      SlideElementsTemplate.ImageRight,
                      {
                        headerText: "",
                        bodyText: "",
                        imagePath: "",
                      }
                    ),
                    background: slide.background,
                  },
                  index: slideIndex + 1,
                });
              }}
            >
              <AddIcon />
              New Slide
            </Button>
            <Button
              gap={"small"}
              size="small"
              id="raised-btn"
              backgroundColor={"#1a90ff"}
              variation="menu"
              padding={"xxs"}
              color={"white"}
              onClick={() => {
                if (!slide) return;
                const copiedElements = slide.elements.map((element) => {
                  return { ...deepClone(element), id: v4() };
                });
                const slideIndex = slideIds.findIndex((id) => id === slide.id);

                addSlideMutation.mutate({
                  slide: {
                    lessonId: lesson.id,
                    elements: copiedElements,
                    background: slide.background,
                  },
                  index: slideIndex + 1,
                });
              }}
            >
              <FaCopy />
              Duplicate
            </Button>
            <Button
              gap={"small"}
              size="small"
              id="raised-btn"
              backgroundColor={"#de3721"}
              variation="destructive"
              padding={"xxs"}
              color={"white"}
              onClick={() => {
                if (!slide) return;
                removeSlideMutation.mutate();
              }}
            >
              <DeleteIcon />
              Delete
            </Button>
          </Flex>
        }
      >
        <View
          position={"relative"}
          padding={"1px"}
          className={"timeline-item " + (isSelected ? "selected" : "")}
          tabIndex={i + 1}
          ref={componentRef}
          onContextMenu={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.preventDefault();
            setShowTooltip(true);
          }}
          onClick={() => {
            setShowTooltip(false);
          }}
          onFocus={() => setFocusedSlide?.(slide)}
          onBlur={() => {
            setShowTooltip(false);
            setFocusedSlide?.(undefined);
          }}
          style={{
            aspectRatio: "16/9",
          }}
        >
          {isStale && (
            <Loader
              bottom={"xxs"}
              right={"xxs"}
              position={"absolute"}
              size="small"
              style={{ zIndex: 5 }}
            />
          )}
          <Draggable
            height={"100%"}
            dragIdKey={"drag-slide-key"}
            dragIdValue={slide.id ?? ""}
            onDragStart={async (e) => {
              await onDragStart?.(e);
            }}
            onDragEnd={async (e) => {
              await onDragEnd?.(e);
            }}
            onDrag={onDrag}
          >
            <SlideView
              isStatic
              isClickable={false}
              slide={slide}
              idPrefix={"timeline-"}
            />
          </Draggable>
        </View>
      </TooltipView>
    );
  else
    return (
      <View
        opacity={slide.temp ? 0.5 : 1}
        height={"100%"}
        className={"timeline-item " + (isSelected ? "selected" : "")}
        tabIndex={i + 1}
        ref={componentRef}
        onContextMenu={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          e.preventDefault();
          setShowTooltip(true);
        }}
        onClick={() => {
          setShowTooltip(false);
        }}
        onFocus={() => setFocusedSlide?.(slide)}
        onBlur={() => {
          setShowTooltip(false);
          setFocusedSlide?.(undefined);
        }}
      >
        <SlideView
          isStatic
          isClickable={false}
          slide={slide}
          idPrefix={"timeline-"}
        />
      </View>
    );
}
