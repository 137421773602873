import { ByLessonIdKey, Lesson } from "../types/lessonTypes";
import { getDatabaseItem } from "../../../services/database/getDatabaseItem";

export async function getLessonById(id: string) {
  const lesson = await getDatabaseItem<Lesson, ByLessonIdKey>("/lessons", {
    id,
  });

  return lesson;
}
