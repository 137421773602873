import { Grid, Radio, Text } from "@aws-amplify/ui-react";
import React, { useMemo, useState } from "react";
import { Question, QuestionType } from "../../../../API";
import useUpdateEffect from "../../../../hooks/useUpdateEffect";
import { MultiSelectButtonGroup } from "./MultiSelectButtonGroup";
import { TypedAnswerView } from "./TypedAnswerView";
import RaisedButton from "../../../../components/RaisedButton";
import { GameData } from "../../Types/GameTypes";

interface AnswerSelectProps {
  question: Question | null | undefined;
  gameData: GameData;
  onChange?: (answer: number[] | string) => void;
  onSubmit?: (answer: number[] | string) => Promise<void>;
  isMyTurn?: boolean;
}

export enum AnswerColours {
  "orange.60",
  "teal.60",
  "green.60",
  "red.60",
}

export default function AnswerSelect({
  question,
  gameData,
  onChange,
  isMyTurn,
}: AnswerSelectProps) {
  const [answer, setAnswer] = useState<number[] | string>([]);

  useUpdateEffect(() => {
    switch (question?.type) {
      case QuestionType.TYPED_ANSWER:
        setAnswer("");
        if (onChange) onChange("");
        break;
      default:
        setAnswer([]);
        if (onChange) onChange([]);
    }
  }, [question]);

  const isAnswered = useMemo(() => {
    return gameData.questionIndex + 1 === gameData.answerResults.length;
  }, [gameData.questionIndex, gameData.answerResults]);

  useUpdateEffect(() => {
    if (onChange) onChange(answer);
  }, [answer]);

  if (question?.type === QuestionType.TYPED_ANSWER)
    return (
      <TypedAnswerView
        gameData={gameData}
        setAnswer={setAnswer}
        isMyTurn={isMyTurn}
      />
    );
  else if (question?.type === QuestionType.MULTIPLE_SELECT)
    return (
      <MultiSelectButtonGroup
        question={question}
        answer={gameData.currentAnswer}
        setAnswer={setAnswer}
        isAnswered={isAnswered}
        isMyTurn={isMyTurn}
      />
    );
  else
    return (
      <Grid
        flex={1}
        templateColumns={"1fr 1fr"}
        templateRows={"1fr 1fr"}
        height={"100%"}
        width={"100%"}
        gap={"xs"}
      >
        {question?.answers.map((optionText, i) => (
          <RaisedButton
            justifyContent={"center"}
            textAlign={"center"}
            alignItems={"center"}
            size="large"
            borderRadius={"xxxs"}
            fontSize={{
              base: "xs",
              medium: "medium",
              xxl: "large",
            }}
            padding="xs"
            key={i}
            isDisabled={isAnswered || isMyTurn === false}
            color={"white"}
            backgroundColor={
              !gameData.currentAnswer || !isAnswered
                ? AnswerColours[i]
                : isAnswered && question.correctIndices.includes(i)
                ? "green.60"
                : "red.80"
            }
            border={
              (answer as number[]).includes(i) ? `3px solid white` : "none"
            }
            onClick={() => {
              let selection: number[] = [i];
              if ((gameData.currentAnswer as number[]).includes(i)) {
                selection = [];
              }
              setAnswer(selection);
            }}
          >
            <Grid
              templateColumns={"1fr 6fr"}
              width={"100%"}
              columnGap={"xxs"}
              height={"100%"}
              alignContent={"center"}
              alignItems={"center"}
            >
              <Radio
                className="answer-option"
                name={"option " + i}
                value={"option " + i}
                column={1}
                // size="large"
                checked={(answer as number[]).includes(i)}
                isDisabled={isAnswered || (isMyTurn !== undefined && !isMyTurn)}
                onClick={() => {
                  setAnswer((prev) => {
                    if (prev === undefined) return [i];
                    if ((prev as number[]).includes(i)) {
                      //remove all occurences of i
                      if (onChange)
                        onChange(
                          (prev as number[]).filter((item) => item !== i)
                        );
                      return (prev as number[]).filter((item) => item !== i);
                    } else {
                      if (onChange) onChange([...(prev as number[]), i]);
                      return [...(prev as number[]), i];
                    }
                  });
                }}
              />
              <Text column={2} color={"white"}>
                {optionText}
              </Text>
            </Grid>
          </RaisedButton>
        ))}
      </Grid>
    );
}
