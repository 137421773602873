import React from "react";
import { Route, Routes } from "react-router-dom";

import useUrl from "../hooks/useUrl";

import QuizView from "../features/quiz/components/QuizLayout";
import { QuizEditor } from "../features/quiz";
import CreateQuizLayout from "../layouts/CreateQuizLayout";

export default function QuizRoutes() {
  const url = useUrl();

  return (
    <>
      <CreateQuizLayout>
        <Routes>
          {/* <Route index element={<CreateHome />} /> */}
          <Route path=":id" element={<QuizView key={url.routePath} ><QuizEditor /></QuizView>} />
        </Routes>
      </CreateQuizLayout>
    </>
  );
}
