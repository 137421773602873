import { Card, TextAreaField, Text, Flex, Radio } from "@aws-amplify/ui-react";
import React from "react";
import OptionCardLabel from "./OptionCardLabel";
import { CreateQuestionInput, Question, QuestionType } from "../../../API";
import { useTranslationContext } from "../../../context/TranslationContextProvider";

export default function OptionCard({
  correctIndices,
  index,
  updateAnswers,
  updateCorrectIndices,
  question
}: {
  correctIndices: (number | null)[] | null | undefined;
  index: number;
  updateAnswers: (index: number, value: string) => void;
  updateCorrectIndices: (index: number, value: boolean) => void;
  question: Question | CreateQuestionInput | null | undefined;

}) {
  const backgroundColors = ["#f57f00", "#1a90ff", "#6ed52a", "#de3721"];

  const { translations } = useTranslationContext();


  if (!question) return <></>;

  return (
    <Card
      id="raised-btn"
      padding={"xs"}
      height={"100%"}
      backgroundColor={backgroundColors[index]}
      borderRadius={"medium"}
    >
      {question.type !== QuestionType.TRUE_OR_FALSE ? (
        <TextAreaField
          style={{ color: "white", textAlign: "center", fontWeight: "bold" }}
          onChange={(e) => updateAnswers(index, e.target.value)}
          variation={"quiet"}
          size="small"
          height={"100%"}
          rows={1}
          value={question?.answers?.[index] as string}
          label={
            question?.answers?.[index] && (
              <OptionCardLabel
                questionType={question.type}
                index={index}
                correctIndices={correctIndices}
                updateCorrectIndices={updateCorrectIndices}
              />
            )
          }
        />
      ) : (
        <Flex
          height={"100%"}
          color="white"
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Radio
            value={""}
            checked={question?.correctIndices?.includes(index)}
            style={{ color: "white" }}
            onChange={(e) => updateCorrectIndices(index, e.target.checked)}
          />
          <Text color={"white"}>
            <b>{index === 0 ? translations.true : translations.false}</b>
          </Text>
        </Flex>
      )}
    </Card>
  );
}
