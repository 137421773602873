import { Button, Flex, Grid } from "@aws-amplify/ui-react";
import React, { useEffect } from "react";
import PlayIcon from "../../../../components/icons/PlayIcon";
import { GameData } from "../../Types/GameTypes";
import ScoreCard from "./ScoreCard";
import { GameEventProps } from "../../Types/GameEventProps";
import ShareIcon from "../../../../components/icons/ShareIcon";
import { useCanvasConffeti } from "../../../../hooks/useCanvasConfetti";
import { useTranslationContext } from "../../../../context/TranslationContextProvider";

interface IGameCompleteViewProps extends GameEventProps {
  gameData: GameData;
  completeGameButtonText?: string;
}

export default function GameCompleteView({
  gameData,
  onPlayAgain,
  onShare,
  completeGameButtonText,
}: IGameCompleteViewProps) {
  const { startRain, fireCannon } = useCanvasConffeti();

  useEffect(() => {
    fireCannon("left");
    fireCannon("right");
    startRain();
  }, []);

  const { translations } = useTranslationContext();

  return (
    <>
      <Grid templateRows={"auto auto 50px"}>
        <Flex row={1} alignContent={"center"} justifyContent={"center"}>
          <ScoreCard gameData={gameData} />
        </Flex>

        <Flex row={2} justifyContent={"center"} alignItems={"center"}>
          {onPlayAgain && (
            <Button
              id={"raised-btn"}
              height={"60px"}
              backgroundColor={"teal.60"}
              fontSize={"large"}
              variation="primary"
              gap={"small"}
              onClick={() => onPlayAgain()}
            >
              {completeGameButtonText || translations.play_again}
              <PlayIcon />
            </Button>
          )}
          {onShare && (
            <Button
              id={"raised-btn"}
              backgroundColor={"teal.60"}
              // width={"80px"}
              height={"60px"}
              fontSize={"large"}
              variation="primary"
              gap={"small"}
              // onClick={() =>
              //   CopyToClipboard(root + `/game/${quizId}`, "Link copied! 👍")
              // }
              onClick={() => {

                onShare();
              }}
            >
              <ShareIcon />
            </Button>
          )}
        </Flex>
      </Grid>
    </>
  );
}
