import { Card, Flex, Grid, Loader, Text } from "@aws-amplify/ui-react";
import * as React from "react";

import { RankedTeam } from "../../Types/TeamTypes";
import { Player } from "../../Types/GameTypes_dep";

import { PlayerAvatar } from "./PlayerAvatar";
import { addOrdinalSuffix } from "../../../../util/addOrdinalSuffix";
import RaisedCard from "../../../../components/RaisedCard";
import useLobbyContext from "../../hooks/useLobbyContext";
import Medal from "../../../../components/MedalGold";
import { useTranslationContext } from "../../../../context/TranslationContextProvider";

export interface ITeamCardLeaderboardProps {
  team: RankedTeam;
  isHost: boolean | undefined;
  players: Player[];
}

export function TeamCardLeaderboard(props: ITeamCardLeaderboardProps) {
  const { team, players } = props;

  const { lobby } = useLobbyContext();
  const { translations } = useTranslationContext();

  const state = React.useMemo(() => {
    const nQs = lobby?.quiz.Questions?.items.length ?? 0;

    const answeredQs = team?.gameData.answerResults.length ?? 0;

    if (answeredQs >= nQs) {
      return "Finished";
    } else {
      return `${answeredQs}/${nQs}`;
    }
  }, [team]);

  const TeamInfo = () => {
    const rank = team.rank;
    return (
      <Flex
        column={"1"}
        row={"1"}
        width={"100%"}
        justifyContent={"start"}
        alignSelf={"start"}
        alignItems={"center"}
        gap={"xs"}
      >
        {rank <= 3 ? (
          <Medal
            type={rank === 1 ? "gold" : rank === 2 ? "silver" : "bronze"}
            height={"60px"}
          />
        ) : (
          <Flex
            width={"40px"}
            height={"30px"}
            backgroundColor={"#FFFFFF88"}
            borderRadius={"small 25px 25px 25px"}
            justifyContent={"center"}
            alignItems={"center"}
            boxShadow={"0px 2px 8px -2px #000000AA"}
          >
            <Text
              fontSize={{ base: "xs", medium: "smaller", large: "small" }}
              fontWeight={"bold"}
            >
              <i>{addOrdinalSuffix(team.rank)}</i>
            </Text>
          </Flex>
        )}
        <RaisedCard
          backgroundColor={"white"}
          textAlign={"center"}
          fontWeight={"bold"}
          padding={"xxs xs xxs xs"}
          whiteSpace={"nowrap"}
          fontSize={{ base: "xs", medium: "smaller", large: "large" }}
        >
          {translations.team} {team.number}
        </RaisedCard>
      </Flex>
    );
  };

  return (
    <Card
      id="leaderboardCard"
      width={"90%"}
      maxWidth={"1200px"}
      backgroundColor={"transparent"}
      borderRadius={"small"}
      padding={"xxs"}
      // paddingLeft={"large"}
      // paddingRight={"large+"}
    >
      <Grid
        width={"100%"}
        height={"100%"}
        templateColumns={{ small: "1fr 1fr", medium: "auto 1fr auto" }}
        templateRows={{ small: "auto auto", medium: "auto" }}
        gap={{ base: "xs", medium: "large" }}
      >
        <TeamInfo />
        <Flex
          column={{ small: "1/-1", medium: "2" }}
          row={{ small: "2", medium: "1" }}
          padding={{ base: 0, medium: "xxs", large: "xs" }}
          gap={{ base: 0, medium: "small" }}
          justifyContent={"start"}
          wrap={"wrap"}
        >
          {players !== undefined ? (
            players.map((player) => (
              <PlayerAvatar
                key={player.userId}
                player={player}
                size={{ base: "35px", medium: "40px", large: "50px" }}
              />
            ))
          ) : (
            <Loader size={"small"} />
          )}
        </Flex>
        <Flex
          column={{ small: "2", medium: "3" }}
          row={"1"}
          justifyContent={"end"}
          alignSelf={"start"}
          alignItems={"start"}
          direction={"row"}
          gap={"xxs"}
        >
          <Card
            backgroundColor={"rgba(0,0,0,0.5)"}
            padding={"xxxs small xxxs small"}
          >
            <Text
              width={"100%"}
              color={"white"}
              textAlign={"center"}
              fontWeight={"semibold"}
              fontSize={{ base: "xs", medium: "small", large: "medium" }}
            >
              {state}
            </Text>
          </Card>
          <Card
            backgroundColor={"rgba(0,0,0,0.5)"}
            padding={"xxs medium xxs medium"}
          >
            <Text
              textAlign={"center"}
              fontWeight={"black"}
              color={"white"}
              fontSize={{ base: "medium", medium: "large", large: "xl" }}
            >
              {team.mark * 100} pts
            </Text>
          </Card>
        </Flex>
      </Grid>
    </Card>
  );
}
