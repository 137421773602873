import RestAPI from "./RestAPI";

export default async function validateUsername(
  username: string,
  {
    onError,
    onSuccess,
  }: { onError: (error: Error) => void; onSuccess: () => Promise<any> }
): Promise<void> {
  // const usernameRegex = /^(?=[a-zA-Z0-9.]{4,20}$)[a-zA-Z0-9]+\.?[a-zA-Z0-9]*$/;
  const usernameRegex =
    /^(?!.*\.{2})(?!.*\.$)(?!^\.$)[a-zA-Z0-9]{3,19}(\.[a-zA-Z0-9]{1,19})?$/;
  if (!usernameRegex.test(username)) {
    onError(
      new Error(
        "Username must be between 4 and 20 alphanumeric characters with no more than 1 period, and cannot start or end with a period"
      )
    );
    return;
  }

  // Create username lowercase
  const username_lc = username.toLowerCase();

  // Check if username hash is taken
  const result = (await RestAPI.get(
    `/users/username/lowercase/${username_lc}`
  ).then((res) => res.json())) as any as any[];

  if (result.length > 0) {
    onError(new Error("Username is taken."));
    return;
  }
  await onSuccess();
}
