// These are custom mutations

export const joinSessionAsPlayer = `
mutation JoinGameAsPlayer($nickname: String!, $sessionID: ID!) {
  createPlayer(input: {nickname: $nickname, sessionID: $sessionID}) {
    id
    sessionID
    nickname
  }
}
`;

export const updateSessionPin = `
mutation uUpdateGamePin($id: ID!, $pin: String!) {
  updateSession(input: {id: $id, pin: $pin}) {
    pin
  }
}
`;

export const initializeSession = `
mutation InitializeSession($id: ID!, $pin: String!) {
  createGame(input: {sessionID: $id, state: LOBBY}) {
    id
    state
  }
  updateSession(input: {id: $id, pin: $pin, state: LOBBY}) {
    id
    pin
    state
    Games {
      items {
        Players {
          items {
            id
            nickname
          }
        }
      }
    }
  }  
}
`;

export const addPlayerToGame = `
mutation AddPlayerToGame($id: ID!, $gameID: ID!) {
  updatePlayer(input: {id: $id, gameID: $gameID}) {
    id
    gameID
  }
}
`;

export const startPlayingSession = `
mutation StartPlayingSession($id: ID!) {
  updateSession(input: {id: $id, state: PLAYING}) {
    id
    state
    Games {
      items {
        id
        state
      }
    }
  }
}
`;

export const startPlayingGame = `
mutation StartPlayingGame($id: ID!) {
  updateGame(input: {id: $id, state: PLAYING}) {
    id
    state
  }
}
`;

export const createPublicQuiz = /* GraphQL */ `
  mutation CreatNewQuiz(
    $title: String!
    $description: String!
    $tags: [String] = []
    $image: S3ObjectProtectedInput
  ) {
    createQuiz(
      input: {
        title: $title
        description: $description
        tags: $tags
        allowReadTo: "public"
        image: $image
      }
    ) {
      id
      creator
      image {
        key
        identityId
        alt
      }
      allowReadTo
      title
      description
      tags
      Questions {
        items {
          id
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const createPrivateQuiz = /* GraphQL */ `
  mutation CreatNewQuiz(
    $title: String!
    $description: String!
    $tags: [String] = []
    $image: S3ObjectProtectedInput
    $lang: String!
  ) {
    createQuiz(
      input: {
        title: $title
        description: $description
        tags: $tags
        allowReadTo: "private"
        image: $image
        lang: $lang
      }
    ) {
      id
      creator
      lang
      image {
        key
        identityId
        alt
      }
      allowReadTo
      title
      description
      tags
      Questions {
        items {
          id
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const updateQuizQuestionSortIndex = /* GraphQL */ `
  mutation UpdateQuizQuestionSortIndex($id: ID!, $sortIndex: Int!) {
    updateQuizQuestion(input: { id: $id, sortIndex: $sortIndex }) {
      sortIndex
    }
  }
`;
