import RestAPI from "../../../../util/RestAPI";
import { AssignmentResult, PostAssignmentResultInput } from "../../types";

export async function createAssignmentResult(
  assignmentId: string,
  studentId: string
) {
  const input: PostAssignmentResultInput = {
    assignmentId,
    studentId,
  };

  try {
    const classData: AssignmentResult = await RestAPI.post(
      "/assignmentResults",
      {
        body: input,
      }
    ).then((response) => response.json() as any as AssignmentResult);
    return classData;
  } catch (err) {

    return;
  }
}
