import { Flex } from "@aws-amplify/ui-react";
import * as React from "react";
import RaisedButton from "../components/RaisedButton";
import QuitIcon from "../components/icons/QuitIcon";
import RaisedCard from "../components/RaisedCard";
import { signOut } from "aws-amplify/auth";

export interface IButtonCardProps {}

export default function ButtonCard(props: IButtonCardProps) {
  const {} = props;

  // const { signOut } = useAuthenticator((context) => {
  //   return [context.user];
  // });

  return (
    <RaisedCard>
      {/* <Heading id="sectionTitle">
      Account Information
    </Heading> */}
      <Flex>
        <RaisedButton
          variation="destructive"
          gap={"xs"}
          fontSize={"smaller"}
          size="small"
          onClick={async () => {
            try {
              await signOut();
            } catch (error) {

            }
          }}
        >
          <QuitIcon />
          Sign Out
        </RaisedButton>
      </Flex>
    </RaisedCard>
  );
}
