import { queryDatabaseItems } from "../../../services/database/queryDatabaseItems";
import { LobbyConnection } from "../Types/LobbyConnectionTypes";

export async function listLobbyConnections(lobbyPin: string) {
  try {
    const result = await queryDatabaseItems<LobbyConnection>(
      "/lobbyConnections",
      lobbyPin
    );

    return result;
  } catch (err) {
    console.error(err);
    throw err;
  }
}
