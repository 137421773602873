import React, { useMemo, useState } from "react";
import {
  Button,
  Flex,
  Heading,
  Text,
  TextAreaField,
  SwitchField,
  View,
} from "@aws-amplify/ui-react";

import useModal from "../../../hooks/useModal";
import ImageGallery from "./ImageGallery";
import { S3ObjectProtected } from "../../../API";

import useClickOutsideDetection from "../../../hooks/useClickOutsideDetection";
import { QuizContext } from "./QuizContextProvider";
import { FaCheckCircle, FaCopy } from "react-icons/fa";
import { FaPen } from "react-icons/fa6";
import RaisedButton from "../../../components/RaisedButton";
import { ImageComponent } from "../../../components/ImageComponent";
import LanguageSelect from "../../../components/LanguageSelect";
import { duplicateQuiz } from "../../../services/quiz";
import { MdTranslate } from "react-icons/md";
import { TooltipView } from "../../../components/TooltipView";
import translateQuiz from "../services/translateQuiz";
import { languageOptions } from "../../translations";
import { useMutation } from "@tanstack/react-query";
import { useUserContext } from "../../../context/UserContextProvider";
import { PremiumFeatureBadge } from "../../premium/components";
import TranslateForm from "../../translations/components/TranslateForm";

//const quizthumbnail = require("../../../assets/default-quiz-img.png");

export default function QuizDetails() {
  const { isCreator, quiz: quiz, updateQuiz } = React.useContext(QuizContext);

  const { isPremium } = useUserContext();

  const { mutateAsync: translateMutation } = useMutation({
    mutationFn: async ({
      quizId,
      locale,
    }: {
      quizId: string;
      locale: keyof typeof languageOptions;
    }) => {
      if (isPremium()) {
        return await translateQuiz(quizId, locale);
      } else {
        setShowTranslateMenu(false);
        throw new Error("You need to be a premium user to translate quizzes.");
      }
    },
    onError: (error) => {
      console.error("Error translating quiz", error);
    },
    onSuccess: (data) => {
      const newQuiz = data;
      // open new quiz in new tab
      window.open(`/quiz/${newQuiz.id}`, "_blank");
    },
  });

  // Editing states
  const [titleEdit, setTitleEdit] = useState(quiz?.title);
  const [descriptionEdit, setDescriptionEdit] = useState(quiz?.description);

  const [editing, setEditing] = useState(false);
  const [duping, setDuping] = useState(false);

  const componentRef = useClickOutsideDetection(updateDetails(), [editing]);

  const [showTranslateMenu, setShowTranslateMenu] = useState(false);

  const [ImageGalleryModal, setShowImageGalleryModal] = useModal(
    {
      size: "lg",
      ReactComponent: () => (
        <ImageGallery
          onUpload={async (image) => {
            updateQuiz({ image: image as S3ObjectProtected });
            setShowImageGalleryModal(false);
          }}
          text={quiz?.title}
        />
      ),
    },
    [quiz?.title]
  );

  const isPublic = useMemo(
    () => quiz?.allowReadTo === "public",
    [quiz?.allowReadTo]
  );

  if (!quiz) return null;

  return (
    <>
      <Flex direction={"column"} ref={componentRef}>
        <Flex height={"150px"} justifyContent={"center"}>
          <ImageComponent
            alt={undefined}
            // width={"100%"}
            aspectRatio="16/9"
            image={quiz?.image}
            updateImage={async ({ image }) => {
              const { identityId, key, alt } = image;
              updateQuiz({ image: { identityId, key, alt } });
            }}
            autoPickPrompt={quiz?.title ?? ""}
            canEdit={isCreator}
          />
        </Flex>

        <Flex direction={"column"} gap={"small"}>
          <View padding="0">
            {editing ? (
              <TextAreaField
                flex={1}
                variation="quiet"
                rows={1}
                // size="large"
                resize="vertical"
                fontSize={"medium"}
                padding={"0"}
                margin={"0"}
                fontWeight={"bold"}
                name="title"
                placeholder="Enter a title"
                value={titleEdit}
                onChange={
                  (e) => setTitleEdit(e.target.value)
                  // updateQuiz({ title: e.target.value })
                }
                label={undefined}
              />
            ) : (
              <Heading fontSize={"medium"} paddingTop={"xxxs"} level={5}>
                {quiz?.title}
              </Heading>
            )}
          </View>

          <View>
            {editing ? (
              <TextAreaField
                flex={1}
                name="description"
                variation="quiet"
                padding={"0"}
                resize="vertical"
                margin={"0"}
                placeholder={"Enter a description"}
                rows={1}
                value={descriptionEdit}
                onChange={(e) => {
                  setDescriptionEdit(e.target.value);
                }}
                label={undefined}
              />
            ) : (
              <Text>{quiz?.description}</Text>
            )}
          </View>
          {isCreator ? (
            <LanguageSelect
              labelHidden
              value={quiz?.lang}
              disableList={[]}
              onChange={(lang) => {
                updateQuiz({ lang });
              }}
            ></LanguageSelect>
          ) : null}
        </Flex>

        <Flex direction={"row"} justifyContent={"space-between"}>
          {isCreator ? (
            <SwitchField
              label={isPublic ? "Public" : "Private"}
              labelPosition="end"
              isChecked={isPublic}
              onChange={(e) => {
                //quizPrivacyUpdateMutation.mutate(e.target.checked);
                const allowReadTo = e.target.checked ? "public" : "private";
                updateQuiz({ allowReadTo });
              }}
            />
          ) : null}
          <Flex gap={"xs"}>
            <TooltipView
              show={showTranslateMenu}
              tooltipChildren={
                <TranslateForm
                  exitFn={() => setShowTranslateMenu(false)}
                  translateFn={async (locale) => {
                    await translateMutation({
                      quizId: quiz.id,
                      locale: locale,
                    });
                  }}
                />
                // <Flex
                //   direction={"column"}
                //   gap={"xs"}
                //   // width={"250px"}

                //   padding={"small"}
                // >
                //   <Flex justifyContent={"space-between"} alignItems={"center"}>
                //     <Text fontWeight={"bold"}>Translate to:</Text>
                //     <Button
                //       variation="link"
                //       size="small"
                //       border={"none"}
                //       backgroundColor={"transparent"}
                //       onClick={() => setShowTranslateMenu(false)}
                //     >
                //       <FaX />
                //     </Button>
                //   </Flex>

                //   <Divider />

                //   <Flex direction={"column"} gap={"xs"}>
                //     <SelectField
                //       id="translate-language"
                //       name="translate-language"
                //       size="small"
                //       label="Translate to"
                //       value={translateLanguage}
                //       onChange={(e) => {
                //         setTranslateLanguage(
                //           e.target.value as keyof typeof languageOptions
                //         );
                //       }}
                //       labelHidden
                //     >
                //       <option disabled value={""}>
                //         Choose a language
                //       </option>
                //       {Object.entries(languageOptions).map(([value, label]) => (
                //         <option key={value} value={value}>
                //           {label}
                //         </option>
                //       ))}
                //     </SelectField>
                //   </Flex>
                //   <Divider />
                //   <Button
                //     variation="primary"
                //     gap={"xs"}
                //     size="small"
                //     isLoading={isTranslating}
                //     isDisabled={!translateLanguage}
                //     loadingText="Translating..."
                //     onClick={async () => {
                //       if (!translateLanguage) return;
                //       await translateMutation({
                //         quizId: quiz.id,
                //         locale: translateLanguage,
                //       });
                //     }}
                //   >
                //     <MdTranslate fontWeight={""} />
                //     Translate <PremiumFeatureBadge />
                //   </Button>
                // </Flex>
              }
            >
              <RaisedButton
                tooltip={
                  <Text>
                    Translate quiz <PremiumFeatureBadge />
                  </Text>
                }
                fontSize={"medium"}
                backgroundColor={"white"}
                textAlign={"center"}
                color={"black"}
                border={"none"}
                onClick={async () => {
                  setShowTranslateMenu(true);
                }}
              >
                <MdTranslate />
              </RaisedButton>
            </TooltipView>
            <RaisedButton
              tooltip={"Copy quiz"}
              fontSize={"medium"}
              backgroundColor={"white"}
              textAlign={"center"}
              color={"black"}
              border={"none"}
              isLoading={duping}
              onClick={async () => {
                setDuping(true);
                const newQuiz = await duplicateQuiz(quiz.id);
                // open new quiz in new tab
                window.open(`/quiz/${newQuiz.id}`, "_blank");

                setDuping(false);
              }}
            >
              <FaCopy />
            </RaisedButton>
            {isCreator ? (
              !editing ? (
                <RaisedButton
                  tooltip={"Edit details"}
                  variation={"primary"}
                  backgroundColor={"teal.40"}
                  // color="white"
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditing(true);
                  }}
                >
                  <FaPen />
                </RaisedButton>
              ) : (
                <Button
                  variation={"primary"}
                  id="raised-btn"
                  backgroundColor={"green.60"}
                  onClick={() => {
                    updateQuiz({
                      title: titleEdit,
                      description: descriptionEdit,
                    });
                    updateQuiz({});
                    setEditing(false);
                  }}
                >
                  <FaCheckCircle />
                </Button>
              )
            ) : null}
          </Flex>
        </Flex>
      </Flex>
      <ImageGalleryModal />
    </>
  );

  function updateDetails(): () => void | Promise<void> {
    return () => {
      if (editing) {
        updateQuiz({ title: titleEdit, description: descriptionEdit });
        updateQuiz({});
        setEditing(false);
      }
    };
  }
}
