import RestAPI from "../../../util/RestAPI";

export async function uploadImageURL(url: string, fileName: string) {
  return await RestAPI.post("/s3/protected/image", {
    body: {
      fileName,
      url,
    },
  }).then((res) => res.json());
}
