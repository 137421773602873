import { invokeLambdaFunction } from "../awsFacade";
import { getCurrentEnvironment } from "../getCurrentEnvironment";

export async function getS3ShortUrl(key: string, bucket?: string) {
  const env = await getCurrentEnvironment();

  const bucketName =
    bucket || env === "prod"
      ? "media-bucket112825-prod"
      : "media-bucket105725-dev";

  const { body: url } = await invokeLambdaFunction("GetS3URLShorter", {
    body: JSON.stringify({
      key,
      bucket: bucketName,
    }),
  });



  return url as string;
}
