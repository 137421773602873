import { Card, Flex, Grid, Text, View } from "@aws-amplify/ui-react";
import React from "react";
import { PlayerAvatar } from "./PlayerAvatar";
import RaisedButton from "../../../../components/RaisedButton";
import useModal from "../../../../hooks/useModal";
import { TeamSelect } from "./TeamSelect";
import { toast } from "react-toastify";

import useLobbyContext from "../../hooks/useLobbyContext";
import RaisedCard from "../../../../components/RaisedCard";
import AudioController from "../../../../layouts/AudioController";
import LobbyHeaderButtons from "./LobbyHeaderButtons";
import { useTranslationContext } from "../../../../context/TranslationContextProvider";
import { useVolumeContext } from "../../../../context";

// import lobbyMusic from "../../../../assets/audio/Music/lobby-music.wav";

interface ILobbyPlayerViewProps {}

export default function Lobby(props: ILobbyPlayerViewProps) {
  const {} = props;

  const { translations } = useTranslationContext();

  const { volume, setVolume, multiplier } = useVolumeContext();

  const {
    teammates,
    lobby,
    updatePlayer,
    player,
    teams,
    numberedTeam: team,
  } = useLobbyContext();

  // useUpdateEffect(() => {
  //   if (team?.teamState === "IN_GAME") {
  //     navigate(`/play/lobby/${pin}/game`);
  //   }
  // }, [team, pin]);

  const [TeamSelectModal, setTeamSelectModal] = useModal(
    {
      ReactComponent: () => (
        <TeamSelect
          teams={teams}
          currentGroupId={team ? team.id : lobby?.id ?? ""}
          onSelectTeam={async (team) => {
            try {
              if (!player) return;
              const updatedPlayer = { ...player, groupId: team.id };
              updatePlayer(updatedPlayer);
            } catch (e) {
              if (e instanceof Error) toast.error(e.message);
            }
            setTeamSelectModal(false);
          }}
        />
      ),
      title: "Team Select",
    },
    [teams?.length, team?.id, player]
  );

  // if (!initializationComplete) return <GibblyLoader />;

  const NotInTeamView = () => {
    return (
      <>
        <View></View>
        <Flex
          width={"100%"}
          height={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Card>
            <Flex direction={"column"}>
              <Text fontWeight={"bold"}>You are not in a team yet</Text>
              {lobby?.playersCanSwitchTeams && (
                <RaisedButton
                  variation="primary"
                  onClick={() => setTeamSelectModal(true)}
                >
                  Select a team
                </RaisedButton>
              )}
            </Flex>
          </Card>
        </Flex>
      </>
    );
  };

  return (
    <>
      {/* <NicknameModal /> */}
      <TeamSelectModal />
      <Grid
        templateRows={"50px auto 1fr auto"}
        templateColumns={"1fr"}
        width={"100%"}
        height={"100%"}
      >
        <LobbyHeaderButtons exitTo="/play/join" />
        {team === undefined ? (
          <NotInTeamView />
        ) : (
          <>
            <Flex justifyContent={"center"}>
              <RaisedCard
                padding={"small xl small xl"}
                fontWeight={"bold"}
                alignSelf={"center"}
              >
                <Text fontSize={"larger"}>
                  {" "}
                  {translations.team} {team.number}
                </Text>
              </RaisedCard>
            </Flex>

            <Flex justifyContent={"center"} alignItems={"center"}>
              <Flex
                alignItems={"center"}
                justifyContent={"center"}
                maxWidth={"1000px"}
                wrap={"wrap"}
              >
                {teammates?.map((player) => (
                  <PlayerAvatar
                    key={player.userId}
                    player={player}
                    size="100px"
                  />
                ))}
              </Flex>
            </Flex>
          </>
        )}

        <Grid
          templateColumns={"1fr auto 1fr"}
          width={"100%"}
          position={"sticky"}
          bottom={"0"}
          justifyContent={"end"}
          padding={"small xl small xl"}
        >
          <Flex column={2} justifyContent={"center"} alignItems={"center"}>
            {lobby?.playersCanSwitchTeams && (
              <RaisedButton
                isDisabled={lobby?.lobbyState !== "LOBBY"}
                variation="primary"
                size={"small"}
                onClick={() => setTeamSelectModal(true)}
              >
                Switch Teams
              </RaisedButton>
            )}
          </Flex>
          <Flex justifyContent={"end"}>
            <Card backgroundColor={"rgba(0,0,0,0.5)"}>
              <AudioController
                src={
                  lobby?.playerMusic
                    ? "/audio/music/Lobby - CG_BGM_1_3.wav"
                    : undefined
                }
                loop
                autoPlay
                volumeMultiplier={multiplier}
                volume={volume}
                setVolume={setVolume}
              />
            </Card>
          </Flex>
        </Grid>
      </Grid>
    </>
  );
}
