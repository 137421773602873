import { Slide, SlideKey } from "../types/slideTypes";
import { getDatabaseItem } from "../../../services/database/getDatabaseItem";

export async function getSlide(lessonId: string, id: string) {
  const slide = await getDatabaseItem<Slide, SlideKey>("/slides", {
    lessonId,
    id,
  });

  return slide;
}
