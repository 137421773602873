import { updateDatabaseItem } from "../../../services/database/updateDatabaseItem";
import { Lobby, LobbyKey } from "../Types/LobbyTypes";

export async function updateLobby(updatedLobby: Lobby) {
  const { pin, id, createdAt, updatedAt, ...updateLobbyObject } = updatedLobby;

  const lobby = await updateDatabaseItem<Lobby, LobbyKey>(
    "/lobbies",
    { pin, id },
    { ...updateLobbyObject }
  );
  return lobby;
}
