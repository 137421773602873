import React, { useEffect, useState } from "react";
import { Button } from "@aws-amplify/ui-react";
//import ExpandIcon from "../components/icons/ExpandIcon";
import ExpandIcon from "../components/icons/ExpandIcon";
import CollapseIcon from "../components/icons/CollapseIcon";

export default function FullScreenButton() {
  const [isFullscreen, setIsFullscreen] = useState(false);

  // Watch for fullscreenchange
  useEffect(() => {
    function onFullscreenChange() {
      setIsFullscreen(Boolean(document.fullscreenElement));
    }

    document.addEventListener("fullscreenchange", onFullscreenChange);

    return () =>
      document.removeEventListener("fullscreenchange", onFullscreenChange);
  }, []);

  function FullscreenIcon() {
    if (isFullscreen) return <CollapseIcon />;
    else return <ExpandIcon />;
  }

  return (
    <Button
      variation={"link"}
      padding={"0"}
      size="large"
      color="white"
      onClick={() => {
        if (isFullscreen) {
          document.exitFullscreen();
        } else {
          document.body.requestFullscreen();
        }
      }}
      // onClick={() => document.exitFullscreen()}
      // https://www.aha.io/engineering/articles/using-the-fullscreen-api-with-react
    >
      <FullscreenIcon />
    </Button>
  );
}
