import { queryDatabaseItems } from "../../../services/database/queryDatabaseItems";
import { Lobby } from "../Types/LobbyTypes";

export async function getActiveLobby(pin: string) {
  let lobbies: Lobby[] | undefined = [];

  try {
    lobbies = await queryDatabaseItems<Lobby>("/lobbies", pin);
    if (lobbies === undefined) throw new Error("Error getting lobbies");
    if (lobbies.length === 0) throw new Error("No lobbies found");
    else if (lobbies.length > 1)
      throw new Error("Multiple lobbies found with same pin");
    else return lobbies[0];
  } catch (err) {
    console.error(err);
    throw err;
  }
}
