import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { getLessonLink } from "../../services/lesson/lesson-link";
import { LessonLink } from "../../types/lesson/lesson-link";

export default function useLessonLinkQuery(
  lessonId: string | undefined,
  linkId: string | undefined,
  options?: UseQueryOptions<
    LessonLink,
    unknown,
    LessonLink,
    [string, string | undefined, string | undefined]
  >
) {
  const { data: lessonLink, ...restQuery } = useQuery(
    ["lesson-link", lessonId, linkId],
    async () => await getLessonLink(linkId as string, lessonId as string),
    {
      enabled: lessonId !== undefined && linkId !== undefined,
      retry: false,
      ...options,
    }
  );

  return { lessonLink, ...restQuery };
}
