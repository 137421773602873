import { updateDatabaseItem } from "../../../services/database/updateDatabaseItem";
import { Player, PlayerKey } from "../Types/GameTypes_dep";

export async function updatePlayerTeam(playerKey: PlayerKey, teamId: string) {
  const player = await updateDatabaseItem<Player, PlayerKey>(
    "/player",
    playerKey,
    {
      groupId: teamId,
      playerStatus: "NORMAL"
    }
  );
  return player;
}
