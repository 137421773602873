import { Icon, IconProps } from "@aws-amplify/ui-react";
import React from "react";
interface IProps extends IconProps {}

export default function CheckIcon(props: IProps) {
  return (
    <Icon
      {...props}
      ariaLabel="Checkmark"
      fill="currentcolor"
      pathData="M12.617 21.503c-0.457 0.562 -0.845 1.154 -1.343 1.631 -0.412 0.394 -1.41 0.136 -1.756 -0.329 -1.018 -1.366 -2.05 -2.721 -3.074 -4.082 -1.351 -1.797 -2.698 -3.597 -4.05 -5.394 -0.602 -0.801 -1.225 -1.587 -1.814 -2.397 -0.462 -0.635 -0.383 -1.328 0.169 -1.88 0.886 -0.886 1.806 -1.74 2.646 -2.668 0.529 -0.585 1.734 -0.432 2.158 0.207 0.44 0.662 1.03 1.222 1.549 1.832 0.712 0.838 1.418 1.681 2.127 2.522 0.017 0.02 0.038 0.038 0.055 0.058 0.487 0.577 0.547 0.59 1.013 0.016 0.706 -0.87 1.39 -1.759 2.081 -2.642 0.746 -0.954 1.49 -1.91 2.233 -2.866 1.006 -1.296 2.016 -2.588 3.012 -3.892 0.504 -0.659 1.128 -0.908 1.811 -0.65 0.164 0.062 0.314 0.187 0.441 0.313 1.183 1.173 2.339 2.374 3.547 3.52 0.504 0.478 0.552 1.359 0.157 1.89 -1.325 1.784 -2.659 3.562 -3.977 5.352 -1.209 1.64 -2.399 3.296 -3.606 4.938 -1.016 1.382 -2.045 2.756 -3.069 4.132 -0.093 0.126 -0.192 0.247 -0.309 0.391z"
    ></Icon>
  );
}
