import * as React from "react";

import { useRichTextContext } from "../RichTextContextProvider";

import { Button, View } from "@aws-amplify/ui-react";

import "../../../../styles/quill.css";
import { TooltipView } from "../../../../components/TooltipView";
import useClickOutsideDetection from "../../../../hooks/useClickOutsideDetection";
import ColorPalette from "./ColorPalette";

export interface ColorProps {}

export default function Color(props: ColorProps) {
  const {} = props;
  const [isOpen, setIsOpen] = React.useState(false);

  const { currentFormats, setCurrentFormats, currentEditor } =
    useRichTextContext();

  const onColor = React.useCallback(
    (color: string | undefined) => {
      currentEditor?.format("color", color);
      setCurrentFormats?.(currentEditor?.getFormat());
    },
    [currentEditor]
  );

  const buttonRef = React.useRef<HTMLButtonElement | null>(null);

  const tooltipRef = useClickOutsideDetection(
    (e) => {
      if (buttonRef.current?.contains(e.target as Node)) return;
      if (isOpen) setIsOpen(false);
    },
    [isOpen]
  );

  return (
    <TooltipView
      show={isOpen}
      placement="bottom"
      trigger={"click"}
      tooltipChildren={
        <View ref={tooltipRef}>
          {
            <ColorPalette
              color={currentFormats?.color}
              onSelectColor={onColor}         />
          }
        </View>
      }
      onToggle={(isOpen) => {
        setIsOpen(isOpen);
      }}
    >
      <Button
        ref={buttonRef}
        size="small"
        variation="link"
        height={"100%"}
        direction={"column"}
        fontSize={"xs"}
        padding={"xxs"}
        fontWeight={"medium"}
        gap={"0"}
        lineHeight={1}
      >
        A
        <View
          height="2px"
          width="12px"
          backgroundColor={currentFormats?.color || "black"}
        />
      </Button>
    </TooltipView>
  );
}
