export const placeholderPasteText: string[] = [
  "Paste in a textbook chapter to create a comprehensive quiz on its contents.",
  "Paste in a news article for a quiz on current events and critical analysis.",
  "Paste in a research paper abstract to generate questions on its thesis and findings.",
  "Paste in a blog post for a quiz on its main arguments and reader engagement.",
  "Paste in a historical document to create a quiz focusing on its significance and impact.",
  "Paste in a scientific article to generate a quiz on its methodology, results, and conclusions.",
  "Paste in a poem to create a quiz exploring its themes, tones, and literary devices.",
  "Paste in a speech transcript to generate questions on rhetorical strategies and key messages.",
  "Paste in a recipe to create a fun quiz on cooking techniques and ingredient knowledge.",
  "Paste in a software documentation section for a quiz on coding practices and API usage.",
];
