import * as React from "react";
import { Heading, Text, Button } from "@aws-amplify/ui-react";
import RaisedCard from "../components/RaisedCard";

export interface IPrivacyAndTermsCardProps {}

export default function PrivacyAndTermsCard(props: IPrivacyAndTermsCardProps) {
  const {} = props;

  return (
    <RaisedCard>
      <Heading level={5} id="sectionTitle">
        Privacy & Terms
      </Heading>

      <Text>
        <a
          href="https://www.gibbly.co/privacy-policy"
          target={"_blank"}
          rel="noreferrer"
        >
          <Button
            fontWeight={"medium"}
            size="small"
            padding={"xxs"}
            gap={"medium"}
            variation="link"
          >
            Privacy Policy
          </Button>
        </a>
      </Text>

      <Text>
        <a
          href="https://www.gibbly.co/terms-of-service"
          target={"_blank"}
          rel="noreferrer"
        >
          <Button
            fontWeight={"medium"}
            size="small"
            padding={"xxs"}
            gap={"medium"}
            variation="link"
          >
            Terms of Service
          </Button>
        </a>
      </Text>
    </RaisedCard>
  );
}
