import * as React from "react";
import { Flex, View } from "@aws-amplify/ui-react";

import SlideElementCollection from "./SlideElementCollection";
import { Slide } from "../../types/slideTypes";
import SlideElementsContextProvider from "../SlideElementsContextProvider";

export interface ISlideViewProps {
  isStatic?: boolean;
  isClickable?: boolean;
  slide: Slide;
  idPrefix: string;
  isImageLoading?: boolean;
}

export default function SlideView(props: ISlideViewProps) {
  const {
    isStatic,
    isClickable = true,
    slide,
    idPrefix,
    isImageLoading,
  } = props;

  const slideRef = React.useRef<HTMLDivElement>(null);
  const SlideContainerRef = React.useRef<HTMLDivElement>(null);

  const updateScale = React.useCallback(() => {
    if (!SlideContainerRef.current || !slideRef.current) return;

    const scale = Math.min(
      SlideContainerRef.current.clientWidth / 960,
      SlideContainerRef.current.clientHeight / 540
    );

    slideRef.current.style.top = `50%`;
    slideRef.current.style.left = `50%`;
    slideRef.current.style.transform = `translate(-50%, -50%) scale(${scale})`;
  }, []);

  React.useEffect(() => {
    updateScale();

    window.addEventListener("resize", updateScale);
    document.addEventListener("fullscreenchange", () =>
      setTimeout(updateScale, 100)
    );

    return () => {
      window.removeEventListener("resize", updateScale);
      document.addEventListener("fullscreenchange", () =>
        setTimeout(updateScale, 100)
      );
    };
  }, []);

  React.useEffect(() => {
    updateScale();
  }, [slide.elements]);

  return (
    <SlideElementsContextProvider
      slideRef={slideRef}
      isImageLoading={isImageLoading}
      slide={slide}
      idPrefix={idPrefix}
      isStatic={isStatic ?? false}
    >
      <Flex
        width={"100%"}
        height={"100%"}
        direction={"column"}
        alignItems={"stretch"}
        ref={SlideContainerRef}
        position={"relative"}
        style={{ pointerEvents: isClickable ? "auto" : "none" }}
      >
        <View
          ref={slideRef}
          id="slide-view"
          position={"absolute"}
          width={`960px`}
          height={`540px`}
          style={{
            aspectRatio: "16/9",
            overflow: isStatic ? "hidden" : "visible",
          }}
        >
          <View
            position={"relative"}
            borderRadius="xl"
            width={`100%`}
            height={`100%`}
            backgroundColor={slide?.background?.color ?? "#FFDD80"}
          >
            <SlideElementCollection elements={slide.elements} />
          </View>
        </View>
      </Flex>
    </SlideElementsContextProvider>
  );
}
