// Let's define the two arrays first
let adjectives = [
  "Bouncy",
  "Zippy",
  "Wacky",
  "Sparkly",
  "Merry",
  "Breezy",
  "Sassy",
  "Vivacious",
  "Jazzy",
  "Plucky",
  "Funky",
  "Nifty",
  "Groovy",
  "Zany",
  "Witty",
  "Jovial",
  "Jaunty",
  "Pizazzy",
  "Peppy",
  "Quirky",
  "Rambunctious",
  "Bubbly",
  "Dapper",
  "Cheeky",
  "Dazzling",
  "Whimsical",
  "Feisty",
  "Twinkly",
  "Ebullient",
  "Jubilant",
  "Kooky",
  "Punchy",
  "Vibrant",
  "Exuberant",
  "Chipper",
  "Gleeful",
  "Radiant",
  "Sprightly",
  "Lively",
  "Snazzy",
  "Popping",
  "Jolly",
  "Spritzy",
  "Gusty",
  "Giddy",
  "Frolicsome",
  "Chirpy",
  "Bustling",
  "Fluffy",
];
let nouns = [
  "Cheetah",
  "Lollipop",
  "Sunbeam",
  "Firecracker",
  "Cupcake",
  "Hummingbird",
  "Popcorn",
  "Dandelion",
  "Tambourine",
  "Crayon",
  "Gumball",
  "Confetti",
  "Flamingo",
  "Starburst",
  "Giggle",
  "Bubblegum",
  "Marshmallow",
  "Butterfly",
  "Sunflower",
  "Pumpkin",
  "Pancake",
  "Carnival",
  "Cockatoo",
  "Sparkler",
  "Macaroon",
  "Bumblebee",
  "Whirlwind",
  "Kangaroo",
  "Firefly",
  "Peppermint",
  "Jellybean",
  "Pinwheel",
  "Cherry",
  "Dumpling",
  "Pixie",
  "Firework",
  "Doughnut",
  "Raccoon",
  "Lemur",
  "Grapefruit",
  "Banana",
  "Sundae",
  "Fiesta",
  "Sprinkles",
  "Custard",
  "Hedgehog",
  "Ripple",
  "Poppy",
  "Popsicle",
  "Rhubarb",
];

// Function to get a random nickname
export function getRandomNickname() {
  let randomAdjective =
    adjectives[Math.floor(Math.random() * adjectives.length)];
  let randomNoun = nouns[Math.floor(Math.random() * nouns.length)];
  return `${randomAdjective} ${randomNoun}`;
}
