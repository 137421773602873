import { Icon } from '@aws-amplify/ui-react'
import React from 'react'

export default function ArrowLeftIcon() {
    return (
        <Icon ariaLabel="Arrow left" fill="currentcolor"
      pathData="M2.54 9.997c0.903 -0.84 1.762 -1.696 2.684 -2.48 1.51 -1.285 3.134 -2.418 4.812 -3.473 0.372 -0.234 0.745 -0.465 1.124 -0.688 0.379 -0.223 0.776 0.013 0.777 0.456 0.001 1.211 0 2.421 0 3.632v0.301c1.083 0.137 2.135 0.276 3.188 0.4 0.8 0.095 1.604 0.162 2.404 0.258 0.961 0.116 1.919 0.256 2.88 0.372 0.647 0.078 1.296 0.137 1.945 0.192 0.329 0.028 0.633 0.31 0.634 0.64 0.004 1.558 0.004 3.116 0 4.674 -0.001 0.306 -0.231 0.578 -0.53 0.622 -0.763 0.113 -1.529 0.208 -2.292 0.321 -0.803 0.118 -1.603 0.256 -2.406 0.371 -0.684 0.097 -1.371 0.166 -2.055 0.261 -0.661 0.092 -1.319 0.207 -1.979 0.305 -0.489 0.072 -0.978 0.163 -1.47 0.187 -0.285 0.014 -0.325 0.118 -0.323 0.36 0.009 1.232 0.004 2.463 0.004 3.695 0 0.486 -0.36 0.693 -0.78 0.43 -0.901 -0.564 -1.817 -1.109 -2.68 -1.727 -1.17 -0.837 -2.319 -1.706 -3.433 -2.615 -0.814 -0.664 -1.577 -1.396 -2.327 -2.134 -0.502 -0.494 -0.939 -1.053 -1.406 -1.583 -0.252 -0.286 -0.237 -1.109 0.033 -1.398 0.409 -0.439 0.789 -0.906 1.197 -1.377z">
      
         </Icon>
  )
}