import React, { useCallback, useMemo, useRef, useState } from "react";

import { Flex, Loader, ResponsiveStyle } from "@aws-amplify/ui-react";

import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";

import { Property } from "csstype";

import QuizCollection from "../QuizCollection";
import {
  IQuizCardFunctionalProps,
  IQuizCardProps,
} from "../../types/IQuizCardProps";
import useTimeout from "../../../../hooks/useTimeout";
import SearchForm from "./SearchForm";

interface SearchableQuizCollectionProps {
  collectionType:
    | "library-dashboard"
    | "discovery-dashboard"
    | "library-classroom"
    | "discovery-classroom";
  queryKey: string;
  CardComponent: React.FC<IQuizCardProps>;
  cardFunctionalProps: IQuizCardFunctionalProps;
  templateColumns:
    | ResponsiveStyle<Property.GridTemplateColumns<0 | (string & {})>>
    | undefined;
  fetchPaginatedList: (pageParam?: string) => Promise<{
    quizIds: (string | undefined)[];
    nextToken: string | null | undefined;
  }>;
  fetchPaginatedSearch: (
    search?: string,
    pageParam?: string
  ) => Promise<{
    quizIds: (string | undefined)[];
    nextToken: string | null | undefined;
  }>;
}

export default function SearchableQuizCollection({
  collectionType,
  queryKey,
  CardComponent,
  cardFunctionalProps,
  fetchPaginatedList: listQueryFunction,
  fetchPaginatedSearch: searchQueryFunction,
  templateColumns,
}: SearchableQuizCollectionProps) {
  const [searchValue, setSearchValue] = useState("");
  const [search, setSearch] = useState("");
  const [isSearching, setIsSearching] = useState(false);

  // It is your responsibility to set up onClear
  const onSearch = (search: string) => {
    setSearch(search);
    if (search === "") setIsSearching(false);
    else setIsSearching(true);
  };

  const queryClient = useQueryClient();

  const {
    data: paginatedList,
    // refetch: refetchList,
    isLoading: listLoading,
    fetchNextPage: fetchNextPageList,
    hasNextPage: hasNextPageList,
  } = useInfiniteQuery<{
    quizIds: (string | undefined)[];
    nextToken: string | null | undefined;
  }>({
    queryKey: [queryKey],
    queryFn: async ({ pageParam }) => listQueryFunction(pageParam),
    getNextPageParam: (prevData) => prevData.nextToken,
  });

  const {
    data: searchPaginated,
    // refetch: refetchSearch,
    isLoading: searchLoading,
    fetchNextPage: fetchNextPageSearch,
    hasNextPage: hasNextPageSearch,
  } = useInfiniteQuery<{
    quizIds: (string | undefined)[];
    nextToken: string | null | undefined;
  }>({
    queryKey: [queryKey, { search }],
    queryFn: async ({ pageParam }) => {

      return searchQueryFunction(search, pageParam);
    },
    getNextPageParam: (prevData) => prevData.nextToken,
  });

  const observer = useRef<IntersectionObserver>();
  const lastDataElementRef = useCallback(
    (node: HTMLElement | null) => {
      if (listLoading || searchLoading) return;
      // if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(
        (entries: IntersectionObserverEntry[]) => {
          if (entries[0].isIntersecting) {
            if (isSearching) fetchNextPageSearch();
            else fetchNextPageList();
          }
        }
      );
      if (node) observer.current.observe(node);
    },
    [listLoading, searchLoading, isSearching]
  );

  // useEffectOnce(() => {
  //   refetchList();
  //   refetchSearch();
  // });

  const searchedQuizIds = useMemo(
    () => searchPaginated?.pages.flatMap((data) => data.quizIds),
    [searchPaginated]
  );

  const allQuizIds = useMemo(
    () => paginatedList?.pages.flatMap((data) => data.quizIds),
    [paginatedList]
  );

  const delayedRefetch = useTimeout(async () => {

    queryClient.invalidateQueries([queryKey]);
    queryClient.invalidateQueries([queryKey, { search }]);
  }, 2000);

  return (
    <Flex direction={"column"} padding={"small 0 large 0"}>
      <SearchForm
        contentType="quiz"
        name={collectionType}
        search={searchValue}
        setSearch={setSearchValue}
        onSubmit={onSearch}
        placeholder="Search quizzes..."
      />

      <QuizCollection
        quizIds={
          isSearching
            ? searchedQuizIds
              ? searchedQuizIds
              : []
            : allQuizIds
            ? allQuizIds
            : []
        }
        CardComponent={CardComponent}
        cardFunctionalProps={{
          ...cardFunctionalProps,
          onChange: async () => {

            delayedRefetch.reset();
          },
        }}
        templateColumns={templateColumns}
      />

      {isSearching
        ? hasNextPageSearch && (
            <Flex justifyContent={"center"} ref={lastDataElementRef}>
              <Loader />
            </Flex>
          )
        : hasNextPageList && (
            <Flex justifyContent={"center"} ref={lastDataElementRef}>
              <Loader />
            </Flex>
          )}
    </Flex>
  );
}
