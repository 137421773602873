// import pptxgen from "pptxgenjs";
// import { Op } from "quill-delta";

import { fonts } from "../components/RichTextContextProvider";
import { SlideElement } from "../types/slideTypes";
import { getParagraphStyles, ParagraphStyle } from "./getParagraphStyles";
import { getTextStyles, TextStyle } from "./getTextStyles";

function mapTextAttributes(opAttributes: any) {
  let textPropsOptions: any = {};
  if (opAttributes) {
    if (opAttributes.bold) {
      textPropsOptions.bold = true;
    }

    if (opAttributes.italic) {
      textPropsOptions.italic = true;
    }

    if (opAttributes.underline) {
      textPropsOptions.underline = true;
    }

    if (opAttributes.font) {
      textPropsOptions.fontFace = fonts[opAttributes.font];
    }

    if (opAttributes.size) {
      textPropsOptions.fontSize = parseInt(opAttributes.size, 10);
    }

    if (opAttributes.color) {
      textPropsOptions.color = opAttributes.color;
    }

    if (opAttributes.backgroundColor) {
      textPropsOptions.highlight = opAttributes.backgroundColor;
    }
  }
  return textPropsOptions;
}
function mapParagraphAttributes(opAttributes: any) {
  let options: any = {};
  if (opAttributes) {
    if (opAttributes.list === "bullet") {
      options.bullet = true;
    }
  }
  return options;
}

export function deltaOpsToTextProps(ops: any[]) {
  let textPropsArr: any[] = [];
  ops.forEach((op: any, index) => {
    if (op.insert !== "\n") {
      let options = { breakLine: false, ...mapTextAttributes(op.attributes) };

      textPropsArr.push({ text: op.insert as string, options: { ...options } });
    } else if (index > 0 && op.attributes) {
      let lastTextProp = textPropsArr[textPropsArr.length - 1];

      lastTextProp.options = {
        ...lastTextProp.options,
        ...mapTextAttributes(op.attributes),
        breakLine: true,
      };
    }
  });
  return textPropsArr;
}
export function deltaOpsToTextPropsV2(textElement: SlideElement) {
  if (textElement.elementType !== "text") return [];

  const ops = textElement.props.ops;
  const textUnicodeArray = ops.flatMap((op) => (op.insert as string).split(""));
  const paragraphArray = ops
    .flatMap((op) => op.insert as string)
    .join("")
    .split("\n");

  const paragraphRanges: {
    startIndex: number;
    endIndex: number;
    text: string;
  }[] = [];
  let start = 0;
  paragraphArray.forEach((p) => {
    const length = p.length;
    paragraphRanges.push({
      startIndex: start,
      endIndex: start + length,
      text: p,
    });
    start += length + 1;
  });

  const textStyles: TextStyle[] = getTextStyles(textElement);
  const paragraphStyles: ParagraphStyle[] = getParagraphStyles(textElement);

  const paragraphRangesToStyle = paragraphStyles.map((pStyle) => {
    const { startIndex, endIndex } = pStyle;
    const paragraphText = paragraphRanges.find(
      (pRange) =>
        (startIndex <= pRange.startIndex && pRange.startIndex < endIndex) ||
        (pRange.startIndex < startIndex && endIndex < pRange.endIndex) ||
        (startIndex <= pRange.endIndex && pRange.endIndex < endIndex)
    );
    return { paragraphStyle: pStyle, text: paragraphText?.text };
  });

  const paragraphStyleTargets: {
    target: TextStyle;
    paragraphStyle: ParagraphStyle;
  }[] = [];

  paragraphRangesToStyle.forEach((pRange) => {
    const { startIndex, endIndex } = pRange.paragraphStyle;
    const textStylesInRange = textStyles.filter(
      (tStyle) =>
        (startIndex <= tStyle.startIndex && tStyle.startIndex < endIndex) ||
        (tStyle.startIndex < startIndex && endIndex < tStyle.endIndex) ||
        (startIndex <= tStyle.endIndex && tStyle.endIndex < endIndex)
    );
    if (textStylesInRange.length > 0)
      paragraphStyleTargets.push({
        target: textStylesInRange[0],
        paragraphStyle: pRange.paragraphStyle,
      });
  });

  const textStyledPropsArr = textStyles.flatMap((tStyle) => {
    const { startIndex, endIndex } = tStyle;

    const pStyle = paragraphStyleTargets.find(
      (t) => t.target === tStyle
    )?.paragraphStyle;
    if (!pStyle) {
      const textOptions = mapTextAttributes(tStyle);
      const options = { ...textOptions };

      const text = textUnicodeArray.slice(startIndex, endIndex).join("");

      return { text, options: { ...options } };
    } else {
      const isOverlap =
        tStyle.startIndex <= pStyle.startIndex &&
        pStyle.startIndex < tStyle.endIndex;

      const textOptions = mapTextAttributes(tStyle);
      const paragraphOptions = mapParagraphAttributes(pStyle);

      const text = textUnicodeArray.slice(startIndex, endIndex).join("");

      const startOffset = pStyle.startIndex - startIndex;
      const endOffset = pStyle.endIndex - startIndex;
      if (!isOverlap) {
        return {
          text,
          options: {
            ...textOptions,
            ...paragraphOptions,
          },
        };
      }

      const splitText = [
        text.slice(0, startOffset),
        text.slice(startOffset),
        text.slice(endOffset),
      ];
      // const splitTextFixed = splitText.filter((t) => t !== "");

      const textProps = splitText
        .map((t, index) => {
          if (index === 1) {
            return {
              text: t,
              options: { ...textOptions, ...paragraphOptions },
            };
          }
          return {
            text: t,
            options: { ...textOptions },
          };
        })
        .filter((t) => t.text !== "");

      return textProps;
    }
  });

  return textStyledPropsArr;
}
