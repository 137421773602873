import { Button, Flex, TextField } from "@aws-amplify/ui-react";
import React, { useState } from "react";
import { useTranslationContext } from "../../../../context/TranslationContextProvider";

interface EnterNicknameProps {
  onSubmit: (nickname: string) => void | Promise<void>;
  loadingText: string;
  initNickname?: string;
}

export default function EnterNicknameForm({
  onSubmit,
  loadingText,
  initNickname,
}: EnterNicknameProps) {
  const [nicknameInput, setNicknameInput] = useState<string>(
    initNickname ?? ""
  );
  const [_hasError, _setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { start } = useTranslationContext().translations;

  const onChangeNickname = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value.length <= 16) setNicknameInput(e.target.value);
  };

  async function trySubmit(): Promise<void | undefined> {
    if (nicknameInput.length === 0) {
      _setHasError(true);
      return;
    }
    _setHasError(false);
    setIsLoading(true);
    await onSubmit(nicknameInput);
    setIsLoading(false);
  }

  return (
    <Flex direction="column">
      <TextField
         
        label={"Enter Nickname"}
        fontWeight="bold"
        value={nicknameInput}
        hasError={_hasError}
        errorMessage={"Invalid nickname"}
        onChange={onChangeNickname}
      ></TextField>
      <Button
        variation="primary"
        isLoading={isLoading}
        loadingText={loadingText}
        id={"raised-btn"}
        backgroundColor={"teal.60"}
        onClick={async () => await trySubmit()}
      >
        {start}
      </Button>
    </Flex>
  );
}
