import React, { useContext } from "react";
import useChatStream from "../../../../../../hooks/useChatStream";
import { Slide } from "../../../../types/slideTypes";
import { Lesson } from "../../../../types/lessonTypes";
import useUpdateEffect from "../../../../../../hooks/useUpdateEffect";
import { convertHTMLToOps } from "../../../../util/convertHTMLToOps";
import {
  SlideElementsTemplate,
  getTemplatedElements,
} from "../../../../util/getTemplatedElements";
import { createSlideObject } from "../../../../util/createSlideObject";
import { LessonContext } from "../../../LessonContextProvider";

interface Props {
  useExistingTopic: boolean;
  useLessonPlan: boolean;
  topicLocal: string;
  lesson: Lesson;
}

interface IFactContent {
  header: string;
  body: string;
}

const useContentSlideGenerator = (props: Props) => {
  const { useExistingTopic, useLessonPlan, lesson, topicLocal } = props;

  const { outputLang } = useContext(LessonContext);

  const [slides, setSlides] = React.useState<Slide[]>([]);
  const [contents, setContents] = React.useState<IFactContent[]>([]);

  // Define your state variables using useState
  const { connect, loading, output } = useChatStream({
    streamType: "list",
    type: "slide-fact-content",
    language: outputLang,
    prompt: {
      topic: useExistingTopic ? lesson.topic : topicLocal,
      objectives: useLessonPlan ? lesson.objectives : "",
      // objectives: useLessonPlan ? lesson.objectives : undefined, // FIXED VERSION
      gradeLevel: lesson.gradeLevel,
      readingLevel: lesson.readingLevel,
    },
    onOpen: () => {
      setContents([]);
      setSlides([]);
    },
    onClose: () => {},
    // onError: (error) => {

    // },
  });

  useUpdateEffect(() => {
    const contents = output.map((o) => {
      try {
        const content = JSON.parse(o) as IFactContent;
        return content;
      } catch (error) {
        return undefined;
      }
    });

    setContents([
      ...(contents.filter((c) => c !== undefined) as IFactContent[]),
    ]);
  }, [output]);

  useUpdateEffect(() => {
    setSlides((prev) => {
      const slides = [...prev];
      const totalCurrentSlides = slides.length;
      const totatlContents = contents.length;

      for (let i = totalCurrentSlides; i < totatlContents; i++) {
        // Create a new div element
        const ops = convertHTMLToOps(contents[i].body);

        const elements = getTemplatedElements(
          i % 2 === 0
            ? SlideElementsTemplate.ImageRight
            : SlideElementsTemplate.ImageLeft,
          {
            headerText: contents[i].header,
            bodyOps: ops,
          }
        );

        const slide: Slide = createSlideObject(lesson.id, elements);
        slides.push(slide);
      }

      // if (slides.length === subtopics.length) disconnect();

      return slides;
    });
  }, [contents]);

  // Return the state variables and any functions that will update them
  return {
    connect,
    loading,
    slides,
    contents,
  };
};

export default useContentSlideGenerator;
