import { Button, Flex, ToggleButton } from "@aws-amplify/ui-react";
import React from "react";
import CheckIcon from "../../../components/icons/CheckIcon";
import DeleteIcon from "../../../components/icons/DeleteIcon";
import { FaAngleDown, FaAngleUp, FaCopy } from "react-icons/fa";

// import duplicateQuestion from "../../../services/quiz/questions/duplicateQuestion";
import { Question } from "../../../API";
import { duplicateQuestion } from "../services/duplicateQuestion";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import RaisedButton from "../../../components/RaisedButton";
import { FaPen } from "react-icons/fa6";
import SoundOnFullIcon from "../../../components/icons/SoundOnFullIcon";
import useModal from "../../../hooks/useModal";
import { AudioMenu } from "./AudioMenu";
import { useQuestionContext } from "../context";

interface QuestionItemButtonsProps {
  showAnswers: boolean;
  setShowAnswers: (show: boolean) => void;
  editingQuestion?: boolean;
  saveQuestionChanges?: () => Promise<void>;
  isCreator?: boolean;
  setShowDeleteQuestionModal?: (show: boolean) => void;
  startEdittingQuestion?: () => void;
  question: Question;
  quizQuestionId?: string;
}

export default function QuestionItemButtons({
  editingQuestion,
  saveQuestionChanges,
  isCreator,
  setShowDeleteQuestionModal,
  startEdittingQuestion,
  showAnswers,
  setShowAnswers,
  quizQuestionId,
}: QuestionItemButtonsProps): JSX.Element {
  const { id } = useParams();

  const queryClient = useQueryClient();
  const [duping, setDuping] = React.useState(false);

  const { question } = useQuestionContext();

  const [AudioModal, setAudioModal] = useModal(
    {
      ReactComponent: () => <AudioMenu />,
    },
    []
  );

  return (
    <>
      <AudioModal />
      <Flex justifyContent={"space-between"} height={"40px"}>
        <ToggleButton
          border={"0"}
          id="raised-btn"
          // borderRadius={"100%"}
          color={"black"}
          backgroundColor={"white"}
          padding={"small"}
          textAlign={"center"}
          isPressed={showAnswers}
          onChange={() => setShowAnswers(!showAnswers)} // TODO seems wrong
        >
          {showAnswers ? <FaAngleUp /> : <FaAngleDown />}
        </ToggleButton>

        <Flex justifyContent={"end"} gap={0}>
          {editingQuestion && saveQuestionChanges && (
            <Button
              size="small"
              variation={"menu"}
              onClick={async () => await saveQuestionChanges()}
            >
              <CheckIcon />
            </Button>
          )}
          {isCreator && setShowDeleteQuestionModal && (
            <>
              <RaisedButton
                tooltip={"Delete"}
                fontSize={"medium"}
                variation="menu"
                colorTheme="error"
                textAlign={"center"}
                border={"none"}
                onClick={() => setShowDeleteQuestionModal(true)}
              >
                <DeleteIcon />
              </RaisedButton>
              {quizQuestionId ? (
                <RaisedButton
                  tooltip={"Duplicate"}
                  fontSize={"medium"}
                  backgroundColor={"white"}
                  textAlign={"center"}
                  color={"black"}
                  border={"none"}
                  isLoading={duping}
                  onClick={async () => {
                    setDuping(true);
                    await duplicateQuestion(id as string, quizQuestionId);
                    queryClient.invalidateQueries(["quiz questions"]);
                    setDuping(false);
                  }}
                >
                  <FaCopy />
                </RaisedButton>
              ) : null}

              <RaisedButton
                tooltip={"Audio"}
                variation="menu"
                analyticsOptions={{
                  label: "audio-menu-btn",
                  data: {
                    questionId: question?.id as string,
                  },
                }}
                onClick={() => {
                  setAudioModal(true);
                }}
              >
                <SoundOnFullIcon />
              </RaisedButton>
              <RaisedButton
                tooltip={"Edit"}
                backgroundColor={"teal.40"}
                color="white"
                onClick={startEdittingQuestion}
              >
                <FaPen />
              </RaisedButton>
            </>
          )}
        </Flex>
      </Flex>
    </>
  );
}
