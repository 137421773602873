import { Student } from "../../types";
import { queryStudentsGSI } from "./getStudentGSI";

export async function getStudentByIdentityId(
  classId: string,
  identityId: string
): Promise<Student> {
  try {
    const students = await queryStudentsGSI({
      indexName: "GetByIdentityId",
      gsiPartitionKeyName: "classId",
      gsiPartitionKeyValue: classId,
      gsiPartitionKeyType: "S",
      gsiSortKeyName: "identityId",
      gsiSortKeyValue: identityId,
      gsiSortKeyType: "S",
    });
    if (students?.[0] === undefined)
      throw new Error("No existing student found");
    return students?.[0];
  } catch (err) {
    console.error(err);
    throw new Error("No existing student found");
  }
}
