import { Card, Flex, Heading, Text } from "@aws-amplify/ui-react";
import React from "react";
import { AssignmentResult } from "../../types";
import { useAssignmentResultsAnalytics } from "../../hooks/useAssignmentResultsAnalytics";

export default function ResultsOverview({
  assignmentResults,
}: {
  assignmentResults: AssignmentResult[];
}) {

  const analytics = useAssignmentResultsAnalytics(assignmentResults);



  return (
    <>
      <Card boxShadow={"medium"}>
        <Flex direction={"column"} gap={"xxs"}>
          <Heading paddingBottom={"xs"}>Overview</Heading>
          <Text fontWeight={"semibold"}># of Participants: <span style={{ fontWeight: "normal" }}> {analytics.numberOfParticipants}</span></Text>
          <Text fontWeight={"semibold"}>Average Score: <span style={{ fontWeight: "normal" }}> {analytics.average.toFixed(2)} %</span></Text>
          <Text fontWeight={"semibold"}>Highest Score: <span style={{ fontWeight: "normal" }}> {analytics.highestScore.toFixed(2)} %</span></Text>
          <Text fontWeight={"semibold"}>Lowest Score: <span style={{ fontWeight: "normal" }}>{analytics.lowestScore.toFixed(2)} %</span></Text>
        </Flex>
      </Card>
    </>
  );
}
