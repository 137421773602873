import { Flex, TextField } from "@aws-amplify/ui-react";
import React, { useState } from "react";
import RaisedButton from "../../../../components/RaisedButton";
import { getRandomNickname } from "../../services/getRandomNickname";
import RefreshIcon from "../../../../components/icons/RefreshIcon";
import { useTranslationContext } from "../../../../context/TranslationContextProvider";

interface RandomizeNicknameProps {
  onSubmit: (nickname: string) => void | Promise<void>;
  loadingText: string;
  initNickname?: string;
}

export default function RandomizeNicknameForm({
  onSubmit,
  loadingText,
  initNickname,
}: RandomizeNicknameProps) {
  const [nickname, setNickname] = useState<string>(
    initNickname ?? getRandomNickname()
  ); // ["Player-" + Math.floor(Math.random() * 1000)
  const [isLoading, setIsLoading] = useState(false);

  const { start } = useTranslationContext().translations;

  async function trySubmit(): Promise<void | undefined> {
    setIsLoading(true);
    await onSubmit(nickname);
    setIsLoading(false);
  }

  return (
    <Flex direction="column">
      <Flex>
        <TextField
           
          label={"Select a Nickname"}
          labelHidden
          fontWeight="bold"
          value={nickname}
          isDisabled={true}
        />
        <RaisedButton onClick={() => setNickname(getRandomNickname())}>
          <RefreshIcon />
        </RaisedButton>
      </Flex>
      <RaisedButton
        variation="primary"
        isLoading={isLoading}
        loadingText={loadingText}
        onClick={async () => await trySubmit()}
      >
        {start}
      </RaisedButton>
    </Flex>
  );
}
