import { View, ViewProps } from "@aws-amplify/ui-react";
import * as React from "react";
import { useState } from "react";

export interface IDroppableProps extends ViewProps {
  enabled?: boolean;
  dragIdKey: string;
  onDragOver?: (e: React.DragEvent<HTMLDivElement>) => void | Promise<void>;
  onDragEnter?: () => void | Promise<void>;
  onDragLeave?: () => void | Promise<void>;
  onDropItem?: (value: string) => void | Promise<void>;
  children?: React.ReactNode;
}

export function Droppable(props: IDroppableProps) {
  const {
    dragIdKey,
    onDragOver,
    onDropItem: onDrop,
    onDragEnter,
    onDragLeave,
    children,
    enabled = true,
    ...viewProps
  } = props;

  const [isOverDroppable, setIsOverDroppable] = useState(false);

  const dragCounter = React.useRef(0);

  const droppableRef = React.useRef<HTMLDivElement>(null);

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    dragCounter.current++;
    if (dragCounter.current === 1) {
      setIsOverDroppable(true);
      onDragEnter?.(); // Assuming onDragEnter is a prop or function defined elsewhere
    }
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    dragCounter.current--;
    if (dragCounter.current === 0) {
      setIsOverDroppable(false);
      onDragLeave?.(); // Assuming onDragLeave is a prop or function defined elsewhere
    }
  };

  const handleDragOver = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    await onDragOver?.(e);
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    try {
      setIsOverDroppable(false);
      dragCounter.current = 0;
      const value = e.dataTransfer.getData(dragIdKey);
      await onDrop?.(value);
    } catch (err) {
      console.error(err);
    }
  };

  if (!enabled) return <View {...viewProps}>{children}</View>;

  return (
    <View
      ref={droppableRef}
      className={isOverDroppable ? "droppable-is-over" : "droppable"}
      {...viewProps}
      onDragEnter={(e: React.DragEvent<HTMLDivElement>) => handleDragEnter(e)}
      onDragLeave={(e: React.DragEvent<HTMLDivElement>) => handleDragLeave(e)}
      onDragOver={async (e: React.DragEvent<HTMLDivElement>) =>
        handleDragOver(e)
      }
      onDrop={async (e: React.DragEvent<HTMLDivElement>) => handleDrop(e)}
    >
      {children}
    </View>
  );
}
