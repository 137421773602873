export enum GameState {
  WAITING,
  LOADING,
  PLAYING,
  COMPLETE,
}

export interface GameData {
  gameState: GameState;
  questionIndex: number;
  currentAnswer: number[] | string;
  answerResults: AnswerResult[];
}

export interface AnswerResult {
  questionIndex: number;
  submittedAnswer: number[] | string;
  acceptedAnswers: number[] | string[];
  isCorrect: boolean;
}
