import React from "react";
import { Text, Flex, Authenticator, Tabs } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";

import { PageIndex, PageTitle } from "../util/SEO";
import SearchableQuizCollection from "../features/quiz/components/Discovery/SearchableQuizCollection";
import fetchPaginatedListUsersQuizzes from "../features/quiz/utils/fetchPaginatedListUsersQuizzes";
import fetchPaginatedSearchUsersQuizzes from "../features/quiz/utils/fetchPaginatedSearchUsersQuizzes";
import LibraryQuizCard from "../features/quiz/components/Library/LibraryQuizCard";
import LessonCollection from "../features/quiz/components/Discovery/LessonCollection";
import { queryLessons } from "../features/lesson/services/queryLessons";
import { querySearchUsersLessons } from "../features/lesson/services/querySearchUsersLessons";
import { ReferAFriend } from "../features/referrals/components/ReferAFriend";
import useModal from "../hooks/useModal";
import RaisedButton from "../components/RaisedButton";
import Media from "react-media";
import { DocumentCollection } from "../features/quiz/components/Library/DocumentCollection";
import { FaRobot } from "react-icons/fa";

export default function Library() {
  const navigate = useNavigate();

  PageTitle("Library | Gibbly");
  PageIndex("noindex");

  const [ReferralModal, setReferralModal] = useModal(
    {
      size: "lg",
      title: "",
      ReactComponent: () => <ReferAFriend />,
      hasBackButton: false,
    },
    []
  );

  return (
    <>
      <ReferralModal />
      <Authenticator>
        <Flex direction={"column"} padding={"medium"} paddingTop={"large"}>
          <Flex justifyContent={"space-between"}>
            <Text id="pageTitle">My Library</Text>

            <Flex alignItems={"flex-end"}>
              <Media queries={{ small: { maxWidth: 480 } }}>
                {(matches) =>
                  matches.small ? (
                    <>
                      <RaisedButton
                        gap={"xs"}
                        fontSize={"smaller"}
                        aria-label="Earn Tokens"
                        variation={"primary"}
                        backgroundColor={"green.80"}
                        onClick={() => setReferralModal(true)}
                        data-attr="earn-tokens-btn"
                        data-ph-capture-attribute-type={"library"}
                      >
                        <FaRobot />
                      </RaisedButton>
                    </>
                  ) : (
                    <>
                      <RaisedButton
                        gap={"xs"}
                        fontSize={"smaller"}
                        variation={"primary"}
                        backgroundColor={"green.80"}
                        onClick={() => setReferralModal(true)}
                        data-attr="earn-tokens-btn"
                        data-ph-capture-attribute-type={"library"}
                      >
                        <FaRobot fontSize={"larger"} />
                        Earn Tokens
                      </RaisedButton>
                    </>
                  )
                }
              </Media>
            </Flex>
          </Flex>

          <Tabs.Container defaultValue="quizzes">
            <Tabs.List>
              <Tabs.Item value={"quizzes"} fontSize={"small"}>
                Quizzes
              </Tabs.Item>
              <Tabs.Item value={"lessons"} fontSize={"small"}>
                Lessons
              </Tabs.Item>
              <Tabs.Item value={"document"} fontSize={"small"}>
                Documents
              </Tabs.Item>
            </Tabs.List>
            <Tabs.Panel value={"quizzes"} fontSize={"small"}>
              <SearchableQuizCollection
                collectionType="library-dashboard"
                queryKey="Library Quiz Ids"
                CardComponent={LibraryQuizCard}
                cardFunctionalProps={{
                  width: {},
                  hasButtons: true,
                  onClick: (quizId) => navigate(`/quiz/${quizId}`),
                }}
                fetchPaginatedList={(pageParam) =>
                  fetchPaginatedListUsersQuizzes(pageParam)
                }
                fetchPaginatedSearch={(search, pageParam) =>
                  fetchPaginatedSearchUsersQuizzes(search, pageParam)
                }
                templateColumns={{
                  base: "100%",
                  small: "minmax(0,1fr) minmax(0,1fr) ",
                  medium: "minmax(0,1fr) minmax(0,1fr) minmax(0,1fr)",
                  large:
                    "minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr)",
                  xl: "minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr)",
                }}
              />
            </Tabs.Panel>
            <Tabs.Panel value={"lessons"} fontSize={"small"}>
              <LessonCollection
                collectionType="library-dashboard"
                listFunction={async () => {
                  const lessons = await queryLessons();
                  const sortedLessons = lessons.sort((a, b) => {
                    if (a.createdAt > b.createdAt) return -1;
                    if (a.createdAt < b.createdAt) return 1;
                    return 0;
                  });
                  return sortedLessons;
                }}
                searchFunction={async (search) => {
                  const lessons = await querySearchUsersLessons(search);
                  const sortedLessons = lessons.sort((a, b) => {
                    if (a.createdAt > b.createdAt) return -1;
                    if (a.createdAt < b.createdAt) return 1;
                    return 0;
                  });
                  return sortedLessons;
                }}
              />
            </Tabs.Panel>
            <Tabs.Panel value={"document"} fontSize={"small"}>
              <DocumentCollection />
            </Tabs.Panel>
          </Tabs.Container>
        </Flex>
      </Authenticator>
    </>
  );
}
