import { Button, Card, Flex, Heading, Text } from "@aws-amplify/ui-react";
import React, { useMemo, useState } from "react";
import ArrowRightIcon from "../../../../components/icons/ArrowRightIcon";
import ArrowLeftIcon from "../../../../components/icons/ArrowLeftIcon";
import { Question } from "../../../../API";
import GibblyLoader from "../../../../components/GibblyLoader";
import { Assignment } from "../../types";
import { AnswerResult } from "../../../game/Types/GameTypes";

export default function ChallengingQuestions({
  answerResults,
  assignment,
}: {
  answerResults: AnswerResult[];
  assignment: Assignment | undefined;
}) {
  const [index, setIndex] = useState(0);

  // const assignment = useAsync(async () => {
  //   if (!assignmentId || !classId) return;
  //   const assignment = await getAssignment(classId, assignmentId);
  //   return assignment;
  // }, [assignmentId]);

  const wrongAnswerResults = useMemo(
    () => answerResults?.filter((ar) => ar.isCorrect === false),
    [answerResults]
  );

  const questionSuccessRates = useMemo(() => {
    let sucessRates: {
      question: Question | null | undefined;
      successRate: number;
    }[] = [];

    const questions = assignment?.quiz.Questions?.items.map(
      (qq) => qq?.question as Question
    );
    if (!questions) return [];

    for (let i = 0; i < questions?.length; i++) {
      const question = questions[i];
      const results = answerResults?.filter(
        (answerResult) => answerResult.questionIndex === i
      );
      const questionSuccessRate =
        results.length === 0 // prevent divide by zero
          ? 1
          : results?.filter((answerResult) => answerResult.isCorrect).length /
            results?.length;
      sucessRates.push({ question, successRate: questionSuccessRate });
    }

    // sort by success rate
    sucessRates.sort((a, b) => {
      if (a.successRate > b.successRate) return 1;
      if (a.successRate < b.successRate) return -1;
      return 0;
    });

    //remove success rates with 100% success rate
    sucessRates = sucessRates.filter(
      (successRate) => successRate.successRate !== 1
    );

    return sucessRates;
  }, [answerResults, assignment?.quiz?.Questions?.items]);

  return (
    <>
      <Card boxShadow={"medium"}>
        {assignment === undefined ? (
          <GibblyLoader />
        ) : (
          <Flex direction={"column"}>
            <Heading paddingBottom={"xs"}>Most Challenging Questions</Heading>
            {wrongAnswerResults.length ? (
              questionSuccessRates?.[index] && (
                <>
                  <i>
                    <Text color={"red"} fontWeight={"bold"}>
                      Fail rate:{" "}
                      {(
                        (1 - questionSuccessRates?.[index]?.successRate) *
                        100
                      ).toFixed(1)}{" "}
                      %
                    </Text>
                  </i>
                  <Text>{questionSuccessRates?.[index]?.question?.text}</Text>
                  <Flex justifyContent={"space-between"}>
                    <Button
                      variation="link"
                      size="large"
                      onClick={() => {
                        setIndex((prev) => {
                          let i = prev - 1;
                          if (i < 0) i = questionSuccessRates.length - 1;

                          return i;
                        });
                      }}
                    >
                      <ArrowLeftIcon />
                    </Button>
                    <Button
                      variation="link"
                      size="large"
                      onClick={() => {
                        setIndex((prev) => {
                          let i = prev + 1;
                          if (i >= questionSuccessRates.length) i = 0;

                          return i;
                        });
                      }}
                    >
                      <ArrowRightIcon />
                    </Button>
                  </Flex>{" "}
                </>
              )
            ) : (
              <Text></Text>
            )}
          </Flex>
        )}
      </Card>
    </>
  );
}
