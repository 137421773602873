import * as React from "react";

import { useRichTextContext } from "../RichTextContextProvider";

import { Button } from "@aws-amplify/ui-react";
import { MdFormatListBulleted } from "react-icons/md";

export interface BulletedListButtonProps { }

export default function BulletedListButton(props: BulletedListButtonProps) {
  const { } = props;
  const { setCurrentFormats, currentEditor, currentFormats } =
    useRichTextContext();

  const onBulletedList = React.useCallback(() => {
    if (!currentFormats || !currentEditor) {
      return;
    }

    const range = currentEditor.getSelection();
    if (!range) {
      return;
    }
    currentEditor.formatLine(
      range.index,
      range.length,
      "list",
      currentFormats.list === "bullet" ? false : "bullet"
    );
    setCurrentFormats?.(currentEditor.getFormat());
  }, [currentFormats, currentEditor]);

  return (
    <Button
      size="small"
      variation="link"
      fontSize={"small"}
      padding={"xxs"}
      backgroundColor={
        currentFormats?.list === "bullet" ? "#eee" : undefined
      }
      onClick={() => onBulletedList()}
    >
      <MdFormatListBulleted />
    </Button>
  );
}
