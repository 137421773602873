import { Card, Flex, Heading, Text } from "@aws-amplify/ui-react";
import React, { useMemo } from "react";
import { GameData } from "../../Types/GameTypes";
import { useTranslationContext } from "../../../../context/TranslationContextProvider";

export default function ScoreCard({ gameData }: { gameData: GameData }) {
  const numCorrect = useMemo(() => {
    return gameData.answerResults.reduce((acc, result) => {
      return acc + (result.isCorrect ? 1 : 0);
    }, 0);
  }, [gameData.answerResults]);

  const accuracy = useMemo(() => {
    return (numCorrect / gameData.answerResults.length) * 100;
  }, [numCorrect, gameData.answerResults]);

  const { finished } = useTranslationContext().translations;

  return (
    <Card
      width={"80%"}
      maxWidth={"800px"}
      marginLeft={"xxl"}
      marginRight={"xxl"}
      alignSelf={"center"}
      id="raised-item"
      borderRadius={"5px"}
    >
      <Flex
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Text
          id="displayText"
          fontSize={{ base: "large", medium: "xxl" }}
          fontWeight={"semibold"}
        >
          🏁 {finished}! 🏁
        </Text>
        <Heading
          fontWeight={"black"}
          id="displayText"
          color={"green"}
          fontSize={{ base: "70px", medium: "100px" }}
        >
          {Math.round(accuracy)}%
        </Heading>
        <Heading level={4} fontWeight={"bold"} textAlign={"center"}>
          {numCorrect}/{gameData.answerResults.length}
        </Heading>
      </Flex>
    </Card>
  );
}
