import { Lesson } from "../types/lessonTypes";
import { queryDatabaseItems } from "../../../services/database/queryDatabaseItems";

export async function querySearchPublicLessons(search: string) {
  const lesson = await queryDatabaseItems<Lesson>(
    "/lessons/search/public",
    undefined,
    {
      queryParams: { q: search },
    }
  );

  return lesson;
}
