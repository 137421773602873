import { getDatabaseItem } from "../../../services/database/getDatabaseItem";
import { Player, PlayerKey } from "../Types/GameTypes_dep";

export async function getPlayer(lobbyId: string, userId: string) {
  try {
    const player = await getDatabaseItem<Player, PlayerKey>("/player", {
      playerSessionId: lobbyId,
      userId,
    });

    return player as Player;
  } catch (err) {
    console.error(err);
    throw err;
    // return undefined;http://localhost:3000/play/join/714350
  }
}
