import { useRef } from "react";

export interface IWebSocketEvents {
  onOpen?: () => void;
  onClose?: (ev: CloseEvent) => void;
  onError?: (error: Event) => void;
  onMessage?: (ev: MessageEvent<any>) => void;
  shouldReconnect?: boolean;
}

export default function useWebSocket(url: string, options: IWebSocketEvents) {
  const { onMessage, onOpen, onClose, onError } = options;

  const socketRef = useRef<WebSocket | null>(null);

  // Initialize WebSocket
  // useEffect(() => {
  //   if (socketRef.current?.readyState === WebSocket.OPEN) return;
  //   connectToWebsocket();

  //   return () => {
  //     socketRef.current?.close(1000, "Closing connection");
  //   };
  // });

  const connectToWebsocket = () => {
    const ws = new WebSocket(url);
    socketRef.current = ws;

    ws.onopen = () => {
      onOpen?.();
    };

    ws.onerror = (error) => {
      onError?.(error);
    };

    ws.onmessage = (message) => {
      onMessage?.(message);
    };

    ws.onclose = (e) => {

      onClose?.(e);
    };

    return ws;
  };

  // Function to send messages
  const sendMessage = (message: string) => {
    if (socketRef.current?.readyState === WebSocket.OPEN) {
      socketRef.current.send(message);
    } else console.error("Failed to send message. Socket is not open.");
  };

  const disconnect = () => {
    socketRef.current?.close();
  };

  return {
    sendMessage,
    disconnect,
    connect: connectToWebsocket,
    socket: socketRef.current,
  };
}
