import { fetchUserAttributes } from "aws-amplify/auth";

export default async function getCreatorId() {
  try {
    const attributes = await fetchUserAttributes();
    return `${attributes.sub}::${attributes.sub}`;
  } catch (err) {
    throw err;
  }
}
