import { GraphQLQuery, generateClient } from "aws-amplify/api";
import { CreateQuestionInput, CreateQuestionMutation } from "../../../API";
import { createQuestion as createQuestionGQLMutation } from "../../../graphql/mutations";
import createQuizQuestion from "../utils/createQuizQuestion";

export async function createQuestion(
  destinationQuizId: string,
  question: CreateQuestionInput,
  sortIndex: number | null | undefined
) {
  const client = generateClient();

  // create new question with extracted data
  const newQuestionResult = await client.graphql<
    GraphQLQuery<CreateQuestionMutation>
  >({
    query: createQuestionGQLMutation,
    variables: {
      input: { ...question },
    },
  });
  const newQuestion = newQuestionResult.data?.createQuestion;
  if (!newQuestion) throw new Error("Failed to create new question");
  // link new question to quiz with quizId

  const newQuizQuestion = await createQuizQuestion({
    questionId: newQuestion.id,
    quizId: destinationQuizId,
    sortIndex,
  });

  return newQuizQuestion;
}
