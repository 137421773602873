import { PutUserBody, UpdateUserObject, User } from "../../types/User";
import RestAPI from "../RestAPI";

export async function updateUser(
  creatorId: string,
  updateObject: UpdateUserObject
) {
  let UpdateExpression = "SET ";
  let ExpressionAttributeValues: any = {};

  const entries = Object.entries(updateObject);
  entries.forEach(([key, value], index) => {
    const placeholder = `:${key}`;
    UpdateExpression += `${key} = ${placeholder}`;

    ExpressionAttributeValues[placeholder] = value;

    if (index !== entries.length - 1) {
      UpdateExpression += ", ";
    }
  });

  const input: PutUserBody = {
    Key: {
      creatorId,
    },
    ReturnValues: "ALL_NEW",
    UpdateExpression,
    ExpressionAttributeValues,
  };

  try {
    const response: any = await RestAPI.put("/users", {
      body: input,
    });
    return response.data.Attributes as User;
  } catch (err) {
    return;
  }
}
