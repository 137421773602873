import { Button, Flex, Text } from "@aws-amplify/ui-react";
import React, { useState, useRef } from "react";
import { FaMicrophone, FaStop } from "react-icons/fa6";
import { useUserContext } from "../../../context";

type AudioRecorderProps = {
  onRecordingStop: (audioFile: File) => void | Promise<void>;
};

const AudioRecorder: React.FC<AudioRecorderProps> = ({ onRecordingStop }) => {
  const { isPremium } = useUserContext();

  const [recording, setRecording] = useState(false);
  //   const [audioURL, setAudioURL] = useState<string | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const [finishing, setFinishing] = useState(false);

  const startRecording = async () => {
    if (!isPremium()) return;
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream);
    mediaRecorderRef.current.ondataavailable = (event) => {
      audioChunksRef.current.push(event.data);
    };
    mediaRecorderRef.current.onstop = async () => {
      setFinishing(true);
      setRecording(false);
      const audioBlob = new Blob(audioChunksRef.current, { type: "audio/wav" });
      //   const audioUrl = URL.createObjectURL(audioBlob);
      //   setAudioURL(audioUrl);
      audioChunksRef.current = [];
      const audioFile = new File([audioBlob], "recording.wav", {
        type: "audio/wav",
      });
      await onRecordingStop(audioFile);
      setFinishing(false);
    };
    setRecording(true);
    mediaRecorderRef.current.start();
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
  };

  return (
    <Flex direction={"column"} alignItems={"center"} gap={"xxs"}>
      <Button
        onClick={recording ? stopRecording : startRecording}
        className={"audio-recorder " + (recording ? "recording" : "")}
        borderRadius={"100px"}
        height={"114px"}
        id="raised-btn"
        variation="primary"
        backgroundColor={recording ? "red" : undefined}
        disabled={finishing}
        style={{ aspectRatio: "1/1" }}
      >
        {recording ? <FaStop size={"80px"} /> : <FaMicrophone size={"80px"} />}
      </Button>
      <Text>
        {recording
          ? "Recording..."
          : finishing
          ? "Finishing up..."
          : "Click to record"}
      </Text>
    </Flex>
  );
};

export default AudioRecorder;
