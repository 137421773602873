import React from "react";
import RaisedButton from "../../../components/RaisedButton";
import { Assignment } from "../types";
import { useNavigate } from "react-router-dom";
import PlayIcon from "../../../components/icons/PlayIcon";

import { useAssignmentResultsForStudent } from "../hooks/useStudentAssignmentResults";
import { Flex } from "@aws-amplify/ui-react";
import { useTranslationContext } from "../../../context/TranslationContextProvider";

export default function AssignmentPlayButton({
  assignment,
  studentId,
}: {
  assignment: Assignment;
  studentId: string;
}) {
  const navigate = useNavigate();

  const assignmentResults = useAssignmentResultsForStudent(
    assignment.id,
    studentId
  );

  const { start, continue_ } = useTranslationContext().translations;

  return (
    <>
      <Flex alignItems={"end"}>
        <RaisedButton
          isLoading={assignmentResults.loading}
          gap="small"
          size={"small"}
          // width={'150px'}
          color={"white"}
          backgroundColor={"purple.90"}
          onClick={() =>
            navigate(
              `/classroom/${assignment.classId}/assignment/${assignment.id}`
            )
          }
        >
          <PlayIcon /> {assignmentResults.hasIncomplete ? continue_ : start}
        </RaisedButton>
      </Flex>
    </>
  );
}
