import * as React from "react";
import { Slide } from "../types/slideTypes";
import { useEffect } from "react";
import useArray from "../../../hooks/useArray";

export interface SlideElementsContextProps {
  selectedElements: string[];
  removeSelectedElement: (elementId: string) => void;
  selectElement: (elementId: string) => void;
  idPrefix: string;
  isStatic: boolean;
  isImageLoading?: boolean;
  slide: Slide;
}

export const SlideElementsContext =
  React.createContext<SlideElementsContextProps>(
    {} as SlideElementsContextProps
  );

export interface ISlideElementsContextProviderProps {
  children: React.ReactNode;
  slideRef: React.RefObject<HTMLDivElement>;
  slide: Slide;
  idPrefix: string;
  isStatic: boolean;
  isImageLoading?: boolean;
}

export default function SlideElementsContextProvider(
  props: ISlideElementsContextProviderProps
) {
  const { children, slide, idPrefix, isStatic, isImageLoading } = props;
  const {
    array: selectedElements,
    push: addSelectedElement,
    removeValue: removeSelectedElement,
    clear: clearSelectedElements,
  } = useArray<string>();

  useEffect(() => {
    clearSelectedElements();
  }, [slide.id]);

  return (
    <SlideElementsContext.Provider
      value={{
        selectedElements,
        removeSelectedElement,
        selectElement: (id: string) => {
          clearSelectedElements();
          addSelectedElement(id);
        },
        idPrefix,
        isStatic,
        isImageLoading,
        slide,
      }}
    >
      {children}
    </SlideElementsContext.Provider>
  );
}
