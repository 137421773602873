import { getDatabaseItem } from "../../../services/database/getDatabaseItem";

import { Team, TeamKey } from "../Types/TeamTypes";

export async function getTeam(
  lobbyId: string,
  teamId: string
): Promise<Team | undefined> {
  try {
    const team = await getDatabaseItem<Team, TeamKey>("/teams", {
      lobbyId,
      id: teamId,
    });
    return team;
  } catch (err) {
    console.error(err);
    return undefined;
  }
}
