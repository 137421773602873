import React from "react";
import {
  Flex,
  Authenticator,
  Button,
  Collection,
  Text,
} from "@aws-amplify/ui-react";
import { PageIndex, PageTitle } from "../util/SEO";
import AddIcon from "../components/icons/AddIcon";
import useModal from "../hooks/useModal";
import ClassroomSettingsForm from "../features/class/components/CreateClassForm";
import useAsync from "../hooks/useAsync";
import ClassCard from "../features/class/components/ClassCard";
import { createDatabaseItem } from "../services/database/createDatabaseItem";
import { getIdentityId } from "../features/game/util";
import { Classroom } from "../features/class/types/classroomTypes";
import { queryDatabaseItems } from "../services/database/queryDatabaseItems";
import { v4 } from "uuid";
import GibblyLoader from "../components/GibblyLoader";
import Media from "react-media";

export default function Classes() {
  PageTitle("Classes | Gibbly");
  PageIndex("noindex");

  const [AddClassModal, setShowAddModal] = useModal(
    {
      ReactComponent: () => (
        <ClassroomSettingsForm
          onSubmit={async (name) => {
            const teacherId = await getIdentityId();
            // await createClassroom(name);
            await createDatabaseItem<Classroom>("/classrooms", {
              title: name,
              teacherId,
              id: v4(),
            });
            classListAsync.reload();
            setShowAddModal(false);
          }}
        />
      ),
    },
    []
  );

  const classListAsync = useAsync(async () => {
    const userId = await getIdentityId();
    const classes = await queryDatabaseItems<Classroom>("/classrooms", userId);

    return classes;
  }, []);

  return (
    <>
      <Authenticator>
        <AddClassModal />
        <Flex
          direction={"column"}
          padding={"medium"}
          paddingTop={"large"}
          width={"100%"}
        >
          <Flex
            justifyContent={"space-between"}
            padding={"zero"}
            margin={"zero"}
          >
            <Text id="pageTitle">My Classes</Text>
            <Flex alignItems={"end"}>
              <Media queries={{ small: { maxWidth: 480 } }}>
                {(matches) =>
                  matches.small ? (
                    <>
                      <Button
                        gap={"xs"}
                        padding={"small"}
                        id="raised-btn"
                        aria-label="Add a class"
                        fontSize={{ base: "small", medium: "medium" }}
                        backgroundColor={"green.60"}
                        variation="primary"
                        onClick={() => setShowAddModal(true)}
                      >
                        <AddIcon />
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        gap={"xs"}
                        id="raised-btn"
                        fontSize={{ base: "small", medium: "medium" }}
                        backgroundColor={"green.60"}
                        variation="primary"
                        onClick={() => setShowAddModal(true)}
                      >
                        <AddIcon /> Add Class
                      </Button>
                    </>
                  )
                }
              </Media>
            </Flex>
          </Flex>

          {classListAsync.value ? (
            <Collection
              items={classListAsync.value}
              type="grid"
              gap={"small"}
              width={"100%"}
              templateColumns={{
                base: "100%",
                medium: "1fr 1fr ",
                large: "1fr 1fr 1fr",
                xl: "1fr 1fr 1fr 1fr",
              }}
              alignItems={{ base: "center", medium: "left" }}
              searchNoResultsFound={
                <Flex justifyContent="left" paddingTop={"large"}>
                  <i>
                    <Text fontWeight={"semibold"} color={"purple.80"}>
                      No classes yet. Add a class to get started!
                    </Text>
                  </i>
                </Flex>
              }
            >
              {(item) => (
                <ClassCard
                  key={item.id}
                  classroom={item}
                  onChange={() => classListAsync.reload()}
                />
              )}
            </Collection>
          ) : (
            <GibblyLoader />
          )}
        </Flex>
      </Authenticator>
    </>
  );
}
