import { S3ObjectProtected } from "../../../API";
import RestAPI from "../../../util/RestAPI";
import { getIdentityId } from "../../game/util";

type Args = {
  //   manifest: string;
  qtiContent: string;
  name: string;
  media: S3ObjectProtected[];
};

export async function downloadQTIZip(args: Args) {
  const { name, qtiContent, media } = args;

  const identityId = await getIdentityId();

  await RestAPI.post("/create-qti-quiz-zip", {
    body: { qtiContent, s3Items: media, identityId, name },
  }).then(async (res) => {
    res.json().then(async (doc) => {
      const { url } = doc as any as { key: string; url: string };

      // const { url } = await getS3Url({
      //   version: 2,
      //   path: key,
      // });

      const link = document.createElement("a");
      link.href = url;
      link.download = `${name}.zip`;
      link.click();
      // link.href = `data:application/zip;base64,${base64}`;
      // link.download = `${name}.zip`;
      // link.click();
    });
  });
}
