import {
  Button,
  Collection,
  Flex,
  ScrollView,
  TextField,
  Image,
  Link,
  Text,
  View,
} from "@aws-amplify/ui-react";
import { useInfiniteQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { uploadProtectedImageFromURL } from "../utils/uploadProtectedImagFromURL";
import { S3ObjectProtected } from "../../../API";
import { ImageDimensions } from "./ImageGallery";
import RestAPI from "../../../util/RestAPI";

export default function PixabayGallery({
  imageSearchInput,
  setImageSearchInput,
  setInputSubmitted,
  inputSubmitted,
  onUpload,
}: {
  imageSearchInput: string;
  setImageSearchInput: React.Dispatch<React.SetStateAction<string>>;
  setInputSubmitted: React.Dispatch<React.SetStateAction<string>>;
  inputSubmitted: string;
  onUpload: (
    image: S3ObjectProtected,
    url: string,
    dimensions: ImageDimensions
  ) => void | Promise<void>;
}) {
  const [selectedIndex, setSelectedIndex] = React.useState<number | undefined>(
    undefined
  );

  const {
    data: pixabayPaginatedData,
    refetch: refetchPixabayImages,
    fetchNextPage: fetchNextPixabayImagesPage,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery<any>({
    queryKey: ["pixabay images", { search: inputSubmitted }],
    enabled: false,
    queryFn: async ({ pageParam = 1 }) => {
      const results = (await RestAPI.get(`/images/pixabay`, {
        queryParams: {
          search: inputSubmitted,
          page: pageParam,
        },
      }).then((res) => res.json())) as any as { imageData: any[] };
      const imageData = results.imageData;
      const images = imageData.map((image) => image);
      return { images, nextPage: pageParam + 1 };
    },
    getNextPageParam: (prevData) => prevData.nextPage,
  });

  useEffect(() => {
    refetchPixabayImages();
  }, [inputSubmitted]);

  return (
    <>
      <TextField
        label={""}
        padding={"small"}
        value={imageSearchInput}
        onChange={(e) => {
          setImageSearchInput(e.target.value);
        }}
        innerEndComponent={
          <Button
            isLoading={isFetching}
            onClick={() => {
              setInputSubmitted(imageSearchInput);
            }}
          >
            Search
          </Button>
        }
        onKeyDown={(e) => {
          if (e.key === "Enter" && !isFetching)
            setInputSubmitted(imageSearchInput);
        }}
      ></TextField>
      {pixabayPaginatedData !== undefined && (
        <ScrollView height={"400px"}>
          <Flex direction="column">
            <Link
              href="https://pixabay.com/"
              isExternal={true}
              paddingLeft={"medium"}
              fontSize={"xs"}
            >
              Royalty free stock images provided by Pixabay
            </Link>
            <Collection
              items={pixabayPaginatedData.pages.flatMap((data) => data.images)}
              type={"grid"}
              padding={"medium"}
              paddingTop={"0px"}
              templateColumns={{ base: "1fr 1fr", small: "1fr 1fr 1fr" }}
              gap={"xs"}
              justifyContent={"center"}
              alignItems={"center"}
              alignContent={"center"}
              // justifyContent={"space-around"}
              // alignContent={'center'}
            >
              {(image, index) => {
                return (
                  <Flex
                    key={index}
                    direction={"column"}
                    gap={0}
                    paddingTop={"0px"}
                  >
                    <View position={"relative"} height={"100px"} width={"100%"}>
                      <Image
                        id="elevate"
                        alt={`Pixabay Image Tagged: ${image.tags}`}
                        src={image.largeImageURL}
                        objectFit={"cover"}
                        objectPosition="50% 50%"
                        height={"100%"}
                        width={"100%"}
                        opacity={selectedIndex === index ? 0.5 : 1}
                        loading={"lazy"}
                        borderRadius={"small"}
                        style={{
                          cursor: selectedIndex ? "not-allowed" : "pointer",
                        }}
                        onClick={async () => {
                          if (selectedIndex !== undefined) return;
                          setSelectedIndex(index);

                          await uploadProtectedImageFromURL(
                            image.largeImageURL,
                            async (s3Image, url) => {
                              await onUpload(
                                {
                                  ...s3Image,
                                  alt: `Pixabay Image Tagged: ${image.tags}`,
                                } as S3ObjectProtected,
                                url.toString(),
                                {
                                  width: image.imageWidth,
                                  height: image.imageHeight,
                                }
                              );
                            }
                          );
                          setSelectedIndex(undefined);
                        }}
                      />
                    </View>
                    <Link href={image.pageURL} isExternal>
                      <Text fontSize={"xx-small"} fontWeight={"bold"}>
                        Photo by {image.user}
                      </Text>
                    </Link>
                  </Flex>
                );
              }}
            </Collection>
            <Flex justifyContent={"center"}>
              <Button
                isLoading={isFetchingNextPage}
                onClick={() => fetchNextPixabayImagesPage()}
              >
                Show More
              </Button>
            </Flex>
          </Flex>
        </ScrollView>
      )}
    </>
  );
}
