import { Button, Flex, Grid } from "@aws-amplify/ui-react";
import React, { useEffect } from "react";
import PlayIcon from "../../../../components/icons/PlayIcon";
import ScoreCard from "./ScoreCard";
import { GameEventProps } from "../../Types/GameEventProps";
import ShareIcon from "../../../../components/icons/ShareIcon";
import { useGameContext } from "./GameContextProvider";
import { useCanvasConffeti } from "../../../../hooks/useCanvasConfetti";
import { useTranslationContext } from "../../../../context/TranslationContextProvider";

interface IGameCompleteViewProps extends GameEventProps {
  completeGameButtonText?: string;
}

export default function GameCompleteView({
  completeGameButtonText,
}: IGameCompleteViewProps) {
  const { gameData, onPlayAgain, onShare, quiz } = useGameContext();

  const {translations} = useTranslationContext()

  const { startRain, fireCannon } = useCanvasConffeti();

  useEffect(() => {
    fireCannon("left");
    fireCannon("right");
    startRain();
  }, []);

  return (
    <>
      <Grid templateRows={"auto auto 50px"}>
        <Flex row={1} alignContent={"center"} justifyContent={"center"}>
          <ScoreCard gameData={gameData} />
        </Flex>

        <Flex row={2} justifyContent={"center"} alignItems={"center"}>
          {onPlayAgain && (
            <Button
              id={"raised-btn"}
              height={"60px"}
              backgroundColor={"teal.60"}
              fontSize={"large"}
              variation="primary"
              gap={"small"}
              onClick={() => onPlayAgain()}
            >
              {completeGameButtonText || translations.play_again}
              <PlayIcon />
            </Button>
          )}
          {onShare && (
            <Button
              id={"raised-btn"}
              backgroundColor={"teal.60"}
              // width={"80px"}
              height={"60px"}
              fontSize={"large"}
              variation="primary"
              gap={"small"}
              // onClick={() =>
              //   CopyToClipboard(root + `/game/${quizId}`, "Link copied! 👍")
              // }
              onClick={() => {

                onShare();
              }}
              data-attr="share-or-play"
              data-ph-capture-attribute-type={"share-solo-finished"}
              data-ph-capture-attribute-quiz-id={quiz?.id}
              data-ph-capture-attribute-quiz-title={quiz?.title}
              data-ph-capture-attribute-quiz-creator={quiz?.creator}
              data-ph-capture-attribute-number-of-questions={quiz?.Questions?.items.length}
              data-ph-capture-attribute-quiz-privacy={quiz?.allowReadTo}
              data-ph-capture-attribute-quiz-language={quiz?.lang}
              data-ph-capture-attribute-quiz-created-at={quiz?.createdAt}
              data-ph-capture-attribute-quiz-updated-at={quiz?.updatedAt}
              // data-ph-capture-attribute-quiz-quiz-owner={quiz?.id}
      
            >
              <ShareIcon />
            </Button>
          )}
        </Flex>
      </Grid>
    </>
  );
}
