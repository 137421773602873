import * as React from "react";

import { fonts, useRichTextContext } from "../RichTextContextProvider";

import { SelectField } from "@aws-amplify/ui-react";

import "../../../../styles/quill.css";

export interface FontFamilyProps {}

export default function FontFamily(props: FontFamilyProps) {
  const {} = props;
  const { setCurrentFormats, currentEditor, currentFormats } =
    useRichTextContext();

  const onFont = React.useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      currentEditor?.format("font", e.target.value);
      setCurrentFormats?.(currentEditor?.getFormat());
    },
    [currentFormats, currentEditor]
  );

  React.useEffect(() => {

  }, [currentFormats?.font]);

  return (
    <SelectField
      size="small"
      label={"fonts"}
      width={"175px"}
      inputStyles={{ border: "1px solid var(--amplify-colors-neutral-20" }}
      labelHidden
      placeholder="Choose font"
      // defaultValue="tahoma"
      fontFamily={currentFormats?.font ? fonts[currentFormats.font] : undefined}
      value={currentFormats?.font || ""}
      onChange={onFont}
    >
      <option hidden value={undefined}></option>
      {/* <option hidden value={""}></option> */}
      {Object.entries(fonts)
        .sort()
        .map(([key, value]) => (
          <option value={key} style={{ fontFamily: value }}>
            {value}
          </option>
        ))}
    </SelectField>
  );
}
