import { useQuery } from "@tanstack/react-query";
import { OpenAIDocument } from "../features/generate/components/OpenAIDocument";
import { list, getProperties } from "aws-amplify/storage";
import { useUserContext } from "../context/UserContextProvider";

export default function useDocumentsQuery() {
  const { user } = useUserContext();

  const { data: docs, ...restQuery } = useQuery(
    ["documents", user?.identityId],
    async () => {
      const results = await list({
        path: `protected/${user?.identityId}/documents`,
      });
      // const { results: fileList } = await list({ path: "public/documents" });
      const fileList = results.items;

      const docPropertyPromises = fileList.map(async (doc) => {
        const docProperties = await getProperties({ path: doc.path });
        return { ...docProperties, ...doc } as OpenAIDocument;
      });

      const docProperties = await Promise.all(docPropertyPromises);


      const uploadedDocProps = docProperties.filter(() => {

        return true;
      });

      const sortedDocProps = uploadedDocProps.sort((a, b) => {
        if (a.lastModified === undefined) return 1;
        if (b.lastModified === undefined) return -1;
        return b.lastModified.getTime() - a.lastModified.getTime();
      });

      return sortedDocProps;
    }
  );

  return { docs, ...restQuery };
}
