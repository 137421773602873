import { Flex, Heading, TextAreaProps } from "@aws-amplify/ui-react";
import * as React from "react";
import { useContext, useRef } from "react";
import { LessonContext } from "./LessonContextProvider";
import RaisedButton from "../../../components/RaisedButton";
import { FaMagic } from "react-icons/fa";
import {
  LessonPlanFormTextAreaRef,
  LessonPlanFormTextArea,
} from "./LessonPlanFormTextArea";
import { toast } from "react-toastify";
import InfoIcon from "../../../components/icons/InfoIcon";
import ModelToggle from "../../generate/components/ModelToggle";

export interface ILessonPlanFormProps extends Omit<TextAreaProps, "ref"> {}

export default function LessonPlanForm(props: ILessonPlanFormProps) {
  const { ...textAreaProps } = props;

  const {
    lesson,
    isOwner,
    setOverview: setLessonOverview,
    setScope: setLessonCoverage,
    setObjectives: setLessonObjectives,
    setVocabulary: setLessonVocabulary,
  } = useContext(LessonContext);

  const overviewRef = useRef<LessonPlanFormTextAreaRef>(null);
  const objectivesRef = useRef<LessonPlanFormTextAreaRef>(null);
  const coverageRef = useRef<LessonPlanFormTextAreaRef>(null);
  const vocabularyRef = useRef<LessonPlanFormTextAreaRef>(null);

  const generateAll = () => {
    if (lesson.topic === "" || lesson.gradeLevel === "") {
      toast.error(
        <p>
          Please enter a <b>topic</b>, and <b>grade Level.</b>
        </p>
      );
      return;
    }

    overviewRef.current?.generateContent();
    objectivesRef.current?.generateContent();
    coverageRef.current?.generateContent();
    vocabularyRef.current?.generateContent();
  };

  return (
    <Flex height={"100%"} direction={"column"} gap={"smaller"}>
      <Flex justifyContent={"space-between"}>
        {isOwner && (
          <>
            <RaisedButton
              backgroundColor={"purple.60"}
              color={"white"}
              size={"small"}
              gap={"small"}
              onClick={generateAll}
              data-attr="create-lesson-plan"
              data-ph-capture-attribute-type={"full-lesson-plan"}
              data-ph-capture-attribute-topic={lesson.topic.toLowerCase()}
              data-ph-capture-attribute-grade-level={lesson.gradeLevel}
              data-ph-capture-attribute-reading-level={lesson.readingLevel}
              data-ph-capture-attribute-language={lesson.lang}
            >
              <FaMagic /> Lesson Plan
            </RaisedButton>
            <ModelToggle />
          </>
        )}
      </Flex>
      <LessonPlanFormTextArea
        ref={overviewRef}
        resize={"vertical"}
        isDisabled={!isOwner}
        label={<Heading fontSize={"small"}>Lesson Overview</Heading>}
        {...textAreaProps}
        value={lesson.overview}
        setValue={setLessonOverview}
        type={"overview"}
        inputObject={{
          topic: lesson.topic,
          gradeLevel: lesson.gradeLevel,
          readingLevel: lesson.readingLevel,
          objectives: lesson.objectives === "" ? undefined : lesson.objectives,
          coverage: lesson.coverage === "" ? undefined : lesson.coverage,
          vocabulary: lesson.vocabulary === "" ? undefined : lesson.vocabulary,
        }}
      />
      <LessonPlanFormTextArea
        ref={objectivesRef}
        resize={"vertical"}
        label={
          <Flex alignItems={"center"}>
            <Heading fontSize={"small"}>Learning Objectives</Heading>
            <InfoIcon message="This is a great place to paste in your local standards, specific expectations, and desired learning outcomes" />
          </Flex>
        }
        isDisabled={!isOwner}
        {...textAreaProps}
        value={lesson.objectives}
        setValue={setLessonObjectives}
        type={"objectives"}
        inputObject={{
          topic: lesson.topic,
          gradeLevel: lesson.gradeLevel,
          readingLevel: lesson.readingLevel,
          overview: lesson.overview === "" ? undefined : lesson.overview,
          coverage: lesson.coverage === "" ? undefined : lesson.coverage,
          vocabulary: lesson.vocabulary === "" ? undefined : lesson.vocabulary,
        }}
      />
      <LessonPlanFormTextArea
        ref={coverageRef}
        resize={"vertical"}
        label={<Heading fontSize={"small"}>This Lesson Covers</Heading>}
        isDisabled={!isOwner}
        {...textAreaProps}
        value={lesson.coverage}
        setValue={setLessonCoverage}
        type={"coverage"}
        inputObject={{
          topic: lesson.topic,
          gradeLevel: lesson.gradeLevel,
          readingLevel: lesson.readingLevel,
          overview: lesson.overview === "" ? undefined : lesson.overview,
          objectives: lesson.objectives === "" ? undefined : lesson.objectives,
          vocabulary: lesson.vocabulary === "" ? undefined : lesson.vocabulary,
        }}
      />
      <LessonPlanFormTextArea
        ref={vocabularyRef}
        resize={"vertical"}
        label={<Heading fontSize={"small"}>Vocabulary and Terms</Heading>}
        isDisabled={!isOwner}
        {...textAreaProps}
        value={lesson.vocabulary}
        setValue={setLessonVocabulary}
        type={"vocabulary"}
        inputObject={{
          topic: lesson.topic,
          gradeLevel: lesson.gradeLevel,
          readingLevel: lesson.readingLevel,
          overview: lesson.overview === "" ? undefined : lesson.overview,
          objectives: lesson.objectives === "" ? undefined : lesson.objectives,
          coverage: lesson.coverage === "" ? undefined : lesson.coverage,
        }}
      />
    </Flex>
  );
}
