import { Flex } from '@aws-amplify/ui-react'
import React from 'react'

export default function OptionNumbered({ index }: { index: number }) {

    const romanNumerals = ['i.', 'ii.', 'iii.', 'iv.', 'v.', 'vi.']


    return <Flex color={'white'}><b>{romanNumerals[index]}</b></Flex>
}

