import * as React from "react";
import {
  Alert,
  // Alert,
  Flex,
  Grid,
  Heading,
  SwitchField,
  Text,
  TextAreaField,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@aws-amplify/ui-react";
import { Privacy } from "../../types/lessonTypes";
import RaisedButton from "../../../../components/RaisedButton";
import AddIcon from "../../../../components/icons/AddIcon";
import DeleteIcon from "../../../../components/icons/DeleteIcon";
import useModal from "../../../../hooks/useModal";
import ImageGallery from "../../../quiz/components/ImageGallery";
import { S3Item } from "../../../../types/S3File";
import { LessonContext } from "../LessonContextProvider";
import useConfirmationModal from "../../../../hooks/useConfirmationModal";
import { deleteLesson } from "../../services/deleteLesson";
import { useNavigate } from "react-router-dom";
import LessonPromptForm from "./LessonPromptForm";
import SparkleIcon from "../../../../components/icons/SparkleIcon";
import { MenuContext, MenuState } from "../MenuContextProvider";
import Media from "react-media";
import { ImageComponent } from "../../../../components/ImageComponent";
import { S3ObjectProtected } from "../../../../API";

const DocumentGenerator: React.FC = () => {
  const [model, setModel] = React.useState<string | string[]>("gpt-4o-mini");
  return (
    <Flex direction={"column"} gap={"xs"}>
      <Heading>Generate Documents</Heading>
      <ToggleButtonGroup
        width={"100%"}
        size="small"
        onChange={(value) => setModel(value as string)}
        value={model}
        isExclusive={true}
        isSelectionRequired={true}
      >
        <ToggleButton value="gpt-4o-mini" flex={1}>
          GPT-4o-mini
        </ToggleButton>
        <ToggleButton value="gpt-4o" flex={1}>
          GPT-4o
        </ToggleButton>
      </ToggleButtonGroup>
      <Grid gap={"xxs"} templateColumns={"1fr 1fr"}>
        <RaisedButton gap={"small"} variation="primary" size="small">
          <AddIcon />
          Lesson Plan
        </RaisedButton>
        <RaisedButton gap={"small"} variation="primary" size="small">
          <AddIcon />
          Handout
        </RaisedButton>
        <RaisedButton gap={"small"} variation="primary" size="small">
          <AddIcon />
          Context Builder
        </RaisedButton>
        <RaisedButton gap={"small"} variation="primary" size="small">
          <AddIcon />
          Word Search
        </RaisedButton>
      </Grid>
    </Flex>
  );
};
const Buttons = () => {
  const { lesson, isOwner } = React.useContext(LessonContext);
  const navigate = useNavigate();

  const { setMenuState } = React.useContext(MenuContext);

  const [DeleteModal, setShowDeleteModal] = useConfirmationModal({
    confirmFn: async () => {
      try {
        await deleteLesson(lesson.id);
        navigate("/dashboard/library");
      } catch (e) {
        console.error(e);
      }
    },
    headerMessage: "Delete Lesson",
  });

  return (
    <>
      {isOwner ? (
        <>
          <DeleteModal />
          <Flex>
            <RaisedButton
              hidden
              gap={"small"}
              size="small"
              variation="destructive"
              flex={1}
              onClick={() => setShowDeleteModal(true)}
            >
              <DeleteIcon />
              Delete
            </RaisedButton>
            <RaisedButton
              gap={"small"}
              size="small"
              variation="primary"
              backgroundColor={"orange.60"}
              flex={1}
              onClick={() => setMenuState(MenuState.ADD)}
            >
              <SparkleIcon />
              Generate Lesson
            </RaisedButton>
          </Flex>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export interface IOverviewMenuProps {
  children?: React.ReactNode;
}
export default function LessonOverviewMenu(props: IOverviewMenuProps) {
  const { children } = props;

  const { lesson, isOwner, setPrivacy, setImage, setTitle, setDescription } =
    React.useContext(LessonContext);

  const onUpload = React.useCallback(
    async (image: S3Item) => {
      // const env = await getCurrentEnvironment();
      // const bucket =
      //   env === "dev" ? "media-bucket105725-dev" : "media-bucket112825-prod";
      // key: `protected/${identityId}/${image.key}`,
      // bucket,
      const s3ImageFile: S3Item = {
        key: image.key,
        identityId: image.identityId,
        level: "protected",
      };
      setImage(s3ImageFile);
      setImageGalleryModal(false);
    },
    [setImage]
  );

  // boolean that determines if the image is legacy or not
  // if the image is legacy, we have to parts the image key that is in the format "protected/identityId/imageKey"
  const ifLegacyImage = React.useMemo(() => !!lesson.image.bucket, []);

  // if is legacy image, we have to parse the image key
  // and upload it the proper way
  React.useEffect(() => {
    if (ifLegacyImage && isOwner) {
      const key = lesson.image.key?.split("/")[2] ?? "";
      const identityId = lesson.image.key?.split("/")[1] ?? "";
      const s3ImageFile: S3Item = {
        key,
        identityId,
        level: "protected",
      };
      setImage(s3ImageFile);
    }
  }, [ifLegacyImage]);

  const [ImageGalleryModal, setImageGalleryModal] = useModal(
    {
      title: "Choose an Image",
      size: "lg",
      ReactComponent: () => {
        return <ImageGallery onUpload={onUpload} />;
      },
    },
    []
  );

  return (
    <>
      <ImageGalleryModal />
      <Flex
        direction={"column"}
        height={"100%"}
        justifyContent={"space-between"}
      >
        <Flex direction={"column"} gap={"xs"}>
          <Media queries={{ small: { maxWidth: 760 } }}>
            {(matches) =>
              matches.small ? (
                <>
                  <Alert
                    isDismissible={true}
                    hasIcon={true}
                    fontSize={"xs"}
                    heading="FYI - Gibbly works best on desktop!"
                  >
                    We're still working to make our site more responsive on
                    mobile devices 😊
                  </Alert>
                </>
              ) : (
                <></>
              )
            }
          </Media>

          <Flex
            position={"relative"}
            height={"150px"}
            style={{ aspectRatio: "16/9" }}
          >
            {/* {ifLegacyImage ? (
              <S3Image
                id={lesson.image.key}
                borderRadius={"large"}
                alt={"Image for lesson: " + lesson.title}
                width={"100%"}
                style={{ aspectRatio: "16/9" }}
                objectFit={"cover"}
                bucket={lesson.image.bucket}
                s3key={lesson.image.key}
              />
            ) : ( */}
            <ImageComponent
              alt={undefined}
              aspectRatio="16/9"
              canEdit={isOwner}
              updateImage={async ({ image }) => {
                console.log("UPDATE LESSON IMAGE", { image });
                onUpload(image);
              }}
              image={
                {
                  key: lesson.image.key,
                  identityId: lesson.image.identityId,
                } as S3ObjectProtected
              }
              autoPickPrompt={lesson.topic}
            />
            {/* )} */}
            {/* {isOwner && (
              <RaisedButton
                backgroundColor={"white"}
                color={"black"}
                position={"absolute"}
                bottom={"small"}
                left={"small"}
                onClick={() => setImageGalleryModal(true)}
              >
                <FaUpload />
              </RaisedButton>
            )} */}
          </Flex>
          <TextField
            inputStyles={{ backgroundColor: "rgba(255,255,255,0.9)" }}
            label={
              <Heading
                fontWeight={"semibold"}
                color={"neutral.100"}
                fontSize={"small"}
              >
                Title
              </Heading>
            }
            disabled={!isOwner}
            fontSize={"smaller"}
            value={lesson.title}
            size="small"
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextAreaField
            label={
              <Heading
                fontWeight={"semibold"}
                color={"neutral.100"}
                fontSize={"small"}
              >
                Description
              </Heading>
            }
            rows={1}
            inputStyles={{ backgroundColor: "rgba(255,255,255,0.9)" }}
            resize="vertical"
            disabled={!isOwner}
            value={lesson.description}
            fontSize={"smaller"}
            size="small"
            onChange={(e) => setDescription(e.target.value)}
          />
          <Flex justifyContent={"space-between"}>
            <SwitchField
              size={"small"}
              isDisabled={!isOwner}
              label={
                <Text fontWeight={"semibold"} color={"neutral.100"}>
                  {lesson.privacy === Privacy.PUBLIC ? "Public" : "Private"}
                </Text>
              }
              isChecked={lesson.privacy === Privacy.PUBLIC}
              labelPosition={"end"}
              onChange={(e) =>
                setPrivacy(e.target.checked ? Privacy.PUBLIC : Privacy.PRIVATE)
              }
            />
          </Flex>
          <LessonPromptForm />
        </Flex>
        {children}
      </Flex>
    </>
  );
}

LessonOverviewMenu.DocumentGenerator = DocumentGenerator;
LessonOverviewMenu.Buttons = Buttons;
