import { Lesson } from "../types/lessonTypes";
import { getIdentityId } from "../../game/util";
import { copyS3Item } from "../../../services/s3/copyS3Item";
import { createLesson } from "./createLesson";
import { querySlides } from "./querySlides";
import { languageOptions } from "../../translations";
import { convertOpsToHTML } from "../util/convertOpsToHTML";
import { invokeLambdaFunction } from "../../../services/awsFacade";
import getCreatorId from "../../../util/GetCreatorId";
import sendCustomEvent from "../../../util/sendCustomEvent";
import { createSlide } from "./createSlide";
import { convertHTMLToOps } from "../util";

type TranslatedLesson = {
  details: {
    title: string;
    description?: string;
    overview?: string;
    objectives?: string;
    coverage?: string;
    vocabulary?: string;
  };
  slides?: {
    elements: {
      html: string;
    }[];
  }[];
};

async function translateLesson(
  lesson: Lesson,
  locale: keyof typeof languageOptions,
  options?: {
    translateLessonPlan: boolean;
    translateSlides: boolean;
  }
) {
  const identityId = await getIdentityId();
  const creatorId = await getCreatorId();
  const { translateLessonPlan, translateSlides } = options ?? {
    translateLessonPlan: true,
    translateSlides: true,
  };
  const { image, title } = lesson;
  try {
    if (image.identityId && image.key) {
      await copyS3Item({
        identityId: image.identityId,
        fileName: image.key,
      });
    }

    const slides = await querySlides(lesson.id);

    const newImage = {
      key: image.key ?? "",
      identityId: identityId,
    };

    const language = languageOptions[locale];

    const lessonInput: TranslatedLesson = {
      details: {
        title: title,
        overview: translateLessonPlan ? lesson.overview : undefined,
        objectives: translateLessonPlan ? lesson.objectives : undefined,
        coverage: translateLessonPlan ? lesson.coverage : undefined,
        vocabulary: translateLessonPlan ? lesson.vocabulary : undefined,
      },
      slides: translateSlides
        ? slides?.map((slide) => ({
            elements: slide.elements.map((element) => ({
              html:
                element.elementType === "text"
                  ? convertOpsToHTML(element.props.ops)
                  : "",
            })),
          }))
        : [],
    };

    const response = await invokeLambdaFunction("TranslationAPI", {
      creatorId,
      language,
      lesson: lessonInput,
    });

    const translatedLesson = JSON.parse(response?.body) as TranslatedLesson;

    const newLesson = await createLesson({
      userId: identityId,
      title: `${lesson.title} - ${languageOptions[locale]} ${translatedLesson.details.title}`,
      gradeLevel: lesson.gradeLevel,
      readingLevel: lesson.readingLevel,
      description: translatedLesson.details.description ?? lesson.description,
      overview: translatedLesson.details.overview ?? lesson.overview,
      objectives: translatedLesson.details.objectives ?? lesson.objectives,
      coverage: translatedLesson.details.coverage ?? lesson.coverage,
      vocabulary: translatedLesson.details.vocabulary ?? lesson.vocabulary,
      lang: locale,
      image: newImage,
      topic: lesson.topic,
    });

    const dupSlidePromises = slides?.map(async (ogSlide, slideIndex) => {
      for (let i = 0; i < ogSlide.elements.length; i++) {
        const element = ogSlide.elements[i];
        if (element.elementType === "image") {
          if (element.s3Item.identityId && element.s3Item.key)
            await copyS3Item({
              identityId: element.s3Item.identityId,
              fileName: element.s3Item.key ?? "",
            });
          element.s3Item.identityId = identityId;
        } else if (
          translateSlides &&
          element.elementType === "text" &&
          translatedLesson.slides
        ) {
          element.props.ops = convertHTMLToOps(
            translatedLesson.slides[slideIndex].elements[i].html
          );
        }
      }

      createSlide({ ...ogSlide, lessonId: newLesson.id });

      // if (ogSlide) {
      //   if (image?.identityId && ogSlide)
      //     await copyS3Item({
      //       identityId: image?.identityId,
      //       fileName: image?.key,
      //     });

      //   const newImage = image?.key
      //     ? {
      //         key: image?.key ?? "",
      //         identityId: identityId,
      //       }
      //     : null;

      //   const newQuestion: CreateQuestionInput = {
      //     text: translatedLesson.questions[i].question,
      //     answers: translatedLesson.questions[i].options,
      //     solution: translatedLesson.questions[i].solution,
      //     image: newImage,
      //     correctIndices: ogSlide.correctIndices,
      //     type: ogSlide.type,
      //   };

      //   return createQuestion(
      //     newLesson.id,
      //     newQuestion,
      //     ogSlide?.sortIndex
      //   );
      // }
      return;
    });

    if (dupSlidePromises) await Promise.all(dupSlidePromises);

    sendCustomEvent("translate", {
      type: "lesson",
      language,
      inputLessonId: lesson.id,
      inputLesson: JSON.stringify(lessonInput),
      outputLessonId: newLesson.id,
      outputLesson: JSON.stringify(translatedLesson),
    });

    return newLesson;
  } catch (e) {
    console.error(e);
    throw new Error("Failed to duplicate lesson");
  }
}

export default translateLesson;
