import * as React from "react";
import { useUserContext } from "./UserContextProvider";

import { Translations } from "../types/Translations";
import useLocalStorage from "../hooks/useLocalStorage";
import {
  getLang,
  getTranslation,
  languageOptions,
} from "../features/translations";
import { useQuery } from "@tanstack/react-query";
import { translations as translations_en } from "../features/translations/data/en";

interface ITranslationContext {
  lang: keyof typeof languageOptions;
  prefLang: keyof typeof languageOptions;
  translations: Translations;
}

const TranslationContext = React.createContext<ITranslationContext>({
  lang: getLang("en"),
  prefLang: getLang("en"),
  translations: translations_en,
});

export function useTranslationContext() {
  return React.useContext(TranslationContext);
}

export interface ITranslationContextProviderProps {
  lang?: string | null;
  children: React.ReactNode;
}

export default function TranslationContextProvider({
  children,
  lang,
}: ITranslationContextProviderProps) {
  const { user } = useUserContext();

  const [prefLang, setPrefLang] = useLocalStorage<{
    lang: string;
  }>("pref-lang", { lang: "en" });

  const usedLang = lang || user?.lang || "en";

  const { data: translations } = useQuery({
    queryKey: ["translations", usedLang],
    queryFn: async () => await getTranslation(usedLang),
  });

  // const translations = React.useMemo(() => {
  //   return lang
  //     ? await getTranslation(lang)
  //     : user?.lang
  //     ? getTranslation(user.lang)
  //     : getTranslation(window.navigator.language);

  //   // switch (lang?.split("-")[0]) {
  //   //   case "fr":
  //   //     return translations;
  //   //   case "en":
  //   //     return translations;
  //   //   default:
  //   //     switch (user?.lang?.split("-")[0]) {
  //   //       case "fr":
  //   //         return translations;
  //   //       case "en":
  //   //         return translations;
  //   //       default:
  //   //         switch (window.navigator.language.split("-")[0]) {
  //   //           case "fr":
  //   //             return translations;
  //   //           case "en":
  //   //             return translations;
  //   //           default:
  //   //             return translations;
  //   //         }
  //   //     }
  //   // }
  // }, [lang, user?.lang]);

  React.useEffect(() => {
    setPrefLang({ lang: user?.lang ?? "en" });
  }, [user?.lang]);

  return (
    <>
      <TranslationContext.Provider
        value={{
          lang: getLang(lang || "en"),
          prefLang: getLang(prefLang.lang || lang || "en"),
          translations: translations || translations_en,
        }}
      >
        {children}
      </TranslationContext.Provider>
    </>
  );
}
