import { Flex, Button } from "@aws-amplify/ui-react";
import React, { useMemo } from "react";
import ExitButtonRelative from "../../../../layouts/ExitButtonRelative";
import FullScreenRelativeButton from "../../../../layouts/FullScreenButtonRelative";
import SettingsIcon from "../../../../components/icons/SettingsIcons";
import useModal from "../../../../hooks/useModal";
import { LobbyOptionsForm } from "./LobbyOptionsForm";
import { LobbyOptions } from "../../Types/LobbyTypes";

import useLobbyContext from "../../hooks/useLobbyContext";
import { FaQuestion } from "react-icons/fa";

import LobbyTutorial from "./LobbyTutorial";

interface LobbyHeaderButtonsProps {
  exitTo?: string;
}

export default function LobbyHeaderButtons(props: LobbyHeaderButtonsProps) {
  const { exitTo } = props;
  const { lobby, teams, updateLobby, updateNumberOfTeams, isHost } =
    useLobbyContext();

  const currentLobbyOptions = useMemo<LobbyOptions>(() => {
    return {
      randomNicknames: lobby?.randomNicknames ?? true,
      autoTeams: lobby?.autoTeams ?? true,
      playersCanSwitchTeams: lobby?.playersCanSwitchTeams ?? true,
      hostSpectating: lobby?.hostSpectating ?? true,
      playerMusic: lobby?.playerMusic ?? true,
    };
  }, [lobby]);


  const [LobbyOptionsModal, setLobbyOptionsModal] = useModal(
    {
      title: "Lobby Settings",
      size: "lg",
      ReactComponent: () => (
        <LobbyOptionsForm
          currentOptions={currentLobbyOptions}
          currentNumberOfTeams={teams?.length ?? 0}
          submitButtonText="Save"
          onSubmitOptions={async (options, numberOfTeams) => {
            try {
              if (teams) {
                await updateNumberOfTeams(numberOfTeams);
              }
              if (lobby) await updateLobby({ lobby: { ...lobby, ...options } });

              setLobbyOptionsModal(false);
            } catch (error) {

            }
          }}
        />
      ),
    },
    [currentLobbyOptions, teams.length]
  );
  const [HowToModal, setHowToModal] = useModal(
    {
      title: "How To Play",
      size: "lg",
      ReactComponent: () => <LobbyTutorial />,
    },
    [currentLobbyOptions, teams.length]
  );

  return (
    <>
      <LobbyOptionsModal />
      <HowToModal />
      <Flex
        padding={"xxxs small xxxs small"}
        justifyContent={"space-between"}
        width={"100%"}
        style={{
          pointerEvents: "auto",
        }}
      >
        <ExitButtonRelative exitTo={exitTo} />

        <Flex justifyContent={"end"}>
          {isHost ? (
            <>
              <Button
                variation={"link"}
                color="white"
                padding={"0"}
                size="large"
                onClick={() => setHowToModal(true)}
              >
                <FaQuestion />
              </Button>
              <Button
                variation={"link"}
                color="white"
                padding={"0"}
                size="large"
                onClick={() => setLobbyOptionsModal(true)}
              >
                <SettingsIcon />
              </Button>
            </>
          ) : null}
          <FullScreenRelativeButton />
        </Flex>
      </Flex>
    </>
  );
}
