import { useState, useEffect, useMemo } from "react";

type SetState<T> =
  | React.Dispatch<React.SetStateAction<T>>
  | ((value: T) => void);

const useCharacterLimit = (
  state: string,
  setState: SetState<string>,
  characterLimit: number
) => {
  const [isLimitReached, setIsLimitReached] = useState<boolean>(false);

  useEffect(() => {
    if (state.length > characterLimit) {
      setState(state.substring(0, characterLimit));
      setIsLimitReached(true);
    } else {
      setIsLimitReached(false);
    }
  }, [state, setState, characterLimit]);

  const numCharacters = useMemo(() => state.length, [state]);


  return { isLimitReached, characterLimit, numCharacters };
};

export default useCharacterLimit;
