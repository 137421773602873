import { QuestionType } from "../../../API";
import { FixedQuestion } from "../types/FixedQuestion";
import { GeneratedQuestion } from "../types/GeneratedQuestion";

export const fixGeneratedQuestion = (
  q: GeneratedQuestion | null
): FixedQuestion | null => {
  if (q === null) return null;
  if (q.answers === undefined || q.answers.length === 0) return null;
  if (q.correctIndices === undefined || q.correctIndices.length === 0)
    return null;
  if (q.text === undefined || q.text.length === 0) return null;
  if (q.type === undefined) return null;

  switch (q.type) {
    case QuestionType.SINGLE_SELECT:
      if (q.correctIndices.length > 1) {
        return {
          text: q.text,
          answers: q.answers,
          correctIndices: q.correctIndices,
          type: QuestionType.MULTIPLE_SELECT,
        };
      }
      break;
    case QuestionType.MULTIPLE_SELECT:
      if (q.correctIndices.length === 1) {
        return {
          text: q.text,
          answers: q.answers,
          correctIndices: q.correctIndices,
          type: QuestionType.SINGLE_SELECT,
        };
      }
      break;
    case QuestionType.TRUE_OR_FALSE:
      if (q.answers.length !== 2) {
        return null;
      }
      break;

    case QuestionType.TYPED_ANSWER:
      const newAnswers = q.answers.filter((_, i) =>
        q.correctIndices?.includes(i)
      );

      return {
        text: q.text,
        answers: newAnswers,
        correctIndices: q.correctIndices,
        type: q.type,
      };
    default:
      return null;
  }

  return {
    text: q.text,
    answers: q.answers,
    correctIndices: q.correctIndices,
    type: q.type,
    image: q.image,
  };
};
export const fixGeneratedQuestions = (
  genQuestions: GeneratedQuestion[]
): FixedQuestion[] => {
  return genQuestions
    .map((q) => fixGeneratedQuestion(q))
    .filter((q) => q !== null) as FixedQuestion[];
};
