import { Icon } from '@aws-amplify/ui-react'
import React from 'react'

export default function KebabIcon() {
    return (
        <Icon ariaLabel="Kebab"
            xmlns="http://www.w3.org/2000/svg"
            viewBox={{
                width: 128,
                height: 512
            }}
            pathData="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z">
        </Icon>
    )
}

