import React from "react";
import {
  Button,
  CheckboxField,
  Fieldset,
  Flex,
  Image,
  Link,
  ResponsiveStyle,
  View,
  Text,
} from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo192.png";
import SimpleLogo from "../assets/logoSimple.png";
import PlayIcon from "../components/icons/PlayIcon";
import CreatePanel from "../features/quiz/CreatePanel";
import useModal from "../hooks/useModal";
import { Property } from "csstype";
import RaisedButton from "../components/RaisedButton";
import { PresentationContext } from "../features/lesson/components/PresentationContextProvider";

import Media from "react-media";
import { PageDescription, PageIndex, PageTitle } from "../util/SEO";

import ShareLessonButton from "./ShareLessonButton";
import { LessonContext } from "../features/lesson/components/LessonContextProvider";
import { SlidesContext } from "../features/lesson/components/SlidesContextProvider";
import KebabIcon from "../components/icons/KebabIcon";
import { TooltipView } from "../components/TooltipView";
import useClickOutsideDetection from "../hooks/useClickOutsideDetection";
import { duplicateLesson } from "../features/lesson/services/duplicateLesson";
import translateLesson from "../features/lesson/services/translateLesson";
import { useMutation } from "@tanstack/react-query";
import { languageOptions } from "../features/translations";
import { Lesson } from "../features/lesson/types/lessonTypes";
import { MdTranslate } from "react-icons/md";
import { PremiumFeatureBadge } from "../features/premium/components";
import { useUserContext } from "../context";
import TranslateForm from "../features/translations/components/TranslateForm";

import "../styles/lesson-header.css";
import { FaCheckCircle, FaCloudUploadAlt } from "react-icons/fa";

export default function LessonHeader({
  useAuthentication,
  area,
}: {
  useAuthentication: boolean;
  area?: ResponsiveStyle<Property.GridArea>;
}) {
  PageTitle("Lesson Generator | Gibbly");
  PageDescription("Test your knowledge with this quiz!");
  PageIndex("noindex");

  const navigate = useNavigate();

  const { isPremium } = useUserContext();

  const { startPresentation } = React.useContext(PresentationContext);

  const { lesson } = React.useContext(LessonContext);

  const { slideIds, status } = React.useContext(SlidesContext);

  const translateRef = React.useRef<HTMLDivElement>(null);

  const [translationOptions, setTranslationOptions] = React.useState({
    translateLessonPlan: true,
    translateSlides: true,
  });

  const [CreateQuizModal, setShowCreateQuizModal] = useModal(
    {
      size: "lg",
      title: "Create",
      ReactComponent: () => (
        <CreatePanel onCreated={() => setShowCreateQuizModal(false)} />
      ),
      hasBackButton: true,
    },
    []
  );

  const [showActions, setShowActions] = React.useState(false);
  const [duping, setDuping] = React.useState(false);
  const [showTranslateMenu, setShowTranslateMenu] = React.useState(false);

  const buttonRef = useClickOutsideDetection<HTMLButtonElement>(
    (e) => {
      if (
        buttonRef.current?.contains(e.target as Node) ||
        translateRef.current?.contains(e.target as Node)
      )
        return;
      if (showActions) {
        setShowActions(false);
        setShowTranslateMenu(false);
      }
    },
    [showActions]
  );

  const { mutateAsync: translateMutation, isLoading: isTranslating } =
    useMutation({
      mutationFn: async ({
        lesson,
        locale,
        options,
      }: {
        lesson: Lesson;
        locale: keyof typeof languageOptions;
        options?: {
          translateLessonPlan: boolean;
          translateSlides: boolean;
        };
      }) => {
        if (isPremium()) {
          return await translateLesson(lesson, locale, options);
        } else {
          setShowTranslateMenu(false);
          throw new Error(
            "You need to be a premium user to translate lessons."
          );
        }
      },
      onError: (error) => {
        console.error("Error translating quiz", error);
      },
      onSuccess: (data) => {
        const newLesson = data;
        // open new lesson in new tab
        window.open(`/lesson/${newLesson.userId}/${newLesson.id}`, "_blank");
      },
    });

  if (useAuthentication) {
    return (
      <>
        <Flex
          area={area}
          height={"56px"}
          justifyContent={"space-between"}
          backgroundColor={"white"}
          boxShadow={"0px -2px rgb(0 0 0 / 10%) inset"}
          padding={"small"}
          style={{
            backgroundImage:
              "linear-gradient(to top left, var(--amplify-colors-teal-100) ,var(--amplify-colors-teal-60))",
          }}
        >
          <CreateQuizModal />

          <Flex rowGap={0}>
            <Flex justifyContent={"start"} padding={"zero"}>
              <Button
                paddingRight={"zero"}
                marginRight={"zero"}
                as={Link}
                variation={"link"}
                size="small"
                onClick={() => navigate("/dashboard/library/")}
              >
                <Media queries={{ medium: { maxWidth: 768 } }}>
                  {(matches) =>
                    matches.medium ? (
                      <>
                        <Image
                          objectFit={"cover"}
                          width={"45px"}
                          height={"45px"}
                          src={SimpleLogo}
                          alt="Gibbly Logo"
                          paddingRight={"zero"}
                          marginRight={"zero"}
                        />
                      </>
                    ) : (
                      <>
                        <Image
                          objectFit={"cover"}
                          height={"45px"}
                          width={"135px"}
                          src={logo}
                          alt="Gibbly Logo"
                          paddingRight={"zero"}
                          marginRight={"zero"}
                        />
                      </>
                    )
                  }
                </Media>
              </Button>
            </Flex>
          </Flex>

          <Media queries={{ medium: { maxWidth: 768 } }}>
            {(matches) =>
              matches.medium ? (
                <>
                  <Flex justifyContent={"space-around"} alignItems={"center"}>
                    <RaisedButton
                      gap={"xs"}
                      ariaLabel="Present"
                      variation="primary"
                      onClick={() => startPresentation()}
                    >
                      <PlayIcon />
                    </RaisedButton>
                    <ShareLessonButton />
                  </Flex>
                </>
              ) : (
                <>
                  <Flex justifyContent={"space-around"} alignItems={"center"}>
                    <Text color={"neutral.20"} fontSize={"medium"}>
                      {status === "saving" ? (
                        <TooltipView
                          tooltipChildren={"Saving..."}
                          placement="bottom"
                        >
                          <FaCloudUploadAlt />
                        </TooltipView>
                      ) : (
                        <TooltipView
                          tooltipChildren={"All changes have been saved."}
                          placement="bottom"
                        >
                          <FaCheckCircle />
                        </TooltipView>
                      )}
                    </Text>
                    <TooltipView
                      show={showActions}
                      placement="left-start"
                      tooltipChildren={
                        <Flex direction={"column"} gap={"0"}>
                          <Button
                            justifyContent={"start"}
                            ref={buttonRef}
                            variation="menu"
                            size="small"
                            color={"black"}
                            backgroundColor={"transparent"}
                            isLoading={duping}
                            loadingText="Making a copy..."
                            data-attr="make-a-copy"
                            data-ph-capture-attribute-type={"lesson"}
                            data-ph-capture-attribute-owner={lesson.userId}
                            data-ph-capture-attribute-id={lesson.id}
                            data-ph-capture-attribute-title={lesson.title}
                            data-ph-capture-attribute-n-slideIds={
                              slideIds?.length
                            }
                            onClick={async (e) => {
                              e.stopPropagation();
                              setDuping(true);

                              const newLesson = await duplicateLesson(lesson);
                              window.open(
                                `/lesson/${newLesson.userId}/${newLesson.id}`,
                                "_blank"
                              );
                              setDuping(false);
                              setShowActions(false);
                              setShowTranslateMenu(false);
                            }}
                          >
                            Make a copy
                          </Button>

                          <TooltipView
                            show={showTranslateMenu}
                            placement="bottom"
                            tooltipChildren={
                              <View ref={translateRef}>
                                <TranslateForm
                                  isDisabled={Object.values(
                                    translationOptions
                                  ).every((option) => option === false)}
                                  exitFn={() => setShowTranslateMenu(false)}
                                  translateFn={async (locale) => {
                                    await translateMutation({
                                      lesson,
                                      locale,
                                      options: translationOptions,
                                    });
                                  }}
                                >
                                  <Fieldset
                                    className="translate-lesson-options"
                                    legend="Options"
                                    legendHidden
                                    direction={"row"}
                                    gap={"large"}
                                    padding={"xxs 0 xxs 0"}
                                  >
                                    <CheckboxField
                                      label="Lesson Plan"
                                      name="translateLessonPlan"
                                      checked={
                                        translationOptions.translateLessonPlan
                                      }
                                      onChange={(e) => {
                                        setTranslationOptions({
                                          ...translationOptions,
                                          translateLessonPlan: e.target.checked,
                                        });
                                      }}
                                    ></CheckboxField>
                                    <CheckboxField
                                      label="Slides"
                                      name="translateSlides"
                                      checked={
                                        translationOptions.translateSlides
                                      }
                                      onChange={(e) => {
                                        setTranslationOptions({
                                          ...translationOptions,
                                          translateSlides: e.target.checked,
                                        });
                                      }}
                                    ></CheckboxField>
                                  </Fieldset>
                                </TranslateForm>
                              </View>
                            }
                          >
                            <Button
                              justifyContent={"start"}
                              ref={buttonRef}
                              variation="menu"
                              size="small"
                              color={"black"}
                              backgroundColor={"transparent"}
                              isLoading={isTranslating}
                              loadingText="Translating..."
                              gap={"xs"}
                              onClick={async (e) => {
                                e.stopPropagation();
                                setShowTranslateMenu(true);
                              }}
                            >
                              <MdTranslate />
                              Translate <PremiumFeatureBadge />
                            </Button>
                          </TooltipView>
                        </Flex>
                      }
                    >
                      <Button
                        padding={"xs"}
                        variation="primary"
                        color={"black"}
                        backgroundColor={"white"}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowActions(!showActions);
                        }}
                      >
                        <KebabIcon />
                      </Button>
                    </TooltipView>

                    <RaisedButton
                      gap={"xs"}
                      variation="primary"
                      size="small"
                      color={"black"}
                      backgroundColor={"white"}
                      onClick={() => startPresentation()}
                      data-attr="share-lesson-slideIds"
                      data-ph-capture-attribute-type={"present"}
                      data-ph-capture-attribute-title={lesson.title.toLowerCase()}
                      data-ph-capture-attribute-topic={lesson.topic.toLowerCase()}
                      data-ph-capture-attribute-grade-level={lesson.gradeLevel}
                      data-ph-capture-attribute-reading-level={
                        lesson.readingLevel
                      }
                      data-ph-capture-attribute-language={lesson.lang}
                      data-ph-capture-attribute-number-of-slides={
                        slideIds.length
                      }
                    >
                      <PlayIcon />
                      Present
                    </RaisedButton>
                    <ShareLessonButton />
                  </Flex>
                </>
              )
            }
          </Media>
        </Flex>
      </>
    );
  } else
    return (
      <Flex
        height={"56px"}
        justifyContent={"space-between"}
        backgroundColor={"white"}
        boxShadow={
          "0px -5px rgb(0 0 0 / 20%) inset, 0px 0px 4px rgb(0 0 0 / 15%"
        }
      >
        <CreateQuizModal />

        <Flex justifyContent={"start"} padding={0}>
          <Button
            variation={"link"}
            padding={0}
            onClick={() => navigate("/dashboard/library/")}
          >
            <Media queries={{ medium: { maxWidth: 768 } }}>
              {(matches) =>
                matches.medium ? (
                  <>
                    <Image
                      objectFit={"cover"}
                      width={"55px"}
                      height={"55px"}
                      src={SimpleLogo}
                      alt="Gibbly Logo"
                      paddingRight={"zero"}
                      marginRight={"zero"}
                    />
                  </>
                ) : (
                  <>
                    <Image
                      objectFit={"cover"}
                      width={"165px"}
                      height={"55px"}
                      src={logo}
                      alt="Gibbly Logo"
                      padding={"zero"}
                      margin={"zero"}
                    />
                  </>
                )
              }
            </Media>
          </Button>
        </Flex>
      </Flex>
    );
}
