import {
  Divider,
  Flex,
  Grid,
  ResponsiveStyle,
  StyleToken,
  Text,
  View,
} from "@aws-amplify/ui-react";
import * as React from "react";
import { FaCheckCircle } from "react-icons/fa";
import { ColorKeys } from "@aws-amplify/ui-react/dist/types/primitives/types/theme";
import { Property } from "csstype";
import RaisedCard from "./RaisedCard";
import RaisedButton from "./RaisedButton";

export type ProductCardProps = {
  title: React.ReactNode;
  heading: React.ReactNode;
  subheading: React.ReactNode;
  buttonText: React.ReactNode;
  highlightColor:
    | ResponsiveStyle<ColorKeys<StyleToken<Property.BackgroundColor>>>
    | undefined;
  onClick: () => void | Promise<void>;
  isLoading?: boolean;
  features: (string | React.ReactNode)[];
};

export default function ProductCard(props: ProductCardProps) {
  const {
    highlightColor,
    title,
    heading,
    subheading,
    buttonText,
    onClick,
    isLoading,
    features,
  } = props;

  return (
    <RaisedCard padding={"0"} width={"100%"} maxWidth={"500px"}>
      <Text
        borderRadius={"large large 0 0"}
        boxShadow={"0px -5px inset rgba(0,0,0,.2)"}
        fontWeight={"bold"}
        fontSize={"larger"}
        padding={"xs"}
        backgroundColor={highlightColor}
        color={"white"}
      >
        {title}
      </Text>
      <Grid templateRows={"1fr auto"} height={"210px"} >
        <View>
          {heading}
          {subheading}
        </View>
        <Flex justifyContent={"center"} padding={"xs xxl small xxl"}>
          <RaisedButton
            fontWeight={"semibold"}
            color="white"
            isFullWidth={true}
            backgroundColor={highlightColor}
            isLoading={isLoading}
            onClick={onClick}
          >
            {buttonText}
          </RaisedButton>
        </Flex>
      </Grid>

      <Divider />

      <Grid
        templateColumns={"auto 1fr"}
        gap={"medium"}
        textAlign="left"
        padding={"medium"}
      >
        {features.map((feature) => (
          <>
            <Text color={"green.60"}>
              <FaCheckCircle />
            </Text>
            <Text fontSize={"small"}>{feature}</Text>
          </>
        ))}
      </Grid>
    </RaisedCard>
  );
}
