import { colorHEXtoRGB } from "../../../util/colorHEXtoRGB";
import { fonts } from "../components/RichTextContextProvider";
import { SlidesRequest } from "../types/batchRequestTypes";
import { SlideElement, TextElement } from "../types/slideTypes";
import { getParagraphStyles, ParagraphStyle } from "./getParagraphStyles";
import { getTextStyles, TextStyle } from "./getTextStyles";

export function deltaOpsToSlideRequests(textElement: SlideElement) {
  if (textElement.elementType !== "text") return [];

  const paragraphStyles: ParagraphStyle[] = getParagraphStyles(textElement);

  const paragraphStyleRequests = getParagraphStyleRequests(
    paragraphStyles,
    textElement
  );

  const textStyles: TextStyle[] = getTextStyles(textElement);

  const textStyleRequests = getTextStyleRequests(textStyles, textElement);

  return [...textStyleRequests, ...paragraphStyleRequests];
}

function getTextStyleRequests(
  textStyles: TextStyle[],
  textElement: TextElement & { id: string }
) {
  const customTextStyleRequests = textStyles.flatMap((style) => {
    let updateTextStyleRequests: SlidesRequest[] = [];
    if (
      style.bold ||
      style.italic ||
      style.underline ||
      style.backgroundColor ||
      style.color ||
      style.font ||
      style.size ||
      style.link
    ) {
      updateTextStyleRequests.push({
        updateTextStyle: {
          objectId: textElement.id,
          textRange: {
            type: "FIXED_RANGE",
            startIndex: style.startIndex,
            endIndex: style.endIndex,
          },
          style: {
            bold: style.bold,
            italic: style.italic,
            underline: style.underline,
            backgroundColor: style.backgroundColor
              ? {
                  opaqueColor: {
                    rgbColor: colorHEXtoRGB(style.backgroundColor),
                  },
                }
              : undefined,

            foregroundColor: style.color
              ? {
                  opaqueColor: {
                    rgbColor: colorHEXtoRGB(style.color),
                  },
                }
              : undefined,
            fontFamily: style.font ? fonts[style.font] : undefined,
            fontSize: style.size
              ? {
                  magnitude: parseInt(style.size),
                  unit: "PT",
                }
              : undefined,
            link: style.link ? { url: style.link } : undefined,
          },
          fields:
            "bold,italic,underline,backgroundColor,foregroundColor,fontFamily,fontSize,link",
        },
      });
    }

    return [...updateTextStyleRequests];
  });

  const { red, green, blue } = colorHEXtoRGB("#212529");

  const baseTextStyleRequest: SlidesRequest = {
    updateTextStyle: {
      objectId: textElement.id,
      textRange: {
        type: "ALL",
      },
      style: {
        fontFamily: "Poppins",
        // fontFamily: textElement.props.fontFace,
        fontSize: {
          magnitude: textElement.props.fontSize
            ? textElement.props.fontSize
            : 0,
          unit: "PT",
        },
        foregroundColor: {
          opaqueColor: {
            rgbColor: {
              red,
              green,
              blue,
            },
          },
        },
      },
      fields: "fontFamily,fontSize,foregroundColor",
    },
  };

  return [baseTextStyleRequest, ...customTextStyleRequests];
}

function getParagraphStyleRequests(
  paragraphStyles: ParagraphStyle[],
  textElement: {
    id: string;
  } & TextElement
) {
  return paragraphStyles.flatMap((style) => {
    let updateParagraphStyleRequests: SlidesRequest[] = [];
    if (style.alignment)
      updateParagraphStyleRequests.push({
        updateParagraphStyle: {
          objectId: textElement.id,
          textRange: {
            type: "FIXED_RANGE",
            startIndex: style.startIndex,
            endIndex: style.endIndex,
          },
          style: { alignment: style.alignment },
          fields: "alignment",
        },
      });
    if (style.list) {
      updateParagraphStyleRequests.push({
        createParagraphBullets: {
          objectId: textElement.id,
          bulletPreset:
            style.list === "bullet"
              ? "BULLET_DISC_CIRCLE_SQUARE"
              : "NUMBERED_DIGIT_ALPHA_ROMAN",
          textRange: {
            type: "FIXED_RANGE",
            startIndex: style.startIndex,
            endIndex: style.endIndex,
          },
        },
      });
    }

    return [...updateParagraphStyleRequests];
  });
}
