export const PageTitle = (newTitle) => {
    return (document.title = newTitle);
}

//Set to 3 because description is the third meta tag listed in the index.html
export const PageDescription = (newDescription) => {
    return (document.getElementsByTagName("meta")[3].content = newDescription);

}

//Set to 4 because robots is the third meta tag listed in the index.html
export const PageIndex = (newPageIndex) => {
    return (document.getElementsByTagName("meta")[4].content = newPageIndex);

}
