import { Breadcrumbs } from "@aws-amplify/ui-react";
import React from "react";

type CurriculumBreadcrumItemProps = {
  index: number;
  breadcrumbs: string[];
  onClick: (index: number) => void;
};

export function CurriculumBreadcrumItem({
  index,
  breadcrumbs,
  onClick,
}: CurriculumBreadcrumItemProps) {
  const breadcrumb = breadcrumbs[index];

  return (
    <Breadcrumbs.Item>
      <Breadcrumbs.Link
        // isCurrent={index === breadcrumbs.length - 1}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClick(index);
        }}
      >
        {breadcrumb}
      </Breadcrumbs.Link>
    </Breadcrumbs.Item>
  );
}
