import React, { useEffect, useMemo, useState } from "react";

import { Collection, Flex, Loader, Text } from "@aws-amplify/ui-react";
import useAsync from "../../../../hooks/useAsync";
import LibraryLessonCard from "../Library/LibraryLessonCard";
import { useNavigate } from "react-router-dom";
import { Lesson } from "../../../lesson/types/lessonTypes";
import SidebarCreateButton from "../../../../layouts/SidebarCreateButton";
import SearchForm from "./SearchForm";

interface LessonCollectionProps {
  listFunction: () => Promise<Lesson[] | undefined>;
  searchFunction: (search: string) => Promise<Lesson[] | undefined>;
  collectionType: "library-dashboard" | "discovery-dashboard" | "library-classroom" | "discovery-classroom";
}

export default function LessonCollection(props: LessonCollectionProps) {
  const { listFunction, searchFunction, collectionType } = props;

  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState("");
  const [search, setSearch] = useState("");
  const [isSearching, setIsSearching] = useState(false);

  // It is your responsibility to set up onClear

  // It is your responsibility to set up onClear
  const onSearch = async (search: string) => {
    setSearch(search);
    if (search === "") setIsSearching(false);
    else setIsSearching(true);
  };

  const lessonsAsync = useAsync(async () => {
    const lessons = await listFunction();
    return lessons;
  }, []);

  const lessons = useMemo(() => lessonsAsync.value, [lessonsAsync.value]);

  const searchedLessonsAsync = useAsync(async () => {
    const lessons = await searchFunction(search);
    return lessons;
  }, [search]);

  const searchedLessons = useMemo(
    () => searchedLessonsAsync.value,
    [searchedLessonsAsync.value]
  );

  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Flex direction={"column"} padding={"small 0 large 0"}>
      <SearchForm
        contentType="lesson"
        name={collectionType}
        search={searchValue}
        setSearch={setSearchValue}
        onSubmit={onSearch}
        placeholder="Search lessons..."
      />
      {/* <Flex
        justifyContent={"center"}
        grow={1}
        padding="xs"
        paddingTop={"small"}
      >
        <SearchField  
          label=""
          width={"100%"}
          size="small"
          placeholder="Search lessons..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onClear={onClear}
          onSubmit={onSearch}
        />
      </Flex> */}
      {lessonsAsync.initializing ? (
        <Loader />
      ) : lessons ? (
        <Collection
          items={isSearching && searchedLessons ? searchedLessons : lessons}
          // direction="row"
          type="grid"
          // wrap="wrap"
          gap="1rem"
          padding={"xxs"}
          templateColumns={{
            base: "100%",
            small: "minmax(0,1fr) minmax(0,1fr) ",
            medium: "minmax(0,1fr) minmax(0,1fr) minmax(0,1fr)",
            large: "minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr)",
            xl: "minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr)",
          }}
          searchNoResultsFound={
            <>
              {show && (
                <Flex
                  className={"fade-in"}
                  gap={"xxxl"}
                  alignItems={"center"}
                  alignContent={"center"}
                  width={"100%"}
                  textAlign={"center"}
                  direction={"column"}
                >
                  <Text
                    color="neutral.90"
                    fontSize={{ base: "medium", medium: "larger" }}
                    maxWidth={"500px"}
                  >
                    Create a lesson by clicking <b>Create</b> in the navigation
                    OR click here 👇
                  </Text>
                  <Flex
                    height={"60px"}
                    justifyContent="center"
                    style={{ scale: "2" }}
                  >
                    <SidebarCreateButton
                      navTitle={"Create"}
                      posthogType={"lessons-tab"}
                    />
                  </Flex>
                </Flex>
              )}
            </>
          }
        >
          {(lesson) => (
            <LibraryLessonCard
              key={lesson.id}
              lesson={lesson}
              width={"100%"}
              hasButtons={true}
              onChange={async () => await lessonsAsync.reloadAsync()}
              onClick={() => {
                navigate(`/lesson/${lesson.userId}/${lesson.id}`);
              }}
            />
          )}
        </Collection>
      ) : (
        <Flex justifyContent={"center"}>
          <Loader size="large" alignSelf={"center"} />
        </Flex>
      )}
    </Flex>
  );
}
