import { queryDatabaseItems } from "../../../services/database/queryDatabaseItems";
import { Player } from "../Types/GameTypes_dep";
import { getSortedTeams } from "./getSortedTeams";

export async function getTeamWithFewestPlayers(lobbyId: string) {
  try {
    const teams = await getSortedTeams(lobbyId);
    let numPlayersArray: number[] = [];

    if (!teams) throw new Error("Error getting teams");

    for (let i = 0; i < teams.length; i++) {
      const team = teams?.[i];
      const players = await queryDatabaseItems<Player>(
        "/player/byGroup",
        team.id
      );
      if (players) numPlayersArray.push(players.length);
      else numPlayersArray.push(0);
    }


    const minPlayers = Math.min(...numPlayersArray);
    const teamIndex = numPlayersArray.indexOf(minPlayers);

    const teamWithLeastPlayers = teams?.[teamIndex];

    if (teamWithLeastPlayers === undefined)
      throw new Error("Error getting team with least players");
    return teamWithLeastPlayers;
  } catch (err) {
    console.error(err);
    return undefined;
  }
}
