import { v4 } from "uuid";
import { getHeaderTextTemplate } from "../data/canvasDataTemplates";
import { Slide, SlideElement, Slide_old } from "../types/slideTypes";
import {
  SlideElementsTemplate,
  getTemplatedElements,
} from "./getTemplatedElements";
import pptxgen from "pptxgenjs";
export function createPageObject(lessonId: string, canvasData?: string) {
  return {
    lessonId: lessonId,
    id: "slide_" + v4(),
    canvasData: canvasData ?? getHeaderTextTemplate("", "", "#FFDD80"),
    orderIndex: 0,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  } as Slide_old;
}
export function createSlideObject(
  lessonId: string,
  elements?: SlideElement[],
  background?: pptxgen.BackgroundProps
): Slide {
  return {
    lessonId: lessonId,
    id: "slide_" + v4(),
    elements:
      elements ??
      getTemplatedElements(SlideElementsTemplate.ImageRight, {
        headerText: "",
        bodyText: "",
        imagePath: "",
      }),
    orderIndex: 0,
    background: background ?? { color: "#FFDD80" },
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  };
}

export function createSlideObjectDefault(
  lessonId: string
): Omit<Slide, "createdAt" | "updatedAt" | "id" | "orderIndex"> {
  return {
    lessonId: lessonId,
    elements: getTemplatedElements(SlideElementsTemplate.ImageRight, {
      headerText: "",
      bodyText: "",
      imagePath: "",
    }),
    background: { color: "#FFDD80" },
  };
}
