import Delta, { Op } from "quill-delta";

export function convertTextPropsToDelta(ops: Op[]): Delta {
  // const opsClone: Op[] = [...ops];
  // const delta = new Delta(opsClone);

  const delta = new Delta(ops);

  return delta;
}
