import { Button, Flex, View } from "@aws-amplify/ui-react";
import * as React from "react";

import { LessonContext } from "../../LessonContextProvider";
import useEffectOnce from "../../../../../hooks/useEffectOnce";

import RaisedButton from "../../../../../components/RaisedButton";
import SparkleIcon from "../../../../../components/icons/SparkleIcon";

import GeneratedSlideCollection from "./GeneratedSlideCollection";
import useContentSlideGenerator from "./hooks/useContentSlideGenerator";
import { Slide } from "../../../types/slideTypes";
import useUpdateEffect from "../../../../../hooks/useUpdateEffect";
import useTitleSlideGenerator from "./hooks/useTitleSlideGenerator";
import useDidYouKnowSlideGenerator from "./hooks/useDidYouKnowSlideGenerator";
import useDiscussionQuestionSlideGenerator from "./hooks/useDiscussionQuestionSlideGenerator";
import { SlidesContext } from "../../SlidesContextProvider";
import { useBatchAddSlides } from "../../../hooks/useBatchAddSlides";

export interface ISlideGeneratorProps {
  useLessonPlan: boolean;
  useExistingTopic: boolean;
  topicLocal: string;
}

export default function FullLessonGenerator(props: ISlideGeneratorProps) {
  const { useLessonPlan, useExistingTopic, topicLocal } = props;

  const { lesson } = React.useContext(LessonContext);

  const addSlideMutation = useBatchAddSlides();

  const { slideIndex, setSlidePreview } = React.useContext(SlidesContext);

  const [slides, setSlides] = React.useState<Slide[]>([]);

  const {
    connect: connectTitle,
    loading: loadingTitle,
    slide: slideTitle,
  } = useTitleSlideGenerator({
    useExistingTopic,
    lesson,
    topicLocal,
  });

  const {
    connect: connectContent,
    loading: loadingContent,
    slides: slidesContent,
  } = useContentSlideGenerator({
    useLessonPlan,
    useExistingTopic,
    lesson,
    topicLocal,
  });

  const {
    connect: connectDidYouKnow,
    loading: loadingDidYouKnow,
    slides: slidesDidYouKnow,
  } = useDidYouKnowSlideGenerator({
    useLessonPlan,
    useExistingTopic,
    lesson,
    topicLocal,
  });

  // use DiscussionQuestionsSlideGenerator
  const {
    connect: connectDiscussionQuestions,
    loading: loadingDiscussionQuestions,
    slides: slidesDiscussionQuestions,
  } = useDiscussionQuestionSlideGenerator({
    useLessonPlan,
    useExistingTopic,
    lesson,
    topicLocal,
  });

  const connect = React.useCallback(() => {
    connectTitle();
    connectContent();
    connectDidYouKnow();
    connectDiscussionQuestions();
  }, []);

  const loading = React.useMemo(
    () =>
      loadingTitle ||
      loadingContent ||
      loadingDidYouKnow ||
      loadingDiscussionQuestions,
    [
      loadingTitle,
      loadingContent,
      loadingDidYouKnow,
      loadingDiscussionQuestions,
    ]
  );

  useEffectOnce(() => {
    connect();
  });

  useUpdateEffect(() => {
    setSlides([
      slideTitle,
      ...slidesDiscussionQuestions.slice(undefined, 2),
      ...slidesContent,
      ...slidesDidYouKnow,
      ...slidesDiscussionQuestions.slice(2, undefined),
    ]);
  }, [slideTitle, slidesContent, slidesDidYouKnow, slidesDiscussionQuestions]);

  const addAllSlides = () => {
    addSlideMutation.mutateAsync({ newSlides: slides, index: slideIndex + 1 });


    setSlides([]);
    setSlidePreview(undefined);
  };

  return (
    <View>
      <Flex justifyContent={"center"}>
        <Flex justifyContent={"center"}>
          <RaisedButton
            fontSize={"small"}
            variation={"primary"}
            textAlign={"center"}
            size="small"
            color={"white"}
            backgroundColor={"orange.60"}
            gap="small"
            isLoading={loading}
            loadingText={"Generating..."}
            onClick={connect}
            data-attr="create-lesson-slides"
            data-ph-capture-attribute-type={"regenerate-full-lesson"}
            data-ph-capture-attribute-topic={lesson.topic.toLowerCase()}
            data-ph-capture-attribute-grade-level={lesson.gradeLevel}
            data-ph-capture-attribute-reading-level={lesson.readingLevel}
            data-ph-capture-attribute-language={lesson.lang}
          >
            <SparkleIcon />
            Regenerate
          </RaisedButton>
        </Flex>
        <Button
          fontSize={"small"}
          size="small"
          textAlign={"center"}
          variation={"link"}
          whiteSpace={"nowrap"}
          color={"neutral.80"}
          textDecoration={"underline"}
          hidden={loading || slides.length === 0}
          onClick={addAllSlides}
          data-attr="add-lesson-slides"
          data-ph-capture-attribute-type={"add-all-full-lesson-slides"}
          data-ph-capture-attribute-topic={lesson.topic.toLowerCase()}
          data-ph-capture-attribute-grade-level={lesson.gradeLevel}
          data-ph-capture-attribute-reading-level={lesson.readingLevel}
          data-ph-capture-attribute-language={lesson.lang}
        >
          Add All {slides.length} Slides
        </Button>
      </Flex>

      <GeneratedSlideCollection
        slides={slides}
        setSlides={setSlides}
        loading={loading}
      />
    </View>
  );
}
