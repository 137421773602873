import RestAPI from "../../../../util/RestAPI";
import { Student, PostStudentInput } from "../../types";

export async function createStudent(classId: string, nickname: string) {
  // const creatorId = await getCreatorId();

  const input: PostStudentInput = {
    classId,
    nickname,
    // authenticated: creatorId !== undefined,
  };

  try {
    const classData: Student = await RestAPI.post("/students", { body: input }).then((response) => response.json() as any as Student);
    return classData;
  } catch (err) {

    return;
  }
}
