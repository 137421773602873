import { GraphQLQuery } from "@aws-amplify/api";
import {
  CreateQuestionInput,
  CreateQuestionMutation,
  CreateQuizQuestionInput,
  CreateQuizQuestionMutation,
  QuestionType,
  Quiz,
} from "../../../API";
import { createQuestion } from "../../../graphql/mutations";
import shuffleArray from "../../../util/shuffleArray";
import { FixedQuestion } from "../components/QuestionGenerator";
import { generateClient } from "aws-amplify/api";

const _createQuizQuestion = /* GraphQL */ `
  mutation CreateQuizQuestion(
    $input: CreateQuizQuestionInput!
    $condition: ModelQuizQuestionConditionInput
  ) {
    createQuizQuestion(input: $input, condition: $condition) {
      id
    }
  }
`;

export default async function uploadGeneratedQuestions(
  quiz: Quiz,
  questions: FixedQuestion[]
) {
  const promises = questions.map((q, i) => {
    try {
      if (
        q.type !== QuestionType.TRUE_OR_FALSE &&
        q.type !== QuestionType.TYPED_ANSWER
      ) {
        // shuffle answers
        const oldAnswers = [...q.answers];
        const shuffledAnswers: string[] = shuffleArray(q.answers);
        // get new correct indices
        let shuffledIndices: number[] = [];
        for (let j = 0; j < q.correctIndices.length; j++) {
          const oldIndex = q.correctIndices[j];
          const oldAnswer = oldAnswers[oldIndex !== null ? oldIndex : 0];
          if (oldAnswer === null) continue; // TODO: fix this
          const newIndex = shuffledAnswers.indexOf(oldAnswer);
          shuffledIndices.push(newIndex);
        }
        q.answers = shuffledAnswers;
        q.correctIndices = shuffledIndices;
      } else if (q.type === QuestionType.TYPED_ANSWER) {
        // if answerIndices length not equal to answers length, then fix it
        if (q.correctIndices.length !== q.answers.length) {
          // Fix correctIndices and answers
          q.answers = q.correctIndices
            .map((index) => {
              const answer = index !== null ? q.answers[index] : null;
              return answer ? answer : "";
            })
            .filter((answer) => answer !== "");
          q.correctIndices = q.answers.map((answer) =>
            q.answers.indexOf(answer)
          );
        }
        while (q.answers.length < 4) {
          q.answers.push("");
        }
      }
      // if q.answers length is greater than 4, then fix but prioritize correct answers
      if (q.answers.length > 4) {
        const correctAnswers = q.correctIndices.map(
          (index) => q.answers[index !== null ? index : 0]
        );
        const incorrectAnswers = q.answers.filter(
          (answer) => !correctAnswers.includes(answer)
        );
        const newAnswers = [...correctAnswers, ...incorrectAnswers];
        q.answers = newAnswers.slice(0, 4); // only take first 4 answers
        q.answers = shuffleArray(q.answers);
        q.correctIndices = correctAnswers.map((answer) =>
          newAnswers.indexOf(answer)
        );
        // filter values where 0 <= index < 4
        q.correctIndices = q.correctIndices.filter((index) => {
          if (index === null) return false;
          return index >= 0 && index < 4;
        });
      }

      const client = generateClient();

      const questionInput: CreateQuestionInput = { ...q, solution: "" };

      return client
        .graphql<GraphQLQuery<CreateQuestionMutation>>({
          query: createQuestion,
          variables: {
            input: questionInput,
          },
        })
        .then(async (response: any) => {
          const question = response.data?.createQuestion;

          if (question === undefined || question === null)
            throw new Error("Question is null or undefined");

          const currentLength = quiz?.Questions?.items?.length;
          if (currentLength === undefined || currentLength === null)
            throw new Error("Current length is null or undefined");
          const sortIndex = currentLength + i;

          const quizQuestionInput: CreateQuizQuestionInput = {
            quizId: quiz?.id,
            questionId: question.id,
            sortIndex,
          };

          await client.graphql<GraphQLQuery<CreateQuizQuestionMutation>>({
            query: _createQuizQuestion,
            variables: {
              input: quizQuestionInput,
            },
          });
        });
    } catch (err) {
      throw new Error("There was an error uploading question with index" + i);
    }
  });

  await Promise.all(promises);
}
