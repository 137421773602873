import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { getLessonById } from "../../features/lesson/services/getLessonById";
import { Lesson } from "../../features/lesson/types/lessonTypes";

export default function useLessonQuery(
  lessonId: string | undefined,
  options?: UseQueryOptions<Lesson, Error, Lesson, [string, string | undefined]>
) {
  const { data: lesson, ...restQuery } = useQuery(
    ["lesson", lessonId],
    async () => {
      return await getLessonById(lessonId as string);
    },
    {
      enabled: lessonId !== undefined,
      ...options,
    }
  );

  return { lesson, ...restQuery };
}
