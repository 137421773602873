import { Image, ImageProps } from "@aws-amplify/ui-react";
import * as React from "react";
import { useQuery } from "@tanstack/react-query";
import { getUrl } from "aws-amplify/storage";

export type Props = Omit<ImageProps, "src"> & {
  s3Path:
    | string
    | (({ identityId }: { identityId?: string | undefined }) => string);
};

export function S3Image(props: Props) {
  const { s3Path, ...restProps } = props;

  const { data: url, isInitialLoading } = useQuery({
    queryKey: ["s3-image", s3Path],
    queryFn: async () => {
      return await getUrl({ path: s3Path }).then(({ url }) => url.href);
    },
    enabled: !!s3Path,
  });

  return (
    <Image
      {...restProps}
      loading="lazy"
      src={url}
      style={{
        ...restProps.style,
        opacity: isInitialLoading ? 0 : 1,
        transition: "opacity 0.2s",
      }}
    />
  );
}
