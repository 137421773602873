import { Button, Divider, Flex, Grid } from "@aws-amplify/ui-react";
import * as React from "react";
import AddIcon from "../../../components/icons/AddIcon";
import ControllerIcon from "../../../components/icons/ControllerIcon";
import PlayIcon from "../../../components/icons/PlayIcon";
import SparkleIcon from "../../../components/icons/SparkleIcon";
import useModal from "../../../hooks/useModal";
import { useNavigate } from "react-router-dom";
import { QuizContext } from "./QuizContextProvider";
import GenerateQuizForm from "../../generate/components/GenerateQuiz";
// import { duplicateQuiz } from "../../../services/quiz";

export interface IQuizEditorButtonsProps {}

export default function QuizEditorButtons(props: IQuizEditorButtonsProps) {
  const {} = props;

  const {
    setSelectedQuestionIndex,
    localQuizQuestions,
    setAddQuestionModal,
    refetchQuizQuestions,
    quiz,
    isCreator,
  } = React.useContext(QuizContext);

  const navigate = useNavigate();

  const [GenerateQuestionsModal, setShowGenerateModal] = useModal(
    {
      title: "Generate Questions",
      size: "lg",
      ReactComponent: () => (
        <GenerateQuizForm
          quiz={quiz}
          onSuccess={() => {
            setShowGenerateModal(false);
            refetchQuizQuestions();
          }}
        />
      ),
    },
    [quiz]
  );

  const playSoloGame = () => {
    navigate("/game/" + quiz?.id);
  };

  const playerMultiplayerGame = () => {
    navigate("/play/" + quiz?.id);
  };

  return (
    <>
      <GenerateQuestionsModal />
      <Flex direction={"column"} gap={"0"}>
        {isCreator && (
          <>
            <Grid
              templateColumns={{ base: "1fr", small: "1fr 1fr" }}
              padding={"small"}
              gap={"xs"}
              textAlign={"center"}
            >
              <Button
                id={"raised-btn"}
                padding={"small"}
                size="small"
                variation="primary"
                backgroundColor={"green.80"}
                gap={"0.3em"}
                onClick={() => {
                  setSelectedQuestionIndex(localQuizQuestions.length);
                  setAddQuestionModal(true);
                }}
              >
                <AddIcon />
                Add Manually
              </Button>
              <Button
                padding={"small"}
                size="small"
                variation="primary"
                id="raised-btn"
                backgroundColor={"orange.60"}
                gap={"0.3em"}
                onClick={() => {
                  setShowGenerateModal(true);
                }}
              >
                <SparkleIcon />
                Add with AI
              </Button>
              {/* <Button
                padding={"small"}
                size="small"
                variation="primary"
                id="raised-btn"
                backgroundColor={"orange.60"}
                gap={"0.3em"}
                onClick={() => {
                  if (quiz) duplicateQuiz(quiz);
                  else console.error("No quiz to duplicate");
                }}
              >
                <SparkleIcon />
                Duplicate
              </Button> */}
            </Grid>
            <Divider
              size="small"
              style={{ borderColor: "var(--amplify-colors-neutral-60" }}
            />
          </>
        )}
        <Grid
          templateColumns={"1fr 1fr"}
          padding={"small"}
          gap={"xs"}
          textAlign={"center"}
        >
          <Button
            id={"raised-btn"}
            backgroundColor={"purple.60"}
            variation="primary"
            isFullWidth={true}
            onClick={playSoloGame}
            gap={"0.3em"}
            data-attr="share-or-play"
            data-ph-capture-attribute-type={"solo-edit-quiz"}
            data-ph-capture-attribute-quiz-id={quiz?.id}
            data-ph-capture-attribute-quiz-title={quiz?.title}
            data-ph-capture-attribute-quiz-creator={quiz?.creator}
            data-ph-capture-attribute-number-of-questions={
              quiz?.Questions?.items.length
            }
            data-ph-capture-attribute-quiz-privacy={quiz?.allowReadTo}
            data-ph-capture-attribute-quiz-language={quiz?.lang}
            data-ph-capture-attribute-quiz-created-at={quiz?.createdAt}
            data-ph-capture-attribute-quiz-updated-at={quiz?.updatedAt}
          >
            <ControllerIcon />
            Solo
          </Button>

          <Button
            id={"raised-btn"}
            backgroundColor={"teal.60"}
            variation="primary"
            isFullWidth={true}
            onClick={playerMultiplayerGame}
            gap={"0.3em"}
            data-attr="share-or-play"
            data-ph-capture-attribute-type={"host-edit-quiz"}
            data-ph-capture-attribute-quiz-id={quiz?.id}
            data-ph-capture-attribute-quiz-title={quiz?.title}
            data-ph-capture-attribute-quiz-creator={quiz?.creator}
            data-ph-capture-attribute-number-of-questions={
              quiz?.Questions?.items.length
            }
            data-ph-capture-attribute-quiz-privacy={quiz?.allowReadTo}
            data-ph-capture-attribute-quiz-language={quiz?.lang}
            data-ph-capture-attribute-quiz-created-at={quiz?.createdAt}
            data-ph-capture-attribute-quiz-updated-at={quiz?.updatedAt}
          >
            <PlayIcon />
            Host
          </Button>
        </Grid>
        <Divider
          size="small"
          style={{ borderColor: "var(--amplify-colors-neutral-60" }}
        />
      </Flex>
    </>
  );
}
