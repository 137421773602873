import { ResponsiveStyle, ScrollView, StyleToken, View } from "@aws-amplify/ui-react";
import { SpaceKeys } from "@aws-amplify/ui-react/dist/types/primitives/types/theme";
import { Property } from "csstype";

import React from "react";

export default function GameLayout({
  children,
  height,
  minHeight,
}: {
  children?: React.ReactNode;
  useAuthentication?: boolean;
  height:
    | ResponsiveStyle<SpaceKeys<StyleToken<Property.Height<0 | (string & {})>>>>
    | undefined;
  minHeight:
    | ResponsiveStyle<SpaceKeys<StyleToken<Property.Height<0 | (string & {})>>>>
    | undefined;
}) {
  return (
    <View className="area" height={height} minHeight={minHeight}>
      <ul className="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>

      <ScrollView
        as="section"
        width={"100%"}
        height={"100%"}
        className={"above"}
        position={"relative"}
      >
        {/* <Flex direction={'column'} width={'100%'} alignContent={'center'} minHeight={'100dvh'}  top={0} justifyContent={'center'}> */}

        {children}

        {/* </Flex> */}
      </ScrollView>
    </View>
  );
}
