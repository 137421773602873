import { View } from "@aws-amplify/ui-react";
import * as React from "react";
import { useParams } from "react-router-dom";
import QuizContextProvider from "./QuizContextProvider";
import NotFoundPage from "../../../pages/NotFound";
import { CenteredLoader } from "../../../components/CenteredLoader";

export interface IQuizViewProps {
  children?: React.ReactNode;
}

export default function QuizView(props: IQuizViewProps) {
  const { children } = props;

  const { id } = useParams();

  return (
    <View
      height={"100%"}
    >
      <React.Suspense fallback={<CenteredLoader />}>
        {id ? (
          <QuizContextProvider id={id}>{children}</QuizContextProvider>
        ) : (
          <NotFoundPage />
        )}
      </React.Suspense>
    </View>
  );
}
