import React from "react";
import {
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import StudentClassroom from "../pages/StudentClassroom";
import GameLayout from "../layouts/GameLayout";
import AssignmentPlay from "../features/class/components/AssignmentPlay";

import ClassroomLogin from "../features/class/components/ClassroomLogin";
import StudentContext from "../features/class/context/StudentContext";
import { getStudentByIdentityId } from "../features/class/util/student/getStudentByIdentityId";
import { getIdentityId } from "../features/game/util";
import { useQuery } from "@tanstack/react-query";

export default function ClassroomRoutes() {
  // const [volume, setVolume] = useState<number>(0.2);
  // const [music, setMusic] = useState();
  // const preMuteVolumeRef = useRef<number>();
  // const musicPlayer = useRef<HTMLAudioElement>(null);
  // const gongSoundPlayer = useRef<HTMLAudioElement>(null);
  // const positiveSoundPlayer = useRef<HTMLAudioElement>(null);
  // const negativeSoundPlayer = useRef<HTMLAudioElement>(null);
  // const finishSoundPlayer = useRef<HTMLAudioElement>(null);

  // useEffect(() => {
  //   if (musicPlayer.current) {
  //     musicPlayer.current.play();
  //   }
  // }, [music]);

  // useEffect(() => {
  //   if (musicPlayer.current) {
  //     musicPlayer.current.volume = volume;
  //   }
  // }, [volume]);

  // useEffect(() => {
  //   if (gongSoundPlayer.current) {
  //     gongSoundPlayer.current.volume = volume;
  //   }
  // }, [volume]);

  // useEffect(() => {
  //   if (positiveSoundPlayer.current) {
  //     positiveSoundPlayer.current.volume = volume;
  //   }
  // }, [volume]);

  // useEffect(() => {
  //   if (negativeSoundPlayer.current) {
  //     negativeSoundPlayer.current.volume = volume;
  //   }
  // }, [volume]);

  // useEffect(() => {
  //   if (finishSoundPlayer.current) {
  //     finishSoundPlayer.current.volume = volume;
  //   }
  // }, [volume]);

  // function toggleMute() {
  //   setVolume((prev) => {
  //     if (prev > 0) {
  //       preMuteVolumeRef.current = prev;
  //       return 0;
  //     }
  //     if (preMuteVolumeRef.current) {
  //       const v = preMuteVolumeRef.current;
  //       preMuteVolumeRef.current = undefined;
  //       return v;
  //     } else return 0.02;
  //   });
  // }

  const { classId } = useParams();

  // const url = useUrl();

  const { data: student } = useQuery(
    ["student", classId],
    async () => {
      if (classId === undefined) return;
      const identityId = await getIdentityId();
      const student = await getStudentByIdentityId(classId, identityId);

      return student;
    },
    {
      retry: false,
    }
  );

  // if (isError) return <Navigate to={`/classroom/${classId}/login`} />;

  return (
    <GameLayout height={"100dvh"} minHeight={"100vh"}>
      <StudentContext.Provider value={student}>
        <Routes>
          <Route index element={<StudentClassroom />} />
          <Route path="/login" element={<ClassroomLogin />} />
          <Route
            path="/assignment/:assignmentId"
            element={
              <AssignmentPlay
              // setVolume={setVolume}
              // toggleMute={toggleMute}
              // volume={volume}
              // playStartGameSound={() => {
              //   setMusic(backgroundGameMusic);
              // }}
              // playCorrectSound={() => positiveSoundPlayer.current?.play()}
              // playIncorrectSound={() => negativeSoundPlayer.current?.play()}
              // playFinishSound={() => {
              //   finishSoundPlayer.current?.play();
              //   setMusic(backgroundEndMusic);
              // }}
              />
            }
          />
        </Routes>
      </StudentContext.Provider>
    </GameLayout>
  );
}
