import { Icon, IconProps } from "@aws-amplify/ui-react";
import React from "react";

export interface IGalleryIconProps extends IconProps {}

export default function GalleryIcon(props: IGalleryIconProps) {
  return (
    <Icon
      {...props}
      ariaLabel="Gallery"
      fill="currentcolor"
      pathData="M1.56 5.383c-0.004 -0.564 -0.016 -1.129 -0.01 -1.693 0.005 -0.468 0.488 -0.945 0.955 -0.963 0.061 -0.002 0.123 0 0.184 0 7.563 0 15.125 0.005 22.688 -0.008 0.814 -0.001 1.337 0.501 1.303 1.304 -0.031 0.748 0.002 1.498 -0.008 2.246 -0.018 1.37 -0.046 2.739 -0.073 4.108 -0.022 1.099 -0.049 2.199 -0.073 3.298 -0.025 1.136 -0.049 2.272 -0.074 3.409 -0.024 1.099 -0.05 2.199 -0.073 3.298 -0.025 1.161 -0.034 2.322 -0.083 3.482 -0.015 0.363 -0.086 0.74 -0.218 1.077 -0.145 0.372 -0.477 0.555 -0.894 0.554 -7.378 -0.001 -14.757 -0.001 -22.135 -0.001 -0.59 0 -1.05 -0.461 -1.061 -1.044 -0.021 -1.173 -0.048 -2.346 -0.073 -3.519 -0.024 -1.099 -0.049 -2.199 -0.073 -3.298 -0.025 -1.124 -0.049 -2.248 -0.074 -3.372 -0.025 -1.136 -0.049 -2.272 -0.074 -3.409 -0.025 -1.161 -0.049 -2.322 -0.074 -3.551 -0.02 -0.686 -0.041 -1.302 -0.061 -1.919m15.636 6.33c-1.349 0.821 -2.571 1.813 -3.728 2.875 -1.077 0.99 -2.082 2.059 -3.113 3.097 -0.129 0.13 -0.171 0.318 -0.43 0.104 -0.879 -0.726 -1.787 -1.416 -2.693 -2.108 -0.069 -0.053 -0.243 -0.055 -0.314 -0.005a85.941 85.941 0 0 0 -2.197 1.611c-0.087 0.066 -0.146 0.225 -0.145 0.34 0.004 0.4 0.053 0.799 0.064 1.199 0.031 1.148 0.052 2.296 0.075 3.444 0.01 0.491 0.372 0.865 0.859 0.865 5.622 0.002 11.243 0.003 16.865 -0.003 0.326 0 0.629 -0.088 0.875 -0.348 0.316 -0.335 0.231 -0.767 0.246 -1.135 0.069 -1.686 0.073 -3.376 0.085 -5.064 0.001 -0.148 -0.072 -0.337 -0.177 -0.439 -0.604 -0.582 -1.232 -1.139 -1.845 -1.711 -1.11 -1.036 -2.314 -1.946 -3.581 -2.782 -0.307 -0.203 -0.542 -0.239 -0.846 0.058m-9.676 -0.323c0.579 0.399 1.217 0.399 1.865 0.249 1.391 -0.322 2.252 -1.814 1.828 -3.143 -0.533 -1.673 -2.518 -2.401 -3.999 -1.226 -0.635 0.503 -0.943 1.21 -0.931 1.984 0.014 0.881 0.433 1.607 1.238 2.136z"
    ></Icon>
  );
}
