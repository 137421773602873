import { QuestionType } from "../API";

export function getQuestionTypeString(type: QuestionType | undefined | null) {
  switch (type) {

    case QuestionType.SINGLE_SELECT:
      return "Single Select";
    case QuestionType.MULTIPLE_SELECT:
      return "Multiple Select";
    case QuestionType.TRUE_OR_FALSE:
      return "True or False";
    case QuestionType.TYPED_ANSWER:
      return "Typed Answer";
    case QuestionType.FILLINBLANK:
      return "Fill in the Blank";
    case QuestionType.MATCH:
      return "Match";
    default:
      return "Single Select";
    //   throw new Error("Unsupported question type");
  }
}
