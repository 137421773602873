import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Slide } from "../types/slideTypes";
import { createSlide } from "../services/createSlide";
import { v4 } from "uuid";
import { useContext } from "react";
import { LessonContext } from "../components/LessonContextProvider";
import { toast } from "react-toastify";

export function useAddSlide() {
  const queryClient = useQueryClient();

  const {
    lesson: { id: lessonId },
  } = useContext(LessonContext);

  return useMutation({
    mutationKey: ["slide", "add"],
    mutationFn: async ({
      slide,
      index,
    }: {
      slide: Omit<Slide, "id" | "createdAt" | "updatedAt" | "orderIndex">;
      index: number;
    }) => {
      const slides = queryClient.getQueryData<Slide[]>(["slides", lessonId]);
      if (slides === undefined) throw new Error("slides is undefined");
      let orderIndex = 0;
      console.log({ slideIds: slides });
      if (slides === undefined) throw new Error("slides is undefined");
      if (slides.length === 0) {
        orderIndex = 0;
      } else {
        const prevSlide = slides[index - 1];
        const nextSlide = slides[index + 1];

        if (prevSlide === undefined) {
          orderIndex = nextSlide.orderIndex - 1;
        } else if (nextSlide === undefined) {
          orderIndex = prevSlide.orderIndex + 1;
        } else orderIndex = (prevSlide.orderIndex + nextSlide.orderIndex) * 0.5;
        console.log({
          prevSlide,
          nextSlide,
        });
      }
      // const orderIndex = calculateOrderIndex(slides, index);

      const tempSlide: Slide = {
        id: "temp_" + v4(),
        orderIndex: orderIndex,
        temp: true,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        ...slide,
      };

      queryClient.setQueryData<Slide>(
        ["slide", lessonId, tempSlide.id],
        tempSlide
      );
      queryClient.setQueryData<Slide[]>(["slides", lessonId], (prev) => {
        if (prev === undefined) return [tempSlide];
        const newSlides = [
          ...prev.slice(0, index),
          tempSlide,
          ...prev.slice(index),
        ];
        console.log({ newSlides });
        return newSlides;
      });

      await createSlide({ ...slide, orderIndex });
    },
    onSettled: () => {
      queryClient.invalidateQueries(["slides"]);
    },
    onError: (error: Error) => {
      toast.error(error.message);
    },
  });
}
