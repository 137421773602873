import { GSIOptions } from "../../../../types/GSIOptions";
import RestAPI from "../../../../util/RestAPI";
import getGSIUrl from "../../../../util/getGSIUrl";
import { Student } from "../../types";

export async function queryStudentsGSI(options: GSIOptions) {
  try {
    const result: Student[] = (await RestAPI.get(
      getGSIUrl("/students", options)
    ).then((res) => res.json())) as any as Student[];
    return result;
  } catch (err) {

    return;
  }
}
