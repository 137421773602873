import {
  RemoveWithPathInput,
  RemoveWithPathOutput,
  remove,
} from "aws-amplify/storage";
import { getIdentityId } from "../../features/game/util";

type RemoveS3ItemInput = {
  fileName: string;
  level: "public" | "protected" | "private";
  options?: RemoveWithPathInput["options"];
};

/***
 * Removes a file from S3
 * @param {RemoveS3ItemInput} input
 * @returns {Promise<RemoveWithPathOutput>}
 */
export async function removeS3Item(
  input: RemoveS3ItemInput
): Promise<RemoveWithPathOutput> {
  const { fileName, level, options } = input;
  if (level === "protected" || level === "private") {
    const identityId = await getIdentityId();
    return await remove({
      path: `${level}/${identityId}/${fileName}`,
      options,
    });
  } else {
    return await remove({ path: `${level}/${fileName}` });
  }
}
