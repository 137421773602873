import { S3Item } from "../../../types/S3File";
import { DynamoDBItem, DynamoDBKey } from "../../../types/DynamoDBTypes";

export type LessonKey = DynamoDBKey<Lesson, "userId" | "id">;

export type ByLessonIdKey = DynamoDBKey<Lesson, "id">;

export interface Lesson extends DynamoDBItem {
  userId: string;
  id: string;
  image: S3Item;
  topic: string;
  title: string;
  description: string;
  overview: string;
  objectives: string;
  coverage: string;
  vocabulary: string;
  gradeLevel: Level | "";
  readingLevel: Level | "";
  lang: string;
  privacy: "PUBLIC" | "PRIVATE";
}

export enum Privacy {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
}

export enum Level {
  KINDERGARDEN = "Kindergarden",
  FIRST = "1st Grade",
  SECOND = "2nd Grade",
  THIRD = "3rd Grade",
  FOURTH = "4th Grade",
  FIFTH = "5th Grade",
  SIXTH = "6th Grade",
  SEVENTH = "7th Grade",
  EIGHTH = "8th Grade",
  NINTH = "9th Grade",
  TENTH = "10th Grade",
  ELEVENTH = "11th Grade",
  TWELFTH = "12th Grade",
  COLLEGE = "College/University",
}

export enum Language {
  ENGLISH = "English",
  SPANISH = "Spanish",
  FRENCH = "French",
  GERMAN = "German",
  PORTUGUESE = "Portuguese",
  DUTCH = "Dutch",
  ITALIAN = "Italian",
  RUSSIAN = "Russian",
  CHINESE = "Chinese (Simplified)",
  JAPANESE = "Japanese",
  KOREAN = "Korean",
  ARABIC = "Arabic",
}
