import * as React from "react";

import { useRichTextContext } from "../RichTextContextProvider";

import { Button } from "@aws-amplify/ui-react";
import { MdFormatUnderlined } from "react-icons/md";

export interface UnderlineProps { }

export default function Underline(props: UnderlineProps) {
  const { } = props;
  const { setCurrentFormats, currentEditor, currentFormats } =
    useRichTextContext();

  const onUnderline = React.useCallback(() => {
    if (!currentFormats || !currentEditor) {
      return;
    }
    currentEditor.format("underline", !currentFormats.underline);
    setCurrentFormats?.(currentEditor.getFormat());
  }, [currentFormats, currentEditor]);

  return (
    <Button
      size="small"
      variation="link"
      padding={"xxs"}
      backgroundColor={currentFormats?.["underline"] ? "#eee" : undefined}
      onClick={onUnderline}
    >
      <MdFormatUnderlined />
    </Button>
  );
}
