import React from "react";
import { Flex, View, Text, Card, Link } from "@aws-amplify/ui-react";
import RaisedButton from "../components/RaisedButton";
import { useUserContext } from "../context/UserContextProvider";
import { useCookieConsent } from "../hooks/useCookieConsent";

export function CookieConsentForm() {
  const { modalState, submitConsent } = useCookieConsent();

  const { authenticated } = useUserContext();

  if (modalState.value?.closed || authenticated) return null;

  return (
    <Flex
      className="cookie-consent-container"
      padding={"small"}
      position={"fixed"}
      bottom={"0"}
      left={"0"}
      width={"100%"}
      justifyContent={"center"}
      style={{
        zIndex: 1000,
        pointerEvents: "none",
      }}
    >
      <Card
        // variation="outlined"
        id="raised-item"
        width={"100%"}
        maxWidth={"1000px"}
        backgroundColor={"white"}
        padding={"large"}
        border={"1px solid"}
        style={{
          pointerEvents: "auto",
        }}
      >
        <Flex
          justifyContent={"center"}
          direction={{ base: "column", medium: "row" }}
        >
          <View>
            <Text fontSize={"small"}>
              We use third party cookies to personalize your experience. Learn
              more in our{" "}
              <u>
                <Link isExternal href="https://www.gibbly.co/privacy-policy">
                  Privacy Policy
                </Link>
              </u>
              . By clicking Accept, you consent to our use of cookies.
            </Text>
          </View>
          <Flex gap={"xs"}>
            <RaisedButton
              flex={1}
              variation={"primary"}
              onClick={() => submitConsent(true)}
            >
              Accept
            </RaisedButton>
            <RaisedButton
              flex={1}
              variation={"destructive"}
              onClick={() => submitConsent(false)}
            >
              Decline
            </RaisedButton>
          </Flex>
        </Flex>
      </Card>
    </Flex>
  );
}
