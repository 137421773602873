import React from "react";
import { Flex, Button, View } from "@aws-amplify/ui-react";
import { FaTimes } from "react-icons/fa";

type ColorPalleteProps = {
  palette?: (string | undefined)[][];
  buttonSize?: string;
  color?: string | undefined;
  onSelectColor?: (color: string | undefined) => void;
};

export default function ColorPalette({
  palette,
  color,
  onSelectColor,
  buttonSize = "22px",
}: ColorPalleteProps) {
  if (!palette) {
    const neutrals = [undefined, "#FFF", "#CCC", , "#444", "#000"];

    const reds = ["#e60000", "#facccc", "#f06666", "#a00000", "#5c0100"];

    const oranges = ["#fe9900", "#ffeacb", "#ffc267", "#b26b01", "#653d00"];
    const yellows = ["#ffff00", "#ffffcb", "#ffff66", "#b2b300", "#656600"];
    const greens = ["#008a00", "#cbe8cc", "#66b967", "#006100", "#003700"];
    const blues = ["#0066cb", "#cbe0f5", "#67a3e1", "#0047b1", "#012966"];
    const purples = ["#9933ff", "#ebd6ff", "#c284ff", "#6b24b2", "#3e1466"];
    palette = [neutrals, reds, oranges, yellows, greens, blues, purples];
  }

  // hues in HSV format
  // const hues = [neutrals, reds, oranges, yellows, greens, blues, purples];

  const inputRef = React.useRef<HTMLInputElement | null>(null);

  return (
    <Flex direction={"column"} gap={"2px"} justifyContent={"center"}>
      <Flex gap={"2px"}>
        {palette.map((h) => (
          <Flex gap={"2px"} direction={"column"}>
            {h.map((c) => (
              <Button
                variation="link"
                width={buttonSize}
                height={buttonSize}
                padding={"0px"}
                borderRadius={"2px"}
                backgroundColor={c ?? "transparent"}
                onClick={() => onSelectColor?.(c)}
                border={"1px solid " + (c ? "transparent" : "#811")}
                boxShadow={c === color ? "0 0 0 2px inset #000" : "none"}
              >
                {c ? "" : <FaTimes color="red" />}
              </Button>
            ))}
          </Flex>
        ))}
      </Flex>

      <Flex justifyContent={"center"} padding={"xxxs 0 0 0"}>
        <Button
          size="small"
          variation="link"
          padding={"xxxs xs"}
          backgroundColor={"transparent"}
          onClick={() => {
            inputRef.current?.click();
          }}
        >
          More Colors
        </Button>
      </Flex>
      <View
        ref={inputRef}
        as={"input"}
        hidden
        type="color"
        value={color ?? "#000000"}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onSelectColor?.(e.target.value);
        }}
      ></View>
    </Flex>
  );
}
