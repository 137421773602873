import * as React from "react";
import { Heading, Text, Button } from "@aws-amplify/ui-react";
import { FaComments, FaEnvelope, FaHeart, FaUserGraduate } from "react-icons/fa";
import RaisedCard from "../components/RaisedCard";
import { Link } from "react-router-dom";


export interface ISupportCardProps { }

export default function SupportCard(props: ISupportCardProps) {
  const { } = props;

  return (
    <RaisedCard>
      <Heading id="sectionTitle">Support</Heading>

      <Text as="p">
        <a href="mailto:support@gibbly.app" target={"_blank"} rel="noreferrer">
          <Button
            fontSize="larger"
            fontWeight={"medium"}
            color={"teal.60"}
            padding={"xxs"}
            gap={"medium"}
            variation="link"
          >
            <FaEnvelope />{" "}
            <Text fontSize="small" color={"black"}>
              {" "}
              Contact Us
            </Text>
          </Button>
        </a>
      </Text>


      <Text as="p">
        <Link to="/dashboard/academy">
          <Button
            fontSize="larger"
            fontWeight={"medium"}
            color={"teal.60"}
            padding={"xxs"}
            gap={"medium"}
            variation="link"
          >
            <FaUserGraduate />{" "}
            <Text fontSize="small" color={"black"}>
              {" "}
              Training Videos
            </Text>
          </Button>
        </Link>

      </Text>


      <Text as="p">
        <a href="https://forms.office.com/r/h1Wd1JGj6e" target={"_blank"} rel="noreferrer">
          <Button
            fontSize="larger"
            fontWeight={"medium"}
            color={"teal.60"}
            padding={"xxs"}
            gap={"medium"}
            variation="link"
          >
            <FaComments />{" "}
            <Text fontSize="small" color={"black"}>
              {" "}
              Leave Feedback
            </Text>
          </Button>
        </a>
      </Text>


      <Text as="p">
        <a href="https://forms.office.com/r/iRD6hXdDaB" target={"_blank"} rel="noreferrer">
          <Button
            fontSize="larger"
            fontWeight={"medium"}
            color={"teal.60"}
            padding={"xxs"}
            gap={"medium"}
            variation="link"
          >
            <FaHeart />{" "}
            <Text fontSize="small" color={"black"}>
              {" "}
              Become a Gibbly Insider
            </Text>
          </Button>
        </a>
      </Text>





    </RaisedCard>
  );
}
