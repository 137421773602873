import * as React from "react";
import RaisedButton from "../../../../../components/RaisedButton";
import { LessonContext } from "../../LessonContextProvider";
import { SlidesContext } from "../../SlidesContextProvider";
import { createSlideObject } from "../../../util/createSlideObject";
import {
  SlideElementsTemplate,
  getTemplatedElements,
} from "../../../util/getTemplatedElements";
import { useAddSlide } from "../../../hooks/useAddSlide";

export interface IAddSlideTemplatedButtonProps {
  template: SlideElementsTemplate;
  children?: React.ReactNode;
}

export default function AddSlideTemplatedButton(
  props: IAddSlideTemplatedButtonProps
) {
  const { template } = props;

  const { lesson } = React.useContext(LessonContext);

  const addSlideMutation = useAddSlide();

  const { slideIndex } = React.useContext(SlidesContext);

  return (
    <RaisedButton
      fontSize={"small"}
      onClick={() => {
        addSlideMutation.mutateAsync({
          slide: createSlideObject(
            lesson.id,
            getTemplatedElements(template, {
              headerText: "",
              bodyText: "",
              imagePath: "",
            })
          ),
          index: slideIndex + 1,
        });
      }}
    >
      {props.children}
    </RaisedButton>
  );
}
