import React from "react";
import {
  Flex,
  Authenticator,
  Text,
  Button,
  Collection,
  Heading,
} from "@aws-amplify/ui-react";
import { PageIndex, PageTitle } from "../util/SEO";
import AddIcon from "../components/icons/AddIcon";
import ArrowLeftIcon from "../components/icons/ArrowLeftIcon";
import { useNavigate, useParams } from "react-router-dom";
import URLIcon from "../components/icons/URLIcon";
import TeacherAssignmentCard from "../features/class/components/TeacherAssignmentCard";

import useModal from "../hooks/useModal";
import AddGameMenu from "../features/class/components/AddGameMenu";
import { useClassroom } from "../features/class/hooks/useClassroom";
import RaisedButton from "../components/RaisedButton";
import { CopyToClipboard } from "../util/CopyToClipboard";
import useUrl from "../hooks/useUrl";
import GibblyLoader from "../components/GibblyLoader";
import Media from "react-media";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import useUserQuery from "../hooks/useUserQuery";
import { getClassroomByClassId } from "../features/class/util/classroom/getClassroomByClassId";

export default function ClassPage() {
  PageTitle("Classes | Gibbly");
  PageIndex("noindex");
  const navigate = useNavigate();

  const { classId } = useParams();

  const url = useUrl();

  const { user } = useUserQuery();

  // Should turn this into a useUserAcces hook
  const { data: canAccess } = useQuery(
    ["can-access", "class", classId, "identity-id", user?.identityId],
    async () => {
      const classroom = await getClassroomByClassId(classId as string);
      const canAccess = classroom.teacherId === user?.identityId;
      if (!canAccess) {
        navigate("/classes");
      }
      return classroom.teacherId === user?.identityId;
    },
    {
      suspense: true,
      enabled: !!user && !!classId,
    }
  );

  const { classroom, classroomQuery, allAssignments } = useClassroom(classId, {
    enabled: canAccess === true,
  });

  const queryClient = useQueryClient();

  const [AddGameModal, setShowModal] = useModal(
    {
      size: "lg",
      ReactComponent: () => (
        <AddGameMenu
          classId={classId}
          onComplete={() => {
            // classroomQuery.refetch();
            queryClient.invalidateQueries(["assignments", classId]);
            setShowModal(false);
          }}
        />
      ),
      title: "Add Quizzes",
      hasBackButton: true,
    },
    [classId]
  );

  function onAddGame() {
    setShowModal(true);
  }

  if (classroomQuery.isLoading) return <GibblyLoader />;

  return (
    <>
      <Authenticator>
        <AddGameModal />
        <Flex
          padding={"medium"}
          paddingTop={"large"}
          width={"100%"}
          direction={"column"}
          gap={{ base: "medium", medium: "xxxs" }}
        >
          <Flex
            justifyContent={"space-between"}
            // direction={{ base: "column", small: "row" }}
          >
            <Flex>
              <Button
                variation="link"
                size="large"
                onClick={() => navigate("/classes")}
              >
                <ArrowLeftIcon />
              </Button>
            </Flex>
            {classId && (
              <Flex textAlign={"center"} justifyContent={"center"}>
                <Media queries={{ small: { maxWidth: 480 } }}>
                  {(matches) =>
                    matches.small ? (
                      <>
                        <RaisedButton
                          gap={"xs"}
                          fontSize={{ base: "small", medium: "medium" }}
                          color={"black"}
                          aria-label="Class URL"
                          backgroundColor={"white"}
                          onClick={() =>
                            CopyToClipboard(
                              url.root + `/classroom/${classId}/login`,
                              "Classroom URL Copied"
                            )
                          }
                        >
                          <URLIcon />
                        </RaisedButton>
                      </>
                    ) : (
                      <>
                        <RaisedButton
                          gap={"xs"}
                          fontSize={{ base: "small", medium: "medium" }}
                          color={"black"}
                          backgroundColor={"white"}
                          onClick={() =>
                            CopyToClipboard(
                              url.root + `/classroom/${classId}/login`,
                              "Classroom URL Copied"
                            )
                          }
                        >
                          <URLIcon />
                          Class URL
                        </RaisedButton>
                      </>
                    )
                  }
                </Media>

                <Media queries={{ small: { maxWidth: 480 } }}>
                  {(matches) =>
                    matches.small ? (
                      <>
                        <Button
                          gap={"xs"}
                          aria-label="Add a game"
                          id="raised-btn"
                          fontSize={{ base: "small", medium: "medium" }}
                          backgroundColor={"green.60"}
                          variation="primary"
                          onClick={() => onAddGame()}
                        >
                          <AddIcon />
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          gap={"xs"}
                          id="raised-btn"
                          fontSize={{ base: "small", medium: "medium" }}
                          backgroundColor={"green.60"}
                          variation="primary"
                          onClick={() => onAddGame()}
                        >
                          <AddIcon /> Add Quiz
                        </Button>
                      </>
                    )
                  }
                </Media>
              </Flex>
            )}
          </Flex>
          {classroom ? (
            <Heading id="pageTitle">{classroom?.title}</Heading>
          ) : (
            <GibblyLoader />
          )}
          <Text paddingBottom={"large"} fontFamily={"Poppins"}>
            Assigned Quizzes
          </Text>

          {/* <ScrollView width={"100%"} grow={1}> */}
          {allAssignments ? (
            <Collection
              items={allAssignments}
              type="list"
              direction="row"
              wrap={"wrap"}
              alignItems={{ base: "center", medium: "left" }}
              searchNoResultsFound={
                <Flex justifyContent="left" paddingTop={"large"}>
                  {/* <i>
                      <Text fontWeight={"semibold"} color={"purple.80"}>
                        No assignments yet. Add a game to get started!
                      </Text>
                    </i> */}
                </Flex>
              }
            >
              {(assignment) => (
                <TeacherAssignmentCard
                  assignment={assignment}
                  key={assignment.id}
                  // onChange={() => classroomQuery.refetch()}
                  onChange={() =>
                    queryClient.invalidateQueries(["assignments", classId])
                  }
                />
              )}
            </Collection>
          ) : (
            <GibblyLoader />
          )}
          <Flex
            paddingTop={"large"}
            wrap="wrap"
            alignItems={{ base: "center", medium: "left" }}
          ></Flex>
          {/* </ScrollView> */}
        </Flex>
      </Authenticator>
    </>
  );
}
