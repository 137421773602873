import {
  Card,
  Collection,
  Divider,
  Flex,
  Grid,
  Heading,
  View,
  Text,
  ScrollView,
} from "@aws-amplify/ui-react";
import React from "react";
import { AssignmentResult } from "../../types";
import { useAssignmentResultsAnalytics } from "../../hooks/useAssignmentResultsAnalytics";
import useAsync from "../../../../hooks/useAsync";
import { getStudent } from "../../util/student/getStudent";
import { useParams } from "react-router-dom";

export default function GradeSummary({
  assignmentResults,
}: {
  assignmentResults: AssignmentResult[];
}) {
  const { highScorers, midScorers, lowScorers } =
    useAssignmentResultsAnalytics(assignmentResults);

  const { classId } = useParams();

  // create a histogram of students with marks from 0-50, 50-80, 80-100
  const lowScorerNicknames = useAsync(async () => {
    if (!classId) return;
    const nicknames = await getStudentNicknames(classId, lowScorers);
    return nicknames;
  }, [lowScorers]);

  const midScorerNicknames = useAsync(async () => {
    if (!classId) return;
    const nicknames = await getStudentNicknames(classId, midScorers);
    return nicknames;
  }, [midScorers]);

  const highScorerNicknames = useAsync(async () => {
    if (!classId) return;
    const nicknames = await getStudentNicknames(classId, highScorers);
    return nicknames;
  }, [highScorers]);

  async function getStudentNicknames(classId: string, studentIds: string[]) {
    const nicknames = await Promise.all(
      studentIds.map(async (studentId) => {
        const student = await getStudent(classId, studentId);
        return student?.nickname;
      })
    );
    return nicknames;
  }

  return (
    <>
      <Card boxShadow={"large"}>
        <Heading paddingBottom={"xs"}>Grade Summary</Heading>
        <ScrollView>
          <Grid
            templateColumns={"1fr 1fr 1fr"}
            textAlign={"center"}
            height={"300px"}
          >
            <View>
              <Text
                padding={"xxxs"}
                fontSize={{ base: "small", medium: "large" }}
                fontWeight={"bold"}
                color={"red.60"}
              >
                0-50%
              </Text>
              <Divider
                style={{ borderColor: 'var(--amplify-colors-neutral-40)' }}
              />


              {lowScorerNicknames.value && (
                <Collection
                  items={lowScorerNicknames.value}
                  searchNoResultsFound={"-"}
                  type="grid"
                  templateColumns={{ base: "1fr", medium: "1fr 1fr 1fr" }}
                  autoFlow={"column"}
                  margin={"medium"}
                  gap={"medium"}
                >
                  {(nickname) => (
                    <View key={nickname}>
                      {nickname && (
                        // <Badge color={'white'} borderRadius={"2rem"} id="raised-item" backgroundColor={"red.60"} textAlign={"center"}>
                        <Flex
                          justifyContent={"center"}
                          borderRadius={"xxl"}
                          id="raised-item"
                          color={'white'}
                          backgroundColor={'red.60'}
                          // style={{ border: "2px solid var(--amplify-colors-red-80)" }}
                          alignItems={"center"}
                          padding={'xs'}
                          grow={1}
                          fontWeight={"semibold"}
                        >
                          {nickname}
                        </Flex>
                        // </Badge>
                      )}
                    </View>
                  )}
                </Collection>
              )}
            </View>
            <View boxShadow={"-2px 0px 0px 0px var(--amplify-colors-neutral-40), 2px 0px 0px 0px var(--amplify-colors-neutral-40)"}>

              <Text
                padding={"xxxs"}
                fontSize={{ base: "small", medium: "large" }}
                fontWeight={"bold"}
                color={"orange.60"}
              >
                50-80%
              </Text>
              <Divider
                style={{ borderColor: 'var(--amplify-colors-neutral-40)' }}
              />
              {midScorerNicknames.value && (
                <Collection
                  items={midScorerNicknames.value}
                  searchNoResultsFound={"-"}
                  type="grid"
                  templateColumns={{ base: "1fr", medium: "1fr 1fr 1fr" }}
                  autoFlow={"column"}
                  margin={"medium"}
                  gap={"medium"}
                >
                  {(nickname) => (
                    <View key={nickname}>
                      {nickname && (
                        <Flex
                          justifyContent={"center"}
                          color="white"
                          borderRadius={"xxl"}
                          backgroundColor={"orange.60"}
                          id="raised-item"
                          // border={"yellow.60"}
                          // style={{ border: "2px solid var(--amplify-colors-orange-80)" }}
                          // color={'white'}
                          // backgroundColor={'yellow.80'}
                          alignItems={"center"}
                          padding={'xs'}
                          grow={1}
                          fontWeight={"semibold"}
                        >
                          {nickname}
                        </Flex>
                      )}
                    </View>
                  )}
                </Collection>
              )}
            </View>
            <View>
              <Text
                padding={"xxxs"}
                fontSize={{ base: "small", medium: "large" }}
                fontWeight={"bold"}
                color={"green.80"}
              >
                {" "}
                80-100%
              </Text>
              <Divider
                style={{ borderColor: 'var(--amplify-colors-neutral-40)' }}
              />
              {highScorerNicknames.value && (
                <Collection
                  items={highScorerNicknames.value}
                  searchNoResultsFound={"-"}
                  type="grid"
                  templateColumns={{ base: "1fr", medium: "1fr 1fr 1fr" }}
                  autoFlow={"column"}
                  margin={"medium"}
                  gap={"medium"}
                >
                  {(nickname) => (
                    <View key={nickname}>
                      {nickname && (
                        <Flex
                          justifyContent={"center"}
                          borderRadius={"xxl"}
                          color={'white'}
                          id="raised-item"
                          backgroundColor={'green.80'}
                          // style={{ border: "2px solid var(--amplify-colors-green-100)" }}
                          alignItems={"center"}
                          padding={'xs'}
                          grow={1}
                          fontWeight={"semibold"}
                        >
                          {nickname}
                        </Flex>
                      )}
                    </View>
                  )}
                </Collection>
              )}
            </View>
          </Grid>
        </ScrollView>
      </Card>
    </>
  );
}
