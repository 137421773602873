import { useMemo } from "react";

import { calculateScore } from "../util/assignmentResult/calculateScore";
import { AssignmentResult } from "../types";

export function useAssignmentResultsAnalytics(assignmentResults?: AssignmentResult[]) {


  const numberOfParticipants = useMemo(() => {
    // return the number of unique participants
    // by looking at the answerResults studentId
    // and filtering out duplicates
    if (!assignmentResults) return 0;
    const uniqueParticipants = new Set(
      assignmentResults.map((result) => result.studentId)
    );
    return uniqueParticipants.size;
  }, [assignmentResults]);

  const average = useMemo(() => {
    if (!assignmentResults || assignmentResults.length === 0) return 0;
    const total = assignmentResults.reduce((acc, result) => acc + calculateScore(result.answerResults), 0);
    return total / assignmentResults.length;
  }, [assignmentResults]);

  const highestScore = useMemo(() => {
    if (!assignmentResults) return 0;
    const highestScore = assignmentResults.reduce((acc, result) => {
      const score = calculateScore(result.answerResults);
      if (score > acc) {
        return score;
      } else {
        return acc;
      }
    }, 0);
    return highestScore;
  }, [assignmentResults]);

  const lowestScore = useMemo(() => {
    if (!assignmentResults) return 0;
    const lowestScore = assignmentResults.reduce((acc, result) => {
      const score = calculateScore(result.answerResults);
      if (score < acc) {
        return score;
      } else {
        return acc;
      }
    }, 100);
    return lowestScore;
  }, [assignmentResults]);

  const highScorers = useMemo(() => {
    // return an array of studentIds that have a score of 80 or higher
    if (!assignmentResults) return [];
    const highScoringStudents = assignmentResults.reduce((acc, result) => {
      const score = calculateScore(result.answerResults);
      if (score >= 80) {
        return [...acc, result.studentId];
      } else {
        return acc;
      }
    }, [] as string[]);
    return highScoringStudents;
  }, [assignmentResults]);

  const lowScorers = useMemo(() => {
    // return an array of studentIds that have a score of 50 or lower
    if (!assignmentResults) return [];
    const lowScoringStudents = assignmentResults.reduce((acc, result) => {
      const score = calculateScore(result.answerResults);
      if (score < 50) {
        return [...acc, result.studentId];
      } else {
        return acc;
      }
    }, [] as string[]);
    return lowScoringStudents;
  }, [assignmentResults]);

  const midScorers = useMemo(() => {
    // return an array of studentIds that have a score between 50 and 80
    if (!assignmentResults) return [];
    const midScoringStudents = assignmentResults.reduce((acc, result) => {
      const score = calculateScore(result.answerResults);
      if (score >= 50 && score < 80) {
        return [...acc, result.studentId];
      } else {
        return acc;
      }
    }, [] as string[]);
    return midScoringStudents;
  }, [assignmentResults]);
  

  return { average, numberOfParticipants, highestScore, lowestScore,  highScorers, midScorers, lowScorers };
}
