import { Text } from "@aws-amplify/ui-react";
import React from "react";
import { getQuestionTypeString } from "../../../util/getQuestionTypeString";
import { useQuestionContext } from "../context";

interface QuestionItemTypeProps {}

export default function QuestionItemType({}: QuestionItemTypeProps): JSX.Element {
  const { question } = useQuestionContext();

  return (
    <i>
      <Text fontWeight={"semibold"} paddingLeft={"xxs"} textAlign={"left"}>
        {getQuestionTypeString(question?.type)}
      </Text>
    </i>
  );
}
