import { createDatabaseItem } from "../../../services/database/createDatabaseItem";
import { Avatar, Player } from "../Types/GameTypes_dep";

export async function createPlayerGrouped(
  lobbyId: string,
  teamId: string,
  userId: string,
  nickname: string,
  avatar: Avatar
) {
  await createDatabaseItem<Player>("/player", {
    playerSessionId: lobbyId,
    groupId: teamId,
    nickname,
    avatar,
    userId,
    playerStatus: "NORMAL",
  });
}
