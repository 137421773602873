import * as React from "react";
import { Question } from "../../../../API";
import { Button } from "@aws-amplify/ui-react";
import useModal from "../../../../hooks/useModal";
import QuestionView from "../../../quiz/components/QuestionView";
import { QuestionContextProvider } from "../../../quiz/context";

export interface IQuestionNumberButtonProps {
  question: Question | null | undefined;
  qNumber: number;
}

export function QuestionNumberButton(props: IQuestionNumberButtonProps) {
  const { question, qNumber } = props;

  const [PreviewModal, setPreviewModal] = useModal(
    {
      title: `Question #${qNumber}`,
      ReactComponent: () => (
        <QuestionContextProvider questionId={question?.id as string}>
          <QuestionView question={question} />
        </QuestionContextProvider>
      ),
      hasBackButton: false,
      hasExitButton: true,
      size: "lg",
    },
    [question]
  );

  return (
    <>
      <PreviewModal />
      <Button
        key={question?.id}
        id="raised-btn"
        size="small"
        width={"25px"}
        border={"none"}
        height={"25px"}
        backgroundColor={"purple.60"}
        color={"white"}
        borderRadius={"50%"}
        onClick={() => setPreviewModal(true)}
      >
        {qNumber}
      </Button>
    </>
  );
}
