import React from "react";
import { listAssignments } from "../util/assignment";
import { sortAssignmentsByDueDate } from "../util/assignment/sortAssignmentsByProperty";
import { getClassroomByClassId } from "../util/classroom/getClassroomByClassId";
import { useQuery } from "@tanstack/react-query";

export function useClassroom(classId: string | undefined, options?: any) {
  const { data: classroom, ...classroomQuery } = useQuery(
    ["classroom", classId],
    async () => {
      if (classId === undefined) throw new Error("Class ID is undefined");
      const classroom = await getClassroomByClassId(classId);

      // // protected from unwanted access
      // if (isProtected && classroom?.teacherId !== userId) {
      //   toast.error("You do not have access to this classroom");
      //   navigate("/classes");
      //   throw new Error("You do not have access to this classroom");
      // }

      return classroom;
    },
    {
      ...options,
    }
  );

  const { data: assignments, ...assignmentsQuery } = useQuery(
    ["assignments", classId],
    async () => {
      if (classId === undefined) throw new Error("Class ID is undefined");
      const assignments = await listAssignments(classId);
      return assignments;
    },
    {
      ...options,
    }
  );

  const sortedAssignments = React.useMemo(() => {
    if (!assignments) return [];
    return sortAssignmentsByDueDate(assignments);
  }, [assignments]);

  return {
    classroom,
    classroomQuery,
    assignmentsQuery,
    allAssignments: sortedAssignments,
    openAssignments: sortedAssignments.filter((a) => a.isOpen),
  };
}
