import { TextField, Button, Flex } from "@aws-amplify/ui-react";
import React, { useState } from "react";

export default function ClassroomSettingsForm({
  onSubmit,
}: {
  onSubmit?: (name: string) => void;
}) {
  const [name, setName] = useState("");


  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        // await createClassroom(name);
        onSubmit?.(name);
      }}
    >
      <TextField
         
        label="Class Name"
        name="class-name"
        fontWeight={"bold"}
        onChange={(e) => {
          setName(e.target.value);
        }}
      />
      <Flex justifyContent={"center"} padding={"small"}>
        <Button
          id="raised-btn"
          backgroundColor={"#1a90ff"}
          color={"white"}
          type="submit"
          value={"Submit"}
        >
          Submit
        </Button>
      </Flex>
    </form>
  );
}
