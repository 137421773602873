import { Flex, Grid, View } from "@aws-amplify/ui-react";
import React, { useState } from "react";
import AnswerSelect from "./AnswerSelect";

import RaisedButton from "../../../../components/RaisedButton";
import { QuestionCard } from "./QuestionCard";
import PlayIcon from "../../../../components/icons/PlayIcon";
import useMediaQuery from "../../../../hooks/useMediaQuery";
import { MdOutlineKeyboardReturn } from "react-icons/md";
import GameHeader from "./GameHeader";
import { useGamePlayMenu } from "./GamePlayMenu_new.hooks";
import CorrectAnswerAndFeedback from "./CorrectAnswerAndFeedback";
import useLobbyContext from "../../hooks/useLobbyContext";
import { useTranslationContext } from "../../../../context/TranslationContextProvider";
import { useScreenReaderContext } from "../../../accessibility";

export interface IGamePlayMenuProps {}

export function GamePlayMenu(props: IGamePlayMenuProps) {
  const {} = props;

  const {
    gameData,
    setGameData,
    isMyTurn,
    submitAnswer,
    NextQuestion,
    question,
    isAnswered,
    getFeedback,
  } = useGamePlayMenu({});

  const { stop } = useScreenReaderContext();

  const { isUpdatingTeam } = useLobbyContext();

  const [carouselIndex, setCarouselIndex] = useState(1);
  const [submittingAnswer, setSubmittingAnswer] = useState(false);

  const { show_answer, show_solution, next, submit } =
    useTranslationContext().translations;

  const isMedium = useMediaQuery("(min-width: 768px)");

  return (
    <Grid
      width={"100%"}
      height={"100%"}
      flex={1}
      gap={"small"}
      templateRows={{
        base: `auto .5fr 30px .75fr`,
        medium: `auto 1fr 50px 1fr`,
      }}
    >
      <View>
        <GameHeader />
      </View>

      <QuestionCard question={question} />

      <Flex justifyContent={"center"} alignItems={"center"}>
        {isAnswered ? (
          <>
            <RaisedButton
              backgroundColor={"rgb(255,255,255, 0.5)"}
              size={isMedium ? "small" : undefined}
              fontSize={{ base: "xxs", medium: "medium" }}
              // padding={"xs"}
              textAlign={"center"}
              onClick={() =>
                setCarouselIndex((prev) => {
                  if (prev === 0) return 1;
                  return prev - 1;
                })
              }
            >
              {carouselIndex === 0 ? show_solution : show_answer}
            </RaisedButton>
            <>
              {isMyTurn && (
                <RaisedButton
                  backgroundColor={"white"}
                  size={isMedium ? "small" : undefined}
                  textAlign={"center"}
                  fontSize={{ base: "xxs", medium: "medium" }}
                  isLoading={isUpdatingTeam}
                  loadingText={next}
                  color={"black"}
                  gap={"xs"}
                  onClick={() => {
                    NextQuestion();
                    stop();
                  }}
                >
                  {next}
                  <PlayIcon />
                </RaisedButton>
              )}
            </>
          </>
        ) : (
          <>
            {isMyTurn && gameData.currentAnswer.length > 0 && (
              <RaisedButton
                backgroundColor={"white"}
                size={isMedium ? "small" : undefined}
                fontSize={{ base: "xxs", medium: "medium" }}
                isLoading={submittingAnswer}
                color={"black"}
                onClick={async () => {
                  setSubmittingAnswer(true);
                  await submitAnswer(gameData.currentAnswer);
                  // await handleSubmitAnswer({gameData.answerResults});
                  setSubmittingAnswer(false);
                  stop();
                }}
                gap={"xs"}
              >
                {submit} <MdOutlineKeyboardReturn />
              </RaisedButton>
            )}
          </>
        )}
      </Flex>
      <Flex padding={"small"} flex={1} height={"100%"}>
        {!isAnswered ? (
          <AnswerSelect
            question={question}
            gameData={gameData}
            isMyTurn={isMyTurn}
            onChange={(value) => {
              setGameData({ ...gameData, currentAnswer: value });
            }}
          />
        ) : (
          <CorrectAnswerAndFeedback
            question={question}
            gameData={gameData}
            setGameData={setGameData}
            getFeedback={getFeedback}
            carouselIndex={carouselIndex}
            isMyTurn={isMyTurn}
          />
        )}
      </Flex>
    </Grid>
  );
}
