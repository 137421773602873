import listUsersWithReferralCode from "../../../util/User/listUsersWithReferralCode";

export async function getReferrerId(referralCode: string) {
  try {
    const users = await listUsersWithReferralCode(referralCode);

    if (users === undefined || users.length === 0) throw new Error("No user with the referral code " + referralCode);


    if (users.length > 1)
      throw new Error("More than one user with the same referral code");

    return users[0].creatorId;
  } catch (err) {
    console.error("Failed to get referrer:", err);
    return;
  }
}
