import React, { useContext } from "react";
import { PageIndex, PageTitle } from "../util/SEO";
import { useClassroom } from "../features/class/hooks/useClassroom";
import { useParams } from "react-router-dom";
import {
  Collection,
  Flex,
  ScrollView,
  Text,
} from "@aws-amplify/ui-react";
import StudentAssignmentCard from "../features/class/components/StudentAssignmentCard";

import StudentContext from "../features/class/context/StudentContext";
import { FaUserAstronaut } from "react-icons/fa";
import GibblyLoader from "../components/GibblyLoader";

export default function StudentClassroom() {
  PageTitle("Classroom | Gibbly");
  PageIndex("noindex");

  // const navigate = useNavigate();

  const { classId } = useParams();

  const student = useContext(StudentContext);

  // const studentAsync = useAsync(async () => {
  //   if (classId === undefined) throw new Error("Class ID is undefined");
  //   const identityId = await getIdentityId();
  //   const student = await getStudentByIdentityId(classId, identityId);


  //   return student;
  // }, [classId]);

  const {classroom, classroomQuery, openAssignments} = useClassroom(classId);

  if (classroomQuery.isLoading) return <GibblyLoader/>;

  return (
    <ScrollView  >

      <Flex
        height={"100dvh"}
        minHeight={'100vh'}
        width={"100%"}
        direction={"column"}
        gap={{ base: "medium", medium: "xxxs" }}
        paddingTop={"large"}
        paddingLeft={{ base: "small", medium: "xxxl" }}
        paddingRight={{ base: "small", medium: "xxxl" }}
        paddingBottom={"large"}
      >


        <Flex
          justifyContent={{ base: "center", small: "space-between" }}
          direction={{ base: "column", small: "row" }}
          paddingBottom={"medium"}
        >
          {classroom ? (
            <Flex>
              <Flex
                id="leaderboardCard"
                padding={"xs"}
                borderRadius={"50rem"}
                backgroundColor={"rgba(255,255,255,0.3)"}
              >
                <Text fontWeight={"semibold"} fontSize={"larger"} color={"black"}>
                  {classroom.title}
                </Text>
              </Flex>
            </Flex>

          ) : (
         <GibblyLoader/>
          )}
          {student ? (
            <Flex
              id="leaderboardCard"
              // alignItems={"center"}
              padding={"xs"}
              borderRadius={"50rem"}
              backgroundColor={"rgba(255,255,255,0.3)"}
            >
              <Text fontSize={"larger"} fontWeight={"semibold"}><FaUserAstronaut /></Text>
              <Text fontWeight={"semibold"} fontSize={"larger"} color={"black"}>
                {student.nickname}
              </Text>
            </Flex>

          ) : (
           <GibblyLoader/>
          )}
        </Flex>


        {openAssignments && student ? (
          <Collection
            items={openAssignments}
            type="list"
            direction="column"
            // padding={'small'}
            // borderRadius={"xl"}
            // backgroundColor={"rgba(255,255,255,.2)"}
            wrap={"wrap"}
            alignItems={{ base: "center", medium: "left" }}
          >
            {(assignment) => (
              <StudentAssignmentCard
                assignment={assignment}
                key={assignment.id}
              />
            )}
          </Collection>
        ) : (
          <GibblyLoader/>
        )}

      </Flex>

    </ScrollView >
  );
}
