import { fetchAuthSession } from "aws-amplify/auth";
// import * as AWS from "aws-sdk";
import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import { LambdaClient, InvokeCommand } from "@aws-sdk/client-lambda";
import { getCurrentEnvironment } from "./getCurrentEnvironment";

// Initialize the AWS SDK
// AWS.config.update({ region: "us-east-1" });

// Create new service objects

export interface GetObjectParams {
  bucket: string;
  key: string;
}

async function invokeLambdaFunction(
  functionName: string,
  payload?: string | object
): Promise<any> {
  // If payload is an object, stringify it.
  if (typeof payload === "object") {
    payload = JSON.stringify(payload);
  }

  const env = await getCurrentEnvironment();

  const credentials = (await fetchAuthSession()).credentials; // current users credentials
  const lambda = new LambdaClient({
    region: "us-east-1",
    credentials,
  });

  const invokeParams = {
    FunctionName: `${functionName}-${env}`,
    Payload: payload,
  };

  try {
    const command = new InvokeCommand(invokeParams);
    const data = await lambda.send(command);
    const response: Uint8Array | undefined = data.Payload;
    // convert Uint8Array to string
    const responseString = new TextDecoder().decode(response);
    try {
      return JSON.parse(responseString);
    } catch {
      return responseString;
    }
  } catch (error) {
    console.error("Error invoking Lambda function:", error);
    throw error;
  }
}

async function getS3Object(bucket: string, key: string): Promise<string> {
  const credentials = (await fetchAuthSession()).credentials; // current users credentials
  const s3 = new S3Client({ region: "us-east-1", credentials });

  const s3Params = {
    Bucket: bucket,
    Key: key,
  };

  try {
    const command = new GetObjectCommand(s3Params);
    const data = await s3.send(command);

    if (data.Body === undefined) throw new Error("No body found in S3 object");
    // Create a blob from the data
    const byteArray = await data.Body.transformToByteArray();
    const blob = new Blob([byteArray], { type: "image/jpeg" });

    // Create a URL for the blob
    let url = URL.createObjectURL(blob);

    return url;
  } catch (error) {
    console.error("Error getting S3 object:", error, s3Params);
    throw error;
  }
}

export { invokeLambdaFunction, getS3Object };
