import * as React from "react";
import AddIcon from "../../../../components/icons/AddIcon";
import { TooltipView } from "../../../../components/TooltipView";
import useLobbyContext from "../../hooks/useLobbyContext";
import RaisedButton from "../../../../components/RaisedButton";

export interface ICreateTeamButtonProps {}

export function CreateTeamButton(props: ICreateTeamButtonProps) {
  const {} = props;

  const { addTeam, teams } = useLobbyContext();

  const [teamsLoading, setTeamsLoading] = React.useState(false);

  const canCreateTeam = React.useMemo(() => teams.length < 8, [teams.length]);

  const CreateButton = () => {
    // const [hover, setHover] = React.useState(false);

    return (
      <RaisedButton
        width={"100%"}
        variation="primary"
        backgroundColor={"green.60"}
        // backgroundColor={hover ? "green.60" : "rgba(0,0,0,.1)"}
        isDisabled={teams.length >= 8}
        textAlign={"center"}
        isLoading={teamsLoading}
        padding={"small xl medium xl"}
        // onMouseEnter={() => setHover(true)}
        // onMouseLeave={() => setHover(false)}
        onClick={async () => {
          setTeamsLoading(true);
          await addTeam();
          setTeamsLoading(false);
        }}
      >
        <AddIcon />
      </RaisedButton>
    );
  };
  return (
    // <View backgroundColor={"red"}>
    <TooltipView
      tooltipProps={{ id: "create-team-button-tooltip" }}
      tooltipChildren={canCreateTeam ? "Add Team" : "Team capacity reached"}
    >
      <CreateButton />
    </TooltipView>
    // </View>
  );
}
