import * as React from "react";
import LessonPlanForm from "../LessonPlanForm";
import SlideEditor from "./SlideEditor";
import { Flex, ScrollView } from "@aws-amplify/ui-react";
import { MenuContext, MenuState } from "../MenuContextProvider";
import RaisedButton from "../../../../components/RaisedButton";
import ArrowRightIcon from "../../../../components/icons/ArrowRightIcon";
import { FaMagic } from "react-icons/fa";
import { LessonContext } from "../LessonContextProvider";

export interface IEditorViewProps { }

export default function EditorView(props: IEditorViewProps) {
  const { } = props;

  const { menuState, setMenuState } = React.useContext(MenuContext);

  const { isOwner } = React.useContext(LessonContext);

  switch (menuState) {
    case MenuState.OVERVIEW:
      return (
        <ScrollView padding={"large"} backgroundColor={"white"}>
          <Flex direction="column" gap="small" paddingBottom={{base:"xxl", small:"small"}}>
            <LessonPlanForm rows={3} />
            {isOwner && <Flex justifyContent={"end"}>
              <RaisedButton
                variation="primary"
                size={"small"}
                backgroundColor={"orange.60"}
                gap={"small"}
                onClick={() => setMenuState(MenuState.ADD)}
                data-attr="slides-btn"
                data-ph-capture-attribute-type={"owner-below-lesson-plan"}
              >
                <FaMagic /> Slides
                <ArrowRightIcon />
              </RaisedButton>
            </Flex>}
          </Flex>
        </ScrollView>
      );
    default:
      return <SlideEditor />;
  }
}
