import { Translations } from "../../../../types/Translations";

export const translations: Translations = {
  true: "True",
  false: "False",
  select_all_that_apply: "Select all that apply",
  play: "Play",
  next: "Next",
  submit: "Submit",
  its_your_turn: "It's Your Turn",
  leaderboard: "Leaderboard",
  game: "Game",
  lobby: "Lobby",
  play_again: "Play Again",
  final_standings: "Final Standings",
  team: "Team",
  lobby_settings: "Lobby Settings",
  start_game: "Start Game",
  end_game: "End Game",
  name: "Name",
  date: "Date",
  correct: "🚀 Correct! 🚀",
  incorrect: "Oops! Try Again. 😟",
  solution: "Solution",
  type_your_answer: "Type Your Answer",
  are_you_sure: "Are You Sure?",
  you_will_leave_game: "You will leave the game",
  leave: "Leave",
  stay: "Stay",
  finished: "Finished",
  answer_key: "Answer Key",
  accepted_answers: "Accepted Answer(s)",
  start: "Start",
  continue_: "Continue",
  retry: "Retry",
  show_solution: "Show Solution",
  show_answer: "Show Answer",
  classroom: "Classroom",
  creating_player: "Creating Player",
  join_at: "Join at",
  game_pin: "Game PIN",
  yes: "Yes",
  no: "No",
  are_you_sure_you_want_to_stop_game:
    "Are you sure you want to stop the game? All teams will be sent to the lobby.",
  this_action_is_irreversible: "This action is irreversible.",
  did_you_know: "Did You Know?",
  created_using_Gibbly: "Created using Gibbly - Make your own at www.gibbly.co",
};

export default translations;
