import { v4 } from "uuid";
import { createDatabaseItem } from "../../../services/database/createDatabaseItem";
import { Lesson, Privacy } from "../types/lessonTypes";
import { getIdentityId } from "../../game/util";
import { getPreferredLang } from "../../../util/getPreferredLang";

export async function createLesson(
  lesson?: Omit<Lesson, "createdAt" | "updatedAt" | "id" | "privacy">
) {
  const userId = await getIdentityId();

  const input = lesson ?? {
    userId,
    image: {
      key: "",
      identityId: "",
      alt: "",
    },
    topic: "",
    title: "",
    description: "",
    overview: "",
    objectives: "",
    coverage: "",
    vocabulary: "",
    gradeLevel: "",
    readingLevel: "",
    lang: getPreferredLang(),
  };

  const lessonItem: Omit<Lesson, "createdAt" | "updatedAt"> = {
    id: "lesson_" + v4(),
    privacy: Privacy.PRIVATE,
    ...input,
  };



  
  try {
    await createDatabaseItem<Lesson>("/lessons", lessonItem);
    return lessonItem;
  } catch (err) {

    throw err;
  }
}
