import { UseQueryOptions } from "@tanstack/react-query";
import { Referral } from "../types/referralTypes";
import { listReferrals } from "./listReferrals";

export function listReferralsQuery(
  creatorId?: string
): UseQueryOptions<Referral[]> {
  return {
    queryKey: ["referrals", { creatorId }],
    queryFn: async () => await listReferrals(creatorId as string),
    enabled: !!creatorId,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    staleTime: 0,
  };
}
