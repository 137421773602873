import { Lesson } from "../types/lessonTypes";
import { queryDatabaseItems } from "../../../services/database/queryDatabaseItems";
import { getIdentityId } from "../../game/util";

export async function queryLessons(userId?: string) {

    if (userId === undefined) userId = await getIdentityId()

    const lesson = await queryDatabaseItems<Lesson>("/lessons", userId)

    return lesson
}