import { useMemo } from "react";
import useAsync from "../../../hooks/useAsync";

import { listAssignmentResultsForStudent } from "../util/assignmentResult/listAssignmentResultsForStudent";

export function useAssignmentResultsForStudent(
  assignmentId?: string,
  studentId?: string
) {
  const resultsAsync = useAsync(async () => {
    try {

      if (!assignmentId || !studentId)
        throw new Error(`Missing assignmentId or studentId`);
      const results = await listAssignmentResultsForStudent(
        assignmentId,
        studentId
      );
      return results;
    } catch (error) {

      return [];
    }
  }, [assignmentId, studentId]);

  const hasIncomplete = useMemo(() => {
    if (!resultsAsync.value) return false;
    return resultsAsync.value.some((result) => !result.complete);
  }, [resultsAsync.value]);

  const incomplete = useMemo(() => {
    if (!resultsAsync.value) return null;
    return resultsAsync.value.find((result) => !result.complete);
  }, [resultsAsync.value]);

  const highestMark = useMemo(() => {
    if (resultsAsync.value === undefined) return undefined;
    return resultsAsync.value.reduce((prev, curr) => {
      // get total answerResults that have isCorrect = true
      const correctAnswerResults = curr.answerResults.filter(
        (answerResult) => answerResult.isCorrect
      );
      // calculate mark
      const mark =
        (correctAnswerResults.length / curr.answerResults.length) * 100;

      if (mark > prev) return mark;
      return prev;
    }, 0);
  }, [resultsAsync.value]);

  return { ...resultsAsync, hasIncomplete, incomplete, highestMark };
}
