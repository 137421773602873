import React, { useState } from "react";
import { Button, Flex, Text, TextField } from "@aws-amplify/ui-react";
import { FaCheck, FaPen } from "react-icons/fa";

interface EditableTextProps {
  value?: string;
  onSubmit: (newText: string) => Promise<void>;
}

export default function EditableText({
  value = "",
  onSubmit,
}: EditableTextProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [editText, setEditText] = useState(value);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleConfirm = async () => {
    await onSubmit(editText);
    setIsEditing(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditText(event.target.value);
  };

  if (isEditing) {
    return (
      <Flex padding={0} margin={0} >
        <TextField fontFamily={"Poppins"} size="small" width={'180px'} variation="quiet" value={editText} onChange={handleChange} label={undefined} />
        <Button
          onClick={async () => {
            await handleConfirm();
          }}
          padding={"0"}
          variation="link"
          color={"black"}
          fontSize={"xs"}
          size="small"
        >
          <FaCheck />
        </Button>
      </Flex>
    );
  }

  return (
    <Flex>
      <Text fontFamily={"Poppins"} paddingLeft={'small'} paddingTop="xxxs" fontSize={'small'}>{value}</Text>
      <Button
        onClick={handleEdit}
        padding={"xxs"}
        fontSize={"xs"}
        size="small"
        gap={"medium"}
        variation="link"
        color={"black"}
      >
        <FaPen />
      </Button>
    </Flex>
  );
}


