import { SelectField, SelectFieldProps } from "@aws-amplify/ui-react";
import React from "react";
import { getPreferredLang } from "../util/getPreferredLang";
import { languageOptions } from "../features/translations";

type LanguageSelectProps = Omit<
  SelectFieldProps,
  "onChange" | "label" | "value"
> & {
  value?: string | null;
  onChange?: (lang: string) => void;
  label?: React.ReactNode;
  disableList?: string[];
};

export default function LanguageSelect({
  value = getPreferredLang(),
  label = "Language",
  disableList = [
    "ar",
    "es",
    "de",
    "zh",
    "zh-yue",
    "hi",
    "he",
    "ja",
    "it",
    "id",
    "pt",
    "ko",
    "ru",
    "uk",
    "nb",
    "nl",
    "so",
  ],
  onChange,
  ...props
}: LanguageSelectProps) {
  return (
    <SelectField
      label={label}
      size="small"
      value={value?.split("-")[0]}
      onChange={(e) => {
        onChange?.(e.target.value);
      }}
      {...props}
    >
      {Object.entries(languageOptions).map(([value, label]) =>
        disableList.includes(value) ? null : (
          <option key={value} value={value}>
            {label}
          </option>
        )
      )}
    </SelectField>
  );
}
