import QuizPDFRenderer from "./QuizPDFRenderer";
import { isMobile } from "react-device-detect";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { Button, Flex, Grid, View } from "@aws-amplify/ui-react";
import React from "react";
import { Quiz } from "../../../API";
import { useTranslationContext } from "../../../context/TranslationContextProvider";

type QuizPDFProps = {
  quiz: Quiz;
  quizQuestions?: any[];
};

// Create Document Component
export default function QuizPDF({ quiz, quizQuestions }: QuizPDFProps) {
  const { translations } = useTranslationContext();

  return (
    <>
      {isMobile ? (
        <Grid
          templateColumns={{ base: "1fr", large: "1fr 1fr 1fr" }}
          padding={"large"}
          columnGap="1rem"
          rowGap="1rem"
        >
          <PDFDownloadLink
            document={
              <QuizPDFRenderer
                quiz={quiz}
                quizQuestions={quizQuestions}
                translations={translations}
              />
            }
            fileName={`${quiz.title
              .split("")
              .filter((e) => e.trim().length)
              .join("")}.pdf`}
          >
            Download Quiz PDF
          </PDFDownloadLink>
        </Grid>
      ) : (
        <Grid>
          <Flex>
            <View padding={"large"}>
              <View
                as={PDFDownloadLink}
                document={
                  <QuizPDFRenderer
                    quiz={quiz}
                    quizQuestions={quizQuestions}
                    translations={translations}
                  />
                }
                fileName={`${quiz.title
                  .split("")
                  .filter((e) => e.trim().length)
                  .join("")}.pdf`}
              >
                <Button
                  id={"raised-btn"}
                  backgroundColor={"teal.60"}
                  gap={"small"}
                  variation="primary"
                  data-attr="share-or-play"
                  data-ph-capture-attribute-type={"download-pdf"}
                  data-ph-capture-attribute-quiz-id={quiz?.id}
                  data-ph-capture-attribute-quiz-title={quiz?.title}
                  data-ph-capture-attribute-quiz-creator={quiz?.creator}
                  data-ph-capture-attribute-number-of-questions={
                    quiz?.Questions?.items.length
                  }
                  data-ph-capture-attribute-quiz-privacy={quiz?.allowReadTo}
                  data-ph-capture-attribute-quiz-language={quiz?.lang}
                  data-ph-capture-attribute-quiz-created-at={quiz?.createdAt}
                  data-ph-capture-attribute-quiz-updated-at={quiz?.updatedAt}
                >
                  Download PDF
                </Button>
              </View>
            </View>
          </Flex>
          <PDFViewer width="100%" height={"700"}>
            <QuizPDFRenderer
              quiz={quiz}
              quizQuestions={quizQuestions}
              translations={translations}
            />
          </PDFViewer>
        </Grid>
      )}
    </>
  );
}
