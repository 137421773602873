import { Button, Flex, Text, Grid } from "@aws-amplify/ui-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import ThreadsIcon from "../components/icons/ThreadsIcon";
import { FaEnvelope, FaFacebook, FaInstagram, FaLinkedin, FaPinterest, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

export default function Footer({ area }: { area?: string }) {

  const navigate = useNavigate()

  return (
    <Flex
      // className="hidden"
      area={area}
      as="section"
      style={{ backgroundImage: "linear-gradient(to top left, var(--amplify-colors-teal-100) ,var(--amplify-colors-teal-80))" }}
      boxShadow="0px 8px rgba(0, 0, 0, 0.2) inset, 0px 0px 4px rgba(0, 0, 0, 0.15)"
      textAlign="center"
      direction={'column'}
      alignItems={'center'}
      padding={{ base: 'small', medium: 'large' }}
      paddingLeft={'xxl'}
      paddingRight={'xxl'}
      justifyContent={'center'}
      gap={"xxxs"}
      height={'70px'}
    >

      <Grid templateColumns={'1fr 1fr'} templateRows={"1fr"} padding={'zero'} width={'100%'}>
        <Flex
          justifyContent={"left"}
          alignItems={"center"}
          columnGap={{ base: "small", medium: "large" }}
          rowGap={'zero'}
          width={'100%'}
          wrap={"wrap"}
          padding={'xxs'}

        >
          <a href="https://twitter.com/GibblyCo" aria-label="Follow us on X (Twitter)" target={"_blank"} rel='noreferrer'>
            <Text color={"white"} fontSize={{ base: "xs", medium: "medium" }}>
             <FaXTwitter/>
            </Text>
          </a>

          <a href="https://www.facebook.com/Gibbly.co/" aria-label="Follow us on Facebook" target={"_blank"} rel='noreferrer'>
            <Text color={"white"} fontSize={{ base: "xs", medium: "medium" }}>
              <FaFacebook/>
            </Text>
          </a>

          <a href="https://www.instagram.com/Gibbly.co/" aria-label="Follow us on Instagram" target={"_blank"} rel='noreferrer'>
            <Text color={"white"} fontSize={{ base: "xs", medium: "medium" }}>
             <FaInstagram/>
            </Text>
          </a>

          <a href="https://www.threads.net/@gibbly.co" aria-label="Follow us on Threads" target={"_blank"} rel='noreferrer'>
            <Text color={"white"} fontSize={{ base: "xs", medium: "medium" }}>
              <ThreadsIcon />
            </Text>
          </a>


          <a href="https://www.youtube.com/@GibblyCo" aria-label="Follow us on YouTube" target={"_blank"} rel='noreferrer'>
            <Text color={"white"} fontSize={{ base: "xs", medium: "medium" }}>
              <FaYoutube/>
            </Text>
          </a>

          <a href="https://www.pinterest.ca/GibblyCo/" aria-label="Follow us on Pinterest" target={"_blank"} rel='noreferrer'>
            <Text color={"white"} fontSize={{ base: "xs", medium: "medium" }}>
              <FaPinterest/>
            </Text>
          </a>

          <a href="https://www.linkedin.com/company/gibbly/" aria-label="Follow us on LinkedIn" target={"_blank"} rel='noreferrer'>
            <Text color={"white"} fontSize={{ base: "xs", medium: "medium" }}>
              <FaLinkedin/>
            </Text>
          </a>

          <a href="mailto:support@gibbly.app" aria-label="Email us" target={"_blank"} rel='noreferrer'>
            <Text color={"white"} fontSize={{ base: "xs", medium: "medium" }}>
            <FaEnvelope/>
            </Text>
          </a>

        </Flex>
        <Flex justifyContent={"right"} columnGap={{ base: 'xs', medium: 'medium' }}>
          {/* <Button fontSize={'small'} color={'white'} textDecoration={'underline'} variation='link' onClick={() => navigate("/faq")}>FAQ</Button> */}
          <Button fontSize={{ base: "xxxs", medium: "xs" }} color={'white'} padding='zero' textDecoration={'underline'} variation='link' onClick={() => navigate("/privacy-policy")}>Privacy Policy</Button>
          <Button fontSize={{ base: "xxxs", medium: "xs" }} color={'white'} padding='zero' textDecoration={'underline'} variation='link' onClick={() => navigate("/terms-of-service")}>Terms of Service</Button>
        </Flex>
      </Grid>


      {/* Turn back on when we have a company name, social handles and the FAQ, Privacy, Terms set up */}
      <Flex justifyContent={{ base: 'center', medium: 'space-around' }} padding={'zero'}
        width={'100%'}>





      </Flex>

      <Text fontSize={{ base: "xs", medium: "small" }} textAlign="center" color={"white"}>
        © 2024 Gibbly Inc
      </Text>

    </Flex>
  );
}
