import { GraphQLQuery } from "@aws-amplify/api";
import { generateClient } from "aws-amplify/api";
import { QuizzesByCreatorQuery } from "../../../API";
import { quizIdsByCreator } from "../../../services/custom-queries";
import getCreatorId from "../../../util/GetCreatorId";

export default async function fetchPaginatedListUsersQuizzes(
  nextToken?: string
) {
  const creator = await getCreatorId();
  const client = generateClient();

  const quizData = await client.graphql<GraphQLQuery<QuizzesByCreatorQuery>>({
    query: quizIdsByCreator,
    variables: {
      creator,
      limit: 10,
      sortDirection: "DESC",
      nextToken,
    },
  });
  // const quizData = await client.graphql<GraphQLQuery<QuizzesByCreatorQuery>>(
  //   graphqlOperation(quizIdsByCreator, {
  //     creator,
  //     limit: 10,
  //     sortDirection: "DESC",
  //     nextToken,
  //   })
  // );


  const list = quizData.data?.quizzesByCreator;

  const quizIds = list?.items.map((quiz) => quiz?.id);
  // if quizIds is undefined or if any element of quizIds is undefined, throw an error
  if (!quizIds) {
    throw new Error("Quiz ids are undefined");
  }
  return { quizIds, nextToken: list?.nextToken };
}
