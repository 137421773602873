import { Flex, Text, TextField } from "@aws-amplify/ui-react";
import * as React from "react";
import { GameData } from "../../Types/GameTypes";
import { useMemo } from "react";
import useUpdateEffect from "../../../../hooks/useUpdateEffect";
import { useTranslationContext } from "../../../../context/TranslationContextProvider";

export interface ITypedAnswerViewProps {
  gameData: GameData;
  setAnswer: React.Dispatch<React.SetStateAction<string | number[]>>;
  onChange?: (answer: string) => void;
  isMyTurn?: boolean;
}

export function TypedAnswerView(props: ITypedAnswerViewProps) {
  const { gameData, setAnswer, onChange, isMyTurn } = props;

  const answer = useMemo(
    () => gameData.currentAnswer,
    [gameData.currentAnswer]
  );

  const isAnswered = useMemo(() => {
    return gameData.questionIndex + 1 === gameData.answerResults.length;
  }, [gameData.questionIndex, gameData.answerResults]);

  const [value, setValue] = React.useState<string>(answer as string);

  useUpdateEffect(() => {
    if (isMyTurn === false) return;
    setAnswer(value);
    if (onChange) onChange(value);
  }, [value]);

  useUpdateEffect(() => {
    if (isMyTurn) return;
    setValue(answer as string);
  }, [gameData]);

  const { type_your_answer } = useTranslationContext().translations;

  return (
    <Flex justifyContent={"center"} alignContent={"center"} width={"100%"}>
      <TextField
         
        label={
          <Text
            fontSize={{ base: "smaller", medium: "large" }}
            color={"purple.20"}
          >
            {type_your_answer}:
          </Text>
        }
        inputStyles={{
          color: "purple.20",
          fontSize: { base: "smaller", medium: "large" },
          backgroundColor: "transparent",
        }}
        fontWeight={"bold"}
        color="red"
        rowGap={"medium"}
        id="leaderboardCard"
        style={{ border: "0px" }}
        value={value}
        isDisabled={isAnswered || (isMyTurn !== undefined && !isMyTurn)}
        onChange={(e) => setValue(e.target.value)}
      />
    </Flex>
  );
}
