import getUser from "../util/User/getUser";
import useAsync from "./useAsync";

export default function useUser(creator: string | null | undefined) {
  const user = useAsync(async () => {
    if (!creator) throw new Error("No creator provided");
    const creatorId = `${creator}::${creator}`;
    return await getUser(creatorId);
  }, [creator]);
  return { ...user };
}
