import { GSIOptions } from "../types/GSIOptions";

export default function getGSIUrl(root: string, options: GSIOptions): string {
  const hasSortKey =
    options.gsiSortKeyName && options.gsiSortKeyValue && options.gsiSortKeyType;

  const {
    indexName,
    gsiPartitionKeyName,
    gsiPartitionKeyValue,
    gsiPartitionKeyType,
    gsiSortKeyName,
    gsiSortKeyValue,
    gsiSortKeyType,
  } = options;
  const url = `${root}/${indexName}/${gsiPartitionKeyName}/${gsiPartitionKeyType}/${gsiPartitionKeyValue}${
    hasSortKey ? `/${gsiSortKeyName}/${gsiSortKeyType}/${gsiSortKeyValue}` : ""
  }`;

  return url;
}
