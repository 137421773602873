import React from "react";


import { Assignment } from "../types";
import DateTimePicker from "./DateTimePicker";
import { Flex, SwitchField } from "@aws-amplify/ui-react";
import RaisedButton from "../../../components/RaisedButton";
import { updateAssignment } from "../util/assignment";

export default function AssignmentSettings({
  assignment,
  onSubmit
}: {
  assignment: Assignment;
  onSubmit?: () => void
}) {

  const [open, setOpen] = React.useState<boolean>(assignment.isOpen)
  const [dueDate, setDueDate] = React.useState<number>(assignment.dueDate)

  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  async function handleDone() {
    setIsLoading(true)
    await updateAssignment(assignment, { isOpen: open, dueDate })
    onSubmit?.()
    setIsLoading(false)
  }

  return (
    <Flex direction={'column'} padding={'small'}>
      <i><DateTimePicker dateName={"Due Date:"} value={new Date(dueDate)} onChange={date => setDueDate(date.getTime())} /></i>
      <Flex justifyContent={'space-between'}>
        <SwitchField trackCheckedColor={'#1a90ff'} label={open ? "Open" : "Closed"} labelPosition={'end'} isChecked={open} onChange={e => setOpen(e.target.checked)} />
        <RaisedButton color={"white"} onClick={async () => await handleDone()} backgroundColor={'#1a90ff'} isLoading={isLoading}>Done</RaisedButton>
      </Flex>
    </Flex>
  );
}

