import { Tabs } from "@aws-amplify/ui-react";
import * as React from "react";
import { useQuestionContext } from "../context";
import { AudioUploader, ReadAloudCreator } from "../../audio";

export interface IAudioMenuProps {}

export function AudioMenu({}: IAudioMenuProps) {
  const { updateQuestion, question } = useQuestionContext();

  return (
    <Tabs.Container defaultValue="audio">
      <Tabs.List defaultValue="audio">
        <Tabs.Item value="audio">Audio</Tabs.Item>
        <Tabs.Item value="read-aloud">Read Aloud</Tabs.Item>
      </Tabs.List>
      <Tabs.Panel value="audio">
        <AudioUploader
          audio={question?.audio}
          onUploadSuccess={async ({ key, identityId }) => {
            await updateQuestion({
              audio: { key, identityId },
            });
          }}
          onRemoveAudio={async () => {
            await updateQuestion({
              audio: null,
            });
          }}
        />
      </Tabs.Panel>
      <Tabs.Panel value="read-aloud">
        <ReadAloudCreator />
      </Tabs.Panel>
    </Tabs.Container>
  );
}
