import { Icon } from '@aws-amplify/ui-react'
import React from 'react'

export default function DeleteIcon() {
    return (
        <Icon ariaLabel="Delete" fill="currentcolor"
      pathData="M14.842 22.547c-2.536 0 -5.041 -0.012 -7.546 0.007 -0.803 0.006 -1.637 -0.711 -1.738 -1.504 -0.126 -0.98 -0.251 -1.961 -0.379 -2.941 -0.146 -1.113 -0.29 -2.226 -0.443 -3.337 -0.141 -1.019 -0.304 -2.034 -0.435 -3.054 -0.104 -0.809 -0.183 -1.622 -0.253 -2.435 -0.027 -0.32 0.235 -0.567 0.552 -0.568 0.839 -0.002 1.679 -0.001 2.527 -0.001l1.135 10.787h2.403l-1.129 -10.769h5.312c-0.025 0.42 -0.042 0.842 -0.078 1.262 -0.026 0.308 -0.088 0.612 -0.115 0.92 -0.09 1.033 -0.167 2.067 -0.256 3.099a199.917 199.917 0 0 1 -0.253 2.721c-0.076 0.76 -0.169 1.519 -0.248 2.279 -0.016 0.154 -0.002 0.312 -0.002 0.501 0.688 0 1.358 0.007 2.027 -0.012 0.063 -0.002 0.167 -0.166 0.176 -0.262 0.076 -0.805 0.129 -1.613 0.198 -2.419 0.079 -0.918 0.166 -1.835 0.252 -2.752 0.062 -0.659 0.127 -1.318 0.189 -1.977 0.086 -0.917 0.167 -1.835 0.255 -2.752 0.019 -0.195 0.058 -0.388 0.092 -0.606 0.045 -0.005 0.115 -0.019 0.185 -0.019 0.758 -0.002 1.516 -0.003 2.273 0 0.358 0.001 0.625 0.245 0.6 0.598 -0.045 0.649 -0.116 1.297 -0.192 1.944 -0.071 0.603 -0.161 1.203 -0.245 1.804 -0.101 0.713 -0.209 1.424 -0.306 2.137 -0.073 0.541 -0.128 1.085 -0.2 1.627 -0.099 0.744 -0.211 1.487 -0.309 2.231 -0.072 0.542 -0.14 1.084 -0.193 1.628 -0.08 0.827 -0.459 1.516 -1.376 1.811a1.016 1.016 0 0 1 -0.301 0.047c-0.716 0.004 -1.431 0.002 -2.179 0.002zm-2.021 -15.853c-3.409 0 -6.787 0 -10.165 0 -0.491 0 -0.698 -0.206 -0.698 -0.697 0 -0.716 -0.001 -1.431 0 -2.147 0.001 -0.401 0.232 -0.63 0.638 -0.63C4.364 3.22 6.132 3.221 7.9 3.221h0.368c-0.063 -0.368 -0.151 -0.713 -0.176 -1.063 -0.03 -0.423 0.081 -0.516 0.498 -0.516 2.273 0 4.546 0 6.819 0 0.55 0 0.75 0.25 0.622 0.783 -0.059 0.248 -0.124 0.494 -0.2 0.796h0.348c1.747 0 3.494 0.003 5.24 -0.002 0.551 -0.001 0.84 0.243 0.818 0.821 -0.025 0.662 -0.005 1.326 -0.006 1.989 0 0.446 -0.219 0.665 -0.666 0.665 -2.904 0 -5.809 0 -8.744 0z">
      
         </Icon>
  )
}