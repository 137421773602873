import {
  Flex,
  Grid,
  Image,
  Link,
  Placeholder,
  ScrollView,
  Text,
  View,
} from "@aws-amplify/ui-react";
import React from "react";
import { useAutoPickedImageListMutation } from "../../../hooks/useAutoPickedImageListMutation";
import RaisedButton from "../../../components/RaisedButton";
import RefreshIcon from "../../../components/icons/RefreshIcon";
import { uploadProtectedImageFromURL } from "../../quiz/utils/uploadProtectedImagFromURL";
import { S3ObjectProtected } from "../../../API";
import { ImageDimensions } from "../../quiz/components/ImageGallery";
import useEffectOnce from "../../../hooks/useEffectOnce";
import { FaExclamationCircle } from "react-icons/fa";

type ImageAutoPickerProps = {
  text?: string;
  onUpload: (
    image: S3ObjectProtected,
    url: string,
    dimensions: ImageDimensions
  ) => void | Promise<void>; 
};

export default function AutoPickedImageCollection(props: ImageAutoPickerProps) {
  const { text, onUpload } = props;

  const { images, isLoading, isError, error, autoPickImageList } =
    useAutoPickedImageListMutation();

  useEffectOnce(() => {
    autoPickImageList(text ?? "");
  });

  const [selectedIndex, setSelectedIndex] = React.useState<number | undefined>(
    undefined
  );

  if (isError)
    return (
      <Text width={"100%"} textAlign={"center"}>
        <FaExclamationCircle /> {error.message} <FaExclamationCircle />
      </Text>
    );

  return (
    <Flex direction={"column"}>
      <ScrollView height={"60vh"}>
        <Grid templateColumns={{ base: "1fr 1fr", medium: "1fr 1fr 1fr" }}>
          {isLoading
            ? // an array from 0-30
              // map over it
              // return a placeholder
              Array.from({ length: 30 }).map((_, i) => (
                <View key={i} style={{ aspectRatio: "16/9" }} padding={"xs"}>
                  <Placeholder
                    width={"100%"}
                    height={"100%"}
                    style={{ aspectRatio: "16/9" }}
                  />
                </View>
              ))
            : images?.map((image, i) => (
                <Flex key={i} padding={"xs"} direction={"column"} gap={"0"}>
                  <Image
                    key={i}
                    id="elevate"
                    width={"100%"}
                    loading="lazy"
                    src={image.previewSrc}
                    alt={image.alt}
                    objectFit={"cover"}
                    borderRadius={"small"}
                    opacity={selectedIndex === i ? 0.5 : 1}
                    style={{
                      aspectRatio: "16/9",
                      cursor: selectedIndex ? "not-allowed" : "pointer",
                    }}
                    onClick={async () => {
                      if (selectedIndex !== undefined) return;
                      setSelectedIndex(i);
                      await uploadProtectedImageFromURL(
                        image.src,
                        async (s3Image, url) => {
                          await onUpload(
                            { ...s3Image, alt: image.alt } as S3ObjectProtected,
                            url.toString(),
                            {
                              width: image.width,
                              height: image.height,
                            }
                          );
                        }
                      );
                    }}
                  />
                  <Text fontSize={{ base: "xxs", medium: "xs" }}>
                    <Link href={image.pageUrl} isExternal>
                      {image.author}
                    </Link>
                    -
                    <Link href={`https://${image.site}.com/`} isExternal>
                      {image.site}
                    </Link>
                  </Text>
                </Flex>
              ))}
        </Grid>
      </ScrollView>
      <Flex justifyContent={"center"} alignItems={"center"}>
        <RaisedButton
          isLoading={isLoading}
          size="small"
          fontSize={"smaller"}
          loadingText="Hold on while we pick some images..."
          onClick={() => autoPickImageList(text ?? "")}
        >
          <RefreshIcon />
        </RaisedButton>
      </Flex>
    </Flex>
  );
}
