import { fetchUserAttributes } from "aws-amplify/auth";
import RestAPI from "../../../util/RestAPI";
import { Organization } from "../types/Organization";

export default async function getUserOrg(creatorId?: string) {
  try {
    if (creatorId) {
      const org: Organization = (await RestAPI.get(
        `/users/${creatorId}/org`
      ).then((res) => res.json())) as any as Organization;
      return org;
    } else {
      const attributes = await fetchUserAttributes();

      const cId = `${attributes.sub}::${attributes.sub}`;
      const org: Organization = (await RestAPI.get(`/users/${cId}/org`).then(
        async (res) => await res.json()
      )) as any as Organization;
      return org;
    }
  } catch (err) {
    throw err;
  }
}
