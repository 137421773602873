import { Flex, Link, Text, TextField, Heading } from "@aws-amplify/ui-react";
import React from "react";
import { RaisedButton } from "../../../components";
import {
  confirmResetPassword,
  ConfirmResetPasswordInput,
  resetPassword,
  ResetPasswordInput,
  ResetPasswordOutput,
} from "aws-amplify/auth";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

type Props = {
  output: ResetPasswordOutput | undefined;
  username: string;
};

export function ConfirmResetPasswordForm({ output, username }: Props) {
  const navigate = useNavigate();

  const [formData, setFormData] = React.useState<ConfirmResetPasswordInput>({
    newPassword: "",
    confirmationCode: "",
    username: username,
  });

  const [confirmPassword, setConfirmPassword] = React.useState("");

  const {
    isError,
    error,
    isLoading,
    mutateAsync: confirmResetPassword_mutateAsync,
  } = useMutation(
    ["ConfirmRestPassword"],
    async (args: ConfirmResetPasswordInput) => {
      if (args.newPassword !== confirmPassword)
        throw new Error("Passwords do not match");
      return await confirmResetPassword(args);
    },
    {
      onSuccess: () => {
        navigate("/auth/login");
      },
    }
  );
  const { isLoading: resendingCode, mutateAsync: resendCode } = useMutation(
    ["ResendCode"],
    async (args: ResetPasswordInput) => {
      return await resetPassword(args);
    }
  );

  return (
    <Flex direction={"column"} gap={"large"} paddingTop={"small"}>
      <Heading level={5} textAlign={"center"}>
        Update Your Password
      </Heading>
      <Text fontSize={"small"}>
        Enter the confirmation code sent to{" "}
        <b>{output?.nextStep.codeDeliveryDetails.destination}</b> and a new
        password. Please check your spam folder just in case!
      </Text>
      <Flex
        direction={"column"}
        as="form"
        gap={"small"}
        onSubmit={async (e) => {
          e.preventDefault();

          await confirmResetPassword_mutateAsync(formData);
        }}
      >
        <TextField
          isRequired
          label="Confirmation Code"
          placeholder="Enter your confirmation code"
          type="text"
          value={formData.confirmationCode}
          onChange={(e) => {
            setFormData({ ...formData, confirmationCode: e.target.value });
          }}
        ></TextField>
        <TextField
          isRequired
          label="New Password"
          placeholder="Enter your new password"
          type="password"
          value={formData.newPassword}
          onChange={(e) => {
            setFormData({ ...formData, newPassword: e.target.value });
          }}
        ></TextField>
        <TextField
          isRequired
          label="Confirm Password"
          placeholder="Enter your new password"
          type="password"
          value={confirmPassword}
          onChange={(e) => {
            setConfirmPassword(e.target.value);
          }}
        ></TextField>

        <RaisedButton
          type="submit"
          isLoading={isLoading || resendingCode}
          loadingText={
            resendingCode ? "Resending Code..." : "Updating Password..."
          }
        >
          Update Password
        </RaisedButton>
        {isError ? (
          <Text variation="error">{(error as Error).message}</Text>
        ) : null}
        <Text fontSize={"small"} textAlign={"center"}>
          <Link onClick={() => resendCode({ username })}>Resend Code</Link>
        </Text>
      </Flex>
    </Flex>
  );
}
