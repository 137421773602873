import * as React from "react";

import { Lobby } from "../Types/LobbyTypes";
import { getLobby } from "../services/getLobby";
import { useQuery } from "@tanstack/react-query";

export interface ILobbyContextProviderProps {
  children: React.ReactNode;
}

export default function useLobbyQuery(pin: string | undefined) {
  const { data: lobby, isInitialLoading: lobbyInitialLoading } =
    useQuery<Lobby>(
      ["lobby", pin],
      async () => (await getLobby(pin as string)) as Lobby,
      { suspense: true, enabled: pin !== undefined, refetchOnWindowFocus: true }
    );

  return { lobby, lobbyInitialLoading };
}
