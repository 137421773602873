import { queryDatabaseItems } from "../../../services/database/queryDatabaseItems";
import { Team } from "../Types/TeamTypes";

export async function getSortedTeams(lobbyId: string) {
  try {
    const result = await queryDatabaseItems<Team>("/teams", lobbyId);
    // convert the created at strings to dates then order the results by ascending dates
    const sortedTeams = result?.sort(
      (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    );

    return sortedTeams;
  } catch (err) {
    console.error(err);
    throw new Error("Failed to get teams");
  }
}
