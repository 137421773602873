import * as React from "react";

import { SlideElementsContext } from "../SlideElementsContextProvider";
import { SlideElement, TextProps } from "../../types/slideTypes";
import Quill, { RangeStatic } from "quill";
import { convertTextPropsToDelta } from "../../util/textPropsToDelta";
import { SlidesContext } from "../SlidesContextProvider";
import useUpdateEffect from "../../../../hooks/useUpdateEffect";
import { toast } from "react-toastify";
import Delta from "quill-delta";
import { useRichTextContext } from "../RichTextContextProvider";

import "../../../../styles/quill.css";

import { LessonContext } from "../LessonContextProvider";
import useDebounce from "../../../../hooks/useDebounce";
import { useUpdateElement } from "../../hooks/useUpdateElement";

export interface ITextElementProps {
  slideElement: SlideElement;
}

export default function TextElement(props: ITextElementProps) {
  const { slideElement: thisElement } = props;
  const { isStatic, idPrefix } = React.useContext(SlideElementsContext);

  const { setCurrentEditor, setCurrentFormats } = useRichTextContext();
  const { lesson } = React.useContext(LessonContext);
  const { slideId } = React.useContext(SlidesContext);
  const { selectElement, removeSelectedElement } =
    React.useContext(SlideElementsContext);

  const { mutateAsync: updateElement } = useUpdateElement({
    lessonId: lesson.id,
    slideId,
    elementId: thisElement.id,
  });
  const [element, setElement] = React.useState<SlideElement>(thisElement);

  useDebounce(
    () => {
      if (!isStatic) updateElement(element);
    },
    300,
    [element]
  );

  let Inline = Quill.import("blots/inline");
  class MarkBlot extends Inline {}
  MarkBlot.blotName = "mark";
  MarkBlot.tagName = "mark";
  Quill.register("formats/mark", MarkBlot);

  const quillRef = React.useRef<Quill | null>(null);
  const paragraphRef = React.useRef<HTMLDivElement | null>(null);
  const editorRef = React.useRef<HTMLDivElement | null>(null);
  // const containerRef = React.useRef<HTMLDivElement | null>(null);

  const textProps = React.useMemo(() => element.props as TextProps, [element]);

  const textContent = React.useMemo(() => {
    return textProps.ops.map((op) => op.insert).join("");
  }, [textProps.ops]);

  useUpdateEffect(() => {
    if (!isStatic) syncFromTextProps();
  }, [slideId, isStatic]);

  useUpdateEffect(() => {
    if (isStatic) syncFromTextProps();
  }, [textProps.ops]);

  React.useEffect(() => {
    if (paragraphRef.current && !quillRef.current) {
      quillRef.current = new Quill(paragraphRef.current, {
        theme: "snow", // or whatever theme you wish to use
        placeholder: "Type here...",
        readOnly: isStatic,
        formats: [
          "mark",
          "bold",
          "italic",
          "underline",
          "strike",
          "color",
          "font",
          "size",
          "background",
          "link",
          "align",
          "list",
        ],
        modules: {
          toolbar: false,
          // authorship: {
          //   enabled: true,
          //   authorId: "me",
          //   color: "red",
          // },
          // toolbar: isStatic ? false : `#slide-toolbar-placeholder`,
        },
      });
      quillRef.current.enable(!isStatic);
      syncFromTextProps();
      if (isStatic) return;

      if (element.elementType !== "text") return;

      // if (delta.ops.length === 0) {
      Object.entries(element.styleDefaults ?? {}).forEach(([key, value]) => {
        quillRef.current?.format(key, value);
      });
      // }
      // reset history on quillRef.current
      quillRef.current.history.clear();

      quillRef.current.on("text-change", handleTextChange);
      quillRef.current.on("selection-change", handleSelectionChange);

      return () => {
        quillRef.current?.off("text-change", handleTextChange);
        quillRef.current?.off("selection-change", handleSelectionChange);
        quillRef.current = null;
      };
    }
    return;
  }, []);

  React.useEffect(() => {
    if (paragraphRef.current) {
      editorRef.current = paragraphRef.current.querySelector(".ql-editor");

      if (editorRef.current) {
        if (textProps.valign === "middle") {
          editorRef.current.style.display = "flex";
          editorRef.current.style.flexDirection = "column";
          editorRef.current.style.justifyContent = "center";
        }
      }
    }
  }, [paragraphRef.current, textProps.valign, element]);

  const syncFromTextProps = React.useCallback(() => {
    const delta = convertTextPropsToDelta(textProps.ops);

    try {
      if (quillRef.current) {
        // if (delta.ops.length === 0) return;
        return quillRef.current.setContents(delta);
      }
    } catch (e) {
      toast.error("Error loading text content.");
      const delta = new Delta();
      if (quillRef.current) {
        return quillRef.current.setContents(delta);
      }
    }
    return new Delta();
  }, [textProps.ops]);

  const updateFormats = React.useCallback(() => {
    const range = quillRef.current?.getSelection();
    if (range) {
      const currentFormats = quillRef.current?.getFormat(range);

      setCurrentFormats?.(currentFormats);
    }
  }, [setCurrentFormats]);

  const handleTextChange = React.useCallback(() => {
    if (isStatic) return;
    // Early return if quillRef.current is null
    if (quillRef.current === null) return;

    selectElement(element.id);

    const contents = quillRef.current.getContents();
    const newTextOps = contents.ops;

    const newSlideElement: SlideElement = { ...element };

    if (newSlideElement.elementType === "text") {
      // Use a type guard or improve typings to avoid 'as any'
      // if (isDeepEqual(newTextOps, newSlideElement.props.ops)) {
      //   return;
      // }

      newSlideElement.props.ops = newTextOps;
      console.log("1");
      // onChangeElement(newSlideElement);
      setElement(newSlideElement);
    }

    updateFormats();
  }, [updateFormats, isStatic, element]);

  const handleSelectionChange = React.useCallback(
    (range: RangeStatic) => {
      if (isStatic) return;

      if (range === null) {
        removeSelectedElement(element.id);
      } else {
        setCurrentEditor?.(quillRef.current);
        selectElement(element.id);
      }
      updateFormats();
    },
    [isStatic, element]
  );

  return (
    <>
      {/* <style>
      {`
      #${idPrefix + element.id} p {
        line-height: 1.2em;
      }
      `}
    </style> */}
      {element.elementType === "text" ? (
        <div
          id={idPrefix + element.id}
          ref={paragraphRef}
          hidden={isStatic && textContent.trim() === ""}
          style={{
            width: "100%",
            height: "100%",
            fontSize: element.styleDefaults?.size
              ? element.styleDefaults?.size
              : textProps.fontSize
              ? `${textProps.fontSize}pt`
              : undefined,
            fontFamily: element.styleDefaults?.font,
            fontWeight: element.styleDefaults?.bold ? "bold" : "normal",
            // lineHeight: "1.2em",
          }}
        />
      ) : null}
    </>
  );
}
