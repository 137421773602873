import {
  CheckboxField,
  Flex,
  Heading,
  TextAreaField,
  Text,
  Grid,
  Image,
  View,
} from "@aws-amplify/ui-react";
import * as React from "react";
import GenerateCustomeOptions from "./GenerateCustomOptions";
import RaisedButton from "../../../../../components/RaisedButton";
import ArrowRightIcon from "../../../../../components/icons/ArrowRightIcon";
import ArrowLeftIcon from "../../../../../components/icons/ArrowLeftIcon";
import FullLessonGenerator from "./FullLessonGenerator";
import InfoIcon from "../../../../../components/icons/InfoIcon";
import { LessonContext } from "../../LessonContextProvider";
import useCharacterLimit from "../../../../../hooks/useCharacterLimit";
import MainContentGenerator from "./MainContentGenerator";
import DidYouKnowFactGenerator from "./DidYouKnowFactGenerator";
import DiscussionQuestionGenerator from "./DiscussionQuestionGenerator";
import { useTranslationContext } from "../../../../../context/TranslationContextProvider";
import ModelToggle from "../../../../generate/components/ModelToggle";

export interface IGenerateOptionsMenuProps {}

enum GenerateMenuState {
  OPTIONS,
  FORM,
  LESSON,
  CONTENT,
  DIDYOUKNOW,
  DISCUSSION,
}

export default function GenerateMenu(props: IGenerateOptionsMenuProps) {
  const {} = props;

  const { lesson } = React.useContext(LessonContext);

  const { continue_ } = useTranslationContext().translations;

  const [state, setState] = React.useState<GenerateMenuState>(
    GenerateMenuState.FORM
  );

  const [useLessonPlan, setUseLessonPlan] = React.useState<boolean>(true);
  const [useExistingTopic, setUseExistingTopic] = React.useState<boolean>(true);
  const [topicLocal, setTopicLocal] = React.useState<string>(lesson.topic);

  const { characterLimit, numCharacters } = useCharacterLimit(
    topicLocal,
    setTopicLocal,
    500
  );

  switch (state) {
    case GenerateMenuState.OPTIONS:
      return (
        <Flex
          direction="column"
          justifyContent={"space-between"}
          height={"100%"}
          paddingTop={"small"}
        >
          {/* <LessonPlanForm lesson={lesson} /> */}
          {/* <Flex direction={"column"} gap={"xs"}>
            <Heading textAlign={"center"}>From Template:</Heading>
            <RaisedButton
              gap="small"
              onClick={() => {
                setState(GenerateMenuState.FORM);
              }}
            >
              <SparkleIcon /> Full Lesson
            </RaisedButton>
          </Flex>
          <Divider /> */}
          <Flex direction={"column"} gap={"xs"}>
            <Heading textAlign={"center"}>Custom Options:</Heading>
            <GenerateCustomeOptions />
          </Flex>
          <RaisedButton
            variation={"primary"}
            backgroundColor={"orange.60"}
            gap="small"
            onClick={() => {
              setState(GenerateMenuState.FORM);
            }}
          >
            {continue_}
            <ArrowRightIcon />
          </RaisedButton>
        </Flex>
      );
    case GenerateMenuState.FORM:
      return (
        <Flex
          direction={"column"}
          justifyContent={"space-between"}
          height={"100%"}
          paddingTop={"small"}
        >
          <Flex borderRadius={"small"} direction={"column"} padding={"medium"}>
            <View>
              <ModelToggle />
            </View>
            <CheckboxField
              value={""}
              size="small"
              label={
                <Flex color={"neutral.80"}>
                  Use Lesson Plan
                  <InfoIcon message="This determines if the lesson plan (overview, objectives, scope, and vocabulary) is included in the prompt for generating the slides." />
                </Flex>
              }
              name={"use-lesson-plan-checkbox"}
              checked={useLessonPlan}
              onChange={(e) => setUseLessonPlan(e.target.checked)}
            />

            <CheckboxField
              value={""}
              size="small"
              label={
                <Flex color={"neutral.80"}>
                  Use Existing Topic
                  <InfoIcon message="You can use the existing topic or you can overwrite it" />
                </Flex>
              }
              name={"use-existing-topic-checkbox"}
              checked={useExistingTopic}
              onChange={(e) => setUseExistingTopic(e.target.checked)}
            />
          </Flex>

          {!useExistingTopic && (
            <TextAreaField
              paddingLeft={"small"}
              paddingRight={"small"}
              rows={2}
              label={
                <Flex
                  fontWeight={"semibold"}
                  color={"neutral.100"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Flex alignItems={"center"}>
                    <Heading
                      fontWeight={"semibold"}
                      color={"neutral.100"}
                      fontSize={"small"}
                    >
                      Topic
                    </Heading>
                    <InfoIcon
                      color={"var(--amplify-neutral-60)"}
                      message="Used as prompt for AI generation"
                    />
                  </Flex>
                  <Flex alignItems={"center"}>
                    <Text fontWeight={"medium"} color={"neutral.60"}>
                      {numCharacters}/{characterLimit}
                    </Text>
                  </Flex>
                </Flex>
              }
              value={topicLocal}
              fontSize={"smaller"}
              size="small"
              onChange={(e) => setTopicLocal(e.target.value)}
            />
          )}
          <Grid
            templateColumns={"1fr 1fr"}
            padding={"small"}
            templateRows={"1fr 1fr"}
            gap={"xs"}
          >
            <RaisedButton
              width={"100%"}
              variation={"primary"}
              backgroundColor={"white"}
              padding={"medium"}
              onClick={() => {
                setState(GenerateMenuState.LESSON);
              }}
              data-attr="create-lesson-slides"
              data-ph-capture-attribute-type={"full-lesson"}
              data-ph-capture-attribute-topic={lesson.topic.toLowerCase()}
              data-ph-capture-attribute-grade-level={lesson.gradeLevel}
              data-ph-capture-attribute-reading-level={lesson.readingLevel}
              data-ph-capture-attribute-language={lesson.lang}
            >
              <Flex
                direction={"column"}
                textAlign={"center"}
                alignItems={"center"}
                gap={"xs"}
              >
                <Image
                  src="/images/icons/presentation.png"
                  alt="A full lesson presentation"
                  padding={"2px"}
                  objectFit={"contain"}
                  height={"xxxl"}
                />
                <Text color={"neutral.80"} fontSize={"smaller"}>
                  Full Lesson
                </Text>
              </Flex>
            </RaisedButton>

            <RaisedButton
              variation={"primary"}
              backgroundColor={"white"}
              onClick={() => {
                setState(GenerateMenuState.CONTENT);
              }}
              data-attr="create-lesson-slides"
              data-ph-capture-attribute-type={"content-slides"}
              data-ph-capture-attribute-topic={lesson.topic.toLowerCase()}
              data-ph-capture-attribute-grade-level={lesson.gradeLevel}
              data-ph-capture-attribute-reading-level={lesson.readingLevel}
              data-ph-capture-attribute-language={lesson.lang}
            >
              <Flex
                direction={"column"}
                textAlign={"center"}
                alignItems={"center"}
                gap={"xs"}
              >
                <Image
                  src="/images/icons/website.png"
                  alt="An information icon on a website with content"
                  padding={"2px"}
                  objectFit={"contain"}
                  height={"xxxl"}
                />
                <Text color={"neutral.80"} fontSize={"smaller"}>
                  Content
                </Text>
              </Flex>
            </RaisedButton>
            <RaisedButton
              variation={"primary"}
              backgroundColor={"white"}
              onClick={() => {
                setState(GenerateMenuState.DIDYOUKNOW);
              }}
              data-attr="create-lesson-slides"
              data-ph-capture-attribute-type={"did-you-know-slides"}
              data-ph-capture-attribute-topic={lesson.topic.toLowerCase()}
              data-ph-capture-attribute-grade-level={lesson.gradeLevel}
              data-ph-capture-attribute-reading-level={lesson.readingLevel}
              data-ph-capture-attribute-language={lesson.lang}
            >
              <Flex
                direction={"column"}
                textAlign={"center"}
                alignItems={"center"}
                gap={"xs"}
              >
                <Image
                  src="/images/icons/faq.png"
                  alt="Questions and exclamations"
                  padding={"2px"}
                  objectFit={"contain"}
                  height={"xxxl"}
                />
                <Text color={"neutral.80"} fontSize={"smaller"}>
                  Did You Know?
                </Text>
              </Flex>
            </RaisedButton>
            <RaisedButton
              variation={"primary"}
              backgroundColor={"white"}
              onClick={() => {
                setState(GenerateMenuState.DISCUSSION);
              }}
              data-attr="create-lesson-slides"
              data-ph-capture-attribute-type={"discussion-question-slides"}
              data-ph-capture-attribute-topic={lesson.topic.toLowerCase()}
              data-ph-capture-attribute-grade-level={lesson.gradeLevel}
              data-ph-capture-attribute-reading-level={lesson.readingLevel}
              data-ph-capture-attribute-language={lesson.lang}
            >
              <Flex
                direction={"column"}
                textAlign={"center"}
                alignItems={"center"}
                gap={"xs"}
              >
                <Image
                  src="/images/icons/discussion.png"
                  alt="Chat bubbles with discussion text"
                  padding={"2px"}
                  objectFit={"contain"}
                  height={"xxxl"}
                />
                <Text color={"neutral.80"} fontSize={"smaller"}>
                  Discussion Question
                </Text>
              </Flex>
            </RaisedButton>
          </Grid>
        </Flex>
      );

    case GenerateMenuState.LESSON:
      return (
        <Flex
          direction={"column"}
          justifyContent={"space-between"}
          height={"100%"}
          paddingTop={"small"}
          overflow={"visible"}
        >
          <Flex justifyContent={"center"}>
            <RaisedButton
              variation="primary"
              fontSize={"small"}
              onClick={() => {
                setState(GenerateMenuState.FORM);
              }}
            >
              <ArrowLeftIcon />
            </RaisedButton>
          </Flex>

          <FullLessonGenerator
            useLessonPlan={useLessonPlan}
            useExistingTopic={useExistingTopic}
            topicLocal={topicLocal}
          />
        </Flex>
      );
    case GenerateMenuState.CONTENT:
      return (
        <Flex
          direction={"column"}
          justifyContent={"space-between"}
          height={"100%"}
          paddingTop={"small"}
          overflow={"visible"}
        >
          <Flex justifyContent={"center"}>
            <RaisedButton
              variation="primary"
              fontSize={"small"}
              onClick={() => {
                setState(GenerateMenuState.FORM);
              }}
            >
              <ArrowLeftIcon />
            </RaisedButton>
          </Flex>

          <MainContentGenerator
            useLessonPlan={useLessonPlan}
            useExistingTopic={useExistingTopic}
            topicLocal={topicLocal}
          />
        </Flex>
      );
    case GenerateMenuState.DIDYOUKNOW:
      return (
        <Flex
          direction={"column"}
          justifyContent={"space-between"}
          height={"100%"}
          paddingTop={"small"}
          overflow={"visible"}
        >
          <Flex justifyContent={"center"}>
            <RaisedButton
              variation="primary"
              fontSize={"small"}
              onClick={() => {
                setState(GenerateMenuState.FORM);
              }}
            >
              <ArrowLeftIcon />
            </RaisedButton>
          </Flex>

          <DidYouKnowFactGenerator
            useLessonPlan={useLessonPlan}
            useExistingTopic={useExistingTopic}
            topicLocal={topicLocal}
          />
        </Flex>
      );
    case GenerateMenuState.DISCUSSION:
      return (
        <Flex
          direction={"column"}
          justifyContent={"space-between"}
          height={"100%"}
          paddingTop={"small"}
          overflow={"visible"}
        >
          <Flex justifyContent={"center"}>
            <RaisedButton
              variation="primary"
              fontSize={"small"}
              onClick={() => {
                setState(GenerateMenuState.FORM);
              }}
            >
              <ArrowLeftIcon />
            </RaisedButton>
          </Flex>

          <DiscussionQuestionGenerator
            useLessonPlan={useLessonPlan}
            useExistingTopic={useExistingTopic}
            topicLocal={topicLocal}
          />
        </Flex>
      );

    default:
      return <></>;
  }
}
