export const languageOptions = {
  en: "English",
  ar: "العربية (Arabic)",
  es: "Español (Spanish)",
  de: "Deutsch (German)",
  fr: "Français (French)",
  zh: "普通话 (Mandarin Chinese)",
  "zh-yue": "廣東話 (Cantonese Chinese)",
  hi: "हिन्दी (Hindi)",
  so: "Af-Soomaali (Somali)",
  he: "עברית (Hebrew)",
  ja: "日本語 (Japanese)",
  it: "Italiano (Italian)",
  id: "Bahasa Indonesia (Indonesian)",
  pt: "Português (Portuguese)",
  ko: "한국어 (Korean)",
  ru: "Русский (Russian)",
  uk: "Українська (Ukrainian)",
  nb: "Norsk bokmål (Norwegian Bokmål)",
  nl: "Nederlands (Dutch)",
};
