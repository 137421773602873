import Quill from "quill";
import { Op } from "quill-delta";
import { toast } from "react-toastify";

export function convertHTMLToOps(text: string) {
  const newDiv = document.createElement("div");

  // Set CSS styles to position the div off-screen
  newDiv.style.position = "absolute";
  newDiv.style.left = "-9999px";

  // Append the newly created element to the DOM
  document.body.appendChild(newDiv);

  // Initialize a Quill editor with the dynamically created div
  const quill = new Quill(newDiv, {
    modules: {
      toolbar: false,
    },
    theme: "snow",
  });

  try {
    quill.clipboard.dangerouslyPasteHTML(text);
    const ops = quill.getContents().ops as Op[];



    // Remove the Quill editor container from the DOM
    document.body.removeChild(newDiv);

    return ops;
  } catch (err) {

    toast.error("Something went wrong with a slide. Please try again.");
    return [];
  }
}
