import React, { createContext, useCallback, useContext, useMemo } from "react";
import { Question, Quiz } from "../../../../API";
import { AnswerResult, GameData, GameState } from "../../Types/GameTypes";
import { Player } from "../../Types/GameTypes_dep";
import { useGameAudioContext } from "./GameAudioContextProvider";
import { GameEventProps } from "../../Types/GameEventProps";
import { NumberedTeam } from "../../Types/TeamTypes";
import TranslationContextProvider from "../../../../context/TranslationContextProvider";

type BaseGameContextProps = {
  quiz: Quiz | undefined;
  completeGameButtonText?: string;
  gameData: GameData;
  // setGameData: React.Dispatch<React.SetStateAction<GameData>>;
  settings?: React.ReactNode;
};

type SingleplayerGameContextProps = {
  playMode: "single-player";
  players: undefined;
  player: undefined;
  team: undefined;
};
type MultiplayerGameContextProps = {
  playMode: "multi-player";
  players: Player[];
  player: Player;
  team: NumberedTeam;
};

export type GameContextProviderProps = {
  children: React.ReactNode;
} & BaseGameContextProps &
  GameEventProps &
  (SingleplayerGameContextProps | MultiplayerGameContextProps);

export enum GameStatus {
  INCOMPLETE,
  COMPLETE,
}

type BaseGameContext = {
  gameData: GameData;
  setGameData: React.Dispatch<React.SetStateAction<GameData>>;
  questions: (Question | null | undefined)[] | undefined;
  status: GameStatus;
  quiz: Quiz | undefined;
  handleSubmitAnswer: (answerResults: AnswerResult[]) => void | Promise<void>;
  startGame: () => void;
  nextQuestion: () => void;
  settings?: React.ReactNode;
};

type GameContext = GameEventProps &
  BaseGameContext &
  (
    | {
        playMode: "multi-player";
        players: Player[];
        turnIndex: number;
        isMyTurn: boolean;
        teamNumber: number;
      }
    | {
        playMode: "single-player";
        players: undefined;
        turnIndex: undefined;
        isMyTurn: true;
        teamNumber: undefined;
      }
  );
const GameContext = createContext({} as GameContext);

export function useGameContext() {
  return useContext(GameContext);
}

export default function GameContextProvider(props: GameContextProviderProps) {
  const {
    gameData,
    quiz,
    onSubmitAnswer,
    onStart,
    playMode,
    players,
    player,
    team,
    settings,
    onChange,
    onComplete,
    onExit,
    onShare,
    onPlayAgain,
  } = props;

  const { playNegativeSound, playPositiveSound } = useGameAudioContext();

  const questions = useMemo(
    () => quiz?.Questions?.items.map((quizQuestion) => quizQuestion?.question),
    [quiz?.Questions?.items]
  );

  const status = useMemo(() => {
    if (
      gameData.answerResults.length === questions?.length &&
      gameData.questionIndex === questions?.length
    )
      return GameStatus.COMPLETE;
    else return GameStatus.INCOMPLETE;
  }, [gameData, questions]);

  const playerIndex = useMemo(() => {
    if (playMode !== "multi-player") return undefined;
    let index = 0;
    players?.forEach((p, i) => {
      if (p.userId === player?.userId) index = i;
    });
    return index;
  }, [players, player]);

  const questionIndex = useMemo(
    () => gameData.questionIndex,
    [gameData.questionIndex]
  );

  const turnIndex = useMemo(() => {
    if (playMode !== "multi-player") return undefined;
    let turnIndex: number;
    if (questionIndex < players.length) turnIndex = questionIndex;
    else turnIndex = questionIndex % players.length;
    return turnIndex;
  }, [players, questionIndex]);

  const isMyTurn = useMemo(() => {
    if (playMode !== "multi-player") return true;
    return turnIndex === playerIndex;
  }, [turnIndex, playerIndex]);

  const teamNumber = useMemo(() => {
    if (playMode !== "multi-player") return undefined;
    return team.number;
  }, [players, playerIndex]);

  const handleSubmitAnswer = useCallback(
    async (answerResults: AnswerResult[]) => {
      await onSubmitAnswer?.(answerResults);

      if (answerResults[answerResults.length - 1].isCorrect) {
        playPositiveSound?.();
      } else {
        playNegativeSound?.();
      }
    },
    [onSubmitAnswer]
  );

  const setGameData = useCallback(
    async (gameData: GameData) => await onChange?.(gameData),
    [gameData]
  );

  const startGame = useCallback(() => {
    const newGameData = { ...gameData, gameState: GameState.PLAYING };
    onStart?.(newGameData);
    setGameData(newGameData);
  }, [gameData]);

  const nextQuestion = useCallback(() => {
    const newGameData = {
      ...gameData,
      questionIndex: gameData.questionIndex + 1,
      currentAnswer: [],
    };
    setGameData(newGameData);
  }, [gameData]);

  return (
    <TranslationContextProvider lang={quiz?.lang || "en"}>
      <GameContext.Provider
        value={
          {
            gameData,
            setGameData,
            questions,
            status,
            handleSubmitAnswer,
            nextQuestion,
            quiz,
            startGame,
            playMode,
            players,
            turnIndex,
            isMyTurn,
            teamNumber,
            onPlayAgain,
            onComplete,
            onExit,
            onShare,
            settings,
            onSubmitAnswer,
            onChange,
          } as GameContext // might be bad
        }
      >
        {props.children}
      </GameContext.Provider>
    </TranslationContextProvider>
  );
}
