import {
  Card,
  Collection,
  Flex,
  Heading,
  ScrollView,
} from "@aws-amplify/ui-react";
import React from "react";
import LeaderboardResult from "./LeaderboardResult";
import { AssignmentResult } from "../../types";
import { useTranslationContext } from "../../../../context/TranslationContextProvider";

export default function Leaderboard({
  assignmentResults,
}: {
  assignmentResults: AssignmentResult[];
}) {
  const { translations } = useTranslationContext();

  return (
    <>
      <Card boxShadow={"large"}>
        <Heading paddingBottom={"xs"}>{translations.leaderboard}</Heading>
        <Flex direction="column" gap={"small"}>
          <ScrollView>
            <Collection
              items={assignmentResults}
              type="list"
              padding={{ base: "xxs", medium: "large" }}
            >
              {(assignmentResult, i) => (
                <LeaderboardResult
                  key={i}
                  assignmentResult={assignmentResult}
                  i={i}
                />
              )}
            </Collection>
          </ScrollView>
        </Flex>
      </Card>
    </>
  );
}
