import { listLobbyConnections } from "../services/listLobbyConnections";
import { useQuery } from "@tanstack/react-query";

export default function useLobbyConnections(lobbyPin: string | undefined) {
  const { data: connections } = useQuery(
    ["connection", "list", lobbyPin],
    async () => await listLobbyConnections(lobbyPin as string),
    {
      enabled: lobbyPin !== undefined,
      suspense: true,
      refetchOnWindowFocus: true,
    }
  );

  return { connections };
}
