import { fetchAuthSession } from "aws-amplify/auth";
import {
  GameSession,
  Player,
  PlayerSession,
  PutPlayerBody,
} from "../Types/GameTypes_dep";
import RestAPI from "../../../util/RestAPI";



export async function fetchPlayerSession(
  gameSession: GameSession
): Promise<PlayerSession | undefined> {
  try {
    const playerSessions = (await RestAPI.get(
      `/playerSession/${gameSession.id}`,
      {}
    ).then((res) => res.json())) as any as PlayerSession[];

    return playerSessions[0];
  } catch (err) {
    console.error(err);
    return;
  }
}

// export async function fetchLobbyPlayers(
//   playerSession?: PlayerSession
// ): Promise<LobbyPlayers | undefined> {
//   if (!playerSession) return;
//   try {
//     const allPlayers = (await API.get(
//       "GameAPI",
//       `/player/${playerSession.id}`,
//       {}
//     )) as Player[];

//     let lobby: LobbyPlayers = {
//       activePlayers: [],
//       inactivePlayers: [],
//       kickedPlayers: [],
//     };

//     allPlayers.map((player) => {
//       if (player.isKicked) lobby.kickedPlayers.push(player);
//       else if (isPlayerActive(player, 7)) lobby.activePlayers.push(player);
//       else lobby.inactivePlayers.push(player);
//       return;
//     });
//     return lobby;
//   } catch (err) {
//     console.error(err);
//     return undefined;
//   }
// }

export async function fetchPlayer(
  playerSession: PlayerSession,
  userId: string
): Promise<Player | undefined> {
  try {
    const player = (await RestAPI.get(
      `/player/object/${playerSession.id}/${userId}`,
      {}
    ).then((res) => res.json())) as any as Player;
    return player;
  } catch (err) {
    console.error(err);
    return undefined;
  }
}

export async function getIdentityId() {
  try {
    const session = await fetchAuthSession();
    // return cred.identityId; // TODO: Check if this is the correct value
    if (!session.identityId) throw new Error("No identityId found in session");
    return session.identityId;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export async function updatePlayer(
  playerSession?: PlayerSession
): Promise<void | undefined> {
  if (!playerSession) return;
  try {
    const updatedAt = new Date(Date.now()).toISOString();
    const putPlayerBody: PutPlayerBody = {
      Key: { playerSessionId: playerSession.id },
      ReturnValues: "ALL_NEW",
      UpdateExpression: "SET updatedAt = :updatedAt",
      ExpressionAttributeValues: {
        ":updatedAt": updatedAt,
      },
    };

    await RestAPI.put(`/player`, {
      body: putPlayerBody,
    });
  } catch (err) {
    console.error(err);
  }
}
