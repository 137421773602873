import RestAPI from "../../../../util/RestAPI";
import { Assignment } from "../../types";

export async function getAssignment(classId: string, assignmentId: string) {
  try {
    const assignment: Assignment = (await RestAPI.get(
      `/assignments/object/${classId}/${assignmentId}`,
      {}
    ).then((res) => res.json())) as any as Assignment;
    return assignment;
  } catch (err) {

    return;
  }
}
