import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Classes from "../pages/Classes";
import ClassPage from "../pages/ClassPage";
import AssignmentResultsPage from "../features/class/components/AssignmentResults/AssignmentResultsPage";
import DashboardLayout from "../layouts/DashboardLayout";
import { CenteredLoader } from "../components/CenteredLoader";
import { useUserContext } from "../context";

export default function ClassesRoutes() {
  const { org } = useUserContext();

  return (
    <>
      <DashboardLayout>
        <Suspense fallback={<CenteredLoader />}>
          {org && org.personalInfoCollection === false ? (
            <Navigate to={"/dashboard"} />
          ) : (
            <Routes>
              <>
                <Route index element={<Classes />} />
                <Route path="/:classId" element={<ClassPage />} />
                {/* <Route path='/:classId/results/:assignmentId' element={<AssignmentResultsPage/>} /> */}
                <Route
                  path="/:classId/assignment/:assignmentId/results"
                  element={<AssignmentResultsPage />}
                />
              </>
            </Routes>
          )}
        </Suspense>
      </DashboardLayout>
    </>
  );
}
