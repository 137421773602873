import { GraphQLQuery } from "@aws-amplify/api";
import { QuizzesByAllowReadToAndCreatedAtQuery } from "../../../API";
import { listPublicQuizIds } from "../../../services/custom-queries";
import { generateClient } from "aws-amplify/api";

export default async function fetchPaginatedListPublicQuizzes(
  nextToken?: string
) {
  const client = await generateClient();

  const quizData = await client.graphql<
    GraphQLQuery<QuizzesByAllowReadToAndCreatedAtQuery>
  >({ query: listPublicQuizIds, variables: { nextToken } });

  const list = quizData.data?.quizzesByAllowReadToAndCreatedAt;

  const quizIds = list?.items.map((quiz) => quiz?.id);
  // if quizIds is undefined or if any element of quizIds is undefined, throw an error
  if (!quizIds) {
    throw new Error("Quiz ids are undefined");
  }
  return { quizIds, nextToken: list?.nextToken };
}
