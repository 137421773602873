import RestAPI from "../../util/RestAPI";
import getUser from "../../util/User/getUser";

export async function getBillingPortal(returnUrl: string) {
  try {
    const customerId = await getUser().then((user) => user.customerId);
    if (!customerId) {
      throw new Error("No customer ID found");
    }
    const response: any = await RestAPI.post(`/stripe/portal`, {
      body: {
        customerId,
        returnUrl,
      },
    }).then((response) => response.json() as any);
    return response.portalSession;
  } catch (err) {
    throw err;
  }
}
