import {
  Card,
  Flex,
  Grid,
  Text,
  View,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
import * as React from "react";
import { Question } from "../../../../API";

import { useTranslationContext } from "../../../../context/TranslationContextProvider";
import { ImageComponent } from "../../../../components/ImageComponent";

import {
  useScreenReaderContext,
  PlayAudioButton,
  ReadAloudButton,
} from "../../../accessibility";

export interface IQuestionCardProps {
  question: Question | null | undefined;
}

export function QuestionCard(props: IQuestionCardProps) {
  const { question } = props;

  const { translations } = useTranslationContext();
  const { ScreenReadButton } = useScreenReaderContext();

  const size = useBreakpointValue({ base: "15px", medium: "20px" }) as string;
  const height = useBreakpointValue({ base: "35px", medium: "50px" }) as string;

  return (
    <View padding={"0 medium 0 medium"} height={"100%"}>
      <Card
        backgroundColor={"rgb(255,255,255,0.5)"}
        id="leaderboardCard"
        width={"100%"}
        height={"100%"}
        position={"relative"}
      >
        <Flex
          position={"absolute"}
          top={"small"}
          left={"small"}
          gap={"xs"}
          direction={{
            base: "column",
            medium: "row",
          }}
          style={{
            zIndex: 100000,
          }}
        >
          <ScreenReadButton />
          {question?.audio ? (
            <View id={"audio-btn-" + question.id}>
              <PlayAudioButton
                hideOnError
                audio={question.audio}
                borderRadius={"50px"}
                backgroundColor={"rgba(255,255,255,0.6)"}
                variation="link"
                size={size}
                padding={"xs xs small xs"}
                justifyContent={"center"}
                alignItems={"center"}
                height={height}
                style={{ aspectRatio: "1/1" }}
              />
            </View>
          ) : null}
          {question?.readAloudText ? (
            <ReadAloudButton
              text={question.readAloudText}
              borderRadius={"50px"}
              backgroundColor={"rgba(255,255,255,0.6)"}
              variation="link"
              size={size}
              padding={"xs xs small xs"}
              justifyContent={"center"}
              alignItems={"center"}
              height={height}
              style={{ aspectRatio: "1/1" }}
            ></ReadAloudButton>
          ) : null}
        </Flex>
        <Grid
          templateColumns={{ base: "1fr", medium: "1fr 400px" }}
          templateRows={{ base: "150px 1fr", medium: "1fr" }}
          templateAreas={{
            base:
              question?.image !== null
                ? '"image" "question"'
                : '"question" "question"',
            medium:
              question?.image !== null
                ? '"question image"'
                : '"question question"',
          }}
          height={"100%"}
          paddingLeft={"large"}
          paddingRight={"large"}
        >
          <Flex
            area={"question"}
            direction={"column"}
            // backgroundColor="white"
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Text
              id="displayText"
              // area={"question"}
              color={"black"}
              fontSize={{ small: "medium", medium: "large", large: "xl" }}
              fontWeight={"bold"}
              textAlign={"center"}
              alignSelf={"center"}
              // backgroundColor={"red"}
            >
              {question?.text}
            </Text>
            {question?.type === "MULTIPLE_SELECT" ? (
              <Text
                fontWeight={"semibold"}
                fontSize={{ small: "xs", medium: "small", large: "large" }}
                color={"neutral.90"}
              >
                ({translations.select_all_that_apply})
              </Text>
            ) : null}
          </Flex>

          <Flex
            area={"image"}
            justifyContent={"center"}
            alignItems={"center"}
            // backgroundColor={"blue"}
            position={"relative"}
          >
            {question?.image?.key && (
              <ImageComponent
                flex={1}
                image={question?.image}
                canEdit={false}
                autoPickPrompt=""
                alt={`Question Image`}
                maxHeight={{ base: "150px", medium: "300px" }}
                // maxWidth={{ base: "300px", medium: "225px" }}
                borderRadius={"xxxl"}
                loading="eager"
                objectFit={"contain"}
                border={"1px solid background.secondary"}
              ></ImageComponent>
            )}
          </Flex>
        </Grid>
      </Card>
    </View>
  );
}
