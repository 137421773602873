import React, { useEffect, useState } from "react";
import { Button } from "@aws-amplify/ui-react";
//import ExpandIcon from "../components/icons/ExpandIcon";
import CollapseIcon from "../components/icons/CollapseIcon";
import ExpandIcon from "../components/icons/ExpandIcon";


export default function FullScreenRelativeButton() {


  const [isFullscreen, setIsFullscreen] = useState(false);

  // Watch for fullscreenchange
  useEffect(() => {
    function onFullscreenChange() {
      setIsFullscreen(Boolean(document.fullscreenElement));
    }

    document.addEventListener('fullscreenchange', onFullscreenChange);

    return () => document.removeEventListener('fullscreenchange', onFullscreenChange);
  }, []);

  function FullscreenIcon() {
    if (isFullscreen) return <CollapseIcon />
    else return <ExpandIcon />
  }



  return (
    <>

      <Button
        color="white"
        padding={'zero'}
        position={"relative"}
        gap={"xxxs"}
        // alignSelf={"right"}
        direction={"column"}
        // justifyContent={'right'}
        variation={"link"}
        size={"large"}
        onClick={() => {
          if (isFullscreen) {
            document.exitFullscreen()
          } else {
            document.body.requestFullscreen()
          }
        }}
      // onClick={() => document.exitFullscreen()}
      // https://www.aha.io/engineering/articles/using-the-fullscreen-api-with-react
      >
        <FullscreenIcon />
      </Button>

    </>
  );
}
