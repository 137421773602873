import { get, post, put, del } from "aws-amplify/api";

const API_NAME = "GameAPI";

type RestAPIOptions = {
  headers?: { [key: string]: any };
  queryParams?: { [key: string]: any };
  body?: any;
};

export default class RestAPI {
  static async get(path: string, options?: RestAPIOptions) {
    return await get({ apiName: API_NAME, path, options }).response.then(
      (res) => res.body
    );
  }
  static async post(path: string, options?: RestAPIOptions) {
    return await post({ apiName: API_NAME, path, options }).response.then(
      (res) => res.body
    );
  }
  static async put(path: string, options?: RestAPIOptions) {
    return await put({ apiName: API_NAME, path, options }).response.then(
      (res) => res.body.json()
    );
  }
  static async del(path: string, options?: RestAPIOptions) {
    return await del({ apiName: API_NAME, path, options }).response.then(
      (res) => res
    );
  }
}
