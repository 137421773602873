import React, { useMemo } from "react";

import { useNavigate } from "react-router-dom";
import { PageDescription, PageIndex, PageTitle } from "../../../../util/SEO";
import useUnloadPrevention from "../../../../hooks/useUnloadPrevention";

import useLobbyContext from "../../hooks/useLobbyContext";
import GibblyLoader from "../../../../components/GibblyLoader";
import Game from "../v3/Game";
import { useLobbyTeams } from "../../hooks/useLobbyTeams";
import { useTranslationContext } from "../../../../context/TranslationContextProvider";

type MultiPlayProps = {
  goToLeaderboard?: () => void;
};

export default function MultiPlay({ goToLeaderboard }: MultiPlayProps) {
  PageTitle("Play 🎮 | Gibbly");
  PageDescription("Test your knowledge with this quiz!");
  PageIndex("");

  useUnloadPrevention();

  const navigate = useNavigate();

  const { team, teammates, lobby, updateTeam, player } = useLobbyContext();

  const { numberedTeams: teams } = useLobbyTeams(lobby);

  const numberedTeam = useMemo(() => {
    return teams?.find((team) => team.id === player?.groupId);
  }, [teams, player]);

  const quiz = useMemo(() => {
    return lobby?.quiz;
  }, [lobby?.quiz]);

  const { translations } = useTranslationContext();

  if (!team) return <GibblyLoader />;

  return (
    <Game
      quiz={quiz}
      gameData={team.gameData}
      // setGameData={(gameData) => {
      //   updateTeam({ team: { ...team, gameData } });
      // }}
      completeGameButtonText={translations.leaderboard}
      onExit={() => navigate("/play/join/")}
      onSubmitAnswer={async (answerResults) => {
        const updatedTeam = {
          ...team,
          gameData: { ...team.gameData, answerResults },
        };
        await updateTeam({ team: updatedTeam, targetGroup: "non-team" });
      }}
      onChange={async (gameData) => {
        const updatedTeam = { ...team, gameData };
        await updateTeam({ team: updatedTeam, targetGroup: "team" });
      }}
      onPlayAgain={goToLeaderboard}
      playMode="multi-player"
      players={teammates}
      player={player}
      team={numberedTeam}
      onComplete={async () => {
        await updateTeam({ team: { ...team, teamState: "FINISHED" } });
      }}
      // settings={isHost ? Settings : undefined}
    />
  );
}
