export const placeholderInstructions: string[] = [
  "Summarize the key concepts from the uploaded document and create a 5-question quiz focusing on these concepts.",
  "Create a quiz with multiple-choice questions that test comprehension of the main arguments presented in the document.",
  "Develop a true or false quiz that assesses understanding of the statistical data included in the document.",
  "Construct a quiz with questions that encourage critical thinking about the theories and hypotheses discussed in the document.",
  "Formulate a set of questions that compare and contrast the perspectives presented in different sections of the document.",
  "Design a quiz focusing on the historical context and its impact as described in the document, with a mix of short answer and multiple-choice questions.",
  "Produce a quiz that evaluates the user's ability to apply the methodologies detailed in the document to hypothetical scenarios.",
  "Assemble a quiz with fill-in-the-blank questions that focus on key terminology and definitions provided in the document.",
  "Craft a quiz that challenges users to identify the logical fallacies or strengths in the arguments made within the document.",
];