import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { PageIndex, PageTitle } from "../../../../util/SEO";

import EnterGamePin from "./EnterGamePin";
import GameLayout from "../../../../layouts/GameLayout";

import MultiPlay from "./MultiPlay";
import CreateLobby from "./CreateLobby";
import LobbyRoutes from "./LobbyRoutes";
import JoinLobby from "./JoinLobby";
import ErrorBoundary from "../../../../components/ErrorBoundary";
import { CenteredLoader } from "../../../../components/CenteredLoader";

export default function PlayTeamsRoutes() {
  PageTitle("Play 🎮 | Gibbly");
  PageIndex("noindex");

  return (
    <>
      <GameLayout height={"100dvh"} minHeight={"100vh"}>
        <Suspense fallback={<CenteredLoader />}>
          <Routes>
            <Route path=":quizId" element={<CreateLobby />} />
            <Route path="/lobby/:pin/*" element={<LobbyRoutes />} />
            {/* <Route path="/leaderboard/:pin" element={<LeaderboardView />} /> */}
            <Route path="/join" element={<EnterGamePin />} />
            <Route
              path="/join/:pin"
              element={
                <Suspense fallback={<CenteredLoader />}>
                  <ErrorBoundary fallback={<Navigate to={"/play/join"} />}>
                    <JoinLobby />
                  </ErrorBoundary>
                </Suspense>
              }
            />
            <Route path="/game/:pin/:teamId" element={<MultiPlay />} />
          </Routes>
        </Suspense>
      </GameLayout>
    </>
  );
}
