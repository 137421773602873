import { Button, Flex } from "@aws-amplify/ui-react";
import React from "react";
import { FaCalendar } from "react-icons/fa";
import useModal from "../hooks/useModal";
import Calendar from "react-calendar";

export default function CalenderButton({
  onChangeDate,
}: {
  onChangeDate: (date: Date) => void;
}) {
  const [CalendarModal, showModal] = useModal(
    {
      title: "Select a due date",
      ReactComponent: () => (
        <Flex justifyContent={'center'} alignItems={'center'}>
          <Calendar
            returnValue="end"
            onChange={(value) => {

              if (value instanceof Date) {
                onChangeDate(value);
                showModal(false);
              }
            }}
          />
        </Flex>
      ),
    },
    []
  );

  return (
    <>
      <CalendarModal />
      <Button
        id={"raised-btn"}
        onClick={() => {
          showModal(true);
        }}
      >
        <FaCalendar />
      </Button>
    </>
  );
}
