import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Slide } from "../types/slideTypes";
import { createSlide } from "../services/createSlide";
import { v4 } from "uuid";
import { useContext } from "react";
import { LessonContext } from "../components/LessonContextProvider";
import { toast } from "react-toastify";

export function useBatchAddSlides() {
  const queryClient = useQueryClient();

  const {
    lesson: { id: lessonId },
  } = useContext(LessonContext);

  return useMutation({
    mutationKey: ["slide", "add", "batch"],
    mutationFn: async ({
      newSlides,
      index,
    }: {
      newSlides: Omit<Slide, "id" | "createdAt" | "updatedAt" | "orderIndex">[];
      index: number;
    }) => {
      console.log("BATCH ADD SLIDES START");

      let start = 0;
      let interval = 0;
      const slides = queryClient.getQueryData<Slide[]>(["slides", lessonId]);
      if (slides === undefined) throw new Error("slides is undefined");
      if (slides.length === 0) {
        start = 0;
        interval = 1;
      } else {
        const prevSlide = slides[index - 1];
        const nextSlide = slides[index + newSlides.length];

        if (prevSlide === undefined) {
          start = nextSlide.orderIndex - newSlides.length;
          interval = 1;
        } else if (nextSlide === undefined) {
          start = prevSlide.orderIndex + 1;
          interval = 1;
        } else {
          interval =
            (nextSlide.orderIndex - prevSlide.orderIndex) /
            (newSlides.length + 1);
          start = prevSlide.orderIndex + interval;
        }
      }
      console.log("BATCH ADD SLIDES", { start, interval });
      const promises = newSlides.map(async (newSlide, i) => {
        const orderIndex = start + i * interval;
        await createSlide({ ...newSlide, orderIndex });
      });

      await Promise.all(promises);
    },
    onMutate: ({ newSlides: slides, index }) => {
      const newSlides: Slide[] = slides.map((s) => ({
        id: "temp_" + v4(),
        orderIndex: 0,
        temp: true,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        ...s,
      }));

      for (const newSlide of newSlides) {
        queryClient.setQueryData<Slide>(
          ["slide", lessonId, newSlide.id],
          newSlide
        );
      }
      queryClient.setQueryData<Slide[]>(["slides", lessonId], (prev) => {
        if (prev === undefined) return [...newSlides];
        const updatedSlides = [
          ...prev.slice(0, index),
          ...newSlides,
          ...prev.slice(index),
        ];
        return updatedSlides;
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(["slides"]);
    },
    onError: (error: Error) => {
      toast.error(error.message);
    },
  });
}
