import { Price, Product } from "../../types/stripe";
import RestAPI from "../../util/RestAPI";

export async function getProductAndPrices(productId: string) {
  try {
    const response: { product: Product; prices: { data: Price[] } } =
      (await RestAPI.get(`/stripe/products/${productId}`).then((res) =>
        res.json()
      )) as any as {
        product: Product;
        prices: { data: Price[] };
      };
    return { ...response };
  } catch (err) {
    throw err;
  }
}
