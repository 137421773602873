import * as React from "react";
import { SlidesContext } from "./SlidesContextProvider";
import { Button, View } from "@aws-amplify/ui-react";
import SlideView from "./editor/SlideView";

import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import RichTextContextProvider from "./RichTextContextProvider";
import { useScreenReaderContext } from "../../accessibility/context";
import { useSlideQuery } from "../hooks/useSlideQuery";
import { LessonContext } from "./LessonContextProvider";

export interface IPresentViewProps {
  transform?: string;
  autoPresent?: boolean;
}

export default function PresentView({
  // transform = "translate(-500%)",
  autoPresent = false,
}: IPresentViewProps) {
  const { nextSlide, prevSlide, slideId } = React.useContext(SlidesContext);
  const { lesson } = React.useContext(LessonContext);
  const [isPresenting, setIsPresenting] = React.useState<boolean>(autoPresent);

  const { elementRef, ScreenReadButton } = useScreenReaderContext();

  const { data: slideCurrent } = useSlideQuery({
    lessonId: lesson.id,
    slideId,
  });

  React.useEffect(() => {
    function handleFullscreenChange() {
      if (document.fullscreenElement) {
        setIsPresenting(true);
      } else {
        setIsPresenting(false);
      }
    }
    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  // Function that will be called when left arrow is pressed
  const handleLeftArrowPress = React.useCallback(() => {
    prevSlide();
  }, [prevSlide]);

  // Function that will be called when right arrow is pressed
  const handleRightArrowPress = React.useCallback(() => {
    nextSlide();
  }, [nextSlide]);

  const handleKeyDown = React.useCallback(
    (e: KeyboardEvent) => {
      switch (e.code) {
        case "ArrowLeft":
          handleLeftArrowPress();
          break;
        case "ArrowRight":
          handleRightArrowPress();
          break;
        default:
          break;
      }
    },
    [handleLeftArrowPress, handleRightArrowPress]
  );
  // Register keydown event listener
  React.useEffect(() => {
    if (isPresenting) window.addEventListener("keydown", handleKeyDown);
    else window.removeEventListener("keydown", handleKeyDown);
    // Cleanup
    return () => {
      if (isPresenting) window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isPresenting, handleKeyDown]);

  if (!slideCurrent) return null;

  return (
    <RichTextContextProvider>
      <View
        id={"presentation-view"}
        position={"absolute"}
        hidden={!isPresenting}
        // transform={transform}
        onClick={() => nextSlide()}
        width={"100%"}
        height={"100%"}
        backgroundColor={slideCurrent.background?.color ?? "white"}
      >
        <View
          id="backdrop-overlay"
          position={"relative"}
          width={"100%"}
          height={"100%"}
          backgroundColor={"rgba(25,25,25, 1)"}
        >
          <View ref={elementRef} width={"100%"} height={"100%"}>
            <SlideView
              isStatic
              slide={slideCurrent}
              idPrefix={"presentation-"}
            />
          </View>
          <View position={"absolute"} top={"small"} left={"small"}>
            <ScreenReadButton />
          </View>
          <View
            className={"presentation-ui"}
            // hidden={!isPresenting}
          >
            <Button
              variation="link"
              fontSize={"xl"}
              onClick={(e) => {
                e.stopPropagation();
                prevSlide();
              }}
              // style={{
              //   WebkitFilter: "drop-shadow(0 2px 0 white)",
              //   filter: "drop-shadow(0 2px 0 white)",
              // }}
            >
              <FaAngleLeft />
            </Button>
            <Button
              fontSize={"xl"}
              variation="link"
              onClick={(e) => {
                e.stopPropagation();
                nextSlide();
              }}
              // style={{
              //   WebkitFilter: "drop-shadow(0 2px 0 white)",
              //   filter: "drop-shadow(0 2px 0 white)",
              // }}
            >
              <FaAngleRight />
            </Button>
          </View>
        </View>
      </View>
    </RichTextContextProvider>
  );
}
