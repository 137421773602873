import { Icon } from '@aws-amplify/ui-react'
import React from 'react'

export default function AddIcon() {
    return (
        <Icon ariaLabel="Add" fill="currentcolor"
      pathData="M10.393 0.847c0.697 -0.024 1.363 -0.082 2.027 -0.063 1.421 0.041 2.784 0.359 4.102 0.905 1.462 0.606 2.714 1.499 3.796 2.642 1.376 1.454 2.257 3.176 2.768 5.103 0.327 1.233 0.37 2.495 0.261 3.741 -0.192 2.187 -0.998 4.16 -2.357 5.901 -1.166 1.494 -2.625 2.607 -4.349 3.369 -1.005 0.444 -2.051 0.782 -3.159 0.846 -0.828 0.048 -1.667 0.135 -2.487 0.059 -2.076 -0.192 -3.968 -0.914 -5.658 -2.159 -1.585 -1.168 -2.77 -2.649 -3.582 -4.433 -0.471 -1.034 -0.821 -2.11 -0.899 -3.249 -0.057 -0.839 -0.112 -1.686 -0.062 -2.523 0.064 -1.063 0.338 -2.098 0.738 -3.088 0.505 -1.253 1.205 -2.382 2.112 -3.396 1.375 -1.538 3.041 -2.602 4.995 -3.222 0.564 -0.179 1.149 -0.288 1.757 -0.43m9.204 12.794c0.007 -1.02 0.015 -2.04 0.019 -3.061 0 -0.043 -0.033 -0.085 -0.07 -0.176 -0.182 -0.339 -0.381 -0.672 -0.807 -0.73a22.191 22.191 0 0 0 -1.376 -0.143c-0.765 -0.056 -1.531 -0.086 -2.295 -0.156 -0.113 -0.01 -0.293 -0.177 -0.303 -0.283 -0.092 -1.04 -0.16 -2.083 -0.223 -3.125 -0.038 -0.621 -0.197 -1.156 -0.903 -1.422 -1.02 -0.007 -2.04 -0.015 -3.06 -0.019 -0.043 0 -0.085 0.033 -0.176 0.07 -0.341 0.181 -0.67 0.383 -0.73 0.807 -0.064 0.456 -0.11 0.916 -0.143 1.376 -0.056 0.765 -0.086 1.532 -0.156 2.295 -0.01 0.113 -0.177 0.293 -0.283 0.303 -1.017 0.092 -2.035 0.172 -3.055 0.218 -0.654 0.03 -1.204 0.18 -1.493 0.906 -0.007 1.02 -0.015 2.04 -0.019 3.061 0 0.043 0.033 0.085 0.062 0.18 0.122 0.405 0.399 0.664 0.795 0.774 0.175 0.049 0.372 0.012 0.558 0.028 1.058 0.091 2.116 0.195 3.175 0.274 0.232 0.017 0.258 0.143 0.272 0.312 0.025 0.312 0.047 0.623 0.071 0.935a1859.348 1859.348 0 0 0 0.212 2.661c0.033 0.415 0.353 0.757 0.83 0.871 1.02 0.007 2.04 0.015 3.06 0.019 0.043 0 0.085 -0.033 0.176 -0.07 0.332 -0.174 0.659 -0.372 0.726 -0.772 0.077 -0.466 0.113 -0.94 0.148 -1.412 0.056 -0.765 0.085 -1.531 0.156 -2.295 0.01 -0.113 0.177 -0.292 0.284 -0.302 1.017 -0.092 2.035 -0.172 3.055 -0.218 0.654 -0.03 1.204 -0.18 1.492 -0.906z">
      
         </Icon>
  )
}