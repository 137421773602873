import { Flex } from '@aws-amplify/ui-react';
import * as React from 'react';
import RefreshIcon from '../../../../components/icons/RefreshIcon';
import RaisedButton from '../../../../components/RaisedButton';

import { Avatar } from '../../Types/GameTypes_dep';
import { avatarColors, avatarGraphics } from '../../../../assets/avatars';
import { AvatarImage } from './AvatarImage';

export interface IAvatarPickerProps {
  avatar: Avatar;
  setAvatar: React.Dispatch<React.SetStateAction<Avatar>>
}

export function AvatarPicker(props: IAvatarPickerProps) {

  const { avatar, setAvatar } = props;


  return (
    <Flex position={'relative'} justifyContent={'center'} >
      <AvatarImage avatar={avatar} size={'150px'} />
      <RaisedButton position={'absolute'} top={'0'} right={'0'} onClick={() => {
        // generate ranomd number between 0 and 29
        const randomAvatarNumber = Math.floor(Math.random() * avatarGraphics.length);
        const randomColorIndex = Math.floor(Math.random() * avatarColors.length);
        setAvatar(prev => ({ ...prev, index: randomAvatarNumber, backgroundColor: avatarColors[randomColorIndex] }));
      }}>
        <RefreshIcon />
      </RaisedButton>
    </Flex>
  );
}
