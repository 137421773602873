import * as React from 'react';

export interface ITextMenuProps {
  
}

export default function TextMenu(props: ITextMenuProps) {

  const {} = props;

  return (
    <div>
      TextMenu
    </div>
  );
}
