import { Text, Flex, Grid, Card, View } from '@aws-amplify/ui-react'
import React from 'react'

export default function HomePageCard({ icon, title, content }: { icon: any, title: string, content: string }) {
    return (


        <Card>
            <Grid templateColumns={{base:"1fr", xl:"1fr 4fr"}} gap={"medium"}>
                <Flex alignItems={"center"} justifyContent={"center"}>
                    <Flex
                        justifyContent={"center"}
                        textAlign={"center"}
                        alignItems={"center"}
                        height="45px"
                        width={"45px"}
                        borderRadius={"100%"}
                    >
                        {icon}
                    </Flex>
                </Flex>
                <View>
                    <Text
                        color={"orange.60"}
                        fontWeight={"bold"}
                        fontSize={"medium"}
                    >
                        {title}
                    </Text>
                    <Text paddingTop={"xs"} fontSize={"xs"}>
                        {content}
                    </Text>
                </View>
            </Grid>
        </Card>



    )
}
