import { queryDatabaseItems } from "../../../services/database/queryDatabaseItems";
import { Player } from "../Types/GameTypes_dep";

export async function getTeamPlayers(teamId: string) {
  try {
    const result = await queryDatabaseItems<Player>("/player/byGroup", teamId);

    return result;
  } catch (err) {
    console.error(err);
    return undefined;
  }
}
