import React from "react";
import { Button, Flex, Grid, Heading, ScrollView } from "@aws-amplify/ui-react";

import { useNavigate } from "react-router-dom";

import { GamePlayMenu } from "./GamePlayMenu_new";

import { GameFooter } from "./GameFooter_new";
import GameCompleteView from "./GameCompleteView_Multi_new";
import { GameStatus, useGameContext } from "./GameContextProvider";
import { GameState } from "../../Types/GameTypes";
import { GameStartMenu } from "./GameStartMenu_new";
import { useTranslationContext } from "../../../../context/TranslationContextProvider";
import { useScreenReaderContext } from "../../../accessibility/context";

export type Feedback = {
  solution: string;
  acceptedAnswers: number[] | string[];
  isCorrect: boolean;
};

export default function GameView() {
  const navigate = useNavigate();

  const { questions, status, playMode, gameData } = useGameContext();

  const { continue_, retry } = useTranslationContext().translations;

  const { elementRef } = useScreenReaderContext();

  if (!questions || questions?.length === 0)
    return (
      <Flex
        direction={"column"}
        textAlign={"center"}
        justifyContent={"center"}
        padding={"xxl"}
        height={"100dvh"}
        minHeight={"100vh"}
      >
        <Heading fontWeight={"bold"} fontSize={{ base: "large", medium: "xl" }}>
          This quiz has no questions... 🤔
        </Heading>
        <Flex justifyContent={"center"}>
          <Button
            id={"raised-btn"}
            variation="primary"
            size="large"
            gap={"small"}
            onClick={() => navigate("/")}
          >
            Go Home
          </Button>
        </Flex>
      </Flex>
    );

  return (
    <ScrollView height={"100dvh"} minHeight={"100vh"}>
      <Grid
        height={"100dvh"}
        minHeight={"100vh"}
        paddingTop={"small"}
        templateRows={"auto 50px"}
        gap={"medium"}
      >
        {gameData.gameState === GameState.WAITING ? (
          <GameStartMenu />
        ) : status === GameStatus.INCOMPLETE ? (
          <Grid ref={elementRef} templateRows={"1fr"} gap={"small"}>
            <GamePlayMenu />
          </Grid>
        ) : (
          <GameCompleteView
            completeGameButtonText={
              playMode === "multi-player" ? continue_ : retry
            }
          />
        )}

        <GameFooter />
      </Grid>
    </ScrollView>
  );
}
