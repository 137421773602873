import RestAPI from "../../../../util/RestAPI";
import { AssignmentResult } from "../../types";

export async function listAssignmentResults(assignmentId: string) {
  try {
    const resultsList: AssignmentResult[] = (await RestAPI.get(
      "/assignmentResults/" + assignmentId,
      {}
    ).then((res) => res.json())) as any as AssignmentResult[];

    return resultsList;
  } catch (err) {

    throw err;
  }
}
