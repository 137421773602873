import React, { useEffect } from "react";
import { FaGraduationCap } from "react-icons/fa";
import { QuizContext } from "./QuizContextProvider";
import RaisedButton from "../../../components/RaisedButton";

import { useClassroomsList } from "../../class/hooks/useClassroomList";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createAssignment } from "../../class/util/assignment";
import { createClassroom } from "../../class/services";
import { toast } from "react-toastify";
import { Autocomplete, ComboBoxOption } from "@aws-amplify/ui-react";
import "./styles/ShareToClassroomButton.css";

export default function ShareToClassroomButton() {
  const { classrooms } = useClassroomsList();

  const { quiz } = React.useContext(QuizContext);

  const queryClient = useQueryClient();
  const searchRef = React.useRef<HTMLInputElement>(null);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [search, setSearch] = React.useState("");
  useEffect(() => {
    if (menuOpen) {
      searchRef.current?.click();
      searchRef.current?.focus();
    }
  }, [menuOpen]);

  const {
    mutateAsync: createAssignment_mutateAsync,
    isLoading: isCreatingAssignment,
  } = useMutation({
    mutationFn: ({
      classroomId,
      quizId,
      dueDate,
    }: {
      classroomId: string;
      quizId: string;
      dueDate: number;
    }) => createAssignment(classroomId, quizId, dueDate, Date.now()),
    onSuccess: (_, { classroomId }) => {
      // open in new tab
      window.open(`/classes/${classroomId}`);
    },
  });

  const {
    mutateAsync: createClassroom_mutateAsync,
    isLoading: isCreatingClassroom,
  } = useMutation({
    mutationFn: async (title: string) => {
      if (!quiz) throw new Error("No quiz to create classroom for");
      const classroom = await createClassroom(title);
      await createAssignment_mutateAsync({
        classroomId: classroom.id,
        quizId: quiz.id,
        dueDate: 0,
      });
      return classroom;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["classrooms"]);
    },
    onError: (error: Error) => {
      toast.error(error.message);
    },
  });

  const classroomOptions: ComboBoxOption[] = [
    ...(search
      ? [{ id: "new-class", label: `+ Create Class "${search}"` }]
      : []),
    ...(classrooms?.map((c) => ({
      id: c.id,
      label: c.title,
    })) ?? []),
  ];

  if (!quiz) {
    return null;
  }

  return !menuOpen ? (
    <RaisedButton
      backgroundColor={"blue.60"}
      whiteSpace={"nowrap"}
      gap={"small"}
      color={"white"}
      variation={"primary"}
      isLoading={isCreatingAssignment || isCreatingClassroom}
      loadingText="Adding to Class..."
      data-attr="share-or-play"
      data-ph-capture-attribute-type={"to-classroom"}
      data-ph-capture-attribute-quiz-id={quiz?.id}
      data-ph-capture-attribute-quiz-title={quiz?.title}
      data-ph-capture-attribute-quiz-creator={quiz?.creator}
      data-ph-capture-attribute-number-of-questions={
        quiz?.Questions?.items.length
      }
      data-ph-capture-attribute-quiz-privacy={quiz?.allowReadTo}
      data-ph-capture-attribute-quiz-language={quiz?.lang}
      data-ph-capture-attribute-quiz-created-at={quiz?.createdAt}
      data-ph-capture-attribute-quiz-updated-at={quiz?.updatedAt}
      onClick={() => {
        setMenuOpen(true);
      }}
    >
      <FaGraduationCap /> Add To Class
    </RaisedButton>
  ) : (
    <Autocomplete
      className="classroom-share-autocomplete"
      ref={searchRef}
      label="add-to-classroom-autocomplete"
      placeholder="Add to a class"
      value={search}
      textAlign={"start"}
      onChange={(e) => setSearch(e.target.value)}
      options={classroomOptions}
      onBlur={() => setMenuOpen(false)}
      style={{
        textAlign: "start",
      }}
      onSelect={(option) => {
        if (option.id === "new-class") {
          createClassroom_mutateAsync(search);
          setMenuOpen(false);
        } else {
          createAssignment_mutateAsync({
            classroomId: option.id,
            quizId: quiz.id,
            dueDate: 0,
          });
          setMenuOpen(false);
        }
      }}
    />
  );
}
