import React, { useMemo, useState } from "react";
import { deleteQuizQuestion, updateQuestion } from "../../../graphql/mutations";
import { toast } from "react-toastify";
import {
  Card,
  Flex,
  Text,
  Placeholder,
  Button,
  View,
  ButtonGroup,
} from "@aws-amplify/ui-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import {
  QuestionType,
  QuizQuestion,
  S3ObjectProtectedInput,
  UpdateQuestionInput,
} from "../../../API";
import QuestionItemButtons from "./QuestionItemButtons";
import QuestionItemType from "./QuestionItemType";
import QuestionItemText from "./QuestionItemText";
import QuestionItemAnswers from "./QuestionItemAnswers";

import ImageGallery from "./ImageGallery";
import useModal from "../../../hooks/useModal";
import QuestionItemSolution from "./QuestionItemSolution";
import EditQuestion from "./EditQuestion";
import { Draggable } from "../../../components/Draggable";
import { FaGripLines } from "react-icons/fa";
import { generateClient } from "aws-amplify/api";
import { ImageComponent } from "../../../components/ImageComponent";

import { useQuestionContext } from "../context";
import { ReadAloudButton } from "../../accessibility/components/ReadAloudButton";
import { PlayAudioButton } from "../../accessibility/components";

export default function QuestionItem({
  quizQuestionId,
  index,
  isCreator,
  quizQuestion,
  onDragStart,
  onDrag,
  onDragEnd,
}: {
  quizQuestionId: string;
  index: number;
  isCreator: boolean;
  quizQuestion: QuizQuestion;
  onDragStart?: (i: number) => void | Promise<void>;
  onDrag?: (e: React.DragEvent<HTMLDivElement>) => void | Promise<void>;
  onDragEnd?: (e: React.DragEvent<HTMLDivElement>) => void | Promise<void>;
}): JSX.Element {
  const [showAnswers, setShowAnswers] = useState(false);
  const [editingQuestion, setEditingQuestion] = useState(false);
  const [dragging, setDragging] = useState(false);

  const { question, updateQuestion: _updateQuestion } = useQuestionContext();

  const [DeleteQuestionModal, setShowDeleteQuestionModal] = useModal(
    {
      ReactComponent: () => (
        <Flex direction={"column"} gap={"medium"} grow={1}>
          <Text textAlign={"center"}>
            {"Are you sure? This action is irreversible."}
          </Text>
          <Flex grow={1}>
            <Button
              variation="primary"
              id="raised-btn"
              backgroundColor={"red.60"}
              flex={1}
              onClick={() => {
                removeQuestionMutation.mutate();
                setShowDeleteQuestionModal(false);
              }}
            >
              Yes
            </Button>
            <Button
              variation="primary"
              id="raised-btn"
              backgroundColor={"green.60"}
              flex={1}
              onClick={() => {
                setShowDeleteQuestionModal(false);
              }}
            >
              No
            </Button>
          </Flex>
        </Flex>
      ),
      initShow: false,
      title: "Delete Question?",
    },
    []
  );

  const queryClient = useQueryClient();

  const client = generateClient();

  const answers = useMemo(() => {
    if (question) return question.answers as string[];
    else return;
  }, [question?.answers]);

  const [ImageGalleryModal, setShowImageGalleryModal] = useModal(
    {
      size: "lg",
      ReactComponent: () => (
        <ImageGallery
          onUpload={async (image) => {
            _updateQuestion({ image });
            // await handleImageChange(image);
            setShowImageGalleryModal(false);
          }}
        />
      ),
    },
    []
  );

  async function tryDeleteQuestion(): Promise<GraphQLResult<any> | undefined> {
    try {
      // create
      const result = (await client.graphql({
        query: deleteQuizQuestion,
        variables: { input: { id: quizQuestionId } },
      })) as GraphQLResult<any>;
      if (result.data) {
        return result;
      } else throw new Error();
    } catch (err) {
      console.error("Delete Question Error: ", err);
      return;
    }
  }

  const removeQuestionMutation = useMutation({
    mutationFn: tryDeleteQuestion,
    onSuccess: () => {
      queryClient.refetchQueries(["quiz"]);
      queryClient.refetchQueries(["quiz questions"]);
    },
    onError: (error) => console.error(error),
  });

  const startEdittingQuestion = () => {
    if (!question) return;
    setEditingQuestion(true);
    setShowAnswers(true);
  };

  const saveQuestionChanges = async (
    updateQuestionInput?: UpdateQuestionInput
  ) => {
    setEditingQuestion(false);
    if (!question) return;

    let image: S3ObjectProtectedInput | null | undefined = null;
    if (question.image) {
      image = {
        key: question.image.key,
        identityId: question.image.identityId,
      };
    }

    try {
      const input: UpdateQuestionInput = updateQuestionInput
        ? updateQuestionInput
        : {
            id: question.id,
            text: question.text,
            answers: question.answers,
            correctIndices: question.correctIndices,
            solution: question.solution,
            type: question.type ? question.type : QuestionType.SINGLE_SELECT,
            image,
          };

      await client.graphql({ query: updateQuestion, variables: { input } });
      toast.success("Question updated!");
      queryClient.refetchQueries(["quiz questions"]);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  if (!question) {
    return <Placeholder height={"176px"} />;
  }

  return (
    <>
      <DeleteQuestionModal />
      <ImageGalleryModal />

      <Card
        // ref={componentRef}
        border={dragging ? "3px dotted orange" : undefined}
        opacity={removeQuestionMutation.isLoading ? 0.5 : 1}
        key={index}
        boxShadow={
          "0px -5px rgb(0 0 0 / 20%) inset, 0px 0px 4px rgb(0 0 0 / 15%"
        }
      >
        <Flex direction={{ base: "column", medium: "row" }}>
          <Flex
            justifyContent={"space-between"}
            alignItems={"center"}
            direction={{ base: "row", medium: "column" }}
            width={"30px"}
          >
            <i>
              <Text fontWeight={"bold"} textAlign={"center"}>
                Q{index + 1}
              </Text>
            </i>

            {isCreator && (
              <Draggable
                enabled={isCreator}
                dragIdKey={"drag-slide-key"}
                dragIdValue={quizQuestion.id}
                onDragStart={async () => {
                  setDragging(true);
                  await onDragStart?.(index);
                }}
                onDragEnd={async (e) => {
                  await onDragEnd?.(e);
                  setDragging(false);
                }}
                onDrag={onDrag}
              >
                <FaGripLines />
              </Draggable>
            )}
          </Flex>
          <Flex direction="column" flex={1}>
            <Card
              backgroundColor={"background.secondary"}
              padding={"small"}
              // onDoubleClick={() => {
              //   if (isCreator) startEdittingQuestion();
              // }}
            >
              {editingQuestion ? (
                <EditQuestion
                  onChange={_updateQuestion}
                  onSubmit={(questionInput) => {
                    _updateQuestion(questionInput as UpdateQuestionInput);
                    setEditingQuestion(false);
                  }}
                  index={index}
                  question={question}
                />
              ) : (
                <>
                  <Flex
                    direction={"column"}
                    gap={"medium"}
                    justifyContent={"space-between"}
                  >
                    <Flex
                      justifyContent={"space-between"}
                      direction={{ base: "column", xl: "row" }}
                    >
                      <Flex direction={"column"} gap={"small"} flex={1}>
                        <QuestionItemType />
                        <QuestionItemText />
                      </Flex>
                      <>
                        <View position={"relative"}>
                          {question.image?.key && (
                            <ImageComponent
                              alt={`Question ${index} Image`}
                              image={question.image}
                              canEdit={false}
                              autoPickPrompt=""
                              width={"160px"}
                              borderRadius={"large"}
                              height={"90px"}
                              objectFit={"cover"}
                              draggable={true}
                              onDragStart={async () => {
                                if (!isCreator) return;
                                setDragging(true);
                                await onDragStart?.(index);
                              }}
                              onDragEnd={async (e) => {
                                if (!isCreator) return;
                                await onDragEnd?.(e);
                                setDragging(false);
                              }}
                              onDrag={isCreator ? onDrag : undefined}
                            />
                          )}
                          <ButtonGroup
                            padding={"xxxs"}
                            gap={"xxxs"}
                            position={"absolute"}
                            bottom={"0"}
                            right={"0"}
                          >
                            {question.audio ? (
                              <PlayAudioButton
                                audio={question.audio}
                                padding={"0"}
                                height={"30px"}
                                borderRadius={"100px"}
                                backgroundColor={"black"}
                                color={"white"}
                                opacity={0.7}
                                style={{ aspectRatio: "1/1" }}
                                data-attr={"audio-preview-btn"}
                                data-ph-capture-attribute-type={"audio-file"}
                              />
                            ) : null}
                            {question.readAloudText ? (
                              <ReadAloudButton
                                text={question.readAloudText ?? ""}
                                padding={"0"}
                                height={"30px"}
                                borderRadius={"100px"}
                                backgroundColor={"black"}
                                color={"white"}
                                opacity={0.7}
                                style={{ aspectRatio: "1/1" }}
                                data-attr={"audio-preview-btn"}
                                data-ph-capture-attribute-audio-key={
                                  "read-aloud"
                                }
                              />
                            ) : null}
                          </ButtonGroup>
                        </View>
                      </>
                    </Flex>
                    {showAnswers && answers && (
                      <>
                        <QuestionItemAnswers
                          answers={answers}
                          question={question}
                        />

                        <QuestionItemSolution
                          solution={question.solution ?? ""}
                        />
                      </>
                    )}
                  </Flex>
                </>
              )}
            </Card>

            <QuestionItemButtons
              editingQuestion={false}
              saveQuestionChanges={saveQuestionChanges}
              isCreator={isCreator}
              setShowDeleteQuestionModal={setShowDeleteQuestionModal}
              startEdittingQuestion={startEdittingQuestion}
              showAnswers={showAnswers}
              setShowAnswers={setShowAnswers}
              question={question}
              quizQuestionId={quizQuestionId}
            />
          </Flex>
        </Flex>
      </Card>
    </>
  );
}
