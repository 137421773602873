import { View } from "@aws-amplify/ui-react";
import * as React from "react";
import {
  OverlayTrigger,
  OverlayTriggerProps,
  Tooltip,
  TooltipProps,
} from "react-bootstrap";

import "../styles/custom-tooltip.css";

export interface ITooltipOnHoverProps
  extends Omit<OverlayTriggerProps, "overlay" | "children"> {
  tooltipProps?: Omit<
    TooltipProps & React.RefAttributes<HTMLDivElement>,
    "children"
  >;
  tooltipChildren: React.ReactNode;
  children?: React.ReactNode;
}

export const TooltipView = React.forwardRef(
  (props: ITooltipOnHoverProps, ref: React.Ref<any>) => {
    const { tooltipChildren, tooltipProps, ...overlayTriggerProps } = props;

    const TooltipView = React.useMemo(
      () => (
        <Tooltip ref={ref} className="custom-tooltip" {...tooltipProps} style={{position:"fixed"}}>
          {tooltipChildren}
        </Tooltip>
      ),
      [tooltipChildren, tooltipProps, ref]
    );

    return (
      <OverlayTrigger {...overlayTriggerProps} overlay={TooltipView}>
        <View ref={ref}>{overlayTriggerProps.children}</View>
      </OverlayTrigger>
    );
  }
);
