import RestAPI from "../../../../util/RestAPI";
import { Assignment, PutAssignmentInput } from "../../types";
import { UpdateAssignmentObject } from "../../types/assignmentTypes";

export async function updateAssignment(
  assignment: Assignment,
  updateObject: UpdateAssignmentObject
) {
  let UpdateExpression = "SET ";
  let ExpressionAttributeValues: any = {};

  const updateKeys = Object.keys(updateObject);
  updateKeys.forEach((key, index) => {
    const placeholder = `:${key}`;
    UpdateExpression += `${key} = ${placeholder}`;

    ExpressionAttributeValues[placeholder] = updateObject[key];

    if (index !== updateKeys.length - 1) {
      UpdateExpression += ", ";
    }
  });




  const input: PutAssignmentInput = {
    Key: {
      classId: assignment.classId,
      id: assignment.id
    },
    ReturnValues: "ALL_NEW",
    UpdateExpression,
    ExpressionAttributeValues
  };

  try {
    const updatedAssignment: Assignment = await RestAPI.put("/assignments", { body: input }) as any as Assignment;
    return updatedAssignment;
  } catch (err) {

    return;
  }
}