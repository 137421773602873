import * as React from "react";
import SlideElementView from "./SlideElement";

import { SlideElement } from "../../types/slideTypes";

export interface ISlideElementCollectionProps {
  elements: SlideElement[];
}

export default function SlideElementCollection(
  props: ISlideElementCollectionProps
) {
  const { elements } = props;

  return elements.map((slideElement, index) => (
    <SlideElementView key={index} slideElement={slideElement} />
  ));
}
