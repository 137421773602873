import { useUserContext } from "../../../context";
import { listClassrooms } from "../services";
import { useQuery } from "@tanstack/react-query";

export function useClassroomsList() {
  const { user } = useUserContext();

  const { data: classrooms, ...classroomsQuery } = useQuery({
    queryKey: ["classrooms", "list", user?.identityId],
    queryFn: () => listClassrooms(),
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    enabled: !!user?.identityId,
  });

  return {
    classrooms,
    ...classroomsQuery,
  };
}
