import { Card, Flex, Grid, Loader, Text } from "@aws-amplify/ui-react";
import React, { useContext, useMemo } from "react";
import { Assignment } from "../types";
import { formatDueDate } from "../util/formatDueDate";
import getTimeSince from "../../../util/timeSinceUpdated";

import AssignmentPlayButton from "./AssignmentPlayButton";
import StudentContext from "../context/StudentContext";
import { useAssignmentResultsForStudent } from "../hooks/useStudentAssignmentResults";
import { ImageComponent } from "../../../components/ImageComponent";

export default function StudentAssignmentCard({
  assignment,
}: {
  assignment: Assignment;
}) {
  const student = useContext(StudentContext);

  const quiz = useMemo(() => assignment.quiz, [assignment.quiz]);

  const assignmentResults = useAssignmentResultsForStudent(
    assignment.id,
    student?.studentId
  );

  return (
    <>
      <Card id="raised-item" padding={"0"} width={"100%"}>
        <Grid
          padding={"0"}
          templateColumns={{
            base: "1fr",
            small: "1fr 1.5fr",
            medium: "1fr 2fr 2fr",
          }}
          templateRows={{
            base: "minmax(0,1fr)",
            small: "minmax(0,1fr)",
            medium: "160px",
          }}
        >
          <Flex rowSpan={2}>
            <ImageComponent
              canEdit={false}
              image={quiz.image}
              alt={""}
              autoPickPrompt={""}
              width={{ base: "100%", medium: "300px" }}
              height={{ base: "200px", small: "300px", medium: "160px" }}
              objectFit="cover"
              borderRadius={{
                base: "large large 0 0",
                small: "large 0 0 large",
                medium: "large 0 0 large",
              }}
            ></ImageComponent>
          </Flex>

          <Flex
            padding="small"
            paddingTop={"xxs"}
            paddingBottom={"0"}
            rowGap={"xxxs"}
            columnGap={"medium"}
            direction={"column"}
          >
            <Text
              id="sectionTitle"
              fontSize={{ base: "small", medium: "medium" }}
            >
              {!quiz ? <Loader /> : <>{quiz.title}</>}
            </Text>

            <Text
              minHeight={"3rem"}
              paddingBottom={"xxxs"}
              fontSize={{ base: "xs", large: "small" }}
            >
              <b>
                <i>Description: </i>
              </b>
              {quiz.description}
            </Text>
          </Flex>

          <Grid
            padding="small"
            paddingTop={"xxxs"}
            templateColumns={{ base: "3fr 1fr", medium: "1fr" }}
            // direction={{ base: "row", medium: "column" }}
            // justifyContent={"space-between"}
            rowGap={"0"}
          >
            <Flex direction={"column"} rowGap={"xxxs"}>
              <Text fontSize={{ base: "xs", large: "small" }}>
                <b>
                  <i>Assigned: </i>
                </b>
                {getTimeSince(assignment.assignDate)}
              </Text>

              <Text fontSize={{ base: "xs", large: "small" }}>
                <b>
                  <i>Due: </i>
                </b>
                {assignment.dueDate
                  ? formatDueDate(new Date(assignment.dueDate))
                  : "Not specified"}
              </Text>

              <Text fontSize={{ base: "xs", large: "small" }}>
                <b>
                  <i>Attempts: </i>
                </b>
                {assignmentResults.value?.length}
              </Text>

              <Text fontSize={{ base: "xs", large: "small" }}>
                <b>
                  <i>Best Mark: </i>
                </b>
                {assignmentResults.highestMark?.toFixed(1)} %
              </Text>
            </Flex>

            <Flex justifyContent={"right"}>
              {student && (
                <AssignmentPlayButton
                  assignment={assignment}
                  studentId={student.studentId}
                />
              )}{" "}
            </Flex>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
