import React from "react";
import { Flex, ScrollView, Grid, Card } from "@aws-amplify/ui-react";

import LeaderboardView from "./LeaderboardView";

import AudioController from "../../../../layouts/AudioController";
import useLobbyContext from "../../hooks/useLobbyContext";
import LobbyHeaderButtons from "./LobbyHeaderButtons";
import { useVolumeContext } from "../../../../context";

const leaderboardMusic = "/audio/music/Game - ArcadeGameBGM_2_Ingame.wav";

export default function Leaderboard() {
  const { lobby } = useLobbyContext();

  const { volume, setVolume, multiplier } = useVolumeContext();

  return (
    <>
      <ScrollView>
        <Flex
          direction={"column"}
          height={"100dvh"}
          minHeight={"100vh"}
          width={"100%"}
          gap={"medium"}
          rowGap={"xl"}
          alignItems={"center"}
        >
          <LobbyHeaderButtons exitTo="/play/join" />
          <LeaderboardView />

          <Grid
            templateColumns={"1fr auto 1fr"}
            justifyContent={"center"}
            padding={"small xl small xl"}
            position={"sticky"}
            width={"100%"}
            bottom={"0"}
          >
            <Flex column={3} justifyContent={"end"}>
              <Card backgroundColor={"rgba(0,0,0,0.5)"}>
                <AudioController
                  src={lobby?.playerMusic ? leaderboardMusic : undefined}
                  loop
                  autoPlay
                  volume={volume}
                  setVolume={setVolume}
                  volumeMultiplier={multiplier}
                ></AudioController>
              </Card>
            </Flex>
          </Grid>
        </Flex>
      </ScrollView>
    </>
  );
}
