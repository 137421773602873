import * as React from "react";
import { Draggable } from "../../../../components/Draggable";
import { TooltipView } from "../../../../components/TooltipView";
import { IPlayerAvatarProps, PlayerAvatar } from "./PlayerAvatar";
import { Button, ButtonGroup, View, Text } from "@aws-amplify/ui-react";
import { Player } from "../../Types/GameTypes_dep";
import useLobbyContext from "../../hooks/useLobbyContext";
import { FaExchangeAlt, FaUserPlus, FaUserTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import useModal from "../../../../hooks/useModal";
import { TeamSelect } from "./TeamSelect";

export interface IPlayerAvatarControlledProps extends IPlayerAvatarProps {
  player: Player;
  draggable?: boolean;
}

export default function PlayerAvatarControlled(
  props: IPlayerAvatarControlledProps
) {
  const { player, draggable = true, ...avatarProps } = props;

  const { updatePlayer, teams, lobby } = useLobbyContext();

  const team = teams?.find((t) => t.id === player.groupId);

  const [TeamSelectModal, setTeamSelectModal] = useModal(
    {
      ReactComponent: () => (
        <TeamSelect
          teams={teams}
          currentGroupId={team ? team.id : lobby?.id ?? ""}
          onSelectTeam={async (team) => {
            try {
              if (!player) return;
              const updatedPlayer = { ...player, groupId: team.id };
              updatePlayer(updatedPlayer);
            } catch (e) {
              if (e instanceof Error) toast.error(e.message);
            }
            setTeamSelectModal(false);
          }}
        />
      ),
      title: "Team Select",
    },
    [teams?.length, team?.id, player]
  );

  if (draggable)
    return (
      <>
        <TeamSelectModal />
        <Draggable
          key={player.userId}
          isDisabled={true}
          dragIdKey={"player"}
          dragIdValue={JSON.stringify(player)}
        >
          <TooltipView
            trigger={"focus"}
            placement="bottom"
            tooltipProps={{
              id: player.nickname + "-button-tooltip",
            }}
            tooltipChildren={
              <ButtonGroup gap={"xxxs"}>
                {player.playerStatus !== "KICKED" ? (
                  <>
                    <TooltipView tooltipChildren={<Text>Move</Text>}>
                      <Button
                        size="small"
                        onClick={async () => {
                          setTeamSelectModal(true);
                        }}
                      >
                        <FaExchangeAlt color="white" />
                      </Button>
                    </TooltipView>
                    {player.userId !== lobby?.hostId ? (
                      <TooltipView
                        tooltipChildren={<Text color="red.80">Kick</Text>}
                      >
                        <Button
                          size="small"
                          onClick={async () => {
                            await updatePlayer({
                              ...player,
                              playerStatus: "KICKED",
                            });
                          }}
                        >
                          <FaUserTimes color="white" />
                        </Button>
                      </TooltipView>
                    ) : null}
                  </>
                ) : (
                  <TooltipView tooltipProps={{}} tooltipChildren={"Unkick"}>
                    <Button
                      variation={"primary"}
                      size="small"
                      onClick={async () => {
                        await updatePlayer({
                          ...player,
                          playerStatus: "NORMAL",
                        });
                      }}
                    >
                      <FaUserPlus color="white" />
                    </Button>
                  </TooltipView>
                )}
              </ButtonGroup>
            }
          >
            <View tabIndex={0}>
              <PlayerAvatar player={player} {...avatarProps} />
            </View>
          </TooltipView>
        </Draggable>
      </>
    );
  else
    return (
      <>
        <TeamSelectModal />

        <TooltipView
          trigger={"focus"}
          placement="bottom"
          tooltipProps={{
            id: player.nickname + "-button-tooltip",
          }}
          tooltipChildren={
            <Button
              variation={
                player.playerStatus === "KICKED" ? "primary" : "destructive"
              }
              size="small"
              onClick={async () => {
                await updatePlayer({
                  ...player,
                  playerStatus:
                    player.playerStatus === "KICKED" ? "NORMAL" : "KICKED",
                });
              }}
            >
              {player.playerStatus === "KICKED" ? "Unkick" : "Kick"}
            </Button>
          }
        >
          <View tabIndex={0}>
            <PlayerAvatar player={player} {...avatarProps} />
          </View>
        </TooltipView>
      </>
    );
}
