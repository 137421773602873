import { S3ObjectProtected } from "../../../API";
import { getS3Url } from "../../../services/s3/getS3Url";

export async function getAudioUrl({ key, identityId }: S3ObjectProtected) {
  const url = await getS3Url({
    version: 1,
    key,
    identityId,
    level: "protected",
  }).then((res) => res.url.toString());
  return url;
}
