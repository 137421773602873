import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import * as React from "react";
import {
  QuizQuestion,
  UpdateQuizQuestionMutation,
  GetQuizQuery,
  UpdateQuizInput,
  Quiz,
} from "../../../API";
import { deleteQuiz } from "../../../graphql/mutations";
import { getQuiz } from "../../../graphql/queries";
import useEffectOnce from "../../../hooks/useEffectOnce";
import useUpdateEffect from "../../../hooks/useUpdateEffect";
import { updateQuizQuestionSortIndex } from "../../../services/custom-mutations";
import { getQuestionsFromQuiz } from "../../../services/custom-queries";
import { useQuizOwnership } from "../hooks/useQuizOwnership";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { useNavigate } from "react-router-dom";
import useModal from "../../../hooks/useModal";
import { generateClient } from "aws-amplify/api";
import { updateQuiz } from "../services/updateQuiz";
import useTimeout from "../../../hooks/useTimeout";
import CreateQuestionForm from "./CreateQuestionForm";

export interface IQuizContextProviderProps {
  children: React.ReactNode;
  id: string;
}

interface IQuizContext {
  isCreator: boolean;
  deleteQuiz: () => void;
  moveQuestion(fromIndex: number, toIndex: number): void;
  selectedQuestionIndex: number;
  setSelectedQuestionIndex: React.Dispatch<React.SetStateAction<number>>;
  localQuizQuestions: QuizQuestion[];
  setAddQuestionModal: (show: boolean) => void;
  refetchQuizQuestions: () => void;
  quiz: Quiz | undefined;
  updateQuiz: (input: Omit<UpdateQuizInput, "id">) => void;
  // saveQuiz: () => void;
  isQuizLoading: boolean;
  areQuestionsLoading: boolean;
}

export const QuizContext = React.createContext<IQuizContext>(
  {} as IQuizContext
);

export default function QuizContextProvider(props: IQuizContextProviderProps) {
  const navigate = useNavigate();
  const { id } = props;
  const client = generateClient();
  // const localQuizQuestions = useArray<QuizQuestion>([]);
  // const [isInitialized, setIsInitialized] = React.useState(false);
  const [selectedQuestionIndex, setSelectedQuestionIndex] =
    React.useState<number>(-1);

  const { isCreator } = useQuizOwnership(id ? id : "");

  const {
    data: quiz,
    isInitialLoading: isQuizLoading,
    refetch: refetchQuiz,
  } = useQuery({
    queryKey: ["quiz", id],
    queryFn: async () => {
      const result = (await client.graphql({
        query: getQuiz,
        variables: { id },
      })) as GraphQLResult<GetQuizQuery>;

      if (result.data?.getQuiz === null) throw new Error();
      else return result.data?.getQuiz;
    },
    onSuccess: () => {
      refetchQuizQuestions();
      // setLocalQuiz(data);
    },
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const queryClient = useQueryClient();

  const { reset, clear } = useTimeout(() => {
    queryClient.invalidateQueries(["quiz", id]);
  }, 1000 * 1);

  const { mutateAsync: updateQuiz_mutateAsync } = useMutation({
    mutationFn: async (variables: Omit<UpdateQuizInput, "id">) =>
      updateQuiz({ id: id, ...variables }),
    onMutate: async (variables) => {
      clear();
      queryClient.cancelQueries(["quiz", id]);
      queryClient.setQueryData<Quiz>(["quiz", id], (old) => {
        if (old) {
          return {
            ...old,
            ...(variables as Quiz),
          };
        }
        return old;
      });
    },
    onSettled: () => {
      reset();
    },
  });

  useEffectOnce(() => {
    refetchQuiz();
  });

  // useUpdateEffect(() => {
  //   if (isInitialized) {
  //     trySaveQuiz();
  //   }
  //   setIsInitialized(true);
  // }, [
  //   quiz?.allowReadTo,
  //   quiz?.image?.key,
  //   quiz?.image?.identityId,
  //   quiz?.title,
  //   quiz?.description,
  //   quiz?.lang,
  // ]);

  React.useEffect(() => {
    refetchQuiz();
    // setIsInitialized(false);
  }, [id]);

  const delelteQuizMutation = useMutation({
    mutationFn: async () => {
      try {
        // create
        const result = (await client.graphql({
          query: deleteQuiz,
          variables: { input: { id } },
        })) as GraphQLResult;
        if (result.data) {
          return result;
        } else throw new Error();
      } catch (err) {
        throw new Error("Quiz delete Error: " + err);
      }
    },
    onSuccess: () => {
      navigate("/dashboard/library");
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const {
    data: quizQuestions,
    isInitialLoading: areQuestionsLoading,
    refetch: refetchQuizQuestions,
  } = useQuery({
    queryKey: ["quiz questions", "list", id],
    queryFn: async () => {
      const result = (await client.graphql({
        query: getQuestionsFromQuiz,
        variables: { quizId: id },
      })) as GraphQLResult<GetQuizQuery>;

      return result.data?.getQuiz?.Questions?.items as QuizQuestion[];
    },
    onError: (error) => console.error(error),
  });

  const { mutateAsync: updateQuizQuestion } = useMutation({
    mutationFn: async (variables: { id: string; sortIndex: number }) => {
      try {
        const result = (await client.graphql({
          query: updateQuizQuestionSortIndex,
          variables,
        })) as GraphQLResult<UpdateQuizQuestionMutation>;
        if (result.data) {
          return result;
        } else {
          console.error(result);
          throw new Error("Error moving question");
        }
      } catch (err) {
        throw new Error("Error moving question: " + err);
      }
    },
    onMutate: async ({ id, sortIndex }) => {
      queryClient.cancelQueries(["quiz questions", "list", id]);
      queryClient.setQueryData<QuizQuestion[]>(
        ["quiz questions", "list", id],
        (old) => {
          if (old) {
            return old.map((qq) => {
              if (qq.id === id) {
                return {
                  ...qq,
                  sortIndex,
                };
              }
              return qq;
            });
          }
          return old;
        }
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(["quiz questions", "list", id]);
    },
  });

  useUpdateEffect(() => {
    fixQuizQuestions();
  }, [quizQuestions]);

  const fixQuizQuestions = React.useCallback(async () => {
    if (!quizQuestions) return;
    let qqCopy = [...quizQuestions];

    let valid = true;
    await Promise.all(
      qqCopy.map(async (quizQuestion, i) => {
        try {
          if (quizQuestion?.sortIndex !== i) {
            valid = false;
            await updateQuizQuestion({
              id: quizQuestion?.id,
              sortIndex: i,
            });
          }
        } catch (err) {
          throw new Error("Error moving question: " + err);
        }
      })
    );
    if (!valid) await refetchQuizQuestions();
  }, [quizQuestions]);

  async function moveQuizQuestion(fromIndex: number, toIndex: number) {
    if (!quizQuestions) return;
    if (toIndex < 0) return;

    queryClient.setQueryData<QuizQuestion[]>(
      ["quiz questions", "list", id],
      (old) => {
        if (old) {
          let newQuizQuestions = [...old];
          // move element[fromIndex] to element[toIndex]
          newQuizQuestions.splice(
            toIndex,
            0,
            newQuizQuestions.splice(fromIndex, 1)[0]
          );
          return newQuizQuestions;
        }
        return old;
      }
    );

    // let newQuizQuestions = [...quizQuestions];
    // // move element[fromIndex] to element[toIndex]
    // newQuizQuestions.splice(
    //   toIndex,
    //   0,
    //   newQuizQuestions.splice(fromIndex, 1)[0]
    // );

    // localQuizQuestions.set(newQuizQuestions);
  }

  // const trySaveQuiz = React.useCallback(async () => {
  //   toast.promise(
  //     async () => {
  //       try {
  //         // create
  //         if (!quiz) return;
  //         const extractUpdateQuizInput = extract<UpdateQuizInput>({
  //           id: true,
  //           creator: true,
  //           image: true,
  //           allowReadTo: true,
  //           title: true,
  //           description: true,
  //           tags: true,
  //           updatedAt: true,
  //           createdAt: true,
  //           lang: true,
  //         });

  //         // change image type oon quiz to S3ObjectProtectedInput
  //         let updatedQuiz = extractUpdateQuizInput(quiz);
  //         updatedQuiz.creator = `${updatedQuiz.creator}::${updatedQuiz.creator}`;
  //         updatedQuiz.image = await getS3ObjectProtectedInput(quiz.image);

  //         const input = updatedQuiz;

  //         const result = (await client.graphql({
  //           query: updateQuiz_mutation,
  //           variables: { input },
  //         })) as GraphQLResult<UpdateQuizMutation>;
  //         if (result.data) {
  //           return result;
  //         } else {
  //           console.error(result);
  //           throw new Error("Error saving ‼");
  //         }
  //       } catch (err: any) {
  //         console.error(err);
  //         return;
  //       }
  //     },
  //     {
  //       pending: "Saving...",
  //       success: "Saved!",
  //       error: "Error saving ‼",
  //     }
  //   );
  // }, [quiz]);

  const [AddQuestionModal, setAddQuestionModal] = useModal(
    {
      size: "xl",
      ReactComponent: () => (
        <CreateQuestionForm
          index={selectedQuestionIndex}
          quizId={quiz?.id}
          onSubmit={() => setAddQuestionModal(false)}
        />
      ),
    },
    [selectedQuestionIndex, quiz]
  );

  // if (isQuizLoading) {
  //   return <GibblyLoader />;
  // }

  // if (isError) {
  //   return <NoAccessPage />;
  // }

  return (
    <>
      <AddQuestionModal />
      <QuizContext.Provider
        value={{
          isCreator,
          deleteQuiz: delelteQuizMutation.mutate,
          moveQuestion: moveQuizQuestion,
          selectedQuestionIndex,
          setSelectedQuestionIndex,
          localQuizQuestions: quizQuestions ?? [],
          setAddQuestionModal,
          refetchQuizQuestions,
          quiz,
          updateQuiz: (input) => updateQuiz_mutateAsync(input),
          // saveQuiz: trySaveQuiz,
          isQuizLoading,
          areQuestionsLoading,
        }}
      >
        {props.children}
      </QuizContext.Provider>
    </>
  );
}
