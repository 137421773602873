import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import LobbyContextProvider from "./LobbyContextProvider";
import LobbyView from "./LobbyView";
import ErrorBoundary from "../../../../components/ErrorBoundary";
import { CenteredLoader } from "../../../../components/CenteredLoader";

export interface ILobbyRoutesProps {}

export default function LobbyRoutes(props: ILobbyRoutesProps) {
  const {} = props;

  return (
    <React.Suspense fallback={<CenteredLoader />}>
      <ErrorBoundary fallback={<Navigate to={"/play/join"} />}>
        <LobbyContextProvider>
          <Routes>
            <Route index element={<LobbyView />} />
          </Routes>
        </LobbyContextProvider>
      </ErrorBoundary>
    </React.Suspense>
  );
}
