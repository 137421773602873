import { View } from "@aws-amplify/ui-react";
import React from "react";

export default function GibblyLoader() {
  return (
    <View height={"50px"} width={'100%'}>
      <View id="custom-loader" >
        <View id="loader-shadow" />
        <View id="loader-box" />
      </View>
    </View>
  );
}
