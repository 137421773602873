import { View, Text } from "@react-pdf/renderer";
import { styles } from "./QuizPDFRenderer_Styles";
import React from "react";
import { QuestionType, QuizQuestion } from "../../../API";
import { useTranslationContext } from "../../../context/TranslationContextProvider";

export default function QuizPDFRenderer_AnswerKeyItem({
  quizQuestion,
}: {
  quizQuestion: QuizQuestion;
}) {
  if (quizQuestion.question?.type === QuestionType.TYPED_ANSWER) {
    const { translations } = useTranslationContext();

    return (
      <View style={styles.answerGroup}>
        <Text style={styles.info}>{translations.accepted_answers}</Text>
        {quizQuestion.question?.answers.map(
          (answer, j: number) =>
            answer !== "" && (
              <View key={j} style={styles.rowFlex}>
                <View
                  style={
                    quizQuestion?.question?.correctIndices.includes(j)
                      ? styles.correct
                      : styles.incorrect
                  }
                >
                  <View style={styles.rowFlex}>
                    <Text style={styles.itemContent}>{answer}</Text>
                  </View>
                </View>
              </View>
            )
        )}
      </View>
    );
  } else {
    return (
      <View style={styles.answerGroup}>
        {quizQuestion.question?.answers.map((answer, j: number) => (
          <View key={j} style={styles.rowFlex}>
            <View
              style={
                quizQuestion?.question?.correctIndices.includes(j)
                  ? styles.correct
                  : styles.incorrect
              }
            >
              <View style={styles.rowFlex}>
                <Text style={styles.bulletPoint}>
                  {String.fromCharCode(97 + j)}.
                </Text>
                <Text style={styles.itemContent}>{answer}</Text>
              </View>
            </View>
          </View>
        ))}
      </View>
    );
  }
}
