import React, { useContext, useMemo } from "react";
import useChatStream from "../../../../../../hooks/useChatStream";
import { Slide } from "../../../../types/slideTypes";
import { Lesson } from "../../../../types/lessonTypes";
import useUpdateEffect from "../../../../../../hooks/useUpdateEffect";
import {
  SlideElementsTemplate,
  getTemplatedElements,
} from "../../../../util/getTemplatedElements";
import { createSlideObject } from "../../../../util/createSlideObject";
import { LessonContext } from "../../../LessonContextProvider";

interface Props {
  useExistingTopic: boolean;
  topicLocal: string;
  lesson: Lesson;
}

const useTitleSlideGenerator = (props: Props) => {
  const { useExistingTopic, lesson, topicLocal } = props;

  const [slide, setSlide] = React.useState<Slide>(
    createSlideObject(
      lesson.id,
      getTemplatedElements(SlideElementsTemplate.Title, { headerText: "" })
    )
  );
  const { outputLang } = useContext(LessonContext);

  // Define your state variables using useState
  const { connect, loading, output } = useChatStream({
    streamType: "full",
    type: "slide-titles",
    language: outputLang,
    think: false,
    prompt: {
      topic: useExistingTopic ? lesson.topic : topicLocal,
      gradeLevel: lesson.gradeLevel,
    },
    // onClose: () => {

    // },
    // onError: (error) => {

    // },
  });
  const contents = useMemo(() => output[0] ?? "", [output]);

  // useUpdateEffect(() => {

  // }, [output]);

  useUpdateEffect(() => {
    setSlide(() => {
      const elements = getTemplatedElements(SlideElementsTemplate.Title, {
        headerText: contents,
      });

      const slide: Slide = createSlideObject(lesson.id, elements);

      return slide;
    });
  }, [contents]);

  // Return the state variables and any functions that will update them
  return {
    connect,
    loading,
    slide,
  };
};

export default useTitleSlideGenerator;
