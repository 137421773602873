import { useQuery } from "@tanstack/react-query";
import * as React from "react";
import { useLocation } from "react-router-dom";
import RestAPI from "../../../../util/RestAPI";
import useUrl from "../../../../hooks/useUrl";
import { CenteredLoader } from "../../../../components/CenteredLoader";

export interface ISlidesConsentHandlerProps {}

export default function SlidesConsentHandler() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const code = queryParams.get("code");
  const error = queryParams.get("error");

  const { root } = useUrl();

  React.useEffect(() => {
    if (error) {
      // eslint-disable-next-line no-restricted-globals
      opener.postMessage({ error, type: "CONSENT_ERROR" }, root);
      // eslint-disable-next-line no-restricted-globals
      close();
    }
  }, [code, error]);

  useQuery(
    ["Google Slides Tokens", code],
    async () => {
      const response = await RestAPI.get("/google/slides/callback", {
        queryParams: {
          code,
        },
      }).then((res) => res.json());
      return response;
    },
    {
      onSettled: (data, error) => {
        if (error) {
          console.error(error);
          // eslint-disable-next-line no-restricted-globals
          opener.postMessage({ error, type: "CONSENT_ERROR" }, root);
        } else if (data) {
          const { tokens } = data as any; // TODO: Type this
          // eslint-disable-next-line no-restricted-globals
          opener.postMessage({ tokens, type: "SLIDES_TOKENS" }, root);
        }
        // eslint-disable-next-line no-restricted-globals
        close();
      },
    }
  );

  return <CenteredLoader />;
}
