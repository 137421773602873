import { useMemo } from "react";

import { calculateScore } from "../util/assignmentResult/calculateScore";
import { AnswerResult } from "../../game/Types/GameTypes";

export function useAnswerResultsAnalytics(answerResults: AnswerResult[]) {

  const score = useMemo(() => calculateScore(answerResults), [answerResults]);
 
  const numCorrect = useMemo(() => answerResults.filter((answerResult) => answerResult.isCorrect).length, [answerResults]);

  const total = useMemo(() => answerResults.length, [answerResults]);

  return { score, numCorrect, total};
}
