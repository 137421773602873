import RestAPI from "../../../util/RestAPI";
import {
  PostReferralInput,
  Referral,
  ReferralStatus,
} from "../types/referralTypes";
import { getReferrerId } from "./getReferrerId";

export async function createRefferalWithCode(
  referralCode: string,
  referredId: string
) {
  try {
    const referrerId = await getReferrerId(referralCode);
    if (referrerId === undefined) throw new Error("Referrer not found");

    const input: PostReferralInput = {
      referrerId,
      referredId,
      status: ReferralStatus.ACCEPTED,
    };
    const referral: Referral = await RestAPI.post("/referrals", {
      body: input,
    }).then((response) => response.json() as any as Referral);
    return referral;
  } catch (err) {
    console.error("Failed to create referral:", err);
    throw err;
  }
}
