import { invokeLambdaFunction } from "./awsFacade";

export type AutoPickedImage = {
  site: "Pexels" | "Pixabay";
  src: string;
  previewSrc: string;
  author: string;
  pageUrl: string;
  alt: string;
  width: number;
  height: number;
};

export const autoPickImage = async (
  prompt: string,
  identityId: string | undefined
) => {
  try {
    if (!identityId) throw new Error("identityId is required");

    // const queryParams = new URLSearchParams({ text: prompt, identityId });

    const response = (await invokeLambdaFunction("AutoPickImages", {
      httpMethod: "GET",
      path: "/auto-pick-images/small",
      queryStringParameters: {
        identityId,
        text: prompt,
      },
    })
      // const response = (await RestAPI.get(
      //   `/auto-pick-images/small?${queryParams.toString()}`
      // ).
      .then((res) => {
        return res.body ? JSON.parse(res.body) : [];
      })) as any;
    return response as AutoPickedImage[];
  } catch (error) {
    console.error(error);
    throw new Error("Error while picking image");
  }
};
