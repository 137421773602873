import { Icon } from '@aws-amplify/ui-react'
import React from 'react'

export default function SearchIcon() {
    return (
        <Icon ariaLabel="Search" fill="currentcolor"
      pathData="M9.667 1.768c1.309-.072 2.542.171 3.711.649.739.302 1.446.697 2.065 1.235.71.616 1.338 1.292 1.83 2.094.599.976 1.023 2.017 1.166 3.159.061.482.128.97.121 1.454a8.256 8.256 0 0 1-.791 3.417c-.087.186-.118.299.082.456.82.643 1.619 1.31 2.424 1.971.606.498 1.207 1.002 1.816 1.497.493.401.531 1.253.054 1.698-.9.839-1.776 1.708-2.611 2.611-.578.625-1.425.535-1.966-.176-.511-.672-1.06-1.317-1.592-1.973l-1.799-2.218c-.819.39-1.661.727-2.576.874a9.935 9.935 0 0 1-2.514.061c-.712-.066-1.386-.252-2.047-.506a8.306 8.306 0 0 1-2.246-1.303c-1.074-.867-1.903-1.928-2.417-3.201a10.204 10.204 0 0 1-.592-2.173c-.107-.654-.111-1.337-.069-2.002.057-.889.286-1.75.648-2.572a8.392 8.392 0 0 1 2.213-3.021c.931-.801 1.973-1.427 3.182-1.71.617-.144 1.25-.216 1.908-.321M7.812 6.507c-.384.358-.807.683-1.144 1.081-.655.773-.91 1.72-.906 2.709.003.738.203 1.457.602 2.098.506.814 1.174 1.435 2.064 1.833.6.269 1.211.306 1.844.298a3.965 3.965 0 0 0 2.681-1.061c1.138-1.033 1.665-2.316 1.45-3.874-.177-1.283-.817-2.307-1.871-2.996-1.507-.986-3.096-1.02-4.721-.09z">
      
         </Icon>
  )
}