import * as React from "react";
import { Heading, Button, Flex } from "@aws-amplify/ui-react";
import { FaInstagram, FaPinterest} from "react-icons/fa";
import FacebookIcon from "../components/icons/FacebookIcon";
import LinkedInIcon from "../components/icons/LinkedInIcon";
import ThreadsIcon from "../components/icons/ThreadsIcon";
import YouTubeIcon from "../components/icons/YouTubeIcon";
import RaisedCard from "../components/RaisedCard";
import { FaXTwitter } from "react-icons/fa6";

export interface ISocialsCardProps { }

export default function SocialsCard(props: ISocialsCardProps) {
  const { } = props;

  return (
    <RaisedCard>
      <Heading id="sectionTitle">Socials</Heading>
      <Flex gap={"xs"} wrap={"wrap"}>
        <a
          href="https://twitter.com/GibblyCo"
          target={"_blank"}
          rel="noreferrer"
        >
          <Button
            fontSize="larger"
            padding={"xxs"}
            color={"teal.60"}
            variation="link"
          >
            <FaXTwitter />
          </Button>
        </a>

        <a
          href="https://www.facebook.com/gibbly.co/"
          target={"_blank"}
          rel="noreferrer"
        >
          <Button
            fontSize="larger"
            padding={"xxs"}
            color={"teal.60"}
            variation="link"
          >
            <FacebookIcon />
          </Button>
        </a>

        <a
          href="https://www.instagram.com/Gibbly.co/"
          target={"_blank"}
          rel="noreferrer"
        >
          <Button
            fontSize="larger"
            padding={"xxs"}
            color={"teal.60"}
            variation="link"
          >
            <FaInstagram />
          </Button>
        </a>

        <a
          href="https://www.threads.net/@gibbly.co"
          target={"_blank"}
          rel="noreferrer"
        >
          <Button
            fontSize="larger"
            padding={"xxs"}
            color={"teal.60"}
            variation="link"
          >
            <ThreadsIcon />
          </Button>
        </a>

        <a
          href="https://www.youtube.com/@GibblyCo"
          target={"_blank"}
          rel="noreferrer"
        >
          <Button
            fontSize="larger"
            padding={"xxs"}
            color={"teal.60"}
            variation="link"
          >
            <YouTubeIcon />
          </Button>
        </a>

        <a
          href="https://www.pinterest.ca/GibblyCo/"
          target={"_blank"}
          rel="noreferrer"
        >
          <Button
            fontSize="larger"
            padding={"xxs"}
            color={"teal.60"}
            variation="link"
          >
            <FaPinterest />
          </Button>
        </a>

        <a
          href="https://www.linkedin.com/company/91172418/admin/feed/posts/"
          target={"_blank"}
          rel="noreferrer"
        >
          <Button
            fontSize="larger"
            padding={"xxs"}
            color={"teal.60"}
            variation="link"
          >
            <LinkedInIcon />
          </Button>
        </a>
      </Flex>
    </RaisedCard>
  );
}
