import { GraphQLQuery } from "@aws-amplify/api";
import {
  CreateQuizQuestionInput,
  CreateQuizQuestionMutation,
  QuizQuestion,
} from "../../../API";
import { generateClient } from "aws-amplify/api";
// import { createQuizQuestion as mutation } from "../../../graphql/mutations";

const mutation = /* GraphQL */ `
  mutation CreateQuizQuestion(
    $input: CreateQuizQuestionInput!
    $condition: ModelQuizQuestionConditionInput
  ) {
    createQuizQuestion(input: $input, condition: $condition) {
      id
      quizId
      questionId
      question {
        id
        image {
          key
          identityId
          alt
        }
        audio {
          key
          identityId
        }
        readAloudText
        text
        type
        solution
        answers
        correctIndices
        createdAt
        updatedAt
        owner
      }
      sortIndex
      updatedAt
      createdAt
      owner
    }
  }
`;

export default async function createQuizQuestion(
  createQuizQuestionObject: CreateQuizQuestionInput
) {
  const client = generateClient();


  const createQuizQuestionResponse = await client.graphql<
    GraphQLQuery<CreateQuizQuestionMutation>
  >({
    query: mutation,
    variables: { input: { ...createQuizQuestionObject } },
  });

  const QuizQuestion: QuizQuestion | undefined | null =
    createQuizQuestionResponse?.data?.createQuizQuestion;

  if (QuizQuestion === undefined || QuizQuestion === null) {
    throw new Error(
      "There was an error creating QuizQuestion. Please try again."
    );
  }

  return QuizQuestion;
}
