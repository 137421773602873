import { useQuery } from "@tanstack/react-query";
import { getEnvironmentQuery } from "../util/query/getEnvironmentQuery";

export default function useEnvironment() {
  const environmentQuery = useQuery(getEnvironmentQuery());

  return {
    ...environmentQuery,
    environment: environmentQuery.data as string,
  };
}
