import * as React from "react";
import { Slide } from "../types/slideTypes";
import { createContext } from "react";
import { LessonContext } from "./LessonContextProvider";
import { updateSlide } from "../services/updateSlide";

import { createSlide } from "../services/createSlide";
import { createSlideObjectDefault } from "../util/createSlideObject";
import { useScreenReaderContext } from "../../accessibility";
import useUnloadPrevention from "../../../hooks/useUnloadPrevention";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { querySlides } from "../services/querySlides";
import { calculateOrderIndex } from "../util/calculateOrderIndex";

interface ISlidesContext {
  slideIndex: number;
  slidePreview: Slide | undefined;
  setSlidePreview: (slide: Slide | undefined) => void;
  setBackgroundColor: (color: string | undefined) => void;
  moveSlide: (slideIndex: number, targetIndex: number) => void;
  nextSlide: () => void;
  prevSlide: () => void;
  setSlideIndex: (i: number) => void;
  slideIds: string[];
  slideId: string;

  updateSlideMutation: UseMutationResult<
    void,
    unknown,
    {
      lessonId: string;
      slideId: string;
      updateSlideObject: Partial<Slide>;
    },
    unknown
  >;
  status: "saving" | "saved";
  // slidesUndo: () => void;
  // slidesRedo: () => void;
}

export const SlidesContext = createContext<ISlidesContext>(
  {} as ISlidesContext
);

export interface ISlidesContextProviderProps {
  children: React.ReactNode;
  slidesServer: Slide[] | undefined;
  lessonId: string;
}

export default function SlidesContextProvider(
  props: ISlidesContextProviderProps
) {
  const { children, lessonId } = props;
  const queryClient = useQueryClient();

  const [slideIndex, setSlideIndex] = React.useState<number>(0);
  const [slidePreview, setSlidePreview] = React.useState<Slide>();

  const { stop } = useScreenReaderContext();
  const { lesson } = React.useContext(LessonContext);

  const [isSaving, setIsSaving] = React.useState(false);

  const { data: _slides, isFetching: isFetchingSlides } = useQuery({
    queryKey: ["slides", lesson.id],
    queryFn: async () => {
      const slides = await querySlides(lessonId);
      const sortedSlides = slides.sort((a, b) => a.orderIndex - b.orderIndex);
      if (sortedSlides.length === 0) {
        await createSlide({
          ...createSlideObjectDefault(lessonId),
          orderIndex: 0,
        });
        return await querySlides(lessonId);
      }

      for (const slide of sortedSlides) {
        queryClient.setQueryData<Slide>(["slide", lesson.id, slide.id], slide);
      }
      return sortedSlides;
    },
    suspense: true,
  });
  useUnloadPrevention(isSaving || isFetchingSlides);

  const slides = React.useMemo(() => _slides ?? [], [_slides]);

  const slideId = React.useMemo(
    () => slides[slideIndex]?.id,
    [slides, slideIndex]
  );

  React.useEffect(() => {
    const mutationCache = queryClient.getMutationCache();

    mutationCache.subscribe(({ type, mutation }) => {
      const mutationKey = mutation?.options.mutationKey;
      if (!mutationKey) return;
      if (
        mutationKey.includes("slide") ||
        mutationKey.includes("element") ||
        mutationKey.includes("lesson")
      ) {
        if (type === "updated") {
          const loading = mutationCache
            .findAll({
              predicate: (mutation) =>
                mutation.options.mutationKey
                  ? mutation.options.mutationKey?.includes("slide") ||
                    mutation.options.mutationKey?.includes("element") ||
                    mutation.options.mutationKey?.includes("lesson")
                  : false,
            })
            .some((mutation) => mutation.state.status === "loading");
          setIsSaving(loading);
        }
      }
    });
  }, []);

  const updateSlideMutation = useMutation({
    mutationKey: ["slide", "update"],
    mutationFn: async ({
      lessonId,
      slideId,
      updateSlideObject,
    }: {
      lessonId: string;
      slideId: string;
      updateSlideObject: Partial<Slide>;
    }) => {
      await updateSlide(lessonId, slideId, updateSlideObject);
    },
    onMutate: ({ lessonId, slideId, updateSlideObject }) => {
      queryClient.setQueryData<Slide>(["slide", lessonId, slideId], (prev) =>
        prev
          ? {
              ...prev,
              ...updateSlideObject,
            }
          : undefined
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(["slides"]);
    },
  });

  React.useEffect(() => {
    setSlidePreview(undefined);
  }, [slideIndex]);

  const setBackgroundColor = (color: string | undefined) => {
    updateSlideMutation.mutate({
      lessonId: lesson.id,
      slideId: slides[slideIndex].id,
      updateSlideObject: { background: { color } },
    });
  };

  const moveSlide = (slideIndex: number, destIndex: number) => {
    console.log({
      slideIndex,
      destIndex,
    });
    const moveBack = slideIndex > destIndex;
    const slides = queryClient.setQueryData<Slide[]>(
      ["slides", lesson.id],
      (prev) =>
        prev
          ? moveBack
            ? [
                ...prev.slice(0, destIndex),
                prev[slideIndex],
                ...prev.slice(destIndex, slideIndex),
                ...prev.slice(slideIndex + 1),
              ]
            : [
                ...prev.slice(0, slideIndex),
                ...prev.slice(slideIndex + 1, destIndex),
                prev[slideIndex],
                ...prev.slice(destIndex),
              ]
          : prev
    );
    if (!slides) return;
    const orderIndex = calculateOrderIndex(
      slides,
      moveBack ? destIndex : destIndex - 1
    );
    console.log({
      slides,
      orderIndex,
    });
    setSlideIndex(moveBack ? destIndex : destIndex - 1);
    updateSlideMutation.mutate({
      lessonId: lesson.id,
      slideId: slides[moveBack ? destIndex : destIndex - 1].id,
      updateSlideObject: { orderIndex },
    });
  };

  const nextSlide = React.useCallback(() => {
    if (slideIndex + 1 < slides.length) {
      setSlideIndex(slideIndex + 1);
    }
    stop();
  }, [slideIndex, slides, stop]);

  const prevSlide = React.useCallback(() => {
    if (slideIndex - 1 >= 0) {
      setSlideIndex(slideIndex - 1);
    }
    stop();
  }, [slideIndex, stop]);

  React.useEffect(() => {
    // check that slideIndex is within bounds
    if (slideIndex >= slides.length) {
      setSlideIndex(slides.length - 1);
    }
  }, [slideIndex, slides.length]);

  return (
    <SlidesContext.Provider
      value={{
        status: isFetchingSlides || isSaving ? "saving" : "saved",

        slideIndex,
        slidePreview,
        setBackgroundColor,
        setSlidePreview,
        nextSlide,
        moveSlide,
        prevSlide,

        updateSlideMutation,
        slideIds: slides?.map((slide) => slide.id) || [],
        slideId,
        setSlideIndex: (index: number) => {
          if (index < 0) setSlideIndex(0);
          else if (index >= slides.length) setSlideIndex(slides.length - 1);
          else setSlideIndex(index);
        },
      }}
    >
      {children}
    </SlidesContext.Provider>
  );
}
