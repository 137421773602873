import * as React from "react";
import useChatStream from "../../../hooks/useChatStream";
import { Loader, Flex, TextAreaField, Text, View } from "@aws-amplify/ui-react";
import RaisedCard from "../../../components/RaisedCard";
import {
  CurriculumPrompt,
  DocumentPrompt,
  PastePrompt,
  TopicPrompt,
  YouTubePrompt,
} from "../types";

export interface ITitleGeneratorProps {
  title: string;
  setTitle: (title: string) => void;
  type: "quiz-title-yt" | "quiz-title" | "quiz-title-doc" | "quiz-title-paste";
  lang: string;
  setTitleLoading: (loading: boolean) => void;
  prompt:
    | TopicPrompt
    | PastePrompt
    | DocumentPrompt
    | CurriculumPrompt
    | YouTubePrompt;
}

export interface TitleGeneratorHandle {
  generateTitle: () => Promise<void>;
}

const characterLimit = 95;

const TitleGenerator = React.forwardRef<
  TitleGeneratorHandle,
  ITitleGeneratorProps
>((props, ref) => {
  const { title, setTitle, type, setTitleLoading, lang, prompt } = props;

  // const initialMessage = React.useMemo(
  //   () => JSON.stringify({ type, input }),
  //   [props]
  // );

  const { connect, loading, output } = useChatStream({
    streamType: "full",
    type: type,
    prompt: prompt,
    language: lang,
  });

  React.useEffect(() => {
    setTitleLoading(loading);
  }, [loading]);

  React.useImperativeHandle(ref, () => ({
    generateTitle,
  }));

  React.useEffect(() => {
    if (output[0]) {
      setTitle(output[0]);
    }
  }, [output[0]]);

  const generateTitle = async () => {
    await connect();
  };

  return (
    <>
      {(loading || output[0]) && (
        <RaisedCard borderRadius={"medium"}>
          <b>
            <TextAreaField
              maxLength={characterLimit}
              rows={2}
              resize="vertical"
              variation="quiet"
              isDisabled={loading}
              label={
                <Flex gap={"small"} justifyContent={"space-between"}>
                  <View>{loading && <Loader alignSelf={"center"} />}</View>
                  <Text
                    fontSize={"small"}
                    fontWeight={"normal"}
                    color={"GrayText"}
                  >
                    {" "}
                    {title.length}/{characterLimit}
                  </Text>
                </Flex>
              }
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </b>
        </RaisedCard>
      )}
    </>
  );
});

export default TitleGenerator;
