import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Flex,
  Button,
  TextField,
  Card,
  Authenticator,
  TextAreaField,
  Grid,
  View,
} from "@aws-amplify/ui-react";
import { createPrivateQuiz } from "../../../services/custom-mutations";
import { PageIndex, PageTitle } from "../../../util/SEO";
import {
  CreateQuizInput,
  CreateQuizMutation,
  S3ObjectProtected,
  S3ObjectProtectedInput,
} from "../../../API";
import { getIdentityId } from "../../game/util";
import { ImageComponent } from "../../../components/ImageComponent";
import LanguageSelect from "../../../components/LanguageSelect";
import { generateClient, GraphQLQuery } from "aws-amplify/api";

const NewQuizForm = ({ onSuccess }: { onSuccess?: () => void }) => {
  PageTitle("Create Game | Gibbly");
  PageIndex("noindex");

  const navigate = useNavigate();
  const [inputHasError, setInputHasError] = useState(false);
  const [inputHasErrorMessage, setInputHasErrorMessage] = useState("");

  const [image, setImage] = useState<S3ObjectProtected>();

  const [lang, setLang] = useState("en"); // not sending lag with quiz creationg

  const [isCreatingQuiz, setIsCreatingQuiz] = useState(false);

  function validateInput() {
    if (quiz.title.length > 30) {
      setInputHasError(true);
      setInputHasErrorMessage("Must be less than 30 characters");
      return false;
    }
    if (quiz.title === "") {
      setInputHasError(true);
      setInputHasErrorMessage("Can't be empty");
      return false;
    }
    setInputHasError(false);
    return true;
  }

  const [quiz, setQuiz] = useState({
    title: "",
    description: "",
    tags: [],
  });

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setQuiz({ ...quiz, [name]: value });
  };

  const onSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (validateInput()) {
      await createQuiz({ ...quiz, lang });
    }
  };

  // TODO: fix types
  async function createQuiz(quiz: CreateQuizInput) {
    // Get unique cognito identity id
    setIsCreatingQuiz(true);
    const identityId = await getIdentityId();
    const imageInput: S3ObjectProtectedInput = image
      ? {
          key: image.key,
          identityId: image.identityId,
        }
      : {
          key: "",
          identityId,
        };
    quiz.image = imageInput;
    // quiz.Questions = [];
    // quiz.owner = "8fe0f597-9865-4e74-8e97-f7dc60b909dc::8fe0f597-9865-4e74-8e97-f7dc60b909dc"

    try {
      // create game

      const client = generateClient();

      const newQuizObject = await client.graphql<
        GraphQLQuery<CreateQuizMutation>
      >({
        query: createPrivateQuiz,
        // variables: { input: { ...quiz } },
        variables: { ...quiz },
      });

      const newQuizId = newQuizObject.data?.createQuiz?.id;

      navigate("/quiz/" + newQuizId);
      onSuccess?.();
    } catch (error) {
      console.error(error);
    }
    setIsCreatingQuiz(false);
  }

  const quickPicPrompt = useMemo(() => {
    return (quiz.title + " " + quiz.description).trim();
  }, [quiz.title, quiz.description]);

  return (
    <>
      <Authenticator>
        <Flex direction={"column"}>
          <Grid
            templateColumns={{
              base: "1fr",
              // small: "1fr",
              // large: "1fr 1fr",
              // large: "1fr 3fr",
            }}
            gap={"small"}
          >
            <Flex justifyContent="center">
              <ImageComponent
                alt={quiz.title + " quiz image"}
                canEdit
                image={image}
                height={{ base: "120px", medium: "200px" }}
                alignSelf={"center"}
                autoPickPrompt={quickPicPrompt}
                // width={"100%"}
                aspectRatio="16/9"
                updateImage={async ({ image }) => {
                  setImage(image);
                }}
              />
            </Flex>
            <Grid>
              <View>
                <Card padding={"medium"} height={"100%"} boxShadow={"medium"}>
                  <Flex direction={"column"}>
                    <TextField
                      label={"Title"}
                      labelHidden
                      placeholder="Enter Title"
                      value={quiz.title}
                      name="title"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      hasError={inputHasError}
                      errorMessage={inputHasErrorMessage}
                    />
                    <TextAreaField
                      label={"Description"}
                      labelHidden
                      name="description"
                      placeholder="Enter Description"
                      value={quiz.description}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <LanguageSelect
                      labelHidden
                      value={lang}
                      disableList={[]}
                      onChange={(e) => {
                        setLang(e);
                      }}
                    ></LanguageSelect>
                  </Flex>
                </Card>
              </View>
            </Grid>
          </Grid>

          <Flex direction={"column"}>
            <Flex></Flex>

            <Flex justifyContent={"center"}>
              <Button
                id="raised-btn"
                backgroundColor={"#1a90ff"}
                variation="primary"
                alignSelf={"end"}
                isLoading={isCreatingQuiz}
                loadingText="Creating Quiz"
                onClick={(e) => {
                  onSubmit(e);
                }}
              >
                Create
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Authenticator>
    </>
  );
};

export default NewQuizForm;
