import { useMemo } from "react";

import { listAssignmentResults } from "../util/assignmentResult";
import { calculateScore } from "../util/assignmentResult/calculateScore";
import { AssignmentResult } from "../types";
import { useQuery } from "@tanstack/react-query";

export function useAssignmentResults(assignmentId: string | undefined, options?: any) {
  const { data: assignmentsResults, ...assignmentResultsQuery } = useQuery(
    ["assignment-results", assignmentId],
    async () => {
      // if (!assignmentId) return [];
      const results = await listAssignmentResults(assignmentId as string);
      return results;
    },
    {
      enabled: !!assignmentId,
      ...options
    }
  );

  // const assignments = useAsync(async () => {
  //   if (!assignmentId) return [];
  //   const results = await listAssignmentResults(assignmentId);
  //   return results;
  // }, [assignmentId]);

  const all = useMemo(() => assignmentsResults, [assignmentsResults]);

  const sorted = useMemo(() => {
    if (!all) return [];
    return all.sort((a, b) => {
      const aScore = calculateScore(a.answerResults);
      const bScore = calculateScore(b.answerResults);
      return bScore - aScore;
    });
  }, [all]);

  const completed = useMemo(
    () => assignmentsResults?.filter((result) => result.complete),
    [assignmentsResults]
  );
  const incomplete = useMemo(
    () => assignmentsResults?.filter((result) => !result.complete),
    [assignmentsResults]
  );

  const total = useMemo(() => assignmentsResults?.length, [assignmentsResults]);

  const studentBests = useMemo(() => {
    // return an array of AssignmentResult objects that contains each student's completed AssignmentResult with the highest score
    if (!completed) return [];
    const bestPerStudent = completed.reduce((acc, result) => {
      const studentId = result.studentId;
      const score = calculateScore(result.answerResults);
      const existingResult = acc.find(
        (result) => result.studentId === studentId
      );
      if (existingResult) {
        if (score > calculateScore(existingResult.answerResults)) {
          return [
            ...acc.filter((result) => result.studentId !== studentId),
            result,
          ];
        } else {
          return acc;
        }
      } else {
        return [...acc, result];
      }
    }, [] as AssignmentResult[]);
    return bestPerStudent;
  }, [completed]);

  const allAnswerResults = useMemo(() => {
    if (!assignmentsResults) return [];
    const allAnswerResults = assignmentsResults.flatMap(
      (result) => result.answerResults
    );
    return allAnswerResults;
  }, [assignmentsResults]);

  return {
    all,
    sorted,
    studentBests,
    completed,
    incomplete,
    total,
    ...assignmentResultsQuery,
    // loading: assignmentsResults.loading,
    // error: assignmentsResults.error,
    allAnswerResults,
  };
}
