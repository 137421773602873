import * as React from "react";
import { Lesson, Level, Privacy } from "../types/lessonTypes";
import { createContext } from "react";
import { S3Item } from "../../../types/S3File";
import { updateLesson } from "../services/updateLesson";
import TranslationContextProvider from "../../../context/TranslationContextProvider";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getLesson } from "../services/getLesson";
import useDebounce from "../../../hooks/useDebounce";

interface ILessonContext {
  lesson: Lesson;
  isOwner: boolean | undefined;
  outputLang: "en" | "fr";
  setOutputLang: (value: "en" | "fr") => void;
  setTopic: (value: string) => void;
  setOverview: (value: string) => void;
  setTitle: (value: string) => void;
  setDescription: (value: string) => void;
  setObjectives: (value: string) => void;
  setScope: (value: string) => void;
  setVocabulary: (value: string) => void;
  setGradeLevel: (value: Level) => void;
  setReadingLevel: (value: Level) => void;
  setLanguage: (value: string) => void;
  setPrivacy: (value: Privacy) => void;
  setImage: (image: S3Item) => void;
}

export const LessonContext = createContext<ILessonContext>(
  {} as ILessonContext
);

export interface ILessonContextProviderProps {
  children: React.ReactNode;
  isOwner: boolean;
  lessonId: string;
  userId: string;
}

export default function LessonContextProvider(
  props: ILessonContextProviderProps
) {
  const { children, isOwner, lessonId, userId } = props;

  // const [lesson, setLesson] = React.useState(lessonServer);
  const queryClient = useQueryClient();

  const { data: lessonServer } = useQuery({
    queryKey: ["lesson", lessonId, userId],
    queryFn: async () => {
      // const userId = await getIdentityId();
      return await getLesson(userId, lessonId);
    },
    suspense: true,
  });

  // const lesson = useMemo(() => _lesson as Lesson, [_lesson]);

  const [lesson, lessonDispatch] = React.useReducer(
    (
      prev: Lesson,
      action:
        | {
            type: "set-prop";
            // type is the prop names of Lesson
            name: keyof Lesson;
            value: string;
          }
        | {
            type: "set-prop";
            name: "image";
            value: S3Item;
          }
    ) => {
      switch (action.type) {
        case "set-prop":
          return { ...prev, [action.name]: action.value };

        default:
          return prev;
      }
    },
    lessonServer as Lesson
  );

  const [outputLang, setOutputLang] = React.useState<"en" | "fr">(
    lesson.lang === "en" || lesson.lang === "fr" ? lesson.lang : "en"
  );

  useDebounce(
    () => {
      const { userId, id, updatedAt, createdAt, ...updateItem } = lesson;
      updateLesson_mutate(updateItem);
    },
    500,
    [lesson]
  );

  const { mutateAsync: updateLesson_mutate } = useMutation({
    mutationKey: ["lesson", "update", lessonId],

    mutationFn: async (lesson: Partial<Lesson>) => {
      await updateLesson(lessonId, lesson);
    },
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: ["lesson", lessonId, userId],
      });

      // // Snapshot the previous value
      // const previousLesson: Lesson | undefined = queryClient.getQueryData([
      //   "lesson",
      //   lessonId,
      // ]);

      // // Optimistically update to the new value
      // queryClient.setQueryData(["lesson", lessonId, userId], {
      //   ...previousLesson,
      //   ...lesson,
      // });
    },
    // Always refetch after error or success:
    onSettled: (_, error) => {
      if (error) console.error(error);
      queryClient.invalidateQueries({
        queryKey: ["lesson", lessonId, userId],
      });
    },
  });

  React.useEffect(() => {
    setOutputLang((prev) => {
      if (prev === lesson.lang) return prev;
      if (lesson.lang === "en" || lesson.lang === "fr") return lesson.lang;
      return prev;
    });
  }, [lesson.lang]);

  // React.useEffect(() => {
  //   console.log("LessonContextProvider", lesson);
  // }, [lesson]);

  return (
    <TranslationContextProvider lang={lesson.lang}>
      <LessonContext.Provider
        value={{
          lesson: lesson,
          isOwner,
          outputLang,
          setOutputLang: (value) => {
            setOutputLang(value);
            lessonDispatch({ type: "set-prop", name: "lang", value });
          },
          setTopic: (value: string) => {
            lessonDispatch({ type: "set-prop", name: "topic", value });
          },
          setOverview: (value: string) => {
            lessonDispatch({ type: "set-prop", name: "overview", value });
          },
          setTitle: (value: string) => {
            lessonDispatch({ type: "set-prop", name: "title", value });
          },
          setDescription: (value: string) => {
            lessonDispatch({ type: "set-prop", name: "description", value });
          },
          setObjectives: (value: string) => {
            lessonDispatch({ type: "set-prop", name: "objectives", value });
          },
          setScope: (value: string) => {
            lessonDispatch({ type: "set-prop", name: "coverage", value });
          },
          setVocabulary: (value: string) => {
            lessonDispatch({ type: "set-prop", name: "vocabulary", value });
          },
          setGradeLevel: (value: Level) => {
            lessonDispatch({ type: "set-prop", name: "gradeLevel", value });
          },
          setReadingLevel: (value: Level) => {
            lessonDispatch({ type: "set-prop", name: "readingLevel", value });
          },
          setLanguage: (value: string) => {
            lessonDispatch({ type: "set-prop", name: "lang", value });
          },
          setPrivacy: (value: Privacy) => {
            lessonDispatch({ type: "set-prop", name: "privacy", value });
          },
          setImage: (image: S3Item) => {
            lessonDispatch({ type: "set-prop", name: "image", value: image });
          },
        }}
      >
        {children}
      </LessonContext.Provider>
    </TranslationContextProvider>
  );
}
