import { useQuery } from "@tanstack/react-query";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { getQuizDetails2 } from "../../../services/custom-queries";
import useEffectOnce from "../../../hooks/useEffectOnce";
import { generateClient } from "aws-amplify/api";
import { Quiz } from "../../../API";

export default function useQuizDetails(
  quizId: string | undefined,
  onSuccess?: (data: Quiz) => void
): {
  title?: string;
  updatedAt?: string;
  description?: string;
  isPublic?: boolean;
  lang?: string | null;
  refetchQuizDetails: () => any;
} {
  useEffectOnce(() => {
    refetchQuizDetails();
  });

  const { data: quiz, refetch: refetchQuizDetails } = useQuery({
    queryKey: ["quiz details", quizId],
    enabled: quizId !== undefined,
    queryFn: async () => {
      const client = generateClient();
      const result = (await client.graphql({
        query: getQuizDetails2,
        variables: { id: quizId },
      })) as GraphQLResult<any>;

      const quiz = result.data.getQuiz;

      return quiz as Quiz;
    },
    onSuccess,
    onError: (e) => console.error("Quiz Query Failed", e),
  });

  return {
    title: quiz?.title,
    updatedAt: quiz?.updatedAt,
    description: quiz?.description,
    isPublic: quiz?.allowReadTo === "public",
    lang: quiz?.lang,
    refetchQuizDetails,
  };
}
