/* src/App.js */
import React, { Suspense, useEffect } from "react";
import { Amplify } from "aws-amplify";
import { Hub } from "aws-amplify/utils";
import { fetchUserAttributes } from "aws-amplify/auth";

/* Custom Stuff */
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify"; // Container for toast alerts

// Pages
import awsExports from "./aws-exports";
import Home from "./pages/Home";
import NotFoundPage from "./pages/NotFound";
import UpgradeRoutes from "./routes/UpgradeRoutes";

import QuizRoutes from "./routes/QuizRoutes";
import PlayTeamsRoutes from "./features/game/pages/v4/PlayTeamsRoutes";
import PlaySoloRoutes from "./features/game/pages/v3/PlaySoloRoutes";
import Settings from "./pages/Settings";
import ClassesRoutes from "./routes/ClassesRoutes";
import ClassroomRoutes from "./routes/ClassroomRoutes";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsofService from "./pages/TermsofService";
import DashboardRoutes from "./routes/CreateRoutes";
import LessonRoutes from "./features/lesson/components/LessonRoutes";
import { CenteredLoader } from "./components/CenteredLoader";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import UserContextProvider from "./context/UserContextProvider";
import ErrorBoundary from "./components/ErrorBoundary";
import Test from "./components/Test";
import Pricing from "./pages/Pricing";
import posthog from "posthog-js";
import VolumeContextProvider from "./context/VolumeContextProvider";
import TranslationContextProvider from "./context/TranslationContextProvider";
import AuthRoutes from "./features/authentication/routes/AuthRoutes";

// import ClassesRoutes from "./routes/ClassesRoutes";

// /**
//  * This is a hack to fix the issue with the Amplify library treating all incoming urls as OAuth responses.
//  * https://github.com/aws-amplify/amplify-js/issues/9208#issuecomment-1309890756*
//  */
// // @ts-ignore
// const _handleAuthResponse = Auth._handleAuthResponse.bind(Auth);
// // @ts-ignore
// Auth._handleAuthResponse = (url: string) => {
//   const configuration = Auth.configure();
//   // @ts-ignore
//   if (!url.includes(configuration.oauth?.redirectSignIn)) return;
//   return _handleAuthResponse(url);
// };

// awsExports.oauth.redirectSignIn = window.location.origin + "/dashboard";
awsExports.oauth.redirectSignIn =
  window.location.origin + "/dashboard/discover/";
awsExports.oauth.redirectSignOut = window.location.origin + "/";

Amplify.configure(awsExports);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
});

const App = () => {
  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    posthog.capture("$pageview");
  }, [location]);

  useEffect(() => {
    const unlisten = Hub.listen("auth", async (data) => {
      switch (data.payload.event) {
        case "signedIn":
          await fetchUserAttributes().then((attributes) => {
            if (attributes) {
              posthog.identify(attributes.email, {
                sub: attributes.sub,
              });
            }
          });
          break;
        case "signedOut":
          // case "oAuthSignedOut":
          posthog.reset();
          navigate("/");
          break;
        default:
          break;
      }
    });

    return () => unlisten();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<CenteredLoader />}>
        <ErrorBoundary fallback={<NotFoundPage />}>
          <UserContextProvider>
            <TranslationContextProvider>
              <VolumeContextProvider>
                <Routes>
                  <Route index element={<Home />} />
                  {/* <Route path={'faq'} element={<NotFoundPage />} /> */}
                  <Route path={"/privacy-policy"} element={<PrivacyPolicy />} />
                  <Route
                    path={"/terms-of-service"}
                    element={<TermsofService />}
                  />
                  <Route path={"auth/*"} element={<AuthRoutes />} />
                  <Route path="dashboard/*" element={<DashboardRoutes />} />
                  <Route path="quiz/*" element={<QuizRoutes />} />
                  <Route path={"classes/*"} element={<ClassesRoutes />} />
                  <Route
                    path={"classroom/:classId/*"}
                    element={<ClassroomRoutes />}
                  />
                  <Route path="settings" element={<Settings />} />
                  <Route path="lesson/*" element={<LessonRoutes />} />
                  <Route path="upgrade/*" element={<UpgradeRoutes />} />
                  <Route path="v3/*" element={<PlaySoloRoutes />} />{" "}
                  {/*Keep for a few months - delete Jan, 2024*/}
                  <Route path="game/*" element={<PlaySoloRoutes />} />
                  <Route path="play/*" element={<PlayTeamsRoutes />} />
                  <Route path="pricing" element={<Pricing />} />
                  <Route path="test/*" element={<Test />} />
                  {/* <Route path="/*" element={<AuthReqRoutes />} /> */}
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </VolumeContextProvider>
            </TranslationContextProvider>
          </UserContextProvider>
          <ReactQueryDevtools position="bottom-right" />
        </ErrorBoundary>
      </Suspense>

      <ToastContainer
        position={"top-center"}
        theme="colored"
        autoClose={3000}
      />
    </QueryClientProvider>
  );
};

export default App;
