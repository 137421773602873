import React from "react";
import { ReferAFriend } from "../../features/referrals/components/ReferAFriend";
import { ModalOptions } from "../../types/ModalOptions";

export function referAFriendModalOptions({
  onHide,
}: {
  onHide?: () => void;
}): ModalOptions {
  return {
    title: "",
    ReactComponent: () => <ReferAFriend />,
    hasBackButton: false,
    size: "lg",
    onHide: onHide,
  };
}
