import React, { useEffect, useState } from "react";
import { Document, Page, Text, View, Font, Image } from "@react-pdf/renderer";
import { Quiz, QuizQuestion } from "../../../API";
import useAsync from "../../../hooks/useAsync";
import QuizPDFRenderer_Answers from "./QuizPDFRenderer_Answers";
import { styles } from "./QuizPDFRenderer_Styles";
import QuizPDFRenderer_AnswerKeyItem from "./QuizPDFRenderer_AnswerKeyItem";
import { Translations } from "../../../types/Translations";
import { getS3Url } from "../../../services/s3/getS3Url";

type QuizPDFRendererProps = {
  quiz: Quiz;
  quizQuestions?: QuizQuestion[];
  translations: Translations;
};

// Create Document Component
export default function QuizPDFRenderer({
  quiz,
  quizQuestions,
  translations,
}: QuizPDFRendererProps) {
  const [imageUrls, setImageUrls] = useState<(string | undefined)[]>([]);

  useEffect(() => {
    let regSrc = "";
    let boldSrc = "";
    let italicSrc = "";
    switch (quiz.lang) {
      case "ko":
        regSrc = "/fonts/Noto-Script/korean/static/NotoSansKR-Regular.ttf";
        boldSrc = "/fonts/Noto-Script/korean/static/NotoSansKR-Bold.ttf";
        italicSrc = "/fonts/Noto-Script/korean/static/NotoSansKR-Regular.ttf";
        break;
      case "ja":
        regSrc = "/fonts/Noto-Script/japanese/static/NotoSansJP-Regular.ttf";
        boldSrc = "/fonts/Noto-Script/japanese/static/NotoSansJP-Bold.ttf";
        italicSrc = "/fonts/Noto-Script/japanese/static/NotoSansJP-Regular.ttf";
        break;
      case "he":
        regSrc = "/fonts/Noto-Script/hebrew/static/NotoSansHebrew-Regular.ttf";
        boldSrc = "/fonts/Noto-Script/hebrew/static/NotoSansHebrew-Bold.ttf";
        italicSrc =
          "/fonts/Noto-Script/hebrew/static/NotoSansHebrew-Regular.ttf";
        break;
      case "ar":
        regSrc = "/fonts/Noto-Script/arabic/static/NotoSansArabic-Regular.ttf";
        boldSrc = "/fonts/Noto-Script/arabic/static/NotoSansArabic-Bold.ttf";
        italicSrc =
          "/fonts/Noto-Script/arabic/static/NotoSansArabic-Regular.ttf";
        break;
      case "zh":
      case "zh-yue":
        regSrc =
          "/fonts/Noto-Script/chinese-simple/static/NotoSansSC-Regular.ttf";
        boldSrc =
          "/fonts/Noto-Script/chinese-simple/static/NotoSansSC-Bold.ttf";
        italicSrc =
          "/fonts/Noto-Script/chinese-simple/static/NotoSansSC-Regular.ttf";
        break;
      case "hi":
        regSrc =
          "/fonts/Noto-Script/devanagari/static/NotoSansDevanagari-Regular.ttf";
        boldSrc =
          "/fonts/Noto-Script/devanagari/static/NotoSansDevanagari-Bold.ttf";
        italicSrc =
          "/fonts/Noto-Script/devanagari/static/NotoSansDevanagari-Regular.ttf";
        break;
      default:
        regSrc = "/fonts/Noto-Script/default/static/NotoSans-Regular.ttf";
        boldSrc = "/fonts/Noto-Script/default/static/NotoSans-Bold.ttf";
        italicSrc = "/fonts/Noto-Script/default/static/NotoSans-Italic.ttf";
    }

    const fontRegister = {
      family: "Poppins",
      fonts: [
        { src: regSrc },
        {
          src: boldSrc,
          fontStyle: "bold",
        },
        {
          src: italicSrc,
          fontStyle: "italic",
        },
      ],
    };

    //Register the Nunito font with react-pdf
    Font.register({ ...fontRegister });

    Font.registerEmojiSource({
      format: "png",
      url: "https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/",
    });

    // const fonts = Font.getRegisteredFontFamilies();
  }, [quiz]); // Only run the effect once

  useAsync(async () => {
    if (quizQuestions === undefined) return;
    const urls = await Promise.all(
      quizQuestions.map(async (qq) => {
        let { key, identityId } = { key: "", identityId: "" };
        const question = qq.question;
        if (
          question &&
          question.image &&
          question.image !== null &&
          question.image.key
        ) {
          key = question.image.key;
          identityId = question.image.identityId;

          const url = (
            await getS3Url({
              version: 1,
              key,
              identityId,
              level: "protected",
            })
          ).url.toString();
          return url;
        } else return "";
      })
    );
    setImageUrls(urls);
  }, [quizQuestions]);

  return (
    <>
      <Document language={"he"}>
        <Page size="LETTER" wrap style={styles.page}>
          <View style={styles.inputGroup}>
            <Text style={styles.inputGroupIem}>{translations.name}:</Text>
            <Text style={styles.inputGroupIem}>{translations.date}:</Text>
          </View>
          <Text style={styles.title}>{quiz.title}</Text>
          {/* <Text style={styles.description}>Select the best answer from the given questions.</Text> */}
          <View style={styles.questionGroup}>
            {quizQuestions?.map(
              (quizQuestion, i) =>
                quizQuestion.question !== null &&
                quizQuestion.question !== undefined && (
                  <View key={i} wrap={false} style={styles.question}>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 4,
                      }}
                    >
                      <View style={styles.rowFlex}>
                        <Text>{i + 1}.</Text>
                        <Text
                          style={{
                            flex: 1,
                          }}
                        >
                          {quizQuestion.question.text}
                        </Text>
                      </View>
                      <QuizPDFRenderer_Answers
                        quizQuestion={quizQuestion}
                        translations={translations}
                      />
                    </View>

                    {imageUrls[i] && (
                      <View style={styles.imageContainer}>
                        <Image src={imageUrls[i]} style={styles.image} />
                      </View>
                    )}
                  </View>
                )
            )}
          </View>
        </Page>
        <Page size="LETTER" style={styles.page}>
          {/* <View style={styles.answerKeyBox}> */}
          <Text style={styles.answerKeyBox}>{translations.answer_key}</Text>
          <i>
            <Text style={styles.centered}>
              {translations.created_using_Gibbly}
            </Text>
          </i>
          {/* </View> */}
          <Text style={styles.title}>{quiz.title}</Text>
          <View style={styles.questionGroup}>
            {quizQuestions?.map(
              (quizQuestion, i) =>
                quizQuestion.question !== null &&
                quizQuestion.question !== undefined && (
                  <View key={i} wrap={false} style={styles.question}>
                    <View style={styles.columnFlex}>
                      <View style={styles.rowFlex}>
                        <Text style={styles.questionText}>{i + 1}.</Text>
                        <Text style={styles.questionText}>
                          {quizQuestion.question.text}
                        </Text>
                      </View>

                      <QuizPDFRenderer_AnswerKeyItem
                        quizQuestion={quizQuestion}
                      />
                    </View>
                  </View>
                )
            )}
          </View>
        </Page>
      </Document>
    </>
  );
}
