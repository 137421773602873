import React from "react";
import { Image } from "@aws-amplify/ui-react";
import { useQuery } from "@tanstack/react-query";
import getQRCode from "../services/getQRCode";

type QRCodeImageProps = {
  text: string;
};

export default function QRCodeImage({ text }: QRCodeImageProps) {
  const { data } = useQuery(["qr-code", text], () => getQRCode(text));

  return <Image alt={undefined} src={data} />;
}
