import React from "react";

import { autoSignIn, SignUpInput } from "aws-amplify/auth";

import { ConfirmSignUp } from "./ConfirmSignUp";
import { LogInForm } from "./LogInForm";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

export function LogInMenu() {
  const [formData, setFormData] = React.useState<SignUpInput>({
    username: "",
    password: "",
  });

  const { username, password } = formData;
  // const [codeDeliveryDetails, setCodeDeliveryDetails] = React.useState<{
  //   destination: string | undefined;
  // }>({ destination: undefined });

  const [menuState, setMenuState] = React.useState<"login" | "confirmation">(
    "login"
  );

  const navigate = useNavigate();
  const { mutateAsync: handleAutoSignIn } = useMutation(
    ["AutoSignIn"],
    async () => {
      const autoSignInRes = await autoSignIn();
      const { nextStep } = autoSignInRes;
      if (nextStep) {
        switch (nextStep.signInStep) {
          case "DONE":
            window.location.href = "/dashboard/library";
            break;
          case "RESET_PASSWORD":
            navigate("/auth/reset-password");
            break;
        }
      }
    }
  );

  switch (menuState) {
    case "login":
      return (
        <LogInForm
          username={username}
          password={password}
          onSetUsername={(username) => {
            setFormData({ ...formData, username });
          }}
          onSetPassword={(password) => {
            setFormData({ ...formData, password });
          }}
          onNextStep={(nextStep) => {
            switch (nextStep.signInStep) {
              case "CONFIRM_SIGN_UP":
                setMenuState("confirmation");
                break;
              case "RESET_PASSWORD":
                navigate("/auth/reset-password");
                break;
              case "DONE":
                window.location.href = "/dashboard/library";
                break;
            }
          }}
        />
      );
    case "confirmation":
      return (
        <ConfirmSignUp
          username={username}
          codeDeliveryDetails={{ destination: "your email" }}
          onNextStep={(nextStep) => {
            switch (nextStep.signUpStep) {
              case "DONE":
                setMenuState("login");
                break;
              case "COMPLETE_AUTO_SIGN_IN":
                handleAutoSignIn();
                break;
            }
          }}
        />
      );
  }
}
