/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createQuestion = /* GraphQL */ `mutation CreateQuestion(
  $input: CreateQuestionInput!
  $condition: ModelQuestionConditionInput
) {
  createQuestion(input: $input, condition: $condition) {
    id
    image {
      key
      identityId
      alt
      __typename
    }
    audio {
      key
      identityId
      alt
      __typename
    }
    readAloudText
    text
    type
    solution
    answers
    correctIndices
    Quizzes {
      items {
        id
        quizId
        questionId
        quiz {
          id
          creator
          allowReadTo
          title
          description
          tags
          lang
          updatedAt
          createdAt
          __typename
        }
        question {
          id
          readAloudText
          text
          type
          solution
          answers
          correctIndices
          createdAt
          updatedAt
          owner
          __typename
        }
        sortIndex
        updatedAt
        createdAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateQuestionMutationVariables,
  APITypes.CreateQuestionMutation
>;
export const updateQuestion = /* GraphQL */ `mutation UpdateQuestion(
  $input: UpdateQuestionInput!
  $condition: ModelQuestionConditionInput
) {
  updateQuestion(input: $input, condition: $condition) {
    id
    image {
      key
      identityId
      alt
      __typename
    }
    audio {
      key
      identityId
      alt
      __typename
    }
    readAloudText
    text
    type
    solution
    answers
    correctIndices
    Quizzes {
      items {
        id
        quizId
        questionId
        quiz {
          id
          creator
          allowReadTo
          title
          description
          tags
          lang
          updatedAt
          createdAt
          __typename
        }
        question {
          id
          readAloudText
          text
          type
          solution
          answers
          correctIndices
          createdAt
          updatedAt
          owner
          __typename
        }
        sortIndex
        updatedAt
        createdAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateQuestionMutationVariables,
  APITypes.UpdateQuestionMutation
>;
export const deleteQuestion = /* GraphQL */ `mutation DeleteQuestion(
  $input: DeleteQuestionInput!
  $condition: ModelQuestionConditionInput
) {
  deleteQuestion(input: $input, condition: $condition) {
    id
    image {
      key
      identityId
      alt
      __typename
    }
    audio {
      key
      identityId
      alt
      __typename
    }
    readAloudText
    text
    type
    solution
    answers
    correctIndices
    Quizzes {
      items {
        id
        quizId
        questionId
        quiz {
          id
          creator
          allowReadTo
          title
          description
          tags
          lang
          updatedAt
          createdAt
          __typename
        }
        question {
          id
          readAloudText
          text
          type
          solution
          answers
          correctIndices
          createdAt
          updatedAt
          owner
          __typename
        }
        sortIndex
        updatedAt
        createdAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteQuestionMutationVariables,
  APITypes.DeleteQuestionMutation
>;
export const createQuiz = /* GraphQL */ `mutation CreateQuiz(
  $input: CreateQuizInput!
  $condition: ModelQuizConditionInput
) {
  createQuiz(input: $input, condition: $condition) {
    id
    creator
    image {
      key
      identityId
      alt
      __typename
    }
    allowReadTo
    title
    description
    tags
    Questions {
      items {
        id
        quizId
        questionId
        quiz {
          id
          creator
          allowReadTo
          title
          description
          tags
          lang
          updatedAt
          createdAt
          __typename
        }
        question {
          id
          readAloudText
          text
          type
          solution
          answers
          correctIndices
          createdAt
          updatedAt
          owner
          __typename
        }
        sortIndex
        updatedAt
        createdAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    lang
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateQuizMutationVariables,
  APITypes.CreateQuizMutation
>;
export const updateQuiz = /* GraphQL */ `mutation UpdateQuiz(
  $input: UpdateQuizInput!
  $condition: ModelQuizConditionInput
) {
  updateQuiz(input: $input, condition: $condition) {
    id
    creator
    image {
      key
      identityId
      alt
      __typename
    }
    allowReadTo
    title
    description
    tags
    Questions {
      items {
        id
        quizId
        questionId
        quiz {
          id
          creator
          allowReadTo
          title
          description
          tags
          lang
          updatedAt
          createdAt
          __typename
        }
        question {
          id
          readAloudText
          text
          type
          solution
          answers
          correctIndices
          createdAt
          updatedAt
          owner
          __typename
        }
        sortIndex
        updatedAt
        createdAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    lang
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateQuizMutationVariables,
  APITypes.UpdateQuizMutation
>;
export const deleteQuiz = /* GraphQL */ `mutation DeleteQuiz(
  $input: DeleteQuizInput!
  $condition: ModelQuizConditionInput
) {
  deleteQuiz(input: $input, condition: $condition) {
    id
    creator
    image {
      key
      identityId
      alt
      __typename
    }
    allowReadTo
    title
    description
    tags
    Questions {
      items {
        id
        quizId
        questionId
        quiz {
          id
          creator
          allowReadTo
          title
          description
          tags
          lang
          updatedAt
          createdAt
          __typename
        }
        question {
          id
          readAloudText
          text
          type
          solution
          answers
          correctIndices
          createdAt
          updatedAt
          owner
          __typename
        }
        sortIndex
        updatedAt
        createdAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    lang
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteQuizMutationVariables,
  APITypes.DeleteQuizMutation
>;
export const createQuizQuestion = /* GraphQL */ `mutation CreateQuizQuestion(
  $input: CreateQuizQuestionInput!
  $condition: ModelQuizQuestionConditionInput
) {
  createQuizQuestion(input: $input, condition: $condition) {
    id
    quizId
    questionId
    quiz {
      id
      creator
      image {
        key
        identityId
        alt
        __typename
      }
      allowReadTo
      title
      description
      tags
      Questions {
        items {
          id
          quizId
          questionId
          sortIndex
          updatedAt
          createdAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      lang
      updatedAt
      createdAt
      __typename
    }
    question {
      id
      image {
        key
        identityId
        alt
        __typename
      }
      audio {
        key
        identityId
        alt
        __typename
      }
      readAloudText
      text
      type
      solution
      answers
      correctIndices
      Quizzes {
        items {
          id
          quizId
          questionId
          sortIndex
          updatedAt
          createdAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    sortIndex
    updatedAt
    createdAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateQuizQuestionMutationVariables,
  APITypes.CreateQuizQuestionMutation
>;
export const updateQuizQuestion = /* GraphQL */ `mutation UpdateQuizQuestion(
  $input: UpdateQuizQuestionInput!
  $condition: ModelQuizQuestionConditionInput
) {
  updateQuizQuestion(input: $input, condition: $condition) {
    id
    quizId
    questionId
    quiz {
      id
      creator
      image {
        key
        identityId
        alt
        __typename
      }
      allowReadTo
      title
      description
      tags
      Questions {
        items {
          id
          quizId
          questionId
          sortIndex
          updatedAt
          createdAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      lang
      updatedAt
      createdAt
      __typename
    }
    question {
      id
      image {
        key
        identityId
        alt
        __typename
      }
      audio {
        key
        identityId
        alt
        __typename
      }
      readAloudText
      text
      type
      solution
      answers
      correctIndices
      Quizzes {
        items {
          id
          quizId
          questionId
          sortIndex
          updatedAt
          createdAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    sortIndex
    updatedAt
    createdAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateQuizQuestionMutationVariables,
  APITypes.UpdateQuizQuestionMutation
>;
export const deleteQuizQuestion = /* GraphQL */ `mutation DeleteQuizQuestion(
  $input: DeleteQuizQuestionInput!
  $condition: ModelQuizQuestionConditionInput
) {
  deleteQuizQuestion(input: $input, condition: $condition) {
    id
    quizId
    questionId
    quiz {
      id
      creator
      image {
        key
        identityId
        alt
        __typename
      }
      allowReadTo
      title
      description
      tags
      Questions {
        items {
          id
          quizId
          questionId
          sortIndex
          updatedAt
          createdAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      lang
      updatedAt
      createdAt
      __typename
    }
    question {
      id
      image {
        key
        identityId
        alt
        __typename
      }
      audio {
        key
        identityId
        alt
        __typename
      }
      readAloudText
      text
      type
      solution
      answers
      correctIndices
      Quizzes {
        items {
          id
          quizId
          questionId
          sortIndex
          updatedAt
          createdAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    sortIndex
    updatedAt
    createdAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteQuizQuestionMutationVariables,
  APITypes.DeleteQuizQuestionMutation
>;
