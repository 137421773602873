import { Flex, Heading, ToggleButton } from '@aws-amplify/ui-react';
import * as React from 'react';

export interface IGenerateCustomeOptionsProps {
}

export default function GenerateCustomeOptions(props: IGenerateCustomeOptionsProps) {

  const { } = props;

  return (
    <Flex direction={'column'} >
      <Flex direction={'column'} gap={'xs'}>
        <Heading>Question Types</Heading>
        <ToggleButton size='small' value='Single Select'>
          Single Select
        </ToggleButton>
        <ToggleButton size='small' value='Multi Select'>
          Multi Select
        </ToggleButton>
        <ToggleButton size='small' value='True False'>
          True False
        </ToggleButton>
        <ToggleButton size='small' value='Typed Answer'>
          Typed Answer
        </ToggleButton>
      </Flex>
      <Flex direction={'column'} gap={'xs'}>
        <Heading>Slide Types</Heading>
        <ToggleButton size='small' value='Header'>
          Header
        </ToggleButton>
        <ToggleButton size='small' value='Image'>
          Image
        </ToggleButton>
        <ToggleButton size='small' value='Header and Text'>
          Header and Text
        </ToggleButton>
        <ToggleButton size='small' value='Fullscreen Media'>
          Fullscreen Media
        </ToggleButton>
        <ToggleButton size='small' value='Image and Text'>
          Image and Text
        </ToggleButton>
        <ToggleButton size='small' value='Text and Image'>
          Text and Image
        </ToggleButton>
      </Flex>
    </Flex>
  );
}
