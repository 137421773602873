import React, { useState } from "react";
import MultiPlay from "./MultiPlay";
import { View, Flex, Text } from "@aws-amplify/ui-react";
import RaisedButton from "../../../../components/RaisedButton";
import RaisedCard from "../../../../components/RaisedCard";
import useClickOutsideDetection from "../../../../hooks/useClickOutsideDetection";
import StopGameButton from "./StopGameButton";
import useLobbyContext from "../../hooks/useLobbyContext";

import Leaderboard from "./HostView.Leaderboard";
import { useTranslationContext } from "../../../../context/TranslationContextProvider";

export default function GameView() {
  const [view, setView] = useState<"game" | "leaderboard">("game");

  const { translations } = useTranslationContext();

  const { lobby, updateLobby, team } = useLobbyContext();

  switch (view) {
    case "game":
      return team === undefined ? (
        <>
          <Leaderboard />
        </>
      ) : (
        <>
          <HostCard />
          <MultiPlay goToLeaderboard={() => setView("leaderboard")} />
        </>
      );
    case "leaderboard":
      return (
        <>
          <HostCard />
          <Leaderboard />;
        </>
      );
  }

  function HostCard() {
    const [isHovered, setIsHovered] = useState(false);

    const [open, setOpen] = useState(false);

    const cardRef = useClickOutsideDetection(() => {
      setOpen(false);
    }, []);

    return (
      <RaisedCard
        className={"host-card" + (open ? " open" : "")}
        ref={cardRef}
        position={"fixed"}
        left={isHovered ? "-10px" : "-20px"}
        top={"200px"}
        lineHeight={"small"}
        textAlign={"center"}
        fontWeight={"black"}
        padding={"small xs small large"}
        opacity={open || isHovered ? 1 : 0.6}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => setOpen(true)}
        style={{
          cursor: "pointer",
          zIndex: 100,
        }}
      >
        {" "}
        {open ? (
          <Flex direction={"column"} gap={"xs"}>
            {view === "game" ? (
              <RaisedButton size="large" onClick={() => setView("leaderboard")}>
                {translations.leaderboard}
              </RaisedButton>
            ) : view === "leaderboard" ? (
              <RaisedButton size="large" onClick={() => setView("game")}>
                {translations.game}
              </RaisedButton>
            ) : null}
            <StopGameButton
              onConfirm={async () => {
                if (lobby)
                  await updateLobby({
                    lobby: {
                      ...lobby,
                      lobbyState: "PODIUM",
                    },
                  });
              }}
            />
          </Flex>
        ) : (
          <View style={{ pointerEvents: "none" }}>
            <Text color={"purple.90"}>H</Text>
            <Text color={"purple.90"}>O</Text>
            <Text color={"purple.90"}>S</Text>
            <Text color={"purple.90"}>T</Text>
          </View>
        )}
      </RaisedCard>
    );
  }
}
