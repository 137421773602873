import { useState } from "react";

export default function useToggle(
  defaultValue: boolean
): [value: boolean, toggleValue: (value?: boolean) => void] {
  const [value, setValue] = useState(defaultValue);

  function toggleValue(value?: boolean) {
    setValue((currentValue) => (value !== undefined ? value : !currentValue));
  }

  return [value, toggleValue];
}
