import { DependencyList, useCallback, useEffect } from "react";
import useEventListener from "./useEventListener";
import { unsubscribe, subscribe } from "../util/events";

export default function useModalBackButton(callback: () => void, deps: DependencyList): void {
  useEventListener("onModalBack", callback);

  const onBack = useCallback(callback, [...deps]);

  useEffect(() => {

    subscribe("onModalBack", onBack);
    return () => {

      unsubscribe("onModalBack", onBack);
    };
  }, [onBack]);

}
