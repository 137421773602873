import {
  Button,
  Card,
  Flex,
  Grid,
  Text,
  ToggleButton,
} from "@aws-amplify/ui-react";
import * as React from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import RaisedCard from "../../../components/RaisedCard";
import useToggle from "../../../hooks/useToggle";
import CheckIcon from "../../../components/icons/CheckIcon";
import CloseIcon from "../../../components/icons/CloseIcon";

export interface IGeneratedQuestionCardProps {
  question: { text: string; answers: string[], correctIndices: number[] };
  keep: boolean;
  toggleKeep: () => void;
}

export default function GeneratedQuestionCard(
  props: IGeneratedQuestionCardProps
) {
  const { question, keep, toggleKeep } = props;

  const { text, answers, correctIndices } = React.useMemo(
    () => question || { text: "Error", answers: [] },
    [question]
  );

  const [show, toggleShow] = useToggle(false);

  return (
    <RaisedCard borderRadius={"medium"}>
      <Flex direction={"column"} gap={"xs"}>
        <Flex alignItems={"center"} gap={"xs"}>
          <Text>
            <b>{text}</b>
          </Text>
          <Button variation="link" size="small" onClick={() => toggleShow()}>
            {show ? <FaAngleUp /> : <FaAngleDown />}
          </Button>

          <Flex flex={1} justifyContent={"end"}>
            <ToggleButton
              variation="primary"
              size="small"
              isPressed={keep}
              backgroundColor={keep ? "green" : "red"}
              onClick={() => toggleKeep()}
              color='white'
            >
              {keep ? <CheckIcon /> : <CloseIcon />}
            </ToggleButton>
          </Flex>
        </Flex>
        {show && (
          <Grid templateColumns={"1fr 1fr"} gap={"xs"}>
            {answers.map((answer, j) => {
              return (
                <Card
                  variation="outlined"
                  backgroundColor={correctIndices.includes(j) ? "green.20" : "background.secondary"}
                  key={`${j}`}
                >
                  <Flex justifyContent={'space-between'} alignItems={'center'} color={correctIndices.includes(j) ? 'green.100' : 'red.100'}>
                    <Text>{answer}</Text>{correctIndices.includes(j) ? <CheckIcon /> : <CloseIcon />} </Flex>
                </Card>
              );
            })}
          </Grid>
        )}
      </Flex>
    </RaisedCard>
  );
}
