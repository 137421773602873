import * as React from "react";
import CreateLayout from "../../../layouts/CreateLayout";
import ContentView from "./ContentView";
import { Route, Routes } from "react-router-dom";
import SlidesConsentHandler from "./google/SlidesConsentHandler";
import SharedPresentation from "./present/SharedPresentation";

export interface ICreatePageProps {}

export default function LessonRoutes(props: ICreatePageProps) {
  const {} = props;

  return (
    <Routes>
      <Route
        path=":userId/:lessonId"
        element={
          <CreateLayout>
            <ContentView />
          </CreateLayout>
        }
      />
      <Route path="shared/:lessonId/:linkId" element={<SharedPresentation />} />
      <Route path="google/callback" element={<SlidesConsentHandler />} />
    </Routes>
  );
}
