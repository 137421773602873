import useWebSocket from "../../../hooks/useWebSocket";
import { LobbyMessage } from "../Types/messageTypes";
import { useCallback, useEffect, useRef } from "react";
import { QueryKey } from "@tanstack/react-query";
import useEnvironment from "../../../hooks/useEnvironment";

interface ILobbyWebSocketOptions {
  lobbyPin: string;
  userId: string;
  onOpen?: () => void;
  onPlayerConnected?: (userId: string) => void | Promise<void>;
  onPlayerDisconnected?: (userId: string) => void | Promise<void>;
  onUpdate?: (payload: QueryKey) => void | Promise<void>;
  onError?: (ev: Event) => void | Promise<void>;
}

export default function useLobbyWebSocket(options: ILobbyWebSocketOptions) {
  const {
    lobbyPin,
    userId,
    onOpen,
    onPlayerConnected,
    onPlayerDisconnected,
    onUpdate,
    onError,
  } = options;

  const onMessage = useCallback(
    (ev: MessageEvent) => {
      const data = JSON.parse(ev.data) as LobbyMessage;

      switch (data.type) {
        case "PLAYER_CONNECTED":
          onPlayerConnected?.(data.payload.userId);
          break;
        case "PLAYER_DISCONNECTED":
          onPlayerDisconnected?.(data.payload.userId);
          break;
        case "UPDATE":
          onUpdate?.(data.payload);
          break;
        default:
          break;
      }
    },
    [onPlayerConnected, onPlayerDisconnected, onUpdate]
  );

  const { environment } = useEnvironment();

  const API_GATEWAY_URL =
    environment === "prod"
      ? "wss://0georhuw06.execute-api.us-east-1.amazonaws.com/production/"
      : "wss://ua868vmpt1.execute-api.us-east-1.amazonaws.com/dev/";

  const {
    connect,
    disconnect,
    sendMessage: send,
  } = useWebSocket(
    API_GATEWAY_URL +
      `?${new URLSearchParams({
        lobbyPin,
        userId,
      })}`,
    {
      shouldReconnect: true,
      onOpen: () => {
        onOpen?.();
      },
      onClose: (e) => {
        // if time out, try to reconnect
        if (e.code === 1001) connect();
      },
      onMessage,
      onError,
    }
  );

  const socketRef = useRef<WebSocket | null>(null);

  useEffect(() => {
    if (socketRef.current?.readyState !== WebSocket.OPEN) {
      socketRef.current = connect();
    }
    return () => {
      socketRef.current?.close(1000, "Closing connection on unmount.");
    };
  }, [lobbyPin]);

  const sendMessage = (message: LobbyMessage) => {
    const stringifiedMessage = JSON.stringify(message);

    send(JSON.stringify({ lobbyPin, message: stringifiedMessage }));
  };

  return {
    sendMessage,
    disconnect,
    socket: socketRef.current,
  };
}
