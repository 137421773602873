import { CSSProperties } from "react";
import { convertHorizontalDimToHtmlPercent } from "./convertHorizontalDimToHtmlPercent";
import { convertVerticalDimToHtmlPercent } from "./convertVerticalDimToHtmlPercent";
import pptxgen from "pptxgenjs";

export const computeElementStyles = (
  PositionProps: pptxgen.PositionProps
): CSSProperties => ({
  position: "absolute",
  left: `${convertHorizontalDimToHtmlPercent(PositionProps.x)}%`,
  top: `${convertVerticalDimToHtmlPercent(PositionProps.y)}%`,
  width: `${convertHorizontalDimToHtmlPercent(PositionProps.w)}%`,
  height: `${convertVerticalDimToHtmlPercent(PositionProps.h)}%`,
});
