import { Icon } from '@aws-amplify/ui-react'
import React from 'react'

export default function ArrowRightIcon() {
    return (
        <Icon ariaLabel="Arrow right" fill="currentcolor"
      pathData="M12.184 16.362c-0.689 -0.105 -1.377 -0.218 -2.068 -0.315 -0.673 -0.094 -1.349 -0.167 -2.022 -0.259 -0.692 -0.095 -1.381 -0.207 -2.072 -0.305 -0.652 -0.092 -1.305 -0.174 -1.957 -0.262 -0.795 -0.107 -1.591 -0.206 -2.383 -0.33 -0.341 -0.053 -0.544 -0.339 -0.545 -0.685 -0.001 -1.514 -0.001 -3.028 0 -4.543 0 -0.401 0.273 -0.658 0.671 -0.699 0.644 -0.067 1.285 -0.169 1.929 -0.244 0.626 -0.073 1.254 -0.123 1.88 -0.196 0.633 -0.074 1.264 -0.17 1.898 -0.246 0.605 -0.072 1.212 -0.125 1.817 -0.196 0.654 -0.077 1.307 -0.168 1.961 -0.245 0.277 -0.033 0.557 -0.047 0.881 -0.089 0.057 -0.133 0.078 -0.247 0.079 -0.36 0.003 -1.267 0.002 -2.533 0.002 -3.8 0.137 -0.264 0.447 -0.34 0.727 -0.165 0.639 0.398 1.284 0.789 1.91 1.208 0.959 0.643 1.92 1.286 2.849 1.972 0.663 0.49 1.286 1.037 1.905 1.584 0.648 0.573 1.288 1.159 1.897 1.774 0.473 0.478 0.9 1.002 1.334 1.518 0.257 0.307 0.268 0.884 0.02 1.213 -0.273 0.362 -0.553 0.722 -0.865 1.049 -0.583 0.611 -1.167 1.224 -1.794 1.789 -0.743 0.671 -1.508 1.322 -2.306 1.926 -0.97 0.734 -1.976 1.423 -2.984 2.106a27.293 27.293 0 0 1 -2.006 1.236c-0.209 0.117 -0.506 0.125 -0.685 -0.205 0 -1.327 0.001 -2.6 -0.004 -3.873 -0.001 -0.12 -0.043 -0.239 -0.066 -0.359z">
      
         </Icon>
  )
}