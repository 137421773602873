import * as React from "react";
import LessonOverviewMenu from "./LessonOverviewMenu";
import AddMenu from "./Add/AddMenu";
import MediaMenu from "./MediaMenu";
import TextMenu from "./TextMenu";
import BackgroundMenu from "./BackgroundMenu";
import { ScrollView, View } from "@aws-amplify/ui-react";
import { MenuContext, MenuState } from "../MenuContextProvider";

export interface IMenuViewProps {}

export default function MenuView(props: IMenuViewProps) {
  const {} = props;

  const { menuState } = React.useContext(MenuContext);

  if (menuState === MenuState.PREVIEW) return <View width={0}></View>;

  return (
    <ScrollView width={{base:"100%", large:"360px"}} backgroundColor={"#eee"} padding={"medium"}>
      <View paddingBottom={"xs"} >
        {menuState === MenuState.OVERVIEW && (
          <LessonOverviewMenu>
            {/* <LessonOverviewMenu.DocumentGenerator /> */}
            {/* <LessonOverviewMenu.Buttons /> */}
          </LessonOverviewMenu>
        )}
        {menuState === MenuState.ADD && <AddMenu />}
        {menuState === MenuState.MEDIA && <MediaMenu />}
        {menuState === MenuState.TEXT && <TextMenu />}
        {menuState === MenuState.BACKGROUND && <BackgroundMenu />}
      </View>
    </ScrollView>
  );
}
