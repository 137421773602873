import { translations as translations_en } from "../data/en";
import { Translations } from "../types";

export async function getTranslation(locale: string): Promise<Translations> {
  try {
    const translations = await import(`../data/${locale}/index.ts`);
    return translations.default;
  } catch (error) {
    console.error(`Error loading translations for locale "${locale}":`, error);
    return translations_en;
  }
}
