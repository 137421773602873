import {
  Flex,
  Divider,
  SelectField,
  Text,
  Button,
} from "@aws-amplify/ui-react";
import React, { useState } from "react";
import { FaX } from "react-icons/fa6";
import { MdTranslate } from "react-icons/md";
import { PremiumFeatureBadge } from "../../premium/components";
import { languageOptions } from "../language-options";
import { useMutation } from "@tanstack/react-query";

type Props = {
  exitFn: () => void;
  translateFn: (locale: keyof typeof languageOptions) => any | Promise<any>;
  isDisabled?: boolean;
  children?: React.ReactNode;
};

export default function TranslateForm({
  exitFn,
  translateFn,
  children,
  isDisabled = false,
}: Props) {
  const [translateLanguage, setTranslateLanguage] = useState<
    keyof typeof languageOptions | ""
  >("");

  //   const { isPremium } = useUserContext();

  const { mutateAsync: translateMutation, isLoading: isTranslating } =
    useMutation({
      mutationFn: translateFn,
    });

  return (
    <Flex
      direction={"column"}
      gap={"xs"}
      // width={"250px"}
      as="form"
      padding={"small"}
      onSubmit={async (e) => {
        e.preventDefault();
        if (!translateLanguage) return;
        await translateMutation(translateLanguage);
      }}
    >
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        <Text fontWeight={"bold"}>Translate:</Text>
        <Button
          variation="link"
          size="small"
          border={"none"}
          backgroundColor={"transparent"}
          onClick={(e) => {
            e.preventDefault();
            exitFn();
          }}
        >
          <FaX />
        </Button>
      </Flex>

      <Divider />
      {children}
      <Flex direction={"column"} gap={"xs"}>
        <SelectField
          id="translate-language"
          name="translate-language"
          size="small"
          label="Translate to"
          value={translateLanguage}
          onChange={(e) => {
            setTranslateLanguage(
              e.target.value as keyof typeof languageOptions
            );
          }}
          labelHidden
        >
          <option disabled value={""}>
            Choose a language
          </option>
          {Object.entries(languageOptions).map(([value, label]) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </SelectField>
      </Flex>
      <Divider />
      <Button
        type="submit"
        variation="primary"
        gap={"xs"}
        size="small"
        isLoading={isTranslating}
        isDisabled={!translateLanguage || isDisabled}
        loadingText="Translating..."
      >
        <MdTranslate fontWeight={""} />
        Translate <PremiumFeatureBadge />
      </Button>
    </Flex>
  );
}
