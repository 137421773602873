import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import { CenteredLoader } from "../../../components";
import AuthLayout from "../components/AuthLayout";
import { ResetPasswordMenu, SignUpMenu } from "../pages";
import { LogInMenu } from "../pages/LogInMenu";

export default function AuthRoutes() {
  return (
    <AuthLayout>
      <Suspense fallback={<CenteredLoader />}>
        <Routes>
          {/* <Route index element={<Authenticate />} /> */}
          <Route path="/login" element={<LogInMenu />} />
          <Route path="/signup" element={<SignUpMenu />} />
          <Route path="/reset-password" element={<ResetPasswordMenu />} />
          {/* <Route path="/confirm/:username" element={<ConfirmSignUp />} /> */}
        </Routes>
      </Suspense>
    </AuthLayout>
  );
}
