/**
 * Counts the number of Unicode units in a string.
 * A Unicode unit can be a single character or a surrogate pair.
 *
 * @param str - The string to count the Unicode units in.
 * @returns The number of Unicode units in the string.
 */
export function countUnicodeUnits(str: string): number {
  let unitCount = 0;

  for (let i = 0; i < str.length; i++) {
    unitCount++;
    const current = str.charCodeAt(i);

    // Check if it's a high surrogate
    if (current >= 55296 && current <= 56319 && i < str.length - 1) {
      const next = str.charCodeAt(i + 1);
      // Check if next is a low surrogate
      if (next >= 56320 && next <= 57343) {
        unitCount++;
        i++; // Skip the next unit as it's part of the surrogate pair
      }
    }
  }
  return unitCount;
}
