import { Badge, BadgeVariations, Flex, Text } from "@aws-amplify/ui-react";
import React from "react";
import { QuestionType } from "../../../API";
import { useTranslationContext } from "../../../context/TranslationContextProvider";

interface QuestionItemAnswersProps {
  answers: string[];
  question: any;
  editingQuestion?: boolean;
  handleAnswerChange?: (
    i: number,
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  saveQuestionChanges?: () => Promise<void>;
  handleCorrectIndexChange?: (
    i: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
}

export default function QuestionItemAnswers({
  answers,
  question,
}: QuestionItemAnswersProps): JSX.Element {
  const { translations } = useTranslationContext();

  switch (question.type) {
    case QuestionType.TYPED_ANSWER:
      return (
        <Flex direction={"column"} gap={"xs"} paddingLeft={"medium"}>
          <Text fontWeight={"bold"}>{translations.accepted_answers}</Text>
          {answers.map(
            (answer: string, i: number) =>
              answer !== "" && (
                <Flex key={i}>
                  <Badge key={i} size="small" variation={"success"}>
                    {answer}
                  </Badge>
                </Flex>
              )
          )}
        </Flex>
      );
    case QuestionType.TRUE_OR_FALSE:
    case QuestionType.TRUEFALSE:
      return (
        <Flex direction={"column"} gap={"xs"} paddingLeft={"medium"}>
          {[translations.true, translations.false].map(
            (answer: string, i: number) => (
              <Flex key={i} alignItems={"center"}>
                <Text>{String.fromCharCode(97 + i)}.</Text>

                <Badge
                  size="small"
                  textAlign={"left"}
                  lineHeight={"normal"}
                  variation={
                    question.correctIndices.includes(i)
                      ? ("success" as BadgeVariations)
                      : ("default" as BadgeVariations)
                  }
                >
                  {answer}
                </Badge>
              </Flex>
            )
          )}
        </Flex>
      );
    default:
      return (
        <Flex direction={"column"} gap={"xs"} paddingLeft={"medium"}>
          {answers.map((answer: any, i: number) => (
            <Flex key={i} alignItems={"center"}>
              <Text>{String.fromCharCode(97 + i)}.</Text>

              <Badge
                size="small"
                textAlign={"left"}
                lineHeight={"normal"}
                variation={
                  question.correctIndices.includes(i)
                    ? ("success" as BadgeVariations)
                    : ("default" as BadgeVariations)
                }
              >
                {answer}
              </Badge>
            </Flex>
          ))}
        </Flex>
      );
  }
}
