import React, { useEffect } from "react";
import { PageDescription, PageIndex, PageTitle } from "../util/SEO";
import Footer from "../layouts/Footer";
import TopNavbar from "../layouts/TopNavbar";
import { Heading, Link, Text, View } from "@aws-amplify/ui-react";
import { Container } from "react-bootstrap";
import ScrollArrow from "../layouts/ScrollArrow";
import useEffectOnce from "../hooks/useEffectOnce";

export default function TermsofService() {
  PageTitle("Terms of Service | Gibbly");
  PageDescription(
    "Gibbly's Terms of Service."
  );
  PageIndex("");

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0))
  }, [])

  useEffectOnce(() => {

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {

        entry.target.classList.toggle('show', entry.isIntersecting);
        if (entry.isIntersecting) observer.unobserve(entry.target)
      });
    });
  
    const hiddenElements = document.querySelectorAll('.hidden');
    hiddenElements.forEach((el) => observer.observe(el));

  });

  return (
    <>

      <TopNavbar useAuthentication={false} />
      <Container>
        <ScrollArrow />
        <View padding={{ base: "medium", medium: "xxl" }} paddingRight={'xxl'}>
          <Heading level={4} paddingBottom={'small'}>Gibbly Terms of Service</Heading>
          <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>ACCEPTANCE OF THE TERMS OF SERVICE</Heading>
          <View padding="xxxs">
            <i><Text paddingBottom={'large'}>Last updated: June 30, 2023</Text></i>

            <Text paddingBottom={'large'}>THE FOLLOWING TERMS AND CONDITIONS (“TERMS”) APPLY TO YOUR USE OF THE WEBSITE GIBBLY.CO, (“GIBBLY”,
              “WE” “US” OR “OUR”), INCLUDING ANY CONTENT, FUNCTIONALITY, PRODUCTS, AND SERVICES OFFERED ON OR THROUGH
              SUCH WEBSITE (COLLECTIVELY, THE “WEBSITE”), WHETHER AS A GUEST OR ACCOUNT HOLDER. THESE TERMS ALSO APPLY
              TO YOUR USE OF OTHER GIBBLY SERVICES THAT DISPLAY OR INCLUDE THESE TERMS (“ADDITIONAL SERVICES”). IN THESE
              TERMS, THE WEBSITE AND ADDITIONAL SERVICES ARE COLLECTIVELY REFERRED TO AS THE “SERVICES.”</Text>

            <Text paddingBottom={'large'}> BY CLICKING/CHECKING THE “I AGREE” BUTTON/BOX, ACCESSING THE GIBBLY WEBSITE OR BY UTILIZING THE GIBBLY
              SERVICES YOU AGREE TO BE BOUND BY THESE TERMS OF SERVICE AND ALL EXHIBITS, ORDER FORMS, AND INCORPORATED POLICIES.</Text>

            <Text paddingBottom={'large'}>  PLEASE READ THESE TERMS CAREFULLY BEFORE YOU START TO USE THE SERVICES. BY USING THE SERVICES, YOU ACCEPT
              AND AGREE TO BE BOUND AND ABIDE BY THESE TERMS OF SERVICE. IF YOU DO NOT WANT TO AGREE TO THESE TERMS OF SERVICE,
              YOU MUST NOT USE THE SERVICES. BY ACCEPTING THESE TERMS:</Text>

            <ol>
              <li style={{ 'paddingBottom': '.2em' }}>You are also agreeing to Gibbly's&nbsp;
                <Link href={"http://www.gibbly.co/privacy-policy"}><u>Privacy Policy</u> </Link>
                which explains what information we collect from you and how we protect it.</li>
              <li>You and Gibbly agree to resolve disputes pursuant to the process outlined below.</li>
            </ol>

            <Text fontWeight={'bold'} paddingBottom={'large'}>THESE TERMS CONTAINS A BINDING CLASS-ACTION WAIVER PROVISION. IF YOU ACCEPT THESE TERMS, YOU AND GIBBLY AGREE
              TO RESOLVE DISPUTES PURSUANT TO THE DISPUTE RESOLUTION PROVISIONS BELOW AND GIVE UP THE RIGHT TO GO TO COURT
              INDIVIDUALLY AS PART OF A CLASS ACTION.</Text>

            <Text paddingBottom={'large'}> If you are using the Service on behalf of a school, institution, company, or organization that has a separate
              express written agreement with Gibbly and the school, institution, company, or organization, that agreement shall
              govern your use of the Service, and all matters not covered by said agreement will be subject to our Terms of
              Service and&nbsp;
              <Link href={"http://www.gibbly.co/privacy-policy"}><u>Privacy Policy</u>. </Link> Such Agreements with schools, institutions, companies, or organizations shall only
              apply to accounts created and paid for expressly by them and not with Starter or free accounts or accounts paid
              for by individual educators, employees, or teachers of those entities. Individuals who are employees of organizations,
              schools, institutions, or companies which use the Starter or Free accounts or pay for accounts individually are bound
              by these Terms of Service agreement solely.</Text>

            <Text paddingBottom={'large'}>TO ENTER INTO THE CONTRACT CREATED BY THESE TERMS, YOU MUST BE OF THE LEGAL AGE OF MAJORITY IN YOUR COUNTRY OF
              RESIDENCE UNLESS THE ACCOUNT IS CREATED THROUGH AN EDUCATIONAL SUBSCRIBER'S USE EXCEPTION DESCRIBED MORE FULLY BELOW.
              YOU ARE LEGALLY AND FINANCIALLY RESPONSIBLE FOR ALL ACTIONS USING OR ACCESSING OUR WEBSITE, INCLUDING THE ACTIONS OF
              ANYONE YOU ALLOW TO ACCESS TO YOUR ACCOUNT. YOU AFFIRM THAT YOU HAVE REACHED THE LEGAL AGE OF MAJORITY, UNDERSTAND
              AND ACCEPT THESE TERMS (INCLUDING ITS DISPUTE RESOLUTION TERMS). IF YOU ARE UNDER THE LEGAL AGE OF MAJORITY, YOUR
              PARENT OR LEGAL GUARDIAN MUST CONSENT TO THESE TERMS. IF AN EDUCATIONAL INSTITUTION OR EDUCATOR CONSENTS ON YOUR
              BEHALF IN LOCO PARENTIS, THE EDUCATIONAL INSTITUTION OR EDUCATOR AGREES THAT IT HAS THE LEGAL AUTHORITY TO DO SO
              BY EXPRESS AGREEMENT WITH PARENTS OR STATUTORY AUTHORITY.</Text>

            <Text paddingBottom={'large'}>EDUCATIONAL INSTITUTIONS AND EDUCATIONAL INSTRUCTORS UTILIZING THE SERVICE ARE RESPONSIBLE FOR MONITORING THE
              RIGHTS AND INTERESTS OF THEIR STUDENTS AND MUST THEREFORE TAKE SPECIAL CARE IN THE REVIEW OF THIS AGREEMENT.
              EDUCATIONAL INSTITUTIONS AND EDUCATIONAL INSTRUCTORS SHALL BE RESPONSIBLE FOR OBTAINING, WHERE APPLICABLE,
              PERTINENT CONSENT FROM PARENTS, LEGAL GUARDIANS, OR ELIGIBLE STUDENTS PRIOR TO UTILIZING THE SERVICE. GIBBLY
              SHALL NOT BE RESPONSIBLE FOR ANY NEGLIGENCE OF THE EDUCATIONAL INSTITUTION IN THE REVIEW OF THIS AGREEMENT OR
              THE OBTAINMENT, WHERE APPLICABLE, OF THE NECESSARY PARENTAL CONSENT.</Text>

            <Text> If you are entering into this Agreement on behalf of a company, educational institution, or other legal entity,
              you acknowledge that you have the authority to bind said entity to these terms and conditions, in which case the terms
              “you,” “your” or “Member” shall refer to said entity. If you do not have such authority, or if you do not agree with
              these terms and conditions, you must not accept this Agreement and may not use the Service, and Gibbly shall not be
              responsible for any use you make of the Service without the pertinent authorization.</Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Privacy Notice</Heading>

            <Text>Please review our
              <Link href={"http://www.gibbly.co/privacy-policy"}> <u>Privacy Policy</u> </Link>
              which also governs your use of the Services, to understand our practices.
            </Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Changes to the Terms of Service</Heading>
            <Text >We may update the Terms of Service from time to time. You can see when the last update was by looking
              at the “Last Updated” date at the top of this page. We will not reduce your rights under these Terms without
              your explicit consent. If we make any significant changes, we will provide notice by posting a notice on the
              Service and/or notifying you by email (using the email address you provided) prior to and after changes
              taking effect, so you can review and make sure you're aware of them. We encourage you to review the Terms
              of Service from time to time, to stay informed about our collection, use, and disclosure of personal
              information through the Service. If you do not agree with any changes to the Terms, you may delete your
              account. By continuing to use the Service after the revised Terms of Service have become effective, you
              acknowledge that you accept and agree to the revised version of the Terms of Service.</Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Accessing the Services and Account Security</Heading>

            <Text paddingBottom={'large'}> We may withdraw or amend the Services, and any related service or content, or restrict access
              (including by means of cancellation, termination, or modification, or suspension of a user account)
              to all or certain users (including you) without notice and without liability to you in our reasonable
              discretion. Additionally, due to your geographic location, the Services or some of their features,
              services, or content may be unavailable to you. Notwithstanding anything to the contrary herein, we may
              terminate or suspend access to the Services based on your breach of these Terms.</Text>

            <Text paddingBottom={'large'}>To access certain Services, you may be asked to create an account or provide registration details
              or other information, and in order to use such resources, all the information you provide must be correct,
              current, and complete. From time to time, in order to access the Services or certain games, services, or
              functionality, Gibbly may require some or all users to download updated or additional software. The terms
              of use of such software may be subject to a separate agreement between you and Gibbly. By agreeing to
              this service, you agree not to block any access to the various website directories which are required
              for functionality of the service including all cloud storage and hosting services.</Text>

            <Text paddingBottom={'large'}>If you choose, or are provided with, a username, password, or any other piece of information as part
              of our security procedures, you must treat such information as confidential (other than username), and you
              must not disclose it to others. You must immediately notify Gibbly (via support@gibbly.app) of any unauthorized
              use of your username or password or any other breach of security. You should use particular caution when
              accessing your account from a public or shared computer so that others are not able to view or record your
              password or other personal information. You may only access the Services through your own account. Users do not
              own their accounts, and gifting or otherwise transferring of accounts or access keys is prohibited.</Text>

            <Text>We reserve the right to change your display name if we deem it offensive, misleading, or potentially
              infringing the rights of third parties. We also reserve the right to terminate your account if it’s been
              inactive for more than a year.</Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>System Requirements</Heading>

            <Text>Use of the Services requires one or more compatible devices, Internet access (fees may apply),
              and certain software (fees may apply), and may require obtaining updates or upgrades from time to time.
              Because use of the Services involves hardware, software, and Internet access, your ability to access and
              use the Services may be affected by the performance of these factors. High-speed Internet access is
              recommended. You acknowledge and agree that such system requirements, which may be changed from time
              to time, are your responsibility.
            </Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Intellectual Property Rights</Heading>

            <Text paddingBottom={'large'}>The Services, including all content, features, and functionality thereof, are owned by Gibbly,
              its licensors, or other providers of such material and are protected by Canada and international
              copyright, trademark, patent, and other intellectual property or proprietary rights laws.</Text>

            <Text paddingBottom={'large'}>You are permitted to use the Services for your personal, non-commercial use only or legitimate
              business purposes related to your role as a current or prospective customer of Gibbly. Except as
              provided below, you must not copy, modify, create derivative works of, publicly display, publicly
              perform, republish, or transmit any of the material obtained through the Services, or delete, or
              alter any copyright, trademark, or other proprietary rights notices from copies of materials from
              the Services. However, if you are otherwise in compliance with these Terms, you are permitted to use,
              elsewhere and on other websites, an unaltered copy of portions of the content that is publicly available
              on the Website for the limited, non-commercial purpose of discussing such content.</Text>

            <Text paddingBottom={'large'}>The Service and the Gibbly Technology are intended solely for the personal, non-commercial
              use of our users and may only be used in accordance with this Agreement. “Gibbly Technology” means all past,
              present, and future content of the Service, including, all the software, hardware and technology used to
              provide the Service (including Gibbly proprietary code and third-party software), user interfaces,
              materials displayed or performed on the Service, such as text, graphics, articles, photographs, images,
              illustrations and the design, structure, sequence and “look and feel” of the Services, and all other
              intellectual property, including all Gibbly Marks. “Gibbly Marks” are the trademarks, service marks,
              logos, or any other trade name, trademarks, service marks and other distinctive or proprietary brand
              features of Gibbly. Gibbly Technology is protected by copyright and other intellectual property laws.
              You are not allowed to use, store, copy, reproduce, modify, translate, publish, broadcast, transmit,
              distribute, perform, upload, create derivative works from, display, license, sell or otherwise exploit
              the Gibbly Technology for any purposes other than as expressly permitted under this Agreement.</Text>

            <Text paddingBottom={'large'}>You agree that you will not use, and will not permit any End User to use, the Services to: (i) modify,
              disassemble, decompile, prepare derivative works of, reverse engineer or otherwise attempt to gain access
              to the source code of the Services; (ii) knowingly or negligently use the Services in a way that abuses,
              interferes with, or disrupts Gibbly's networks, your accounts, or the Services; (iii) engage in activity
              that is illegal, fraudulent, false, or misleading, (iv) transmit through the Services any material that
              may infringe the intellectual property or other rights of third parties; (v) build or benchmark a competitive
              product or service, or copy any features, functions or graphics of the Services; or (vi) use the Services to
              communicate any message or material that is harassing, libelous, threatening, obscene, indecent, would violate
              the intellectual property rights of any party or is otherwise unlawful, that would give rise to civil liability,
              or that constitutes or encourages conduct that could constitute a criminal offense, under any applicable law or
              regulation; (vii) upload or transmit any software, content, or code that does or is intended to harm, disable,
              destroy, or adversely affect performance of the Services in any way or which does or is intended to harm or
              extract information or data from other hardware, software, or networks of Gibbly or other users of Services;
              (viii) engage in any activity or use the Services in any manner that could damage, disable, overburden, impair,
              or otherwise interfere with or disrupt the Services, or any servers or networks connected to the Services or
              Gibbly’s security system; (ix) use the Services in violation of any Gibbly policy or in a manner that violates
              applicable law, including but not limited to anti-spam, export control, privacy, and anti-terrorism laws and
              regulations and laws requiring the consent of subjects of audio and video recordings, and you agree that you
              are solely responsible for compliance with all such laws and regulations.</Text>

            <Text paddingBottom={'large'}>You may not reproduce, resell, or distribute the Services or any reports or data generated by the Services
              for any purpose unless you have been specifically permitted to do so under a separate agreement with Gibbly.
              You may not offer or enable any third parties to use the Services purchased by you, display on any website or
              otherwise publish the Services or any Content obtained from a Service (other than content created by you) or
              otherwise generate income from the Services or use the Services for the development, production, or marketing
              of a service or product substantially similar to the Services.</Text>

            <Text paddingBottom={'large'}>You are responsible for the activities of all End Users who access or use the Services through your account
              and you agree to ensure that any such End User will comply with the terms of this Agreement and any Gibbly policies.
              Gibbly assumes no responsibility or liability for violations. If you become aware of any violation of this Agreement
              in connection with use of the Services by any person, please contact Gibbly at support@gibbly.app. Gibbly may
              investigate any complaints and violations that come to its attention and may take any (or no) action that it
              believes is appropriate, including, but not limited to issuing warnings, removing the content, or terminating
              accounts and/or User profiles. Under no circumstances will Gibbly be liable in any way for any data or other content
              viewed while using the Services, including, but not limited to, any errors or omissions in any such data or content,
              or any loss or damage of any kind incurred as a result of the use of, access to, or denial of access to any data or
              content.</Text>

            <Text paddingBottom={'large'}>For clarity, the foregoing permissions are limited to the Services, and no rights are granted with respect to
              any servers, computers, or databases associated with the Services.</Text>

            <Text paddingBottom={'large'}>The above license (i) will terminate when you delete any User submissions with intellectual property rights
              (“IP content”), like photos, you delete any personal information, or you delete your account, unless your content
              has been shared with others and they have not deleted it, or you have made games public. If you ever want to delete
              your account, you can do so by contacting us at support@gibbly.app and we will proceed to delete the requested data
              within a reasonable period of time.</Text>

            <Text>Additionally, when you delete IP content or personal information, it is deleted in a manner similar to emptying
              the recycle or trash bin on a computer. However, you understand that any removed User submission may persist in backup
              copies for a reasonable period of time. Note that the information contained in Gibbly's security backups or copies will
              not be available or accessible to others.</Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Ownership of Gibbly Teacher Accounts</Heading>

            <Text paddingBottom={'large'}>Your account belongs to you, regardless of the email address you used when signing up for
              the Services. However, please keep in mind that if your educational institution, organization,
              or company disables your email address and you're not able to log in, we won't be able to recover
              your account.
            </Text>

            <Text>
              Additionally, if you utilize the Service through a Premium license acquired by another party
              for you to use (e.g., bought by your school for teaching purposes), the party paying for such
              feature has the right to control access to and get reports on your use of such paid feature;
              however, they do not have rights to your personal account.
            </Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Additional Terms by User Types</Heading>
            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Students</Heading>

            <Text paddingBottom={'large'}>If you are a student accessing the Service at the invitation of a teacher or other school
              official, the following terms apply to you:</Text>

            <Text paddingBottom={'large'}>  Only students who have been invited by their teacher, school, or district may use the
              Service unless they are of an age in their country which allows the student to create an
              account. Please see the&nbsp;
              <Link href={"http://www.gibbly.co/privacy-policy"}><u>Privacy Policy</u> </Link>for more details. You may not access or use the Service
              unless you are invited by a teacher, school, or district who is authorized to give you access
              to the Service.</Text>

            <Text paddingBottom={'large'}>We request minimal personal information to be provided from students to enable use of
              the Service: username, email, and password. If you are a student, please do not provide any
              personal information about yourself to us, other than what we request from you when you sign
              up for the Service.</Text>

            <Text paddingBottom={'large'}>Without prejudice to the aforementioned, when using the Game PIN created by Gibbly and
              initiated by a host, students can access and use the Gibbly service without the need to create
              an account. In this modality, students may have to provide a username and only responses will
              be collected and processed by the service.</Text>

            <Text>Gibbly only collects, uses, shares, and retains personal student information for purposes
              for which we were authorized by the educational institution or teacher.</Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Teachers</Heading>

            <Text paddingBottom={'large'}>  You understand that we only collect, use, share, and retain student personal information
              for purposes for which we were authorized by the educational institution or teacher.</Text>

            <Text paddingBottom={'large'}>You acknowledge and agree that you have School Consent to allow children under 13 to use
              the Service and that you and/or the Institution will be solely responsible (and hereby agree
              that Gibbly is not responsible) for compliance with PIPEDA or any other applicable laws.
              This includes limiting access to the Service to those student users from whom School Personnel
              has received valid Consent forms and complying with all parental requests regarding the
              collection, use, and disclosure of such child's information. School Personnel or the
              educational institution are responsible for providing parental consent forms to the parents
              of potential student users, for confirming receipt of valid consent forms for each child
              before granting the student access to the Service, and for retaining such consent forms
              on file. If a user's parent does not consent or rescind such School Consent, School Personnel
              or the educational institution shall immediately notify Gibbly to discontinue that student's
              access to the Service and ensure that such student's information is no longer accessible
              through the Services.</Text>

            <Text paddingBottom={'large'}>You agree that you are acting on behalf of (or have permission from) your Institution
              to enter into this Agreement and to use the Service as part of your curriculum.</Text>

            <Text paddingBottom={'large'}>You agree that you are responsible for maintaining and monitoring the accuracy of the
              list of students for each of your classes. By way of example, you will only retain students
              in your classroom as long as it is reasonably needed for their educational development,
              which shall not exceed more than one school year.</Text>

            <Text>You acknowledge and agree that, in order to guarantee the privacy and security of your
              students' data and provide them with the best possible experience through the Gibbly website,
              only personnel who are current employees of the institution may use the service on the
              institution's behalf. Upon termination of a teacher, aide, trainer, or other staff member's
              employment with the institution, such individual must return and cease using all login details
              and student access she or he has in her or his possession. Gibbly shall not be responsible
              for the misuse of the service by institution personnel and shall not be held accountable for
              teachers, aides, trainers, or other staff members of the institution not returning student
              education records, and/or not returning and ceasing to use all login details and student
              access they have in their possession, upon termination of their employment agreement with
              the institution.</Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Billing, Payment,
              and Gibbly Subscription Plans</Heading>

            <Text>ALL CHARGES INCURRED IN CONNECTION WITH THE SERVICES AND ALL FUNDS FOR GIBBLY
              SUBSCRIPTIONS ARE PAYABLE IN ADVANCE AND ARE NOT REFUNDABLE IN WHOLE OR IN PART,
              REGARDLESS OF THE PAYMENT METHOD, EXCEPT AS EXPRESSLY SET FORTH IN THESE TERMS
              OR AS REQUIRED BY APPLICABLE LAW. AT GIBBLY'S SOLE DISCRETION, GIBBLY MAY REFUND THE
              PORTION OF A YEARLY SUBSCRIPTION TO AN INDIVIDUAL IF THEIR EDUCATIONAL INSTITUTION
              PURCHASES A PLAN FOR THEIR USE. THE AMOUNT OF THE REFUND SHALL BE CALCULATED BY
              MULTIPLYING THE AMOUNT OF MONTHS THE TEACHERS USED THE PLAN BY THE MONTHLY FEE FOR
              USE CALCULATED AT THE MONTHLY AND NOT YEARLY RATE. THAT AMOUNT SHALL BE DEDUCTED
              FROM THE YEARLY FEE PAID BY THE TEACHER.</Text>


            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Gibbly Subscription Plans</Heading>

            <Text paddingBottom={'large'}>Gibbly offers both free and paid subscription plans for services offered as
              Gibbly Premium accounts. Gibbly Premium Subscription Plans also include Group Plans.
              Those plans are available in accordance with the following:</Text>

            <Text paddingBottom={'large'}>Gibbly Services that may be accessed subject to payment are currently referred to as
              “Gibbly Premium Subscriptions”. You agree to abide by the terms and restrictions applicable
              to the Service Plan that you have signed up for.</Text>

            <Text paddingBottom={'large'}> We reserve the right to change or withdraw features, specifications, services, and
              content of a Service Plan at any time, without notice to you. Please note that the content
              and features of a Service Plan may vary based on when you signed up and whether you have
              signed up through our website. We reserve the right to terminate your account for any
              reason acceptable to Gibbly.</Text>

            <Text paddingBottom={'large'}>When you create an account with us, you must provide us information that is accurate,
              complete, and current at all times. Failure to do so constitutes a breach of the terms,
              which may result in immediate termination of your account.</Text>

            <Text paddingBottom={'small'}>We will not store or collect your payment card details. That information is provided
              directly to our third-party payment processors whose use of your personal information is
              governed by their Privacy Policy. These payment processors adhere to the standards set by
              PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of
              brands like Visa, MasterCard, American Express and Discover. PCI-DSS requirements help
              ensure the secure handling of payment information.</Text>

            <ul>
              <li>Stripe</li>
            </ul>

            <Text>Their Privacy Policy can be viewed <Link href={"https://stripe.com/us/privacy"} isExternal={true}> <u>here</u>.</Link> </Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Gibbly Starter</Heading>

            <Text paddingBottom={'large'}>Gibbly Starter Services is provided to you free-of-charge, for as long as you use
              the Services for non-commercial purposes only, in accordance with the limitations of
              use set out in these Terms of Service. The Service Plan that does not require payment
              is currently referred to as the “Gibbly Starter”. </Text>

            <Text>YOU MAY NOT USE GIBBLY STARTER FOR
              COMMERCIAL PURPOSES. HENCE, IF YOU INTEND TO USE GIBBLY SERVICES FOR PROFESSIONAL
              PURPOSES, INCLUDING BUT NOT LIMITED TO IN CONJUNCTION WITH OR AS PART OF A PAID
              SERVICE OR PRODUCT, FOR THE PURPOSE OF MARKETING YOUR OWN OR SOMEONE ELSE'S PRODUCTS
              OR SERVICES OR OTHERWISE IN CONJUNCTION WITH A COMMERCIAL EVENT SUCH AS A CONCERT,
              GAME SHOW OR BROADCASTED EVENT, YOU MUST SIGN UP FOR A PAID SUBSCRIPTION OR ENTER
              INTO A SEPARATE AGREEMENT WITH GIBBLY.</Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Paid Subscriptions</Heading>

            <Text>Gibbly Premium is a paid version of our services. Gibbly may offer additional
              subscription offers which are subject to these same terms and conditions. Gibbly
              offers various Service Plans for use of the Gibbly Services at home, in schools, businesses,
              and non-profit organizations. Some Service Plans require payment before you can access them
              and certain areas of use are only permitted if you have signed up for a Paid Subscription
              or entered into a separate agreement with Gibbly. If you have signed up for Paid Subscriptions,
              you may get access to features that are not available through Gibbly Starter and you may use
              the Services for commercial purposes, only if and to the extent such commercial use is
              permitted under the selected Service Plan.</Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Pre-Paid Offers</Heading>

            <Text>If you have received a code or other offer provided or sold by or on behalf of Gibbly
              for access to a Paid Subscription (“Code”), separate terms and conditions presented to you
              along with the Code may also apply to your access to the Service and you agree to comply
              with any such terms and conditions.</Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Trials</Heading>

            <Text paddingBottom={'large'}>We may, at our own sole discretion, offer trials of Paid Subscriptions for a specified
              period without payment or at a discounted rate (a “Trial”). We reserve the right to revoke
              the Trial and put your account on hold in the event that we determine that you are not
              eligible.</Text>

            <Text paddingBottom={'large'}>For some Trials, we'll require you to provide your payment details to start the Trial.
              We will charge you on a recurring basis for the selected subscription fee at the end of
              the Trial using the payment details you have provided us, unless you cancel your subscription
              prior to the end of the Trial.</Text>

            <Text>You have provided your payment details in conjunction with the Trial and you accept that
              we charge you using such payment details. If you do not want this charge, you must cancel
              your Paid Subscriptions through your Gibbly account's settings or terminate your Gibbly
              account before the end of the Trial. If you do not want to continue to be charged on a
              recurring monthly or annual basis (as the case may be, depending on your Service Plan),
              you must cancel the applicable paid subscription through your Gibbly account's subscription
              page or terminate your Gibbly account before the end of the recurring monthly period.
              Paid Subscriptions cannot be terminated before the end of the period for which you have
              already paid, and except as expressly provided in these terms, Gibbly will not refund any
              fees that you have already paid.</Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Payments and Cancellations</Heading>

            <Text paddingBottom={'large'}>If you have signed up for Gibbly Starter or if you cancel your Paid Subscription during
              a Trial Subscription period, Gibbly will never charge you any fees for your use of the Gibbly
              Service.</Text>

            <Text paddingBottom={'large'}>If you have signed up for a Paid Subscription for a monthly subscription fee, you will be
              charged for one month's subscription fee. The Paid Subscription and the payment to Gibbly will
              automatically renew at the end of the monthly subscription period, unless you cancel your Paid
              Subscription through your subscription page before the end of the current monthly subscription
              period. The monthly subscription period commences on the day of the month that you purchase
              the subscription and renews on the same day of the month the following month.</Text>

            <Text paddingBottom={'large'}>If you have signed up for a Paid Subscription which gives you access to the Gibbly Service
              for a specific time period (“Pre-Paid Period”), you will be charged for the Pre-Paid Period in
              advance upon expiration of any Trial. The Paid Subscription and the payment to Gibbly will
              automatically renew at the end of the Pre-paid Period unless you cancel your Paid Subscription
              through your subscription page before the end of the Pre-paid Period.</Text>

            <Text paddingBottom={'large'}>If you cancel your Paid Subscription, the cancellation will take effect on the day after
              the last day of the current subscription period, after which you will be downgraded to Gibbly
              Starter. We will not refund any subscription fees already paid to us.</Text>

            <Text paddingBottom={'large'}>Gibbly may change the price for the Paid Subscriptions from time to time and will
              communicate any price changes to you in advance and, if applicable, how to accept those
              changes. Price changes for Paid Subscriptions will take effect at the start of the next
              subscription period following the date of the price change. As permitted by local law,
              you accept the new price by continuing to use the Gibbly Service after the price change
              takes effect. If you do not agree with the price changes, you have the right to reject
              the change by unsubscribing from the Paid Subscription prior to the price change going
              into effect, in which case you will be downgraded to Gibbly Starter.</Text>

            <Text>We do not provide any refunds if the price for a Paid Subscription drops, or if we
              offer subsequent promotional pricing or change the content or features of a Service Plan.
              Paid Subscriptions purchased through other platforms are subject to the refund policies
              of those platforms. Gibbly cannot be held responsible for these platforms' policies.</Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Upgrades and Downgrades</Heading>
            <Text> You may at any time upgrade or downgrade your Paid Subscription to a different
              Service Plan:</Text>

            <Heading level={6} paddingTop={'large'} paddingBottom={'small'}>Paid Subscriptions – Monthly:</Heading>

            <ul>
              <li style={{ 'paddingBottom': '.2em' }}>If you have downgraded your monthly Paid Subscription to a lower Service Plan, such downgrade
                will take effect on the day after the last day of the current subscription period.</li>
              <li>If you have upgraded your monthly Paid Subscription to a higher Service Plan, such upgrade
                will take effect immediately subject to payment of the upgrade fee. </li>
            </ul>


            <Heading level={6} paddingTop={'large'} paddingBottom={'small'}>Paid Subscriptions - Pre-paid Period:</Heading>

            <ul>
              <li style={{ 'paddingBottom': '.2em' }}>If you have downgraded your Paid Subscription to a lower Service Plan during the Pre-paid
                Period, such downgrade will take effect on the date of renewal (i.e. the day after the last
                day of the Pre-paid Period).</li>
              <li>If you have upgraded your Paid Subscription to a higher Service Plan during the Pre-paid
                Period, such upgrade will take effect immediately subject to payment of the upgrade fee
                and you will automatically enter into a new Pre-paid Period.
              </li>
            </ul>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Taxes</Heading>

            <Text>Prices listed may not include sales or value added tax and applicable tax may be
              calculated and added at the time you complete a transaction and, depending on where
              you have purchased your Paid Subscription, such tax may not be visible to you until you
              receive a receipt for your purchase. In certain jurisdictions, local laws require that
              prices include all applicable taxes, in which case this will be indicated at the time of
              the transaction. In the event that you are claiming an exemption from sales tax, you must
              supply Gibbly with exemption certificates in order to benefit from any exemption. Failure
              to supply an exemption certificate will result in the possibility of the charge for sales
              or value added taxes.</Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Prohibited Uses</Heading>

            <Text paddingBottom={'large'}>You may use the Services only for lawful purposes and in accordance with these Terms
              of Service. You agree not to access or use the Services for any purpose that is illegal
              or beyond the scope of the Services' intended use (in Gibbly's sole judgment).</Text>

            <Text paddingBottom={'large'}>You agree that you will not, in whole or in part or under any circumstances,
              do the following:</Text>

            <ol >
              <li style={{ 'paddingBottom': '1em' }}>Derivative Works: Copy or reproduce, translate, reverse engineer, derive source
                code from, modify, disassemble, decompile, or create derivative works based on or
                related to the Platform.</li>
              <li style={{ 'paddingBottom': '1em' }}>Cheating: Create, use, offer, promote, advertise, make available and/or distribute
                the following or assist therein:</li>
              <ol>
                <li style={{ 'paddingBottom': '0.5em' }}>cheats; i.e. methods not expressly authorized by Gibbly (whether accomplished
                  using hardware, software, a combination thereof, or otherwise), influencing and/or
                  facilitating gameplay, including exploits of any in-game bugs, and thereby granting
                  you and/or any other user an advantage over other players not using such methods;</li>
                <li style={{ 'paddingBottom': '0.5em' }}>bots; i.e. any code and/or software, not expressly authorized by Gibbly, that
                  allows the automated control of a Game, or any other feature of the Platform, e.g.
                  the automated control of a character in a Game;</li>
                <li style={{ 'paddingBottom': '0.5em' }}>hacks; i.e. accessing or modifying the software of the Platform in any manner not
                  expressly authorized by Gibbly;</li>
                <li>and/or any code and/or software, not expressly authorized by Gibbly, that can be
                  used in connection with the Platform and/or any component or feature thereof which
                  changes and/or facilitates the gameplay or other functionality;</li>
              </ol>
            </ol>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>User Contributions</Heading>

            <Text paddingBottom={'large'}>The Services contain various forums, networks, and other interactive features that
              allow you to post, submit, publish, display, or transmit to Gibbly and other users
              (“Post”) content or materials (“User Contributions”) on or through the Services.</Text>

            <Text paddingBottom={'large'}>All User Contributions must comply with the following content standards: User
              Contributions must not be illegal, fraudulent, deceptive, obscene, threatening, defamatory,
              invasive of privacy, infringing of intellectual property rights, or otherwise injurious
              to third parties or objectionable, and must not consist of or contain software viruses,
              commercial solicitation, chain letters, mass mailings, or any form of “spam.”</Text>

            <Text paddingBottom={'large'}>Any User Contribution that you Post will be considered non-confidential and
              non-proprietary, and you grant Gibbly a nonexclusive, royalty-free, perpetual, irrevocable,
              and fully sublicensable right to use, copy, reproduce, modify, adapt, publish, translate,
              create derivative works from, distribute, and display such User Contribution throughout
              the world in any media; however, Gibbly will only share personal information that you
              provide in accordance with Gibbly's&nbsp;
              <Link href={"http://www.gibbly.co/privacy-policy"}><u>Privacy Policy</u>.</Link></Text>

            <Text paddingBottom={'large'}>Gibbly allows users to create games. If the user selects the public option, Gibbly
              will obtain a nonexclusive, royalty-free, perpetual, irrevocable, and fully sublicensable
              right to use, copy, reproduce, modify, adapt, publish, translate, create derivative works
              from, distribute, and display such games.</Text>

            <Text paddingBottom={'large'}>You represent and warrant that you own or otherwise control all of the rights to the
              User Contributions that you Post at the time of Posting; that the User Contributions are
              accurate and not fraudulent or deceptive; and that the User Contributions do not violate
              these Terms of Service or the rights (intellectual property rights or otherwise) of any
              third party, and will not cause injury to any person or entity. You understand that your
              User Contributions may be copied by other Services users and discussed on and outside of
              the Services, and if you do not have the right to submit User Contributions for such use,
              it may subject you to liability. Gibbly takes no responsibility and assumes no liability
              for any content posted by you or any third party.</Text>

            <Text>Gibbly has the right but not the obligation to monitor and edit or remove any User
              Contributions. Gibbly also has the right to terminate your access to all or part of the
              Services for any or no reason, including without limitation, any violation of these Terms
              of Service. Gibbly may exercise these rights at any time, without notice or liability to
              you or any third party.</Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Linking</Heading>

            <Text paddingBottom={'large'}>You may link to publicly available portions of the Services if you do so in a way
              that is fair and does not damage or take advantage of our reputation, but you must not
              establish a link in such a way as to suggest any form of association, approval, or
              endorsement on our part. The Services must not be framed on any other website or service.
              We reserve the right to withdraw linking permission without notice.</Text>

            <Text>If the Services contain links to other sites and resources provided by third parties,
              these links are provided for your convenience only. We have no control over the contents
              of those sites or resources and accept no responsibility for them or for any loss or
              damage that may arise from your use of them.</Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Disclaimers and Limitation of Liability</Heading>

            <Text paddingBottom={'large'}>Nothing in these Terms will prejudice the statutory rights that you may have as
              a consumer of the Services. Some countries, states, provinces, or other jurisdictions
              do not allow the exclusion of certain warranties, or the limitation of liability as
              stated in this section, so the below terms may not fully apply to you. Instead, in
              such jurisdictions, the exclusions and limitations below shall apply only to the extent
              permitted by the laws of such jurisdictions.</Text>

            <Text paddingBottom={'large'}>The Services and all information, content, materials, products (including software),
              and other services included on or otherwise made available to you through the Services
              are provided by Gibbly on an “as is'' and “as available” basis. Gibbly makes no
              representations or warranties of any kind, express or implied, as to the operation of
              the Services, or the information, content, materials, products (including software),
              or other services included on or otherwise made available to you through the Services.
              You expressly agree that your use of the Services is at your sole risk. To the full
              extent permissible by law, Gibbly disclaims all warranties, express or implied, including,
              but not limited to, implied warranties of merchantability and fitness for a particular
              purpose. Gibbly does not warrant that the Services, information, content, materials,
              products (including software), or other services included on or otherwise made available
              to you through the Services, Gibbly's servers, or electronic communications sent from
              Gibbly are free of viruses or other harmful components.</Text>

            <Text>To the full extent permissible by law, Gibbly will not be liable for any loss of
              profits or any indirect, incidental, punitive, special, or consequential damages arising
              out of or in connection with these Terms of Service. Further, to the full extent
              permissible by law, Gibbly's aggregate liability arising out of or in connection with
              these Terms will not exceed the total amounts you have paid (if any) to Gibbly under this
              Agreement during the twelve (12) months immediately preceding the events giving rise to
              such liability. These limitations and exclusions regarding damages apply even if any
              remedy fails to provide adequate compensation.</Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Indemnification</Heading>

            <Text paddingBottom={'large'}>This section only applies to the extent permitted by applicable law. If you are
              prohibited by law from entering into the indemnification obligation below, then you
              assume, to the extent permitted by law, all liability for all claims, demands, actions,
              losses, liabilities, and expenses (including attorneys' fees, costs, and expert
              witnesses' fees) that are the stated subject matter of the indemnification
              obligation below.</Text>

            <Text>You agree to defend, indemnify, and hold harmless Gibbly, its affiliates, and
              licensors, and their respective officers, directors, employees, contractors, agents,
              licensors, and suppliers from and against any claims, liabilities, damages, judgments,
              awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) resulting
              from your User Contributions or violation of these Terms.</Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Governing Law and Jurisdiction</Heading>

            <Text>Any dispute or claim by you arising out of or related to these Terms shall be governed
              by Ontario law, exclusive of its choice of law rules. You and Gibbly agree to submit to the
              exclusive jurisdiction of The Ontario Court of Justice in Ontario, or, if federal court
              jurisdiction exists, the Supreme Court of Canada. You and Gibbly agree to waive any
              jurisdictional, venue, or inconvenient forum objections to such courts (without affecting
              either party's rights to remove a case to federal court if permissible), as well as any
              right to a jury trial. The Convention on Contracts for the International Sale of Goods
              will not apply. Any law or regulation which provides that the language of a contract
              shall be construed against the drafter will not apply to these Terms of Service. This
              paragraph will be interpreted as broadly as applicable law permits.</Text>


            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>United States Legal Compliance</Heading>

            <Text>You represent and warrant that (i) you are not located in a country that is subject
              to the United States government embargo, or that has been designated by the United States
              government as a "terrorist supporting" country, and (ii) you are not listed on any
              United States government list of prohibited or restricted parties.</Text>


            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>No Class Actions</Heading>

            <Text paddingBottom={'large'}>PLEASE READ THIS SECTION CAREFULLY. IT AFFECTS YOUR RIGHTS, INCLUDING YOUR RIGHT
              TO FILE A LAWSUIT IN COURT.</Text>

            <Text paddingBottom={'large'}>Most issues can be resolved quickly and amicably by contacting Gibbly customer
              support at support@gibbly.app. We understand that sometimes disputes can't be easily
              resolved by customer support. This Section explains how you and Gibbly agree to resolve
              those disputes.</Text>

            <Text paddingBottom={'large'}>Disputes related to these Terms:</Text>

            <Text>If you have an issue related to these Terms, Gibbly's dispute-resolution
              terms below apply.</Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Informal Resolution</Heading>

            <Text paddingBottom={'large'}>If you have an issue that our customer support can't resolve, prior to starting
              arbitration you and Gibbly agree to attempt to resolve the dispute informally to help
              get us to a resolution and control costs for both parties. You and Gibbly agree to make
              a good-faith effort to negotiate any dispute between us for at least 30 days (“Informal
              Resolution”). Those informal negotiations will start on the day you or Gibbly receive
              a written Notice of a Dispute in accordance with these Terms.</Text>

            <Text paddingBottom={'large'}>You will send your Notice of Dispute to Gibbly, 3-209 St. Clements Avenue,
              Toronto, Ontario, M4R 1H3, Canada. Include your name, any relevant account information
              you use, address, how to contact you, what the problem is, and what you want Gibbly to do.
              If Gibbly has a dispute with you, Gibbly will send our Notice of Dispute to your
              registered email address and any billing address you have provided us.</Text>

            <Text>If the dispute isn't resolved by Informal Resolution or small-claims court (
              below), you or Gibbly may start an arbitration in accordance with these
              Terms of Service.</Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Class Action Waiver</Heading>

            <Text>To the maximum extent permitted by applicable law, you and Gibbly agree to only
              bring Disputes in an individual capacity and shall not: seek to bring, join, or
              participate in any class or representative action, collective or class-wide arbitration,
              or any other action where another individual or entity acts in a representative capacity
              (e.g., private attorney general actions); or consolidate or combine individual proceedings
              or permit an arbitrator to do so without the express consent of all parties to these
              Terms of Service and all other actions or arbitrations.</Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Severability</Heading>

            <Text>If all or any provision of these Terms of Service is found invalid, unenforceable,
              or illegal, then you and Gibbly agree that the provision will be severed, and the rest
              of these Terms of Service shall remain in effect and be construed as if any severed
              provision had not been included. The sole exception is that if the Class Action
              Waiver is found invalid, unenforceable, or illegal, you and Gibbly agree that it
              will not be severable; any dispute will be resolved in court subject to the venue
              and choice of clauses specified in these Terms of Service.</Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Language</Heading>

            <Text>To the fullest extent permitted by law, the controlling language for these
              Terms of Service is English. It is the express wish of the parties that
              these Terms of Service and all related documents have been drawn up in English.
              Any translation has been provided for your convenience.</Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Waiver and Severability</Heading>

            <Text paddingBottom={'large'}>No waiver of these Terms of Service by Gibbly shall be deemed a further or
              continuing waiver of such term or condition or any other term or condition, and
              any failure of Gibbly to assert a right or provision under these Terms of Service
              shall not constitute a waiver of such right or provision.</Text>

            <Text paddingBottom={'large'}>If any provision of these Terms of Service is held by a court of competent
              jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision
              shall be eliminated or limited to the minimum extent such that the remaining provisions
              of these Terms of Service will continue in full force and effect.</Text>

            <Text>If the class action waiver is found to be illegal or unenforceable as to all
              or some parts of a dispute, then those parts will not be arbitrated but will be
              resolved in court, with the balance resolved through arbitration.</Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>General Questions</Heading>

            <Text>For general questions, contact us at support@gibbly.app.</Text>

          </View>
        </View >
      </Container >
      <Footer />

    </>

  );
}
