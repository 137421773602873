import RestAPI from "../../../util/RestAPI";
import { Referral } from "../types/referralTypes";

export async function listReferrals(referrerId: string) {
  try {
    const referrals: Referral[] = await RestAPI.get(
      "/referrals/" + referrerId,
      {}
    ).then((res) =>
      res.json()) as any as Referral[];
    return referrals;
  } catch (err) {
    console.error("error listing referrals:", err);
    throw err;
  }
}
