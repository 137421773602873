import React from "react";
import RaisedButton from "../../../components/RaisedButton";
import GalleryIcon from "../../../components/icons/GalleryIcon";
import ImageGallery, {
  ImageDimensions,
} from "../../quiz/components/ImageGallery";
import useModal from "../../../hooks/useModal";
import { S3ObjectProtected } from "../../../API";
import { ButtonProps } from "@aws-amplify/ui-react";

type ImageGalleryButtonProps = ButtonProps & {
  onSelectImage: (
    image: S3ObjectProtected,
    url: string,
    dimensions: ImageDimensions
  ) => void | Promise<void>;
  text?: string;
  fileName?: string;
};

export default function ImageGalleryButton(props: ImageGalleryButtonProps) {
  const { onSelectImage: onSelect, text, fileName, ...rest } = props;

  const [ImageGalleryModal, setShowImageGalleryModal] = useModal(
    {
      size: "lg",
      ReactComponent: () => (
        <ImageGallery
          text={text}
          onUpload={async (image, url, dimensions) => {
            await onSelect(image, url, dimensions);
            setShowImageGalleryModal(false);
          }}
        />
      ),
    },
    [text, onSelect, fileName]
  );

  return (
    <>
      <ImageGalleryModal />
      <RaisedButton
        {...rest}
        backgroundColor={"white"}
        color={"black"}
        border={"none"}
        onClick={() => {
          setShowImageGalleryModal(true);
        }}
      >
        <GalleryIcon />
      </RaisedButton>
    </>
  );
}
