import {
  Authenticator,
  Button,
  Flex,
  Grid,
  Heading,
  Text,
  View,
} from "@aws-amplify/ui-react";
import React from "react";
import ResultsOverview from "./ResultsOverview";
import ChallengingQuestions from "./ChallengingQuestions";
import { PageIndex, PageTitle } from "../../../../util/SEO";
import { useNavigate, useParams } from "react-router-dom";
import ArrowLeftIcon from "../../../../components/icons/ArrowLeftIcon";
import GradeSummary from "./GradeSummary";
import Leaderboard from "./Leaderboard";
import { useAssignmentResults } from "../../hooks/useAssignmentResults";
import { useClassroom } from "../../hooks/useClassroom";
import { getAssignment } from "../../util/assignment/getAssignment";
import useAsync from "../../../../hooks/useAsync";
import ResultsTable from "./ResultsTable";
import { useQuery } from "@tanstack/react-query";
import useUserQuery from "../../../../hooks/useUserQuery";
import { getClassroomByClassId } from "../../util/classroom/getClassroomByClassId";

export default function AssignmentResultsPage() {
  PageTitle("Class Results | Gibbly");
  PageIndex("noindex");

  const navigate = useNavigate();

  const { classId, assignmentId } = useParams();

  const { user } = useUserQuery();

  // Should turn this into a useUserAcces hook
  const { data: canAccess } = useQuery(
    ["can-access", "class", classId, "identity-id", user?.identityId],
    async () => {
      const classroom = await getClassroomByClassId(classId as string);
      const canAccess = classroom.teacherId === user?.identityId;
      if (!canAccess) {
        navigate("/classes");
      }
      return classroom.teacherId === user?.identityId;
    },
    {
      suspense: true,
      enabled: !!user && !!classId,
    }
  );

  const assignmentResults = useAssignmentResults(assignmentId, {
    enabled: canAccess === true,
  });

  const { classroom } = useClassroom(classId, {
    enabled: canAccess === true,
  });

  const assignmentAsync = useAsync(async () => {
    if (!assignmentId || !classId) return;
    const assignment = await getAssignment(classId, assignmentId);
    return assignment;
  }, [assignmentId]);

  return (
    <>
      <Authenticator>
        <Flex
          width={"100%"}
          direction={"column"}
          padding={{ base: "0", medium: "medium" }}
          paddingTop={"large"}
          gap={{ base: "medium", medium: "xxxs" }}
        >
          <Flex
            justifyContent={{ base: "center", small: "space-between" }}
            direction={{ base: "column", small: "row" }}
          >
            <Flex>
              <Button
                variation="link"
                size="large"
                onClick={() => navigate(`/classes/${classId}`)}
              >
                <ArrowLeftIcon />
              </Button>
            </Flex>
            <Flex textAlign={"center"} justifyContent={"center"}>
              {/* <Button
                gap={"xs"}
                id="raised-btn"
                backgroundColor={"green.60"}
                variation="primary"
              >
                <FaDownload /> Export
              </Button> */}
            </Flex>
          </Flex>

          <Heading id="pageTitle">View Results</Heading>

          <Text paddingBottom={"large"}>
            {assignmentAsync.value?.quiz.title} - {classroom?.title}
          </Text>

          <View width={"100%"} grow={1}>
            <Flex direction={"column"} rowGap={"small"} paddingBottom={"xxl"}>
              <Grid
                templateColumns={{ base: "1fr", medium: "1fr 1fr" }}
                gap={"medium"}
              >
                {assignmentResults.completed && (
                  <ResultsOverview
                    assignmentResults={assignmentResults.completed}
                  />
                )}
                {assignmentId && (
                  <ChallengingQuestions answerResults={assignmentResults.allAnswerResults} assignment={assignmentAsync.value} />
                )}
              </Grid>
              {assignmentResults.completed && (
                <GradeSummary
                  assignmentResults={assignmentResults.studentBests}
                />
              )}
              {assignmentResults.completed && assignmentAsync.value && (
                <ResultsTable
                  assignment={assignmentAsync.value}
                  assignmentResults={assignmentResults.completed}
                />
              )}
              {assignmentResults.all && (
                <Leaderboard assignmentResults={assignmentResults.sorted} />
              )}
            </Flex>
          </View>
        </Flex>
      </Authenticator>
    </>
  );
}
