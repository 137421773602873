import { getIdentityId } from "../../features/game/util";
import getCreatorId from "../../util/GetCreatorId";
import { getCurrentEnvironment } from "../getCurrentEnvironment";

interface IChatStreamEvents {
  onOpen?: (ev: Event) => void;
  onMessage?: (ev: MessageEvent<any>) => void;
  onClose?: (ev: Event) => void;
  onError?: (error: Error) => void;
}

export async function connectToChatStream(callbacks: IChatStreamEvents) {
  const { onMessage, onOpen, onClose, onError } = callbacks;
  const userId = await getIdentityId();
  const creatorId = await getCreatorId();
  let socket: WebSocket | null = null;

  try {

    const env = await getCurrentEnvironment();

    // Websocket URL from AWS API Gateway
    const API_GATEWAY_URL =
      env === "prod"
        ? "wss://4sc7zmkuwa.execute-api.us-east-1.amazonaws.com/production/"
        : "wss://dx3qtxsrak.execute-api.us-east-1.amazonaws.com/dev/";

    // Create WebSocket connection
    socket = new WebSocket(
      API_GATEWAY_URL +
        `?${new URLSearchParams({
          userId: userId ?? "",
          creatorId,
        })}`
    );

    // Connection opened
    socket.addEventListener("open", onOpen ?? (() => {}));

    // Listen for messages
    socket.addEventListener("message", onMessage ?? (() => {}));

    // Connection closed
    socket.addEventListener("close", onClose ?? (() => {}));

  } catch (error) {
    console.error("Error connecting to chat stream:", error);
    onError?.(error as Error);
    // Close the connection if needed
    socket?.close();
  }
  return socket;
}
