import { copy } from "aws-amplify/storage";

type CopyS3ItemInput = {
  identityId?: string;
  fileName: string;
};

/***
 * Copys a file to the users S3 protected folder
 * @param {CopyS3ItemInput} input
 */
export async function copyS3Item(input: CopyS3ItemInput) {
  const { fileName, identityId } = input;
  const level = identityId ? "protected" : "public";
  const directory = identityId ? `${level}/${identityId}` : level;
  const result = await copy({
    source: {
      path: `${directory}/${fileName}`,
    },
    destination: {
      path: ({ identityId }) => `protected/${identityId}/${fileName}`,

    }
  });
  return result;

}
