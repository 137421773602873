import { useQuery } from "@tanstack/react-query";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { getQuizImage } from "../../../services/custom-queries";
import useEffectOnce from "../../../hooks/useEffectOnce";
import { generateClient } from "aws-amplify/api";
import { GetQuizQuery, S3ObjectProtected } from "../../../API";

export default function useQuizImage(quizId: string) {
  useEffectOnce(() => {
    refetch();
  });

  const {
    data: image,
    refetch: refetch,
    isLoading: loading,
  } = useQuery({
    queryKey: ["quiz image", quizId],
    enabled: quizId !== undefined,
    queryFn: async () => {
      const client = generateClient();
      const result = (await client.graphql({
        query: getQuizImage,
        variables: { id: quizId },
      })) as GraphQLResult<GetQuizQuery>;

      const quiz = result.data.getQuiz;
      // let { key, identityId } = { key: "", identityId: "" };

      // if (quiz.image !== null && quiz.image && quiz.image.key) {

      return quiz?.image as S3ObjectProtected;
    },
    onError: (e) => console.error("Quiz Query Failed", e),
  });

  return {
    image,
    refetch,
    loading,
  };
}
