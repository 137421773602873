import {
  Button,
  Flex,
  Heading,
  Link,
  Loader,
  Text,
} from "@aws-amplify/ui-react";
import * as React from "react";
import useAsync from "../../../hooks/useAsync";
import { toast } from "react-toastify";
import { updateUser } from "../../../util/User/updateUser";
import { CopyToClipboard } from "../../../util/CopyToClipboard";
import { getAvailableReferralCode } from "../../../util/User/getAvailableReferralCode";
import { useQuery } from "@tanstack/react-query";
import { listReferralsQuery } from "../utils/listReferralsQuery";
import RaisedButton from "../../../components/RaisedButton";
import { FaCopy, FaCrown, FaEnvelope } from "react-icons/fa";
import { getCheckoutSessionPremiumFreeTrial } from "../../../services/stripe/getCheckoutSessionPremiumFreeTrial";
import useUserQuery from "../../../hooks/useUserQuery";
import { FaXTwitter } from "react-icons/fa6";

export interface IReferAFriendProps {}

export function ReferAFriend(props: IReferAFriendProps) {
  const {} = props;

  const { user, org } = useUserQuery();

  const referralCode = useAsync(async () => {
    if (!user) return undefined;

    if (user.referralCode) return user.referralCode;

    try {
      const code = await getAvailableReferralCode();

      // update User with new referral code
      await updateUser(user.creatorId, { referralCode: code });

      return code;
    } catch (e) {
      toast.error("Error generating referral code");
      return undefined;
    }
  }, [user]);

  // const referrals: Referral[] = useAsync(async () => {
  //   if (!user) return [];
  //   return await listReferrals(user.creatorId);
  // }, [user]);

  const {
    data: allReferalls,
    isLoading: isLoadingReferalls,
    isFetching,
  } = useQuery(listReferralsQuery(user?.creatorId));

  const usableReferalls = React.useMemo(
    () => allReferalls?.filter((referral) => referral.status === "ACCEPTED"),
    [allReferalls]
  );

  // const canRedeem = React.useMemo(() => true, []);

  const canRedeem = React.useMemo(
    () => usableReferalls && usableReferalls?.length >= 5,
    [usableReferalls]
  );

  const shareMessage = `I just tried out this awesome AI tool by @GibblyCo. You can create quizzes and lessons on any topic in minutes.
  Use my referral code ${referralCode.value} for free AI tokens! www.gibbly.co`;

  const shareEmail = `I just tried out this awesome AI tool by Gibbly (www.gibbly.co). You can create quizzes and lessons on any topic in minutes.
  Use my referral code ${referralCode.value} for free AI tokens!`;

  return (
    <>
      <Flex direction="column" alignItems={"center"} rowGap={"small"}>
        <Heading
          textAlign={"center"}
          fontSize={{ base: "small", small: "large", medium: "xl" }}
          paddingBottom={"zero"}
        >
          Refer Friends, Earn Tokens!
        </Heading>

        <Flex direction={"column"} padding={"medium"}>
          <Flex
            alignContent={"center"}
            direction={{ base: "column", small: "row" }}
            gap="xxs"
            padding={"zero"}
            justifyContent={"center"}
          >
            <Text
              alignSelf={"center"}
              fontSize={{ base: "smaller", medium: "medium" }}
            >
              Your Referral Code:
            </Text>
            <Button
              isLoading={referralCode.loading}
              isDisabled={referralCode.error !== undefined}
              loadingText="Generating Referral Code..."
              padding={"xxxs"}
              fontSize={{ base: "small", medium: "large" }}
              backgroundColor={"background.tertiary"}
              gap={"small"}
              variation="link"
              color={"black"}
              onClick={() =>
                CopyToClipboard(
                  referralCode.value ? referralCode.value : "",
                  "Referral Code Copied 🙏"
                )
              }
              data-attr="copied-referral-code"
            >
              {referralCode.value}
              <FaCopy />
            </Button>
          </Flex>
          <Flex
            alignContent={"center"}
            justifyContent={"center"}
            direction={{ base: "column", small: "row" }}
          >
            <Text
              alignSelf={"center"}
              fontSize={{ base: "smaller", medium: "medium" }}
            >
              Friends Referred:
            </Text>
            {isLoadingReferalls ? (
              <Loader />
            ) : (
              <Text
                textAlign={"center"}
                fontWeight={"bold"}
                opacity={isFetching ? "50" : undefined}
              >
                {allReferalls?.length}
              </Text>
            )}
          </Flex>

          <Flex justifyContent={"center"}>
            <RaisedButton
              className={canRedeem ? "bouncy" : ""}
              gap={"small"}
              size="small"
              backgroundColor={"#47a6ff"}
              isDisabled={!canRedeem}
              color={"white"}
              fontWeight={"normal"}
              textAlign={"center"}
              onClick={async () => {
                if (canRedeem) {
                  if (org?.subscriptionStatus === "active") {
                    toast.info(
                      "Your organization has already subscribed to Gibbly Premium"
                    );
                  } else {
                    const checkoutSession =
                      await getCheckoutSessionPremiumFreeTrial();
                    window.location.href = checkoutSession.url;
                  }
                } else {
                  toast.error(
                    "You need 5 referrals to redeem a free month of Gibbly Premium"
                  );
                }
              }}
            >
              {canRedeem ? (
                <>
                  <FaCrown color="gold" />
                  <b>Redeem 1 Month</b>
                  <FaCrown color="gold" />
                </>
              ) : (
                <Text color="white">
                  Refer{" "}
                  <b>
                    <u>
                      {usableReferalls ? 5 - usableReferalls.length : 5} more
                    </u>
                  </b>{" "}
                  for 1 free month
                </Text>
              )}
            </RaisedButton>
          </Flex>
        </Flex>

        <Heading
          textAlign={"center"}
          fontSize={{ base: "small", small: "larger" }}
          paddingBottom={"zero"}
        >
          Here's What To Do:
        </Heading>

        <ol>
          <Text
            fontSize={{ base: "xs", medium: "small" }}
            paddingBottom={"xxxs"}
          >
            <li>Share your referral code with family and friends</li>
          </Text>
          <Text
            fontSize={{ base: "xs", medium: "small" }}
            paddingBottom={"xxxs"}
          >
            <li>Ask them to use your referral code when they sign up</li>
          </Text>

          <Text
            fontSize={{ base: "xs", medium: "small" }}
            paddingBottom={"xxxs"}
          >
            <li>
              For every successful refer, you’ll get 10,000 tokens and they will
              too
            </li>
          </Text>

          <Text fontSize={{ base: "xs", medium: "small" }}>
            <li>
              After 5 friends join, you can redeem a free month of Gibbly
              Premium!
            </li>
          </Text>
        </ol>

        <Flex>
          <Link
            isExternal
            href={`https://twitter.com/intent/tweet?&ref_src=twsrc%5Etfw&text=${shareMessage}%0A%0A&hashtags=AIforEducation,TeachersWhoAI,TeachersofTwitter,Teachers`}
          >
            <RaisedButton
              backgroundColor={"white"}
              isFullWidth
              gap={"small"}
              color={"black"}
              variation={"primary"}
            >
              <FaXTwitter />
            </RaisedButton>
          </Link>

          <Link
            isExternal
            href={`mailto:?subject=You need to check out Gibbly!&body=Hey! %0A%0A${shareEmail}`}
          >
            <RaisedButton
              isFullWidth
              backgroundColor={"white"}
              gap={"small"}
              color={"black"}
              variation={"primary"}
            >
              <FaEnvelope />
            </RaisedButton>
          </Link>
        </Flex>
      </Flex>
    </>
  );
}
