import RestAPI from "../../../util/RestAPI";
import { FreeTrial } from "../types";

type Params = Pick<FreeTrial, "userId" | "username">;

export async function startFreeTrial(params: Params) {
  await RestAPI.post("/free-trials", {
    body: { ...params, trialStatus: "active" },
  });
}
