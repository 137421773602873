import React, { useCallback, useContext, useEffect } from "react";
import {
  CreateQuestionInput,
  Question,
  QuestionType,
  S3ObjectProtected,
  UpdateQuestionInput,
} from "../../../API";
import {
  Grid,
  SelectField,
  TextAreaField,
  Card,
  Flex,
  View,
} from "@aws-amplify/ui-react";
import { getQuestionTypeString } from "../../../util/getQuestionTypeString";
import RaisedButton from "../../../components/RaisedButton";
import EditQuestionOptions from "./EditQuestionOptions";
import AddIcon from "../../../components/icons/AddIcon";
import useUpdateEffect from "../../../hooks/useUpdateEffect";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import useModal from "../../../hooks/useModal";
import ImageGallery from "./ImageGallery";
import SparkleIcon from "../../../components/icons/SparkleIcon";
import useChatStream from "../../../hooks/useChatStream";
import { ImageComponent } from "../../../components/ImageComponent";
import { useTranslationContext } from "../../../context/TranslationContextProvider";
import { QuizContext } from "./QuizContextProvider";
import CheckIcon from "../../../components/icons/CheckIcon";

interface EditQuestionProps {
  index: number | undefined;
  onChange?: (
    question: Omit<UpdateQuestionInput, "id"> | CreateQuestionInput
  ) => void;
  onSubmit: (
    questionInput: CreateQuestionInput,
    index: number
  ) => any | Promise<any>;
  question: Question | CreateQuestionInput | null | undefined;
}

export default function EditQuestion(props: EditQuestionProps) {
  const { index, question, onChange, onSubmit } = props;
  const { translations } = useTranslationContext();

  const { quiz } = useContext(QuizContext);

  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const [ImageGalleryModal, setShowImageGalleryModal] = useModal(
    {
      size: "lg",
      ReactComponent: () => (
        <ImageGallery
          onUpload={async (image) => {
            await handleImageChange(image);
            setShowImageGalleryModal(false);
          }}
          text={question?.text ?? ""}
        />
      ),
    },
    [question?.text]
  );

  const { loading, connect, output } = useChatStream({
    type: "solution",
    language: quiz?.lang,
    prompt: {
      type: question?.type,
      text: question?.text,
      answers: question?.answers,
      correctIndices: question?.correctIndices,
    },
  });

  useEffect(() => {
    onChange?.({
      solution: output[0] ?? question?.solution ?? "",
    });
  }, [output[0]]);

  async function handleImageChange(image: {
    key: string;
    identityId: string;
    alt?: string | undefined | null;
  }): Promise<void> {
    onChange?.({ image });
  }

  const [questionHasError, setQuestionHasError] =
    React.useState<boolean>(false);
  const [q1HasError, setQ1HasError] = React.useState<boolean>(false);
  const [q2HasError, setQ2HasError] = React.useState<boolean>(false);
  const [correctIndicesHasError, setCorrectIndicesHasError] =
    React.useState<boolean>(false);

  const validateQuestionText = useCallback(() => {
    if (question?.text === "") {
      setQuestionHasError(true);
      return false;
    } else {
      setQuestionHasError(false);
      return true;
    }
  }, [question?.text]);
  const validateAnswer1 = useCallback(() => {
    if (question?.type === QuestionType.TRUE_OR_FALSE) {
      setQ1HasError(false);
      return true;
    } else if (question?.answers?.[0] === "") {
      setQ1HasError(true);
      return false;
    } else {
      setQ1HasError(false);
      return true;
    }
  }, [question?.answers?.[0], question?.type]);
  const validateAnswer2 = useCallback(() => {
    if (
      question?.type === QuestionType.TRUE_OR_FALSE ||
      question?.type === QuestionType.TYPED_ANSWER
    ) {
      setQ2HasError(false);
      return true;
    } else if (question?.answers?.[1] === "") {
      setQ2HasError(true);
      return false;
    } else {
      setQ2HasError(false);
      return true;
    }
  }, [question?.answers?.[1], question?.type]);
  const validateCorrectIndices = useCallback(() => {
    if (question?.correctIndices?.length === 0) {
      setCorrectIndicesHasError(true);
      return false;
    } else {
      setCorrectIndicesHasError(false);
      return true;
    }
  }, [question?.correctIndices?.length]);

  useUpdateEffect(() => {
    validateQuestionText();
  }, [question?.text]);
  useUpdateEffect(() => {
    validateAnswer1();
  }, [question?.answers?.[0], question?.type]);
  useUpdateEffect(() => {
    validateAnswer2();
  }, [question?.answers?.[1], question?.type]);
  useUpdateEffect(() => {
    validateCorrectIndices();
  }, [question?.correctIndices?.length]);

  function validateQuestion(): boolean {
    let valid = true;

    valid =
      validateQuestionText() &&
      validateAnswer1() &&
      validateAnswer2() &&
      validateCorrectIndices();

    return valid;
  }

  const updateAnswers = (index: number, value: string) => {
    if (!question) return;
    let answers = [...question.answers];
    let correctIndices = [...question.correctIndices];

    switch (question?.type) {
      case QuestionType.TYPED_ANSWER:
        answers[index] = value;
        correctIndices = answers
          ?.map((a, i) => {
            if (a !== "" && a !== undefined) return i;
            return undefined;
          })
          .filter((a) => a !== undefined) as number[];
        break;
      case QuestionType.TRUE_OR_FALSE:
        return;
      default:
        if (value === "")
          correctIndices = correctIndices?.filter((i) => i !== index);
        answers[index] = value;

        break;
    }

    onChange?.({
      answers,
      correctIndices,
    });
  };

  function updateType(type: QuestionType): void {
    if (!question) return;
    if (type === question.type) return;
    let correctIndices = question.correctIndices;
    let answers = question.answers;

    switch (type) {
      case QuestionType.SINGLE_SELECT:
        if (correctIndices.length === 0) correctIndices = [0];
        if (answers.length < 4) {
          answers = [...answers];
          while (answers.length < 4) {
            answers.push("");
          }
        } else {
          answers = answers.slice(0, 4);
        }
        break;
      case QuestionType.MULTIPLE_SELECT:
        if (answers.length < 4) {
          answers = [...answers];
          while (answers.length < 4) {
            answers.push("");
          }
        } else {
          answers = answers.slice(0, 4);
        }
        break;
      case QuestionType.TRUE_OR_FALSE:
        // correct indices can only be [0] or [1]
        // if correctIndices contains 0, set correctIndices to [0]
        // else if correctIndices contains 1, set correctIndices to [1]
        // else set correctIndices to [0]
        if (correctIndices.includes(0)) correctIndices = [0];
        else if (correctIndices.includes(1)) correctIndices = [1];
        else correctIndices = [0];
        break;
    }
    onChange?.({
      type,
      answers,
      correctIndices,
    });
  }

  const updateCorrectIndices = (index: number, e: boolean) => {
    const checked = e;
    if (!question) return;

    let correctIndices = [...question.correctIndices];

    switch (question.type) {
      case QuestionType.MULTIPLE_SELECT:
        if (checked) {
          // add index if not in list
          if (!correctIndices.includes(index)) correctIndices.push(index);
        } else {
          // remove all occurences of index from list
          correctIndices = correctIndices.filter((i) => i !== index);
        }
        break;
      case QuestionType.SINGLE_SELECT:
        if (checked) {
          correctIndices = [index];
        }
        break;
      case QuestionType.TRUE_OR_FALSE:
        correctIndices = [checked ? index : index === 0 ? 1 : 0];

        break;
      default:
        if (checked) {
          correctIndices = [index];
        }
        break;
    }

    onChange?.({ correctIndices });
  };

  return (
    <>
      <ImageGalleryModal />
      <Grid
        height={"100%"}
        templateRows={"auto auto auto auto auto"}
        gap={"2px"}
      >
        {/* {hasError && <Text variation='error'>{errorMessage}</Text>} */}
        <Flex justifyContent={{ base: "center", large: "left" }}>
          <SelectField
            label={undefined}
            // size="small"
            fontWeight={"bold"}
            value={question?.type !== null ? question?.type : ""}
            onChange={(e) => {
              updateType(e.target.value as QuestionType);
            }}
          >
            {Object.values(QuestionType).map(
              (type: QuestionType, i: number) => {
                if (i < 6) return;
                return (
                  <option key={i} value={type}>
                    {getQuestionTypeString(type)}
                  </option>
                );
              }
            )}
          </SelectField>
        </Flex>

        <Grid
          templateColumns={{ base: "auto", xl: "1fr auto" }}
          gap={"small"}
          margin={"0 0 small 0"}
          width={"100%"}
        >
          <Flex justifyContent={"center"}>
            <View flex={1}>
              <OverlayTrigger
                show={questionHasError}
                placement="bottom"
                overlay={
                  <Tooltip id="correctAnswer-error-tooltip">
                    You haven't added a question.
                  </Tooltip>
                }
              >
                <b>
                  <TextAreaField
                    size="small"
                    // height={"100%"}
                    label={""}
                    variation="quiet"
                    placeholder="Enter your question here"
                    textAlign={"center"}
                    value={question?.text ?? ""}
                    onChange={(e) => {
                      onChange?.({
                        text: e.target.value,
                      });
                    }}
                  />
                </b>
              </OverlayTrigger>
            </View>
          </Flex>
          <Flex justifyContent="center">
            <ImageComponent
              alt={undefined}
              aspectRatio="16/9"
              height={"120px"}
              autoPickPrompt={question?.text ?? ""}
              updateImage={async ({ image }) => {
                await onChange?.({
                  image,
                });
              }}
              canEdit={true}
              image={
                question?.image?.key !== ""
                  ? (question?.image as S3ObjectProtected)
                  : undefined
              }
            />
          </Flex>
        </Grid>

        <EditQuestionOptions
          question={question}
          updateAnswers={updateAnswers}
          updateCorrectIndices={updateCorrectIndices}
          q1HasError={q1HasError}
          q2HasError={q2HasError}
          correctIndicesHasError={correctIndicesHasError}
        />
        <Card>
          <TextAreaField
            label={
              <Flex justifyContent={"end"}>
                <RaisedButton
                  size="small"
                  backgroundColor={"#9100ff"}
                  color={"white"}
                  onClick={connect}
                  isLoading={loading}
                >
                  <SparkleIcon />
                </RaisedButton>
              </Flex>
            }
            placeholder="Enter your solution here"
            size="small"
            variation={"quiet"}
            resize="vertical"
            rows={2}
            value={question?.solution ?? ""}
            onChange={(e) =>
              onChange?.({
                solution: e.target.value,
              })
            }
          />
        </Card>
        <Flex justifyContent={"center"}>
          <RaisedButton
            backgroundColor={"#1a90ff"}
            color={"white"}
            gap={"small"}
            isLoading={submitting}
            onClick={async () => await handleSubmit()}
          >
            {question ? (
              <>
                <CheckIcon />
                Done
              </>
            ) : (
              <>
                <AddIcon />
                Add Question
              </>
            )}
          </RaisedButton>
        </Flex>
      </Grid>
    </>
  );

  async function handleSubmit() {
    if (!validateQuestion()) return;
    setSubmitting(true);

    if (!question) return;

    let answers = [...question.answers];
    let correctIndices = [...question.correctIndices];

    if (question.type === QuestionType.TRUE_OR_FALSE) {
      if (answers?.[0] === translations.false)
        correctIndices?.[0] === 0
          ? (correctIndices = [1])
          : (correctIndices = [0]);

      answers = [translations.true, translations.false];
    } else {
      // if answers have any empty strings, remove them

      const correctAnswers = answers?.filter((_, i) =>
        correctIndices?.includes(i)
      );
      answers = answers?.filter((a, i) => {
        if (a === "") {
          // remove occurrences of i from correctIndices
          correctIndices = correctIndices?.filter((index) => index !== i);
          return false;
        }
        return true;
      });
      // create new correctIndices array
      correctIndices = answers
        ?.map((a, i) => {
          if (correctAnswers?.includes(a)) return i;
          return undefined;
        })
        .filter((a) => a !== undefined) as number[];
    }

    onChange?.({
      answers,
      correctIndices,
    });
    onSubmit?.({ ...question, answers, correctIndices }, index ? index : 0);
    setSubmitting(false);
  }
}
