import React, { useMemo, useState } from "react";
import { Button, Card, Flex, Grid, Text, View } from "@aws-amplify/ui-react";
import { Modal } from "react-bootstrap";
import CloseIcon from "../components/icons/CloseIcon";
import useUpdateEffect from "./useUpdateEffect";
import ArrowLeftIcon from "../components/icons/ArrowLeftIcon";
import { publish } from "../util/events";
import { ModalOptions } from "../types/ModalOptions";

export default function useModal(
  options: ModalOptions = {
    ReactComponent: () => <Card></Card>,
    title: "",
    initShow: false,
    hasExitButton: true,
    hasBackButton: true,
  },
  deps: React.DependencyList
): [React.FunctionComponent<any>, (arg: boolean) => void] {
  const {
    ReactComponent,
    initShow,
    hasExitButton,
    hasBackButton,
    onShow,
    onHide,
    title,
    size,
  } = options;

  const [show, setShow] = useState(initShow ? initShow : false);

  useUpdateEffect(() => {

    if (onShow && show) onShow();
    else if (onHide && !show) onHide();
  }, [show]);

  const hiddenExitButton: boolean = useMemo(
    () => hasExitButton !== undefined && hasExitButton === false,
    [hasExitButton]
  );
  const hiddenBackButton: boolean = useMemo(
    () => hasBackButton === undefined || hasBackButton === false,
    [hasBackButton]
  );

  const ModalContent = useMemo(
    () => (
      <>
        <View row={1} column={1}>
          <Button
            id="raised-btn"
            backgroundColor={"#1a90ff"}
            hidden={hiddenBackButton}
            variation={"primary"}
            onClick={() => {
              publish("onModalBack");
            }}
          >
            <ArrowLeftIcon />
          </Button>
        </View>
        <Flex justifyContent={"center"}>
          <Text
            fontSize={{ base: "medium", small: "large" }}
            row={1}
            column={2}
            fontWeight={"bold"}
            alignSelf={"center"}
          >
            {title}
          </Text>
        </Flex>
        <Flex justifyContent={"right"} alignItems={"start"}>
          <Button
            id="raised-btn"
            fontSize={{ base: "xs", medium: "medium" }}
            backgroundColor={"#de3721"}
            hidden={hiddenExitButton}
            variation={"destructive"}
            onClick={() => {

              setShow(false);
            }}
          >
            <CloseIcon />
          </Button>
        </Flex>
        <Card column={"1/4"} row={"2"} padding={"small"}>
          <ReactComponent />
        </Card>
      </>
    ),
    [...deps]
  );

  const Component: React.FunctionComponent<any> = useMemo<
    React.FunctionComponent<any>
  >(() => {
    return () => (
      <Modal show={show} size={size}>
        <Card
          boxShadow={
            "0px -5px rgb(0 0 0 / 20%) inset, 0px 0px 20px rgb(0 0 0 / 60%)"
            // "0px -5px rgb(0 0 0 / 20%) inset, 0px 10px 2px 6px rgb(0 0 0 / 40%), 0px 0px 0px 1px rgb(0 0 0)"
          }
          // paddingBottom={"-5px"}
          borderRadius={"medium"}
        >
          <Grid
            templateColumns="40px 1fr 40px"
            templateRows={"50px 1fr"}
            rowGap={"small"}
          >
            {ModalContent}
          </Grid>
        </Card>
      </Modal>
    );
  }, [show]);

  return [Component, setShow];
}
