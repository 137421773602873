import React from "react";
import { CreateQuestionInput, Question, QuestionType } from "../../../API";
import { Grid, View, Text, Flex } from "@aws-amplify/ui-react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import OptionCard from "./OptionCard";

interface Props {
  updateAnswers: (index: number, value: string) => void;
  updateCorrectIndices: (index: number, value: boolean) => void;
  q1HasError: boolean;
  q2HasError: boolean;
  correctIndicesHasError: boolean;
  question: Question | CreateQuestionInput | null | undefined;
}

function EditQuestionOptions(props: Props) {
  const {
    updateAnswers,
    updateCorrectIndices,
    q1HasError,
    q2HasError,
    correctIndicesHasError,
    question,
  } = props;

  return (
    <OverlayTrigger
      show={correctIndicesHasError}
      placement="bottom"
      overlay={
        <Tooltip id="correctAnswer-error-tooltip">
          You haven't selected a correct answer
        </Tooltip>
      }
    >
      <Flex
        direction="column"
        gap={"xs"}
        // paddingLeft={"medium"}
        // paddingRight={"medium"}
      >
        {question?.type === QuestionType.TYPED_ANSWER && (
          <Text fontWeight={"semibold"} fontSize={"smaller"}>
            Accepted Answers:
          </Text>
        )}
        <Grid
          templateColumns={{
            base: "1fr",
            small: "1fr 1fr",
            medium: "1fr",
            large: "1fr 1fr",
          }}
          autoRows={"80px"}
          gap={"xxs"}
        >
          <OverlayTrigger
            show={q1HasError}
            placement="bottom"
            overlay={<Tooltip id="q1-error-tooltip">Cannot be empty</Tooltip>}
          >
            <View>
              <OptionCard
                index={0}
                question={question}
                correctIndices={question?.correctIndices}
                updateAnswers={updateAnswers}
                updateCorrectIndices={updateCorrectIndices}
              />
            </View>
          </OverlayTrigger>
          <OverlayTrigger
            show={q2HasError}
            placement="bottom"
            overlay={<Tooltip id="q2-error-tooltip">Cannot be empty</Tooltip>}
          >
            <View>
              <OptionCard
                index={1}
                question={question}
                correctIndices={question?.correctIndices}
                updateAnswers={updateAnswers}
                updateCorrectIndices={updateCorrectIndices}
              />
            </View>
          </OverlayTrigger>
          {question?.type !== QuestionType.TRUE_OR_FALSE && (
            <>
              <OptionCard
                index={2}
                question={question}
                correctIndices={question?.correctIndices}
                updateAnswers={updateAnswers}
                updateCorrectIndices={updateCorrectIndices}
              />
              <OptionCard
                index={3}
                question={question}
                correctIndices={question?.correctIndices}
                updateAnswers={updateAnswers}
                updateCorrectIndices={updateCorrectIndices}
              />
            </>
          )}
        </Grid>
      </Flex>
    </OverlayTrigger>
  );
}

export default EditQuestionOptions;
