import React from "react";
import { Button } from "@aws-amplify/ui-react";
//import ExpandIcon from "../components/icons/ExpandIcon";
import QuitIcon from "../components/icons/QuitIcon";
import { useNavigate } from "react-router-dom";

type ExitButtonRelativeProps = {
  exitTo?: string;
};

export default function ExitButtonRelative({
  exitTo,
}: ExitButtonRelativeProps) {
  const navigate = useNavigate();

  return (
    <>
      <Button
        padding={"zero"}
        position={"relative"}
        gap={"xxxs"}
        color="white"
        direction={"column"}
        variation={"link"}
        size={"large"}
        onClick={() => navigate(exitTo ?? "/")}
      >
        <QuitIcon />
      </Button>
    </>
  );
}
