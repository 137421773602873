import { UseQueryOptions } from "@tanstack/react-query";
import { getProductAndPrices } from "../../services/stripe/getProductAndPrices";
import { Price, Product } from "../../types/stripe";

type ProductAndPrices = {
  product: Product;
  prices: { data: Price[] };
};

export function getProductAndPricesQuery(
  productId: string
): UseQueryOptions<ProductAndPrices> {
  return {
    queryKey: ["stripe", "product", "prices", productId],
    queryFn: async () => await getProductAndPrices(productId),

    enabled: !!productId,
  };
}
