import { TextElement } from "../types/slideTypes";
import { countUnicodeUnits } from "./countUnicodeUnits";

export type ParagraphStyle = {
  startIndex: number;
  endIndex: number;
  list?: "bullet" | "number";
  alignment?: "CENTER" | "START" | "END" | "JUSTIFIED";
};

export function getParagraphStyles(
  textElement: {
    id: string;
  } & TextElement
) {
  const paragraphStyles: ParagraphStyle[] = [];
  let paragraph: ParagraphStyle = { startIndex: 0, endIndex: 0 };
  let i1 = 0;
  textElement.props.ops.forEach((op) => {
    if (op.insert === "\n") {
      paragraph.list = op.attributes?.list as "bullet" | "number" | undefined;

      if (op.attributes?.align)
        switch (op.attributes?.align) {
          case "center":
            paragraph.alignment = "CENTER";
            break;
          case "right":
            paragraph.alignment = "END";
            break;
          case "left":
            paragraph.alignment = "START";
            break;
        }
      i1 += 1;
      paragraph.endIndex = i1;
      paragraphStyles.push({ ...paragraph });
      paragraph = { startIndex: i1, endIndex: i1 + 1 };
    } else {
      // find last instance of \n in op.insert
      const offset =
        (op.insert as string).lastIndexOf("\n") !== -1
          ? (op.insert as string).lastIndexOf("\n") + 1
          : 0;

      paragraph.startIndex = i1 + offset;

      i1 += countUnicodeUnits(op.insert as string);
    }
  });
  return paragraphStyles;
}

