import { GraphQLQuery } from "@aws-amplify/api";
import { generateClient } from "aws-amplify/api";
import { SearchQuizzesQuery } from "../../../API";
import { librarySearch } from "../../../services/custom-queries";
import getCreatorId from "../../../util/GetCreatorId";

export default async function fetchPaginatedSearchUsersQuizzes(
  search?: string,
  nextToken?: string
) {
  try {
    const client = await generateClient();
    const creator = await getCreatorId();
    const quizData = await client.graphql<GraphQLQuery<SearchQuizzesQuery>>({
      query: librarySearch,
      variables: { creator, search, nextToken },
    });

    const list = quizData.data?.searchQuizzes;

    const quizIds = list?.items.map((quiz) => quiz?.id);
    // if quizIds is undefined or if any element of quizIds is undefined, throw an error
    if (!quizIds) {
      throw new Error("Quiz ids are undefined");
    }

    return { quizIds, nextToken: list?.nextToken };
  } catch (error) {
    console.error(error);
    return { quizIds: [], nextToken: "" };
  }
}
