import { useCallback, useEffect, useMemo, useState } from "react";
import { AnswerResult, GameData, GameState } from "../../Types/GameTypes";
import { Feedback } from "./GameView";
import { QuestionType } from "../../../../API";
import { useGameContext } from "./GameContextProvider";

export interface IGamePlayMenuProps {}

export function useGamePlayMenu(props: IGamePlayMenuProps) {
  const {} = props;

  const { gameData, questions, setGameData, isMyTurn, handleSubmitAnswer } =
    useGameContext();

  const [carouselIndex, setCarouselIndex] = useState(1);

  const questionIndex = useMemo(
    () => gameData.questionIndex,
    [gameData.questionIndex]
  );

  const question = useMemo(
    () => questions?.[questionIndex],
    [questions, questionIndex]
  );

  const isAnswered = useMemo(() => {
    return questionIndex + 1 === gameData.answerResults.length;
  }, [questionIndex, gameData.answerResults]);

  const getFeedback = useCallback(
    (answer: number[] | string) => {
      try {

        // if (gameData.currentAnswer.length === 0) throw new Error("Current answer length is 0");
        if (!question) throw new Error("No question");

        const solution = question.solution as string;
        let isCorrect = false;
        let acceptedAnswers;
        switch (question.type) {
          case QuestionType.TYPED_ANSWER:

            if (typeof answer !== "string") {
              const newGameData = { ...gameData, currentAnswer: "" };
              setGameData(newGameData);
              // setGameData((prev) => {
              //   const newGameData = { ...prev, currentAnswer: "" };
              //   setGameData(newGameData);
              //   return newGameData;
              // });
              throw new Error("Current answer is not a string");
            }
            isCorrect = question.answers
              .map((a) => {
                return (a as string).toLowerCase();
              })
              .includes((answer as string).toLowerCase());
            acceptedAnswers = question.correctIndices.map(
              (ci) => question.answers[ci as number] as string
            );
            break;
          default:
            isCorrect =
              question.correctIndices.length === answer.length &&
              question.correctIndices.every((ci) =>
                (answer as number[]).includes(ci !== null ? ci : -1)
              );
            acceptedAnswers = [...(question.correctIndices as number[])];
            break;
        }
        const feedback: Feedback = { solution, isCorrect, acceptedAnswers };

        return feedback;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    [question, gameData]
  );

  const submitAnswer = useCallback(
    async (answer: number[] | string) => {

      const feedback = getFeedback(answer);

      if (feedback === undefined) throw new Error("Feedback is undefined");
      if (question === undefined || question === null)
        throw new Error("Question is undefined");

      let acceptedAnswers: string[] | number[];

      switch (question.type) {
        case QuestionType.TYPED_ANSWER:
          acceptedAnswers = question.correctIndices.map((index) => {
            return question?.answers[index as number] as string;
          });
          break;
        default:
          acceptedAnswers = question?.correctIndices as number[];
          break;
      }

      const result: AnswerResult = {
        questionIndex,
        submittedAnswer: answer,
        isCorrect: feedback.isCorrect,
        acceptedAnswers,
      };

      const newAnswerResults = [...gameData.answerResults];
      newAnswerResults.push(result);

      await handleSubmitAnswer?.(newAnswerResults);

      setGameData({
        ...gameData,
        answerResults: newAnswerResults,
      });
    },
    [question, questionIndex, gameData]
  );

  useEffect(() => {
    const keyDownHandler = async (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        if (isAnswered) return;
        if (isMyTurn && gameData.currentAnswer.length > 0) {
          await submitAnswer(gameData.currentAnswer);
        }
      }
    };

    window.addEventListener("keydown", keyDownHandler);

    // Cleanup: remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", keyDownHandler);
    };
  }, [isAnswered, isMyTurn, gameData.currentAnswer, submitAnswer]);

  const NextQuestion = useCallback(async () => {
    setCarouselIndex(1);

    const complete = gameData.questionIndex + 1 === questions?.length;

    const newGameData : GameData = {
      ...gameData,
      questionIndex: gameData.questionIndex + 1,
      currentAnswer: [],
      gameState: complete ? GameState.COMPLETE : GameState.PLAYING,
    };

    setGameData(newGameData);
  }, [gameData, setGameData, setCarouselIndex, questions]);


  // trying to add music to the end screen (START HERE MONDAY)

  return {
    gameData,
    questions,
    setGameData,
    isMyTurn,
    handleSubmitAnswer,
    getFeedback,
    isAnswered,
    question,
    submitAnswer,
    NextQuestion,
    carouselIndex,
    setCarouselIndex,
  };
}
