import React, { useState } from "react";

import {
  Button,
  TextField,
  Flex,
  SelectField,
  Text,
} from "@aws-amplify/ui-react";
import { placeholderTopics } from "../../../data/PlaceholderTopics";

import { PageIndex, PageTitle } from "../../../util/SEO";
import useEffectOnce from "../../../hooks/useEffectOnce";
import SparkleIcon from "../../../components/icons/SparkleIcon";
import { GenerationInput } from "./GenerateQuiz";
import LanguageSelect from "../../../components/LanguageSelect";

export default function TopicForm({
  onGenerate,
  loading,
}: {
  onGenerate: (input: GenerationInput) => void;
  loading: boolean;
}) {
  PageTitle("Gibbly | Generate Quiz");
  PageIndex("noindex");

  // const { prefLang } = useTranslationContext();

  const [formInput, setFormInput] = useState({
    topic: "",
    lang: "en",
    numberOfQuestions: "20",
    gradeLevel: "Easy",
  });

  const [topicPlaceholder, setTopicPlaceholder] = useState("");

  useEffectOnce(() => {
    const randomIndex = Math.floor(Math.random() * placeholderTopics.length);
    const randomPlaceholder = placeholderTopics[randomIndex];
    setTopicPlaceholder(randomPlaceholder);
  });

  const handleTopicSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onGenerate({
      prompt: { ...formInput },
      type: "mixed",
    });
  };

  return (
    <Flex
      as="form"
      direction={"column"}
      padding={"0"}
      onSubmit={handleTopicSubmit}
    >
      <TextField
        fontSize={"small"}
        size="small"
        label={
          <Text fontWeight={"semibold"} color={"neutral.90"}>
            Topic
          </Text>
        }
        isRequired
        placeholder={topicPlaceholder}
        // hasError={topicHasError}
        errorMessage={"Must provide a topic"}
        value={formInput.topic}
        onChange={(e) =>
          setFormInput((prev) => ({ ...prev, topic: e.target.value }))
        }
      />
      <Flex wrap={"wrap"}>
        <SelectField
          fontSize={"small"}
          size="small"
          flex={1}
          label={
            <Text fontWeight={"semibold"} color={"neutral.90"}>
              Grade Level
            </Text>
          }
          isRequired
          value={formInput.gradeLevel}
          onChange={(e) =>
            setFormInput((prev) => ({ ...prev, gradeLevel: e.target.value }))
          }
        >
          <option value={"Easy"}>Easy</option>
          <option value={"Medium"}>Medium</option>
          <option value={"Hard"}>Hard</option>
          <option value={"Grade 1"}>Grade 1</option>
          <option value={"Grade 2"}>Grade 2</option>
          <option value={"Grade 3"}>Grade 3</option>
          <option value={"Grade 4"}>Grade 4</option>
          <option value={"Grade 5"}>Grade 5</option>
          <option value={"Grade 6"}>Grade 6</option>
          <option value={"Grade 7"}>Grade 7</option>
          <option value={"Grade 8"}>Grade 8</option>
          <option value={"Grade 9"}>Grade 9</option>
          <option value={"Grade 10"}>Grade 10</option>
          <option value={"Grade 11"}>Grade 11</option>
          <option value={"Grade 12"}>Grade 12</option>
          <option value={"College/University"}>College/University</option>
        </SelectField>
        <LanguageSelect
          fontSize={"small"}
          size="small"
          flex={1}
          label={
            <Text fontWeight={"semibold"} color={"neutral.90"}>
              Language
            </Text>
          }
          isRequired
          value={formInput.lang}
          onChange={(lang) => setFormInput((prev) => ({ ...prev, lang: lang }))}
        ></LanguageSelect>
        <SelectField
          label={
            <Text fontWeight={"semibold"} color={"neutral.90"}>
              # of Questions
            </Text>
          }
          fontSize={"small"}
          size="small"
          isRequired
          value={formInput.numberOfQuestions}
          onChange={(e) =>
            setFormInput((prev) => ({
              ...prev,
              numberOfQuestions: e.target.value,
            }))
          }
        >
          // from 1 to 20
          {[...Array(20).keys()].map((i) => (
            <option key={i} value={(i + 1).toString()}>
              {i + 1}
            </option>
          ))}
        </SelectField>
      </Flex>

      <Button
        id="raised-btn"
        backgroundColor={"#fd7e14"}
        variation="primary"
        gap={"small"}
        type="submit"
        color={"white"}
        alignSelf={"center"}
        isLoading={loading}
        loadingText={"Generating..."}
        data-attr="generate-new-quiz"
        data-ph-capture-attribute-type={"topic"}
        data-ph-capture-attribute-prompt={formInput.topic}
        data-ph-capture-attribute-grade-level={formInput.gradeLevel}
        data-ph-capture-attribute-language={formInput.lang}
        data-ph-capture-attribute-number-of-questions={
          formInput.numberOfQuestions
        }
      >
        <SparkleIcon />
        Generate
      </Button>
    </Flex>
  );
}
