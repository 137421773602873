import { Button, Card, Flex, Heading, View } from "@aws-amplify/ui-react";
import * as React from "react";
import PlayIcon from "../../../../components/icons/PlayIcon";
import { useMemo } from "react";
import { useGameContext } from "./GameContextProvider";
import { useTranslationContext } from "../../../../context/TranslationContextProvider";
import { useScreenReaderContext } from "../../../accessibility/context";

export interface IGameStartMenuProps {}

export function GameStartMenu(props: IGameStartMenuProps) {
  const {} = props;

  const { gameData, quiz, startGame } = useGameContext();

  const questionIndex = useMemo(
    () => gameData.questionIndex,
    [gameData.questionIndex]
  );

  const { ScreenReadButton, elementRef, stop } = useScreenReaderContext();

  const { start, continue_ } = useTranslationContext().translations;

  return (
    <Flex
      ref={elementRef}
      justifyContent={"center"}
      alignItems={"center"}
      direction={"column"}
      gap={"xl"}
      position={"relative"}
    >
      <View position={"absolute"} top={"small"} left={"small"}>
        <ScreenReadButton />
      </View>
      <Card
        width={"80%"}
        minHeight={{ base: "50px", medium: "100px" }}
        maxWidth={"800px"}
        id="raised-item"
        borderRadius={"small"}
      >
        <Flex direction={"column"}>
          <Heading
            id="displayText"
            textAlign={"center"}
            fontWeight={"bold"}
            fontSize={{ base: "large", medium: "xxl" }}
          >
            {quiz?.title}
          </Heading>
        </Flex>
      </Card>

      {startGame && (
        <Button
          id={"raised-btn"}
          backgroundColor={"teal.60"}
          variation="primary"
          size="large"
          gap={"small"}
          onClick={() => {
            startGame();
            stop();
          }}
        >
          <PlayIcon />
          {questionIndex === 0 ? start : continue_}
        </Button>
      )}
    </Flex>
  );
}
