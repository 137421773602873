import { User } from "../../types/User";
import RestAPI from "../RestAPI";

export default async function listUsersWithReferralCode(referralCode: string) {
  try {
    const users: User[] = await RestAPI.get(
      `/users/referralCode/${referralCode}`
    ).then((res) =>
      res.json()) as any as User[];
    return users;
  } catch (err) {
    console.error(
      `Failed to get users with referralCode ${referralCode}:`,
      err
    );
    throw err;
  }
}
