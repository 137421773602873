import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  Flex,
  Grid,
  Heading,
  ScrollView,
  View,
} from "@aws-amplify/ui-react";

import { useNavigate } from "react-router-dom";
import { PageDescription, PageIndex, PageTitle } from "../../../../util/SEO";

import { AnswerResult, GameData, GameState } from "../../Types/GameTypes";
import useUpdateEffect from "../../../../hooks/useUpdateEffect";
import { Quiz } from "../../../../API";

import { GameStartMenu } from "./GameStartMenu";
import { GamePlayMenu } from "./GamePlayMenu";

// AUDIO
import positiveSound from "../../../../assets/audio/Postive.wav";
import negativeSound from "../../../../assets/audio/Negative.wav";
import { GameFooter } from "./GameFooter";
import { Player } from "../../Types/GameTypes_dep";
import GameCompleteView from "./GameCompleteView_Multi";
import { GameEventProps } from "../../Types/GameEventProps";
import { PlayerAvatar } from "../v4/PlayerAvatar";
import GibblyLoader from "../../../../components/GibblyLoader";
import RaisedCard from "../../../../components/RaisedCard";
import useAudio from "../../../../hooks/useAudio";
import { useTranslationContext } from "../../../../context/TranslationContextProvider";
import { useVolumeContext } from "../../../../context";
import { useScreenReaderContext } from "../../../accessibility";

export type Feedback = {
  solution: string;
  acceptedAnswers: number[] | string[];
  isCorrect: boolean;
};

interface IGameViewProps extends GameEventProps {
  quiz: Quiz | undefined;
  onChange?: (gameData: GameData) => void | Promise<void>;
  gameData: GameData;
  // setGameData: React.Dispatch<React.SetStateAction<GameData>>;
  // lobbyPlayers?: LobbyPlayers;
  isMyTurn?: boolean;
  turnIndex?: number;
  completeGameButtonText?: string;
  players?: Player[];
  teamNumber?: number;
}

export default function GameView(props: IGameViewProps) {
  const {
    quiz,
    onStart,
    onChange,
    onSubmitAnswer,
    gameData,
    // setGameData,
    onComplete,
    // lobbyPlayers,
    isMyTurn,
    turnIndex,
    onPlayAgain,
    onShare,
    onExit,
    completeGameButtonText,
    players,
    teamNumber,
  } = props;

  PageTitle("Play 🎮 | Gibbly");
  PageDescription("Test your knowledge with this quiz!");
  PageIndex("");
  useUpdateEffect(() => {
    PageDescription(`Test your knowledge with this quiz! ${quiz?.title}.`);
  }, [quiz?.title]);

  const [music, setMusic] = useState<string>(
    "/audio/music/Game - ArcadeGameBGM_2_Ingame.wav"
  );
  const musicPlayerRef = useRef<HTMLAudioElement>(null);
  const gongSoundPlayer = useRef<HTMLAudioElement>(null);
  const positiveSoundPlayer = useRef<HTMLAudioElement>(null);
  const negativeSoundPlayer = useRef<HTMLAudioElement>(null);

  const { translations } = useTranslationContext();

  const { volume, multiplier } = useVolumeContext();

  const { elementRef } = useScreenReaderContext();

  const { replay: playPositiveSound } = useAudio({
    src: positiveSound,
    volume,
    volumeMultiplier: multiplier,
  });
  const { replay: playNegativeSound } = useAudio({
    src: negativeSound,
    volume,
    volumeMultiplier: multiplier,
  });

  useUpdateEffect(() => {
    if (musicPlayerRef.current) {
      musicPlayerRef.current.play();
    }
  }, [music]);

  useEffect(() => {
    if (musicPlayerRef.current) {
      musicPlayerRef.current.volume = volume;
    }
    if (gongSoundPlayer.current) {
      gongSoundPlayer.current.volume = volume;
    }
    if (positiveSoundPlayer.current) {
      positiveSoundPlayer.current.volume = volume;
    }
    if (negativeSoundPlayer.current) {
      negativeSoundPlayer.current.volume = volume;
    }
  }, [volume]);

  const [isStarting, setIsStarting] = useState(false);

  const navigate = useNavigate();

  const questions = useMemo(
    () => quiz?.Questions?.items.map((quizQuestion) => quizQuestion?.question),
    [quiz?.Questions?.items]
  );

  useUpdateEffect(() => {
    if (questions)
      if (
        gameData.questionIndex >= questions?.length &&
        gameData.gameState === GameState.PLAYING
      ) {
        const newGameData = { ...gameData, gameState: GameState.COMPLETE };
        onChange?.(newGameData);

        setMusic("/audio/music/Podium - Happiness (Loopable).wav");
        onComplete?.();
      }
  }, [questions?.length, gameData]);

  useEffect(() => {
    if (gameData.gameState === GameState.PLAYING) {
      if (music === "/audio/music/Game - ArcadeGameBGM_2_Ingame.wav") return;
      setMusic("/audio/music/Game - ArcadeGameBGM_2_Ingame.wav");
    }
    if (gameData.gameState === GameState.COMPLETE) {
      if (music === "/audio/music/Podium - Happiness (Loopable).wav") return;
      setMusic("/audio/music/Podium - Happiness (Loopable).wav");
    }
  }, [gameData.gameState, music]);

  const _onSubmitAnswer = useCallback((answerResults: AnswerResult[]) => {
    if (answerResults[answerResults.length - 1].isCorrect) {
      playPositiveSound();
    } else {
      playNegativeSound();
    }
    // setGameData((prev) => {
    //   const newGameData = { ...prev, answerResults };
    //   onChange?.(newGameData);
    //   return newGameData;
    // });

    onSubmitAnswer?.(answerResults);
  }, []);

  if (!questions || questions?.length === 0)
    return (
      <Flex
        direction={"column"}
        textAlign={"center"}
        justifyContent={"center"}
        padding={"xxl"}
        height={"100dvh"}
        minHeight={"100vh"}
      >
        <Heading fontWeight={"bold"} fontSize={{ base: "large", medium: "xl" }}>
          This quiz has no questions... 🤔
        </Heading>
        <Flex justifyContent={"center"}>
          <Button
            id={"raised-btn"}
            variation="primary"
            size="large"
            gap={"small"}
            onClick={() => navigate("/")}
          >
            Go Home
          </Button>
        </Flex>
      </Flex>
    );

  return (
    <ScrollView height={"100dvh"} minHeight={"100vh"}>
      <Grid
        height={"100dvh"}
        minHeight={"100vh"}
        paddingTop={"small"}
        templateRows={"auto 50px"}
        gap={"medium"}
      >
        {/* <audio src={music} loop ref={musicPlayerRef}></audio> */}
        {/* <audio src={positiveSound} ref={positiveSoundPlayer}></audio> */}
        {/* <audio src={negativeSound} ref={negativeSoundPlayer}></audio> */}

        {gameData.gameState === GameState.WAITING && (
          <GameStartMenu
            quiz={quiz}
            gameData={gameData}
            startGame={onStart ? startGame : undefined}
            isStarting={isStarting}
          />
        )}
        {gameData.gameState === GameState.LOADING && (
          <Flex
            justifyContent={"center"}
            direction={"column"}
            minHeight={"100vh"}
          >
            <GibblyLoader />
          </Flex>
        )}
        {gameData.gameState === GameState.PLAYING && (
          <Grid ref={elementRef} templateRows={"auto 1fr"} gap={"small"}>
            <Grid
              templateColumns={"1fr 1fr 1fr"}
              justifyContent={"center"}
              alignItems={"center"}
              paddingLeft={"small"}
              paddingRight={"small"}
            >
              <View>
                {isMyTurn ? (
                  <RaisedCard
                    className="bouncy"
                    fontWeight={"black"}
                    textAlign={"center"}
                  >
                    {translations.its_your_turn}
                  </RaisedCard>
                ) : (
                  <></>
                )}
              </View>
              <Flex
                justifyContent={"center"}
                alignItems={"center"}
                gap={"small"}
              >
                {players &&
                  players.map((player, index) => (
                    <PlayerAvatar
                      key={player.userId}
                      player={player}
                      size={
                        turnIndex === index
                          ? { base: "35px", medium: "65px" }
                          : { base: "20px", medium: "40px" }
                      }
                      opacity={turnIndex === index ? 1 : 0.5}
                      showName={false}
                    />
                  ))}
              </Flex>
              <View>
                {teamNumber && (
                  <RaisedCard
                    fontWeight={"bold"}
                    borderRadius={"50px"}
                    paddingLeft={"xl"}
                    paddingRight={"xl"}
                  >
                    {translations.team} {teamNumber}
                  </RaisedCard>
                )}
              </View>
            </Grid>
            <GamePlayMenu
              gameData={gameData}
              onChange={onChange}
              // setGameData={setGameData}
              questions={questions}
              onSubmitAnswer={_onSubmitAnswer}
              // lobby={lobbyPlayers}
              isMyTurn={isMyTurn}
              turnIndex={turnIndex}
            />
          </Grid>
        )}
        {gameData.gameState === GameState.COMPLETE && (
          <GameCompleteView
            gameData={gameData}
            onPlayAgain={onPlayAgain}
            onShare={onShare}
            completeGameButtonText={completeGameButtonText}
          />
        )}

        <GameFooter
          music={music}
          questionIndex={gameData.questionIndex}
          questions={questions}
          onExit={onExit}
        />
      </Grid>
    </ScrollView>
  );

  async function startGame() {
    setIsStarting(true);

    const newGameData = { ...gameData, gameState: GameState.PLAYING };
    onChange?.(newGameData);
    // let newGameData = gameData;
    // setGameData((prev) => {
    //   newGameData = { ...prev, gameState: GameState.PLAYING };
    //   onChange?.(newGameData);
    //   return newGameData;
    // });
    await onStart?.(newGameData);
    setIsStarting(false);
  }
}
