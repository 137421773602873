import { GraphQLQuery, generateClient } from "aws-amplify/api";
import { GetQuestionQuery, Question } from "../../../API";

const getQuestion_query = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      image {
        key
        identityId
        alt
      }
      audio {
        key
        identityId
      }
      readAloudText
      text
      type
      solution
      answers
      correctIndices
      createdAt
      updatedAt
      owner
    }
  }
`;
export async function getQuestion(id: string) {
  const client = generateClient();

  const question = (
    await client.graphql<GraphQLQuery<GetQuestionQuery>>({
      query: getQuestion_query,
      variables: { id },
    })
  )?.data?.getQuestion as Question;
  return question;
}
