import { useMemo } from "react";
import { Lobby } from "../Types/LobbyTypes";
import { getSortedTeams } from "../services/getSortedTeams";
import { MarkedTeam, NumberedTeam, RankedTeam, Team } from "../Types/TeamTypes";
import {
  Updater,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { getTeam } from "../services/getTeam";

export function useLobbyTeams(lobby: Lobby | undefined) {
  const { pin } = useParams();

  const queryClient = useQueryClient();

  const { data: teamIds } = useQuery<string[]>(
    ["team", "list", pin],
    async () =>
      (await getSortedTeams((lobby as Lobby).id)).map((team) => team.id),
    { enabled: lobby !== undefined, suspense: true }
  );

  const teamQueries = useQueries<Team[]>({
    queries: (teamIds ?? []).map((teamId) => ({
      queryKey: ["team", pin, teamId],
      queryFn: async () => {
        const team = await getTeam((lobby as Lobby).id, teamId);

        return team;
      },
    })),
  });

  const teams = useMemo(() => {
    return teamQueries
      .map((query) => query.data as Team | undefined)
      .filter((team) => team !== undefined) as Team[];
  }, [teamQueries]);

  function setTeamIdsList(
    updater: Updater<string[] | undefined, string[] | undefined>
  ) {
    queryClient.setQueryData<string[] | undefined>(
      ["team", "list", pin],
      updater
    );
  }

  const numberedTeams = useMemo(() => {
    if (teams) {
      const numberedTeams = teams.map((team, index) => {
        const numberedTeam: NumberedTeam = {
          ...team,
          number: index + 1,
        };
        return numberedTeam;
      });
      return numberedTeams;
    }
    return [];
  }, [teams]);

  const markedTeams = useMemo(() => {
    // determine the mark for each team by counting the total gameData.answerResults with isCorrect === true
    const markedTeams = numberedTeams?.map((team) => {
      const mark = team.gameData.answerResults.filter(
        (answerResult) => answerResult.isCorrect
      ).length;
      const markedTeam: MarkedTeam = {
        ...team,
        mark,
      };
      return markedTeam;
    });
    return markedTeams;
  }, [numberedTeams]);

  const rankedTeams = useMemo(() => {
    const sortedTeams = markedTeams?.sort((a, b) => a.mark - b.mark);
    // .map((team, index, markedTeams) => {
    //   const rankedTeam: RankedTeam = {
    //     ...team,
    //     rank: index + 1,
    //   };
    //   return rankedTeam;
    // });
    // const teamsGroupedByMark = Object.groupBy(sortedTeams, (team) => team.mark);
    const teamsGroupedByMark = sortedTeams.reduce(
      (
        acc: {
          [key: number]: MarkedTeam[];
        },
        team
      ) => {
        const mark = team.mark;
        if (acc[mark] === undefined) {
          acc[mark] = [];
        }
        acc[mark].push(team);
        return acc;
      },
      {}
    );



    let rank = 1;
    const rankedTeams: RankedTeam[] = [];
    Object.values(teamsGroupedByMark)
      .reverse()
      .forEach((teams) => {
        if (teams === undefined) return;
        teams.forEach((team) => {
          const rankedTeam: RankedTeam = {
            ...team,
            rank,
          };
          rankedTeams.push(rankedTeam);
        });
        rank += teams.length;
      });

    return rankedTeams;
  }, [markedTeams]);

  return {
    teams,
    numberedTeams,
    rankedTeams,
    setTeams: setTeamIdsList,
  };
}
