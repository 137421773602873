import { Flex, Heading } from "@aws-amplify/ui-react";
import * as React from "react";
import { FaCrown } from "react-icons/fa";

export default function Purchase() {
  return (
    <Flex
      width={"100%"}
      height={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Heading>
        Enjoy Gibbly Premium!{" "}
        <FaCrown color="gold" style={{ marginLeft: "5px" }} />
      </Heading>
    </Flex>
  );
}
