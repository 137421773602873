import {
  Button,
  Collection,
  Flex,
  ScrollView,
  TextField,
  Image,
  Link,
  Text,
  View,
} from "@aws-amplify/ui-react";
import { useInfiniteQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { uploadProtectedImageFromURL } from "../utils/uploadProtectedImagFromURL";
import { S3ObjectProtected } from "../../../API";
import { ImageDimensions } from "./ImageGallery";
import RestAPI from "../../../util/RestAPI";

export default function PexelsGallery({
  imageSearchInput,
  setImageSearchInput,
  setInputSubmitted,
  inputSubmitted,
  onUpload,
}: {
  imageSearchInput: string;
  setImageSearchInput: React.Dispatch<React.SetStateAction<string>>;
  setInputSubmitted: React.Dispatch<React.SetStateAction<string>>;
  inputSubmitted: string;
  onUpload: (
    image: S3ObjectProtected,
    url: string,
    dimensions: ImageDimensions
  ) => void | Promise<void>;
}) {
  const [selectedIndex, setSelectedIndex] = React.useState<number | undefined>(
    undefined
  );

  const {
    data: pixabayPaginatedData,
    refetch: refetchPixabayImages,
    fetchNextPage: fetchNextPixabayImagesPage,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery<any>({
    queryKey: ["pexels images", { search: inputSubmitted }],
    enabled: false,
    queryFn: async ({ pageParam = 1 }) => {
      const results = (await RestAPI.get(`/images/pexels`, {
        queryParams: {
          search: inputSubmitted,
          page: pageParam,
        },
      }).then((res) => res.json())) as any as {
        imageData: { photos: any[]; next_page: string };
      };

      const photos = results.imageData.photos;
      return {
        images: photos,
        nextPage: results.imageData.next_page ? pageParam + 1 : null,
      };
    },
    getNextPageParam: (prevData) => prevData.nextPage,
    // onSuccess: (data) => {

    // },
  });

  useEffect(() => {
    refetchPixabayImages();
  }, [inputSubmitted]);

  return (
    <>
      <TextField
        label={""}
        padding={"small"}
        value={imageSearchInput}
        onChange={(e) => {
          setImageSearchInput(e.target.value);
        }}
        innerEndComponent={
          <Button
            isLoading={isFetching}
            onClick={() => {
              setInputSubmitted(imageSearchInput);
            }}
          >
            Search
          </Button>
        }
        onKeyDown={(e) => {
          if (e.key === "Enter" && !isFetching)
            setInputSubmitted(imageSearchInput);
        }}
      ></TextField>
      {pixabayPaginatedData !== undefined && (
        <ScrollView height={"400px"}>
          <Flex direction="column">
            <Link
              href="https://www.pexels.com/"
              isExternal={true}
              paddingLeft={"medium"}
              fontSize={"xs"}
            >
              Royalty free stock images provided by Pexels
            </Link>
            <Collection
              items={pixabayPaginatedData.pages.flatMap((data) => data.images)}
              type={"grid"}
              padding={"medium"}
              paddingTop={"0px"}
              templateColumns={{ base: "1fr 1fr", small: "1fr 1fr 1fr" }}
              gap={"xs"}
              justifyContent={"center"}
              alignItems={"center"}
              alignContent={"center"}
              // justifyContent={"space-around"}
              // alignContent={'center'}
            >
              {(image, index) => {
                return (
                  <Flex
                    key={index}
                    direction={"column"}
                    gap={0}
                    paddingTop={"0px"}
                  >
                    <View position={"relative"} height={"100px"} width={"100%"}>
                      <Image
                        id="elevate"
                        alt={image.alt}
                        src={image.src.medium}
                        key={index}
                        objectFit={"cover"}
                        objectPosition="50% 50%"
                        height={"100%"}
                        width={"100%"}
                        opacity={selectedIndex === index ? 0.5 : 1}
                        loading={"lazy"}
                        borderRadius={"small"}
                        style={{
                          cursor: selectedIndex ? "not-allowed" : "pointer",
                        }}
                        onClick={async () => {
                          if (selectedIndex !== undefined) return;
                          setSelectedIndex(index);
                          console.log("uploading image: ", {
                            image: image.src.original,
                          });
                          await uploadProtectedImageFromURL(
                            image.src.original,
                            async (s3Image, url) => {
                              console.log("URL: ", url);

                              await onUpload(
                                {
                                  ...s3Image,
                                  alt: image.alt,
                                } as S3ObjectProtected,
                                url,
                                {
                                  width: image.width,
                                  height: image.height,
                                }
                              );
                            }
                          );
                          setSelectedIndex(undefined);
                        }}
                      />
                    </View>
                    <Link href={image.pageURL} isExternal>
                      <Text fontSize={"xx-small"} fontWeight={"bold"}>
                        Image by {image.photographer}
                      </Text>
                    </Link>
                  </Flex>
                );
              }}
            </Collection>
            {hasNextPage && (
              <Flex justifyContent={"center"}>
                <Button
                  isLoading={isFetchingNextPage}
                  onClick={() => fetchNextPixabayImagesPage()}
                >
                  Show More
                </Button>
              </Flex>
            )}
          </Flex>
        </ScrollView>
      )}
    </>
  );
}
