import { Icon } from '@aws-amplify/ui-react'
import React from 'react'

export default function SoundOnHalfFullIcon() {
    return (
        <Icon ariaLabel="Sound on, half full" fill="currentcolor"
      pathData="M16.492 3.855c.244.335.315.68.315 1.071-.01 6.213-.015 12.425.004 18.637.002.636-.674 1.345-1.358 1.357-.583.009-1.014-.19-1.413-.592-.854-.86-1.728-1.698-2.603-2.535-.775-.741-1.56-1.472-2.352-2.194-.101-.091-.277-.139-.418-.14-1.657-.01-3.315-.006-4.972-.006-.749-.001-1.411-.653-1.411-1.393 0-2.48.018-4.96-.013-7.44-.008-.605.706-1.423 1.409-1.415 1.51.017 3.02.012 4.53-.004a.804.804 0 0 0 .5-.205c.803-.752 1.585-1.527 2.38-2.289 1.031-.988 2.077-1.96 3.098-2.958.666-.653 1.681-.535 2.304.106z M20.794 12.504c.897.739 1.015 2.191.513 3.005-.473.767-1.138 1.149-2.057 1.149v-4.709c.582-.024 1.071.202 1.544.555z">
      
         </Icon>
  )
}