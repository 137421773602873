import React, { useMemo } from "react";
import { Flex, Text } from "@aws-amplify/ui-react";
import { NavLink } from "react-router-dom";
import { NavLinkProps } from "react-bootstrap";

// import MailIcon from '../components/icons/MailIcon';

export default function SidebarNavLink({
  navIcon,
  navTitle,
  to,
  ...navLinkProps
}: { navIcon: React.ReactNode; navTitle: string; to: string } & NavLinkProps) {
  const selected = useMemo(
    () => window.location.pathname.includes(to),
    [to, window.location.pathname]
  );

  return (
    <NavLink
      to={to}
      className={"navItem " + (selected ? "selected" : "")}
      style={{ borderRadius: "var(--amplify-radii-xxl)" }}
      {...navLinkProps}
    >
      <Flex
        direction={"column"}
        textAlign={"center"}
        gap={"0"}
        width={"68px"}
        height={{ base: "30px", small: "60px" }}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {navIcon}

        <Text
          className="navItemText"
          fontSize={{ base: "xxxs", small: "0.65rem" }}
          fontWeight={"semibold"}
          color={selected ? "orange.60" : "neutral.80"}
        >
          {navTitle}
        </Text>
      </Flex>
    </NavLink>
  );
}
