import { DynamoDBItem } from "../../types/DynamoDBTypes";
import RestAPI from "../../util/RestAPI";

export async function updateDatabaseItem<T extends DynamoDBItem, K>(
  endpoint: string,
  keys: Pick<T, Extract<keyof T, keyof K>>,
  updateObject: Partial<T>
): Promise<T> {
  let UpdateExpression = "SET ";
  let ExpressionAttributeValues: any = {};

  const updateKeys = Object.keys(updateObject);
  updateKeys.forEach((key) => {
    const placeholder = `:${key}`;
    UpdateExpression += `${key} = ${placeholder}, `;

    ExpressionAttributeValues[placeholder] = (updateObject as any)[key];
  });

  ExpressionAttributeValues[":updatedAt"] = new Date(Date.now()).toISOString();
  UpdateExpression += "updatedAt = :updatedAt";

  const input = {
    Key: keys,
    ReturnValues: "ALL_NEW",
    UpdateExpression,
    ExpressionAttributeValues,
  };

  try {
    const response: any = await RestAPI.put(endpoint, {
      body: input,
    })
    return response.data.Attributes as T;
  } catch (err) {

    throw err;
  }
}
