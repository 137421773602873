import { Flex, Link, Text, TextField, Heading } from "@aws-amplify/ui-react";
import React from "react";
import { RaisedButton } from "../../../components";
import {
  resetPassword,
  ResetPasswordInput,
  ResetPasswordOutput,
} from "aws-amplify/auth";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

type Props = {
  username: string;
  onSetUsername: (username: string) => void;
  onReset: (output: ResetPasswordOutput) => void;
};

export function ResetPasswordForm({ username, onSetUsername, onReset }: Props) {
  const navigate = useNavigate();

  const {
    isError,
    error,
    isLoading,
    mutateAsync: resetPassword_mutateAsync,
  } = useMutation(
    ["ResetPassword"],
    async (args: ResetPasswordInput) => {
      return await resetPassword(args);
    },
    {
      onSuccess: (output) => {
        onReset(output);

        // switch (nextStep.resetPasswordStep) {
        //   case "":
        //     navigate("/auth/confirm/" + username);
        //     break;
        //   default:
        //     break;
        // }
      },
    }
  );

  return (
    <Flex direction={"column"} gap={"large"} paddingTop={"small"}>
      <Heading level={5} textAlign={"center"}>
        Reset Password
      </Heading>
      <Flex direction={"column"} gap={"xs"}>
        <Flex
          direction={"column"}
          as="form"
          onSubmit={async (e) => {
            e.preventDefault();
            await resetPassword_mutateAsync({ username });
          }}
        >
          <TextField
            isRequired
            label="Enter your email"
            placeholder="Enter your email"
            type="email"
            value={username}
            onChange={(e) => {
              onSetUsername(e.target.value);
            }}
          ></TextField>

          <RaisedButton
            type="submit"
            isLoading={isLoading}
            loadingText="Sending code..."
          >
            Send code
          </RaisedButton>
          {isError ? (
            <Text variation="error">{(error as Error).message}</Text>
          ) : null}
          <Text fontSize={"small"} textAlign={"center"}>
            <Link
              onClick={() => {
                navigate("/auth/login");
              }}
            >
            <u>  Back to Log In</u>
            </Link>
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
