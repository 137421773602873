/**
 * Convert HEX color code to RGB color code
 * @param hexCode HEX color code
 * @param magnitude Magnitude of the color
 * @returns RGB color code
 */
export function colorHEXtoRGB(hexCode: string, magnitude: number = 1) {
  try {
    const hex = hexCode.replace("#", "");

    const isValid = validateHex(hex);
    if (!isValid) {
      return { red: magnitude, green: magnitude, blue: magnitude };
    }

    const red = (parseInt(hex.substring(0, 2), 16) / 255) * magnitude;
    const green = (parseInt(hex.substring(2, 4), 16) / 255) * magnitude;
    const blue = (parseInt(hex.substring(4, 6), 16) / 255) * magnitude;

    return { red, green, blue };
  } catch (e) {

    return { red: magnitude, green: magnitude, blue: magnitude };
  }
}

function validateHex(hex: string) {
  return /^#?[0-9A-F]{6}$/i.test(hex);
}
