import React from "react";
import useUnloadPrevention from "../../../../hooks/useUnloadPrevention";

import { Flex, Grid, Text } from "@aws-amplify/ui-react";
import { TeamCardLeaderboard } from "./TeamCardLeaderboard";

import useLobbyContext from "../../hooks/useLobbyContext";
import { useTranslationContext } from "../../../../context/TranslationContextProvider";


type LeaderboardViewProps = {};

export default function LeaderboardView({}: LeaderboardViewProps) {
  useUnloadPrevention();

  const { isHost, players, rankedTeams } = useLobbyContext();

  const {translations} = useTranslationContext()


  return (
    <Grid
      templateRows={"auto 1fr auto"}
      width={"100%"}
      height={"100%"}
      paddingTop={"large"}
      paddingBottom={"xxl"}
      gap={"large"}
    >
      <Text
        color="white"
        fontSize={{ base: "large", medium: "xxl" }}
        fontWeight={"black"}
        textAlign={"center"}
      >
        {translations.leaderboard}
      </Text>
      <Flex
        direction={"column"}
        width={"100%"}
        gap="large"
        alignItems={"center"}
      >
        {rankedTeams?.map((team) => (
          <TeamCardLeaderboard
            key={team.id}
            team={team}
            players={players.filter((player) => player.groupId === team.id)}
            isHost={isHost}
          />
        ))}
      </Flex>
    </Grid>
  );
}
