import { updateDatabaseItem } from "../../../services/database/updateDatabaseItem";
import { getIdentityId } from "../../game/util";
import { Lesson, LessonKey } from "../types/lessonTypes";


export async function updateLesson(id: string, updateObject: Partial<Lesson>) {

    const userId = await getIdentityId()

    const lesson = await updateDatabaseItem<Lesson, LessonKey>(
        "/lessons",
        { userId, id },
        { ...updateObject }
    );
    return lesson;
}
