import Quill from "quill";
import Delta, { Op } from "quill-delta";
import { toast } from "react-toastify";

export function convertOpsToHTML(ops: Op[]) {
  const newDiv = document.createElement("div");

  // Set CSS styles to position the div off-screen
  newDiv.style.position = "absolute";
  newDiv.style.left = "-9999px";

  // Append the newly created element to the DOM
  document.body.appendChild(newDiv);

  // Initialize a Quill editor with the dynamically created div
  const quill = new Quill(newDiv, {
    modules: {
      toolbar: false,
    },
    theme: "snow",
  });

  try {
    const delta = new Delta(ops);
    quill.setContents(delta);
    const html = quill.root.innerHTML;

    // Remove the Quill editor container from the DOM
    document.body.removeChild(newDiv);

    return html;
  } catch (err) {
    toast.error("Something went wrong converting Ops to HTML.");
    throw new Error("Something went wrong.");
  }
}
