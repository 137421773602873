import React from "react";
import { Flex, Grid, Link, Text } from "@aws-amplify/ui-react";
import { PageDescription, PageIndex, PageTitle } from "../../../util/SEO";
import TopNavbar from "../../../layouts/TopNavbar";
import Footer from "../../../layouts/Footer";
import useEffectOnce from "../../../hooks/useEffectOnce";
import RaisedCard from "../../../components/RaisedCard";

export default function AuthLayout({
  children,
}: {
  children?: React.ReactNode;
}) {
  PageTitle("Login / Sign Up | Gibbly");
  PageDescription(
    "Login or Sign up for a Gibbly account! Generate quizzes, host games, and create a collaborative learning environment today."
  );
  PageIndex("");

  useEffectOnce(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        entry.target.classList.toggle("show", entry.isIntersecting);
        if (entry.isIntersecting) observer.unobserve(entry.target);
      });
    });

    const hiddenElements = document.querySelectorAll(".hidden");
    hiddenElements.forEach((el) => observer.observe(el));
  });

  return (
    <Grid minHeight={"100dvh"} gap={"0"} templateRows={"auto 1fr auto"}>
      <TopNavbar useAuthentication={false} />

      <Flex
        alignItems={"center"}
        justifyContent={"center"}
        width={"100%"}
        height={"100%"}
        direction={"column"}
        backgroundColor={"background.secondary"}
        padding={"large small"}
      >
        <RaisedCard maxWidth={"500px"} width={"100%"} padding={"large"}>
          {children}
        </RaisedCard>
        <Text
          fontSize={"small"}
          textAlign={"start"}
          maxWidth={"500px"}
          opacity={"60"}
        >
          By signing up and continuing to use the service, you accept our&nbsp;
          <Link
            href={"http://www.gibbly.co/terms-of-service"}
            isExternal={true}
          >
            <u>Terms of Service</u>{" "}
          </Link>
          , agree to receiving emails from us, and consent to our use of
          cookies. Please read our&nbsp;
          <Link href={"http://www.gibbly.co/privacy-policy"} isExternal={true}>
            <u>Privacy Policy</u>
          </Link>
          .
        </Text>
      </Flex>

      <Footer />
    </Grid>
    // </motion.view>
  );
}
