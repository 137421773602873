import * as React from 'react';

export interface IMediaMenuProps {
  
}

export default function MediaMenu(props: IMediaMenuProps) {

  const {} = props;

  return (
    <div>
      MediaMenu
    </div>
  );
}
