import { uploadData, UploadDataWithPathInput } from "aws-amplify/storage";

type UploadS3ItemInput = {
  data: File;
  fileName: string;
  level: "public" | "protected" | "private";
  options?: UploadDataWithPathInput["options"];
};

/***
 * Uploads a file to S3
 * @param {UploadS3ItemInput} input
 */
export async function uploadS3Item(input: UploadS3ItemInput) {
  const { fileName, level, ...restInput } = input;
  if (level === "protected" || level === "private") {
    const result = await uploadData({
      path: ({ identityId }) => `${level}/${identityId}/${fileName}`,
      ...restInput,
    }).result;
    return result;
  } else {
    const result = await uploadData({
      path: `${level}/${fileName}`,
      ...restInput,
    }).result;
    return result;
  }
}
