import React, { useEffect } from "react";
import { PageDescription, PageIndex, PageTitle } from "../util/SEO";
import Footer from "../layouts/Footer";
import TopNavbar from "../layouts/TopNavbar";
import { Heading, Text, View, Link } from "@aws-amplify/ui-react";
import { Container } from "react-bootstrap";
import ScrollArrow from "../layouts/ScrollArrow";
import useEffectOnce from "../hooks/useEffectOnce";

export default function PrivacyPolicy() {
  PageTitle("Privacy Policy | Gibbly");
  PageDescription(
    "Gibbly's Privacy Policy."
  );
  PageIndex("");

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0))
  }, [])


  useEffectOnce(() => {

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {

        entry.target.classList.toggle('show', entry.isIntersecting);
        if (entry.isIntersecting) observer.unobserve(entry.target)
      });
    });

    const hiddenElements = document.querySelectorAll('.hidden');
    hiddenElements.forEach((el) => observer.observe(el));

  });


  return (

    <>
      <ScrollArrow />
      <TopNavbar useAuthentication={false} />

      <Container>
        <View padding={{ base: "medium", medium: "xxl" }} paddingRight={'xxl'}>
          <Heading level={4} paddingBottom={'small'}> Gibbly Privacy Policy</Heading>
          <View padding="xxxs">
            <i><Text paddingBottom={'large'}>Last updated: June 30, 2023</Text></i>

            <Text paddingBottom={'small'}>Gibbly Inc operates Gibbly.co. Gibbly Inc (“Gibbly”) is concerned about
              the protection of privacy of all of our users. Gibbly wants you to be familiar
              with how we collect, use, and disclose information. This Privacy Policy describes
              our practices in connection with information that we collect through websites
              operated by us from which you are accessing this Privacy Policy (the “Website”)
              and through HTML-formatted email messages that we send that link to this
              Privacy Policy (collectively, the “Services”). By providing Personal
              Information to us, you agree to the terms and conditions of this Privacy Policy.</Text>

            <Text paddingBottom={'small'} >This Privacy Policy explains:</Text>
            <ul>
              <li style={{ 'paddingBottom': '.2em' }}>What information Gibbly collects from you and why we collect it.</li>
              <li style={{ 'paddingBottom': '.2em' }}>How we use and share that information.</li>
              <li style={{ 'paddingBottom': '.2em' }}>The choices you have, including how to access, update, and delete your information.</li>
              <li style={{ 'paddingBottom': '.2em' }}>This Policy applies to all services offered by Gibbly Inc
                (hereinafter referred to as “Gibbly”, “we”, “us", and “our”).</li>
              <li>This Policy applies to all Gibbly Users and Visitors. Gibbly Users
                are our registered account holders. Gibbly Visitors are parties invited to play a game or engage in assignments by a registered user, school, or business. The primary information collected from Visitors is a username of their choice.</li>

            </ul>

            <Text paddingBottom={'small'}>We have done our best to write this Policy in simple, clear terms.
              We encourage you to read it carefully, together with any other privacy notice we may
              provide on specific occasions when we are collecting or processing personal information
              about you so that you are aware of how and why we are using such information.</Text>

            <ul>
              <li style={{ 'paddingBottom': '.2em' }}>Purpose of processing: provide access to and use of Gibbly.co.</li>
              <li style={{ 'paddingBottom': '.2em' }}>Legal grounds: processing based on user consent, contract performance, and the legitimate interest of the company or third parties.</li>
              <li style={{ 'paddingBottom': '.2em' }}>Recipients: Gibbly third-party service providers which help provide the Service. Please see the Security Measures section of this Privacy Policy for more information.</li>
              <li style={{ 'paddingBottom': '.2em' }}>User rights: access, rectification, erasure, restriction, objection, and data portability (see the Gibbly Privacy Principles section of this Privacy Policy).</li>
              <li style={{ 'paddingBottom': '.2em' }}>Inquiries: for inquiries regarding this Privacy Policy, please contact Gibbly at support@gibbly.app or at the physical address listed at the end of this Policy.</li>
              <li>Additional information: to be found in Gibbly's <Link href={"http://www.gibbly.co/terms-of-service"}>
                <u>Terms of Service</u> </Link>and this Privacy Policy.</li>
            </ul>

            <Text paddingBottom={'small'}> Personal information is any information you provide to us that personally identifies you, like your name or
              email address, or any other information which we could reasonably link to your identity. We will only collect, use,
              and share your personal information in accordance with this Privacy Policy. This Policy applies when you use Gibbly
              through Gibbly.co. In addition, this Privacy Policy also covers Gibbly's treatment of any personal information about
              our users that our partners or other services might share with us. This Policy does not apply to websites or services
              or practices of companies that Gibbly does not own or control. These other services have their own privacy policies,
              and we encourage you to review them before providing them with personal information. At the end of this Privacy Policy,
              you will find a list of our third-party service providers and a link to their privacy policies, as well as an overview of
              how, why, and under which conditions they might process your personal information. Please take the time to get to know our
              privacy practices. If you have any questions, we are here to help. To learn more about how we protect your privacy, send
              us an email at support@gibbly.app.</Text>

            <b><Text>BY USING THE SERVICE, YOU ACKNOWLEDGE THAT YOU ACCEPT AND AGREE TO THIS PRIVACY POLICY.</Text></b>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Gibbly</Heading>

            <Text paddingBottom={'small'}>Gibbly provides a game based learning tool that can be played through most web browsers (https://www.gibbly.co/).
              With Gibbly, you can create collaborative games, assessments, and review assignments that your students love and that
              can save you hours of prep. Visitors or Users can host a multiplayer game where everyone plays together on their own devices. They can also play
              the game solo.</Text>
            <Text paddingBottom={'small'}>Gibbly collects player's gameplay responses. As further explained below, if the school or teacher elects to utilize
              Gibbly with students under a specific age, the school will be responsible for obtaining any necessary parental consent
              under the applicable laws. Game hosts will be provided with a unique Game PIN for each game. Users must consciously agree
              to this Policy by reading and accepting the Privacy Policy notice given prior to signing up.</Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Gibbly Privacy Principles</Heading>
            <Text paddingBottom={'small'}>In collecting and processing your personal information, we will comply with the data protection laws and regulations
              in force at the time. This requires that the personal information we hold about you must be:
            </Text>
            <ul>
              <li style={{ 'paddingBottom': '.2em' }}>Used lawfully, fairly, and in a transparent way.</li>
              <li style={{ 'paddingBottom': '.2em' }}>Collected only for valid purposes that we have clearly explained to you and not used in a way
                that is incompatible with those purposes.</li>
              <li style={{ 'paddingBottom': '.2em' }}>Relevant to the purposes we have told you about and limited only to those purposes.</li>
              <li style={{ 'paddingBottom': '.2em' }}>Accurate and kept up-to-date.</li>
              <li style={{ 'paddingBottom': '.2em' }}>Kept only as long as necessary for the purposes we have told you about.</li>
              <li>Kept securely.</li>
            </ul>
            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Collection of Personal Information</Heading>
            <Text paddingBottom={'small'}>Gibbly collects personal information from Users to provide the Service. The personal information of Users is
              collected and used for the following purposes:
            </Text>
            <ul>
              <li style={{ 'paddingBottom': '.2em' }}>To create the necessary accounts to use the Service.</li>
              <li style={{ 'paddingBottom': '.2em' }}>To assess the quality of the Service.</li>
              <li style={{ 'paddingBottom': '.2em' }}>To conduct product research and development.</li>
              <li style={{ 'paddingBottom': '.2em' }}>To secure and safeguard personal information.</li>
              <li style={{ 'paddingBottom': '.2em' }}>To access premium features, if applicable.</li>
              <li style={{ 'paddingBottom': '.2em' }}>To comply with applicable laws or respond to valid legal process, including from law enforcement or
                government agencies, to investigate or participate in civil discovery, litigation, or other adversarial
                legal proceedings, and to enforce or investigate potential violations of our&nbsp;
                <Link href={"http://www.gibbly.co/terms-of-service"}><u>Terms of Service</u> </Link>or policies.</li>
            </ul>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Rights Regarding Personal Information</Heading>
            <Text paddingBottom={'small'}>Your rights relating to your personal information include:</Text>
            <ul>
              <li style={{ 'paddingBottom': '.2em' }}>to be informed about how Gibbly uses your personal information;</li>
              <li style={{ 'paddingBottom': '.2em' }}>to request access to personal information held by Gibbly, and to have any incorrect, inaccurate, or
                incomplete personal information rectified;</li>
              <li style={{ 'paddingBottom': '.2em' }}>where appropriate, to restrict processing concerning you or to object to processing;</li>
              <li style={{ 'paddingBottom': '.2em' }}>to have personal information erased where there is no compelling reason for its continued processing; and</li>
              <li style={{ 'paddingBottom': '.2em' }}>where applicable, to portability of personal data, that is to say, to receive your personal information
                in a structured and commonly used format.</li>
            </ul>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Responsibilities of Users of Gibbly.co</Heading>
            <Text>We require that your personal information is accurate. Please let us know if the personal information you
              provided us for creating your account has changed. If we do not have the correct information, we cannot take
              responsibility for information-related errors. Additionally, if we determine that you are in violation of this
              Policy, you will be subject to disciplinary action that could eventually lead to the banning of your account.
            </Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Security of Your Personal Information</Heading>
            <Text paddingBottom={'small'}>Gibbly is committed to securing your personal information.</Text>
            <Text paddingBottom={'small'}>Some of the ways in which we protect your personal information include:</Text>
            <ul>
              <li style={{ 'paddingBottom': '.2em' }}>We encrypt your personal information when it is stored at rest.</li>
              <li style={{ 'paddingBottom': '.2em' }}>We protect your personal information with encryption during transmission over the public Internet.</li>
              <li style={{ 'paddingBottom': '.2em' }}>We use reasonable organizational and technical safeguards designed to help protect the privacy
                and security of your personal information.</li>
            </ul>


            <Text paddingBottom={'small'}>Some of the ways in which we encourage you to protect your personal information include:</Text>
            <ul>
              <li style={{ 'paddingBottom': '.2em' }}>We encourage you to create a username that does not reveal your identity.</li>
              <li style={{ 'paddingBottom': '.2em' }}>We encourage you to create (and keep) a strong password.</li>
              <li style={{ 'paddingBottom': '.2em' }}>We encourage you to be thoughtful about what you post and continue to learn about online safety.</li>
            </ul>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Transparency and Choice</Heading>
            <Text paddingBottom={'small'}>We try to be transparent about what information we collect so that you can make meaningful choices about how it is used.</Text>
            <Text paddingBottom={'small'}>For example, you can:</Text>
            <ul>
              <li style={{ 'paddingBottom': '.2em' }}>Access and manage your account information by using the account settings within Gibbly.</li>
              <li style={{ 'paddingBottom': '.2em' }}>Delete your account and information by emailing support@gibbly.app.</li>
            </ul>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Notice</Heading>
            <Text>When providing you with information on the processing of your personal information,
              such as its collection, transfer to other countries, types, or identity of third parties to which we disclose
              that information and the purposes for which we do so, we will make sure that such information is provided in clear
              and understandable language. Also, initial notice on our practices and policies will be provided when you are first
              asked to provide personal information to us, or as soon as practicable thereafter, and in any event before we use
              the information for a purpose other than that for which it was originally collected.</Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Change of Purpose</Heading>
            <Text>We will only use your personal information for the purposes for which we collected it, unless we reasonably
              consider that we need to use it for another reason which is compatible with the original purpose. If we need to
              use your personal information for an unrelated purpose, we will notify you and we will explain the legal basis
              which allows us to do so.</Text>


            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Contract Performance</Heading>
            <Text>When you create a Gibbly account, you provide a username and an email address. We require those data
              elements for you to enter into the&nbsp;
              <Link href={"http://www.gibbly.co/terms-of-service"}><u>Terms of Service</u> </Link>
              agreement with us, and we process those elements on
              the basis of performing that contract.</Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Consent</Heading>
            <Text>Please note that Gibbly provides its Service upon explicit consent given by you when signing up.
              Prior to signing up, we will direct you to our&nbsp;
              <Link href={"http://www.gibbly.co/terms-of-service"}><u>Terms of Service</u> </Link>and this Privacy Policy. When signing up,
              you will be declaring to have read such policies and consented to them. Remember, nonetheless, that you
              will be able to withdraw your consent at any time by deleting your account by contacting Gibbly to have
              your account deleted. EDUCATIONAL INSTITUTIONS AND EDUCATORS UTILIZING THE SERVICE ARE RESPONSIBLE FOR
              MONITORING THE RIGHTS AND INTERESTS OF THEIR STUDENTS AND MUST THEREFORE TAKE SPECIAL CARE WHEN REVIEWING
              THIS PRIVACY POLICY. EDUCATIONAL INSTITUTIONS AND EDUCATORS SHALL BE RESPONSIBLE FOR OBTAINING, WHERE
              APPLICABLE, PERTINENT CONSENT FROM PARENTS, LEGAL GUARDIANS, OR ELIGIBLE STUDENTS (THOSE WHO ARE 18 YEARS
              OF AGE OR ATTEND A POSTSECONDARY INSTITUTION) PRIOR TO UTILIZING THE SERVICE. GIBBLY SHALL NOT BE RESPONSIBLE
              FOR ANY NEGLIGENCE OF THE EDUCATIONAL INSTITUTION IN THE REVIEWING OF THIS PRIVACY POLICY OR THE OBTAINMENT,
              WHERE APPLICABLE, OF THE NECESSARY PARENTAL CONSENT.
            </Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Legitimate Interests</Heading>
            <Text>Generally, the remainder of the processing of personal information we perform is necessary for the
              purposes of our legitimate interests or those of third parties. For example, for legal compliance purposes
              or to maintain ongoing confidentiality, integrity, availability, and resilience of Gibbly's systems,
              website, and overall services, we must keep logs of Technical Information. As foreseen in the
              “Restrictions” and “Account Bans” sections of our&nbsp;
              <Link href={"http://www.gibbly.co/terms-of-service"}><u>Terms of Service</u>, </Link>
              the breach of certain rules in the use of the Service may lead to the suspension or indefinite ban of
              your account, depending on the severity of the offense. In the event of an indefinite account ban,
              Gibbly may keep part of your personal information, such as, but not limited to, IP address and email
              address, to prevent you from accessing or using the Service.</Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Notice of Changes of Policy</Heading>
            <Text>We may occasionally update this Privacy Policy. You can see when the last update was by looking
              at the “Last Updated” date at the top of this page. We will not reduce your rights under this
              Privacy Policy without your explicit consent. If we make any significant changes, we will provide
              prominent notice by posting a notice on the Service and/or notifying you by email (using the email
              address you provided) prior to and after changes taking effect, so you can review and make sure
              you're aware of them. We encourage you to review this Privacy Policy from time to time to stay
              informed about our collection, use, and disclosure of personal information through the Service.
              If you do not agree with any changes to the Privacy Policy, you may delete your account. By
              continuing to use the Service after the revised Privacy Policy has become effective, you acknowledge
              that you accept and agree to the current version of the Privacy Policy.</Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Protecting the Privacy Rights of Children</Heading>
            <Text paddingBottom={'small'}>Gibbly permits registered Users to invite Visitors to the website. Visitors are only required
              to provide a username to play a game or complete an assignment.</Text>

            <Text paddingBottom={'small'}>Gibbly does not permit children under the age of 13 (a “Child” or “Children”) to create an
              account without the consent and at the direction of a Parent or School official consenting in
              loco parentis. Children under 13 may create an account only with parental consent. When Gibbly
              is used by a School in an educational setting, we may rely on the School to provide the requisite
              consent for Gibbly to collect information from a School User under the age of 13, in lieu of
              parental consent as is described in the Consent Section.</Text>

            <Text paddingBottom={'small'}>Gibbly collects the minimal amount of information from Users necessary to create accounts
              on our Service. Beyond this information, Users and Visitors can submit responses.
              In addition to the information entered by the child, we automatically collect some
              information from any use of our Service as set forth in the "Information Collected Automatically"
              section. We use this information to provide the Service to the child, for security and safety purposes,
              or as required by law or to enforce our Terms. We will not require children to provide more personal
              information than is reasonably necessary in order to participate in the Service. If we discover
              that we have collected information from a child in a manner inconsistent with PIPEDA or any
              other applicable laws or regulations, we will take appropriate steps to delete the information.
              We do not disclose any personal information about children to third parties except to service
              providers necessary to provide the Service, as required by law, or to protect the security of
              the Service for other users. Information collected from students (including personal information
              and information collected automatically) is never used or disclosed for third-party advertising,
              including any kind of first- or third-party behaviorally targeted advertising, and children's
              personal information is never sold or rented to anyone, including marketers or advertisers.</Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Information Regarding Children</Heading>
            <Text>If a teacher chooses to display Gibbly in their classroom by projecting via a smartboard or
              interactive whiteboard, students physically present in that classroom may see other students'
              usernames, responses, comments, or total scores. EDUCATIONAL INSTITUTIONS AND TEACHERS SHALL
              MAKE A RESPONSIBLE USE OF THE SERVICE AND AVOID COMPROMISING CHILDREN'S PERSONAL INFORMATION
              AT ALL TIMES WHEN DISPLAYING GIBBLY IN THE CLASSROOM OR ON SOCIAL MEDIA ACCOUNTS OF TEACHERS
              OR SCHOOLS. GIBBLY SHALL NOT BE HELD LIABLE FOR THE INAPPROPRIATE USE OF THE SERVICE BY THE
              EDUCATIONAL INSTITUTION OR THE TEACHER.</Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Storage of Data</Heading>
            <Text paddingBottom={'small'}>We store the data of Visitors including usernames to allow Users to evaluate assignments
              or evaluate participation in games.</Text>
            <ul>
              <li style={{ 'paddingBottom': '.2em' }}>Minimal information: As mentioned before, Gibbly collects the minimal amount of
                information from Visitors necessary to utilize our Service: we ask Visitors who
                are invited to play the games or participate in assignments to only create a username.
                Students who join as Users with parental consent or who are above specified ages
                only provide email addresses and usernames. We only collect, use, share, and retain
                student personal information for purposes for which we were authorized by the educational
                institution/agency, teacher, or the student. Beyond this information, students can
                submit responses depending on the activities they are assigned, that will remain private
                between teacher and student. In addition to the information entered by the child, we
                automatically collect some information from any use of our Service as set forth in
                the "Information collected automatically" section.</li>
              <li>Deleting inactive accounts: Gibbly will delete inactive accounts and data
                in compliance with the data retention policy of the company.</li>
            </ul>

            <Text>After deletion of the account or data, Gibbly may retain copies and/or backups
              of the mentioned information for a maximum term of eighteen (18) months.
              Nevertheless, Gibbly shall not be responsible for the accidental loss or
              destruction of data on behalf of users. Gibbly will not be obliged to recover erased
              data stored in backups when erasure is attributable to users. EDUCATIONAL INSTITUTIONS
              UTILIZING THE SERVICE ARE RESPONSIBLE FOR COMPLYING WITH THE RETENTION OF STUDENT
              EDUCATION RECORDS FOR AS LONG AS LEGALLY APPLICABLE. TEACHER ACCOUNTS WILL BE
              PROVIDED WITH NECESSARY TOOLS TO MANAGE AND DELETE STUDENT INFORMATION. GIBBLY SHALL
              NOT BE RESPONSIBLE FOR ERASURE OF STUDENT PROGRESS DUE TO ACCOUNT DELETION AFTER AN
              EXTENDED PERIOD OF INACTIVITY OR BECAUSE OF THE VOLUNTARY ELECTION TO DELETE TEACHER
              ACCOUNTS. SCHOOL OFFICIALS MAY REQUEST DELETION OF STUDENT INFORMATION AND CONTENT
              AT ANY TIME BY CONTACTING GIBBLY AT SUPPORT@GIBBLY.APP.
            </Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Parental Choices</Heading>
            <Text>Any parents that want copies of their children's personal information that we
              may have stored can contact their children's school personnel to that end. If a
              parent has authorized an account, they also may contact Gibbly to retrieve their
              child's personal information. At any time, the school can also refuse to permit us to
              collect further personal information from its students and can request that we delete
              the personal information we have collected from them by contacting us at support@gibbly.app.
              Please keep in mind that deleting records may require us to terminate the account
              in question. Before we can share the information with the school,
              or delete it per your request, we will, by reasonable means, proceed to verify the
              identity of the requester.</Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Information Collected</Heading>
            <Text>Gibbly collects two types of information about you: (1) information that you
              voluntarily provide us by using the Gibbly Service (described below under
              "Information you provide to us") and (2) information collected automatically
              as a result of your use of the Service (described below under “Information collected automatically”).
              The types and amounts of information collected will vary depending on whether the user is a
              Visitor to the site invited to participate in a game. Only minimal information is collected
              for both Users and Visitors.</Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Information You Provide to Us</Heading>
            <Text paddingBottom={'small'}>There are currently two categories of users on our Service: Users and Visitors. </Text>
            <Text paddingBottom={'small'}> We collect and store the following types of information from each type of user:</Text>

            <ul>
              <li style={{ 'paddingBottom': '.2em' }}>Account Sign-up and Profile Information: To create a Gibby account, you may be asked
                to provide some basic information. If you create a Gibbly account as a User, you will
                be asked to enter your username, password, and email. Visitors who participate in games
                will be asked only for a username.</li>
              <li style={{ 'paddingBottom': '.2em' }}>Contact Information: When you choose to provide us with your personal information
                through the Service in some other manner (e.g., when you request a quote for upgrading
                to a "Gibbly Premium", when you submit a copyright claim or report any media on our
                platform, when you send us an email asking a question, or submit a support request).</li>
              <li>Billing Information: When subscribing to any of our "Gibbly Premium" options, you
                will be asked to provide necessary information for processing the payment (e.g.,
                first name, last name, billing address, credit/debit card number). As further explained
                in the Security Measures section of this Privacy Policy, payments are processed over
                Stripe through their third-party website service.</li>
            </ul>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Information Collected Automatically</Heading>
            <Text paddingBottom={'small'}>Like most web-based services, we (or our service providers) may automatically receive
              and log information on our server logs from your browser or your device when you use
              the Service. For example, this could include the frequency and duration of your
              visits to Gibbly. If you use Gibbly on different devices, we may link the information
              we collect from those different devices to help us provide a consistent Service across
              your different devices. If we combine any automatically-collected information with
              personal information, we will treat the combined information as personal information,
              and it will be protected as per this Privacy Policy. The technologies and information
              we automatically collect include:
            </Text>

            <ul>
              <li style={{ 'paddingBottom': '.2em' }}>Cookies and Other Similar Technologies: We (or our service providers) may use various
                technologies to collect and store information when you visit our Service, including
                clear GIFs (also known as “web beacons”), “tags”, “scripts”, and “cookies”. We also
                make use of persistent secure cookies: persistent cookie remains after you close your
                browser (although they can be removed) and may be used by your browser to identify you
                on subsequent visits to the Service. We may also use, collect, and store information
                locally on your device using mechanisms such as browser web storage (including HTML 5).
                Like many services, Gibbly uses these technologies to tailor the Service for you, and
                to help the Service work better for you.</li>
              <li style={{ 'paddingBottom': '.2em' }}>Device Information: We collect, through our third-party analytics services,
                device-specific information such as your operating system, hardware version, device settings,
                file and software names and types, battery and signal strength, and device identifiers.
                This helps us measure how the Service is performing and improve Gibbly for you on your
                particular device.</li>
              <li>Log Information: Like most online services, when you use our Service, we automatically
                collect and store certain information in our server logs. Examples include:</li>
              <ul>
                <li style={{ 'paddingBottom': '.2em' }}>Details of how you used our service, such as your activity on the Service,
                  and the frequency and duration of your visits to the Gibbly Website.</li>
                <li style={{ 'paddingBottom': '.2em' }}>IP Address.</li>
                <li>Device event information such as crashes, system activity, hardware settings,
                  browser type, browser language, the date and time of your request, and referral URL.</li>
              </ul>
            </ul>

            <Text paddingBottom={'small'}>This information helps us make decisions about how we can improve our Service.</Text>
            <ul>
              <li>Location Information: When you use our Service we may collect and process
                information about your geographic location, for example through GPS, Bluetooth,
                or Wi-Fi signals. We collect coarse (i.e., city-level) location data. We will
                not store or track your device location on an ongoing basis or without your
                permission. We do not share precise geolocation data with third parties other
                than our service providers as necessary to provide the Service.</li>
            </ul>


            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Automated Decision Making and Profiling</Heading>
            <Text>Automated Decision Making (ADM) refers to a decision which is taken solely
              on the basis of automated processing of your personal data. This means processing
              using, for example, software code or an algorithm, which does not require human
              intervention. Profiling means using automated processes without human intervention
              (such as computer programs) to analyze your personal information in order to
              evaluate your behavior or to predict things about you which are relevant in the
              context of using Gibbly, such as what kind of Gibbly games you used. As profiling
              uses automated processing, it is sometimes connected with automated decision-making.
              Not all profiling results in automated decision-making, but it can.</Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Use of Information by Gibbly</Heading>
            <Text paddingBottom={'small'}>Gibbly does not sell or rent any personal information to any third party for
              any purpose including for advertising or marketing purposes. We use the information
              we collect from you to provide you with the best Gibbly experience. More specifically,
              this information is used to:</Text>

            <ul>
              <li style={{ 'paddingBottom': '.2em' }}>Provide and improve the Service, for example by developing new products and features.</li>
              <li style={{ 'paddingBottom': '.2em' }}>Respond to your requests for information or customer support.</li>
              <li style={{ 'paddingBottom': '.2em' }}>Customize the Service for you and improve your experience with it.</li>
              <li style={{ 'paddingBottom': '.2em' }}>Send you information about new features and Gibbly products we believe you may be interested in.</li>
              <li style={{ 'paddingBottom': '.2em' }}> Most crucially, to protect our community by making sure the Service remains safe and secure.</li>
            </ul>

            <Text>We use automatically collected information (described in the "Information
              Collected Automatically" section above) to provide and support our Service,
              and for the additional uses described in this section of our Privacy Policy.
            </Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Storage of Data</Heading>
            <Text>We store your personal information for as long as it is necessary to provide
              products and Services to you and others, including those described above pursuant
              to our Data Retention Policy. Deletion will affect any ongoing paid subscriptions,
              which will be immediately cancelled. Note we may retain and use de-identified data
              (i.e., data which has been stripped of all information that can be used to identify
              a person) for purposes of research, improvement of our products and services, and/or
              the development of new products and services. We may also have to retain some
              information after your account is deleted to comply with legal obligations, to
              protect the safety and security of our community or our Service, or to prevent
              abuse of our Terms. In case we keep copies or backups of personal information,
              such copies or backups will be kept for a maximum term of eighteen (18) months
              after the deletion of your account.
            </Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Security Measures</Heading>
            <Text paddingBottom={'small'}>Gibbly does not sell or rent your or your students' personal information
              to any third party for any purpose - including for advertising or marketing purposes.
              We do not share personal information with any third parties except in the limited
              circumstances described in this Privacy Policy. If you are a user, you may choose to
              share information or content through the Service with other Gibbly users - for example,
              things like your account information or Gibbly games. Please keep in mind that information
              (including personal information or children's personal information) or content that you
              voluntarily disclose to others - including other Gibbly users you interact with through
              the Service can be viewed, copied, stored, and used by the people you share it with.
              We cannot control the actions of people with whom you choose to share information.
            </Text>

            <ul>
              <li style={{ 'paddingBottom': '.2em' }}>Service Providers: We do work with vendors, service providers, and other partners to help
                us provide the Service by performing tasks on our behalf. We may need to share or provide
                information (including personal information) to them to help them perform these business
                functions, for example, sending emails on our behalf, database management services, database
                hosting, providing customer support software, and security. Generally, these service providers
                do not have the right to use your personal information we share with them beyond what is necessary
                to assist us. Additionally, these service providers must adhere to confidentiality and security
                obligations in a way that is consistent with this Privacy Policy.</li>
              <li style={{ 'paddingBottom': '.2em' }}>Analytics Services: We use analytics services, including mobile analytics software, to help us
                understand and improve how the Service is being used. These services may collect, store, and use
                information to help us understand things like how often you use the Service, the events that occur
                within the application, usage, performance data, and from where the application was downloaded.</li>
              <li style={{ 'paddingBottom': '.2em' }}>Aggregated Information and Non-Identifying Information: We may share aggregated, non-personally
                identifiable information publicly, including with users, partners, or the press in order to, for
                example, demonstrate how Gibbly is used, spot industry trends, or to provide marketing materials
                for Gibbly. Any aggregated information shared this way will not contain any personal information.</li>
              <li style={{ 'paddingBottom': '.2em' }}>Legal Requirements: We may disclose personal information if we have a good faith belief that
                doing so is necessary to comply with the law, such as complying with a subpoena or other legal process.
                We may need to disclose personal information where, in good faith, we think it is necessary to protect
                the rights, property, or safety of Gibbly, our employees, our community, or others, or to prevent
                violations of our&nbsp;
                <Link href={"http://www.gibbly.co/terms-of-service"}><u>Terms of Service</u> </Link>
                or other agreements. This includes, without limitation, exchanging
                information with other companies and organizations for fraud protection or responding to government requests.</li>
              <li style={{ 'paddingBottom': '.2em' }}>Sharing with Gibbly Companies: Over time, Gibbly may grow and reorganize. We may share your personal
                information with affiliates such as a parent company, subsidiaries, joint venture partners, or other
                companies that we control or that are under common control by us, in which case we will require those
                companies to agree to use your personal information in a way that is consistent with this Privacy Policy.</li>
              <li style={{ 'paddingBottom': '.2em' }}>Change of Control: In the event that all or a portion of Gibbly or its assets are acquired by or merged
                with a third party, personal information that we have collected from users would be one of the assets
                transferred to or acquired by that third party. This Privacy Policy will continue to apply to your
                information, and any acquirer would only be able to handle your personal information as per this Policy
                (unless you give consent to a new Policy). We will provide you with notice of an acquisition within
                thirty (30) days following the completion of such a transaction, by posting on our homepage, and by
                email to the email address that you provided to us. If you do not consent to the use of your personal
                information by such a successor company, you may request its deletion from the company. In the unlikely
                event that Gibbly goes out of business, or files for bankruptcy, we will protect your personal information,
                and will not sell it to any third party.</li>
              <li>With your Consent: Other than the cases above, we won't disclose your personal information for any
                purpose unless you consent to it. Additionally, as discussed above, we will never sell or rent your
                personal information to advertisers or other third parties.</li>
            </ul>

            {/* <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Do Not Track</Heading>
            <Text>Gibbly does not track its users over time and across third-party websites to provide targeted
              advertising and therefore does not respond to Do Not Track (DNT) signals.</Text> */}

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Gibbly's Third-Party Service Providers</Heading>
            <Text paddingBottom={'small'}>It is important to us that we keep your information safe and secure. To best provide our services
              and keep your information safe, we work with a few other companies. These companies
              ("third-party service providers", "collaborators", or "agents") will only have access to the information
              they need to provide the Gibbly service. Below is a list of the service providers which, subject to their
              terms of service and privacy policies, may have access to personal data to process on our behalf in
              accordance with our instructions, Privacy Policy, and any other requirements regarding confidentiality, security, or integrity:
            </Text>

            <ul>
              <li style={{ 'paddingBottom': '.2em' }}>
                <Link href={"https://aws.amazon.com/compliance/data-privacy-faq/"} isExternal={true}><u>Amazon Web Services (AWS)</u> </Link>
                for email messaging, data storage, and hosting Gibbly's servers, videos, images, and audio files</li>
              <li style={{ 'paddingBottom': '.2em' }}>
                <Link href={"https://policies.google.com/privacy"} isExternal={true}><u>Google</u> </Link>
                for:
                <ul>
                  <li>Single sign-on and exporting presentations and documents to Google Drive (Google Cloud)</li>
                  <li>Website analytics <Link href={"https://policies.google.com/technologies/partner-sites"} isExternal={true}><u>(Google Analytics)</u> </Link></li>
                </ul>
              </li>

              <li>
                <Link href={"https://mailchimp.com/help/mailchimp-intuit-privacy-faq/"} isExternal={true}><u>MailChimp</u> </Link>
                for newsletters and email marketing</li>
              <li>
                <Link href={"https://stripe.com/privacy"} isExternal={true}><u>Stripe</u> </Link>
                as a payment processing service</li>
              <li>
                <Link href={"https://openai.com/policies/privacy-policy"} isExternal={true}><u>OpenAI</u> </Link>
                for our generative AI features</li>
              <li>
                <Link href={"https://posthog.com/privacy"} isExternal={true}><u>PostHog</u> </Link>
                for website analytics</li>
            </ul>

            <Text>This list may change over time, and we will work hard to keep it up-to-date. Gibbly reserves the right
              to change or add service providers which provide services in concert with the provisions of this agreement.</Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Accountability for Onward Transfer</Heading>
            <Text>We will transfer your personal information to third-party service providers only for limited and specific
              purposes. We will obtain contractual assurances from our collaborators that they will safeguard personal
              information in a manner consistent with this Policy and that they will provide the same level of protection
              as per best industry standards. We recognize our responsibility and potential liability for onward transfers
              to agents. Where we have knowledge that an agent is using or disclosing personal information in a manner
              contrary to this Policy and/or level of protection as required by applicable laws and regulations, we will
              take reasonable steps to prevent, remediate or stop such use or disclosure. If we transfer personal information
              to non-agent third parties, that is to say, any new collaborators that are not included in the previously
              mentioned list, we will (1) notify you with all necessary information on any key elements affecting the
              processing of your personal data, and (2) obtain contractual assurance from these parties that they will provide
              the same level of security as per best industry standards and in accordance with any applicable laws and regulations.</Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Gibbly Account Security</Heading>
            <Text paddingBottom={'small'}>Your Gibbly account is protected by a password. You can help us protect your account from unauthorized
              access by keeping your password secret at all times. The security of your personal information is important
              to us. We work hard to protect our community, and we maintain administrative, technical, and physical
              safeguards designed to protect against unauthorized use, disclosure of, or access to personal information, such as:</Text>

            <ul>
              <li style={{ 'paddingBottom': '.2em' }}>Security Protocols: We periodically review our information collection, storage, and processing practices,
                including physical security measures, to protect against unauthorized access to systems.</li>
              <li style={{ 'paddingBottom': '.2em' }}>Security Technology: We continually develop and implement features to keep your personal information safe.</li>
              <li style={{ 'paddingBottom': '.2em' }}>We ensure passwords are stored and transferred securely using encryption.</li>
              <li>Employee Access: We use best-effort practices to secure usernames, passwords, and any other means
                of gaining access to users' data.</li>
            </ul>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Notification of Security Breaches</Heading>
            <Text paddingBottom={'small'}> Although we make concerted good faith efforts to maintain the security of personal information, and we
              work hard to ensure the integrity and security of our systems as per best industry standards, no practices are
              100% immune, and we can't guarantee the security of information. Outages, attacks, human error, system failure,
              unauthorized use, or other factors may compromise the security of user information at any time.</Text>
            <ul>
              <li style={{ 'paddingBottom': '.2em' }}>Initial Notice: Upon the discovery of a security breach that results in the unauthorized release,
                disclosure, or acquisition of personal information, we will notify electronically of such discovery
                to all affected users. This initial notice will include, to the extent known at the time of the notification,
                the date and time of the breach, its nature and extent, and the Service's plan to investigate and remediate the breach.</li>
              <li style={{ 'paddingBottom': '.2em' }}>Detailed Notification: Upon discovery of a breach, we will conduct a deep investigation to electronically
                provide all affected users with a more detailed notice of the breach, including but not limited to the
                date and time of the breach, nature and extent of the breach, and measures taken to ensure that such
                breach does not occur in the future. We may also post a notice on our homepage (www.gibbly.co) and,
                depending on where you live, you may have a legal right to receive notice of a security breach in writing.
                When it is not possible to provide all of the aforementioned information at the same time, we will provide
                you with the remaining information without undue further delay.</li>
            </ul>

            <Text> Both notifications will be written in plain language, will be titled “Notice of Data Breach” and
              will present the information described above under the following heading: “What Happened,” “What Information
              Was Involved,” “What We Are Doing,” “What You Can Do” and “For More Information”. Additional information may
              be provided as a supplement to the notice.</Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Managing My Information</Heading>
            <Text paddingBottom={'small'}>Upon request and in accordance with the applicable laws and regulations, we will grant you
              reasonable access to your personal information that is held by Gibbly. In addition, we will
              take reasonable steps to permit you to correct, amend, or delete your personal information
              that is demonstrated to be inaccurate, incomplete, or processed in violation of this Privacy Policy.
            </Text>

            <ul>
              <li style={{ 'paddingBottom': '.2em' }}>Accessing Your Information: To request access to the personal information we have about you
                on file, users can contact us at support@gibbly.app. In some cases, we will not be able to
                guarantee complete access due to legal restrictions; for example, you will not be allowed to
                access files that contain information about other users or information that is confidential to us.
                Furthermore, we may not be able to fulfill requests that are unreasonably repetitive, require
                disproportionate technical effort, or would be extremely impractical.</li>
              <li style={{ 'paddingBottom': '.2em' }}>Updating Your Information: You may update, correct, or delete some of your profile information
                or your preferences at any time by logging into your account on Gibbly and accessing your account
                settings page. You may also, at any time, update, correct, or delete certain personal information
                that you have provided to us. To that end, users can contact us at support@gibbly.app. Please note
                that while your changes may be reflected promptly in active content, users that have previously
                accessed the content may still have access to old copies cached on their device or may have copied
                and stored your content. In addition, we may retain a backup copy of the prior version for a limited
                period of time or for legal purposes.</li>
              <li>Limitations: Without prejudice to the aforementioned, please note that we may limit or deny access
                to personal information (a) where the burden or expense of providing access would be disproportionate
                to the risks to your privacy; (b) where the legitimate rights of persons other than you would be
                violated or if necessary to safeguard important countervailing public interests (e.g., national
                security) or in other limited circumstances (e.g., disclosure would breach a legal privilege);
                and (c) where applicable law or regulatory requirements allow or require us to refuse to provide
                some or all of the personal information that we hold about you. In addition, the personal information
                may have been destroyed, erased, or made anonymous in accordance with our record retention obligations
                and practices. In the event that we cannot provide you with access to your personal information, we
                will endeavor to inform you of the reasons why, subject to any legal or regulatory restrictions.
              </li>
            </ul>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Account Deletion</Heading>
            <Text>If you ever want to delete your account, you can do so by contacting us at support@gibbly.app and we will proceed
              to delete the requested data within a reasonable period of time. When we delete your account, we delete any personal
              information that you provided in your profile (such as your username, password, and email address) and also questions,
              responses, and comments. Please note that information that you have shared with others, that others have shared about you,
              or content other users may have copied and stored, is not part of your account and may not be deleted when you delete
              your account. Part of your personal information will remain in our possession as a copy or backup that is part of our
              disaster recovery storage system for such period of time identified in our data retention policy. Personal information
              will be kept in accordance with the applicable federal, provincial, or state laws.
            </Text>


            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Consumer Complaints</Heading>
            <Text paddingBottom={'small'}>You may file a complaint concerning Gibbly's processing of your personal data to support@gibbly.app
              or by regular mail to the following address:    </Text>
            <Text paddingBottom={'small'}>Gibbly, 3-209 St. Clements Avenue, Toronto, Ontario, M4R 1H3, Canada</Text>
            <Text>
              We will take steps to remedy issues arising out of Gibbly's alleged failure to comply with the principles set out in this Privacy Policy. We will respond to your complaints within thirty (30) days. If your complaint cannot be resolved through our internal processes, we will direct you to the provincial, federal, state, or national data protection authority in the jurisdiction where you reside.
            </Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Liability</Heading>
            <Text>In the event that Gibbly or the aforementioned authorities determine that Gibbly
              failed to comply with this Policy, Gibbly will take appropriate steps to address any adverse
              effects arising directly from such failure and to promote future compliance.</Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Ontario</Heading>
            <Text>This agreement shall be construed, interpreted, and enforced in accordance
              with the laws of the Province of Ontario. Each Party irrevocably and unconditionally
              attorns to the exclusive jurisdiction of the Province of Ontario.</Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Canada</Heading>
            <Text>This Privacy Policy provides all safeguards as standardized in the
              Personal Information Protection and Electronic Documents Act (“PIPEDA”).
            </Text>

            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Other Countries</Heading>
            <Text paddingBottom={'small'}>Transfers of Personal Information to the U.S.</Text>
            <Text>Gibbly is hosted in the United States. If you use the Service from any other
              regions with laws governing data collection, protection, and use that may differ
              from United States law, please note that you may be transferring your personal information
              outside of those jurisdictions to the United States. By using the Service, you consent to this,
              and to the use and storage of personal information in accordance with this Privacy Policy.
              Third parties that have content embedded on the Gibbly website, such as a social feature,
              may set cookies on a user's browser and/or obtain information about the fact that a web browser
              visited the Gibbly website from a certain IP address. Third parties cannot collect any other
              personally identifiable information from Gibbly's websites unless you provide it to them directly.</Text>


            <Heading level={5} paddingTop={'large'} paddingBottom={'small'}>Processing in Other Regions</Heading>
            <Text>For users from other countries, Gibbly will make sure that all appropriate physical,
              technical, and organizational safeguards are adopted in accordance with this Privacy Policy
              against accidental, unauthorized or unlawful destruction, loss, alteration, disclosure, access,
              use or processing of users' personal information in Gibbly's possession.</Text>

          </View>
        </View >
      </Container >

      <Footer />

    </>

  );
}
