import * as React from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import useEffectOnce from "../hooks/useEffectOnce";

interface IVolumeContext {
  volume: number;
  setVolume: React.Dispatch<
    React.SetStateAction<{
      value: number;
    }>
  >;
  multiplier: number;
  setMultiplier: React.Dispatch<React.SetStateAction<number>>;
}

const VolumeContext = React.createContext<IVolumeContext>({
  volume: 0,
  setVolume: () => {},
  multiplier: 1,
  setMultiplier: () => {},
});

export function useVolumeContext() {
  return React.useContext(VolumeContext);
}

export interface IVolumeContextProviderProps {
  children: React.ReactNode;
}

export default function VolumeContextProvider(
  props: IVolumeContextProviderProps
) {
  const [volume, setVolume] = useLocalStorage<{ value: number }>(
    "audio-volume",
    { value: 0.1 }
  );
  const [multiplier, setMultiplier] = React.useState<number>(1);

  useEffectOnce(() => {
    if (volume.value > 0.3) setVolume({ value: 0.3 });
  });

  return (
    <>
      <VolumeContext.Provider
        value={{
          volume: volume.value,
          setVolume,
          multiplier,
          setMultiplier,
        }}
      >
        {props.children}
      </VolumeContext.Provider>
    </>
  );
}
