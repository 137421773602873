import * as React from "react";
import { SlideElement } from "../../types/slideTypes";
import ImageElementView from "./ImageElementView";
import { SlideElementsContext } from "../SlideElementsContextProvider";
import { computeElementStyles } from "../../util/computeElementStyles";
import { View } from "@aws-amplify/ui-react";
import TextElement from "./TextElementQuill";

export interface ISlideElementViewProps {
  slideElement: SlideElement;
}

export default function SlideElementView(props: ISlideElementViewProps) {
  const { slideElement } = props;

  const { selectedElements, isStatic } = React.useContext(SlideElementsContext);

  const selected = React.useMemo(
    () => selectedElements.includes(slideElement.id),
    [selectedElements, slideElement]
  );

  const elementStyles = React.useMemo(() => {
    if (slideElement.elementType === "image") {
      let { x, y, w, h } = slideElement.props.sizing ?? {
        x: 1,
        y: 1,
        w: 1,
        h: 1,
      };
      return {
        ...computeElementStyles({ x, y, w, h }),
        padding: "0",
      };
    } else if (slideElement.elementType === "text") {
      let { x, y, w, h } = slideElement.positionProps ?? {
        x: 1,
        y: 1,
        w: 1,
        h: 1,
      };

      return {
        ...computeElementStyles({ x, y, w, h }),
        padding: "0",
      };
    }
    return {};
  }, [slideElement]);

  return (
    <View
      id={slideElement.id}
      className={
        isStatic ? "" : "slide-element" + (selected ? " selected" : "")
      }
      style={elementStyles}
    >
      {slideElement.elementType === "text" && (
        <TextElement key={slideElement.id} slideElement={slideElement} />
      )}
      {slideElement.elementType === "image" && (
        <ImageElementView key={slideElement.id} slideElement={slideElement} />
      )}
    </View>
  );
}
