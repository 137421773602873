import React, { useState } from "react";
import MultiPlay from "./MultiPlay";

import useLobbyContext from "../../hooks/useLobbyContext";
import Leaderboard from "./PlayerView.Leaderboard";

export default function GameView() {
  const [view, setView] = useState<"game" | "leaderboard">("game");

  const { team } = useLobbyContext();

  switch (view) {
    case "game":
      return team === undefined ? (
        <Leaderboard />
      ) : (
        <MultiPlay goToLeaderboard={() => setView("leaderboard")} />
      );
    case "leaderboard":
      return <Leaderboard />;
  }
}
