import React from "react";
import { ResetPasswordForm } from "./ResetPasswordForm";
import { ConfirmResetPasswordForm } from "./ConfirmResetPasswordForm";
import { ResetPasswordOutput } from "aws-amplify/auth";

export function ResetPasswordMenu() {
  const [username, setUsername] = React.useState("");
  const [menuState, setMenuState] = React.useState<"reset" | "confirmation">(
    "reset"
  );

  const [output, setOutput] = React.useState<ResetPasswordOutput | undefined>(
    undefined
  );

  switch (menuState) {
    case "reset":
      return (
        <ResetPasswordForm
          username={username}
          onSetUsername={setUsername}
          onReset={(output) => {
            setOutput(output);
            if (
              output.nextStep.resetPasswordStep ===
              "CONFIRM_RESET_PASSWORD_WITH_CODE"
            )
              setMenuState("confirmation");
          }}
        />
      );
    case "confirmation":
      return <ConfirmResetPasswordForm output={output} username={username} />;
  }
}
