import React, { useEffect, useReducer, useState } from "react";

import {
  Button,
  Flex,
  SelectField,
  Text,
  View,
  TextAreaField,
} from "@aws-amplify/ui-react";

import { PageIndex, PageTitle } from "../../../util/SEO";
import useEffectOnce from "../../../hooks/useEffectOnce";
import SparkleIcon from "../../../components/icons/SparkleIcon";
import { GenerationInput } from "./GenerateQuiz";
import LanguageSelect from "../../../components/LanguageSelect";
import CurriculumAccordian from "../../curriculum/components/CurriculumAccordian";
import { CurriculumFile } from "../../curriculum/types";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { CurriculumPrompt } from "../types";
import { placeholderInstructions } from "../../../data/PlaceholderInstructions";

export default function CurriculumForm({
  onGenerate,
  loading,
}: {
  onGenerate: (input: GenerationInput) => void;
  loading: boolean;
}) {
  PageTitle("Gibbly | Generate Quiz");
  PageIndex("noindex");

  // const { prefLang } = useTranslationContext();

  const [formInput, setFormInput] = useState<CurriculumPrompt>({
    topic: "",
    instructions: "",
    lang: "en",
    numberOfQuestions: "20",
    paths: [],
  });

  const [topicPlaceholder, setTopicPlaceholder] = useState("");

  useEffectOnce(() => {
    const randomIndex = Math.floor(
      Math.random() * placeholderInstructions.length
    );
    const randomPlaceholder = placeholderInstructions[randomIndex];
    setTopicPlaceholder(randomPlaceholder);
  });

  const handleTopicSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onGenerate({
      prompt: { ...formInput },
      type: "curriculum",
    });
  };

  // const [selectedFiles, setSelectedFiles] = useState<string[]>([]);

  type CrossCurriculumsAction = {
    type: "add";
  };

  function crossCurriculumsReducer(
    state: CurriculumFile[][],
    action: CrossCurriculumsAction
  ) {
    switch (action.type) {
      case "add":
        return [...state, []];
    }
  }
  const [crossCurriculums] = useReducer(crossCurriculumsReducer, [[]]);
  const [breadcrumbs, setBreadcrumbs] = useLocalStorage<string[]>(
    "curriculum-breadcrumb",
    []
  );

  useEffect(() => {
    setFormInput((prev) => ({
      ...prev,
      topic: breadcrumbs.join(" > "),
    }));
  }, [breadcrumbs]);

  return (
    <Flex
      as="form"
      direction={"column"}
      padding={"0"}
      onSubmit={handleTopicSubmit}
    >
      {crossCurriculums.map((_, i) => (
        <View key={i}>
          <CurriculumAccordian
            selectedFiles={formInput.paths}
            setSelectedFiles={(paths) => {
              setFormInput((prev) => ({ ...prev, paths }));
            }}
            breadcrumbs={breadcrumbs}
            setBreadcrumbs={setBreadcrumbs}
          />
        </View>
      ))}
      {/* <Button
        onClick={() => {
          crossCurriculumsDispatch({
            type: "add",
          });
          // crossCurriculumsDispatch((prev) => [
          //   ...prev,
          //   {
          //     breadcrumbs: [],
          //     documentNames: [],
          //   },
          // ]);
        }}
      >
        <FaPlus />
      </Button> */}
      <TextAreaField
        fontSize={"small"}
        size="small"
        label={
          <Text fontWeight={"semibold"} color={"neutral.90"}>
            Instructions
          </Text>
        }
        placeholder={topicPlaceholder}
        // hasError={topicHasError}
        errorMessage={"Must provide a topic"}
        value={formInput.instructions}
        onChange={(e) =>
          setFormInput((prev) => ({ ...prev, instructions: e.target.value }))
        }
      />
      <Flex wrap={"wrap"}>
        <LanguageSelect
          fontSize={"small"}
          size="small"
          flex={1}
          label={
            <Text fontWeight={"semibold"} color={"neutral.90"}>
              Language
            </Text>
          }
          isRequired
          value={formInput.lang}
          onChange={(lang) => setFormInput((prev) => ({ ...prev, lang: lang }))}
        ></LanguageSelect>
        <SelectField
          label={
            <Text fontWeight={"semibold"} color={"neutral.90"}>
              # of Questions
            </Text>
          }
          fontSize={"small"}
          size="small"
          isRequired
          value={formInput.numberOfQuestions}
          onChange={(e) =>
            setFormInput((prev) => ({
              ...prev,
              numberOfQuestions: e.target.value,
            }))
          }
        >
          // from 1 to 20
          {[...Array(20).keys()].map((i) => (
            <option key={i} value={(i + 1).toString()}>
              {i + 1}
            </option>
          ))}
        </SelectField>
      </Flex>

      <Button
        id="raised-btn"
        backgroundColor={"#fd7e14"}
        variation="primary"
        gap={"small"}
        type="submit"
        color={"white"}
        alignSelf={"center"}
        isLoading={loading}
        loadingText={"Generating..."}
        data-attr="generate-new-quiz"
        data-ph-capture-attribute-type={"curriculum"}
        data-ph-capture-attribute-topic={formInput.topic}
        data-ph-capture-attribute-paths={formInput.paths}
        data-ph-capture-attribute-instructions={formInput.instructions}
        data-ph-capture-attribute-language={formInput.lang}
        data-ph-capture-attribute-number-of-questions={
          formInput.numberOfQuestions
        }
      >
        <SparkleIcon />
        Generate
      </Button>
    </Flex>
  );
}
