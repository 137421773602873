import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import { autoPickImages } from "../services/autoPickImages";

import { useUserContext } from "../context/UserContextProvider";
import { toast } from "react-toastify";
// type SearchResult = {
//   search: string;
//   results: PixabayImage[];
// };

export function useAutoPickedImageListMutation() {
  const { hasTokens, user } = useUserContext();

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (prompt: string) => {
      if (!prompt) {
        toast.info(
          "QuickPic needs more info. Try adding a title, topic, or some text.",
          { toastId: "auto-pick-image" }
        );
        throw new Error(
          "QuickPic needs more info. Try adding a title, topic, or some text."
        );
      }
      if (hasTokens()) {
        const images = await autoPickImages(prompt, user?.identityId);
        queryClient.invalidateQueries(["user"]);
        return images;
      }
      return [];
    },
    onError: (err: Error) => {
      console.error(err);
      // toast.error(err.message, { toastId: "auto-pick-image-" + prompt });
    },
  });

  const {
    data: images,
    mutateAsync: autoPickImageList,
    ...restMutation
  } = useMemo(() => mutation, [mutation]);

  return {
    images,
    autoPickImageList,
    ...restMutation,
  };
}
