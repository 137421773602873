import {
  getUrl,
  GetUrlWithPathInput,
  GetUrlWithPathOutput,
} from "aws-amplify/storage";

type GetS3UrlOptions =
  | ({
      version: 1;
      key: string;
    } & (
      | {
          level: "public";
        }
      | {
          level: "protected" | "private";
          identityId?: string;
        }
    ))
  | ({
      version: 2;
    } & GetUrlWithPathInput);

/***
 * Get the S3 URL for the given key and level
 * You must define a version number at minimun. Version 1 complies with previous amplify storage api implementation, version 2 is the new one.
 * @param {GetS3UrlOptions} options
 * @returns {Promise<GetUrlWithPathOutput>}
 */
export async function getS3Url(
  options: GetS3UrlOptions
): Promise<GetUrlWithPathOutput> {
  if (options.version === 1) {
    const { key, level } = options;
    if (level === "protected" || level === "private") {
      const { identityId } = options;
      return await getUrl({
        path: identityId
          ? `${level}/${identityId}/${key}`
          : ({ identityId }) => `${level}/${identityId}/${key}`,
        options: {
          validateObjectExistence: true,
        },
      });
    } else {
      return await getUrl({ path: `${level}/${key}` });
    }
  } else {
    const { version, ...getUrlOptions } = options;

    return await getUrl(getUrlOptions);
  }
}
