import { QueryClient } from "@tanstack/react-query";
import { synthesizeSpeechAudio, synthesizeSpeechMarks } from ".";
import { LanguageOption } from "../../translations";
import { SynthesizedMarking, Speed, Synthesis } from "../types";

type Options = {
  text: string;
  lang?: LanguageOption;
  speed?: Speed;
  onStart?: (audio: HTMLAudioElement) => void;
  onPause?: () => void;
  onWord?: (marking: SynthesizedMarking, index: number) => void;
  onEnd?: () => void;
  onError?: (e: Error) => void;
  queryClient?: QueryClient;
};

export async function readAndHighlightText({
  text,
  lang = "en",
  speed = "medium",
  onWord,
  onStart,
  onPause,
  onEnd,
  onError,
  queryClient,
}: Options) {
  let cached: Synthesis | undefined;
  if (queryClient) {
    cached = queryClient.getQueryData([
      "speech-synthesis",
      text,
      lang,
      speed,
    ]) as Synthesis;
  }

  let audio: HTMLAudioElement;
  let markings: SynthesizedMarking[];
  if (cached) {
    const { url } = cached;
    audio = new Audio(url);
    markings = cached.markings;
  } else {
    const marksStream = await synthesizeSpeechMarks(text, lang, speed);
    const audioStream = await synthesizeSpeechAudio(text, lang, speed);

    const string = await marksStream.transformToString();
    try {
      markings = string
        .trim()
        .split("\n")
        .map((mark) => JSON.parse(mark));
    } catch (e) {
      console.error(e);
      onError?.(e as Error);
      return;
    }

    const byteArray = await audioStream.transformToByteArray();

    const url = URL.createObjectURL(new Blob([byteArray]));
    audio = new Audio(url);

    if (queryClient)
      queryClient.setQueryData(["speech-synthesis", text, lang, speed], {
        url,
        markings,
      });
  }

  onStart?.(audio);

  const timeouts: NodeJS.Timeout[] = [];

  audio.onplay = () => {
    for (let i = 0; i < markings.length; i++) {
      const marking = markings[i];
      const timeout = setTimeout(() => {
        onWord?.(marking, i);
      }, marking.time);
      timeouts.push(timeout);
    }
  };

  // audio.playbackRate = speed === "Slow" ? 0.7 : 1;
  // audio.preservesPitch = true;
  audio.play();

  await new Promise((resolve) => {
    audio.onended = (ev) => {
      onEnd?.();
      resolve(ev);
    };
    audio.onpause = (ev) => {
      timeouts.forEach(clearTimeout);
      if (audio.currentTime === 0) onPause?.();
      resolve(ev);
    };
  });
  if (audio.currentTime !== 0) {
    await new Promise((resolve) => {
      setTimeout(resolve, 500);
    });
  }
}
