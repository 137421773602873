import { getQuestionsFromQuiz } from "../../../services/custom-queries";
import { GetQuizQuery, QuizQuestion } from "../../../API";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { generateClient } from "aws-amplify/api";

export default async function fetchQuizQuestionsFromQuiz(
  quizId: string | undefined
): Promise<QuizQuestion[]> {
  if (quizId === undefined) throw new Error("Quiz ID is undefined");
  const client = await generateClient();

  const result = (await client.graphql({
    query: getQuestionsFromQuiz,
    variables: { quizId },
  })) as GraphQLResult<GetQuizQuery>;
  return result.data?.getQuiz?.Questions?.items as QuizQuestion[];
}
