import { GraphQLQuery, generateClient } from "aws-amplify/api";
import createQuiz from "../../features/quiz/utils/createQuiz";
import { GetQuizQuery, S3ObjectProtectedInput } from "../../API";
import { getIdentityId } from "../../features/game/util";
import { copyS3Item } from "../s3/copyS3Item";
import { duplicateQuestion } from "../../features/quiz/services/duplicateQuestion";

const getQuiz = /* GraphQL */ `
  query GetQuiz($id: ID!) {
    getQuiz(id: $id) {
      id
      title
      description
      lang
      tags
      Questions {
        items {
          id
        }
      }
      image {
        key
        identityId
        alt
      }
    }
  }
`;

async function duplicateQuiz(quizId: string) {
  const client = generateClient();

  const result = await client.graphql<GraphQLQuery<GetQuizQuery>>({
    query: getQuiz,
    variables: { id: quizId },
  });
  const quiz = result.data?.getQuiz;

  if (!quiz) throw new Error("Quiz not found");

  const { title, description, lang, image, tags, Questions } = quiz;

  // make a copy of the image with new owner
  if (image?.identityId && image.key)
    await copyS3Item({ identityId: image?.identityId, fileName: image?.key });

  const identityId = await getIdentityId();

  const newImage: S3ObjectProtectedInput | null = image?.key
    ? {
        key: image?.key ?? "",
        identityId: identityId,
      }
    : null;

  const newQuiz = await createQuiz({
    title: `Copy of ${title}`,
    description,
    lang: lang ?? "en",
    image: newImage,
    tags,
  });

  const dupQuestionPromises = Questions?.items?.map((quizQuestion) => {
    if (quizQuestion) return duplicateQuestion(newQuiz.id, quizQuestion?.id);
    return;
  });

  if (dupQuestionPromises) await Promise.all(dupQuestionPromises);

  return newQuiz;
}

export default duplicateQuiz;
