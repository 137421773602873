import React from "react";
import useModal from "./useModal";
import { Button, Flex, Text } from "@aws-amplify/ui-react";

type Props = {
  confirmFn?: () => void;
  initialShow?: boolean;
  headerMessage?: string;
  bodyMessage?: string;
};

export default function useConfirmationModal(
  props: Props
): [React.FunctionComponent<any>, (arg: boolean) => void] {

  const {
    confirmFn = () => { },
    initialShow = false,
    headerMessage = "Are you sure?",
    bodyMessage = "This action is irreversible.",
  } = props;

  const [Modal, setShow] = useModal(
    {
      title: headerMessage,
      initShow: initialShow,
      ReactComponent: () => (
        <>
          <Flex direction={"column"} gap={"medium"} grow={1}>
            <Text textAlign={"center"}>{bodyMessage}</Text>
            <Flex grow={1}>
              <Button
                variation="primary"
                id="raised-btn"
                backgroundColor={"#de3721"}
                flex={1}
                onClick={() => {
                  confirmFn();
                  setShow(false);
                }}
              >
                Yes
              </Button>

              <Button
                variation="primary"
                id="raised-btn"
                backgroundColor={"#428019"}
                flex={1}
                onClick={() => {
                  setShow(false);
                }}
              >
                No
              </Button>
            </Flex>
          </Flex>
        </>
      ),
    },
    [headerMessage, bodyMessage]
  );

  return [Modal, setShow];
}
