import { updateDatabaseItem } from "../../../services/database/updateDatabaseItem";
import { Player, PlayerKey } from "../Types/GameTypes_dep";

export async function updatePlayer(
  lobbyId: string,
  userId: string,
  updatePlayerObject: Partial<Player>
) {
  const player = await updateDatabaseItem<Player, PlayerKey>(
    "/player",
    { playerSessionId: lobbyId, userId },
    updatePlayerObject
  );
  return player;
}
