import React from 'react'
import ArrowUpIcon from '../components/icons/ArrowUpIcon'
import RaisedButton from '../components/RaisedButton'
import { useEffect, useState } from 'react'

export default function ScrollArrow() {


    const [scrollTop, setScrollTop] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 100) {
                setScrollTop(true)
            } else {
                setScrollTop(false)
            }
        })

    }, [])

    const scrollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }


    return (
        <>
            {scrollTop && (
                <RaisedButton
                    fontSize={{ base: 'small', medium: 'medium' }}
                    borderRadius={'100%'}
                    color={'white'}
                    backgroundColor={'teal.60'}
                    position={'fixed'} top='3%' right='1%'
                    justifyContent={'center'}
                    onClick={scrollUp}
                ><ArrowUpIcon /> </RaisedButton>

            )}
        </>
    )
}
