import { Flex, Loader, Text } from '@aws-amplify/ui-react'
import React, { useMemo } from 'react'
import { AssignmentResult } from '../../types'
import useAsync from '../../../../hooks/useAsync'
import { getStudent } from '../../util/student/getStudent'
import { useParams } from 'react-router-dom'
import { useAnswerResultsAnalytics } from '../../hooks/useAnswerResults'

export default function LeaderboardResult({ assignmentResult, i }: { assignmentResult: AssignmentResult, i: number }) {
  const { classId } = useParams()

  const student = useAsync(async () => {
    if (classId === undefined)
      return;
    const student = await getStudent(classId, assignmentResult.studentId)
    return student;
  }, [])

  const { score } = useAnswerResultsAnalytics(
    assignmentResult.answerResults
  );

  const color = React.useMemo(() => {
    if (score < 50) return "var(--amplify-colors-red-60)";
    if (score < 80) return "var(--amplify-colors-orange-60)";
    return "var(--amplify-colors-green-80)";
  }, []);

  const average = useMemo(() => {

    // get all answerResults with isCorrect = true
    const correctAnswerResults = assignmentResult.answerResults.filter(
      (answerResult) => answerResult.isCorrect
    );
    // get the total number of correct answers
    const totalCorrectAnswers = correctAnswerResults.length;
    // get the total number of answers
    const totalAnswers = assignmentResult.answerResults.length;
    // return the average score
    if (totalAnswers === 0) {
      return 0;
    } else {
      return totalCorrectAnswers / totalAnswers * 100;
    }
  }, [assignmentResult]);


  const correct = useMemo(() => {

    // get all answerResults with isCorrect = true
    const correctAnswerResults = assignmentResult.answerResults.filter(
      (answerResult) => answerResult.isCorrect
    );
    // get the total number of correct answers
    const totalCorrectAnswers = correctAnswerResults.length;
    return totalCorrectAnswers;
  }, [assignmentResult]);


  const total = useMemo(() => {

    const totalAnswers = assignmentResult.answerResults.length;
    return totalAnswers;
  }, [assignmentResult]);



  return (
    <>
      <Flex gap='large' columnGap={'large'}  width={'100%'} grow={1} alignItems={"center"} >
        <Text fontSize={{ base: "smaller", medium: "medium" }} fontWeight={'semibold'} width={'3%'}>{i + 1}</Text>
        <Text  id="raised-item" backgroundColor={color} color="white" borderRadius={"xxl"} textAlign={'center'}   fontSize={{ base: "smaller", medium: "small" }} fontWeight={'semibold'} width={{ base: '30%', medium: '10%' }} >{student.value?.nickname}</Text>
        <Loader
          size="large"
          width={{ base: '60%', medium: '80%' }}
          variation="linear"
          emptyColor="var(--amplify-colors-red-60)"
          filledColor="var(--amplify-colors-green-60)"
          percentage={average}
          isDeterminate
          isPercentageTextHidden
          borderRadius={'small'}
        />
        <Text fontSize={{ base: "smaller", medium: "medium" }} textAlign={'center'} fontWeight={'semibold'} minWidth={'7%'}>{correct}/{total}</Text>
      </Flex>
    </>
  )
}
