
import { Avatar } from "../Types/GameTypes_dep";
import { createPlayerGrouped } from "./createPlayerGrouped";
import { createPlayerUngrouped } from "./createPlayerUngrouped";
import { getTeamWithFewestPlayers } from "./getTeamWithFewestPlayers";

export async function createPlayerAutoGrouped(
  lobbyId: string,
  userId: string,
  nickname: string,
  avatar: Avatar
) {
  const team = await getTeamWithFewestPlayers(lobbyId);
  if (team)
    await createPlayerGrouped(lobbyId, team.id, userId, nickname, avatar);
  else await createPlayerUngrouped(lobbyId, userId, nickname, avatar);
}
