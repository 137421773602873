import RestAPI from "../../../../util/RestAPI";
import {
  AssignmentResult,
  PutAssignmentResultInput,
  UpdateAssignmentResultObject,
} from "../../types/assignmentResultTypes";

export async function updateAssignmentResult(
  assignmentResultId: string,
  assignmentId: string,
  updateObject: UpdateAssignmentResultObject
) {
  let UpdateExpression = "SET ";
  let ExpressionAttributeValues: any = {};

  const updateKeys = Object.keys(updateObject);
  updateKeys.forEach((key, index) => {
    const placeholder = `:${key}`;
    UpdateExpression += `${key} = ${placeholder}`;

    ExpressionAttributeValues[placeholder] = updateObject[key];

    if (index !== updateKeys.length - 1) {
      UpdateExpression += ", ";
    }
  });




  const input: PutAssignmentResultInput = {
    Key: {
      assignmentId: assignmentId,
      id: assignmentResultId,
    },
    ReturnValues: "ALL_NEW",
    UpdateExpression,
    ExpressionAttributeValues,
  };

  try {
    const updatedAssignment: AssignmentResult = (await RestAPI.put(
      "/assignmentResults",
      { body: input }
    )) as any as AssignmentResult;
    return updatedAssignment;
  } catch (err) {

    return;
  }
}
