import * as React from "react";

import Quill from "quill";

export const fonts: { [key: string]: string } = {
  badscript: "Bad Script",
  // opensans: "Open Sans",
  arial: "Arial",
  arialblack: "Arial Black",
  arialnarrow: "Arial Narrow",
  // bellmt: "Bell MT",
  // bookantiqua: "Book Antiqua",
  // bookmanoldstyle: "Bookman Old Style",
  calibri: "Calibri",
  cambria: "Cambria",
  candara: "Candara",
  century: "Century",
  comicsans: "Comic Sans MS",
  consolas: "Consolas",
  constantia: "Constantia",
  corbel: "Corbel",
  couriernew: "Courier New",
  georgia: "Georgia",
  impact: "Impact",
  lucidasans: "Lucida Sans",
  palatinolinotype: "Palatino Linotype",
  verdana: "Verdana",
  segoescript: "Segoe Script",
  simsun: "SimSun",
  tahoma: "Tahoma",
  timesnewroman: "Times New Roman",
  trebuchetms: "Trebuchet MS",
  serif: "Serif",
  monospace: "Monospace",
};

export const sizes = [
  "8",
  "9",
  "10",
  "11",
  "12",
  "14",
  "16",
  "18",
  "20",
  "24",
  "28",
  "32",
  "36",
  "40",
  "44",
  "48",
  "54",
  "60",
  "66",
  "72",
  "80",
  "88",
  "96",
];

export interface RichTextContextProps {
  currentEditor: Quill | null;
  setCurrentEditor?: React.Dispatch<React.SetStateAction<Quill | null>>;
  currentFormats: any | undefined;
  setCurrentFormats?: React.Dispatch<React.SetStateAction<any | undefined>>;
}

export const RichTextContext = React.createContext<RichTextContextProps>({
  currentEditor: null,
  currentFormats: undefined,
});

export interface IRichTextContextProviderProps {
  children: React.ReactNode;
}

export function useRichTextContext() {
  return React.useContext(RichTextContext);
}

export default function RichTextContextProvider({
  children,
}: IRichTextContextProviderProps) {
  const [currentEditor, setCurrentEditor] = React.useState<Quill | null>(null);
  const [currentFormats, setCurrentFormats] = React.useState<any>();

  const Font: any = Quill.import("formats/font");
  Font.whitelist = Object.keys(fonts);
  Quill.register(Font, true);

  var Size: any = Quill.import("attributors/style/size");
  Size.whitelist = sizes.map((size) => size + "pt");
  Quill.register(Size, true);

  // const Parchment = Quill.import("parchment");

  // // const customFontFamilyAttributor = new Parchment.Attributor.Style(
  // //   "custom-family-attributor",
  // //   "font-family"
  // // );
  // const customSizeAttributor = new Parchment.Attributor.Style(
  //   "size",
  //   "font-size"
  // );
  // const customColorAttributor = new Parchment.Attributor.Style(
  //   "color",
  //   "color"
  // );

  // const ListItemBlot = Quill.import("formats/list/item");

  // class CustomListItem extends ListItemBlot {
  //   optimize(context: any) {
  //     super.optimize(context);

  //     if (this.children.length >= 1) {
  //       const child = this.children.head;
  //       const attributes = child?.attributes?.attributes;

  //       if (attributes) {
  //         for (const key in attributes) {
  //           const element = attributes[key];

  //           let name = element.keyName;
  //           const value = element.value(child.domNode);
  //           // if (name === "color") super.format("color", value);
  //           // else if (name === "ql-font") super.format("font", value);
  //           // else if (name === "font-size") super.format("size", value);

  //           if (name === "color") super.format("color", value);
  //           // else if (name === "font-family")
  //           //   super.format("custom-family-attributor", value);
  //           else if (name === "font-size") super.format("size", value);
  //         }
  //       } else {
  //         super.format("color", false);
  //         // super.format("custom-family-attributor", false);
  //         super.format("size", false);
  //       }
  //     }
  //   }
  // }

  // Quill.register(customColorAttributor, true);
  // // Quill.register(customFontFamilyAttributor, true);
  // Quill.register(customSizeAttributor, true);
  // Quill.register(CustomListItem, true);

  return (
    <RichTextContext.Provider
      value={{
        currentEditor,
        setCurrentEditor,
        currentFormats,
        setCurrentFormats,
      }}
    >
      {children}
    </RichTextContext.Provider>
  );
}
