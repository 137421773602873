import { Assignment } from "../../types";


export function sortAssignmentsByDueDate(assignments: Assignment[]) {
    return assignments.sort((a, b) => {
      if (a.dueDate === 0 && b.dueDate === 0) {
        return 0;
      } else if (a.dueDate === 0) {
        return 1;
      } else if (b.dueDate === 0) {
        return -1;
      } else {
        return a.dueDate - b.dueDate;
      }
    });
  }