import React, { useEffect, useState } from "react";

import {
  Flex,
  Collection,
  Loader,
  ResponsiveStyle,
  Text,
} from "@aws-amplify/ui-react";

import {
  Property
} from "csstype";

import {
  IQuizCardProps,
  IQuizCardFunctionalProps,
} from "../types/IQuizCardProps";
import SidebarCreateButton from "../../../layouts/SidebarCreateButton";

export default function QuizCollection({
  quizIds,
  CardComponent,
  cardFunctionalProps,
  templateColumns
}: {
  quizIds: (string | undefined)[];
  CardComponent: React.ComponentType<IQuizCardProps>;
  cardFunctionalProps: IQuizCardFunctionalProps;
  templateColumns: ResponsiveStyle<Property.GridTemplateColumns<0 | (string & {})>> | undefined
}) {

  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {quizIds ? (
        <Collection
          items={quizIds}
          type="grid"
          templateColumns={templateColumns}
          gap="1rem"
          padding={"xxs"}
          searchNoResultsFound={
            <>
              {show && (
                <Flex className={"fade-in"} gap={"xxxl"} alignItems={"center"} alignContent={"center"} width={"100%"} textAlign={"center"} direction={"column"}>
                  <Text color="neutral.90" fontSize={{ base: "medium", medium: "larger" }} maxWidth={"500px"} >
                    Create a quiz by clicking <b>Create</b> in the navigation OR click here 👇
                  </Text>
                  <Flex height={"60px"} justifyContent="center" style={{ scale: "2" }}>
                    <SidebarCreateButton navTitle={"Create"} posthogType={"quizzes-tab"} />
                  </Flex>
                </Flex>)}
            </>
          }
        >
          {(quizId) => (
            <CardComponent
              key={quizId}
              quizId={quizId}
              {...cardFunctionalProps}
            />
          )}
        </Collection>
      ) : (
        <Flex justifyContent={"center"}>
          <Loader size="large" alignSelf={"center"} />
        </Flex>
      )}
    </>
  );
}
