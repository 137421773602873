import { Text, Flex, ScrollView, Rating } from '@aws-amplify/ui-react'
import React from 'react'

export default function TestimonalItem({ testimonial, name }: { testimonial: string, name: string }) {
    return (


        <Flex
            paddingTop="0"
            paddingLeft={{ base: "0", small: "medium", xl: "xxxl" }}
            paddingRight={{ base: "0", small: "medium", xl: "xxxl" }}
        >
            <ScrollView width={"100%"}>
                <Flex
                    direction={"column"}
                    paddingLeft={{ base: "small", small: "medium", xl: "xxxl" }}
                    paddingRight={{ base: "small", small: "medium", xl: "xxxl" }}
                    gap={{ base: "small", medium: "large" }}
                    height={"200px"}
                    justifyContent={"center"}
                    width={"100%"}
                    grow={1}
                    textAlign={"center"}
                    alignItems={"center"}
                >

                    <Rating
                        value={5}
                        maxValue={5}
                        fillColor="orange.60"
                    />

                    <Text
                        fontSize={{ base: "smaller", medium: "medium" }}
                     >
                        {testimonial}
                    </Text>

                    <i>
                        <Text
                            fontWeight={"bold"}
                        >
                            {name}
                        </Text>
                    </i>

                </Flex>
            </ScrollView>


        </Flex >




    )
}
