import { Lesson, LessonKey } from "../types/lessonTypes";
import { getIdentityId } from "../../game/util";
import { deleteDatabaseItem } from "../../../services/database/deleteDatabaseItem";

export async function deleteLesson(id: string) {

    const userId = await getIdentityId()

    const lesson = await deleteDatabaseItem<Lesson, LessonKey>("/lessons", { userId, id })

    return lesson
}