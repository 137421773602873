import { View, Heading, Card, Text } from "@aws-amplify/ui-react";
import React from "react";
import AddIcon from "../../../../components/icons/AddIcon";
import DeleteIcon from "../../../../components/icons/DeleteIcon";
import SettingsIcon from "../../../../components/icons/SettingsIcons";

export default function LobbyTutorial() {

  return (
    <View>
      {/* <Heading level={4} padding={"medium 0 small 0"}>
      Getting Started
    </Heading> */}
      <Card
        variation="outlined"
        borderRadius={"medium"}
        backgroundColor={"neutral.10"}
      >
        <Text paddingBottom={"small"}>
          Gibbly is a <b>collaborative</b> learning experience where students
          build their <b>communication</b> and <b>teamwork</b> skills while
          competing against other teams for points.
        </Text>
        <Text paddingBottom={"small"}>
          Students will take turns answering questions, and will need to work
          together to get the correct answer. There is{" "}
          <u>
            <b>no time limit</b>
          </u>
          , leaving room for discussion and collaboration.
        </Text>
      </Card>
      <Heading level={5} padding={"medium 0 small 0"}>
        Lobby Settings
      </Heading>

      <Text>
        <Text paddingBottom={"small"}>
          When creating the lobby, you have a few options to customize the game
          environment:
        </Text>
        <Text paddingBottom={"small"}>
          <ol>
            <li>
              <b>Number of Teams:</b> Adjust the number of teams (up to 8).
            </li>
            <li>
              <b>Nicknames:</b> Allow players to choose their own <i>Custom</i>{" "}
              nickname, or select <i>Random</i> to provide randomized ones (A
              great option to prevent inappropriate names).
            </li>

            <li>
              <b>Automatic Teams:</b> Automatically assign players to teams as
              they join the lobby.
            </li>

            <li>
              <b>Players Can Choose Team:</b> Allow players to choose their own
              teams from their device.
            </li>

            <li>
              <b>Host Role:</b> Act as a <i>Spectator</i> or play along as a{" "}
              <i>Player</i>!
            </li>

            <li>
              <b>Player Music:</b> Toggle the music <i>On/Off</i> on your
              players devices. You can independently control your volume and
              sounds from your screen as a host once you're in the lobby.
            </li>
          </ol>

          <Text paddingBottom={"small"}>
            To update the Settings, click on the Settings button{" "}
            <SettingsIcon />.
          </Text>
        </Text>
      </Text>

      <Heading level={5} padding={"0 0 small 0"}>
        Team Management
      </Heading>
      <Text paddingBottom={"small"}>
        Consider splitting your players into teams, seating the teammates
        together, and then assigning them a team number. Turn{" "}
        <b>Players Can Choose Team</b> to <i>Yes</i> to allow them to get into
        the right team. Before starting the game, go back into the Settings and
        turn this to <i>No</i> to make sure there are no last minute switches.
      </Text>
      <Text paddingBottom={"small"}>
        To manage teams, you can also click and drag players to the team you
        want them in.
      </Text>
      <Text paddingBottom={"small"}>
        To create teams, click on the Plus button <AddIcon />. To delete teams,
        click on the Trash button <DeleteIcon />.
      </Text>
    </View>
  );
}
