import { downloadQTIZip } from ".";
import { generateClient, GraphQLQuery } from "aws-amplify/api";
import {
  GetQuizQuery,
  Question,
  QuestionType,
  S3ObjectProtected,
} from "../../../API";

const getQuiz = /* GraphQL */ `
  query GetQuiz($id: ID!) {
    getQuiz(id: $id) {
      id
      title
      description
      lang
      tags
      Questions {
        items {
          question {
            type
            id
            text
            answers
            correctIndices
            image {
              key
              identityId
              alt
            }
            audio {
              key
              identityId
            }
          }
        }
      }
      image {
        key
        identityId
        alt
      }
    }
  }
`;

export async function downloadQuizToQTIZip(quizId: string | undefined) {
  if (!quizId) throw new Error("No quiz id provided");

  const client = generateClient();

  const result = await client.graphql<GraphQLQuery<GetQuizQuery>>({
    query: getQuiz,
    variables: { id: quizId },
  });
  const quiz = result.data?.getQuiz;
  if (!quiz) throw new Error("Quiz not found");
  const { Questions: QuizQuestions } = quiz;

  if (!QuizQuestions) throw new Error("Questions not found");
  const questions = QuizQuestions.items.map(
    (question) => question?.question as Question
  );

  // const input: CreateCanvasQuizInput = {
  //   title,
  //   description,
  // };

  // Add qti.xml
  const qtiContent = `<?xml version="1.0" encoding="UTF-8"?>
<questestinterop xmlns="http://www.imsglobal.org/xsd/ims_qtiasiv1p2" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.imsglobal.org/xsd/ims_qtiasiv1p2 http://www.imsglobal.org/xsd/ims_qtiasiv1p2p1.xsd">
  <assessment ident="${quiz.id}" title="${quiz.title.replaceAll(
    `"`,
    "'"
  )}" description="${quiz.description.replaceAll(`"`, "'")}">
    <section ident="s1">
      ${questions
        .map((q, qi) =>
          q
            ? `
      <item ident="${q.id}" title="Question ${qi + 1}">
        <itemmetadata>
          <qtimetadata>
            <qtimetadatafield>
              <fieldlabel>question_type</fieldlabel>
              <fieldentry>${getQuestionType(q.type)}</fieldentry>
            </qtimetadatafield>
            <qtimetadatafield>
              <fieldlabel>points_possible</fieldlabel>
              <fieldentry>1.0</fieldentry>
            </qtimetadatafield>
          </qtimetadata>
        </itemmetadata>
        <presentation>
          <material>
            <mattext texttype="text/html">
              <p>${q.text}</p>
              </mattext>
                ${
                  q.image
                    ? `<matimage imagtype="image/*" uri="media/${
                        q.image.key
                      }" width="400" height="267" label="${q.image.alt ?? `Question ${qi} Image`}" />`
                    : ""
                }
          </material>
          ${getResponse(q.type, q, qi)}
        </presentation>        
        <resprocessing>
          ${getResponseProcessing(q.type, q, qi)}
        </resprocessing>
      </item>`
            : ""
        )
        .join("\n")}
    </section>
  </assessment>
</questestinterop>`;

  // const oneLineQtiContent = qtiContent.replace(/\n/g, "").replace(/\s+/g, " ");

  const media = questions.flatMap(
    (q) => [q.image, q.audio].filter((m) => m) as S3ObjectProtected[]
  );

  await downloadQTIZip({
    name: `${quiz.title}`,
    qtiContent,
    media,
  });
}

function getQuestionType(type: QuestionType | null | undefined) {
  switch (type) {
    case QuestionType.MULTI:
    case QuestionType.MULTIPLE_SELECT:
      return "multiple_answers_question";
    case QuestionType.SINGLE:
    case QuestionType.SINGLE_SELECT:
      return "multiple_choice_question";
    case QuestionType.TRUEFALSE:
    case QuestionType.TRUE_OR_FALSE:
      return "true_false_question";
    case QuestionType.TYPED:
    case QuestionType.TYPED_ANSWER:
      return "short_answer_question";
  }
  return "multiple_choice_question";
}

function getResponse(
  type: QuestionType | null | undefined,
  question: Question,
  index: number
) {
  switch (type) {
    case QuestionType.MULTI:
    case QuestionType.MULTIPLE_SELECT:
      return `<response_lid ident="response1" rcardinality="Multiple" >
            <render_choice>
              ${question.answers
                .map((a, ai) =>
                  a
                    ? `<response_label ident="choice${index}${ai}">
                <material>
                  <mattext texttype="text/html">${a}</mattext>
                </material>
              </response_label>`
                    : ""
                )
                .join("\n")}
            </render_choice>
          </response_lid>`;
    case QuestionType.SINGLE:
    case QuestionType.SINGLE_SELECT:
    default:
      return `<response_lid ident="response1" rcardinality="Single" >
              <render_choice>
                ${question.answers
                  .map((a, ai) =>
                    a
                      ? `<response_label ident="choice${index}${ai}">
                  <material>
                    <mattext texttype="text/html">${a}</mattext>
                  </material>
                </response_label>`
                      : ""
                  )
                  .join("\n")}
              </render_choice>
            </response_lid>`;
    case QuestionType.TRUEFALSE:
    case QuestionType.TRUE_OR_FALSE:
      return `<response_lid ident="response1" rcardinality="Single" >
            <render_choice>
              ${["True", "False"]
                .map(
                  (a, ai) =>
                    `<response_label ident="choice${index}${ai}">
                <material>
                  <mattext texttype="text/html">${a}</mattext>
                </material>
              </response_label>`
                )
                .join("\n")}
            </render_choice>
          </response_lid>`;
    case QuestionType.TYPED_ANSWER:
      return `<response_str ident="response1" rcardinality="Single">
            <render_fib>
              <response_label ident="answer1" rshuffle="No"/>
            </render_fib>
          </response_str>`;
  }
}
function getResponseProcessing(
  type: QuestionType | null | undefined,
  question: Question,
  index: number
) {
  switch (type) {
    case QuestionType.MULTI:
    case QuestionType.MULTIPLE_SELECT:
      return `<outcomes>
            <decvar maxvalue="100" minvalue="0" varname="SCORE" vartype="Decimal"/>
          </outcomes>
          <respcondition continue="No">
            <conditionvar>
              <and>
                ${question.answers
                  .map((_, ai) =>
                    question.correctIndices.includes(ai)
                      ? `<varequal respident="response1">choice${index}${ai}</varequal>`
                      : `<not><varequal respident="response1">choice${index}${ai}</varequal></not>`
                  )
                  .join("")}
              </and>
            </conditionvar>
            <setvar action="Set" varname="SCORE">100</setvar>
          </respcondition>`;
    case QuestionType.SINGLE:
    case QuestionType.SINGLE_SELECT:
    case QuestionType.TRUEFALSE:
    case QuestionType.TRUE_OR_FALSE:
    default:
      return `<outcomes>
            <decvar maxvalue="100" minvalue="0" varname="SCORE" vartype="Decimal"/>
          </outcomes>
          <respcondition continue="No">
            <conditionvar>
            ${question.correctIndices
              .map(
                (ci) =>
                  `<varequal respident="response1">choice${index}${ci}</varequal>`
              )
              .join("\n")}
              </conditionvar>
              <setvar action="Set" varname="SCORE">100</setvar>
              </respcondition>`;
    case QuestionType.TYPED_ANSWER:
      return `<outcomes>
                    <decvar maxvalue="100" minvalue="0" varname="SCORE" vartype="Decimal"/>
                  </outcomes>
                  <respcondition continue="No">
                    <conditionvar>
                    ${question.answers
                      .map(
                        (a) => `<varequal respident="response1">${a}</varequal>`
                      )
                      .join("\n")}
                      </conditionvar>
                      <setvar action="Set" varname="SCORE">100</setvar>
                      </respcondition>`;
  }
}
