import RestAPI from "../../../../util/RestAPI";
import {  Assignment } from "../../types";

export async function deleteAssignment(assignment: Assignment) {
  try {
    await RestAPI.del(`/assignments/object/${assignment.classId}/${assignment.id}`, {});
  } catch (err) {

  }
}
