import { Flex, Grid, Text } from "@aws-amplify/ui-react";
import * as React from "react";
import ExitButton from "../../../../layouts/ExitButton";
import FullScreenButton from "../../../../layouts/FullScreenButton";
import AudioController from "../../../../layouts/AudioController";
import { useVolumeContext } from "../../../../context";

export interface IGameHeaderProps {
  questionIndex: number;
  questions: any;
  onExit?: () => void | Promise<void>;
  music?: string;
}

export function GameFooter(props: IGameHeaderProps) {
  const { questionIndex, questions, onExit, music } = props;
  const { volume, setVolume, multiplier } = useVolumeContext();

  return (
    <Grid
      backgroundColor={"rgba(0,0,0,.5)"}
      width={"100%"}
      boxShadow={"0 5px 1px rgba(0, 0, 0, 0.1)"}
      justifyContent={"space-around"}
      templateColumns={"1fr 1fr"}
    >
      <Flex alignItems={"center"} padding={"xs"}>
        {questions ? (
          <Text
            id="displayText"
            color="white"
            fontWeight={"black"}
            fontSize={{ base: "xs", small: "larger" }}
          >
            {Math.min(questionIndex + 1, questions.length)}/{questions.length}
          </Text>
        ) : null}
      </Flex>

      <Flex gap={"large"} alignContent={"center"} justifyContent={"right"}>
        <AudioController
          src={music}
          loop
          autoPlay
          volumeMultiplier={multiplier}
          volume={volume}
          setVolume={setVolume}
        ></AudioController>
        <Flex alignContent={"center"}>
          {onExit && <ExitButton onExit={onExit} />}
          <FullScreenButton />
        </Flex>
      </Flex>
    </Grid>
  );
}
