import { Button, Flex, View } from "@aws-amplify/ui-react";
import * as React from "react";

import { LessonContext } from "../../LessonContextProvider";
import useEffectOnce from "../../../../../hooks/useEffectOnce";

import RaisedButton from "../../../../../components/RaisedButton";
import SparkleIcon from "../../../../../components/icons/SparkleIcon";

import GeneratedSlideCollection from "./GeneratedSlideCollection";
import { Slide } from "../../../types/slideTypes";
import useUpdateEffect from "../../../../../hooks/useUpdateEffect";

import useDiscussionQuestionSlideGenerator from "./hooks/useDiscussionQuestionSlideGenerator";
import { SlidesContext } from "../../SlidesContextProvider";
import { useBatchAddSlides } from "../../../hooks/useBatchAddSlides";

export interface IDiscussionQuestionGeneratorProps {
  useLessonPlan: boolean;
  useExistingTopic: boolean;
  topicLocal: string;
}

export default function DiscussionQuestionGenerator(
  props: IDiscussionQuestionGeneratorProps
) {
  const { useLessonPlan, useExistingTopic, topicLocal } = props;

  const { lesson } = React.useContext(LessonContext);

  const [slides, setSlides] = React.useState<Slide[]>([]);

  // use DiscussionQuestionsSlideGenerator
  const {
    connect: connectDiscussionQuestions,
    loading: loadingDiscussionQuestions,
    slides: slidesDiscussionQuestions,
  } = useDiscussionQuestionSlideGenerator({
    useLessonPlan,
    useExistingTopic,
    lesson,
    topicLocal,
    n: 6,
  });

  const connect = React.useCallback(() => {
    connectDiscussionQuestions();
  }, []);

  const loading = React.useMemo(
    () => loadingDiscussionQuestions,
    [loadingDiscussionQuestions]
  );

  useEffectOnce(() => {
    connect();
  });

  useUpdateEffect(() => {
    setSlides([...slidesDiscussionQuestions]);
  }, [slidesDiscussionQuestions]);

  const addSlideMutation = useBatchAddSlides();
  const { slideIndex, setSlidePreview } = React.useContext(SlidesContext);
  const addAllSlides = () => {
    addSlideMutation.mutateAsync({ newSlides: slides, index: slideIndex + 1 });

    setSlides([]);
    setSlidePreview(undefined);
  };

  return (
    <View>
      <Flex justifyContent={"center"}>
        <RaisedButton
          fontSize={"small"}
          variation={"primary"}
          size="small"
          textAlign={"center"}
          color={"white"}
          backgroundColor={"orange.60"}
          gap="small"
          isLoading={loading}
          loadingText={"Generating..."}
          onClick={connect}
          data-attr="create-lesson-slides"
          data-ph-capture-attribute-type={
            "regenerate-discussion-question-slides"
          }
          data-ph-capture-attribute-topic={lesson.topic.toLowerCase()}
          data-ph-capture-attribute-grade-level={lesson.gradeLevel}
          data-ph-capture-attribute-reading-level={lesson.readingLevel}
          data-ph-capture-attribute-language={lesson.lang}
        >
          <SparkleIcon />
          Regenerate
        </RaisedButton>
        <Button
          fontSize={"small"}
          variation={"link"}
          size="small"
          color={"neutral.80"}
          textAlign={"center"}
          textDecoration={"underline"}
          hidden={loading || slides.length === 0}
          onClick={addAllSlides}
          data-attr="add-lesson-slides"
          data-ph-capture-attribute-type={"add-all-discussion-question-slides"}
          data-ph-capture-attribute-topic={lesson.topic.toLowerCase()}
          data-ph-capture-attribute-grade-level={lesson.gradeLevel}
          data-ph-capture-attribute-reading-level={lesson.readingLevel}
          data-ph-capture-attribute-language={lesson.lang}
        >
          Add All {slides.length} Slides
        </Button>
      </Flex>

      <GeneratedSlideCollection
        slides={slides}
        setSlides={setSlides}
        loading={loading}
      />
    </View>
  );
}
