import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";

export default function useArray<T>(defaultValue: T[] = []): {
  array: T[];
  set: Dispatch<SetStateAction<T[]>>;
  push: (element: T) => void;
  pop: () => void;
  filter: (callback: (value: T, index: number, array: T[]) => unknown) => T[];
  update: (index: number, newElement: T) => void;
  remove: (index: number) => void;
  removeValue: (value: T) => void;
  removeFiltered: (
    callback: (value: T, index: number, array: T[]) => unknown
  ) => void;
  clear: () => void;
  indicesOf: (
    callback: (value: T, index: number, array: T[]) => unknown
  ) => number[];
  insert: (index: number, element: T) => void;
} {
  const [array, setArray] = useState<T[]>(defaultValue);

  const arrayRef = useRef<T[]>(defaultValue);

  useEffect(() => {
    arrayRef.current = array;
  }, [array]);

  function push(element: T) {
    setArray((a) => [...a, element]);
  }
  function pop() {
    setArray((a) => [...a.slice(0, a.length - 1)]);
  }

  function filter(callback: (value: T, index: number, array: T[]) => unknown) {
    const filteredArray = array.filter(callback);
    return filteredArray;
  }

  function update(index: number, newElement: T) {
    setArray((a) => [
      ...a.slice(0, index),
      newElement,
      ...a.slice(index + 1, a.length),
    ]);
  }

  function removeIndex(index: number) {
    setArray((a) => [...a.slice(0, index), ...a.slice(index + 1, a.length)]);
  }

  function removeFiltered(
    callback: (value: T, index: number, array: T[]) => unknown
  ) {

    const indices = indicesOf(callback);

    indices.forEach((index) => removeIndex(index));
  }

  function removeValue(value: T) {
    setArray((a) => a.filter((v) => v !== value));
  }

  function clear() {
    setArray([]);
  }

  function indicesOf(
    callback: (value: T, index: number, array: T[]) => unknown
  ) {
    let indices: number[] = [];
    const elements = arrayRef.current.filter(callback);
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      indices.push(arrayRef.current.indexOf(element));
    }
    return indices;
  }

  function insert(index: number, element: T) {
    setArray((a) => [
      ...a.slice(0, index),
      element,
      ...a.slice(index, a.length),
    ]);
  }

  return {
    array,
    set: setArray,
    push,
    pop,
    filter,
    update,
    remove: removeIndex,
    removeValue,
    removeFiltered,
    clear,
    indicesOf,
    insert,
  };
}
