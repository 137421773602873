/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateQuestionInput = {
  id?: string | null,
  image?: S3ObjectProtectedInput | null,
  audio?: S3ObjectProtectedInput | null,
  readAloudText?: string | null,
  text: string,
  type?: QuestionType | null,
  solution: string,
  answers: Array< string | null >,
  correctIndices: Array< number | null >,
};

export type S3ObjectProtectedInput = {
  key: string,
  identityId: string,
  alt?: string | null,
};

export enum QuestionType {
  SINGLE = "SINGLE",
  MULTI = "MULTI",
  TRUEFALSE = "TRUEFALSE",
  TYPED = "TYPED",
  FILLINBLANK = "FILLINBLANK",
  MATCH = "MATCH",
  SINGLE_SELECT = "SINGLE_SELECT",
  MULTIPLE_SELECT = "MULTIPLE_SELECT",
  TRUE_OR_FALSE = "TRUE_OR_FALSE",
  TYPED_ANSWER = "TYPED_ANSWER",
}


export type ModelQuestionConditionInput = {
  readAloudText?: ModelStringInput | null,
  text?: ModelStringInput | null,
  type?: ModelQuestionTypeInput | null,
  solution?: ModelStringInput | null,
  answers?: ModelStringInput | null,
  correctIndices?: ModelIntInput | null,
  and?: Array< ModelQuestionConditionInput | null > | null,
  or?: Array< ModelQuestionConditionInput | null > | null,
  not?: ModelQuestionConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelQuestionTypeInput = {
  eq?: QuestionType | null,
  ne?: QuestionType | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Question = {
  __typename: "Question",
  id: string,
  image?: S3ObjectProtected | null,
  audio?: S3ObjectProtected | null,
  readAloudText?: string | null,
  text: string,
  type?: QuestionType | null,
  solution: string,
  answers: Array< string | null >,
  correctIndices: Array< number | null >,
  Quizzes?: ModelQuizQuestionConnection | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type S3ObjectProtected = {
  __typename: "S3ObjectProtected",
  key: string,
  identityId: string,
  alt?: string | null,
};

export type ModelQuizQuestionConnection = {
  __typename: "ModelQuizQuestionConnection",
  items:  Array<QuizQuestion | null >,
  nextToken?: string | null,
};

export type QuizQuestion = {
  __typename: "QuizQuestion",
  id: string,
  quizId: string,
  questionId: string,
  quiz?: Quiz | null,
  question?: Question | null,
  sortIndex?: number | null,
  updatedAt: string,
  createdAt: string,
  owner?: string | null,
};

export type Quiz = {
  __typename: "Quiz",
  id: string,
  creator?: string | null,
  image?: S3ObjectProtected | null,
  allowReadTo?: string | null,
  title: string,
  description: string,
  tags: Array< string | null >,
  Questions?: ModelQuizQuestionConnection | null,
  lang?: string | null,
  updatedAt: string,
  createdAt: string,
};

export type UpdateQuestionInput = {
  id: string,
  image?: S3ObjectProtectedInput | null,
  audio?: S3ObjectProtectedInput | null,
  readAloudText?: string | null,
  text?: string | null,
  type?: QuestionType | null,
  solution?: string | null,
  answers?: Array< string | null > | null,
  correctIndices?: Array< number | null > | null,
};

export type DeleteQuestionInput = {
  id: string,
};

export type CreateQuizInput = {
  id?: string | null,
  creator?: string | null,
  image?: S3ObjectProtectedInput | null,
  allowReadTo?: string | null,
  title: string,
  description: string,
  tags: Array< string | null >,
  lang?: string | null,
  updatedAt?: string | null,
  createdAt?: string | null,
};

export type ModelQuizConditionInput = {
  creator?: ModelStringInput | null,
  allowReadTo?: ModelStringInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  lang?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelQuizConditionInput | null > | null,
  or?: Array< ModelQuizConditionInput | null > | null,
  not?: ModelQuizConditionInput | null,
};

export type UpdateQuizInput = {
  id: string,
  creator?: string | null,
  image?: S3ObjectProtectedInput | null,
  allowReadTo?: string | null,
  title?: string | null,
  description?: string | null,
  tags?: Array< string | null > | null,
  lang?: string | null,
  updatedAt?: string | null,
  createdAt?: string | null,
};

export type DeleteQuizInput = {
  id: string,
};

export type CreateQuizQuestionInput = {
  id?: string | null,
  quizId: string,
  questionId: string,
  sortIndex?: number | null,
  updatedAt?: string | null,
  createdAt?: string | null,
};

export type ModelQuizQuestionConditionInput = {
  quizId?: ModelIDInput | null,
  questionId?: ModelIDInput | null,
  sortIndex?: ModelIntInput | null,
  updatedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelQuizQuestionConditionInput | null > | null,
  or?: Array< ModelQuizQuestionConditionInput | null > | null,
  not?: ModelQuizQuestionConditionInput | null,
  owner?: ModelStringInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type UpdateQuizQuestionInput = {
  id: string,
  quizId?: string | null,
  questionId?: string | null,
  sortIndex?: number | null,
  updatedAt?: string | null,
  createdAt?: string | null,
};

export type DeleteQuizQuestionInput = {
  id: string,
};

export type ModelQuestionFilterInput = {
  id?: ModelIDInput | null,
  readAloudText?: ModelStringInput | null,
  text?: ModelStringInput | null,
  type?: ModelQuestionTypeInput | null,
  solution?: ModelStringInput | null,
  answers?: ModelStringInput | null,
  correctIndices?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelQuestionFilterInput | null > | null,
  or?: Array< ModelQuestionFilterInput | null > | null,
  not?: ModelQuestionFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelQuestionConnection = {
  __typename: "ModelQuestionConnection",
  items:  Array<Question | null >,
  nextToken?: string | null,
};

export type ModelQuizFilterInput = {
  id?: ModelIDInput | null,
  creator?: ModelStringInput | null,
  allowReadTo?: ModelStringInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  lang?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelQuizFilterInput | null > | null,
  or?: Array< ModelQuizFilterInput | null > | null,
  not?: ModelQuizFilterInput | null,
};

export type ModelQuizConnection = {
  __typename: "ModelQuizConnection",
  items:  Array<Quiz | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type SearchableQuizFilterInput = {
  id?: SearchableIDFilterInput | null,
  creator?: SearchableStringFilterInput | null,
  allowReadTo?: SearchableStringFilterInput | null,
  title?: SearchableStringFilterInput | null,
  description?: SearchableStringFilterInput | null,
  tags?: SearchableStringFilterInput | null,
  lang?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableQuizFilterInput | null > | null,
  or?: Array< SearchableQuizFilterInput | null > | null,
  not?: SearchableQuizFilterInput | null,
};

export type SearchableIDFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableStringFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableQuizSortInput = {
  field?: SearchableQuizSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableQuizSortableFields {
  id = "id",
  creator = "creator",
  allowReadTo = "allowReadTo",
  title = "title",
  description = "description",
  tags = "tags",
  lang = "lang",
  updatedAt = "updatedAt",
  createdAt = "createdAt",
}


export enum SearchableSortDirection {
  asc = "asc",
  desc = "desc",
}


export type SearchableQuizAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableQuizAggregateField,
};

export enum SearchableAggregateType {
  terms = "terms",
  avg = "avg",
  min = "min",
  max = "max",
  sum = "sum",
  cardinality = "cardinality",
}


export enum SearchableQuizAggregateField {
  id = "id",
  creator = "creator",
  allowReadTo = "allowReadTo",
  title = "title",
  description = "description",
  tags = "tags",
  lang = "lang",
  updatedAt = "updatedAt",
  createdAt = "createdAt",
}


export type SearchableQuizConnection = {
  __typename: "SearchableQuizConnection",
  items:  Array<Quiz | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type SearchableAggregateResult = {
  __typename: "SearchableAggregateResult",
  name: string,
  result?: SearchableAggregateGenericResult | null,
};

export type SearchableAggregateGenericResult = SearchableAggregateScalarResult | SearchableAggregateBucketResult


export type SearchableAggregateScalarResult = {
  __typename: "SearchableAggregateScalarResult",
  value: number,
};

export type SearchableAggregateBucketResult = {
  __typename: "SearchableAggregateBucketResult",
  buckets?:  Array<SearchableAggregateBucketResultItem | null > | null,
};

export type SearchableAggregateBucketResultItem = {
  __typename: "SearchableAggregateBucketResultItem",
  key: string,
  doc_count: number,
};

export type ModelQuizQuestionFilterInput = {
  id?: ModelIDInput | null,
  quizId?: ModelIDInput | null,
  questionId?: ModelIDInput | null,
  sortIndex?: ModelIntInput | null,
  updatedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelQuizQuestionFilterInput | null > | null,
  or?: Array< ModelQuizQuestionFilterInput | null > | null,
  not?: ModelQuizQuestionFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelSubscriptionQuestionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  readAloudText?: ModelSubscriptionStringInput | null,
  text?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  solution?: ModelSubscriptionStringInput | null,
  answers?: ModelSubscriptionStringInput | null,
  correctIndices?: ModelSubscriptionIntInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionQuestionFilterInput | null > | null,
  or?: Array< ModelSubscriptionQuestionFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionQuizFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  tags?: ModelSubscriptionStringInput | null,
  lang?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionQuizFilterInput | null > | null,
  or?: Array< ModelSubscriptionQuizFilterInput | null > | null,
  creator?: ModelStringInput | null,
};

export type ModelSubscriptionQuizQuestionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  quizId?: ModelSubscriptionIDInput | null,
  questionId?: ModelSubscriptionIDInput | null,
  sortIndex?: ModelSubscriptionIntInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionQuizQuestionFilterInput | null > | null,
  or?: Array< ModelSubscriptionQuizQuestionFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type CreateQuestionMutationVariables = {
  input: CreateQuestionInput,
  condition?: ModelQuestionConditionInput | null,
};

export type CreateQuestionMutation = {
  createQuestion?:  {
    __typename: "Question",
    id: string,
    image?:  {
      __typename: "S3ObjectProtected",
      key: string,
      identityId: string,
      alt?: string | null,
    } | null,
    audio?:  {
      __typename: "S3ObjectProtected",
      key: string,
      identityId: string,
      alt?: string | null,
    } | null,
    readAloudText?: string | null,
    text: string,
    type?: QuestionType | null,
    solution: string,
    answers: Array< string | null >,
    correctIndices: Array< number | null >,
    Quizzes?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizId: string,
        questionId: string,
        quiz?:  {
          __typename: "Quiz",
          id: string,
          creator?: string | null,
          allowReadTo?: string | null,
          title: string,
          description: string,
          tags: Array< string | null >,
          lang?: string | null,
          updatedAt: string,
          createdAt: string,
        } | null,
        question?:  {
          __typename: "Question",
          id: string,
          readAloudText?: string | null,
          text: string,
          type?: QuestionType | null,
          solution: string,
          answers: Array< string | null >,
          correctIndices: Array< number | null >,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        sortIndex?: number | null,
        updatedAt: string,
        createdAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateQuestionMutationVariables = {
  input: UpdateQuestionInput,
  condition?: ModelQuestionConditionInput | null,
};

export type UpdateQuestionMutation = {
  updateQuestion?:  {
    __typename: "Question",
    id: string,
    image?:  {
      __typename: "S3ObjectProtected",
      key: string,
      identityId: string,
      alt?: string | null,
    } | null,
    audio?:  {
      __typename: "S3ObjectProtected",
      key: string,
      identityId: string,
      alt?: string | null,
    } | null,
    readAloudText?: string | null,
    text: string,
    type?: QuestionType | null,
    solution: string,
    answers: Array< string | null >,
    correctIndices: Array< number | null >,
    Quizzes?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizId: string,
        questionId: string,
        quiz?:  {
          __typename: "Quiz",
          id: string,
          creator?: string | null,
          allowReadTo?: string | null,
          title: string,
          description: string,
          tags: Array< string | null >,
          lang?: string | null,
          updatedAt: string,
          createdAt: string,
        } | null,
        question?:  {
          __typename: "Question",
          id: string,
          readAloudText?: string | null,
          text: string,
          type?: QuestionType | null,
          solution: string,
          answers: Array< string | null >,
          correctIndices: Array< number | null >,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        sortIndex?: number | null,
        updatedAt: string,
        createdAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteQuestionMutationVariables = {
  input: DeleteQuestionInput,
  condition?: ModelQuestionConditionInput | null,
};

export type DeleteQuestionMutation = {
  deleteQuestion?:  {
    __typename: "Question",
    id: string,
    image?:  {
      __typename: "S3ObjectProtected",
      key: string,
      identityId: string,
      alt?: string | null,
    } | null,
    audio?:  {
      __typename: "S3ObjectProtected",
      key: string,
      identityId: string,
      alt?: string | null,
    } | null,
    readAloudText?: string | null,
    text: string,
    type?: QuestionType | null,
    solution: string,
    answers: Array< string | null >,
    correctIndices: Array< number | null >,
    Quizzes?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizId: string,
        questionId: string,
        quiz?:  {
          __typename: "Quiz",
          id: string,
          creator?: string | null,
          allowReadTo?: string | null,
          title: string,
          description: string,
          tags: Array< string | null >,
          lang?: string | null,
          updatedAt: string,
          createdAt: string,
        } | null,
        question?:  {
          __typename: "Question",
          id: string,
          readAloudText?: string | null,
          text: string,
          type?: QuestionType | null,
          solution: string,
          answers: Array< string | null >,
          correctIndices: Array< number | null >,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        sortIndex?: number | null,
        updatedAt: string,
        createdAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateQuizMutationVariables = {
  input: CreateQuizInput,
  condition?: ModelQuizConditionInput | null,
};

export type CreateQuizMutation = {
  createQuiz?:  {
    __typename: "Quiz",
    id: string,
    creator?: string | null,
    image?:  {
      __typename: "S3ObjectProtected",
      key: string,
      identityId: string,
      alt?: string | null,
    } | null,
    allowReadTo?: string | null,
    title: string,
    description: string,
    tags: Array< string | null >,
    Questions?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizId: string,
        questionId: string,
        quiz?:  {
          __typename: "Quiz",
          id: string,
          creator?: string | null,
          allowReadTo?: string | null,
          title: string,
          description: string,
          tags: Array< string | null >,
          lang?: string | null,
          updatedAt: string,
          createdAt: string,
        } | null,
        question?:  {
          __typename: "Question",
          id: string,
          readAloudText?: string | null,
          text: string,
          type?: QuestionType | null,
          solution: string,
          answers: Array< string | null >,
          correctIndices: Array< number | null >,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        sortIndex?: number | null,
        updatedAt: string,
        createdAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    lang?: string | null,
    updatedAt: string,
    createdAt: string,
  } | null,
};

export type UpdateQuizMutationVariables = {
  input: UpdateQuizInput,
  condition?: ModelQuizConditionInput | null,
};

export type UpdateQuizMutation = {
  updateQuiz?:  {
    __typename: "Quiz",
    id: string,
    creator?: string | null,
    image?:  {
      __typename: "S3ObjectProtected",
      key: string,
      identityId: string,
      alt?: string | null,
    } | null,
    allowReadTo?: string | null,
    title: string,
    description: string,
    tags: Array< string | null >,
    Questions?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizId: string,
        questionId: string,
        quiz?:  {
          __typename: "Quiz",
          id: string,
          creator?: string | null,
          allowReadTo?: string | null,
          title: string,
          description: string,
          tags: Array< string | null >,
          lang?: string | null,
          updatedAt: string,
          createdAt: string,
        } | null,
        question?:  {
          __typename: "Question",
          id: string,
          readAloudText?: string | null,
          text: string,
          type?: QuestionType | null,
          solution: string,
          answers: Array< string | null >,
          correctIndices: Array< number | null >,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        sortIndex?: number | null,
        updatedAt: string,
        createdAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    lang?: string | null,
    updatedAt: string,
    createdAt: string,
  } | null,
};

export type DeleteQuizMutationVariables = {
  input: DeleteQuizInput,
  condition?: ModelQuizConditionInput | null,
};

export type DeleteQuizMutation = {
  deleteQuiz?:  {
    __typename: "Quiz",
    id: string,
    creator?: string | null,
    image?:  {
      __typename: "S3ObjectProtected",
      key: string,
      identityId: string,
      alt?: string | null,
    } | null,
    allowReadTo?: string | null,
    title: string,
    description: string,
    tags: Array< string | null >,
    Questions?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizId: string,
        questionId: string,
        quiz?:  {
          __typename: "Quiz",
          id: string,
          creator?: string | null,
          allowReadTo?: string | null,
          title: string,
          description: string,
          tags: Array< string | null >,
          lang?: string | null,
          updatedAt: string,
          createdAt: string,
        } | null,
        question?:  {
          __typename: "Question",
          id: string,
          readAloudText?: string | null,
          text: string,
          type?: QuestionType | null,
          solution: string,
          answers: Array< string | null >,
          correctIndices: Array< number | null >,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        sortIndex?: number | null,
        updatedAt: string,
        createdAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    lang?: string | null,
    updatedAt: string,
    createdAt: string,
  } | null,
};

export type CreateQuizQuestionMutationVariables = {
  input: CreateQuizQuestionInput,
  condition?: ModelQuizQuestionConditionInput | null,
};

export type CreateQuizQuestionMutation = {
  createQuizQuestion?:  {
    __typename: "QuizQuestion",
    id: string,
    quizId: string,
    questionId: string,
    quiz?:  {
      __typename: "Quiz",
      id: string,
      creator?: string | null,
      image?:  {
        __typename: "S3ObjectProtected",
        key: string,
        identityId: string,
        alt?: string | null,
      } | null,
      allowReadTo?: string | null,
      title: string,
      description: string,
      tags: Array< string | null >,
      Questions?:  {
        __typename: "ModelQuizQuestionConnection",
        items:  Array< {
          __typename: "QuizQuestion",
          id: string,
          quizId: string,
          questionId: string,
          sortIndex?: number | null,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      lang?: string | null,
      updatedAt: string,
      createdAt: string,
    } | null,
    question?:  {
      __typename: "Question",
      id: string,
      image?:  {
        __typename: "S3ObjectProtected",
        key: string,
        identityId: string,
        alt?: string | null,
      } | null,
      audio?:  {
        __typename: "S3ObjectProtected",
        key: string,
        identityId: string,
        alt?: string | null,
      } | null,
      readAloudText?: string | null,
      text: string,
      type?: QuestionType | null,
      solution: string,
      answers: Array< string | null >,
      correctIndices: Array< number | null >,
      Quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        items:  Array< {
          __typename: "QuizQuestion",
          id: string,
          quizId: string,
          questionId: string,
          sortIndex?: number | null,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    sortIndex?: number | null,
    updatedAt: string,
    createdAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateQuizQuestionMutationVariables = {
  input: UpdateQuizQuestionInput,
  condition?: ModelQuizQuestionConditionInput | null,
};

export type UpdateQuizQuestionMutation = {
  updateQuizQuestion?:  {
    __typename: "QuizQuestion",
    id: string,
    quizId: string,
    questionId: string,
    quiz?:  {
      __typename: "Quiz",
      id: string,
      creator?: string | null,
      image?:  {
        __typename: "S3ObjectProtected",
        key: string,
        identityId: string,
        alt?: string | null,
      } | null,
      allowReadTo?: string | null,
      title: string,
      description: string,
      tags: Array< string | null >,
      Questions?:  {
        __typename: "ModelQuizQuestionConnection",
        items:  Array< {
          __typename: "QuizQuestion",
          id: string,
          quizId: string,
          questionId: string,
          sortIndex?: number | null,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      lang?: string | null,
      updatedAt: string,
      createdAt: string,
    } | null,
    question?:  {
      __typename: "Question",
      id: string,
      image?:  {
        __typename: "S3ObjectProtected",
        key: string,
        identityId: string,
        alt?: string | null,
      } | null,
      audio?:  {
        __typename: "S3ObjectProtected",
        key: string,
        identityId: string,
        alt?: string | null,
      } | null,
      readAloudText?: string | null,
      text: string,
      type?: QuestionType | null,
      solution: string,
      answers: Array< string | null >,
      correctIndices: Array< number | null >,
      Quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        items:  Array< {
          __typename: "QuizQuestion",
          id: string,
          quizId: string,
          questionId: string,
          sortIndex?: number | null,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    sortIndex?: number | null,
    updatedAt: string,
    createdAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteQuizQuestionMutationVariables = {
  input: DeleteQuizQuestionInput,
  condition?: ModelQuizQuestionConditionInput | null,
};

export type DeleteQuizQuestionMutation = {
  deleteQuizQuestion?:  {
    __typename: "QuizQuestion",
    id: string,
    quizId: string,
    questionId: string,
    quiz?:  {
      __typename: "Quiz",
      id: string,
      creator?: string | null,
      image?:  {
        __typename: "S3ObjectProtected",
        key: string,
        identityId: string,
        alt?: string | null,
      } | null,
      allowReadTo?: string | null,
      title: string,
      description: string,
      tags: Array< string | null >,
      Questions?:  {
        __typename: "ModelQuizQuestionConnection",
        items:  Array< {
          __typename: "QuizQuestion",
          id: string,
          quizId: string,
          questionId: string,
          sortIndex?: number | null,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      lang?: string | null,
      updatedAt: string,
      createdAt: string,
    } | null,
    question?:  {
      __typename: "Question",
      id: string,
      image?:  {
        __typename: "S3ObjectProtected",
        key: string,
        identityId: string,
        alt?: string | null,
      } | null,
      audio?:  {
        __typename: "S3ObjectProtected",
        key: string,
        identityId: string,
        alt?: string | null,
      } | null,
      readAloudText?: string | null,
      text: string,
      type?: QuestionType | null,
      solution: string,
      answers: Array< string | null >,
      correctIndices: Array< number | null >,
      Quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        items:  Array< {
          __typename: "QuizQuestion",
          id: string,
          quizId: string,
          questionId: string,
          sortIndex?: number | null,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    sortIndex?: number | null,
    updatedAt: string,
    createdAt: string,
    owner?: string | null,
  } | null,
};

export type GetQuestionQueryVariables = {
  id: string,
};

export type GetQuestionQuery = {
  getQuestion?:  {
    __typename: "Question",
    id: string,
    image?:  {
      __typename: "S3ObjectProtected",
      key: string,
      identityId: string,
      alt?: string | null,
    } | null,
    audio?:  {
      __typename: "S3ObjectProtected",
      key: string,
      identityId: string,
      alt?: string | null,
    } | null,
    readAloudText?: string | null,
    text: string,
    type?: QuestionType | null,
    solution: string,
    answers: Array< string | null >,
    correctIndices: Array< number | null >,
    Quizzes?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizId: string,
        questionId: string,
        quiz?:  {
          __typename: "Quiz",
          id: string,
          creator?: string | null,
          allowReadTo?: string | null,
          title: string,
          description: string,
          tags: Array< string | null >,
          lang?: string | null,
          updatedAt: string,
          createdAt: string,
        } | null,
        question?:  {
          __typename: "Question",
          id: string,
          readAloudText?: string | null,
          text: string,
          type?: QuestionType | null,
          solution: string,
          answers: Array< string | null >,
          correctIndices: Array< number | null >,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        sortIndex?: number | null,
        updatedAt: string,
        createdAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListQuestionsQueryVariables = {
  filter?: ModelQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuestionsQuery = {
  listQuestions?:  {
    __typename: "ModelQuestionConnection",
    items:  Array< {
      __typename: "Question",
      id: string,
      image?:  {
        __typename: "S3ObjectProtected",
        key: string,
        identityId: string,
        alt?: string | null,
      } | null,
      audio?:  {
        __typename: "S3ObjectProtected",
        key: string,
        identityId: string,
        alt?: string | null,
      } | null,
      readAloudText?: string | null,
      text: string,
      type?: QuestionType | null,
      solution: string,
      answers: Array< string | null >,
      correctIndices: Array< number | null >,
      Quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        items:  Array< {
          __typename: "QuizQuestion",
          id: string,
          quizId: string,
          questionId: string,
          sortIndex?: number | null,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetQuizQueryVariables = {
  id: string,
};

export type GetQuizQuery = {
  getQuiz?:  {
    __typename: "Quiz",
    id: string,
    creator?: string | null,
    image?:  {
      __typename: "S3ObjectProtected",
      key: string,
      identityId: string,
      alt?: string | null,
    } | null,
    allowReadTo?: string | null,
    title: string,
    description: string,
    tags: Array< string | null >,
    Questions?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizId: string,
        questionId: string,
        quiz?:  {
          __typename: "Quiz",
          id: string,
          creator?: string | null,
          allowReadTo?: string | null,
          title: string,
          description: string,
          tags: Array< string | null >,
          lang?: string | null,
          updatedAt: string,
          createdAt: string,
        } | null,
        question?:  {
          __typename: "Question",
          id: string,
          readAloudText?: string | null,
          text: string,
          type?: QuestionType | null,
          solution: string,
          answers: Array< string | null >,
          correctIndices: Array< number | null >,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        sortIndex?: number | null,
        updatedAt: string,
        createdAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    lang?: string | null,
    updatedAt: string,
    createdAt: string,
  } | null,
};

export type ListQuizzesQueryVariables = {
  filter?: ModelQuizFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuizzesQuery = {
  listQuizzes?:  {
    __typename: "ModelQuizConnection",
    items:  Array< {
      __typename: "Quiz",
      id: string,
      creator?: string | null,
      image?:  {
        __typename: "S3ObjectProtected",
        key: string,
        identityId: string,
        alt?: string | null,
      } | null,
      allowReadTo?: string | null,
      title: string,
      description: string,
      tags: Array< string | null >,
      Questions?:  {
        __typename: "ModelQuizQuestionConnection",
        items:  Array< {
          __typename: "QuizQuestion",
          id: string,
          quizId: string,
          questionId: string,
          sortIndex?: number | null,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      lang?: string | null,
      updatedAt: string,
      createdAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type QuizzesByCreatorQueryVariables = {
  creator: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuizFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type QuizzesByCreatorQuery = {
  quizzesByCreator?:  {
    __typename: "ModelQuizConnection",
    items:  Array< {
      __typename: "Quiz",
      id: string,
      creator?: string | null,
      image?:  {
        __typename: "S3ObjectProtected",
        key: string,
        identityId: string,
        alt?: string | null,
      } | null,
      allowReadTo?: string | null,
      title: string,
      description: string,
      tags: Array< string | null >,
      Questions?:  {
        __typename: "ModelQuizQuestionConnection",
        items:  Array< {
          __typename: "QuizQuestion",
          id: string,
          quizId: string,
          questionId: string,
          sortIndex?: number | null,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      lang?: string | null,
      updatedAt: string,
      createdAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type QuizzesByAllowReadToAndCreatedAtQueryVariables = {
  allowReadTo: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuizFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type QuizzesByAllowReadToAndCreatedAtQuery = {
  quizzesByAllowReadToAndCreatedAt?:  {
    __typename: "ModelQuizConnection",
    items:  Array< {
      __typename: "Quiz",
      id: string,
      creator?: string | null,
      image?:  {
        __typename: "S3ObjectProtected",
        key: string,
        identityId: string,
        alt?: string | null,
      } | null,
      allowReadTo?: string | null,
      title: string,
      description: string,
      tags: Array< string | null >,
      Questions?:  {
        __typename: "ModelQuizQuestionConnection",
        items:  Array< {
          __typename: "QuizQuestion",
          id: string,
          quizId: string,
          questionId: string,
          sortIndex?: number | null,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      lang?: string | null,
      updatedAt: string,
      createdAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchQuizzesQueryVariables = {
  filter?: SearchableQuizFilterInput | null,
  sort?: Array< SearchableQuizSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableQuizAggregationInput | null > | null,
};

export type SearchQuizzesQuery = {
  searchQuizzes?:  {
    __typename: "SearchableQuizConnection",
    items:  Array< {
      __typename: "Quiz",
      id: string,
      creator?: string | null,
      image?:  {
        __typename: "S3ObjectProtected",
        key: string,
        identityId: string,
        alt?: string | null,
      } | null,
      allowReadTo?: string | null,
      title: string,
      description: string,
      tags: Array< string | null >,
      Questions?:  {
        __typename: "ModelQuizQuestionConnection",
        items:  Array< {
          __typename: "QuizQuestion",
          id: string,
          quizId: string,
          questionId: string,
          sortIndex?: number | null,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      lang?: string | null,
      updatedAt: string,
      createdAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetQuizQuestionQueryVariables = {
  id: string,
};

export type GetQuizQuestionQuery = {
  getQuizQuestion?:  {
    __typename: "QuizQuestion",
    id: string,
    quizId: string,
    questionId: string,
    quiz?:  {
      __typename: "Quiz",
      id: string,
      creator?: string | null,
      image?:  {
        __typename: "S3ObjectProtected",
        key: string,
        identityId: string,
        alt?: string | null,
      } | null,
      allowReadTo?: string | null,
      title: string,
      description: string,
      tags: Array< string | null >,
      Questions?:  {
        __typename: "ModelQuizQuestionConnection",
        items:  Array< {
          __typename: "QuizQuestion",
          id: string,
          quizId: string,
          questionId: string,
          sortIndex?: number | null,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      lang?: string | null,
      updatedAt: string,
      createdAt: string,
    } | null,
    question?:  {
      __typename: "Question",
      id: string,
      image?:  {
        __typename: "S3ObjectProtected",
        key: string,
        identityId: string,
        alt?: string | null,
      } | null,
      audio?:  {
        __typename: "S3ObjectProtected",
        key: string,
        identityId: string,
        alt?: string | null,
      } | null,
      readAloudText?: string | null,
      text: string,
      type?: QuestionType | null,
      solution: string,
      answers: Array< string | null >,
      correctIndices: Array< number | null >,
      Quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        items:  Array< {
          __typename: "QuizQuestion",
          id: string,
          quizId: string,
          questionId: string,
          sortIndex?: number | null,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    sortIndex?: number | null,
    updatedAt: string,
    createdAt: string,
    owner?: string | null,
  } | null,
};

export type ListQuizQuestionsQueryVariables = {
  filter?: ModelQuizQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuizQuestionsQuery = {
  listQuizQuestions?:  {
    __typename: "ModelQuizQuestionConnection",
    items:  Array< {
      __typename: "QuizQuestion",
      id: string,
      quizId: string,
      questionId: string,
      quiz?:  {
        __typename: "Quiz",
        id: string,
        creator?: string | null,
        image?:  {
          __typename: "S3ObjectProtected",
          key: string,
          identityId: string,
          alt?: string | null,
        } | null,
        allowReadTo?: string | null,
        title: string,
        description: string,
        tags: Array< string | null >,
        Questions?:  {
          __typename: "ModelQuizQuestionConnection",
          nextToken?: string | null,
        } | null,
        lang?: string | null,
        updatedAt: string,
        createdAt: string,
      } | null,
      question?:  {
        __typename: "Question",
        id: string,
        image?:  {
          __typename: "S3ObjectProtected",
          key: string,
          identityId: string,
          alt?: string | null,
        } | null,
        audio?:  {
          __typename: "S3ObjectProtected",
          key: string,
          identityId: string,
          alt?: string | null,
        } | null,
        readAloudText?: string | null,
        text: string,
        type?: QuestionType | null,
        solution: string,
        answers: Array< string | null >,
        correctIndices: Array< number | null >,
        Quizzes?:  {
          __typename: "ModelQuizQuestionConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      sortIndex?: number | null,
      updatedAt: string,
      createdAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type QuizQuestionsByQuizIdAndSortIndexQueryVariables = {
  quizId: string,
  sortIndex?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuizQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type QuizQuestionsByQuizIdAndSortIndexQuery = {
  quizQuestionsByQuizIdAndSortIndex?:  {
    __typename: "ModelQuizQuestionConnection",
    items:  Array< {
      __typename: "QuizQuestion",
      id: string,
      quizId: string,
      questionId: string,
      quiz?:  {
        __typename: "Quiz",
        id: string,
        creator?: string | null,
        image?:  {
          __typename: "S3ObjectProtected",
          key: string,
          identityId: string,
          alt?: string | null,
        } | null,
        allowReadTo?: string | null,
        title: string,
        description: string,
        tags: Array< string | null >,
        Questions?:  {
          __typename: "ModelQuizQuestionConnection",
          nextToken?: string | null,
        } | null,
        lang?: string | null,
        updatedAt: string,
        createdAt: string,
      } | null,
      question?:  {
        __typename: "Question",
        id: string,
        image?:  {
          __typename: "S3ObjectProtected",
          key: string,
          identityId: string,
          alt?: string | null,
        } | null,
        audio?:  {
          __typename: "S3ObjectProtected",
          key: string,
          identityId: string,
          alt?: string | null,
        } | null,
        readAloudText?: string | null,
        text: string,
        type?: QuestionType | null,
        solution: string,
        answers: Array< string | null >,
        correctIndices: Array< number | null >,
        Quizzes?:  {
          __typename: "ModelQuizQuestionConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      sortIndex?: number | null,
      updatedAt: string,
      createdAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type QuizQuestionsByQuestionIdAndUpdatedAtQueryVariables = {
  questionId: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuizQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type QuizQuestionsByQuestionIdAndUpdatedAtQuery = {
  quizQuestionsByQuestionIdAndUpdatedAt?:  {
    __typename: "ModelQuizQuestionConnection",
    items:  Array< {
      __typename: "QuizQuestion",
      id: string,
      quizId: string,
      questionId: string,
      quiz?:  {
        __typename: "Quiz",
        id: string,
        creator?: string | null,
        image?:  {
          __typename: "S3ObjectProtected",
          key: string,
          identityId: string,
          alt?: string | null,
        } | null,
        allowReadTo?: string | null,
        title: string,
        description: string,
        tags: Array< string | null >,
        Questions?:  {
          __typename: "ModelQuizQuestionConnection",
          nextToken?: string | null,
        } | null,
        lang?: string | null,
        updatedAt: string,
        createdAt: string,
      } | null,
      question?:  {
        __typename: "Question",
        id: string,
        image?:  {
          __typename: "S3ObjectProtected",
          key: string,
          identityId: string,
          alt?: string | null,
        } | null,
        audio?:  {
          __typename: "S3ObjectProtected",
          key: string,
          identityId: string,
          alt?: string | null,
        } | null,
        readAloudText?: string | null,
        text: string,
        type?: QuestionType | null,
        solution: string,
        answers: Array< string | null >,
        correctIndices: Array< number | null >,
        Quizzes?:  {
          __typename: "ModelQuizQuestionConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      sortIndex?: number | null,
      updatedAt: string,
      createdAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateQuestionSubscriptionVariables = {
  filter?: ModelSubscriptionQuestionFilterInput | null,
  owner?: string | null,
};

export type OnCreateQuestionSubscription = {
  onCreateQuestion?:  {
    __typename: "Question",
    id: string,
    image?:  {
      __typename: "S3ObjectProtected",
      key: string,
      identityId: string,
      alt?: string | null,
    } | null,
    audio?:  {
      __typename: "S3ObjectProtected",
      key: string,
      identityId: string,
      alt?: string | null,
    } | null,
    readAloudText?: string | null,
    text: string,
    type?: QuestionType | null,
    solution: string,
    answers: Array< string | null >,
    correctIndices: Array< number | null >,
    Quizzes?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizId: string,
        questionId: string,
        quiz?:  {
          __typename: "Quiz",
          id: string,
          creator?: string | null,
          allowReadTo?: string | null,
          title: string,
          description: string,
          tags: Array< string | null >,
          lang?: string | null,
          updatedAt: string,
          createdAt: string,
        } | null,
        question?:  {
          __typename: "Question",
          id: string,
          readAloudText?: string | null,
          text: string,
          type?: QuestionType | null,
          solution: string,
          answers: Array< string | null >,
          correctIndices: Array< number | null >,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        sortIndex?: number | null,
        updatedAt: string,
        createdAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateQuestionSubscriptionVariables = {
  filter?: ModelSubscriptionQuestionFilterInput | null,
  owner?: string | null,
};

export type OnUpdateQuestionSubscription = {
  onUpdateQuestion?:  {
    __typename: "Question",
    id: string,
    image?:  {
      __typename: "S3ObjectProtected",
      key: string,
      identityId: string,
      alt?: string | null,
    } | null,
    audio?:  {
      __typename: "S3ObjectProtected",
      key: string,
      identityId: string,
      alt?: string | null,
    } | null,
    readAloudText?: string | null,
    text: string,
    type?: QuestionType | null,
    solution: string,
    answers: Array< string | null >,
    correctIndices: Array< number | null >,
    Quizzes?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizId: string,
        questionId: string,
        quiz?:  {
          __typename: "Quiz",
          id: string,
          creator?: string | null,
          allowReadTo?: string | null,
          title: string,
          description: string,
          tags: Array< string | null >,
          lang?: string | null,
          updatedAt: string,
          createdAt: string,
        } | null,
        question?:  {
          __typename: "Question",
          id: string,
          readAloudText?: string | null,
          text: string,
          type?: QuestionType | null,
          solution: string,
          answers: Array< string | null >,
          correctIndices: Array< number | null >,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        sortIndex?: number | null,
        updatedAt: string,
        createdAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteQuestionSubscriptionVariables = {
  filter?: ModelSubscriptionQuestionFilterInput | null,
  owner?: string | null,
};

export type OnDeleteQuestionSubscription = {
  onDeleteQuestion?:  {
    __typename: "Question",
    id: string,
    image?:  {
      __typename: "S3ObjectProtected",
      key: string,
      identityId: string,
      alt?: string | null,
    } | null,
    audio?:  {
      __typename: "S3ObjectProtected",
      key: string,
      identityId: string,
      alt?: string | null,
    } | null,
    readAloudText?: string | null,
    text: string,
    type?: QuestionType | null,
    solution: string,
    answers: Array< string | null >,
    correctIndices: Array< number | null >,
    Quizzes?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizId: string,
        questionId: string,
        quiz?:  {
          __typename: "Quiz",
          id: string,
          creator?: string | null,
          allowReadTo?: string | null,
          title: string,
          description: string,
          tags: Array< string | null >,
          lang?: string | null,
          updatedAt: string,
          createdAt: string,
        } | null,
        question?:  {
          __typename: "Question",
          id: string,
          readAloudText?: string | null,
          text: string,
          type?: QuestionType | null,
          solution: string,
          answers: Array< string | null >,
          correctIndices: Array< number | null >,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        sortIndex?: number | null,
        updatedAt: string,
        createdAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateQuizSubscriptionVariables = {
  filter?: ModelSubscriptionQuizFilterInput | null,
  creator?: string | null,
};

export type OnCreateQuizSubscription = {
  onCreateQuiz?:  {
    __typename: "Quiz",
    id: string,
    creator?: string | null,
    image?:  {
      __typename: "S3ObjectProtected",
      key: string,
      identityId: string,
      alt?: string | null,
    } | null,
    allowReadTo?: string | null,
    title: string,
    description: string,
    tags: Array< string | null >,
    Questions?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizId: string,
        questionId: string,
        quiz?:  {
          __typename: "Quiz",
          id: string,
          creator?: string | null,
          allowReadTo?: string | null,
          title: string,
          description: string,
          tags: Array< string | null >,
          lang?: string | null,
          updatedAt: string,
          createdAt: string,
        } | null,
        question?:  {
          __typename: "Question",
          id: string,
          readAloudText?: string | null,
          text: string,
          type?: QuestionType | null,
          solution: string,
          answers: Array< string | null >,
          correctIndices: Array< number | null >,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        sortIndex?: number | null,
        updatedAt: string,
        createdAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    lang?: string | null,
    updatedAt: string,
    createdAt: string,
  } | null,
};

export type OnUpdateQuizSubscriptionVariables = {
  filter?: ModelSubscriptionQuizFilterInput | null,
  creator?: string | null,
};

export type OnUpdateQuizSubscription = {
  onUpdateQuiz?:  {
    __typename: "Quiz",
    id: string,
    creator?: string | null,
    image?:  {
      __typename: "S3ObjectProtected",
      key: string,
      identityId: string,
      alt?: string | null,
    } | null,
    allowReadTo?: string | null,
    title: string,
    description: string,
    tags: Array< string | null >,
    Questions?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizId: string,
        questionId: string,
        quiz?:  {
          __typename: "Quiz",
          id: string,
          creator?: string | null,
          allowReadTo?: string | null,
          title: string,
          description: string,
          tags: Array< string | null >,
          lang?: string | null,
          updatedAt: string,
          createdAt: string,
        } | null,
        question?:  {
          __typename: "Question",
          id: string,
          readAloudText?: string | null,
          text: string,
          type?: QuestionType | null,
          solution: string,
          answers: Array< string | null >,
          correctIndices: Array< number | null >,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        sortIndex?: number | null,
        updatedAt: string,
        createdAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    lang?: string | null,
    updatedAt: string,
    createdAt: string,
  } | null,
};

export type OnDeleteQuizSubscriptionVariables = {
  filter?: ModelSubscriptionQuizFilterInput | null,
  creator?: string | null,
};

export type OnDeleteQuizSubscription = {
  onDeleteQuiz?:  {
    __typename: "Quiz",
    id: string,
    creator?: string | null,
    image?:  {
      __typename: "S3ObjectProtected",
      key: string,
      identityId: string,
      alt?: string | null,
    } | null,
    allowReadTo?: string | null,
    title: string,
    description: string,
    tags: Array< string | null >,
    Questions?:  {
      __typename: "ModelQuizQuestionConnection",
      items:  Array< {
        __typename: "QuizQuestion",
        id: string,
        quizId: string,
        questionId: string,
        quiz?:  {
          __typename: "Quiz",
          id: string,
          creator?: string | null,
          allowReadTo?: string | null,
          title: string,
          description: string,
          tags: Array< string | null >,
          lang?: string | null,
          updatedAt: string,
          createdAt: string,
        } | null,
        question?:  {
          __typename: "Question",
          id: string,
          readAloudText?: string | null,
          text: string,
          type?: QuestionType | null,
          solution: string,
          answers: Array< string | null >,
          correctIndices: Array< number | null >,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        sortIndex?: number | null,
        updatedAt: string,
        createdAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    lang?: string | null,
    updatedAt: string,
    createdAt: string,
  } | null,
};

export type OnCreateQuizQuestionSubscriptionVariables = {
  filter?: ModelSubscriptionQuizQuestionFilterInput | null,
  owner?: string | null,
};

export type OnCreateQuizQuestionSubscription = {
  onCreateQuizQuestion?:  {
    __typename: "QuizQuestion",
    id: string,
    quizId: string,
    questionId: string,
    quiz?:  {
      __typename: "Quiz",
      id: string,
      creator?: string | null,
      image?:  {
        __typename: "S3ObjectProtected",
        key: string,
        identityId: string,
        alt?: string | null,
      } | null,
      allowReadTo?: string | null,
      title: string,
      description: string,
      tags: Array< string | null >,
      Questions?:  {
        __typename: "ModelQuizQuestionConnection",
        items:  Array< {
          __typename: "QuizQuestion",
          id: string,
          quizId: string,
          questionId: string,
          sortIndex?: number | null,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      lang?: string | null,
      updatedAt: string,
      createdAt: string,
    } | null,
    question?:  {
      __typename: "Question",
      id: string,
      image?:  {
        __typename: "S3ObjectProtected",
        key: string,
        identityId: string,
        alt?: string | null,
      } | null,
      audio?:  {
        __typename: "S3ObjectProtected",
        key: string,
        identityId: string,
        alt?: string | null,
      } | null,
      readAloudText?: string | null,
      text: string,
      type?: QuestionType | null,
      solution: string,
      answers: Array< string | null >,
      correctIndices: Array< number | null >,
      Quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        items:  Array< {
          __typename: "QuizQuestion",
          id: string,
          quizId: string,
          questionId: string,
          sortIndex?: number | null,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    sortIndex?: number | null,
    updatedAt: string,
    createdAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateQuizQuestionSubscriptionVariables = {
  filter?: ModelSubscriptionQuizQuestionFilterInput | null,
  owner?: string | null,
};

export type OnUpdateQuizQuestionSubscription = {
  onUpdateQuizQuestion?:  {
    __typename: "QuizQuestion",
    id: string,
    quizId: string,
    questionId: string,
    quiz?:  {
      __typename: "Quiz",
      id: string,
      creator?: string | null,
      image?:  {
        __typename: "S3ObjectProtected",
        key: string,
        identityId: string,
        alt?: string | null,
      } | null,
      allowReadTo?: string | null,
      title: string,
      description: string,
      tags: Array< string | null >,
      Questions?:  {
        __typename: "ModelQuizQuestionConnection",
        items:  Array< {
          __typename: "QuizQuestion",
          id: string,
          quizId: string,
          questionId: string,
          sortIndex?: number | null,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      lang?: string | null,
      updatedAt: string,
      createdAt: string,
    } | null,
    question?:  {
      __typename: "Question",
      id: string,
      image?:  {
        __typename: "S3ObjectProtected",
        key: string,
        identityId: string,
        alt?: string | null,
      } | null,
      audio?:  {
        __typename: "S3ObjectProtected",
        key: string,
        identityId: string,
        alt?: string | null,
      } | null,
      readAloudText?: string | null,
      text: string,
      type?: QuestionType | null,
      solution: string,
      answers: Array< string | null >,
      correctIndices: Array< number | null >,
      Quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        items:  Array< {
          __typename: "QuizQuestion",
          id: string,
          quizId: string,
          questionId: string,
          sortIndex?: number | null,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    sortIndex?: number | null,
    updatedAt: string,
    createdAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteQuizQuestionSubscriptionVariables = {
  filter?: ModelSubscriptionQuizQuestionFilterInput | null,
  owner?: string | null,
};

export type OnDeleteQuizQuestionSubscription = {
  onDeleteQuizQuestion?:  {
    __typename: "QuizQuestion",
    id: string,
    quizId: string,
    questionId: string,
    quiz?:  {
      __typename: "Quiz",
      id: string,
      creator?: string | null,
      image?:  {
        __typename: "S3ObjectProtected",
        key: string,
        identityId: string,
        alt?: string | null,
      } | null,
      allowReadTo?: string | null,
      title: string,
      description: string,
      tags: Array< string | null >,
      Questions?:  {
        __typename: "ModelQuizQuestionConnection",
        items:  Array< {
          __typename: "QuizQuestion",
          id: string,
          quizId: string,
          questionId: string,
          sortIndex?: number | null,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      lang?: string | null,
      updatedAt: string,
      createdAt: string,
    } | null,
    question?:  {
      __typename: "Question",
      id: string,
      image?:  {
        __typename: "S3ObjectProtected",
        key: string,
        identityId: string,
        alt?: string | null,
      } | null,
      audio?:  {
        __typename: "S3ObjectProtected",
        key: string,
        identityId: string,
        alt?: string | null,
      } | null,
      readAloudText?: string | null,
      text: string,
      type?: QuestionType | null,
      solution: string,
      answers: Array< string | null >,
      correctIndices: Array< number | null >,
      Quizzes?:  {
        __typename: "ModelQuizQuestionConnection",
        items:  Array< {
          __typename: "QuizQuestion",
          id: string,
          quizId: string,
          questionId: string,
          sortIndex?: number | null,
          updatedAt: string,
          createdAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    sortIndex?: number | null,
    updatedAt: string,
    createdAt: string,
    owner?: string | null,
  } | null,
};
