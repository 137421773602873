import useEffectOnce from "../../../hooks/useEffectOnce";
import useAsync from "../../../hooks/useAsync";
import { getQuiz } from "../../../graphql/queries";
import useUser from "../../../hooks/useUser";
import { GraphQLQuery } from "@aws-amplify/api";
import { GetQuizQuery } from "../../../API";
import { generateClient } from "aws-amplify/api";

export default function useQuizUser(quizId: string) {
  useEffectOnce(() => {
    creator.reload();
  });

  const creator = useAsync(async () => {
    const client = generateClient();
    const result = await client.graphql<GraphQLQuery<GetQuizQuery>>({
      query: getQuiz,
      variables: { id: quizId },
    });

    const quiz = result?.data?.getQuiz;

    if (quiz) return quiz.creator;
    throw new Error("Quiz not found");
  }, [quizId]);

  const user = useUser(creator.value);

  return { ...user };
}
