import { Slide } from "../types/slideTypes";

export function calculateOrderIndex(slides: Slide[], index: number) {
  if (slides.length === 0) {
    return 0;
  }

  const prevSlide = slides[index - 1];
  const nextSlide = slides[index + 1];
  console.log({
    slides: slides.map((slide) => ({ id: slide.id, order: slide.orderIndex })),
    index,
  });
  console.log({
    prevSlideOrder: prevSlide?.orderIndex,
    nextSlideOrder: nextSlide?.orderIndex,
  });
  if (prevSlide === undefined) {
    return nextSlide.orderIndex - 1;
  } else if (nextSlide === undefined) {
    return prevSlide.orderIndex + 1;
  } else return (prevSlide.orderIndex + nextSlide.orderIndex) * 0.5;
}
