import { GraphQLQuery } from "@aws-amplify/api";
import { CreateQuizInput, CreateQuizMutation, Quiz } from "../../../API";
import { createPrivateQuiz } from "../../../services/custom-mutations";
import { generateClient } from "aws-amplify/api";

export default async function createQuiz(createQuizObject: CreateQuizInput) {
  const { title, description, tags, image, lang } = createQuizObject;

  //const quizData = await client.graphql(graphqlOperation(mutations.createQuiz, { input: quizDataJSON }));
  const client = generateClient();

  const createQuizResponse = await client.graphql<
    GraphQLQuery<CreateQuizMutation>
  >({
    query: createPrivateQuiz,
    variables: { title, description, tags, image, lang },
  });

  const quiz: Quiz | undefined | null = createQuizResponse?.data?.createQuiz;


  if (quiz === undefined || quiz === null) {
    throw new Error("There was an error generating quiz. Please try again.");
  }

  return quiz;
}
