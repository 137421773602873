import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Slide, SlideElement } from "../types/slideTypes";
import { updateSlide } from "../services/updateSlide";

export function useUpdateElement({
  lessonId,
  slideId,
  elementId,
}: {
  lessonId: string;
  slideId: string;
  elementId: string;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["element", "update", elementId],
    mutationFn: async (newElement: SlideElement) => {
      const slide = queryClient.getQueryData<Slide>([
        "slide",
        lessonId,
        slideId,
      ]);

      if (!slide) return;
      const index = slide.elements.findIndex(
        (element) => element.id === elementId
      );
      if (index === -1) return;
      const newElements = [
        ...slide.elements.slice(0, index),
        newElement,
        ...slide.elements.slice(index + 1),
      ];
      await updateSlide(lessonId, slideId, { elements: newElements });
    },
    onSettled: () => {
      queryClient.invalidateQueries(["slide", lessonId, slideId]);
    },
  });
}
