import { View, ViewProps } from "@aws-amplify/ui-react";
import * as React from "react";

export interface IDraggableProps extends ViewProps {
  enabled?: boolean;
  dragIdKey: string;
  dragIdValue: string;
  onDrag?: (e: React.DragEvent<HTMLDivElement>) => void | Promise<void>;
  onDragStart?: (e: React.DragEvent<HTMLDivElement>) => void | Promise<void>;
  onDragEnd?: (e: React.DragEvent<HTMLDivElement>) => void | Promise<void>;
  children?: React.ReactNode;
}

export function Draggable(props: IDraggableProps) {
  const {
    enabled = true,
    dragIdKey,
    dragIdValue,
    onDragStart,
    onDrag,
    onDragEnd,
    children,
    ...viewProps
  } = props;

  const handleDragStart = async (e: React.DragEvent<HTMLDivElement>) => {
    // const originalElement = e.target as HTMLDivElement;
    // const originalHTML = originalElement.innerHTML;

    // const dragIcon = document.createElement("div");
    // dragIcon.innerHTML = originalHTML;
    // dragIcon.style.position = "absolute";
    // // dragIcon.style.top = "-1000px";
    // document.body.appendChild(dragIcon);

    // e.dataTransfer.setDragImage(dragIcon, 0, 0);

    // // Clean up after the drag operation is done
    // originalElement.addEventListener("dragend", () => {
    //   document.body.removeChild(dragIcon);
    // });

    e.dataTransfer.setData(dragIdKey, dragIdValue);
    await onDragStart?.(e);
  };

  const handleDrag = async (e: React.DragEvent<HTMLDivElement>) => {
    await onDrag?.(e);
  }

  const handleDragEnd = async (e: React.DragEvent<HTMLDivElement>) => {
    await onDragEnd?.(e);
  }

  if (!enabled) return <View {...viewProps}>{children}</View>;

  return (
    <View
      {...viewProps}
      draggable={true}
      style={{ cursor: "grab" }}
      onDrag={handleDrag}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      {children}
    </View>
  );
}
