import { GraphQLQuery, generateClient } from "aws-amplify/api";
import { CreateQuestionMutation, GetQuizQuestionQuery } from "../../../API";
import { copyS3Item } from "../../../services/s3/copyS3Item";
import { getIdentityId } from "../../game/util";
import { createQuestion } from "../../../graphql/mutations";
import createQuizQuestion from "../utils/createQuizQuestion";

const getQuizQuestion = /* GraphQL */ `
  query GetQuizQuestion($id: ID!) {
    getQuizQuestion(id: $id) {
      id
      quizId
      questionId
      sortIndex
      question {
        audio {
          key
          identityId
        }
        image {
          key
          identityId
          alt
        }
        readAloudText
        text
        type
        solution
        answers
        correctIndices
      }
    }
  }
`;

export async function duplicateQuestion(
  destinationQuizId: string,
  quizQuestionId: string
) {
  const client = generateClient();

  // get question with questionId
  const quizQuestion = await client.graphql<GraphQLQuery<GetQuizQuestionQuery>>(
    {
      query: getQuizQuestion,
      variables: { id: quizQuestionId },
    }
  );
  // extract question data
  if (!quizQuestion.data?.getQuizQuestion)
    throw new Error("Quiz Question not found");
  const { question, sortIndex } = quizQuestion.data?.getQuizQuestion;
  if (!question) throw new Error("Question not found");
  const {
    image,
    text,
    type,
    solution,
    answers,
    correctIndices,
    audio,
    readAloudText,
  } = question;

  // make a copy of the image with new owner
  if (image?.identityId && image.key)
    await copyS3Item({ identityId: image?.identityId, fileName: image?.key });

  if (audio?.identityId && audio.key)
    await copyS3Item({ identityId: audio?.identityId, fileName: audio?.key });

  const identityId = await getIdentityId();

  const newImage = image?.key
    ? {
        key: image?.key ?? "",
        identityId: identityId,
      }
    : null;

  const newAudio = audio?.key
    ? {
        key: audio?.key ?? "",
        identityId: identityId,
      }
    : null;
  // create new question with extracted data
  const newQuestionResult = await client.graphql<
    GraphQLQuery<CreateQuestionMutation>
  >({
    query: createQuestion,
    variables: {
      input: {
        image: newImage,
        audio: newAudio,
        readAloudText,
        text,
        type,
        solution,
        answers,
        correctIndices,
      },
    },
  });
  const newQuestion = newQuestionResult.data?.createQuestion;
  if (!newQuestion) throw new Error("Failed to create new question");
  // link new question to quiz with quizId

  const newQuizQuestion = await createQuizQuestion({
    questionId: newQuestion.id,
    quizId: destinationQuizId,
    sortIndex,
  });

  return newQuizQuestion;
}
