import { useContext } from "react";
import { useParams } from "react-router-dom";
import { PageDescription, PageIndex, PageTitle } from "../../../util/SEO";
import { QuizContext } from "./QuizContextProvider";
import {
  Flex,
  Grid,
  Loader,
  ScrollView,
  Tabs,
  View,
} from "@aws-amplify/ui-react";
import React from "react";
import QuestionsList from "./QuestionsList";
import QuizDetails from "./QuizDetails";
import QuizEditorButtons from "./QuizEditorButtons";
import QuizPDF from "./QuizPDF";
import ShareQuizButtons from "./ShareQuizButtons";
import { useQueryClient } from "@tanstack/react-query";
import TranslationContextProvider from "../../../context/TranslationContextProvider";

export default function QuizEditor() {
  PageTitle("Quiz Generator | Gibbly");
  PageDescription("Test your knowledge with this quiz!");
  PageIndex("noindex");

  const { id } = useParams();

  const queryClient = useQueryClient();

  const {
    quiz,
    localQuizQuestions,
    isCreator,
    isQuizLoading,
    areQuestionsLoading,
  } = useContext(QuizContext);

  return (
    <TranslationContextProvider lang={quiz?.lang || "en"}>
      <ScrollView grow={1} height={"100%"}>
        <Grid
          templateColumns={{ base: "1fr", medium: "360px auto" }}
          gap={"small"}
          width={"100%"}
          height={{ base: "auto", medium: "100%" }}
        >
          <ScrollView
            width={"100%"}
            backgroundColor={"#eee"}
            padding="medium"
            height={"100%"}
          >
            <View height={"100%"} grow={1}>
              {!quiz ? (
                <Loader />
              ) : (
                <Flex direction={"column"}>
                  {id && <QuizDetails />}
                  <QuizEditorButtons />
                </Flex>
              )}
            </View>
          </ScrollView>
          <ScrollView width={"100%"} padding="medium">
            {areQuestionsLoading || isQuizLoading || !quiz ? (
              <Loader />
            ) : (
              <Tabs.Container
                height={"100%"}
                defaultValue="quiz"
                onValueChange={(value) => {
                  if (value === "quiz") queryClient.refetchQueries(["quiz"]);
                }}
              >
                <Tabs.List>
                  <Tabs.Item value={"quiz"}>Questions</Tabs.Item>
                  <Tabs.Item value={"pdf"}>PDF</Tabs.Item>
                  <Tabs.Item value={"share"}>Share</Tabs.Item>
                </Tabs.List>

                <Tabs.Panel value="quiz" padding={"medium"}>
                  <Flex height={"100%"} direction={"column"}>
                    <QuestionsList
                      quizQuestions={localQuizQuestions}
                      isCreator={isCreator}
                    />
                  </Flex>
                </Tabs.Panel>
                <Tabs.Panel value="pdf" padding={"medium"}>
                  <View>
                    <QuizPDF
                      quiz={quiz}
                      quizQuestions={localQuizQuestions}
                    />
                  </View>
                </Tabs.Panel>
                <Tabs.Panel value="share" padding={"medium"}>
                  <View>
                    <ShareQuizButtons quizId={id} />
                  </View>
                </Tabs.Panel>
              </Tabs.Container>
            )}
          </ScrollView>
        </Grid>
      </ScrollView>
    </TranslationContextProvider>
  );
}
