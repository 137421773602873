import {
  Button,
  Card,
  Flex,
  Grid,
  Heading,
  ScrollView,
  View,
} from "@aws-amplify/ui-react";
import React, { useMemo, useState } from "react";
import LobbyInfoCard from "./LobbyInfoCard";
import { Droppable } from "../../../../components/Droppable";
import { Player } from "../../Types/GameTypes_dep";
import { Team } from "../../Types/TeamTypes";
import { TeamCardLobby } from "./TeamCardLobby";
import { Lobby } from "../../Types/LobbyTypes";
import { GameState } from "../../Types/GameTypes";
import { CreateTeamButton } from "./CreateTeamButton";
import GibblyLoader from "../../../../components/GibblyLoader";
import useLobbyContext from "../../hooks/useLobbyContext";
import PlayerAvatarControlled from "./PlayerAvatarControlled";
import AudioController from "../../../../layouts/AudioController";
import LobbyHeaderButtons from "./LobbyHeaderButtons";
import { useTranslationContext } from "../../../../context/TranslationContextProvider";
import { useVolumeContext } from "../../../../context";

type View = "lobby" | "game" | "leaderboard";

export default function LobbyHostView() {
  const { volume, setVolume, multiplier } = useVolumeContext();

  const [isStarting, setIsStarting] = useState(false);
  const {
    teams,
    lobby,
    updateLobby,
    players,
    updatePlayer,
    updateTeam,
    kickedPlayers,
    removeTeam,
  } = useLobbyContext();

  // const playersGroupedByTeam = useMemo(() => {
  //   return Object.groupBy(players, (player) =>
  //     teams.some((team) => team.id === player.groupId)
  //       ? player.groupId
  //       : "ungrouped"
  //   );
  // }, [players, teams]);
  const playersGroupedByTeam = useMemo(() => {
    const groupedPlayers = players.reduce((acc, player) => {
      if (teams.some((team) => team.id === player.groupId)) {
        if (!acc[player.groupId]) {
          acc[player.groupId] = [];
        }
        acc[player.groupId].push(player);
      } else {
        if (!acc["ungrouped"]) {
          acc["ungrouped"] = [];
        }
        acc["ungrouped"].push(player);
      }
      return acc;
    }, {} as Record<string, Player[]>);
    return groupedPlayers;
  }, [players, teams]);

  const ungroupedPlayers = useMemo(() => {
    return playersGroupedByTeam["ungrouped"] ?? [];
  }, [playersGroupedByTeam]);

  const canPlay = useMemo(() => {
    const hasUngrouped = ungroupedPlayers.length > 0;

    const allTeamsEmpty = Object.entries(playersGroupedByTeam)
      .filter(([key]) => key !== "ungrouped")
      .every(([_, value]) => value && value.length === 0);

    return !hasUngrouped && !allTeamsEmpty;
  }, [playersGroupedByTeam, ungroupedPlayers]);

  const { translations } = useTranslationContext();

  const { start } = translations;

  async function onDropInGroup(value: string, group: Lobby | Team) {
    const player: Player = JSON.parse(value);
    try {
      // if (player.isKicked) await unkickPlayer(player.userId);
      await updatePlayer({
        ...player,
        groupId: group.id,
        playerStatus: "NORMAL",
      });
    } catch (err) {
      console.error(err);
    }
  }

  const DroppableTeamCard = ({
    team,
    index,
  }: {
    team: Team;
    index: number;
  }) => {
    const [dragOver, setDragOver] = useState(false);

    return (
      <Droppable
        key={team.id}
        dragIdKey={"player"}
        width={"100%"}
        onDragEnter={() => {
          setDragOver(true);
        }}
        onDragLeave={() => {
          setDragOver(false);
        }}
        onDropItem={async (value) => await onDropInGroup(value, team)}
      >
        <TeamCardLobby
          players={playersGroupedByTeam[team.id] || []}
          team={team}
          index={index}
          borderColor={dragOver ? "white" : undefined}
        />
      </Droppable>
    );
  };

  if (lobby === undefined)
    return (
      <Flex width={"100%"} height={"100%"}>
        <GibblyLoader />
      </Flex>
    );

  return (
    <>
      <ScrollView>
        <Flex
          direction={"column"}
          height={"100dvh"}
          minHeight={"100vh"}
          width={"100%"}
          gap={"medium"}
          rowGap={"xl"}
          alignItems={"center"}
        >
          <>
            <View
              alignSelf={"stretch"}
              position="sticky"
              top={"xs"}
              style={{
                zIndex: "100",
                pointerEvents: "none",
              }}
            >
              <Flex direction={"column"} gap={"0"}>
                <LobbyHeaderButtons exitTo="/dashboard/library" />
                <Grid templateColumns={"1fr auto 1fr"} gap={"large"}>
                  <View />
                  <LobbyInfoCard />
                  <Flex alignItems={"center"}>
                    <View textAlign={"center"}>
                      <Heading color={"white"} fontSize={"xxl"}>
                        {players.length}
                      </Heading>
                      <Heading color={"white"} level={5}>
                        {players.length === 1 ? "Player" : "Players"}
                      </Heading>
                    </View>
                  </Flex>
                </Grid>
              </Flex>
            </View>
            <Flex width={"100%"} justifyContent={"center"}>
              <Grid
                width={"100%"}
                padding={"0 large 0 large"}
                gap={"xl"}
                templateColumns={"4fr 1fr"}
                alignItems={"center"}
                maxWidth={"1400px"}
              >
                <View>
                  {ungroupedPlayers.length > 0 && (
                    <Droppable
                      dragIdKey={"player"}
                      onDropItem={async (value) =>
                        await onDropInGroup(value, lobby)
                      }
                      flex={1}
                    >
                      <Card
                        id="leaderboardCard"
                        backgroundColor={"transparent"}
                      >
                        <Flex direction={"column"} gap={"large"}>
                          <Heading>Waiting for a Team:</Heading>
                          <Flex>
                            {ungroupedPlayers.map((player) => (
                              <PlayerAvatarControlled
                                key={player.userId}
                                player={player}
                              />
                            ))}
                          </Flex>
                        </Flex>
                      </Card>
                    </Droppable>
                  )}
                </View>
                <View>
                  {kickedPlayers && kickedPlayers.length > 0 && (
                    <Card
                      id="leaderboardCard"
                      backgroundColor={"transparent"}
                      flex={1}
                    >
                      <Flex direction={"column"} gap={"large"}>
                        <Heading>Kicked Players:</Heading>
                        <Flex>
                          {kickedPlayers &&
                            kickedPlayers.map((player) => (
                              <PlayerAvatarControlled
                                key={player.userId}
                                draggable={true}
                                player={player}
                              />
                              // <PlayerAvatar player={player} />
                            ))}
                        </Flex>
                      </Flex>
                    </Card>
                  )}
                </View>
              </Grid>
            </Flex>

            {teams && (
              <>
                <Flex
                  flex={1}
                  width={"100%"}
                  maxWidth={"1400px"}
                  padding={"0 large 0 large"}
                  direction={"column"}
                  gap={"small"}
                  alignItems={"stretch"}
                >
                  {teams.map((team, index) => (
                    <DroppableTeamCard team={team} index={index} />
                  ))}
                  <View padding={"0 large 0 large"} alignSelf={"start"}>
                    <CreateTeamButton />
                  </View>
                </Flex>
              </>
            )}
            <Grid
              width={"100%"}
              templateColumns={{ base: "1fr", small: "1fr auto 1fr" }}
              position={"sticky"}
              padding={"small large small large"}
              bottom={"0"}
              gap={"medium"}
              style={{ pointerEvents: "none" }}
            >
              <View></View>
              <Flex justifyContent={"center"}>
                <Button
                  size="large"
                  column={2}
                  fontSize={{ base: "small", medium: "large" }}
                  variation="primary"
                  id={"raised-btn"}
                  backgroundColor={"teal.60"}
                  isLoading={isStarting}
                  isDisabled={!canPlay}
                  loadingText={"Starting"}
                  style={{ pointerEvents: "auto" }}
                  onClick={async () => {
                    setIsStarting(true);

                    const promises = (teams as Team[]).map(async (team) => {
                      const players = playersGroupedByTeam[team.id] || [];
                      if (players.length === 0) {
                        removeTeam(team.id);
                      } else {
                        await updateTeam({
                          team: {
                            ...team,
                            teamState: "IN_GAME",
                            gameData: {
                              gameState: GameState.PLAYING,
                              questionIndex: 0,
                              currentAnswer: [],
                              answerResults: [],
                            },
                          },
                        });
                      }
                    });

                    await Promise.all(promises);

                    await updateLobby({
                      lobby: { ...lobby, lobbyState: "IN_GAME" },
                    });
                    setIsStarting(false);
                  }}
                >
                  {start}
                </Button>
              </Flex>
              <Flex justifyContent={"end"}>
                <Card
                  backgroundColor={"rgba(0,0,0,0.5)"}
                  style={{ pointerEvents: "auto" }}
                >
                  <AudioController
                    src={"/audio/music/Lobby - CG_BGM_1_3.wav"}
                    autoPlay={true}
                    loop={true}
                    volume={volume}
                    setVolume={setVolume}
                    volumeMultiplier={multiplier}
                  />
                </Card>
              </Flex>
            </Grid>
          </>
        </Flex>
      </ScrollView>
    </>
  );
}
