import { useQuery } from "@tanstack/react-query";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { Quiz } from "../components/Quiz";
import { getQuestionIdsFromQuiz } from "../../../services/custom-queries";
import useEffectOnce from "../../../hooks/useEffectOnce";
import { generateClient } from "aws-amplify/api";

export default function useQuizQuestionIds(
  quizId: string,
  onSuccess?: (data: Quiz) => void
): {
  questionIds?: string[];
  refetchQuestionIds: () => any;
} {
  useEffectOnce(() => {
    refetchQuestionIds();
  });

  const { data: quiz, refetch: refetchQuestionIds } = useQuery({
    queryKey: ["quiz questions", quizId],
    enabled: quizId !== undefined,
    queryFn: async () => {
      const client = generateClient();
      const result = (await client.graphql({
        query: getQuestionIdsFromQuiz,
        variables: { quizId },
      })) as GraphQLResult<any>;

      const quiz = result.data.getQuiz;

      return quiz as Quiz;
    },
    onSuccess,
    onError: (e) => console.error("Quiz Query Failed", e),
  });

  return {
    questionIds: quiz?.Questions.items.map((q) => q.id),
    refetchQuestionIds: refetchQuestionIds,
  };
}
