import { getDatabaseItem } from "../../../services/database/getDatabaseItem";
import { queryDatabaseItems } from "../../../services/database/queryDatabaseItems";
import { Lobby, LobbyKey } from "../Types/LobbyTypes";

export async function getLobby(pin: string, id?: string) {
  try {
    if (id) {
      const lobby = await getDatabaseItem<Lobby, LobbyKey>("/lobbies", {
        pin,
        id,
      });

      return lobby;
    } else {
      const lobbies = await queryDatabaseItems<Lobby>("/lobbies", pin);
      if (lobbies === undefined || lobbies?.length === 0) return; // error
      const lobby = lobbies[0];
      return lobby;
    }
  } catch (err) {
    throw err;
  }
}
