import { ButtonProps } from "@aws-amplify/ui-react";
import React from "react";

import { RaisedButton } from "../../../components";
import { useQuery } from "@tanstack/react-query";

import useAudio from "../../../hooks/useAudio";
import { FaStop } from "react-icons/fa6";
import SoundOnFullIcon from "../../../components/icons/SoundOnFullIcon";
import { S3ObjectProtected } from "../../../API";
import { getAudioUrl } from "../../audio/services";
import { useVolumeContext } from "../../../context";
import { toast } from "react-toastify";

type Props = Omit<ButtonProps, "onClick" | "size"> & {
  audio: S3ObjectProtected;
  size?: string;
  hideOnError?: boolean;
};

export function PlayAudioButton(props: Props) {
  const { audio, size, hideOnError = false, ...buttonProps } = props;

  const {
    data: url,
    isFetching,
    isError,
  } = useQuery(["s3-audio", "url", audio.key, audio.identityId], async () => {
    const url = await getAudioUrl(audio);
    return url;
  });
  // const { data: valid, isLoading: checkingValidity } = useQuery(
  //   ["s3-audio", "valid", url],
  //   async () => {
  //     const isValid = await isValidAudio(url as string);
  //     return isValid;
  //   },
  //   {
  //     enabled: url !== undefined,
  //   }
  // );

  const { setMultiplier } = useVolumeContext();
  const { play, playing, stop } = useAudio({
    src: url,
    volume: 1,
    volumeMultiplier: 1,
    onPlay: () => {
      setMultiplier(0.15);
    },
    onPause: () => setMultiplier(1),
    onError: () => {
      stop();
    },
  });


  if (isError && hideOnError) return null;

  return (
    <RaisedButton
      borderRadius={"50px"}
      backgroundColor={"rgba(255,255,255,0.6)"}
      variation="link"
      padding={"xs xs small xs"}
      justifyContent={"center"}
      alignItems={"center"}
      height={"30px"}
      isLoading={isFetching}
      isDisabled={isError}
      tooltip={!isError ? "Play Audio" : "Audio is invalid"}
      style={{
        aspectRatio: "1",
      }}
      {...buttonProps}
      onClick={() => {
        if (isError) {
          toast.error("Cannot play audio");
          return;
        }

        if (playing) stop();
        else play();
      }}
    >
      {playing ? (
        <FaStop fontSize={size} />
      ) : (
        <SoundOnFullIcon
          fontSize={size}
          color={!isError ? undefined : "red.40"}
        />
      )}
    </RaisedButton>
  );
}
