import React from "react";

import { Button, TextField, View } from "@aws-amplify/ui-react";
import SearchIcon from "../../../../components/icons/SearchIcon";
import CloseIcon from "../../../../components/icons/CloseIcon";

interface SearchFormProps {
  contentType?: "quiz" | "lesson";
  name?:
    | "library-dashboard"
    | "discovery-dashboard"
    | "library-classroom"
    | "discovery-classroom";
  search: string;
  setSearch: (search: string) => void;
  onSubmit?: (search: string) => void;
  placeholder?: string;
}

export default function SearchForm({
  contentType,
  name,
  search,
  setSearch,
  onSubmit,
  placeholder,
}: SearchFormProps) {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    if (event.target.value === "") onSubmit?.("");
  };

  return (
    <View
      id="search-form"
      as="form"
      flex={1}
      data-attr={contentType ? `search-${contentType}` : "search"}
      data-ph-capture-attribute-type={name}
      data-ph-capture-attribute-value-raw={search}
      data-ph-capture-attribute-value-lower={search.toLowerCase()}
      onReset={() => {
        setSearch("");
        onSubmit?.("");
      }}
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit?.(search);
      }}
    >
      <TextField
        id="search-field"
        labelHidden
        width={"100%"}
        size="small"
        label={"search"}
        placeholder={placeholder}
        type="search"
        value={search}
        onChange={onChange}
        innerEndComponent={
          <Button
            variation="link"
            type="reset"
            onClick={() => {
              setSearch("");
            }}
          >
            <CloseIcon />
          </Button>
        }
        outerEndComponent={
          <Button type="submit">
            <SearchIcon />
          </Button>
        }
      ></TextField>
    </View>
    // <SearchField
    //   data-attr="search-quiz"
    //   data-ph-capture-attribute-type={collectionType}
    //   data-ph-capture-attribute-value={search}
    //
    //   label=""
    //   width={"100%"}
    //   size="small"
    //   placeholder="Search quizzes ..."
    //   value={search}
    //   onChange={onChange}
    //   onClear={onClear}
    //   onSubmit={onSearch}
    // />
  );
}
