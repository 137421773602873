import { Engine, LanguageCode, VoiceId } from "@aws-sdk/client-polly";
import { languageOptions } from "../../translations";

export function getLanguageCodeAndVoice(
  lang: keyof typeof languageOptions | undefined
): { languageCode: LanguageCode; voiceId: VoiceId; engine: Engine } {
  switch (lang) {
    case "en":
      return { languageCode: "en-US", voiceId: "Joanna", engine: "neural" };
    case "ar":
      return { languageCode: "arb", voiceId: "Hala", engine: "neural" };
    case "es":
      return { languageCode: "es-US", voiceId: "Lupe", engine: "neural" };
    case "de":
      return { languageCode: "de-DE", voiceId: "Vicki", engine: "neural" };
    case "fr":
      return { languageCode: "fr-CA", voiceId: "Gabrielle", engine: "neural" };
    case "zh":
      return { languageCode: "cmn-CN", voiceId: "Zhiyu", engine: "neural" };
    case "zh-yue":
      return { languageCode: "yue-CN", voiceId: "Hiujin", engine: "neural" };
    case "hi":
      return { languageCode: "hi-IN", voiceId: "Kajal", engine: "neural" };
    case "ja":
      return { languageCode: "ja-JP", voiceId: "Kazuha", engine: "neural" };
    case "it":
      return { languageCode: "it-IT", voiceId: "Bianca", engine: "neural" };
    case "pt":
      return { languageCode: "pt-PT", voiceId: "Ines", engine: "neural" };
    case "ko":
      return { languageCode: "ko-KR", voiceId: "Seoyeon", engine: "neural" };
    case "ru":
      return { languageCode: "ru-RU", voiceId: "Tatyana", engine: "standard" };
    case "nb":
      return { languageCode: "nb-NO", voiceId: "Ida", engine: "neural" };
    case "nl":
      return { languageCode: "nl-NL", voiceId: "Laura", engine: "neural" };

    default:
      return { languageCode: "en-US", voiceId: "Joanna", engine: "neural" };
  }
}
