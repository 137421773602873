import React, { FormEvent, useMemo, useState } from "react";

import {
  Button,
  Flex,
  SelectField,
  TextAreaField,
  Text,
} from "@aws-amplify/ui-react";

import { PageIndex, PageTitle } from "../../../util/SEO";
import SparkleIcon from "../../../components/icons/SparkleIcon";
import { GenerationInput } from "./GenerateQuiz";

import useEffectOnce from "../../../hooks/useEffectOnce";
import { placeholderPasteText } from "../../../data/PlaceholderPasteText";
import LanguageSelect from "../../../components/LanguageSelect";
import { useTranslationContext } from "../../../context/TranslationContextProvider";

type FormInput = {
  topic: string;
  lang: string;
  numberOfQuestions: string;
  gradeLevel: string;
};

export default function PasteForm({
  onGenerate,
  loading,
}: {
  onGenerate: (input: GenerationInput) => void;
  loading: boolean;
}) {
  PageTitle("Gibbly | Generate Quiz");
  PageIndex("noindex");

  const { prefLang } = useTranslationContext();

  const [formInput, setFormInput] = useState<FormInput>({
    topic: "",
    lang: prefLang,
    numberOfQuestions: "20",
    gradeLevel: "Easy",
  });

  const [pastePlaceholder, setPastePlaceholder] = useState("");

  useEffectOnce(() => {
    const randomIndex = Math.floor(Math.random() * placeholderPasteText.length);
    const randomPlaceholder = placeholderPasteText[randomIndex];
    setPastePlaceholder(randomPlaceholder);
  });

  const handleTopicSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onGenerate({
      type: "paste-mixed",
      prompt: formInput,
    });
  };

  const maxCharacterLength = 7500;

  const characterCountText = useMemo(() => {
    // format text like "1000/7500"
    return `${formInput.topic.length}/${maxCharacterLength}`;
  }, [formInput.topic.length]);

  return (
    <Flex
      as="form"
      direction={"column"}
      padding={"0"}
      onSubmit={handleTopicSubmit}
    >
      <TextAreaField
        fontSize={"small"}
        size="small"
        label={
          <Flex justifyContent={"space-between"}>
            <Text fontWeight={"semibold"} color={"neutral.90"}>
              Text
            </Text>
            <Text fontWeight={"semibold"} color={"neutral.90"}>
              {characterCountText}
            </Text>
          </Flex>
        }
        isRequired
        placeholder={pastePlaceholder}
        maxLength={7500}
        rows={5}
        resize="vertical"
        value={formInput.topic}
        onChange={(e) =>
          setFormInput((prev) => ({
            ...prev,
            topic: e.target.value,
          }))
        }
      />

      <Flex wrap={"wrap"}>
        <SelectField
          fontSize={"small"}
          size="small"
          label={
            <Text fontWeight={"semibold"} color={"neutral.90"}>
              Grade Level
            </Text>
          }
          flex={1}
          isRequired
          value={formInput.gradeLevel}
          onChange={(e) =>
            setFormInput((prev) => ({
              ...prev,
              gradeLevel: e.target.value,
            }))
          }
        >
          <option value={"Easy"}>Easy</option>
          <option value={"Medium"}>Medium</option>
          <option value={"Hard"}>Hard</option>
          <option value={"Grade 1"}>Grade 1</option>
          <option value={"Grade 2"}>Grade 2</option>
          <option value={"Grade 3"}>Grade 3</option>
          <option value={"Grade 4"}>Grade 4</option>
          <option value={"Grade 5"}>Grade 5</option>
          <option value={"Grade 6"}>Grade 6</option>
          <option value={"Grade 7"}>Grade 7</option>
          <option value={"Grade 8"}>Grade 8</option>
          <option value={"Grade 9"}>Grade 9</option>
          <option value={"Grade 10"}>Grade 10</option>
          <option value={"Grade 11"}>Grade 11</option>
          <option value={"Grade 12"}>Grade 12</option>
          <option value={"College/University"}>College/University</option>
        </SelectField>
        <LanguageSelect
          fontSize={"small"}
          size="small"
          flex={1}
          label={
            <Text fontWeight={"semibold"} color={"neutral.90"}>
              Language
            </Text>
          }
          isRequired
          value={formInput.lang}
          onChange={(lang) => setFormInput((prev) => ({ ...prev, lang: lang }))}
        ></LanguageSelect>
        <SelectField
          fontSize={"small"}
          size="small"
          label={
            <Text fontWeight={"semibold"} color={"neutral.90"}>
              # of Questions
            </Text>
          }
          isRequired
          value={formInput.numberOfQuestions}
          onChange={(e) =>
            setFormInput((prev) => ({
              ...prev,
              numberOfQuestions: e.target.value,
            }))
          }
        >
          // from 1 to 20
          {[...Array(20).keys()].map((i) => (
            <option key={i} value={(i + 1).toString()}>
              {i + 1}
            </option>
          ))}
        </SelectField>
      </Flex>

      <Button
        id="raised-btn"
        backgroundColor={"#fd7e14"}
        variation="primary"
        gap={"small"}
        type="submit"
        color={"white"}
        alignSelf={"center"}
        isLoading={loading}
        loadingText={"Generating..."}
        data-attr="generate-new-quiz"
        data-ph-capture-attribute-type={"paste"}
        data-ph-capture-attribute-prompt={formInput.topic}
        data-ph-capture-attribute-grade-level={formInput.gradeLevel}
        data-ph-capture-attribute-language={formInput.lang}
        data-ph-capture-attribute-number-of-questions={
          formInput.numberOfQuestions
        }
      >
        <SparkleIcon />
        Generate
      </Button>
    </Flex>
  );
}
