import * as React from "react";
import { Tabs } from "@aws-amplify/ui-react";
import GenerateMenu from "./GenerateMenu";
import { useState } from "react";
import AddSlideOptions from "./AddSlideOptions";

export interface IAddMenuProps {}

export default function AddMenu(props: IAddMenuProps) {
  const {} = props;

  const [tab, setTab] = useState<string>("ai-generated");

  return (
    // <Grid
    //   templateRows={"auto minmax(0, 1fr)"}
    //   templateColumns={"minmax(0, 1fr)"}
    //   justifyContent={"start"}
    //   height={"100%"}
    //   width={"100%"}
    //   gap={"0"}
    // >
    <Tabs.Container
      value={tab}
      onValueChange={(i) => setTab(i)}
      spacing={"equal"}
      justifyContent={"space-between"}
    >
      <Tabs.List>
        <Tabs.Item
          value="ai-generated"
          fontSize={"smaller"}
          color={"neutral.80"}
        >
          AI Generated
        </Tabs.Item>
        <Tabs.Item value="manually" fontSize={"smaller"} color={"neutral.80"}>
          Manually
        </Tabs.Item>
      </Tabs.List>
      <Tabs.Panel
        value="ai-generated"
        fontSize={"smaller"}
        // color={"neutral.80"}
      >
        <GenerateMenu />
      </Tabs.Panel>
      <Tabs.Panel value="manually" fontSize={"smaller"} color={"neutral.80"}>
        <AddSlideOptions />
      </Tabs.Panel>
    </Tabs.Container>
    //    <View>
    //     {tab === "0" && <GenerateMenu />}
    //     {tab === "1" && <AddSlideOptions />}
    //   </View>
    // </Grid>
  );
}
