import React from "react";
import { Text, Flex, SwitchField, Grid, Heading } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import { PageIndex, PageTitle } from "../util/SEO";
import InfoIcon from "../components/icons/InfoIcon";
import sendCustomEvent from "../util/sendCustomEvent";
import VolumePricingCard from "./Pricing.VolumePricingCard";
import PremiumPricingCard from "./Pricing.PremiumPricingCard";
import ProductCard from "../components/ProductCard.new";
import { Carousel } from "react-bootstrap";
import TestimonalItem from "../layouts/TestimonalItemPricing";
import { useUserContext } from "../context";

// import { S3Image } from '../components/S3Image'

export default function Pricing() {
  const navigate = useNavigate();

  PageTitle("Gibbly | Upgrade Your AI Tools  ");
  PageIndex("");

  const { user } = useUserContext();

  const [isYearly, setIsYearly] = React.useState<boolean>(false);

  //   useEffect(() => {
  //     if (!authenticated) return;
  //     const script1 = document.createElement("script");
  //     script1.async = true;
  //     script1.src = "//cdn.trackdesk.com/tracking.js";
  //     script1.setAttribute("site", "XJGKQVXK");
  //     const script2 = document.createElement("script");
  //     // add code to the script tag
  //     script2.innerHTML = `
  //     (function(t,d,k){(t[k]=t[k]||[]).push(d);t[d]=t[d]||t[k].f||function(){(t[d].q=t[d].q||[]).push(arguments)}})(window,"trackdesk","TrackdeskObject");

  //     trackdesk('gibbly', 'click');
  // `;
  //     document.head.appendChild(script1);
  //     const script3 = document.createElement("script");
  //     // add code to the script tag
  //     script3.innerHTML = `
  //       (function(t,d,k){(t[k]=t[k]||[]).push(d);t[d]=t[d]||t[k].f||function(){(t[d].q=t[d].q||[]).push(arguments)}})(window,"trackdesk","TrackdeskObject");

  //     trackdesk('gibbly', 'externalCid', {

  //     externalCid: "maitland.joshua@gmail.com",

  //     revenueOriginId: 'revenueOriginId'

  //   });
  // `;
  //     document.head.appendChild(script1);
  //     document.head.appendChild(script2);
  //     document.head.appendChild(script3);

  //     return () => {
  //       document.head.removeChild(script1);
  //       document.head.removeChild(script2);
  //       document.head.removeChild(script3);
  //     };
  //   }, []);

  return (
    <>
      {/* <SaleBanner /> */}
      <Flex
        direction={"column"}
        gap={"0"}
        fontFamily={"Poppins"}
        paddingLeft={{ base: "small", medium: "xl" }}
        paddingRight={{ base: "small", medium: "xl" }}
      >
        <Flex
          textAlign={{ base: "center", medium: "left" }}
          padding={{
            base: "xl xxxs 0 xxs",
            large: "xl xs 0 xs",
            xl: "xl xl 0 xl",
          }}
          gap={"xs"}
          justifyContent={"center"}
        >
          <Flex
            direction={"column"}
            textAlign={"center"}
            gap={"0"}
            width={"100%"}
            grow={1}
            flex={1}
          >
            <Heading
              id="displayText"
              fontSize={{ base: "large", xl: "2.25em" }}
              level={1}
            >
              Elevate Your Teaching with Time-Saving AI Tools!
            </Heading>
            <Carousel pause="hover" indicators={false} interval={5000}>
              <Carousel.Item>
                <TestimonalItem
                  testimonial={
                    "Gibbly has been an absolute pleasure to use! It has made lesson planning so fun and easy! It has saved me so much time while creating relevant curriculum based lessons!"
                  }
                  name={"Melyssa - Teacher"}
                />
              </Carousel.Item>

              <Carousel.Item>
                <TestimonalItem
                  testimonial={
                    "Gibbly helps take the game off the computer and into the minds of the students. Using AI to create a quiz that ties into the content and then made into a lesson sets Gibbly apart."
                  }
                  name={"Matthew - Teacher"}
                />
              </Carousel.Item>

              <Carousel.Item>
                <TestimonalItem
                  testimonial={
                    "Gibbly is excellent for peer induced teamwork! My students love and I love having the option to have teams. Great for review or study guides."
                  }
                  name={" Candy - Teacher"}
                />
              </Carousel.Item>

              <Carousel.Item>
                <TestimonalItem
                  testimonial={
                    "Gibbly is a teacher's dream. It cuts down on the amount of time lesson creation takes and leaves more time for other aspects of the job."
                  }
                  name={"Danielle - Teacher"}
                />
              </Carousel.Item>

              <Carousel.Item>
                <TestimonalItem
                  testimonial={
                    "Gibbly saves so much time in creating quick, fact-based assessments that I can use as formative or summative assessments."
                  }
                  name={"Alie - Teacher"}
                />
              </Carousel.Item>

              <Carousel.Item>
                <TestimonalItem
                  testimonial={
                    "For all the teachers, educators, and homeschooling parents, I highly recommend Gibbly. Try it! You will not be disappointed!"
                  }
                  name={"Tina - Homeschooling Parent"}
                />
              </Carousel.Item>

              <Carousel.Item>
                <TestimonalItem
                  testimonial={
                    "I like that in team mode it requires students to be mindful of their turn when questions come up. I also like how quick and simple things are."
                  }
                  name={"George - Teacher"}
                />
              </Carousel.Item>

              <Carousel.Item>
                <TestimonalItem
                  testimonial={
                    "Gibbly is a timesaver for every teacher. It can reduce the workload off of teachers immensely. I highly recommended for schools, online teaching and training sessions."
                  }
                  name={"Shahinaz - Teacher"}
                />
              </Carousel.Item>

              <Carousel.Item>
                <TestimonalItem
                  testimonial={
                    'I love the quiz-making feature. I teach adults 1:1 and use the quizzes as stand-alone activities. I also enjoy the space provided for adding qualifying information to the "Correct answer".'
                  }
                  name={"Ralph - Teacher"}
                />
              </Carousel.Item>
            </Carousel>
          </Flex>
        </Flex>

        <Flex
          justifyContent="center"
          gap={"0"}
          direction={"column"}
          alignContent={"center"}
          width="100%"
        >
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            padding={"medium"}
          >
            <Text
              flex={1}
              paddingLeft={"xl"}
              fontSize={"small"}
              textAlign={"right"}
              onClick={() => setIsYearly(false)}
              style={{ cursor: "pointer" }}
            >
              Monthly
            </Text>
            <SwitchField
              label={""}
              labelHidden
              paddingRight={"small"}
              isChecked={isYearly}
              onChange={(e) => {
                sendCustomEvent("pricing_interval_switched", {
                  interval: e.target.checked ? "yearly" : "monthly",
                });
                setIsYearly(e.target.checked);
              }}
            />
            <Text
              flex={1}
              textAlign={"left"}
              fontSize={"small"}
              onClick={() => setIsYearly(true)}
              style={{ cursor: "pointer" }}
            >
              Yearly
            </Text>
          </Flex>

          <Grid
            templateColumns={{ base: "1fr", medium: "auto auto auto" }}
            templateRows={"minmax(0,1fr)"}
            gap={{ base: "small", large: "large" }}
            textAlign={"center"}
            justifyContent={"center"}
            width={"100%"}
            alignContent={"center"}
          >
            <Flex justifyContent={"center"}>
              <ProductCard
                title={"Starter"}
                heading={
                  <Text
                    fontWeight={"bold"}
                    fontSize={"large"}
                    padding={"medium"}
                  >
                    FREE
                  </Text>
                }
                subheading={
                  <Text
                    height={"60px"}
                    fontSize={"small"}
                    fontWeight={"semibold"}
                    color={"orange.100"}
                    padding={"xs"}
                  >
                    $0 / month
                  </Text>
                }
                buttonText={
                  user !== undefined &&
                  (user.customerTier === "Starter" ||
                    user.customerTier === undefined)
                    ? "Current Plan"
                    : "Get Started"
                }
                highlightColor={"orange.60"}
                onClick={() => navigate(user ? "/dashboard" : "/auth/signup")}
                features={[
                  <Flex>
                    10K AI text generation tokens/month{" "}
                    <InfoIcon message="1,000 tokens is approximately 750 words. (Subject to change)" />
                  </Flex>,
                  "Host unlimited review games",
                  "Create unlimited quizzes and lessons",
                  "Create unlimited classes",
                  "Unlimited access to Discover content",
                  "Google exports & Google SSO",
                ]}
              ></ProductCard>
            </Flex>

            <Flex justifyContent={"center"}>
              <PremiumPricingCard interval={isYearly ? "year" : "month"} />
            </Flex>

            <Flex justifyContent={"center"}>
              <VolumePricingCard interval={isYearly ? "year" : "month"} />
            </Flex>
          </Grid>

          <Text
            fontFamily={"Poppins"}
            padding={"medium"}
            textAlign={"center"}
            fontSize={"smaller"}
          >
            Local currency and taxes may apply
          </Text>
        </Flex>
      </Flex>
    </>
  );
}
