import React from "react";
import RaisedCard from "../../../../components/RaisedCard";
import { Flex } from "@aws-amplify/ui-react";
import Medal from "../../../../components/MedalGold";
import { TeamCardPodium } from "./TeamCardPodium";
import { RankedTeam } from "../../Types/TeamTypes";
import { Carousel } from "react-bootstrap";

type PodiumProps = {
  teams: RankedTeam[];
  medalType: "gold" | "silver" | "bronze";
};

export default function Podium({ teams, medalType }: PodiumProps) {
  return (
    <RaisedCard backgroundColor={"rgba(0, 0, 0, 0.5)"} height={"100%"}>
      <Flex direction={"column"} alignItems={"center"}>
        <Medal
          type={medalType}
          height={medalType === "gold" ? "125px" : "110px"}
        />
        <Carousel
          interval={2000}
          controls={false}
          indicators={false}
          style={{ width: "100%" }}
        >
          {teams?.map((team) => (
            <Carousel.Item>
              <TeamCardPodium team={team} />
            </Carousel.Item>
          ))}
        </Carousel>
      </Flex>
    </RaisedCard>
  );
}
