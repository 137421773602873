import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { connectToChatStream } from "../services/websocket/connectToChatStream";
import { toast } from "react-toastify";
import useUnloadPrevention from "./useUnloadPrevention";
import { useUserContext } from "../context/UserContextProvider";
import posthog from "posthog-js";
import {
  CurriculumPrompt,
  DocumentPrompt,
  PastePrompt,
  TopicPrompt,
  YouTubePrompt,
} from "../features/generate/types";
import { SolutionPrompt } from "../features/generate/types/solution-prompt";
import { SlideTitlePrompt } from "../features/generate/types/slide-title-prompt";
import { SlideDiscussionPrompt } from "../features/generate/types/slide-discussion-prompt";

interface IChatStreamEvents {
  onOpen?: (ev: Event) => void;
  onMessage?: (ev: MessageEvent<any>) => void;
  onClose?: (ev: Event) => void;
  onError?: (error: Error) => void;
  onChat?: (data: string) => void;
}

interface IChatStreamOptions extends IChatStreamEvents {
  streamType?: "full" | "list";
  language?: string | null;
  type?:
    | "solution"
    | "overview"
    | "objectives"
    | "coverage"
    | "vocabulary"
    | "mixed"
    | "yt-mixed"
    | "quiz-title"
    | "quiz-title-yt"
    | "slide-fact-content"
    | "slide-did-you-know-facts"
    | "slide-discussion-questions"
    | "slide-titles"
    | "quiz-title-doc"
    | "doc-mixed"
    | "paste-mixed"
    | "quiz-title-paste"
    | "curriculum";
  initialMessage?: string;
  prompt?:
    | TopicPrompt
    | PastePrompt
    | DocumentPrompt
    | CurriculumPrompt
    | YouTubePrompt
    | SolutionPrompt
    | SlideTitlePrompt
    | SlideDiscussionPrompt;
  think?: boolean;
  curriculumPaths?: string[];
}

export const enum ChatStreamState {
  IDLE,
  CONNECTING,
  THINKING,
  STREAMING,
}

export default function useChatStream(options: IChatStreamOptions) {
  const socketRef = useRef<WebSocket | null>(null);
  const {
    onMessage,
    onOpen,
    onClose,
    onError,
    onChat,
    streamType = "full",
    initialMessage,
    type,
    prompt: inputObject,
    think = true,
    language = "en",
    curriculumPaths,
  } = options;

  const { hasTokens, userRefetch, user } = useUserContext();

  // const [retryCount, setRetryCount] = useState(0);

  const onOpenCallback = useCallback(
    (ev: Event) => {
      onOpen?.(ev);
      if (initialMessage) sendMessage(initialMessage);
      else {
        const message = JSON.stringify({
          type,
          model: user?.modelType === "smarter" ? "gpt-4o" : "gpt-4o-mini",
          input: inputObject,
          language,
          curriculumPaths,
        });
        sendMessage(message);
      }
      setStream("");
      setConnecting(false);
      setConnected(true);
    },
    [inputObject, initialMessage, type, user?.modelType]
  );

  const onMessageCallback = useCallback((ev: MessageEvent<any>) => {
    onMessage?.(ev);
    const data = JSON.parse(ev.data);
    const { action, payload, error } = data;

    switch (action) {
      case "chat":
        setStream((prev) => prev.concat(payload));
        onChat?.(payload);
        break;
      case "disconnect":
        disconnect();
        // setRetryCount(0);

        break;
      case "error":
        disconnect();
        // if (retryCount < 3) {
        //   setRetryCount((prev) => prev + 1);
        //   connectCallback();
        // } else {
        // setRetryCount(0);
        toast.error(error);
        // }

        break;
      default:
        break;
    }
  }, []);

  const onCloseCallback = useCallback((ev: Event) => {
    userRefetch();
    onClose?.(ev);

    socketRef.current = null;
    setConnected(false);
  }, []);

  const connectCallback = useCallback(async () => {
    if (!hasTokens()) return;
    posthog.capture("Chat Stream Connect", {
      type,
      initialMessage,
      inputObject,
      language,
    });

    setConnecting(true);
    socketRef.current = await connectToChatStream({
      onOpen: onOpenCallback,
      onMessage: onMessageCallback,
      onClose: onCloseCallback,
      onError,
    });
  }, [onOpenCallback, onMessageCallback, onCloseCallback]);

  const [connecting, setConnecting] = useState(false);
  const [connected, setConnected] = useState(false);

  const [stream, setStream] = useState<string>("");

  const [output, setOutput] = useState<string[]>([]);

  const parsedStream = useMemo(() => {
    const array = stream.split("###");
    //remove all occurences of starting and ending whitespace and "\n"
    const trimmedArray = array.map((item) => item.replace(/^\s+|\s+$/g, ""));
    //remove all empty strings
    const finalArray = trimmedArray.filter((item) => item !== "");

    return finalArray;
  }, [stream]);

  const thoughts = useMemo(() => parsedStream[0], [parsedStream]);

  const numFinished = useMemo(() => {
    // count the number of "###" in stream
    const count = (stream.match(/###/g) || []).length;
    return count - 1;
  }, [parsedStream]);

  const parsedOutput = useMemo(
    () => parsedStream.slice(think ? 1 : 0),
    [parsedStream]
  );

  const outputDepencyList = useMemo(() => {
    if (streamType === "full") return [parsedOutput];
    else if (streamType === "list") return [numFinished];
    else return [];
  }, [streamType, parsedOutput, numFinished]);

  useEffect(() => {
    onOutputChange();
  }, outputDepencyList);

  const onOutputChange = useCallback(() => {
    if (streamType === "list") {
      const newOutput = parsedOutput.slice(0, numFinished);
      setOutput(newOutput);
    } else if (streamType === "full") {
      setOutput(parsedOutput);
    }
  }, [parsedOutput, numFinished, streamType]);

  const sendMessage = (
    message: string | ArrayBufferLike | Blob | ArrayBufferView
  ) => {
    if (socketRef.current?.readyState !== WebSocket.OPEN) return;
    socketRef.current?.send(message);
  };

  const disconnect = () => {
    socketRef.current?.close();
  };

  const state = useMemo(() => {
    if (connecting) return ChatStreamState.CONNECTING;
    else if (connected) {
      if (parsedOutput.length === 0) return ChatStreamState.THINKING;
      else return ChatStreamState.STREAMING;
    } else return ChatStreamState.IDLE;
  }, [connecting, connected, parsedOutput]);

  useUnloadPrevention(state !== ChatStreamState.IDLE);

  return {
    state,
    connect: connectCallback,
    loading: connecting || connected,
    thinking: connected && parsedOutput.length === 0,
    connected,
    connecting,
    sendMessage,
    disconnect,
    stream,
    thoughts,
    output,
    socket: socketRef.current,
  };
}
