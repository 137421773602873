import { Card, Flex, TextField, View } from "@aws-amplify/ui-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import FullScreenButton from "../../../../layouts/FullScreenButton";
import ExitButton from "../../../../layouts/ExitButton";
import useAsync from "../../../../hooks/useAsync";
import isAuthenticated from "../../../../util/isAuthenticated";
import RaisedButton from "../../../../components/RaisedButton";

export default function EnterGamePin() {
  const navigate = useNavigate();

  const [pinInput, setPinInput] = useState<string>("");
  const [hasError, setHasError] = useState<boolean>(false);

  const isValidInput = () => {
    return pinInput.length === 6;
  };

  const authenticated = useAsync(async () => await isAuthenticated(), []);

  function submitPin(pin: string) {
    if (!isValidInput) {
      setHasError(true);
      return;
    }
    setHasError(false);
    navigate(`/play/join/${pin}`);
  }

  const onChangeNickname = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value.length <= 6) setPinInput(e.target.value);
  };
  return (
    <>
      <Flex height={"100dvh"} minHeight={"100vh"} padding={"xs"} direction="column">
        <Flex justifyContent={"space-between"}>
          <ExitButton
            onExit={() => {
              if (authenticated.value) navigate("/dashboard/library");
              else navigate("/");
            }}
          />
          <FullScreenButton />
        </Flex>
        <Flex height="100%" justifyContent={"center"} alignItems={"center"}>
          <View>
            <Card boxShadow={"large"}>
              <Flex
                direction="column"
                as={"form"}
                onSubmit={() => submitPin(pinInput)}
              >
                <TextField
                   
                  label={"Enter Game PIN"}
                  fontWeight="bold"
                  value={pinInput}
                  hasError={hasError}
                  errorMessage="Invalid PIN"
                  onChange={onChangeNickname}
                ></TextField>
                <RaisedButton
                  isDisabled={!isValidInput}
                  variation="primary"
                  onClick={() => submitPin(pinInput)}
                  type="submit"
                >
                  Join
                </RaisedButton>
              </Flex>
            </Card>
          </View>
        </Flex>
      </Flex>
    </>
  );
}
