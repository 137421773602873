import { Icon } from '@aws-amplify/ui-react'
import React from 'react'

export default function QuitIcon() {
    return (
        <Icon ariaLabel="Quit" fill="currentcolor"
      pathData="M1.908 23.287c-0.435 0.035 -1.003 -0.433 -1.024 -1.033 -0.001 -6.972 -0.002 -13.881 -0.002 -20.789 -0.035 -0.436 0.433 -1.003 1.033 -1.024 4.614 -0.001 9.165 -0.002 13.715 -0.002C16.449 0.726 16.653 1.009 16.653 1.861c0 0.835 -0.006 1.67 0.004 2.504 0.003 0.238 -0.053 0.327 -0.32 0.362 -0.865 0.111 -1.725 0.267 -2.585 0.415 -0.231 0.04 -0.35 -0.011 -0.344 -0.269 0.009 -0.404 0.002 -0.808 0.002 -1.238H4.077v16.456h9.333c0 -0.389 0.002 -0.789 -0.001 -1.189 -0.002 -0.25 0.018 -0.353 0.355 -0.285 0.868 0.176 1.754 0.261 2.629 0.403 0.098 0.016 0.247 0.164 0.248 0.253 0.018 1.006 0.026 2.013 0.004 3.02 -0.009 0.432 -0.382 0.957 -1.023 0.993 -4.614 0.001 -9.165 0.002 -13.715 0.002zm8.849 -12.982c0.057 -0.373 0.34 -0.52 0.652 -0.572 1.405 -0.234 2.813 -0.453 4.222 -0.665 1.043 -0.158 2.089 -0.298 3.134 -0.446 0.19 -0.027 0.394 -0.018 0.566 -0.088 0.093 -0.038 0.191 -0.209 0.186 -0.316 -0.03 -0.677 -0.093 -1.352 -0.133 -2.029 -0.006 -0.111 0.026 -0.24 0.082 -0.336 0.163 -0.278 0.759 -0.365 1.064 -0.161 1.008 0.678 2.032 1.336 3.015 2.049 0.7 0.508 1.355 1.081 2.01 1.647 0.47 0.407 0.912 0.846 1.358 1.279 0.498 0.483 0.561 1.12 0.003 1.657 -1.958 1.882 -4.061 3.576 -6.394 4.977 -0.288 0.173 -0.58 0.182 -0.868 0.009 -0.25 -0.151 -0.297 -0.393 -0.277 -0.664 0.053 -0.713 0.102 -1.425 0.157 -2.187 -0.648 -0.104 -1.291 -0.21 -1.934 -0.312 -0.71 -0.113 -1.419 -0.224 -2.129 -0.334 -0.722 -0.111 -1.445 -0.219 -2.168 -0.33 -0.663 -0.102 -1.334 -0.171 -1.984 -0.326 -0.214 -0.051 -0.375 -0.323 -0.561 -0.556l-0.001 -2.297z">
      
         </Icon>
  )
}