import { deleteDatabaseItem } from "../../../services/database/deleteDatabaseItem";
import { Team, TeamKey } from "../Types/TeamTypes";
import { getTeamPlayers } from "./getTeamPlayers";
import { updatePlayerTeam } from "./updatePlayerTeam";

export async function deleteTeam(lobbyId: string, id: string) {
    try {
        await getTeamPlayers(id).then((players) => {
            players?.forEach(async (player) => {
                await updatePlayerTeam({ playerSessionId: lobbyId, userId: player.userId }, lobbyId);
            });
        })

        await deleteDatabaseItem<Team, TeamKey>("/teams", {
            lobbyId,
            id,
        });
    } catch (err) {
        console.error(err);
    }
}