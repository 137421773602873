import React, { createContext } from "react";
import positiveSound from "../../../../assets/audio/Postive.wav";
import negativeSound from "../../../../assets/audio/Negative.wav";
import useAudio from "../../../../hooks/useAudio";
import { useVolumeContext } from "../../../../context";

export interface IGameAudioContextProviderProps {
  children: React.ReactNode;
}

type GameAudioContext = {
  playPositiveSound?: () => void;
  playNegativeSound?: () => void;
};

const GameAudioContext = createContext<GameAudioContext>({});

export function useGameAudioContext() {
  return React.useContext(GameAudioContext);
}

export default function GameAudioContextProvider(
  props: IGameAudioContextProviderProps
) {
  // const [volume, setVolume] = React.useState<number>(0.2);
  // const musicPlayerRef = React.useRef<HTMLAudioElement>(null);
  // const gongSoundPlayer = React.useRef<HTMLAudioElement>(null);
  // const positiveSoundPlayer = React.useRef<HTMLAudioElement>(null);
  // const negativeSoundPlayer = React.useRef<HTMLAudioElement>(null);
  // const finishSoundPlayer = React.useRef<HTMLAudioElement>(null);

  // const [playing, setPlaying] = React.useState<boolean>(false);

  // React.useEffect(() => {
  //   // if (musicPlayerRef.current) {
  //   //   musicPlayerRef.current.volume = volume;
  //   // }
  //   if (gongSoundPlayer.current) {
  //     gongSoundPlayer.current.volume = volume;
  //   }
  //   if (positiveSoundPlayer.current) {
  //     positiveSoundPlayer.current.volume = volume;
  //   }
  //   if (negativeSoundPlayer.current) {
  //     negativeSoundPlayer.current.volume = volume;
  //   }
  //   if (finishSoundPlayer.current) {
  //     finishSoundPlayer.current.volume = volume;
  //   }
  // }, [volume]);

  const { volume, multiplier } = useVolumeContext();

  const { replay: playPositiveSound } = useAudio({
    src: positiveSound,
    volume,
    volumeMultiplier: multiplier,
  });

  const { replay: playNegativeSound } = useAudio({
    src: negativeSound,
    volume,
    volumeMultiplier: multiplier,
  });

  return (
    <>
      {/* <audio loop ref={musicPlayerRef}></audio> */}
      {/* <audio src={finishSound} ref={finishSoundPlayer}></audio> */}
      <GameAudioContext.Provider
        value={{
          playPositiveSound,
          playNegativeSound,
        }}
      >
        {props.children}
      </GameAudioContext.Provider>
    </>
  );
}
