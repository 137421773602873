import {
  Card,
  Image,
  Flex,
  Text,
  Divider,
  Button,
  CheckboxField,
  Loader,
  View,
} from "@aws-amplify/ui-react";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ControllerIcon from "../../../../components/icons/ControllerIcon";
import useQuizDetails from "../../hooks/useQueryQuizDetails";
import useQuizImage from "../../hooks/useQuizImage";
import useQuizQuestionIds from "../../hooks/useQueryQuizQuestions";
import PlayIcon from "../../../../components/icons/PlayIcon";
import useQuizUser from "../../hooks/useQuizUser";
import { IQuizCardProps } from "../../types/IQuizCardProps";
import RaisedCard from "../../../../components/RaisedCard";
import { ImageComponent } from "../../../../components/ImageComponent";
import { TooltipView } from "../../../../components/TooltipView";
import KebabIcon from "../../../../components/icons/KebabIcon";
import { duplicateQuiz } from "../../../../services/quiz";
import useClickOutsideDetection from "../../../../hooks/useClickOutsideDetection";

export default function DiscoveryQuizCard({
  ...props
}: IQuizCardProps): JSX.Element {
  const {
    quizId,
    width,
    hasButtons,
    onClick,
    selectable,
    onSelect,
    onDeselect,
    selectedIds,
  } = props;

  const navigate = useNavigate();

  const id = useMemo(() => (quizId ? quizId : ""), [quizId]);

  const { title } = useQuizDetails(id);

  const { image } = useQuizImage(id);

  const { questionIds } = useQuizQuestionIds(id);

  const user = useQuizUser(id);

  const selected = useMemo(() => selectedIds?.includes(id), [selectedIds, id]);

  const [showActions, setShowActions] = React.useState(false);
  const [duping, setDuping] = React.useState(false);

  const buttonRef = useClickOutsideDetection<HTMLButtonElement>(
    (e) => {
      if (buttonRef.current?.contains(e.target as Node)) return;
      if (showActions) {
        setShowActions(false);
      }
    },
    [showActions]
  );

  return (
    <>
      <RaisedCard
        width={width}
        padding={"0"}
        position={"relative"}
        id="raised-btn"
        style={{ cursor: "pointer" }}
      >
        <Flex
          direction={"column"}
          minHeight={"100%"}
          onClick={() => {
            onClick?.(id);
          }}
        >
          {/* <Link to={linkTo}> */}
          <Flex
            flex={1}
            grow={1}
            direction={"column"}
            position="relative"
            style={{
              aspectRatio: "16/9",
            }}
          >
            <ImageComponent
              alt={title + " quiz image"}
              image={image}
              autoPickPrompt=""
              objectFit="cover"
              objectPosition="50% 50%"
              backgroundColor="initial"
              height={"200px"}
              opacity="100%"
              borderRadius={"small small 0 0"}
            />

            <View position={"absolute"} top={"5px"} right={"5px"}>
              <TooltipView
                show={showActions}
                placement="left-start"
                tooltipChildren={
                  <Button
                    justifyContent={"start"}
                    ref={buttonRef}
                    variation="menu"
                    size="small"
                    color={"black"}
                    backgroundColor={"transparent"}
                    isLoading={duping}
                    loadingText="Making a copy..."
                    data-attr="make-a-copy"
                    data-ph-capture-attribute-type={"discovery-quiz"}
                    data-ph-capture-attribute-id={quizId}
                    data-ph-capture-attribute-title={title}
                    data-ph-capture-attribute-n-questions={questionIds?.length}
                    onClick={async (e) => {
                      e.stopPropagation();
                      setDuping(true);
                      const newQuiz = await duplicateQuiz(id);
                      window.open(`/quiz/${newQuiz.id}`, "_blank");

                      setDuping(false);
                      setShowActions(false);
                    }}
                  >
                    Make a copy
                  </Button>
                }
              >
                <Button
                  border={"none"}
                  borderRadius={"5rem"}
                  padding={"xxs"}
                  backgroundColor={"overlay.70"}
                  color={"white"}
                  position={"relative"}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowActions(!showActions);
                  }}
                >
                  <KebabIcon />
                </Button>
              </TooltipView>
            </View>
            <Card
              width="60%"
              borderRadius={"5rem"}
              padding={"xxxs"}
              marginTop={"zero"}
              backgroundColor={"overlay.70"}
              textAlign={"center"}
              fontSize={"smaller"}
              fontWeight={"semibold"}
              color={"white"}
              style={{ position: "absolute", bottom: "5px", right: "5px" }}
            >
              {questionIds ? questionIds.length : "?"} Questions
            </Card>
          </Flex>
          {/* </Link> */}

          {/* <Flex height={"4rem"} direction={"column"} gap={"xxxs"}> */}

          <Flex
            padding={"xxxs"}
            paddingLeft={"small"}
            paddingRight={"small"}
            paddingBottom={"small"}
            direction={"column"}
            gap={"xxxs"}
          >
            <Text
              fontSize={"medium"}
              fontWeight={"semibold"}
              isTruncated
              padding={"0"}
            >
              {title}
            </Text>
            {/* <Text padding={'0'} fontSize={'xs'} fontWeight={'bold'} as='span'>0 plays</Text> */}
            <Flex
              direction={"row"}
              padding={"0"}
              columnGap={"xs"}
              alignItems={"center"}
            >
              {!user.loading ? (
                <>
                  <Image
                    borderRadius={"100%"}
                    height={"20px"}
                    width={"24px"}
                    src="/images/icons/user.png"
                    alt="A chatbot"
                    objectFit={"contain"}
                  />

                  <Text padding={"0"} fontSize={"smaller"}>
                    {user.value?.username}
                  </Text>
                </>
              ) : (
                <Loader />
              )}
            </Flex>
            <Text padding={"0"}></Text>
          </Flex>

          {hasButtons && (
            <Flex
              height={"3rem"}
              padding={"xs"}
              justifyContent={"center"}
              direction="column"
            >
              <Divider
                alignSelf={"center"}
                position={"absolute"}
                bottom={"60px"}
                style={{ borderColor: "var(--amplify-colors-neutral-40)" }}
              />
              <Flex position={"absolute"} bottom={"9px"} alignSelf={"center"}>
                <Flex>
                  <Button
                    id={"raised-btn"}
                    gap={"xxs"}
                    variation="primary"
                    backgroundColor={"purple.60"}
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/game/${id}`);
                    }}
                    data-attr="share-or-play"
                    data-ph-capture-attribute-type={"solo-discovery"}
                    data-ph-capture-attribute-quiz-id={quizId}
                    data-ph-capture-attribute-quiz-title={title}
                    data-ph-capture-attribute-quiz-creator={
                      user.value?.creatorId
                    }
                    data-ph-capture-attribute-quiz-username={
                      user.value?.username
                    }
                    data-ph-capture-attribute-number-of-questions={
                      questionIds?.length
                    }
                  >
                    <ControllerIcon />
                    Solo
                  </Button>
                </Flex>
                <Flex>
                  <Button
                    id={"raised-btn"}
                    gap={"xxs"}
                    variation="primary"
                    backgroundColor={"teal.60"}
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/play/${id}`);
                    }}
                    data-attr="share-or-play"
                    data-ph-capture-attribute-type={"host-discovery"}
                    data-ph-capture-attribute-quiz-id={quizId}
                    data-ph-capture-attribute-quiz-title={title}
                    data-ph-capture-attribute-quiz-creator={
                      user.value?.creatorId
                    }
                    data-ph-capture-attribute-quiz-username={
                      user.value?.username
                    }
                    data-ph-capture-attribute-number-of-questions={
                      questionIds?.length
                    }
                  >
                    <PlayIcon />
                    Host
                  </Button>
                </Flex>
              </Flex>
              {/* Turn back on when multiplayer is set up */}
              {/* <Button size={'small'} gap={'xxs'} padding={'xxxs'} variation='primary'><PlayIcon />Host</Button> */}
            </Flex>
          )}
          {/* </Grid> */}
        </Flex>
        {selectable && (
          <CheckboxField
            backgroundColor={"white"}
            borderRadius={"medium"}
            name={"index"}
            value={"yes"}
            label={"Selected"}
            labelHidden
            position={"absolute"}
            size={"large"}
            top={"15px"}
            left={"15px"}
            checked={selected}
            onChange={(e) => {
              if (e.target.checked) onSelect?.(id);
              else onDeselect?.(id);
            }}
          />
        )}
      </RaisedCard>
    </>
  );
}
