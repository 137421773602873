import * as React from "react";
import GameContextProvider, {
  GameContextProviderProps,
} from "./GameContextProvider";
import GameView from "./GameView_new";
import GameAudioContextProvider from "./GameAudioContextProvider";
import { PageDescription, PageIndex, PageTitle } from "../../../../util/SEO";
import { ScreenReaderContextProvider } from "../../../accessibility/context";

type GameProps = Omit<GameContextProviderProps, "children">;

export default function Game(props: GameProps) {
  const { ...contextProviderProps } = props;

  PageTitle("Play 🎮 | Gibbly");
  PageDescription("Test your knowledge with this quiz!");
  PageIndex("");
  React.useEffect(() => {
    PageDescription(
      `Test your knowledge with this quiz! ${contextProviderProps.quiz?.title}.`
    );
  }, [contextProviderProps.quiz?.title]);

  return (
    <GameAudioContextProvider>
      <GameContextProvider
        {...(contextProviderProps as GameContextProviderProps)}
      >
        <ScreenReaderContextProvider>
          <GameView />
        </ScreenReaderContextProvider>
      </GameContextProvider>
    </GameAudioContextProvider>
  );
}
