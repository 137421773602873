import { Icon } from '@aws-amplify/ui-react'
import React from 'react'

export default function EditIcon() {
    return (
        <Icon ariaLabel="Edit" fill="currentcolor"
      pathData="M8.037 16.216c-0.782 -0.782 -1.55 -1.548 -2.315 -2.315 -0.326 -0.327 -0.327 -0.684 -0.016 -1.009a862.381 862.381 0 0 0 2.4 -2.524c0.712 -0.751 1.421 -1.505 2.131 -2.257C10.917 7.388 11.596 6.663 12.28 5.944c0.256 -0.269 0.734 -0.283 0.996 -0.022 1.67 1.664 3.338 3.329 5.001 5 0.327 0.329 0.317 0.652 -0.012 0.97a713.849 713.849 0 0 1 -3.038 2.913c-0.741 0.707 -1.488 1.408 -2.235 2.109 -0.578 0.542 -1.161 1.08 -1.743 1.618 -0.275 0.254 -0.658 0.238 -0.941 -0.044 -0.753 -0.75 -1.503 -1.503 -2.271 -2.27zm14.753 -8.864c-0.215 0.432 -0.48 0.779 -0.848 1.07 -0.425 0.335 -0.786 0.75 -1.194 1.107 -0.341 0.298 -0.626 0.265 -0.969 -0.077 -0.909 -0.906 -1.815 -1.814 -2.722 -2.722l-2.209 -2.209c-0.487 -0.488 -0.533 -0.735 -0.089 -1.235 0.497 -0.559 1.04 -1.081 1.587 -1.593 0.417 -0.39 0.954 -0.421 1.486 -0.352 0.547 0.071 0.976 0.364 1.361 0.759 0.999 1.024 2.013 2.033 3.032 3.036 0.602 0.593 0.757 1.294 0.575 2.095 -0.007 0.03 -0.002 0.063 -0.011 0.119zM5.542 16.689c0.983 0.983 1.949 1.949 2.916 2.917 0.496 0.497 0.435 0.941 -0.202 1.234 -1.11 0.511 -2.213 1.047 -3.353 1.482 -0.857 0.327 -1.755 0.558 -2.699 0.553 -0.405 -0.002 -0.891 -0.483 -0.889 -0.892 0.006 -1.058 0.287 -2.052 0.672 -3.012 0.428 -1.068 0.931 -2.106 1.421 -3.148 0.204 -0.433 0.744 -0.507 1.089 -0.174 0.348 0.336 0.686 0.682 1.044 1.04z">
      
         </Icon>
  )
}