import { v4 } from "uuid";
import { createDatabaseItem } from "../../../services/database/createDatabaseItem";
import { Team } from "../Types/TeamTypes";
import { GameState } from "../Types/GameTypes";

export async function createTeam(lobbyId: string) {
  const team: Omit<Team, "createdAt" | "updatedAt"> = {
    lobbyId,
    id: `tm-${v4()}`,
    gameData: {
      gameState: GameState.WAITING,
      questionIndex: 0,
      currentAnswer: [],
      answerResults: [],
    },
    teamState: "LOBBY",
  };
  await createDatabaseItem<Team>("/teams", team);
  return team as Team;
}
