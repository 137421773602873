import ReactGA from "react-ga4";

import { UaEventOptions } from "react-ga4/types/ga4";

export default function sendCustomEventGA(
  optionsOrName: string | UaEventOptions,
  params?: any
) {
  ReactGA.event(optionsOrName, params);
}
