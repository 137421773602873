import { useQuery } from "@tanstack/react-query";
import { getSlide } from "../services/getSlide";

export function useSlideQuery({
  lessonId,
  slideId,
}: {
  lessonId: string;
  slideId: string;
}) {
  const slide = useQuery(
    ["slide", lessonId, slideId],
    async () => {
      return await getSlide(lessonId, slideId);
    },
    {
      enabled: !!lessonId && !!slideId,
    }
  );

  return slide;
}
