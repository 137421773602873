import { PlayerStatus } from "../Types/GameTypes_dep";
import { Lobby } from "../Types/LobbyTypes";
import { listPlayers } from "./listPlayers";

export async function getPlayerStatus(lobby: Lobby, userId: string): Promise<PlayerStatus> {
  const players = await listPlayers(lobby.id);

  if (players === undefined) throw new Error("players list is undefined");
  // if a player has the same userId as the current user, then they are in the lobby
  const player = players.find((p) => p.userId === userId);

  if (player === undefined) throw new Error("player is undefined");

  return player.playerStatus;
}