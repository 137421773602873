// These are custom queries

export const getQuizDetails = /* GraphQL */ `
  query GetQuizDetails($id: ID!) {
    getQuiz(id: $id) {
      id
      title
      description
      lang
      image {
        key
        identityId
        alt
      }
      Questions {
        items {
          id
          question {
            id
            text
            answers
            correctIndices
            solution
          }
        }
      }
      updatedAt
    }
  }
`;
export const getQuizTitleAndDescription = `
query GetQuizDetails($id: ID!) {
  getQuiz(id: $id) {
    title
    description    
  }
}
`;

export const getSessionByPin = /* GraphQL */ `
  query GetSessionByPin($eq: String!) {
    listSessions(filter: { pin: { eq: $eq } }) {
      items {
        id
      }
    }
  }
`;

export const qetQuizCreator = /* GraphQL */ `
  query GetQuiz($id: ID!) {
    getQuiz(id: $id) {
      creator
    }
  }
`;

export const qetQuizPrivacy = /* GraphQL */ `
  query GetQuiz($id: ID!) {
    getQuiz(id: $id) {
      allowReadTo
    }
  }
`;

export const getQuizImage = /* GraphQL */ `
  query GetQuiz($id: ID!) {
    getQuiz(id: $id) {
      image {
        key
        identityId
        alt
      }
    }
  }
`;

export const getQuizDetails2 = /* GraphQL */ `
  query GetQuiz($id: ID!) {
    getQuiz(id: $id) {
      allowReadTo
      title
      description
      tags
      updatedAt
      lang
    }
  }
`;

export const getQuestionsFromQuiz = /* GraphQL */ `
  query GetQuestionsFromQuiz($quizId: ID!) {
    getQuiz(id: $quizId) {
      Questions {
        items {
          id
          questionId
          sortIndex
          question {
            id
            type
            image {
              key
              identityId
              alt
            }
            text
            answers
            correctIndices
            solution
          }
        }
      }
    }
  }
`;
export const getQuestionsFromQuizMinimal = /* GraphQL */ `
  query GetQuestionsFromQuiz($quizId: ID!) {
    getQuiz(id: $quizId) {
      Questions {
        items {
          id
          sortIndex
          question {
            type
            text
            answers
            correctIndices
            solution
          }
        }
      }
    }
  }
`;

export const getQuestionIdsFromQuiz = /* GraphQL */ `
  query GetQuestionsFromQuiz($quizId: ID!) {
    getQuiz(id: $quizId) {
      Questions {
        items {
          id
          question {
            id
          }
        }
      }
    }
  }
`;

export const getQuizForAssignment_query = /* GraphQL */ `
  query GetQuestionsFromQuiz($quizId: ID!) {
    getQuiz(id: $quizId) {
      id
      title
      description
      lang
      image {
        key
        identityId
        alt
      }
      Questions {
        items {
          question {
            audio {
              key
              identityId
            }
            readAloudText
            type
            text
            answers
            correctIndices
            solution
            image {
              key
              identityId
              alt
            }
          }
        }
      }
    }
  }
`;

export const quizIdsByCreator = /* GraphQL */ `
  query QuizzesByCreator(
    $creator: String!
    $sortDirection: ModelSortDirection
    $filter: ModelQuizFilterInput
    $limit: Int
    $nextToken: String
  ) {
    quizzesByCreator(
      creator: $creator
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;

export const librarySearch = /* GraphQL */ `
  query LibrarySearch(
    $creator: String = ""
    $search: String = ""
    $nextToken: String
  ) {
    searchQuizzes(
      filter: {
        creator: { eq: $creator }
        title: { matchPhrasePrefix: $search }
      }
      limit: 10
      nextToken: $nextToken
    ) {
      nextToken
      items {
        id
      }
    }
  }
`;

export const getCorrectAnswersAndFeedback = `
query GetQuestionsFromQuiz($quizId: ID!) {
  getQuiz(id: $quizId) {
    Questions {
      items {
        question {
          correctIndices
          solution
        }
      }
    }
  }
}
`;

export const getQuizQuestionsFromQuiz = `
query GetQuizQuestionsFromQuiz($quizId: ID!) {
  listQuizQuestions(filter: {quizId: {eq: $quizId}}, limit: 1) {
    items {
      id
      questionId
      quizId
    }
  }
}
`;

export const getGameLobbyInfo = `
query GetGameLobbyInfo($gameID: ID!) {
  getGame(id: $gameID) {
    Players {
      items {
        nickname
      }
    }
    state
  }
}
`;

export const listUsersQuizIds = `
query ListQuizzesForLibrary($filter: ModelQuizFilterInput = {}, $limit: Int = 20, $nextToken: String = null) {
  listQuizzes(limit: $limit, filter: $filter, nextToken: $nextToken) {
    items {
      id
    }
    nextToken
  }
}
`;

export const listPublicQuizIds = `
query searchPublicQuizzes ($nextToken: String = null) {
  quizzesByAllowReadToAndCreatedAt(sortDirection: DESC, allowReadTo: "public", limit: 20, nextToken: $nextToken) {
    items {
      id
    }
    nextToken
  }
}
`;

export const searchPublicQuizzes = `
query searchPublicQuizzes($limit: Int = 20, $titleMatchPhrasePrefix: String = "8", $nextToken: String = null) {
  searchQuizzes(limit: $limit, filter: {title: {matchPhrasePrefix: $titleMatchPhrasePrefix}, and: {allowReadTo: {eq: "public"}}}, nextToken: $nextToken, sort: {field: createdAt, direction: desc}) {
    items {
      id
    }
    nextToken
  }
}
`;
