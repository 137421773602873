import { Icon } from '@aws-amplify/ui-react'
import React from 'react'

export default function RefreshIcon() {
    return (
        <Icon ariaLabel="Save" fill="currentcolor"
      pathData="M8.383 9.089c0.468 -0.34 0.904 -0.696 1.379 -0.986 0.788 -0.482 1.634 -0.84 2.554 -0.993 0.492 -0.081 0.988 -0.144 1.537 -0.224 -0.021 -0.332 -0.054 -0.706 -0.066 -1.081 -0.012 -0.401 -0.028 -0.804 0.004 -1.202 0.02 -0.258 0.477 -0.388 0.769 -0.223 0.664 0.374 1.338 0.734 1.973 1.153 0.799 0.527 1.581 1.084 2.336 1.673 0.511 0.398 0.976 0.859 1.433 1.32 0.267 0.269 0.258 0.625 0.028 0.88 -0.797 0.884 -1.714 1.628 -2.695 2.282 -1.006 0.671 -2.054 1.283 -3.095 1.899 -0.411 0.243 -0.758 0.02 -0.759 -0.449 -0.001 -0.743 0 -1.485 0 -2.241 -0.663 0.132 -1.293 0.434 -1.866 0.845 -0.767 0.55 -1.37 1.258 -1.727 2.133 -0.197 0.484 -0.29 1.015 -0.395 1.533 -0.164 0.811 -0.016 1.598 0.268 2.361 0.453 1.217 1.246 2.153 2.382 2.778 0.744 0.409 1.538 0.695 2.402 0.665 1.303 -0.045 2.485 -0.414 3.496 -1.291 0.822 -0.712 1.364 -1.587 1.653 -2.614 0.279 -0.992 0.229 -1.994 -0.096 -2.974 -0.054 -0.162 -0.032 -0.217 0.11 -0.314 0.676 -0.457 1.338 -0.936 2.054 -1.442 0.082 0.254 0.176 0.509 0.245 0.77 0.251 0.943 0.292 1.912 0.21 2.87 -0.139 1.614 -0.735 3.067 -1.723 4.36 -0.895 1.17 -2.029 2.022 -3.37 2.608 -0.725 0.317 -1.477 0.55 -2.272 0.597 -0.623 0.037 -1.253 0.096 -1.872 0.046 -1.269 -0.101 -2.452 -0.5 -3.55 -1.16 -0.689 -0.414 -1.32 -0.897 -1.858 -1.489 -1.051 -1.157 -1.757 -2.492 -2.086 -4.033 -0.182 -0.852 -0.196 -1.708 -0.14 -2.559 0.083 -1.261 0.498 -2.436 1.143 -3.529 0.432 -0.732 0.976 -1.369 1.594 -1.973z">
      
         </Icon>
  )
}