import {
  Button,
  Flex,
  Grid,
  Heading,
  Text,
  TextField,
} from "@aws-amplify/ui-react";
import React, { useCallback, useState } from "react";
import DeleteIcon from "../../../components/icons/DeleteIcon";
import { useNavigate } from "react-router-dom";
import useConfirmationModal from "../../../hooks/useConfirmationModal";
import useUpdateEffect from "../../../hooks/useUpdateEffect";
import { updateDatabaseItem } from "../../../services/database/updateDatabaseItem";
import { deleteDatabaseItem } from "../../../services/database/deleteDatabaseItem";
import { Classroom, ClassroomKey } from "../types/classroomTypes";
import RaisedCard from "../../../components/RaisedCard";
import EditIcon from "../../../components/icons/EditIcon";
import ControllerIcon from "../../../components/icons/ControllerIcon";
import CheckIcon from "../../../components/icons/CheckIcon";
import RaisedButton from "../../../components/RaisedButton";

export default function ClassCard({
  classroom,
  onChange,
}: {
  classroom: Classroom;
  onChange: () => void;
}) {
  const navigate = useNavigate();

  const [isDeleted, setIsDeleted] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editValue, setEditValue] = useState(classroom.title);

  useUpdateEffect(() => {
    if (editing === false) {
      updateClassroomName();
    }
  }, [editing]);

  const updateClassroomName = useCallback(async () => {
    if (editValue !== classroom.title) {
      await updateDatabaseItem<Classroom, ClassroomKey>(
        "/classrooms",
        { teacherId: classroom.teacherId, id: classroom.id },
        { title: editValue }
      );
      // await updateClassroom(classroom.teacherId, classroom.id, {
      //   title: editValue,
      // });
      onChange();
    }
  }, [editValue, classroom]);

  const [DeleteModal, setShowModal] = useConfirmationModal({
    headerMessage: "Delete Classroom",
    bodyMessage: "This action is irreversible.",
    confirmFn: async () => {
      setIsDeleted(true);
      // await deleteClassroom(classroom);
      await deleteDatabaseItem<Classroom, ClassroomKey>("/classrooms", {
        teacherId: classroom.teacherId,
        id: classroom.id,
      });
      onChange();
    },
  });

  return (
    <>
      <DeleteModal />
      <RaisedCard
        id="raised-btn"
        opacity={isDeleted ? "0.3" : "1"}
        style={{ cursor: "pointer" }}
        onClick={() => {
          navigate(`/classes/${classroom.id}`);
        }}
      >
        <Flex direction={"column"} gap={"small"}>
          <Grid
            alignItems={"center"}
            gap={"small"}
            width={"100%"}
            templateColumns={"1fr auto"}
          >
            {!editing ? (
              <Heading isTruncated>{classroom.title}</Heading>
            ) : (
              <TextField
                 
                label={undefined}
                value={editValue}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => setEditValue(e.target.value)}
                onBlur={() => setEditing(false)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") setEditing(false);
                }}
              />
            )}
            <Flex gap={"small"} justifyContent={"space-between"}>
              <Button
                padding={0}
                gap={"small"}
                variation="menu"
                onClick={(e) => {
                  e.stopPropagation();
                  setEditing((prev) => !prev);
                }}
              >
                <Text fontSize={"xs"}>
                  {editing ? <CheckIcon /> : <EditIcon />}
                </Text>
              </Button>
              <RaisedButton
                variation="menu"
                size="small"
                gap="small"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowModal(true);
                }}
              >
                <DeleteIcon />
              </RaisedButton>
            </Flex>
          </Grid>
          <Text fontWeight={"bold"} fontSize={"small"} color={"purple.100"}>
            <ControllerIcon /> Assigned Quizzes
          </Text>
        </Flex>
      </RaisedCard>
    </>
  );
}

/*
1. I do not have a current good estimate of the number of classrooms, assignments, and students. But i hope that there will be many of them soon.
2. When teachers access their classroom dashboard, it should list all of there currently active classes. When they click on a classroom it should list all of the assignments, and all the students that belong to that classroom. When an assignment is clicked all the submitted results should be listed. When A student is clicked, their submitted results for all assignments of that classroom should be listed.
3. i'm not sure how frequent I expect data modification to occur. Hopefully often.
4. Yes I want to sort results by student name, and by mark.
5. I think consistency is critical for my application.
6. Yes i do expect significant growth.
7. I do not have specific performance requirements.
*/
