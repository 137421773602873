import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { createContext } from "react";
import { getQuestion } from "../services/getQuestion";
import { Question, UpdateQuestionInput } from "../../../API";
import { updateQuestion } from "../services/updateQuestion";
import useTimeout from "../../../hooks/useTimeout";

type QuestionContextData = {
  question: Question | undefined;
  updateQuestion: (variables: Omit<UpdateQuestionInput, "id">) => Promise<void>;
  isLoading: boolean;
};

const QuestionContext = createContext<QuestionContextData>({
  question: undefined,
  updateQuestion: async () => {},
  isLoading: false,
});

type QuestionContextProviderProps = {
  questionId: string;
  children: React.ReactNode;
};

export const useQuestionContext = () => {
  const context = React.useContext(QuestionContext);
  if (context === undefined) {
    throw new Error(
      "useQuestionContext must be used within a QuestionContextProvider"
    );
  }
  return context;
};

export function QuestionContextProvider({
  questionId,
  children,
}: QuestionContextProviderProps) {
  const { data: question, isLoading } = useQuery(
    ["question", questionId],
    async () => getQuestion(questionId)
  );

  const queryClient = useQueryClient();

  const { reset, clear } = useTimeout(() => {
    queryClient.invalidateQueries(["question", questionId]);
  }, 1000 * 1);

  // optimistically update question
  const { mutateAsync: updateQuestion_mutateAsync } = useMutation({
    mutationFn: async (variables: Omit<UpdateQuestionInput, "id">) =>
      updateQuestion({ id: questionId, ...variables }),
    onMutate: async (variables) => {
      clear();
      queryClient.cancelQueries(["question", questionId]);
      queryClient.setQueryData<Question>(["question", questionId], (old) => {
        if (old) {
          return {
            ...old,
            ...(variables as Question),
          };
        }
        return old;
      });
    },
    onSettled: () => {
      reset();
    },
  });

  return (
    <QuestionContext.Provider
      value={{
        question,
        updateQuestion: updateQuestion_mutateAsync,
        isLoading,
      }}
    >
      {children}
    </QuestionContext.Provider>
  );
}
