import { Icon } from '@aws-amplify/ui-react'
import React from 'react'

export default function SoundOffIcon() {
    return (
        <Icon ariaLabel="Sound off" fill="currentcolor"
      pathData="m26.213 10.408.566.564c.368.372.365.867.018 1.252-.576.638-1.135 1.292-1.718 1.924-.167.181-.048.288.045.395.564.65 1.132 1.294 1.707 1.934.301.336.307.771-.013 1.097-.378.385-.762.764-1.144 1.146-.399.397-.86.412-1.275.044-.644-.571-1.289-1.14-1.929-1.713-.092-.083-.147-.132-.267-.023-.62.56-1.259 1.1-1.874 1.665-.484.445-.922.457-1.382-.003l-1.04-1.042c-.403-.404-.423-.857-.054-1.275.569-.644 1.139-1.288 1.711-1.929.077-.086.15-.14.034-.267-.552-.611-1.079-1.244-1.639-1.847-.484-.522-.501-.941-.007-1.435.364-.364.727-.731 1.095-1.092.312-.306.765-.306 1.096-.012.634.563 1.272 1.121 1.904 1.685.179.16.306.178.506-.01a59.644 59.644 0 0 1 1.901-1.688c.364-.315.845-.285 1.195.064l.564.566z M11.7 4.989c.484-.342.877-.752 1.235-1.178.725-.862 2.287-.536 2.622.468.044.132.064.254.064.386 0 6.323-.002 12.646.004 18.969 0 .576-.588 1.217-1.237 1.275-.61.054-1.093-.129-1.528-.577a47.942 47.942 0 0 0-2.529-2.452c-.83-.738-1.558-1.581-2.412-2.292-.142-.118-.27-.136-.429-.136l-4.972.001c-.765-.001-1.413-.655-1.413-1.428 0-2.443.029-4.887-.018-7.329-.013-.719.783-1.524 1.524-1.5 1.471.048 2.946.011 4.419.019a.65.65 0 0 0 .511-.207c.79-.783 1.571-1.579 2.401-2.318.605-.538 1.152-1.13 1.758-1.701z">
      
         </Icon>
  )
}