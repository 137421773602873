import { Grid, ScrollView } from "@aws-amplify/ui-react";
import MenuView from "./menus/MenuView";
import React from "react";
import EditorView from "./editor/EditorView";

export interface IContentViewProps {
}

export default function ContentView(props: IContentViewProps) {
  const { } = props;

  return (
    <ScrollView height={"100%"}>
      <Grid
        height={"100%"}
        templateColumns={{ base: "1fr", medium: "minmax(0, 1fr) minmax(0, 1fr)", large: "auto minmax(0, 1fr)" }}
        templateRows={{ base: "100% 100%", medium: "minmax(0, 1fr)" }}
        gap={"0"}
      >
        <MenuView />
        <EditorView />
      </Grid>
    </ScrollView>
  );
}
