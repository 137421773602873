import { GSIOptions } from "../../../../types/GSIOptions";
import RestAPI from "../../../../util/RestAPI";
import getGSIUrl from "../../../../util/getGSIUrl";
import { Classroom } from "../../types/classroomTypes";

export async function getClassroomByClassId(classId: string) {
  const options: GSIOptions = {
    indexName: "GetByClassId",
    gsiPartitionKeyName: "id",
    gsiPartitionKeyValue: classId,
    gsiPartitionKeyType: "S",
  };

  try {
    const classrooms: Classroom[] = (await RestAPI.get(
      getGSIUrl("/classrooms", options)
    ).then((res) => res.json())) as any as Classroom[];
    return classrooms[0];
  } catch (err) {

    throw err;
  }
}
