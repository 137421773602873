import React from "react";
import useUnloadPrevention from "../../../../hooks/useUnloadPrevention";

import { default as HostLobbyView } from "./HostView.Lobby";
import { default as HostGameView } from "./HostView.GameView";
import { default as HostLeaderboardView } from "./HostView.Leaderboard";
import { default as PlayerLobbyView } from "./PlayerView.Lobby";
import { default as PlayerGameView } from "./PlayerView.GameView";
import { default as PlayerLeaderboardView } from "./PlayerView.Leaderboard";
import useLobbyContext from "../../hooks/useLobbyContext";

export default function LobbyView() {
  useUnloadPrevention();

  const { isHost, lobby } = useLobbyContext();

  if (isHost) {
    switch (lobby?.lobbyState) {
      case "LOBBY":
        return <HostLobbyView />;
      case "IN_GAME":
        return <HostGameView />;
      case "LEADERBOARD":
      case "PODIUM":
        return <HostLeaderboardView />;
      default:
        return <HostLobbyView />;
    }
  } else {
    switch (lobby?.lobbyState) {
      case "LOBBY":
        return <PlayerLobbyView />;
      case "IN_GAME":
        return <PlayerGameView />;
      case "LEADERBOARD":
      case "PODIUM":
        return <PlayerLeaderboardView />;
      default:
        return <PlayerLobbyView />;
    }
  }
  // } else return <PlayerLobbyView />;
}
