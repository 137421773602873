import { TextElement } from "../types/slideTypes";
import { countUnicodeUnits } from "./countUnicodeUnits";

export type TextStyle = {
  startIndex: number;
  endIndex: number;
  bold?: true;
  italic?: true;
  underline?: true;
  backgroundColor?: string;
  color?: string;
  font?: string;
  size?: string;
  link?: string;
};

export function getTextStyles(
  textElement: {
    id: string;
  } & TextElement
) {
  const textStyles: TextStyle[] = [];
  let textStyle: TextStyle = { startIndex: 0, endIndex: 0 };
  let i = 0;
  // let nBreakLines = 0;
  textElement.props.ops.forEach((op) => {
    if (op.insert !== "\n") {
      if (op.attributes) {
        if (op.attributes.bold) textStyle.bold = true;
        if (op.attributes.italic) textStyle.italic = true;
        if (op.attributes.underline) textStyle.underline = true;
        if (op.attributes.color)
          textStyle.color = op.attributes.color as string;
        if (op.attributes.background)
          textStyle.backgroundColor = op.attributes.background as string;
        if (op.attributes.font) textStyle.font = op.attributes.font as string;
        if (op.attributes.size || textElement.props.fontSize)
          textStyle.size =
            (op.attributes.size as string) || textElement.props.fontSize + "pt";
        if (op.attributes.link) textStyle.link = op.attributes.link as string;
      }
      textStyle.startIndex = i;
      textStyle.endIndex =
        textStyle.startIndex + countUnicodeUnits(op.insert as string);
      textStyles.push({ ...textStyle });
      textStyle = { startIndex: 0, endIndex: 0 };
    }
    i += countUnicodeUnits(op.insert as string);
  });
  return textStyles;
}
