import { useState } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useQuery } from "@tanstack/react-query";
import { qetQuizCreator } from "../../../services/custom-queries";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import useEffectOnce from "../../../hooks/useEffectOnce";
import { generateClient } from "aws-amplify/api";

export function useQuizOwnership(id: string) {
  const { user } = useAuthenticator((context) => [context.user]);
  const [isCreator, setIsCreator] = useState(false);

  useEffectOnce(() => {
    refetchIsCreator();
  });

  const { refetch: refetchIsCreator } = useQuery({
    queryKey: ["quiz creator", id],
    enabled: id !== undefined,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    queryFn: async () => {
      const client = generateClient();

      const result = (await client.graphql({
        query: qetQuizCreator,
        variables: { id },
      })) as GraphQLResult<any>;


      if (result.data?.getQuiz.creator === null) throw new Error();
      else return result.data?.getQuiz.creator;
    },
    onSuccess: (data) => {


      if (data === user.username) setIsCreator(true);
    },
  });

  return { isCreator, refetchIsCreator };
}
