import React from "react";
import { Flex, Heading, Text } from "@aws-amplify/ui-react";
import { useTranslationContext } from "../../../context/TranslationContextProvider";

interface QuestionItemFeedbackProps {
  solution: string;
}

export default function QuestionItemSolution({
  solution,
}: QuestionItemFeedbackProps): JSX.Element {
  const { translations } = useTranslationContext();

  return (
    <Flex direction={"column"} gap={"xs"}>
      <Flex direction={"column"}>
        <Heading minWidth={"110px"}>✔ {translations.solution}: </Heading>
        <Text>{solution}</Text>
      </Flex>
    </Flex>
  );
}
