import { Flex, Link, Text } from "@aws-amplify/ui-react";
import React from "react";

import { CopyToClipboard } from "../../../../util/CopyToClipboard";
import useUrl from "../../../../hooks/useUrl";

import { TooltipView } from "../../../../components/TooltipView";
import useLobbyContext from "../../hooks/useLobbyContext";
import { FaCopy } from "react-icons/fa";
import RaisedCard from "../../../../components/RaisedCard";
import QRCodeImage from "../../../../components/QRCodeImage";
import { useTranslationContext } from "../../../../context/TranslationContextProvider";

interface LobbyInfoCardProps {
  // lobby: Lobby;
  // setLobby: (lobby: Lobby) => void;
  // teams: Team[];
}

export default function LobbyInfoCard(props: LobbyInfoCardProps) {
  const {} = props;

  const { translations } = useTranslationContext();

  const { lobby } = useLobbyContext();

  const { root } = useUrl();

  return (
    <Flex
      alignItems={"center"}
      justifyContent={"center"}
      direction={{ base: "column", large: "row" }}
    >
      <RaisedCard
        borderRadius={"5px"}
        padding={"xs xs xs small"}
        style={{
          pointerEvents: "auto",
        }}
      >
        <Flex
          gap={"xl"}
          rowGap={"small"}
          alignItems={"center"}
          direction={{ base: "column", medium: "row" }}
        >
          <Text
            fontWeight={"semibold"}
            fontSize={{ base: "medium", medium: "1.75em" }}
          >
            {translations.join_at}:
            <br /> <b>www.gibbly.co/play/join</b>
          </Text>

          <Flex direction={"column"} gap="0">
            <Text padding={"0"} fontWeight={"bold"} fontSize={"1.2rem"}>
              {translations.game_pin}:
            </Text>
            <TooltipView
              tooltipProps={{ id: "copy-lobby-link-tooltip" }}
              tooltipChildren={
                <Text>
                  <FaCopy /> Copy link
                </Text>
              }
              placement="bottom"
            >
              <Link
                onClick={async () => {
                  await CopyToClipboard(
                    root + `/play/join/${lobby?.pin}`,
                    "Join link copied! 🚀"
                  );
                }}
              >
                <Text
                  lineHeight={".9em"}
                  id="gamePIN"
                  paddingTop={"xs"}
                  paddingBottom={"xs"}
                  color={"#333333"}
                  fontWeight={"black"}
                  fontSize={{ base: "xl", medium: "xxxl" }}
                >
                  {lobby?.pin}
                </Text>
              </Link>
            </TooltipView>
          </Flex>
          <QRCodeImage text={root + `/play/join/${lobby?.pin}`} />
        </Flex>
      </RaisedCard>
    </Flex>
  );
}
