import { Flex } from "@aws-amplify/ui-react";
import React, { useMemo } from "react";
import { ConfigFile, Configuration } from "../types";
import { useQuery } from "@tanstack/react-query";
import { downloadData } from "aws-amplify/storage";
import FileConfigView from "./FileConfigView";

type CurriculumBreadcrumItemProps = {
  selectedFiles: string[];
  setSelectedFiles: (files: string[]) => void;
  setBreadcrumbs: React.Dispatch<React.SetStateAction<string[]>>;
  // documents: CurriculumFile[];
  configFile: {
    name: string;
  } & ConfigFile;
};

export function DocumentSelector({
  selectedFiles,
  setSelectedFiles,
  // documents,
  configFile,
}: CurriculumBreadcrumItemProps) {
  // const allSelected = useMemo(() => {
  //   return documents.length > 0 && selectedFiles.length === documents.length;
  // }, [documents, selectedFiles]);

  const { data: config } = useQuery({
    queryKey: ["config", configFile.path],
    queryFn: async () => {
      const { body } = await downloadData({
        path: `public/curriculum/${configFile.path}`,
      }).result;
      const config = JSON.parse(await body.text()) as Configuration;
      return config;
    },
    enabled: !!configFile,
  });

  const path =
    "public/curriculum/" + configFile.path.replace("/config.json", "");

  const fileOrderMap = useMemo(() => {
    const order = getOrderIndex({
      config,
      path,
    });
    console.log(order);
    return order;
  }, [config, path]);

  return (
    <Flex direction={"column"} gap={"1px"}>
      {config ? (
        <FileConfigView
          config={config}
          path={path}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          fileOrderMap={fileOrderMap}
        />
      ) : null}
      {/* {documents.length > 0 ? (
        <CheckboxField
          size="small"
          name={"select-all"}
          justifyContent={"start"}
          backgroundColor={"background.secondary"}
          padding={"xxs small"}
          checked={allSelected}
          onChange={(e) => {
            setSelectedFiles(() => {
              if (e.target.checked === true) {
                return documents.map((doc) => doc.name);
              }
              return [];
            });
          }}
          label={"Select All"}
        ></CheckboxField>
      ) : null}
      {documents.map((doc) => (
        <CheckboxField
          size="small"
          name={doc.name}
          justifyContent={"start"}
          backgroundColor={"background.secondary"}
          padding={"xxs small"}
          checked={selectedFiles.includes(doc.name)}
          onChange={(e) => {
            setSelectedFiles((prev) => {
              if (e.target.checked) {
                return [...prev, doc.name];
              } else {
                return prev.filter((f) => f !== doc.name);
              }
            });
          }}
          label={doc.name}
        ></CheckboxField>
      ))} */}
    </Flex>
  );
}
function getOrderIndex({
  config,
  mappings = {},
  path,
}: {
  config: Configuration | undefined;
  mappings?: Record<string, number>;
  path: string;
}): Record<string, number> {
  if (!config) {
    return mappings;
  }
  if (config.src) {
    mappings[`${path}${config.src}`] = Object.entries(mappings).length;
  }
  if (config.items) {
    for (const item of config.items) {
      const childMappings = getOrderIndex({
        config: item,
        mappings,
        path,
      });
      mappings = { ...mappings, ...childMappings };
    }
  }
  return mappings;
}
