import {
  Alert,
  Flex,
  Heading,
  Link,
  Text,
  TextAreaField,
} from "@aws-amplify/ui-react";
import * as React from "react";
import { useUserContext } from "../../../context";
import { FaX } from "react-icons/fa6";
import { useQuestionContext } from "../../quiz/context";
import { ReadAloudButton } from "../../accessibility/components";

export interface IReadAloudCreatorProps {}

export function ReadAloudCreator({}: IReadAloudCreatorProps) {
  const { user, isPremium } = useUserContext();
  // const [readAloudText, setReadAloudText] = React.useState<string>("");

  const { updateQuestion, question, isLoading } = useQuestionContext();

  // React.useEffect(() => {
  //   setReadAloudText(question?.readAloudText || "");
  // }, [question?.readAloudText]);

  return (
    <Flex direction={"column"}>
      <Flex direction={"column"}>
        {user?.customerTier === "Starter" ? (
          <Alert
            hasIcon
            variation="info"
            heading={"Read aloud is a premium feature"}
          >
            <Link href="/dashboard/upgrade" isExternal>
              Upgrade now
            </Link>
          </Alert>
        ) : null}

        <Heading>Enter text below to be read aloud.</Heading>

        <Flex direction={"column"} alignItems={"center"} gap={"xxs"}>
          <ReadAloudButton
            text={question?.readAloudText || ""}
            size="70px"
            height={"114px"}
            variation="primary"
            isDisabled={question?.readAloudText === ""}
            borderRadius={"200px"}
            // backgroundColor={"blue.60"}
            // color={"white"}
            padding={"large"}
            border={"none"}
            style={{ aspectRatio: "1" }}
            data-attr={"audio-preview-btn"}
            data-ph-capture-attribute-audio-key={"read-aloud"}
          />
          <Text>Listen to a preview</Text>
        </Flex>
        <TextAreaField
          label={
            <Text width={"100%"} textAlign={"end"} color={"font.tertiary"}>
              {120 - (question?.readAloudText?.length || 0)}
            </Text>
          }
          placeholder="Enter your text here"
          disabled={isLoading}
          maxLength={120}
          value={question?.readAloudText || ""}
          onChange={(e) => {
            if (isPremium()) updateQuestion({ readAloudText: e.target.value });
          }}
        ></TextAreaField>
      </Flex>
      <Flex justifyContent={"space-between"}>
        <Link
          alignSelf={"end"}
          color={"red.90"}
          onClick={async () => {
            await updateQuestion({
              readAloudText: "",
            });
          }}
        >
          <FaX /> Clear text
        </Link>
        <Text color={"font.tertiary"}>Changes saved automatically</Text>
      </Flex>
    </Flex>
  );
}
