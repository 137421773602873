import * as React from "react";

import { useRichTextContext } from "../RichTextContextProvider";

import { Button } from "@aws-amplify/ui-react";
import { MdFormatBold } from "react-icons/md";

export interface BoldProps {}

export default function Bold(props: BoldProps) {
  const {} = props;
  const { setCurrentFormats, currentEditor, currentFormats } =
    useRichTextContext();

  // React.useEffect(() => {

  // }, [currentFormats]);

  const onBold = React.useCallback(() => {
    if (!currentFormats || !currentEditor) {
      return;
    }
    currentEditor.format("bold", !currentFormats.bold);
    setCurrentFormats?.(currentEditor.getFormat());
  }, [currentFormats, currentEditor]);

  return (
    <Button
      size="small"
      variation="link"
      padding={"xxs"}
      backgroundColor={currentFormats?.["bold"] ? "#eee" : undefined}
      onClick={onBold}
    >
      <MdFormatBold />
    </Button>
  );
}
