import {
  Flex,
  Text,
  Menu,
  MenuItem,
  MenuButton,
  MenuProps,
} from "@aws-amplify/ui-react";
import React, { useMemo } from "react";
import { FaBrain } from "react-icons/fa";
import { FaAngleDown, FaBoltLightning } from "react-icons/fa6";
import "../../../styles/model-toggle.css";
import InfoIcon from "../../../components/icons/InfoIcon";
import { PremiumFeatureBadge } from "../../premium/components";
import { useUserContext } from "../../../context/UserContextProvider";

type ModelToggleProps = {
  //   model: "faster" | "smarter";
} & Omit<MenuProps, "trigger">;

export default function ModelToggle({ ...menuProps }: ModelToggleProps) {
  const { isPremium, updateUser, user } = useUserContext();

  const model = useMemo(() => {
    return user?.modelType || "faster";
  }, [user]);

  return (
    <Menu
      {...menuProps}
      size="small"
      className="model-menu"
      //   triggerClassName="model-menu-trigger"
      trigger={
        <MenuButton size="small" className="trigger" gap={"small"}>
          AI Model:{" "}
          {model === "faster" ? (
            <Text>
              <FaBoltLightning color={"#fd7e14"} /> Faster
            </Text>
          ) : (
            <Text>
              <FaBrain color={"#9100ff"} /> Smarter
            </Text>
          )}
          <FaAngleDown />
        </MenuButton>
      }
    >
      <MenuItem
        className="faster-item"
        onClick={() => {
          updateUser({ modelType: "faster" });
        }}
      >
        <Flex
          flex={1}
          gap={"small"}
          justifyContent={"start"}
          alignItems={"center"}
          fontSize={"small"}
        >
          <FaBoltLightning color={"#fd7e14"} />
          Faster
        </Flex>
      </MenuItem>
      <MenuItem
        className="smarter-item"
        onClick={() => {
          if (isPremium()) updateUser({ modelType: "smarter" });
        }}
      >
        <Flex
          flex={1}
          gap={"small"}
          justifyContent={"start"}
          alignItems={"center"}
          fontSize={"small"}
        >
          <FaBrain color={"#9100ff"} />
          Smarter
          <InfoIcon
            message="More intelligent, and better with complexity."
            fontSize={"small"}
          />
          <PremiumFeatureBadge />
        </Flex>
      </MenuItem>
    </Menu>
    // <Button
    //   className={`toggle-switch button`}
    //   backgroundColor={"background"}
    //   gap={"large"}
    //   padding={"xs"}
    //   position={"relative"}
    //   onClick={() => {
    //     setModel(model === "faster" ? "smarter" : "faster");
    //   }}
    // >
    //   <View
    //     className={`toggle-switch container ` + model}
    //     position={"absolute"}
    //     width={"50%"}
    //     height={"100%"}
    //     padding={"xxxs"}
    //     style={{ zIndex: 1 }}
    //   >
    //     <Card
    //       className={`toggle-switch switch ` + model}
    //       width={"100%"}
    //       height={"100%"}
    //       //   backgroundColor={"rgba(255, 255, 255, 0.8)"}
    //     ></Card>
    //   </View>
    //   <Flex
    //     flex={1}
    //     gap={"small"}
    //     padding={"xs"}
    //     width={"140px"}
    //     justifyContent={"center"}
    //     alignItems={"center"}
    //     fontSize={"small"}
    //     style={{ zIndex: 2 }}
    //   >
    //     <FaBoltLightning color={model === "faster" ? "#fd7e14" : undefined} />
    //     Faster
    //   </Flex>
    //   <Flex
    //     flex={1}
    //     gap={"small"}
    //     padding={"xs"}
    //     width={"140px"}
    //     justifyContent={"center"}
    //     alignItems={"center"}
    //     fontSize={"small"}
    //     style={{ zIndex: 2 }}
    //   >
    //     <FaBrain color={model === "smarter" ? "#9100ff" : undefined} />
    //     Smarter
    //     <InfoIcon
    //       message="More intelligent, and better with complexity."
    //       fontSize={"small"}
    //     />
    //   </Flex>
    // </Button>
  );
}
