import { AnswerResult } from "../../../game/Types/GameTypes";

export function calculateScore(answerResults: AnswerResult[]) {

    // get all answerResults with isCorrect = true
    const correctAnswerResults = answerResults.filter(
      (answerResult) => answerResult.isCorrect
    );
    // get the total number of correct answers
    const totalCorrectAnswers = correctAnswerResults.length;
    // get the total number of answers
    const totalAnswers = answerResults.length;
    // return the average score
    if (totalAnswers === 0) {
      return 0;
    }
    return (totalCorrectAnswers / totalAnswers) * 100;
  }