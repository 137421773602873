import { fetchUserAttributes } from "aws-amplify/auth";

export default async function isAuthenticated() {
  try {
    await fetchUserAttributes();
    return true;
  } catch {
    return false;
  }
}
