import * as React from "react";
import { Avatar } from "../../Types/GameTypes_dep";
import { avatarGraphics } from "../../../../assets/avatars";
import { View, Image, Flex, StyleToken, ResponsiveStyle } from "@aws-amplify/ui-react";
import { SpaceKeys } from "@aws-amplify/ui-react/dist/types/primitives/types/theme";
import {Property} from "csstype";
export interface IAvatarImageProps {
  avatar: Avatar;
  size: ResponsiveStyle<SpaceKeys<StyleToken<Property.Width<0 | (string & {})>>>> | undefined;
}

export function AvatarImage(props: IAvatarImageProps) {
  const { avatar, size } = props;

  return (
    <View
      backgroundColor={avatar.backgroundColor}
      width={size}
      height={size}
      borderRadius={"50%"}
      boxShadow={"0 2px 3px 1px rgba(0,0,0,0.1)"}
    >
      <Flex
        width={"100%"}
        height={"100%"}
        padding={"14%"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Image alt={"avatar-image"} className="white-outline" src={avatarGraphics[avatar.index]} />
      </Flex>
    </View>
  );
}
