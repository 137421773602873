import RestAPI from "../../util/RestAPI";

type File = {
  id: string;
  object: "file";
  bytes: number;
  created_at: number;
  filename: string;
  purpose: "fine-tune" | "assistants" | "batch";
};

export async function createFile(path: string): Promise<{ file: File }> {
  try {
    const response = await RestAPI.post(`/openai/files`, {
      body: {
        key: path,
      },
    });

    return (await response.json()) as any as { file: File };
  } catch (err) {
    console.error(err);
    throw new Error("Error creating file");
  }
}

// const fileToBase64 = (file: File): Promise<string> => {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();

//     reader.onload = () => {
//       // Convert array buffer to base64 string
//       const base64String = btoa(String.fromCharCode(...new Uint8Array(reader.result as ArrayBuffer)));
//       resolve(base64String);
//     };

//     reader.onerror = error => reject(error);

//     reader.readAsArrayBuffer(file);
//   });
// };
