import * as React from "react";
import { Player } from "../../Types/GameTypes_dep";
import {
  ResponsiveStyle,
  StyleToken,
  View,
  Text,
  Flex,
} from "@aws-amplify/ui-react";
import { TooltipView } from "../../../../components/TooltipView";
import { getIdentityId } from "../../util";
import useAsync from "../../../../hooks/useAsync";
import { AvatarImage } from "./AvatarImage";
import { SpaceKeys } from "@aws-amplify/ui-react/dist/types/primitives/types/theme";
import { Property } from "csstype";
export interface IPlayerAvatarProps {
  player: Player;
  size?:
    | ResponsiveStyle<SpaceKeys<StyleToken<Property.Width<0 | (string & {})>>>>
    | undefined;
  opacity?: number;
  showName?: boolean;
}

export function PlayerAvatar(props: IPlayerAvatarProps) {
  const { player, size, opacity, showName = true } = props;

  const { value: isMe } = useAsync(async () => {
    const userId = await getIdentityId();
    return player.userId === userId;
  }, [player]);

  return (
    <TooltipView
      tooltipProps={{ id: player.nickname + "-name-tooltip" }}
      tooltipChildren={<View>{player.nickname}</View>}
      placement="bottom"
    >
      <Flex
        position={"relative"}
        direction={"column"}
        justifyContent={"start"}
        alignItems={"center"}
        gap={"xs"}
      >
        <View
          className={isMe ? "border-pulse" : ""}
          // border={isMe ? "4px solid white" : undefined}
          // borderRadius={"50%"}
          opacity={opacity ?? 1}
        >
          <AvatarImage avatar={player.avatar} size={size ?? "70px"} />
        </View>
        {isMe && showName && (
          <Text
            position={"absolute"}
            bottom={"95%"}
            paddingLeft={"xs"}
            paddingRight={"xs"}
            borderRadius={"xs"}
            backgroundColor={"rgba(255,255,255,1)"}
            color={"yellow.60"}
            fontWeight={"bold"}
            style={{
              textShadow:
                "1px 1px purple, -1px -1px  purple, 1px -1px purple, -1px 1px purple",
            }}
          >
            Me
          </Text>
        )}{" "}
      </Flex>
    </TooltipView>
  );
}
