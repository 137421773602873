import { fetchUserAttributes } from "aws-amplify/auth";
import RestAPI from "../../util/RestAPI";
import getUser from "../../util/User/getUser";
import { getCurrentEnvironment } from "../getCurrentEnvironment";

// type CheckoutSession = {

// }

export async function getCheckoutSessionPremiumFreeTrial() {
  try {
    const env = await getCurrentEnvironment();

    const priceId =
      env == "dev"
        ? "price_1OQAqSElTY6nFOWqfo84D974"
        : "price_1OUD94ElTY6nFOWqYL19xHhi";

    const customerId = await getUser().then((user) => user.customerId);
    let email;
    if (!customerId) {
      email = await fetchUserAttributes().then(
        (attributes) => attributes.email
      );
    }

    const response: any = await RestAPI.post(`/stripe/checkout/free-trial`, {
      body: {
        priceId,
        customerId,
        email,
      },
    }).then((response) => response.json() as any);
    return response.checkoutSession;
  } catch (err) {
    throw err;
  }
}
