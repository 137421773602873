import { useQuery } from "@tanstack/react-query";
import { getBillingPortal } from "../../../services/stripe/getBillingPortal";
import React from "react";

export const useBillingPortal = () => {
  const query = useQuery({
    queryKey: ["billing-portal-session", window.location.href],
    queryFn: async () => await getBillingPortal(window.location.href),
  });

  const openBillingPortal = React.useCallback(async () => {
    
    if (!query.data) throw new Error("No billing portal session found");
    window.location.href = query.data.url;
  }, [query.data]);

  return {
    ...query,
    openBillingPortal,
  };
};
