import { Badge, Flex, Heading, Text } from "@aws-amplify/ui-react";
import * as React from "react";
import RaisedButton from "../../../components/RaisedButton";
import { referAFriendModalOptions } from "../../../util/modals/referAFriendModalOptions";
import useModal from "../../../hooks/useModal";
import { FaArrowCircleRight } from "react-icons/fa";

export interface IPremiumFeatureAlertProps {
  onComplete?: () => void;
}

export function PremiumFeatureAlert(props: IPremiumFeatureAlertProps) {
  const { onComplete } = props;

  const [ReferralModal, setReferralModal] = useModal(
    referAFriendModalOptions({ onHide: () => onComplete?.() }),
    []
  );

  return (
    <>
      <ReferralModal />
      <Flex direction={"column"}>
        <Text fontSize={{ base: "smaller", medium: "medium" }}>
          Here are 2 ways to get access to awesome premium features:
        </Text>
        <Flex alignItems={"center"}>
          <Text
            fontWeight={"bold"}
            fontSize={{ base: "smaller", medium: "medium" }}
          >
            <Badge marginRight={"xxxs"}>1</Badge> Upgrade to Premium 👑
          </Text>

          <RaisedButton
            size="small"
            backgroundColor={"#47a6ff"}
            onClick={() => {
              // navigate("/dashboard/upgrade");
              window.open("/dashboard/upgrade", "_blank");
              onComplete?.();
            }}
          >
            <FaArrowCircleRight />
          </RaisedButton>
        </Flex>
        <Text fontSize={{ base: "smaller", medium: "medium" }}>
          <ul>
            <li>Endless quiz and lesson generations</li>
            <li>Unlimited text generation tokens</li>
            <li>Infinite translations</li>
            <li>And much more!</li>
          </ul>
          {/* <ul>
            <li>
              Get unlimited text generation tokens, translation capabilities,
              and unlock endless quiz and lesson generations.{" "}
            </li>
          </ul> */}
        </Text>
        <Flex alignItems={"center"}>
          <Text
            fontWeight={"bold"}
            fontSize={{ base: "smaller", medium: "medium" }}
          >
            <Badge marginRight={"xxxs"}>2</Badge> Refer & Win Together 👫{" "}
          </Text>

          <RaisedButton
            size="small"
            backgroundColor={"#47a6ff"}
            onClick={() => {
              setReferralModal(true);
            }}
          >
            <FaArrowCircleRight />
          </RaisedButton>
        </Flex>
        <Text fontSize={{ base: "smaller", medium: "medium" }}>
          <ul>
            <li>Share your unique referral code with friends.</li>
            <li>
              Each friend who joins gets you 10,000 tokens - and they get 10,000
              too!
            </li>
            <li>
              Bonus: After 5 referrals, grab a{" "}
              <i>1-Month Free Trial of Premium</i>! 🎉
            </li>
          </ul>
        </Text>

        <Heading
          fontSize={{ base: "smaller", medium: "medium" }}
          alignSelf={"center"}
        >
          🧡 Start now and keep the fun going! 🧡
        </Heading>
        {/* <Flex width={"100%"}></Flex> */}
      </Flex>
    </>
  );
}
