import React, { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Flex, Heading, Button, Grid, Text } from "@aws-amplify/ui-react";
import { Avatar } from "../../Types/GameTypes_dep";
import FullScreenButton from "../../../../layouts/FullScreenButton";
import ExitButton from "../../../../layouts/ExitButton";
import { getActiveLobby } from "../../services/getActiveLobby";
import { getIdentityId } from "../../util";

import useAsync from "../../../../hooks/useAsync";
import { getPlayerStatus } from "../../services/getPlayerStatus";
import { getPlayer } from "../../services/getPlayer";
import GibblyLoader from "../../../../components/GibblyLoader";
import useLobbyContext from "../../hooks/useLobbyContext";
import { createPlayerAutoGrouped } from "../../services/createPlayerAutoGrouped";
import { createPlayerUngrouped } from "../../services/createPlayerUngrouped";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { AvatarAndNicknameForm } from "./AvatarAndNicknameForm";
import useLobbyConnections from "../../hooks/useLobbyConnections";
import TranslationContextProvider from "../../../../context/TranslationContextProvider";

export default function JoinLobby() {
  const { pin } = useParams();
  const navigate = useNavigate();

  const {} = useLobbyContext();

  const { data: lobby } = useQuery(
    ["lobby", pin],
    async () => await getActiveLobby(pin ?? ""),
    {
      suspense: true,
      enabled: pin !== undefined,
      onError: () => {
        toast.error("This lobby does not exist");
      },
    }
  );

  const { data: userId } = useQuery<string>(
    ["identity-id"],
    async () => await getIdentityId(),
    { suspense: true }
  );
  const { connections } = useLobbyConnections(pin);

  const existingConnection = useMemo(
    () => connections?.find((c) => c.userId === userId),
    [userId, connections]
  );

  const playerAsync = useAsync(
    async () => {
      if (lobby === undefined) return;
      const userId = await getIdentityId();
      const player = await getPlayer(lobby.id, userId);
      return player;
    },
    [lobby],
    lobby !== undefined
  );

  const playerStatus = useAsync(
    async () => {
      if (lobby === undefined) return;
      const userId = await getIdentityId();
      const playerStatus = await getPlayerStatus(lobby, userId);
      return playerStatus;
    },
    [lobby],
    lobby !== undefined
  );

  const submitNickname = useCallback(
    async (nickname: string, avatar: Avatar) => {
      if (!lobby) return;
      try {
        const userId = await getIdentityId();
        if (lobby.autoTeams) {
          await createPlayerAutoGrouped(lobby.id, userId, nickname, avatar);
        } else {
          await createPlayerUngrouped(lobby.id, userId, nickname, avatar);
        }
        // joinLobby();
        navigate(`/play/lobby/${pin}`);
      } catch (err) {
        console.error(err);
      }
    },
    [lobby, pin]
  );

  if (existingConnection) {
    return (
      <TranslationContextProvider lang={lobby?.quiz.lang}>
        <Grid templateRows={"60px 1fr"} height={"100dvh"} minHeight={"100vh"}>
          <Flex justifyContent={"space-between"}>
            <ExitButton
              onExit={() => {
                navigate(`/play/join`);
              }}
            />{" "}
            <FullScreenButton />
          </Flex>
          <Flex justifyContent={"center"} alignItems={"center"}>
            <Card>
              <Heading>You are already in this lobby</Heading>
            </Card>
          </Flex>
        </Grid>
      </TranslationContextProvider>
    );
  }

  if (!playerAsync.value) {
    return (
      <TranslationContextProvider lang={lobby?.quiz.lang}>
        <Grid templateRows={"60px 1fr"} height={"100dvh"} minHeight={"100vh"}>
          <Flex justifyContent={"space-between"}>
            <ExitButton
              onExit={() => {
                navigate(`/play/join`);
              }}
            />{" "}
            <FullScreenButton />
          </Flex>
          <Flex justifyContent={"center"} alignItems={"center"}>
            <>
              {lobby ? (
                <Card>
                  <AvatarAndNicknameForm
                    randomNicknames={lobby.randomNicknames}
                    onSubmit={async (nickname, avatar) => {
                      await submitNickname(nickname, avatar);
                    }}
                  />
                </Card>
              ) : (
                <Card>
                  <Text>This lobby does not exist</Text>
                </Card>
              )}
            </>
          </Flex>
        </Grid>
      </TranslationContextProvider>
    );
  }

  switch (playerStatus.value) {
    case "NORMAL":
      return (
        <TranslationContextProvider lang={lobby?.quiz.lang}>
          <Grid templateRows={"60px 1fr"} height={"100dvh"} minHeight={"100vh"}>
            <Flex justifyContent={"space-between"}>
              <ExitButton
                onExit={() => {
                  navigate(`/play/join`);
                }}
              />{" "}
              <FullScreenButton />
            </Flex>
            <Flex justifyContent={"center"} alignItems={"center"}>
              <Card boxShadow={"medium"}>
                <Flex direction={"column"} gap={"large"}>
                  <Heading>
                    Rejoin lobby as {playerAsync?.value?.nickname}?
                  </Heading>
                  <Button
                    id={"raised-btn"}
                    backgroundColor={"teal.60"}
                    variation={"primary"}
                    onClick={() => navigate(`/play/lobby/${pin}`)}
                  >
                    Rejoin
                  </Button>
                </Flex>
              </Card>
            </Flex>
          </Grid>
        </TranslationContextProvider>
      );
    case "KICKED":
      return (
        <TranslationContextProvider lang={lobby?.quiz.lang}>
          <Grid templateRows={"60px 1fr"} height={"100dvh"} minHeight={"100vh"}>
            <Flex justifyContent={"space-between"}>
              <ExitButton
                onExit={() => {
                  navigate(`/play/join`);
                }}
              />{" "}
              <FullScreenButton />
            </Flex>
            <Flex justifyContent={"center"} alignItems={"center"}>
              <Card boxShadow={"medium"}>
                <Heading>You've been kicked from this lobby</Heading>
              </Card>
            </Flex>
          </Grid>
        </TranslationContextProvider>
      );
    case "BANNED":
      return (
        <TranslationContextProvider lang={lobby?.quiz.lang}>
          <Grid templateRows={"60px 1fr"} height={"100dvh"} minHeight={"100vh"}>
            <Flex justifyContent={"space-between"}>
              <ExitButton
                onExit={() => {
                  navigate(`/play/join`);
                }}
              />{" "}
              <FullScreenButton />
            </Flex>
            <Flex justifyContent={"center"} alignItems={"center"}>
              <Card boxShadow={"medium"}>
                <Heading>You've been banned from this lobby</Heading>
              </Card>
            </Flex>
          </Grid>
        </TranslationContextProvider>
      );
    default:
      return (
        <TranslationContextProvider lang={lobby?.quiz.lang}>
          <>
            <Grid
              templateRows={"60px 1fr"}
              height={"100dvh"}
              minHeight={"100vh"}
            >
              <Flex justifyContent={"space-between"}>
                <ExitButton
                  onExit={() => {
                    navigate(`/play/join`);
                  }}
                />{" "}
                <FullScreenButton />
              </Flex>
              <Flex justifyContent={"center"} alignItems={"center"}>
                <GibblyLoader />
              </Flex>
            </Grid>
          </>
        </TranslationContextProvider>
      );
  }
}
