import { View, Text } from "@react-pdf/renderer";
import { styles } from "./QuizPDFRenderer_Styles";
import React from "react";
import { QuestionType, QuizQuestion } from "../../../API";
import { Translations } from "../../../types/Translations";

export default function QuizPDFRenderer_Answers({
  quizQuestion,
  translations,
}: {
  quizQuestion: QuizQuestion;
  translations: Translations;
}) {
  switch (quizQuestion.question?.type) {
    case QuestionType.MULTIPLE_SELECT:
      return (
        <>
          <Text style={styles.info}>
            ({translations.select_all_that_apply})
          </Text>
          <View style={styles.answerGroup}>
            {quizQuestion.question &&
              quizQuestion.question.answers.map((answer, j: number) => (
                <View key={j} style={styles.rowFlex}>
                  <View style={{ marginTop: 3 }}>
                    <View style={styles.square} />
                  </View>
                  <Text style={styles.bulletPoint}>
                    {String.fromCharCode(97 + j)}
                  </Text>
                  <Text style={styles.itemContent}>{answer}</Text>
                </View>
              ))}
          </View>
        </>
      );
    case QuestionType.TYPED_ANSWER:
      return <View style={[styles.typedAnswerBox, styles.answerGroup]}></View>;

    default:
      return (
        <View style={styles.answerGroup}>
          {quizQuestion.question &&
            quizQuestion.question.answers.map((answer, j: number) => (
              <View key={j} style={styles.rowFlex}>
                <View style={styles.circle} />
                <Text style={styles.bulletPoint}>
                  {String.fromCharCode(97 + j)}
                </Text>
                <Text style={styles.itemContent}>{answer}</Text>
              </View>
            ))}
        </View>
      );
  }
}
