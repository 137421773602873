import * as React from "react";
import { getIdentityId } from "../../util";
import useLobbyWebSocket from "../../hooks/useLobbyWebSocket";
import { useNavigate, useParams } from "react-router-dom";
import {
  QueryKey,
  UseMutateAsyncFunction,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { Player } from "../../Types/GameTypes_dep";
import { getPlayer } from "../../services/getPlayer";
import { Lobby } from "../../Types/LobbyTypes";
import { NumberedTeam, RankedTeam, Team } from "../../Types/TeamTypes";
import { useLobbyTeams } from "../../hooks/useLobbyTeams";
import { createTeam } from "../../services/createTeam";
import { deleteTeam } from "../../services/deleteTeam";
import { updatePlayer } from "../../services/updatePlayer";
import { updateLobby } from "../../services/updateLobby";
import { updateTeam } from "../../services/updateTeam";
import useLobbyQuery from "../../hooks/useLobbyQuery";
import useLobbyConnections from "../../hooks/useLobbyConnections";
import { listPlayers } from "../../services/listPlayers";
import { toast } from "react-toastify";
import TranslationContextProvider from "../../../../context/TranslationContextProvider";

export interface ILobbyContextProviderProps {
  children: React.ReactNode;
}

interface ILobbyContext {
  players: Player[];
  playerIds: string[];
  lobby?: Lobby;
  teams: Team[];
  rankedTeams: RankedTeam[];
  isHost?: boolean;
  updateLobby: UseMutateAsyncFunction<
    Lobby,
    Error,
    {
      lobby: Lobby;
    },
    unknown
  >;
  updateNumberOfTeams: (numberOfTeams: number) => void | Promise<void>;
  updatePlayer: (player: Player) => void | Promise<void>;
  updateTeam: UseMutateAsyncFunction<
    Team,
    Error,
    {
      team: Team;
      targetGroup?: "team" | "non-team";
    }
  >;
  isUpdatingTeam: boolean;
  player?: Player;
  addTeam: () => void | Promise<void>;
  removeTeam: (teamId: string) => void;
  team: Team | undefined;
  numberedTeam: NumberedTeam | undefined;
  teammates: Player[];
  kickedPlayers: Player[] | undefined;
  broadcastUpdate: (queryKey: QueryKey) => void | Promise<void>;
}

export const LobbyContext = React.createContext({
  isHost: true,
  isUpdatingTeam: false,
} as ILobbyContext);

export default function LobbyContextProvider(
  props: ILobbyContextProviderProps
) {
  const { pin } = useParams();

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const invalidateConnections = () => {
    queryClient.invalidateQueries(["connection"]);
  };

  const { data: userId, isInitialLoading: userIdInitialLoading } =
    useQuery<string>(["identity-id"], async () => await getIdentityId(), {
      suspense: true,
    });

  const { disconnect, sendMessage } = useLobbyWebSocket({
    lobbyPin: pin as string,
    userId: userId ?? "",
    onOpen: invalidateConnections,
    onPlayerConnected: invalidateConnections,
    onPlayerDisconnected: invalidateConnections,
    onError: () => {
      console.error("Error with WebSocket connection");
      toast.info("Looks like you're already connected to this lobby 🤔");
      navigate("/play/join");
    },
    onUpdate: (queryKey: QueryKey) => {

      queryClient.invalidateQueries(queryKey);
    },
  });

  const { connections } = useLobbyConnections(pin);

  const { lobby, lobbyInitialLoading } = useLobbyQuery(pin);

  const { teams, numberedTeams, rankedTeams, setTeams } = useLobbyTeams(lobby);

  const playerIds = connections?.map((c) => c.userId) ?? [];

  const playerQueries = useQueries<Player[]>({
    queries: playerIds.map((id) => ({
      queryKey: ["player", pin, id],
      queryFn: async () => await getPlayer(lobby?.id as string, id),
      enabled: !userIdInitialLoading && !lobbyInitialLoading,
      refetchOnWindowFocus: true,
    })),
  });

  const { data: kickedPlayers } = useQuery<Player[]>({
    queryKey: ["player", pin, "list", "kicked"],
    queryFn: async () => {
      const players = await listPlayers(lobby?.id as string);
      return players.filter((p) => p.playerStatus === "KICKED");
    },
    enabled: !!lobby,
  });

  const updatePlayerWithBroadcast = async (player: Player) => {
    // updatePlayerLocal(player);

    const { playerSessionId, userId, createdAt, updatedAt, ...updateObject } =
      player;


    queryClient.setQueryData<Player>(["player", pin, player.userId], player);

    await updatePlayer(playerSessionId, userId, updateObject).then(() =>
      broadcastUpdatedPlayer(player)
    );
  };

  const broadcastUpdatedPlayer = async (player: Player) => {
    broadcastUpdate(["player", pin, player.userId]);
    broadcastUpdate(["player", pin, "list", "kicked"]);
  };

  /**
   * Broadcast to other clients that an update has been made to some cache item(s)
   * @param queryKey QueryKey that represents the cache items to be invalidated on other clients
   * @param targets Array of user IDs to send the message to
   */
  const broadcastUpdate = async (queryKey: QueryKey, targets?: string[]) => {
    sendMessage({
      type: "UPDATE",
      payload: queryKey,
      targets,
    });
  };

  const players = React.useMemo(
    () =>
      playerQueries
        .map((query) => query.data as Player | undefined)
        .filter((p) => {
          if (p === undefined) {
            return false;
          } else if (p.userId === lobby?.hostId) {
            return !lobby?.hostSpectating;
          } else {
            return true;
          }
        }) as Player[],
    [playerQueries, lobby?.hostSpectating, lobby?.hostId]
  );

  const player = React.useMemo(() => {

    return players.find((player) => player.userId === userId);
  }, [players, userId]);

  React.useEffect(() => {
    if (player?.playerStatus === "KICKED") navigate("/");
  }, [player?.playerStatus]);

  const updateLobby_Mutation = useMutation<
    Lobby,
    Error,
    {
      lobby: Lobby;
    }
  >({
    mutationKey: ["lobby", "update"],
    mutationFn: async ({ lobby }) => await updateLobby(lobby),
    onMutate: ({ lobby }) => {
      queryClient.cancelQueries(["lobby", pin]);
      queryClient.setQueryData<Lobby>(["lobby", pin], lobby);
    },
    onError: (err) => {
      console.error("Error updating lobby: ", err);
      queryClient.invalidateQueries(["lobby", pin]);
    },
    onSuccess: async () => {
      broadcastUpdate(["lobby", pin]);
    },
  });

  const { mutateAsync: updateTeamWithBroadcast, isLoading: isUpdatingTeam } =
    useMutation<
      Team,
      Error,
      {
        team: Team;
        targetGroup?: "team" | "non-team";
      }
    >({
      mutationKey: ["team", "update"],
      mutationFn: async ({ team }) => await updateTeam(team),
      // onMutate: ({ team }) => {


      //   queryClient.cancelQueries(["team", pin, team.id]);
      //   queryClient.setQueryData<Team>(["team", pin, team.id], team);
      // },
      onError: (err, { team }) => {
        console.error("Error updating team: ", err);
        queryClient.invalidateQueries(["team", pin, team.id]);
      },
      onSuccess: async (_, { team, targetGroup }) => {


        queryClient.cancelQueries(["team", pin, team.id]);
        queryClient.setQueryData<Team>(["team", pin, team.id], team);

        const targets =
          targetGroup === "team"
            ? teammates.map((p) => p.userId).filter((id) => id !== userId)
            : targetGroup === "non-team"
            ? players
                .map((p) => p.userId)
                .filter((id) => id !== userId)
                .concat(lobby?.hostId as string)
            : undefined;

        await broadcastUpdate(["team", pin, team.id], targets);
      },
    });

  // const updateTeamWithBroadcast = async (
  //   team: Team,
  //   target?: "team" | "non-team"
  // ) => {
  //   updateTeamLocal(team);

  //   // get teammate ids
  //   const targets =
  //     target === "team"
  //       ? teammates.map((p) => p.userId).filter((id) => id !== userId)
  //       : target === "non-team"
  //       ? players
  //           .map((p) => p.userId)
  //           .filter((id) => id !== userId)
  //           .concat(lobby?.hostId as string)
  //       : undefined;

  //   await updateTeam(team);
  //   broadcastUpdate(["team", pin, team.id], targets);
  // };

  const updateNumberOfTeams_Mutation = useMutation(
    async (numberOfTeams: number) => {
      if (!teams || !lobby) return;
      if (numberOfTeams > teams.length) {
        // Add teams
        for (let i = teams.length; i < numberOfTeams; i++) {
          await addTeamWithBroadcast();
        }
      } else if (numberOfTeams < teams.length) {
        // Remove teams
        for (let i = teams.length - 1; i >= numberOfTeams; i--) {
          const team = teams[i];
          await removeTeamWithBroadcast(team.id);
        }
      }
    }
  );

  const isHost = React.useMemo(() => lobby?.hostId === userId, [lobby, userId]);

  const team = React.useMemo(
    () => teams?.find((team) => team.id === player?.groupId),
    [teams, player?.groupId]
  );

  const teammates = React.useMemo(() => {
    const teammates = players.filter((p) => p.groupId === team?.id);
    // sort teammates alphabetically by nickname
    const sortedTeammates = teammates.sort((a, b) => {
      if (a.nickname < b.nickname) return -1;
      if (a.nickname > b.nickname) return 1;
      return 0;
    });
    return sortedTeammates;
  }, [players, team, lobby]);

  const numberedTeam = React.useMemo(
    () => numberedTeams?.find((team) => team.id === player?.groupId),
    [numberedTeams, player?.groupId]
  );

  const addTeamWithBroadcast = async () => {
    const team = await createTeam(lobby?.id as string);
    setTeams((prev) => {
      return [...(prev ?? []), team.id];
    });
    broadcastUpdate(["team", "list"]);
  };

  const removeTeamWithBroadcast = async (teamId: string) => {
    await deleteTeam(lobby?.id as string, teamId);
    setTeams((prev) => {
      return (prev ?? []).filter((id) => id !== teamId);
    });
    broadcastUpdate(["team", "list"]);
  };

  // useUpdateEffect(() => {
  //   if (isHost && !lobby?.hostSpectating) joinLobby_Callback();
  //   else if (isHost && lobby?.hostSpectating) leaveLobby_Callback();
  // }, [lobby?.hostSpectating, isHost, joinLobby_Callback, leaveLobby_Callback]);

  React.useEffect(() => {
    if (player?.playerStatus === "KICKED") {
      // leaveLobby_Callback();
      disconnect();
      navigate(`/play/join`);
    }
  }, [player?.playerStatus]);

  return (
    <TranslationContextProvider lang={lobby?.quiz.lang || "en"}>
      <LobbyContext.Provider
        value={{
          players: players,
          playerIds,
          lobby: lobby,
          teams: teams as Team[],
          rankedTeams: rankedTeams as RankedTeam[],
          isHost,
          updateLobby: updateLobby_Mutation.mutateAsync,
          isUpdatingTeam,
          updateNumberOfTeams: updateNumberOfTeams_Mutation.mutateAsync,
          updatePlayer: updatePlayerWithBroadcast,
          updateTeam: updateTeamWithBroadcast,
          addTeam: addTeamWithBroadcast,
          removeTeam: removeTeamWithBroadcast,
          player,
          team,
          numberedTeam,
          teammates,
          kickedPlayers,
          broadcastUpdate,
        }}
      >
        {props.children}
      </LobbyContext.Provider>
    </TranslationContextProvider>
  );
}
