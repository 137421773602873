import { DependencyList, useCallback, useEffect, useState } from "react";
import useToggle from "./useToggle";

export default function useAsync<T>(
  callback: () => Promise<T>,
  dependencies: DependencyList,
  enabled = true,
) {
  const [initializing, setInitializing] = useState(true);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>();
  const [value, setValue] = useState<T>();
  const toggle = useToggle(false);

  const callbackMemoized = useCallback(async () => {
    setLoading(true);
    setError(undefined);
    // setValue(undefined)
    try {
      return await callback()
        .then((value) => {
          setValue(value);
          setLoading(false);
          setInitializing(false);
          return value;
        })
        .catch(setError);
    } catch (err) {

      setError(err);
    }
  }, [...dependencies]);

  useEffect(() => {
    if(enabled) callbackMemoized();
  }, [callbackMemoized, toggle[0], enabled]);

  return {
    initializing,
    loading,
    error,
    value,
    reload: toggle[1],
    reloadAsync: callbackMemoized,
  };
}
