import confetti from "canvas-confetti";
import { useEffect, useState } from "react";
import useToggle from "./useToggle";
import useDebounce from "./useDebounce";
import useAudio from "./useAudio";
import { useVolumeContext } from "../context";

export function useCanvasConffeti() {
  useEffect(() => {
    return () => {
      confetti.reset();
    };
  }, []);

  const { start, stop } = useConfettiRain();

  const { fireCannon } = useCannon();

  return {
    confetti: confetti,
    startRain: start,
    stopRain: stop,
    fireCannon,
  };
}

function useConfettiRain() {
  const [active, setActive] = useState(false);
  const [rainDropEvent, triggerRainDrop] = useToggle(true);

  useDebounce(
    () => {
      if (active) {
        createParticle();
        triggerRainDrop();
      }
    },
    25,
    [active, rainDropEvent]
  );

  const createParticle = () => {
    function randomInRange(min: number, max: number) {
      return Math.random() * (max - min) + min;
    }
    const shapes = ["square", "circle"] as confetti.Shape[];
    const colors = [
      "#84E264",
      "#EF933F",
      "#EBE24F",
      "#9D50F7",
      "#2DB5F5",
      "#EF5387",
    ];
    // skew = Math.max(0.8, skew - 0.001);

    const randomShape = shapes[Math.floor(Math.random() * shapes.length)];
    const randomColor = colors[Math.floor(Math.random() * colors.length)];

    confetti({
      particleCount: 1,
      startVelocity: 0,
      origin: {
        x: (Math.random() - 0.5) * 1.1 + 0.5,
        y: Math.random() - 0.2,
      },
      ticks: 300,
      colors: [randomColor],
      shapes: [randomShape],
      gravity: randomInRange(0.5, 0.8),
      scalar: randomInRange(1.25, 1.75),
      drift: randomInRange(-0.3, 0.3),
    });
  };

  const start = () => {
    setActive(true);
  };

  const stop = () => {
    setActive(false);
  };

  return { start, stop };
}

function useCannon() {
  const confettiSound = "/audio/sounds/Confetti - Special & Powerup (35).wav";

  const { volume } = useVolumeContext();

  const { replay: playConfettiSound } = useAudio({
    src: confettiSound,
    volumeMultiplier: 1.25,
    volume,
  });

  const cannon = (side: "left" | "right") => {
    var count = 200;
    var defaults = {
      scalar: 1.5,
      angle: side === "left" ? 55 : 125,
      origin: side === "left" ? { x: 0, y: 1 } : { x: 1, y: 1 },
    };

    function fire(particleRatio: number, opts: any) {
      confetti({
        ...defaults,
        ...opts,
        particleCount: Math.floor(count * particleRatio),
      });
    }

    playConfettiSound();

    fire(0.25, {
      spread: 26,
      startVelocity: 55 * 2,
    });
    fire(0.2, {
      spread: 60,
    });
    fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 35 * 2,
      decay: 0.92,
      scalar: 1.2,
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 50 * 2,
    });
  };

  return { fireCannon: cannon };
}
