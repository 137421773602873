import posthog, { CaptureOptions, Properties } from "posthog-js";
import ReactGA from "react-ga4";

export default function sendCustomEvent(
  eventName: string,
  properties?: Properties | null | undefined,
  options?: CaptureOptions | undefined
) {
  posthog.capture(eventName, properties, options);
  ReactGA.event(eventName, properties);
}

// OLD
// import ReactGA from "react-ga4";
// import { UaEventOptions } from "react-ga4/types/ga4";

// export default function sendCustomEvent(
//   optionsOrName: string | UaEventOptions,
//   params?: any
// ) {
//   ReactGA.event(optionsOrName, params);
// }
