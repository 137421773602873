import {
  Card,
  Flex,
  Heading,
  ScrollView,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@aws-amplify/ui-react";
import React, { useMemo } from "react";
import { Assignment, AssignmentResult } from "../../types";
import { ResultsTableRow } from "./ResultsTableRow";
import { QuestionNumberButton } from "./QuestionNumberButton";

export default function ResultsTable({
  assignment,
  assignmentResults,
}: {
  assignment: Assignment;
  assignmentResults: AssignmentResult[];
}) {

  const questions = useMemo(() => {
    return assignment.quiz.Questions?.items?.map((quizQuestion) => {
      return quizQuestion?.question;
    });
  }, [assignment]);

  return (
    <>
      <Card boxShadow={"large"}>
        <Heading paddingBottom={"xs"}>Results</Heading>
        <ScrollView>
          <Table size="small" variation={"striped"}>
            <TableHead>
              <TableRow>
                <TableCell as="th" width={"75px"} textAlign={"center"}>
                  Student
                </TableCell>
                <TableCell as="th" width={"75px"} textAlign={"center"}>
                  Attempt
                </TableCell>
                <TableCell as="th" width={"75px"} textAlign={"center"}>
                  Score
                </TableCell>
                <TableCell as="th" width={"500px"}>
                  <Flex justifyContent="start">
                    {questions?.map(
                      (question, i) => (
                        // <Text>Hi</Text>
                        <QuestionNumberButton
                          key={i}
                          question={question}
                          qNumber={i+1}
                        />
                      )
                    )}
                  </Flex>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {assignmentResults.map((result) => (
                <ResultsTableRow key={result.id} assignmentResult={result} questions={questions}  />
              ))}
            </TableBody>
          </Table>
        </ScrollView>
      </Card>
    </>
  );
}
