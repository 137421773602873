import React from "react";
import { FaGraduationCap } from "react-icons/fa";
import { QuizContext } from "./QuizContextProvider";
import RaisedButton from "../../../components/RaisedButton";

import { useMutation } from "@tanstack/react-query";
import "./styles/ShareToClassroomButton.css";
import { downloadQuizToQTIZip } from "../../canvas";
import { toast } from "react-toastify";

export default function ShareToCanvasButton() {
  const { quiz } = React.useContext(QuizContext);

  const {
    mutateAsync: createCanvasQuiz_mutateAsync,
    isLoading: isCreatingQuiz,
  } = useMutation({
    mutationFn: () => downloadQuizToQTIZip(quiz?.id),
    // exportQuizToCanvas(courseId, quiz?.id as string),
    // onSuccess: ({ html_url }) => {
    // open in new tab
    // window.open(html_url, "_blank");
    // },
    onError: (error: Error) => {
      toast.error("Failed to create Canvas quiz: " + error.message);
    },
  });

  // const {
  //   // mutateAsync: createCanvasCourse_mutateAsync,
  //   isLoading: isCreatingCanvasCourse,
  // } = useMutation({
  //   mutationFn: async (title: string) => {
  //     if (!quiz) throw new Error("No quiz to create classroom for");
  //     const classroom = await createCanvasCourse(title);
  //     // await createCanvasQuiz_mutateAsync({
  //     //   classroomId: classroom.id,
  //     //   quizId: quiz.id,
  //     //   dueDate: 0,
  //     // });
  //     return classroom;
  //   },
  //   onSuccess: () => {
  //     queryClient.invalidateQueries(["classrooms"]);
  //   },
  //   onError: (error: Error) => {
  //     toast.error(error.message);
  //   },
  // });

  return (
    <RaisedButton
      backgroundColor={"blue.60"}
      whiteSpace={"nowrap"}
      gap={"small"}
      color={"white"}
      variation={"primary"}
      isLoading={
        isCreatingQuiz
        //  || isCreatingCanvasCourse
      }
      loadingText="Exporting..."
      data-attr="share-or-play"
      data-ph-capture-attribute-type={"to-canvas"}
      data-ph-capture-attribute-quiz-id={quiz?.id}
      data-ph-capture-attribute-quiz-title={quiz?.title}
      data-ph-capture-attribute-quiz-creator={quiz?.creator}
      data-ph-capture-attribute-number-of-questions={
        quiz?.Questions?.items.length
      }
      data-ph-capture-attribute-quiz-privacy={quiz?.allowReadTo}
      data-ph-capture-attribute-quiz-language={quiz?.lang}
      data-ph-capture-attribute-quiz-created-at={quiz?.createdAt}
      data-ph-capture-attribute-quiz-updated-at={quiz?.updatedAt}
      onClick={() => {
        createCanvasQuiz_mutateAsync();
      }}
    >
      <FaGraduationCap />
      QTI .ZIP for Canvas
    </RaisedButton>
  );
}
