import { DynamoDBItem } from "../../types/DynamoDBTypes";
import RestAPI from "../../util/RestAPI";

export async function createDatabaseItem<T extends DynamoDBItem>(
  endpoint: string,
  item: Omit<T, "createdAt" | "updatedAt">
): Promise<T> {
  try {
    const now = new Date(Date.now()).toISOString();
    const response: any = await RestAPI.post(endpoint, {
      body: { ...item, createdAt: now, updatedAt: now },
    }).then((response) => response.json() as any);
    return response.data as T;
  } catch (err) {
    throw new Error("Error creating item");
  }
}

// Usage:
// const newStudent = await createDatabaseItem<Student>("/students", student);
// const newClassroom = await createDatabaseItem<Classroom>("/classrooms", classroom);
