import { Heading } from "@aws-amplify/ui-react";
import React from "react";
import { useQuestionContext } from "../context";

interface QuestionItemTextProps {}

export default function QuestionItemText({}: QuestionItemTextProps): JSX.Element {
  const { question } = useQuestionContext();

  return <Heading padding={"xxxs"}>{question?.text}</Heading>;
}
