import React from "react";

import RaisedButton from "../components/RaisedButton";

import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  Loader,
  Text,
  TextField,
} from "@aws-amplify/ui-react";

import { TooltipView } from "../components/TooltipView";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  createLessonLink,
  deleteLessonLink,
} from "../services/lesson/lesson-link";
import { LessonContext } from "../features/lesson/components/LessonContextProvider";
import { SlidesContext } from "../features/lesson/components/SlidesContextProvider";
import { FaArrowLeft, FaCheckCircle } from "react-icons/fa";
import useListLessonLinksQuery from "../hooks/lessons/useListLessonLinskQuery";

export default function ViewLinkMenu({
  lessonId,
  onBack,
  onDelete,
}: {
  lessonId: string;
  onBack: () => void;
  onDelete?: () => void;
}) {
  const queryClient = useQueryClient();

  // const [menu, setMenu] = useState<"create" | "copy">("create");

  const { mutateAsync: deleteLink, isLoading: deletingLessonLink } =
    useMutation(
      ["delete-lesson-link"],
      async ({ lessonId, linkId }: { lessonId: string; linkId: string }) => {
        await deleteLessonLink(linkId, lessonId);
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["lesson-link"]);
          onDelete?.();
        },
      }
    );
  const { lessonLinks, isLoading: lessonLinksIsLoading } =
    useListLessonLinksQuery(lessonId);

  const { mutateAsync: createLink, isLoading: creatingLessonLink } =
    useMutation(
      ["create-lesson-link"],
      async () => await createLessonLink(lessonId),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["lesson-link"]);
        },
      }
    );

  const lessonLink = lessonLinks?.[0];

  const { lesson } = React.useContext(LessonContext);

  const { slideIds } = React.useContext(SlidesContext);

  const link = `${window.location.origin}/lesson/shared/${lessonId}/${lessonLink?.linkId}`;

  return (
    <>
      <Flex alignItems={"center"}>
        <Button
          variation="link"
          backgroundColor={"transparent"}
          onClick={(e) => {
            e.stopPropagation();
            onBack();
          }}
        >
          <FaArrowLeft />
        </Button>
        <Heading>Public view link</Heading>
      </Flex>
      <Flex direction="column">
        <Divider
          padding={"0"}
          style={{ borderColor: "var(--amplify-colors-neutral-60" }}
          size="small"
        />
        {lessonLinksIsLoading ? (
          <Loader />
        ) : lessonLink !== undefined ? (
          <>
            <Text textAlign={"start"}>
              Share this lesson publicly. Anyone with the link can access a
              view-only version. No sign-in required.
            </Text>

            <Grid
              justifyContent={"center"}
              templateColumns={"1fr auto"}
              gap={"xs"}
            >
              <TextField
                label={"Public view link"}
                labelHidden
                size="small"
                flex={1}
                value={link}
              ></TextField>
              <TooltipView
                trigger={"focus"}
                tooltipChildren={
                  <Flex gap={"xxxs"} alignItems={"center"}>
                    <FaCheckCircle
                      color="var(--amplify-colors-green-60"
                      fontSize={"small"}
                    />{" "}
                    Copied to clipboard
                  </Flex>
                }
              >
                <Flex justifyContent={"center"} alignItems={"center"}>
                  <RaisedButton
                    backgroundColor={"#ff7b00"}
                    size="small"
                    fontSize={"small"}
                    padding={"xxs xs xs xs"}
                    onClick={(e) => {
                      e.stopPropagation();
                      navigator.clipboard.writeText(link);
                    }}
                    data-attr="share-lesson-slides"
                    data-ph-capture-attribute-type={"public-view-link"}
                    data-ph-capture-attribute-topic={lesson.topic.toLowerCase()}
                    data-ph-capture-attribute-title={lesson.title}
                    data-ph-capture-attribute-grade-level={lesson.gradeLevel}
                    data-ph-capture-attribute-reading-level={
                      lesson.readingLevel
                    }
                    data-ph-capture-attribute-language={lesson.lang}
                    data-ph-capture-attribute-number-of-slides={slideIds.length}
                  >
                    Copy
                  </RaisedButton>
                </Flex>
              </TooltipView>
            </Grid>

            <Divider
              padding={"0"}
              style={{ borderColor: "var(--amplify-colors-neutral-60" }}
              size="small"
            />
            <Button
              size="small"
              justifyContent={"center"}
              backgroundColor={"background.tertiary"}
              isLoading={deletingLessonLink}
              onClick={(e) => {
                e.stopPropagation();
                deleteLink({ lessonId, linkId: lessonLink?.linkId });
              }}
            >
              Delete public view link
            </Button>
          </>
        ) : (
          <>
            <Text textAlign={"start"}>
              Create a public view link to allow anyone to access a view-only
              version of this lesson, no sign-in required. This can be deleted
              at any time.
            </Text>
            <RaisedButton
              backgroundColor={"#ff7b00"}
              size="small"
              fontSize={"small"}
              padding={"xxs xs xs xs"}
              isLoading={creatingLessonLink}
              onClick={(e) => {
                e.stopPropagation();
                if (lessonLinks?.length === 0) {
                  createLink();
                }
              }}
              data-attr="create-public-view-link"
              data-ph-capture-attribute-type={"public-view-link"}
              data-ph-capture-attribute-lesson-id={lesson.id}
              data-ph-capture-attribute-title={lesson.title}
              data-ph-capture-attribute-grade-level={lesson.gradeLevel}
              data-ph-capture-attribute-reading-level={lesson.readingLevel}
              data-ph-capture-attribute-language={lesson.lang}
              data-ph-capture-attribute-number-of-slides={slideIds.length}
            >
              Create public view link
            </RaisedButton>
          </>
        )}
      </Flex>
    </>
  );
}
