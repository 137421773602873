import React, { useRef } from "react";
import { Button, Flex, SliderField } from "@aws-amplify/ui-react";
//import ExpandIcon from "../components/icons/ExpandIcon";
import SoundOnFullIcon from "../components/icons/SoundOnFullIcon";
import SoundHalfFullIcon from "../components/icons/SoundOnHalfFullIcon";
import SoundOffIcon from "../components/icons/SoundOffIcon";
import PlayIcon from "../components/icons/PlayIcon";
import { FaPause } from "react-icons/fa";
import useAudio from "../hooks/useAudio";

type AudioControllerProps = React.DetailedHTMLProps<
  React.AudioHTMLAttributes<HTMLAudioElement>,
  HTMLAudioElement
> & {
  volumeMultiplier?: number;
  volume: number;
  setVolume: React.Dispatch<React.SetStateAction<{ value: number }>>;
};

/**
 * This component is used to control audio playback and volume.
 * It takes all the same props as the HTMLAudioElement
 * and adds a isDisabled prop to disable the audio controls.
 * If not src is provided, the audio controls will be disabled.
 * @param props - AudioControllerProps
 * @returns AudioController component
 */
export default function AudioController(props: AudioControllerProps) {
  const { src, loop, autoPlay, volumeMultiplier, volume, setVolume } = props;

  const { play, pause, playing } = useAudio({
    src,
    loop,
    autoPlay,
    volume,
    volumeMultiplier,
  });

  const preMuteVolumeRef = useRef<number>();
  function toggleMute() {
    setVolume((prev) => {
      if (prev.value > 0) {
        preMuteVolumeRef.current = prev.value;
        return { value: 0 };
      }
      if (preMuteVolumeRef.current) {
        const v = preMuteVolumeRef.current;
        preMuteVolumeRef.current = undefined;
        return { value: v };
      } else return { value: 0.1 };
    });
  }

  return (
    <>
      <Flex height={"100%"} gap={"xs"} alignItems={"center"}>
        {src ? (
          <Button
            borderRadius={"30px"}
            padding={"xxs"}
            variation={"menu"}
            fontSize={{ base: "xs", small: "larger" }}
            color="white"
            onClick={playing ? () => pause?.() : () => play?.()}
          >
            {playing ? <FaPause /> : <PlayIcon />}
          </Button>
        ) : null}
        <Button
          borderRadius={"30px"}
          padding={"xxs"}
          variation={"menu"}
          fontSize={{ base: "xs", small: "larger" }}
          color="white"
          // isDisabled={disabled}
          onClick={() => toggleMute()}
        >
          <SoundIcon />
        </Button>
        <Flex flex={1}>
          <SliderField
            width={"100%"}
            label={"Volume"}
            labelHidden
            isValueHidden
            value={volume}
            onChange={(v) => setVolume({ value: v })}
            size={"small"}
            filledTrackColor={"teal.60"}
            min={0}
            max={1}
            step={0.02}
          />
        </Flex>
      </Flex>
    </>
  );
  function SoundIcon() {
    if (volume <= 0) return <SoundOffIcon />;
    else if (volume <= 0.5) return <SoundHalfFullIcon />;
    else return <SoundOnFullIcon />;
  }
}
