import { CurriculumFile } from "../types";

export function getFilesAtBreadcrumb(
  files: CurriculumFile[],
  breadcrumb: string[]
): CurriculumFile[] {
  let currentFiles = files;
  for (let i = 0; i < breadcrumb.length; i++) {
    const crumb = breadcrumb[i];
    console.log("Checking Crumb: ", crumb);
    const targetFile = currentFiles.find((f) => {
      return f.name === crumb;
    });
    if (!targetFile) {
      return [];
    }
    console.log("Found File matching crumb: ", targetFile);
    if (targetFile.type === "folder") {
      currentFiles = targetFile.children;
    } else {
      return [];
    }
  }
  return currentFiles;
}
