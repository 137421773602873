import * as React from "react";
import { AnswerResult } from "../../../game/Types/GameTypes";
import { Question } from "../../../../API";
import { AnswerPreviewOptions } from "./AnswerPreviewOptions";
import { Card, Flex, Grid, Text } from "@aws-amplify/ui-react";
import { ImageComponent } from "../../../../components/ImageComponent";
import { PlayAudioButton, ReadAloudButton } from "../../../accessibility";

export interface IAnswerPreviewProps {
  question: Question | null | undefined;
  answerResult: AnswerResult;
}

export function AnswerPreview(props: IAnswerPreviewProps) {
  const { question, answerResult } = props;

  return (
    <Flex direction={"column"} gap={"small"}>
      <Card width={"100%"} height={"100%"} padding={"0"}>
        <Grid
          templateColumns={{ base: "1fr", medium: "1fr 1fr" }}
          templateRows={{ base: "150px 1fr", medium: "1fr" }}
          rowGap={"xxs"}
          templateAreas={{
            base:
              question?.image !== null
                ? '"image" "question"'
                : '"question" "question"',
            medium:
              question?.image !== null
                ? '"question image"'
                : '"question question"',
          }}
          height={"100%"}
        >
          <Card
            backgroundColor={"background.secondary"}
            area={"question"}
            minHeight={"125px"}
            position={"relative"}
            borderRadius={"large"}
          >
            <Flex width={"100%"} height={"100%"} justifyContent={"center"}>
              <Flex position={"absolute"} top={"small"} left={"small"}>
                {question?.audio ? (
                  <PlayAudioButton audio={question.audio} />
                ) : null}
                {question?.readAloudText ? (
                  <ReadAloudButton text={question.readAloudText} />
                ) : null}
              </Flex>
              <Text
                id="displayText"
                // area={"question"}
                color={"black"}
                fontSize={{ base: "medium", medium: "large" }}
                fontWeight={"600"}
                textAlign={"center"}
                alignSelf={"center"}
              >
                {question?.text}
              </Text>
            </Flex>
          </Card>
          {question?.image && (
            <Flex
              area={"image"}
              justifyContent={{ base: "center", medium: "right" }}
              alignItems={"center"}
            >
              <ImageComponent
                canEdit={false}
                alt={`Question Image`}
                maxHeight={{ base: "150px", medium: "225px" }}
                borderRadius={"large"}
                // loading="eager"
                image={question?.image}
                objectFit={"contain"}
                border={"1px solid background.secondary"}
                autoPickPrompt={""}
              ></ImageComponent>
            </Flex>
          )}
        </Grid>
      </Card>
      <AnswerPreviewOptions question={question} answerResult={answerResult} />
    </Flex>
  );
}
