import { Flex, Heading } from "@aws-amplify/ui-react";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

export default function FreeTrial() {
  const [params] = useSearchParams();

  React.useEffect(() => {

  }, [params]);

  return (
    <Flex
      width={"100%"}
      height={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Heading>Enjoy your 30 day free trial!</Heading>
    </Flex>
  );
}
