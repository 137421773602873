import { useQuery } from "@tanstack/react-query";
import RestAPI from "../../../util/RestAPI";
import { FreeTrial } from "../types";
import { User } from "../../../types/User";

export const useFreeTrials = ({
  user,
  onSuccess,
}: {
  user: User | undefined;
  onSuccess?: (data: FreeTrial[]) => void;
}) => {
  const query = useQuery({
    queryKey: ["free-trial", "list", user?.identityId],
    queryFn: async () => {
      return await RestAPI.get("/free-trials").then(async (res) => {
        return (await res.json()) as any as FreeTrial[];
      });
    },
    onSuccess,
    enabled: !!user,
    refetchInterval: 1000 * 15,
  });

  return query;
};
