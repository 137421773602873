import React, { useCallback, useEffect } from "react";
import { Route, Routes, useSearchParams } from "react-router-dom";
import { QuizEditor } from "../features/quiz";
import Library from "../pages/Library";
import Discovery from "../pages/Discovery";
import useUrl from "../hooks/useUrl";
import DashboardLayout from "../layouts/DashboardLayout";
import useModal from "../hooks/useModal";
import UserSetupModal from "../components/Modals/UserSetupModal";
import { useAuthenticator } from "@aws-amplify/ui-react";
import getCreatorId from "../util/GetCreatorId";
import RestAPI from "../util/RestAPI";
import GibblyAcademy from "../pages/GibblyAcademy";
import FreeTrial from "../features/stripe/components/FreeTrial";
import Purchase from "../features/stripe/components/Purchase";
import Pricing from "../pages/Pricing";
import { User } from "../types/User";

export default function DashboardRoutes() {
  const url = useUrl();

  const [SetupModal, setShowSetupModal] = useModal(
    {
      title: "Setup Your Account",
      // initShow: true,
      // size:"lg",
      ReactComponent: () => <UserSetupModal setShowModal={setShowSetupModal} />,
      hasExitButton: false,
    },
    []
  );
  const [FreeTrialModal, setShowFreeTrialModal] = useModal(
    {
      title: "Thank you!",

      ReactComponent: () => <FreeTrial />,
      onHide: () => {
        setParams({});
      },
    },
    []
  );
  const [PurchaseModal, setShowPurchaseModal] = useModal(
    {
      title: "Thank you!",

      ReactComponent: () => <Purchase />,
      onHide: () => {
        setParams({});
      },
    },
    []
  );

  const { user } = useAuthenticator((context) => {
    return [context.user];
  });

  useEffect(() => {
    checkUserExists();
  }, [user]);

  const checkUserExists = useCallback(async () => {

    if (user) {
      const creatorId = await getCreatorId();
      // check if User exists in database
      const result = (await RestAPI.get(`/users/${creatorId}`).then((res) =>
        res.json()
      )) as any as User;

      if (Object.keys(result).length > 0) {
        // if so, do nothing
        return;
      }
      // if not, show modal
      else setShowSetupModal(true);
    }
  }, [user]);

  const [params, setParams] = useSearchParams();

  useEffect(() => {
    if (params.get("startedFreeTrial") === "true") setShowFreeTrialModal(true);
    if (params.get("purchasePremium") === "true") setShowPurchaseModal(true);
  }, [params]);

  return (
    <>
      <FreeTrialModal />
      <PurchaseModal />
      <SetupModal />
      <DashboardLayout>
        <Routes>
          <Route index element={<Library />} />
          {/* <Route index element={<GenerateQuizForm />} />  */}
          {/* <Route path='generate' element={<GenerateQuizForm />} /> */}
          <Route path=":id" element={<QuizEditor key={url.routePath} />} />
          <Route path="library" element={<Library />} />
          <Route path="discover" element={<Discovery />} />
          <Route path="academy" element={<GibblyAcademy />} />
          <Route path="free-trial" element={<FreeTrial />} />
          <Route path="purchase" element={<Purchase />} />
          <Route path="upgrade" element={<Pricing />} />
        </Routes>
      </DashboardLayout>
    </>
  );
}
