import React, { useState } from "react";
import { Collection, Flex, View } from "@aws-amplify/ui-react";

import QuestionItem from "./QuestionItem";
import { QuizQuestion } from "../../../API";
import AddQuestionHereButton from "./AddQuestionHereButton";
import { QuizContext } from "./QuizContextProvider";
import { Droppable } from "../../../components/Droppable";
import { QuestionContextProvider } from "../context";

type QuestionListProps = {
  // TODO: make type for quizQuestion list
  quizQuestions?: QuizQuestion[];
  isCreator: boolean;
  onDragStart?: (i: number) => void | Promise<void>;
  onDrag?: (e: React.DragEvent<HTMLDivElement>) => void | Promise<void>;
  onDragEnd?: (e: React.DragEvent<HTMLDivElement>) => void | Promise<void>;

  // onClickAddQuestion?: (index: number) => void | Promise<void>;
};

export default function QuestionsList({
  quizQuestions,
  isCreator,
}: QuestionListProps) {
  const { setSelectedQuestionIndex, setAddQuestionModal, moveQuestion } =
    React.useContext(QuizContext);

  const [dragIndex, setDragIndex] = useState<number>();
  const [draggedQuestionIndex, setDraggedQuestionIndex] = useState<number>();

  const handleDrag = React.useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      if (!isCreator) return;
      const { clientX, clientY } = event;

      const slideElements = Array.from(
        document.querySelectorAll(".question-item")
      );

      let dropIndex = undefined;

      for (let i = 0; i < slideElements.length; i++) {
        const currElementRect = slideElements[i].getBoundingClientRect();
        const nextElementRect =
          i < slideElements.length - 1
            ? slideElements[i + 1].getBoundingClientRect()
            : undefined;

        const currCenterY = (currElementRect.top + currElementRect.bottom) / 2;
        const nextCenterY = nextElementRect
          ? (nextElementRect.top + nextElementRect.bottom) / 2
          : undefined;

        if (clientX < currElementRect.left || clientX > currElementRect.right) {
          break;
        }

        if (!nextCenterY && clientY > currCenterY) {
          dropIndex = i + 1;
          break;
        }
        if (nextCenterY && clientY > currCenterY && clientY < nextCenterY) {
          dropIndex = i + 1;
          break;
        } else if (i === 0 && clientY <= currCenterY) {
          dropIndex = 0;
          break;
        }
      }
      if (
        draggedQuestionIndex !== undefined &&
        (draggedQuestionIndex === dropIndex ||
          draggedQuestionIndex + 1 === dropIndex)
      ) {
        dropIndex = undefined;
      }

      setDragIndex(dropIndex);

      // Now you have the index at which to insert the dragged slide.
    },
    [draggedQuestionIndex]
  );

  return (
    <Droppable
      enabled={isCreator}
      height={"100%"}
      dragIdKey={"drag-slide-key"}
      onDragOver={handleDrag}
      onDropItem={async () => {
        if (dragIndex !== undefined && draggedQuestionIndex !== undefined) {
          let newIndex = dragIndex;
          if (dragIndex > draggedQuestionIndex) newIndex = dragIndex - 1;
          else if (dragIndex < draggedQuestionIndex) newIndex = dragIndex;

          await moveQuestion(draggedQuestionIndex, newIndex);
        }
        setDragIndex(undefined);
      }}
    >
      <Flex direction={"column"} gap={"0"}>
        {quizQuestions && (
          <Collection
            items={quizQuestions}
            height={"100%"}
            paddingTop={"xxs"}
            type="grid"
            gap="0"
            // autoRows={"200px"}
          >
            {(quizQuestion, i) => (
              <React.Fragment key={i}>
                <View
                  className={
                    "horizontal-drag-placeholder" +
                    (i === dragIndex ? " selected" : "")
                  }
                  height={"100%"}
                />
                <View key={quizQuestion.id} className="question-item">
                  {isCreator && (
                    <AddQuestionHereButton
                      key={i}
                      index={i}
                      onClick={(index) => {
                        // await onClickAddQuestion?.(index);
                        setSelectedQuestionIndex(index);
                        setAddQuestionModal(true);
                      }}
                    />
                  )}
                  <QuestionContextProvider questionId={quizQuestion.questionId}>
                    <QuestionItem
                      quizQuestion={quizQuestion}
                      quizQuestionId={quizQuestion.id}
                      index={i}
                      isCreator={isCreator}
                      onDragStart={(i) => setDraggedQuestionIndex(i)}
                      onDrag={handleDrag}
                      onDragEnd={() => setDraggedQuestionIndex(undefined)}
                    />
                  </QuestionContextProvider>
                </View>
              </React.Fragment>
            )}
          </Collection>
        )}
        {isCreator && (
          <>
            <View
              className={
                "horizontal-drag-placeholder" +
                (quizQuestions?.length === dragIndex ? " selected" : "")
              }
              height={"100%"}
            />
            <AddQuestionHereButton
              key={quizQuestions?.length ? quizQuestions.length : 0}
              index={quizQuestions?.length ? quizQuestions.length : 0}
              onClick={(index) => {
                // await onClickAddQuestion?.(index);
                setSelectedQuestionIndex(index);
                setAddQuestionModal(true);
              }}
            />
          </>
        )}
      </Flex>
    </Droppable>
  );
}
