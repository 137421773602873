import React from "react";
import { CreateQuestionInput, QuestionType } from "../../../API";

import EditQuestion from "./EditQuestion";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createQuestion } from "../services/createQuestion";
import useUpdateLogger from "../../../hooks/useUpdateLogger";

interface CreateQuestionFormProps {
  index: number | undefined;
  quizId: string | undefined;
  onSubmit?: () => void;
}

export default function CreateQuestionForm(props: CreateQuestionFormProps) {
  const { index, quizId, onSubmit } = props;

  const queryClient = useQueryClient();
  const { mutateAsync: createQuestion_mutateAsync } = useMutation({
    mutationFn: ({
      questionInput,
      index,
    }: {
      questionInput?: CreateQuestionInput;
      index: number;
    }) =>
      createQuestion(
        quizId as string,
        questionInput as CreateQuestionInput,
        index
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(["quiz questions"]);
      queryClient.invalidateQueries(["quiz", "game"]);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const [question, setQuestion] = React.useState<CreateQuestionInput>({
    text: "",
    type: QuestionType.SINGLE_SELECT,
    answers: ["", "", "", ""],
    correctIndices: [] as number[],
    solution: "",
    image: {
      key: "",
      identityId: "",
      alt: "",
    },
  });

  useUpdateLogger(question, "question");

  return (
    <EditQuestion
      index={index}
      onChange={(question) =>
        setQuestion((prev) => ({
          ...prev,
          ...(question as CreateQuestionInput),
        }))
      }
      onSubmit={() => {
        createQuestion_mutateAsync({
          questionInput: question,
          index: index ?? 0,
        });
        onSubmit?.();
      }}
      question={question}
    />
  );
}
