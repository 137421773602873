import React, { MouseEventHandler } from "react";
import { Button, Flex, Text } from "@aws-amplify/ui-react";
import { useTranslationContext } from "../../../context/TranslationContextProvider";

export default function PreventExit({
  onStay,
  onLeave,
}: {
  onStay: MouseEventHandler<HTMLButtonElement>;
  onLeave: MouseEventHandler<HTMLButtonElement>;
}) {
  const { you_will_leave_game, stay, leave } =
    useTranslationContext().translations;

  return (
    <Flex direction={"column"} alignItems={"center"} padding={"small"}>
      <Text>{you_will_leave_game}</Text>
      <Flex flex={1}>
        <Button
          variation="primary"
          width={"120px"}
          backgroundColor={"#de3721"}
          id="raised-btn"
          flex={1}
          onClick={onLeave}
        >
          {leave}
        </Button>
        <Button
          variation="primary"
          width={"120px"}
          backgroundColor={"#428019"}
          id="raised-btn"
          flex={1}
          onClick={onStay}
        >
          {stay}
        </Button>
      </Flex>
    </Flex>
  );
}
