import { Grid, View } from "@aws-amplify/ui-react";
import * as React from "react";
import SlideTimeline from "./SlideTimeline";
import { LessonContext } from "../LessonContextProvider";
import SlideView from "./SlideView";
import { SlidesContext } from "../SlidesContextProvider";
import RichTextContextProvider from "../RichTextContextProvider";
import RichTextToolbar from "./RichTextToolbar";
import { useSlideQuery } from "../../hooks/useSlideQuery";

export interface ISlideEditorProps {}

export default function SlideEditor(props: ISlideEditorProps) {
  const {} = props;

  const { isOwner, lesson } = React.useContext(LessonContext);

  const { slidePreview, slideId } = React.useContext(SlidesContext);

  const { data: slide } = useSlideQuery({ lessonId: lesson.id, slideId });

  return (
    <RichTextContextProvider>
      <Grid
        templateRows={"auto minmax(0, 1fr) auto"}
        paddingBottom={{ base: "xxxl", small: "small" }}
        templateColumns={"minmax(0,1fr)"}
        gap={"medium"}
        padding={"0"}
      >
        {isOwner ? <RichTextToolbar /> : <div></div>}
        <View padding="0 large">
          {slidePreview ? (
            <SlideView
              isStatic
              slide={slidePreview}
              idPrefix={"editor-preview-"}
            />
          ) : slide ? (
            <SlideView slide={slide} isStatic={!isOwner} idPrefix={"editor-"} />
          ) : null}
        </View>
        <SlideTimeline />
      </Grid>
    </RichTextContextProvider>
  );
}
