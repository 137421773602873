import { Flex, Grid, Text } from "@aws-amplify/ui-react";
import * as React from "react";

import { RankedTeam } from "../../Types/TeamTypes";

import { PlayerAvatar } from "./PlayerAvatar";
import RaisedCard from "../../../../components/RaisedCard";
import useLobbyContext from "../../hooks/useLobbyContext";
import { useTranslationContext } from "../../../../context/TranslationContextProvider";

export interface ITeamCardPodiumProps {
  team: RankedTeam;
}

export function TeamCardPodium(props: ITeamCardPodiumProps) {
  const { team } = props;

  const { players } = useLobbyContext();

  const teamPlayers = players.filter((player) => player.groupId === team.id);

  const { translations } = useTranslationContext();

  return (
    <RaisedCard width={"100%"} backgroundColor={"rgba(0, 0, 0, 0.1)"}>
      <Grid width={"100%"} height={"100%"} templateRows={"auto 1fr"}>
        <Flex alignSelf={"start"} alignItems={"start"}>
          <RaisedCard
            backgroundColor={"white"}
            textAlign={"center"}
            fontWeight={"bold"}
            padding={"xxs xs xxxs xs"}
            fontSize={{ base: "xs", medium: "small" }}
          >
            <Text isTruncated>
              {translations.team} {team.number}
            </Text>
          </RaisedCard>
        </Flex>
        <Flex gap={"0"} justifyContent={"center"} wrap={"wrap"}>
          {teamPlayers.map((player) => (
            <PlayerAvatar
              key={player.userId}
              player={player}
              size={{ base: "60px", medium: "80px", large: "100px" }}
              showName={false}
            />
          ))}
        </Flex>
      </Grid>
    </RaisedCard>
  );
}
