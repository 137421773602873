import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Slide } from "../types/slideTypes";
import { deleteSlide } from "../services/deleteSlide";

export function useDeleteSlide({
  lessonId,
  slideId,
}: {
  lessonId: string;
  slideId: string;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["slide", "remove"],
    mutationFn: async () => {
      await deleteSlide(lessonId, slideId);
    },
    onMutate: () => {
      queryClient.setQueryData<Slide[]>(["slides", lessonId], (prev) =>
        prev ? prev.filter((slide) => slide.id !== slideId) : []
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(["slides"]);
    },
  });
}
