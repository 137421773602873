import { View, Flex, CheckboxField, Text } from "@aws-amplify/ui-react";
import React, { useCallback, useEffect, useMemo } from "react";
import { Configuration } from "../types";
import { useQuery } from "@tanstack/react-query";
import { downloadData } from "aws-amplify/storage";

type Props = {
  config: Configuration;
  level?: number;
  path: string;
  breadcrumbs?: string[];
  selectedFiles: string[];
  setSelectedFiles: (files: string[]) => void;
  fileOrderMap: Record<string, number>;
};

export default function FileConfigView({
  config,
  level = 0,
  path,
  selectedFiles,
  setSelectedFiles,
  fileOrderMap,
}: Props) {
  const checked = useMemo(() => {
    return selectedFiles.includes(`${path}${config.src}`);
  }, [selectedFiles, config, path]);

  const { data: section } = useQuery({
    queryKey: ["config", "section", `${path}${config.src}`],
    queryFn: async () => {
      const { body } = await downloadData({
        path: `${path}${config.src}`,
      }).result;
      const contents = await body.text();

      // get first line of text
      const lines = contents.replaceAll("\r", "").split("\n");
      const title = lines[0];
      const description = lines[1];

      return {
        title,
        description,
      };
    },
  });

  // const selectFile = useCallback(() => {}, []);

  const handleSelect = useCallback(() => {
    const files = getFiles(config, selectedFiles, path, fileOrderMap);

    if (checked) {
      setSelectedFiles(
        sortFiles(
          selectedFiles.filter((f) => !files.includes(f)),
          fileOrderMap
        )
      );
    } else {
      setSelectedFiles(
        sortFiles([...selectedFiles, `${path}${config.src}`], fileOrderMap)
      );
    }
  }, [checked, config, setSelectedFiles, path]);

  useEffect(() => {
    console.log(selectedFiles);
  }, [selectedFiles]);

  return (
    <View paddingTop={config.items ? "xs" : "0"}>
      <Flex direction={"column"} gap={"0"} opacity={checked ? 1 : 0.6}>
        {section?.title ? (
          <CheckboxField
            size="small"
            name={section.title ?? ""}
            justifyContent={"start"}
            checked={checked}
            onChange={() => {
              handleSelect();
            }}
            label={
              <Text fontSize={"small"} fontWeight={"semibold"}>
                {section.title}
              </Text>
            }
          ></CheckboxField>
        ) : null}
        {section?.description ? (
          <Text fontSize={"xs"} paddingLeft={"19px"}>
            {section.description}
          </Text>
        ) : null}
        {config.name || config.identifier ? (
          <CheckboxField
            size="small"
            name={config.identifier ?? "" + config.name ?? ""}
            justifyContent={"start"}
            checked={checked}
            onChange={() => {
              handleSelect();
            }}
            label={
              <Text fontSize={"small"} fontWeight={"semibold"}>
                {config.identifier} {config.name}
              </Text>
            }
          ></CheckboxField>
        ) : null}
        <Text fontSize={"xs"} paddingLeft={"18px"}>
          {config.description}
        </Text>
      </Flex>
      {config.items && (checked || level === 0) ? (
        <Flex
          direction={"column"}
          paddingLeft={((level + 1) * 5).toString() + "px"}
          gap={"xs"}
        >
          {/* <Text fontSize={"small"} color={"font.tertiary"}>
            {config.children.type}
          </Text> */}
          {config.items.map((item) => (
            <FileConfigView
              config={item}
              level={level + 1}
              path={path}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              fileOrderMap={fileOrderMap}
            />
          ))}
        </Flex>
      ) : null}
    </View>
  );
}

function getFiles(
  config: Configuration,
  selectedFiles: string[],
  path: string,
  fileOrderMap: Record<string, number>
): string[] {
  return [
    ...(config.src ? [`${path}${config.src}`] : []),
    ...(config.items
      ? config.items.flatMap((item) =>
          getFiles(item, selectedFiles, path, fileOrderMap)
        )
      : []),
  ]
    .flat()
    .sort((a, b) => {
      return (
        (a in fileOrderMap ? fileOrderMap[a] : 0) -
        (b in fileOrderMap ? fileOrderMap[b] : 0)
      );
    });
}

function sortFiles(files: string[], fileOrderMap: Record<string, number>) {
  return files.sort((a, b) => {
    return (
      (a in fileOrderMap ? fileOrderMap[a] : 0) -
      (b in fileOrderMap ? fileOrderMap[b] : 0)
    );
  });
}
