import { useRef, useEffect, useState } from "react";

type Props = {
  src: string | undefined;
  autoPlay?: boolean;
  loop?: boolean;
  playbackRate?: number;
  preservesPitch?: boolean;
  volumeMultiplier?: number;
  volume?: number;
  onPlay?: () => void;
  onPause?: () => void;
  onError?: () => void;
};

const useAudio = ({
  src,
  autoPlay = false,
  loop = false,
  playbackRate = 1,
  preservesPitch = false,
  volumeMultiplier = 1,
  volume,
  onPlay,
  onPause,
  onError,
}: Props) => {
  const audioRef = useRef<HTMLAudioElement>(
    document.body.appendChild(new Audio())
  );

  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    // add audio element DOM

    audioRef.current.src = src || "";
    audioRef.current.autoplay = autoPlay;
    audioRef.current.loop = loop;
    audioRef.current.playbackRate = playbackRate;
    audioRef.current.preservesPitch = preservesPitch;
    // audioRef.current.id = "audio-element";
    // }
  }, [src, autoPlay, loop, playbackRate, preservesPitch]);

  useEffect(() => {
    if (audioRef.current) {
      const v = Math.min(Math.max((volume ?? 0.2) * volumeMultiplier, 0), 1);

      audioRef.current.volume = v;
    }

    // return () => {
    //   if (audioRef.current) {
    //     audioRef.current.onerror = () => {
    //       console.error("Error playing audio");
    //     };
    //   }
    // };
  }, [volume, volumeMultiplier]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.onended = () => {
        setPlaying(false);
      };
      audioRef.current.onpause = () => {
        onPause?.();
        setPlaying(false);
      };
      audioRef.current.onplay = () => {
        onPlay?.();
        setPlaying(true);
      };
      audioRef.current.onerror = () => {
        onError?.();
        setPlaying(false);
      };
    }
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.src = "";
      }
    };
  }, [audioRef.current]);

  const play = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  const pause = () => {
    if (audioRef.current) {
      audioRef.current.pause();
    }
  };

  const stop = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  };

  const replay = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
      audioRef.current.play();
    }
  };

  const setSrc = (src: string) => {
    if (audioRef.current) {
      audioRef.current.src = src;
    }
  };

  //   useEffect(() => {
  //     return () => {
  //       if (audioRef.current) {
  //         audioRef.current.pause();
  //         audioRef.current.src = "";
  //       }
  //     };
  //   }, []);

  return {
    audioRef,
    play,
    pause,
    replay,
    setSrc,
    playing,
    stop,
  };
};

export default useAudio;
