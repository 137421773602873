import { updateDatabaseItem } from "../../../services/database/updateDatabaseItem";
import { Team, TeamKey } from "../Types/TeamTypes";

export async function updateTeam(updateTeam: Team) {
  const { lobbyId, id, createdAt, updatedAt, ...updateObject } = updateTeam;

  const team = await updateDatabaseItem<Team, TeamKey>(
    "/teams",
    {
      lobbyId,
      id,
    },
    updateObject
  );
  return team;
}
