import { updateDatabaseItem } from "../../../services/database/updateDatabaseItem";
import { Slide, SlideKey } from "../types/slideTypes";

export async function updateSlide(
  lessonId: string,
  id: string,
  updateObject: Partial<Slide>
) {
  const slide = await updateDatabaseItem<Slide, SlideKey>(
    "/slides",
    { lessonId, id },
    { ...updateObject }
  );
  return slide;
}
