import React, { useEffect, useMemo, useState } from "react";
import useUnloadPrevention from "../../../../hooks/useUnloadPrevention";

import { Card, Flex, Grid, Heading, View } from "@aws-amplify/ui-react";

import useLobbyContext from "../../hooks/useLobbyContext";

import "../../../../styles/podium.css";
import Podium from "./Podium";
import ArrowLeftIcon from "../../../../components/icons/ArrowLeftIcon";
import RefreshIcon from "../../../../components/icons/RefreshIcon";
import RaisedButton from "../../../../components/RaisedButton";
import AudioController from "../../../../layouts/AudioController";
import useAudio from "../../../../hooks/useAudio";
import LobbyHeaderButtons from "./LobbyHeaderButtons";
import { useCanvasConffeti } from "../../../../hooks/useCanvasConfetti";
import { useTranslationContext } from "../../../../context/TranslationContextProvider";
import { RankedTeam } from "../../Types/TeamTypes";
import { useVolumeContext } from "../../../../context";

type LeaderboardViewProps = {};

enum AnimationPhase {
  Idle = 0,
  FinalStandings,
  First,
  Second,
  Third,
}

const podiumSound = "/audio/sounds/Podium - Special & Powerup (38).wav";
const winSound = "/audio/sounds/Winner - Positive 4.wav";
const drumRollSound = "/audio/sounds/Drum roll 3.wav";

export default function PodiumView({}: LeaderboardViewProps) {
  useUnloadPrevention();

  const { rankedTeams, lobby, updateLobby } = useLobbyContext();

  const { volume, setVolume } = useVolumeContext();

  const { replay: playDrumRoll } = useAudio({
    src: drumRollSound,
    volumeMultiplier: 1.25,
    playbackRate: 0.8,
    preservesPitch: true,
    volume,
  });

  const { replay: playPodiumSound1 } = useAudio({
    src: podiumSound,
    playbackRate: 0.75,
    volumeMultiplier: 1.25,
    volume,
  });
  const { replay: playPodiumSound2 } = useAudio({
    src: podiumSound,
    playbackRate: 0.9,
    volumeMultiplier: 1.25,
    volume,
  });
  const { replay: playPodiumSound3 } = useAudio({
    src: podiumSound,
    playbackRate: 1.1,
    volumeMultiplier: 1.25,
    volume,
  });
  const { replay: playWinSound } = useAudio({
    src: winSound,
    volume,
    volumeMultiplier: 1,
  });

  const {
    1: first,
    2: second,
    3: third,
  } = useMemo(
    // () => Object.groupBy(rankedTeams, (team) => team.rank),
    () =>
      rankedTeams.reduce(
        (
          acc: {
            [key: number]: RankedTeam[];
          },
          team
        ) => {
          if (team.rank === 1 || team.rank === 2 || team.rank === 3) {
            if (acc[team.rank] === undefined) {
              acc[team.rank] = [];
            }
            acc[team.rank].push(team);
          }
          return acc;
        },
        {}
      ),
    [rankedTeams]
  );

  const [animationPhase, setAnimationPhase] = useState<AnimationPhase>(0);
  const [volumeMult, setVolumeMult] = useState(1);

  const [animating, setAnimating] = useState(false);

  const { confetti, startRain, stopRain, fireCannon } = useCanvasConffeti();

  const { translations } = useTranslationContext();

  const animate = async () => {
    stopRain();
    setAnimating(true);
    confetti.reset();

    const wait = async (ms: number) => {
      return new Promise((resolve) => setTimeout(resolve, ms));
    };

    setAnimationPhase(0);
    setVolumeMult(0.25);
    await wait(2000);
    playDrumRoll();
    setAnimationPhase(1); // Final Standings
    await wait(4000);

    if (third) {
      // First the 3rd place team
      setAnimationPhase(2);
      playPodiumSound1();
      await wait(1000);
      fireCannon("right");
      await wait(1500);
    }
    if (second) {
      setAnimationPhase(3);
      playPodiumSound2();
      await wait(1000);
      fireCannon("left");
      await wait(2000);
    }
    // await wait(1500);
    setAnimationPhase(4);
    playPodiumSound3();

    await wait(1000);
    fireCannon("left");
    fireCannon("right");
    playWinSound();
    startRain();

    await wait(2000);
    setVolumeMult(1);

    setAnimating(false);
  };

  useEffect(() => {
    animate();
  }, []);

  // const confettiCanvasRef = useRef<HTMLCanvasElement>(null);

  // const confettiRef = useRef<confetti.CreateTypes>();

  // useEffect(() => {
  //   if (!confettiCanvasRef.current) {
  //     return;
  //   }
  //   confettiRef.current = confetti.create(confettiCanvasRef.current, {
  //     resize: true,
  //     useWorker: true,
  //   });

  //   return () => {
  //     confetti.reset();
  //   };
  // }, []);

  return (
    <>
      <Grid
        width={"100%"}
        height={"100%"}
        templateRows={"auto auto 1fr auto"}
        templateColumns={"1fr"}
        alignItems={"center"}
        justifyContent={"stretch"}
        overflow={"clip"}
        paddingTop={"0 xl xl xl"}
        gap={"small"}
      >
        <View>
          <LobbyHeaderButtons exitTo="/dashboard/library" />
        </View>
        <Heading
          level={2}
          fontWeight={"black"}
          color={"white"}
          textAlign={"center"}
          className={animationPhase >= 1 ? "animate-in" : "out"}
        >
          {translations.final_standings}
        </Heading>
        <Flex height={"100%"} justifyContent={"center"} alignItems={"stretch"}>
          <Grid
            height={"100%"}
            width={"100%"}
            maxWidth={"1200px"}
            templateColumns={"1fr 1fr 1fr"}
            gap="medium"
            alignItems={"end"}
            justifyContent={"center"}
          >
            {second ? (
              <View
                className={
                  "podium " + (animationPhase >= 3 ? "animate-in" : "out")
                }
                column={1}
                height={"80%"}
              >
                <Podium teams={second} medalType="silver"></Podium>
              </View>
            ) : null}
            {first ? (
              <View
                className={
                  "podium " + (animationPhase >= 4 ? "animate-in" : "out")
                }
                column={2}
                height={"100%"}
              >
                <Podium teams={first} medalType="gold"></Podium>
              </View>
            ) : null}
            {third ? (
              <View
                className={
                  "podium " + (animationPhase >= 2 ? "animate-in" : "out")
                }
                column={3}
                height={"60%"}
              >
                <Podium teams={third} medalType="bronze"></Podium>
              </View>
            ) : null}
          </Grid>
        </Flex>
        <Grid
          flex={1}
          templateColumns={"1fr auto 1fr"}
          justifyContent={"center"}
          alignItems={"end"}
          padding={"small xl small xl"}
          position={"sticky"}
          width={"100%"}
          bottom={"0"}
        >
          <Flex gap={"small"} column={2}>
            <Flex>
              <RaisedButton
                size="large"
                gap={"small"}
                onClick={async () => {
                  if (lobby)
                    await updateLobby({
                      lobby: { ...lobby, lobbyState: "LOBBY" },
                    });
                }}
              >
                <ArrowLeftIcon /> {translations.lobby}
              </RaisedButton>
              <RaisedButton
                size="large"
                isDisabled={animating}
                onClick={async () => {
                  await animate();
                }}
              >
                <RefreshIcon />
              </RaisedButton>
            </Flex>
          </Flex>
          <Flex justifyContent={"end"}>
            <Card backgroundColor={"rgba(0,0,0,0.5)"}>
              <AudioController
                src={"/audio/music/Podium - Happiness (Loopable).wav"}
                loop
                autoPlay
                volumeMultiplier={volumeMult}
                volume={volume}
                setVolume={setVolume}
              />
            </Card>
          </Flex>
        </Grid>
      </Grid>
    </>
  );
}
