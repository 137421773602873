import { View } from "@aws-amplify/ui-react";
import React from "react";
import AnswerSelect from "./AnswerSelect";
import { FeedbackView } from "./FeedbackView";
import { Question } from "../../../../API";
import { GameData } from "../../Types/GameTypes";
import { Feedback } from "./GameView_new";

type CorrectAnswerAndFeedbackProps = {
  carouselIndex: number;
  question: Question | null | undefined;
  gameData: GameData;
  isMyTurn: boolean;
  setGameData: any;
  getFeedback: (answer: string | number[]) => Feedback | undefined;
};

export default function CorrectAnswerAndFeedback({
  carouselIndex,
  question,
  gameData,
  isMyTurn,
  setGameData,
  getFeedback,
}: CorrectAnswerAndFeedbackProps) {
  return (
    <View height={"100%"} width={"100%"}>
      {carouselIndex === 0 && question && (
        <AnswerSelect
          question={question}
          gameData={gameData}
          isMyTurn={isMyTurn}
          onChange={(value) => {
            setGameData({ ...gameData, currentAnswer: value });
          }}
        />
      )}
      {carouselIndex === 1 && (
        <FeedbackView
          feedback={getFeedback(gameData.currentAnswer)}
          questionType={question?.type}
        />
      )}
    </View>
  );
}
