import * as React from "react";
import { CreateHeader, CreateSidebar } from ".";
import { Grid, ScrollView, View } from "@aws-amplify/ui-react";
import PresentationContextProvider from "../features/lesson/components/PresentationContextProvider";
import LessonContextProvider from "../features/lesson/components/LessonContextProvider";
import { useParams } from "react-router-dom";
import { getLesson } from "../features/lesson/services/getLesson";
import useAsync from "../hooks/useAsync";
import SlidesContextProvider from "../features/lesson/components/SlidesContextProvider";
import { querySlides } from "../features/lesson/services/querySlides";
import { createSlide } from "../features/lesson/services/createSlide";
import { createSlideObjectDefault } from "../features/lesson/util/createSlideObject";
import MenuContextProvider from "../features/lesson/components/MenuContextProvider";
import { getIdentityId } from "../features/game/util";
import NotFoundPage from "../pages/NotFound";
import GibblyLoader from "../components/GibblyLoader";
import { ScreenReaderContextProvider } from "../features/accessibility";

export interface ICreateLayoutProps {
  children?: React.ReactNode;
  useAuthentication?: boolean;
}

export default function CreateLayout(props: ICreateLayoutProps) {
  const { children, useAuthentication = true } = props;

  const { lessonId, userId } = useParams();

  const lessonServerAsync = useAsync(async () => {
    if (lessonId === undefined || userId === undefined) return;
    try {
      const lesson = await getLesson(userId, lessonId);

      return lesson;
    } catch {
      return undefined;
    }
  }, [lessonId]);

  const isOwnerAsync = useAsync(async () => {
    if (userId === undefined) return false;
    return userId === (await getIdentityId());
  }, [userId]);

  const isOwner = React.useMemo(() => isOwnerAsync.value, [isOwnerAsync]);

  const lessonServer = React.useMemo(
    () => lessonServerAsync.value,
    [lessonServerAsync]
  );

  const slidesServerAsync = useAsync(async () => {
    if (lessonId === undefined) return;
    try {
      const slides = await querySlides(lessonId);
      if (slides?.length === 0) {
        await createSlide({
          ...createSlideObjectDefault(lessonId),
          orderIndex: 0,
        });
        return await querySlides(lessonId);
      }
      return slides;
    } catch {
      return [];
    }
  }, [lessonId]);

  const slidesServer = React.useMemo(
    () => slidesServerAsync.value,
    [slidesServerAsync]
  );

  return (
    <>
      {!lessonServerAsync.initializing &&
      lessonServer !== undefined &&
      isOwner !== undefined &&
      slidesServer !== undefined &&
      userId &&
      lessonId ? (
        lessonServer.privacy !== "PRIVATE" || isOwner ? (
          // <TranslationContextProvider lang={lessonServer?.lang}>
          <LessonContextProvider
            lessonId={lessonId}
            isOwner={isOwner}
            userId={userId}
          >
            <SlidesContextProvider
              slidesServer={slidesServer}
              lessonId={lessonId}
            >
              <ScreenReaderContextProvider>
                <PresentationContextProvider>
                  <MenuContextProvider>
                    <ScrollView height={"100dvh"} minHeight={"100vh"}>
                      <Grid
                        height={"100dvh"}
                        minHeight={"100vh"}
                        columnGap="0"
                        rowGap="0"
                        templateColumns={"auto minmax(0, 1fr)"}
                        templateRows={"auto minmax(0, 1fr)"}
                        templateAreas={`
                          "header header" 
                          "sidebar content"`}
                      >
                        <CreateHeader
                          useAuthentication={useAuthentication}
                          area={"header"}
                        />

                        <CreateSidebar area={"sidebar"} hidden={false} />

                        <View backgroundColor={"#fbfbfb"} area={"content"}>
                          {children}
                        </View>
                      </Grid>
                    </ScrollView>
                  </MenuContextProvider>
                </PresentationContextProvider>
              </ScreenReaderContextProvider>
            </SlidesContextProvider>
          </LessonContextProvider>
        ) : (
          // </TranslationContextProvider>
          <NotFoundPage />
        )
      ) : (
        <View width={"100%"} height={"100dvh"} minHeight={"100vh"}>
          <GibblyLoader />
        </View>
      )}
    </>
  );
}
