import { GraphQLQuery, generateClient } from "aws-amplify/api";
import { CreateQuestionInput, GetQuizQuery, Question } from "../../../API";
import { invokeLambdaFunction } from "../../../services/awsFacade";
import { copyS3Item } from "../../../services/s3/copyS3Item";
import createQuiz from "../utils/createQuiz";
import { getIdentityId } from "../../game/util";
import { createQuestion } from "./createQuestion";
import getCreatorId from "../../../util/GetCreatorId";
import { languageOptions } from "../../translations";
import sendCustomEvent from "../../../util/sendCustomEvent";
// import { duplicateQuestion } from "./duplicateQuestion";

const getQuiz = /* GraphQL */ `
  query GetQuiz($id: ID!) {
    getQuiz(id: $id) {
      id
      title
      description
      lang
      tags
      Questions {
        items {
          sortIndex
          question {
            text
            answers
            solution
            type
            correctIndices
            readAloudText
            image {
              key
              identityId
              alt
            }
            audio {
              key
              identityId
            }
          }
        }
      }
      image {
        key
        identityId
        alt
      }
    }
  }
`;

type TranslatedQuiz = {
  details: {
    title: string;
    description: string;
  };
  questions: {
    question: string;
    options: string[];
    solution: string;
  }[];
};

async function translateQuiz(
  quizId: string,
  locale: keyof typeof languageOptions
) {
  try {
    const client = generateClient();
    const creatorId = await getCreatorId();
    const result = await client.graphql<GraphQLQuery<GetQuizQuery>>({
      query: getQuiz,
      variables: { id: quizId },
    });
    const quiz = result.data?.getQuiz;

    if (!quiz) throw new Error("Quiz not found");

    const { title, description, image, tags, Questions } = quiz;
    const quizQuestions = Questions?.items;
    //   make a copy of the image with new owner
    if (image?.identityId && image.key)
      await copyS3Item({ identityId: image?.identityId, fileName: image?.key });

    const identityId = await getIdentityId();

    const newImage = image?.key
      ? {
          key: image?.key ?? "",
          identityId: identityId,
        }
      : null;

    const language = languageOptions[locale];

    const quizInput = {
      details: {
        title: title,
        description: description,
      },
      questions: quizQuestions?.map((question) => ({
        question: question?.question?.text,
        options: question?.question?.answers,
        solution: question?.question?.solution,
      })),
    };

    const response = await invokeLambdaFunction("TranslationAPI", {
      creatorId,
      language,
      quiz: quizInput,
    });

    const translatedQuiz = JSON.parse(response?.body) as TranslatedQuiz;

    const newQuiz = await createQuiz({
      title: translatedQuiz.details.title,
      description: translatedQuiz.details.description,
      lang: locale,
      image: newImage,
      tags,
    });

    const dupQuestionPromises = quizQuestions?.map(async (quizQuestion, i) => {
      const ogQuestion = quizQuestion?.question as Question;
      const { image, audio } = ogQuestion;
      if (ogQuestion) {
        if (image?.identityId && ogQuestion)
          await copyS3Item({
            identityId: image?.identityId,
            fileName: image?.key,
          });

        const newImage = image?.key
          ? {
              key: image?.key ?? "",
              identityId: identityId,
            }
          : null;
        if (audio?.identityId && ogQuestion)
          await copyS3Item({
            identityId: audio?.identityId,
            fileName: audio?.key,
          });

        const newAudio = audio?.key
          ? {
              key: audio?.key ?? "",
              identityId: identityId,
            }
          : null;

        const newQuestion: CreateQuestionInput = {
          text: translatedQuiz.questions[i].question,
          answers: translatedQuiz.questions[i].options,
          solution: translatedQuiz.questions[i].solution,
          image: newImage,
          audio: newAudio,
          readAloudText: ogQuestion.readAloudText,
          correctIndices: ogQuestion.correctIndices,
          type: ogQuestion.type,
        };

        return createQuestion(newQuiz.id, newQuestion, quizQuestion?.sortIndex);
      }
      return;
    });

    if (dupQuestionPromises) await Promise.all(dupQuestionPromises);

    sendCustomEvent("translate", {
      type: "quiz",
      language,
      inputQuizId: quizId,
      inputQuiz: JSON.stringify(quizInput),
      outputQuizId: newQuiz.id,
      outputQuiz: JSON.stringify(translatedQuiz),
    });

    return newQuiz;
  } catch (e) {
    console.error(e);
    throw new Error("Failed to duplicate quiz");
  }
}

export default translateQuiz;
