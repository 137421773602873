export default function getTimeSince(
  date: Date | number | string | undefined
): string {
  if (!date) return "";

  const currentTime = Date.now();
  const updateTime = new Date(date).getTime();
  const timeDifference: number = currentTime - updateTime;

  const minute = 60 * 1000;
  const hour = minute * 60;
  const day = hour * 24;
  const month = day * 30;

  if (timeDifference < minute) {
    return `Just now`;
  } else if (timeDifference < hour) {
    return `${Math.round(timeDifference / minute)} mins ago`;
  } else if (timeDifference < day) {
    return `${Math.round(timeDifference / hour)} hours ago`;
  } else if (timeDifference < month) {
    return `${Math.round(timeDifference / day)} days ago`;
  } else {
    return `${Math.round(timeDifference / month)} months ago`;
  }
}
