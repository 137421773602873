import * as React from "react";
import { AnswerResult } from "../../../game/Types/GameTypes";
import { Question, QuestionType } from "../../../../API";
import { Badge, Flex, Grid, Text } from "@aws-amplify/ui-react";
import RaisedButton from "../../../../components/RaisedButton";

export interface IAnswerPreviewOptionsProps {
  question: Question | null | undefined;
  answerResult: AnswerResult;
}

export function AnswerPreviewOptions(props: IAnswerPreviewOptionsProps) {
  const { question, answerResult } = props;

  if (!question) return null;

  if (question.type === QuestionType.TYPED_ANSWER)
    return (
      <Flex
        justifyContent={"space-around"}
        alignContent={"center"}
        width={"100%"}
      >
        <Flex
          direction={"column"}
          alignContent={"center"}
          justifyContent={"start"}
          gap={"xs"}
        >
          <Text fontWeight={"bold"} isDisabled={true}>
            Submitted Answer:
          </Text>
          <Badge
            textAlign={"center"}
            alignSelf={"center"}
            color="white"
            backgroundColor={answerResult.isCorrect ? "#6ed52a" : "#de3721"}
          >
            {answerResult.submittedAnswer}
          </Badge>
        </Flex>
        <Flex
          direction={"column"}
          alignContent={"center"}
          justifyContent={"start"}
          gap={"xs"}
        >
          <Text fontWeight={"bold"} isDisabled={true}>
            Accepted Answers:
          </Text>
          {answerResult.acceptedAnswers.map((answer) => (
            <Badge
              color={"white"}
              backgroundColor={"#6ed52a"}
              alignSelf={"center"}
            >
              {answer}
            </Badge>
          ))}
        </Flex>
      </Flex>
    );
  else
    return (
      <Grid
        templateColumns={"1fr 1fr"}
        templateRows={"1fr 1fr"}
        height={"100%"}
        width={"100%"}
        gap={"xs"}
      >
        {question?.answers.map((optionText, i) => (
          <RaisedButton
            justifyContent={"center"}
            textAlign={"center"}
            alignItems={"center"}
            minHeight={"100px"}
            borderRadius={"xxxs"}
            fontSize={{
              base: "xs",
              medium: "medium",
              // xxl: "large",
            }}
            padding="xs"
            key={i}
            color={"white"}
            backgroundColor={
              question.correctIndices.includes(i) ? "#6ed52a" : "#de3721"
            }
            opacity={
              (answerResult.submittedAnswer as number[]).includes(i)
                ? "100"
                : "40"
            }
          >
            <Text color={"white"}>{optionText}</Text>
          </RaisedButton>
        ))}
      </Grid>
    );
}
