import { fetchUserAttributes } from "aws-amplify/auth";
import RestAPI from "../../util/RestAPI";
import getUser from "../../util/User/getUser";

// type CheckoutSession = {

// }

export async function getCheckoutSession(priceId: string) {
  try {
    const customerId = await getUser().then((user) => user.customerId);
    let email;
    if (!customerId) {
      email = await fetchUserAttributes().then(
        (attributes) => attributes.email
      );
    }

    const response: any = await RestAPI.post(`/stripe/checkout`, {
      body: {
        priceId,
        customerId,
        email,
      },
    }).then((response) => response.json() as any);
    return response.checkoutSession;
  } catch (err) {
    console.error(err);
    throw new Error("Error getting checkout session");
  }
}
