export const placeholderTopics: string[] = [
    'Harry Potter',
    'Lord of the Rings',
    'The Chronicles of Narnia',
    'History of Music',
    'Electromagnetism',
    'Famous scientists',
    'Insects',
    'Geography of Canada',
    'Bird species',
    'Car brands',
    'Canadian law',
    'Financial literacy',
    'Computer science',
    'Quantum computing',
    'Renewable energy',
    'Space exploration',
    'Evolutionary biology',
    'Medieval history',
    'Classical literature',
    'Artificial intelligence',
    'Environmental science',
    'Genetics',
    'Ancient history',
    'World War II',
    'World cultures',
    'Famous painters'
];