import React from "react";
import { Route, Routes } from "react-router-dom";
import { Grid, ScrollView } from "@aws-amplify/ui-react";
import TopNavbar from "../layouts/TopNavbar";
import Pricing from "../pages/Pricing";

export default function UpgradeRoutes() {
  return (
    <Grid
      height={"100dvh"}
      minHeight={"100vh"}
      gap={"0"}
      templateRows={"auto 1fr"}
    >
      <TopNavbar useAuthentication={false} />

      <ScrollView
        backgroundColor={"#f5f5f5"}

      >
        <Routes>
          <Route index element={<Pricing />} />
          <Route path={"pricing"} element={<Pricing />} />
          {/* <Route path={'quote'} element={<QuoteGenerator />} />
            <Route path={'purchase-order'} element={<PurchaseOrder />} /> */}
        </Routes>
      </ScrollView>
    </Grid>
  );
}
