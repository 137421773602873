import { createDatabaseItem } from "../../../services/database/createDatabaseItem";
import { Avatar, Player } from "../Types/GameTypes_dep";

export async function createPlayerUngrouped(
  lobbyId: string,
  userId: string,
  nickname: string,
  avatar: Avatar
) {
  await createDatabaseItem<Player>("/player", {
    playerSessionId: lobbyId,
    groupId: lobbyId,
    nickname,
    avatar,
    userId,
    playerStatus: "NORMAL",
  });
}
