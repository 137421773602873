import React, { FormEvent, useState } from "react";

import {
  Alert,
  Button,
  Flex,
  SelectField,
  Text,
  TextAreaField,
} from "@aws-amplify/ui-react";

import { PageIndex, PageTitle } from "../../../util/SEO";
import SparkleIcon from "../../../components/icons/SparkleIcon";
import { GenerationInput } from "./GenerateQuiz";

import OpenAIDocSelector from "./OpenAIDocSelector";
import { OpenAIDocument } from "./OpenAIDocument";
import useEffectOnce from "../../../hooks/useEffectOnce";
import { placeholderInstructions } from "../../../data/PlaceholderInstructions";
import LanguageSelect from "../../../components/LanguageSelect";
import { useTranslationContext } from "../../../context/TranslationContextProvider";

type FormInput = {
  fileKey: string | undefined;
  fileId: string | undefined;
  instructions: string;
  numberOfQuestions: string;
  gradeLevel: string;
  lang: string;
};

export default function DocumentForm({
  onGenerate,
  loading,
}: {
  onGenerate: (input: GenerationInput) => void;
  loading: boolean;
}) {
  PageTitle("Gibbly | Generate Quiz");
  PageIndex("noindex");

  const { prefLang } = useTranslationContext();

  const [formInput, setFormInput] = useState<FormInput>({
    fileKey: undefined,
    fileId: undefined,
    instructions: "",
    numberOfQuestions: "20",
    gradeLevel: "Easy",
    lang: prefLang,
  });

  const [instructionsPlaceholder, setInstructionsPlaceholder] = useState("");

  useEffectOnce(() => {
    const randomIndex = Math.floor(
      Math.random() * placeholderInstructions.length
    );
    const randomPlaceholder = placeholderInstructions[randomIndex];
    setInstructionsPlaceholder(randomPlaceholder);
  });

  const [doc, setDoc] = React.useState<OpenAIDocument | undefined>(undefined);

  const handleTopicSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!doc) return;

    onGenerate({
      type: "doc-mixed",
      prompt: {
        topic: doc.path?.split("/").pop() || "",
        fileId: doc?.metadata?.fileid,
        instructions: formInput.instructions,
        gradeLevel: formInput.gradeLevel,
        numberOfQuestions: formInput.numberOfQuestions,
        lang: formInput.lang,
      },
    });
  };

  return (
    <Flex
      as="form"
      direction={"column"}
      paddingTop={"0"}
      onSubmit={handleTopicSubmit}
    >
      <Alert
        gap={"xxs"}
        padding={"xxs"}
        fontSize={{ base: "8px", medium: "smaller" }}
        variation="info"
        isDismissible
      >
        This can consume a lot of tokens. For best results, use 8-page documents
        or less.
        <Text opacity={0.8}>
          They must contain text to work. Supports .pdf, .docx, .pptx, .txt,
          .html, and .md
        </Text>
      </Alert>
      <OpenAIDocSelector doc={doc} onChange={(doc) => setDoc(doc)} />
      {doc !== undefined ? (
        <>
          <TextAreaField
            fontSize={"small"}
            size="small"
            label={
              <Text fontWeight={"semibold"} color={"neutral.90"}>
                Prompt
              </Text>
            }
            placeholder={instructionsPlaceholder}
            value={formInput.instructions}
            onChange={(e) =>
              setFormInput((prev) => ({
                ...prev,
                instructions: e.target.value,
              }))
            }
          />

          <Flex wrap={"wrap"}>
            <SelectField
              flex={1}
              fontSize={"small"}
              size="small"
              label={
                <Text fontWeight={"semibold"} color={"neutral.90"}>
                  Grade Level
                </Text>
              }
              isRequired
              value={formInput.gradeLevel}
              onChange={(e) =>
                setFormInput((prev) => ({
                  ...prev,
                  gradeLevel: e.target.value,
                }))
              }
            >
              <option value={"Easy"}>Easy</option>
              <option value={"Medium"}>Medium</option>
              <option value={"Hard"}>Hard</option>
              <option value={"Grade 1"}>Grade 1</option>
              <option value={"Grade 2"}>Grade 2</option>
              <option value={"Grade 3"}>Grade 3</option>
              <option value={"Grade 4"}>Grade 4</option>
              <option value={"Grade 5"}>Grade 5</option>
              <option value={"Grade 6"}>Grade 6</option>
              <option value={"Grade 7"}>Grade 7</option>
              <option value={"Grade 8"}>Grade 8</option>
              <option value={"Grade 9"}>Grade 9</option>
              <option value={"Grade 10"}>Grade 10</option>
              <option value={"Grade 11"}>Grade 11</option>
              <option value={"Grade 12"}>Grade 12</option>
              <option value={"College/University"}>College/University</option>
            </SelectField>
            <LanguageSelect
              fontSize={"small"}
              size="small"
              flex={1}
              label={
                <Text fontWeight={"semibold"} color={"neutral.90"}>
                  Language
                </Text>
              }
              isRequired
              value={formInput.lang}
              onChange={(lang) =>
                setFormInput((prev) => ({ ...prev, lang: lang }))
              }
            ></LanguageSelect>
            <SelectField
              fontSize={"small"}
              size="small"
              label={
                <Text fontWeight={"semibold"} color={"neutral.90"}>
                  # of Questions
                </Text>
              }
              isRequired
              value={formInput.numberOfQuestions}
              onChange={(e) =>
                setFormInput((prev) => ({
                  ...prev,
                  numberOfQuestions: e.target.value,
                }))
              }
            >
              // from 1 to 20
              {[...Array(20).keys()].map((i) => (
                <option key={i} value={(i + 1).toString()}>
                  {i + 1}
                </option>
              ))}
            </SelectField>
          </Flex>

          <Button
            id="raised-btn"
            backgroundColor={"#fd7e14"}
            variation="primary"
            gap={"small"}
            type="submit"
            color={"white"}
            alignSelf={"center"}
            isDisabled={!doc || loading}
            isLoading={loading}
            loadingText={"Generating..."}
            data-attr="generate-new-quiz"
            data-ph-capture-attribute-type={"document"}
            data-ph-capture-attribute-prompt={formInput.instructions}
            data-ph-capture-attribute-grade-level={formInput.gradeLevel}
            data-ph-capture-attribute-language={formInput.lang}
            data-ph-capture-attribute-number-of-questions={
              formInput.numberOfQuestions
            }
            data-ph-capture-attribute-document-name={formInput.fileKey}
            data-ph-capture-attribute-document-identifier={formInput.fileId}
          >
            <SparkleIcon />
            Generate
          </Button>
        </>
      ) : null}
      {/* {loading && <RaisedCard>{waitingMessage}</RaisedCard>} */}
    </Flex>
  );
}
