import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { listLessonLinks } from "../../services/lesson/lesson-link";
import { LessonLink } from "../../types/lesson/lesson-link";

export default function useListLessonLinksQuery(
  lessonId: string | undefined,
  options?: UseQueryOptions<
    LessonLink[],
    unknown,
    LessonLink[],
    [string, string | undefined, string | undefined]
  >
) {
  const { data: lessonLinks, ...restQuery } = useQuery(
    ["lesson-link", lessonId, "list"],
    async () => await listLessonLinks(lessonId as string),
    {
      enabled: lessonId !== undefined,
      retry: false,
      ...options,
    }
  );

  return { lessonLinks, ...restQuery };
}
