import { useEffect, useRef } from "react";

export default function useClickOutsideDetection<
  T extends HTMLElement = HTMLDivElement
>(
  callback: (e: MouseEvent) => void | Promise<void>,
  deps: React.DependencyList
) {
  const myRef = useRef<T | null>(null);

  useEffect(() => {
    async function handleClickOutside(event: MouseEvent) {
      if (myRef.current && !myRef.current.contains(event.target as Node)) {
        await callback(event);
      }
    }

    // Bind the event listener
    document.addEventListener("click", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleClickOutside);
    };
  }, [myRef, ...deps]);

  return myRef;
}
