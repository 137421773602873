import * as React from "react";
import { Card, Flex, ScrollView, View } from "@aws-amplify/ui-react";
import AddIcon from "../../../../components/icons/AddIcon";
import TimelineSlide from "./TimelineSlide";
import { useState } from "react";
import { createSlideObjectDefault } from "../../util/createSlideObject";
import { Droppable } from "../../../../components/Droppable";
import { LessonContext } from "../LessonContextProvider";
import { SlidesContext } from "../SlidesContextProvider";
import { useAddSlide } from "../../hooks/useAddSlide";

export interface ISlideTimelineProps {}

export default function SlideTimeline(props: ISlideTimelineProps) {
  const {} = props;

  const { lesson, isOwner } = React.useContext(LessonContext);
  const addSlideMutation = useAddSlide();
  const { slideIds, moveSlide, setSlideIndex } =
    React.useContext(SlidesContext);

  // const [copiedSlide, setCopiedSlide] = useState<Slide>();
  // const [focusedSlide, setFocusedSlide] = useState<Slide>();

  const [dragIndex, setDragIndex] = useState<number>();

  const [draggedSlideIndex, setDraggedSlideIndex] = useState<number>();

  // useEffect(() => {
  //   const handleKeyDown = (event: KeyboardEvent) => {
  //     if (event.ctrlKey || event.metaKey) {
  //       switch (event.key) {
  //         case "c":
  //           handleCopy();
  //           break;
  //         case "v":
  //           handlePaste();
  //           break;
  //         default:
  //           break;
  //       }
  //     }
  //   };

  //   window.addEventListener("keydown", handleKeyDown);

  //   return () => {
  //     window.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, [focusedSlide, copiedSlide]);

  // const handleCopy = React.useCallback(() => {
  //   setCopiedSlide(focusedSlide);
  // }, [focusedSlide]);

  // const handlePaste = React.useCallback(() => {
  //   if (copiedSlide && focusedSlide) {
  //     const slide = createSlideObject(lesson.id, copiedSlide.elements);
  //     addSlide(slide, focusedSlide.orderIndex + 1);
  //   }
  // }, [focusedSlide, copiedSlide]);

  const handleDrag = React.useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      const { clientX, clientY } = event;

      const slideElements = Array.from(
        document.querySelectorAll(".timeline-item")
      );

      let dropIndex = undefined;

      for (let i = 0; i < slideElements.length; i++) {
        const currElementRect = slideElements[i].getBoundingClientRect();
        const nextElementRect =
          i < slideElements.length - 1
            ? slideElements[i + 1].getBoundingClientRect()
            : undefined;

        const currCenterX = (currElementRect.left + currElementRect.right) / 2;
        const nextCenterX = nextElementRect
          ? (nextElementRect.left + nextElementRect.right) / 2
          : undefined;

        if (clientY < currElementRect.top || clientY > currElementRect.bottom) {
          break;
        }

        if (!nextCenterX && clientX > currCenterX) {
          dropIndex = i + 1;
          break;
        }
        if (nextCenterX && clientX > currCenterX && clientX < nextCenterX) {
          dropIndex = i + 1;
          break;
        } else if (i === 0 && clientX <= currCenterX) {
          dropIndex = 0;
          break;
        }
      }
      if (
        draggedSlideIndex !== undefined &&
        (draggedSlideIndex === dropIndex || draggedSlideIndex + 1 === dropIndex)
      ) {
        dropIndex = undefined;
      }

      setDragIndex(dropIndex);

      // Now you have the index at which to insert the dragged slide.
    },
    [draggedSlideIndex]
  );

  return (
    <ScrollView height={"100px"} padding={"0 0 6px 0"}>
      <Droppable
        height={"100%"}
        width={"auto"}
        dragIdKey={"drag-slide-key"}
        onDragOver={handleDrag}
        onDropItem={() => {
          if (dragIndex !== undefined && draggedSlideIndex !== undefined)
            moveSlide(draggedSlideIndex, dragIndex);
          // Implement onDrop
          setDragIndex(undefined);
        }}
      >
        <Flex height={"100%"}>
          <View
            className={
              "timeline-placeholder" + (0 === dragIndex ? " selected" : "")
            }
            height={"100%"}
          />
          {slideIds.map((slideId, i) => (
            <React.Fragment key={slideId}>
              <View
                padding={"2px"}
                height={"100%"}
                style={{
                  aspectRatio: "16/9",
                }}
                onClick={() => setSlideIndex(i)}
              >
                <TimelineSlide
                  slideId={slideId}
                  i={i}
                  onDragStart={() => setDraggedSlideIndex(i)}
                  onDrag={handleDrag}
                  onDragEnd={() => setDraggedSlideIndex(undefined)}
                />
              </View>
              <View
                className={
                  "timeline-placeholder" +
                  (i + 1 === dragIndex ? " selected" : "")
                }
                height={"100%"}
              />
            </React.Fragment>
          ))}
          {isOwner && (
            <View height={"100%"}>
              <Card
                height={"100%"}
                style={{ aspectRatio: "16/9" }}
                opacity={0.5}
                onClick={() => {
                  addSlideMutation.mutateAsync({
                    slide: createSlideObjectDefault(lesson.id),
                    index: slideIds.length,
                  });
                }}
              >
                <Flex
                  height={"100%"}
                  width={"100%"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <AddIcon />
                </Flex>
              </Card>
            </View>
          )}
        </Flex>
      </Droppable>
    </ScrollView>
  );
}
