import { UseQueryOptions } from "@tanstack/react-query";
import { getCurrentEnvironment } from "../../services/getCurrentEnvironment";

export function getEnvironmentQuery(): UseQueryOptions<string> {
  return {
    queryKey: ["environment"],
    queryFn: async () => await getCurrentEnvironment(),
    suspense: true,
    staleTime: Infinity,
  };
}
