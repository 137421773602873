import { Flex, View, Grid } from "@aws-amplify/ui-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import AnswerSelect from "./AnswerSelect";
import { FeedbackView } from "./FeedbackView";
import { AnswerResult, GameData } from "../../Types/GameTypes";
import { Feedback } from "./GameView";
import { Question, QuestionType } from "../../../../API";
import RaisedButton from "../../../../components/RaisedButton";
import { QuestionCard } from "./QuestionCard";
import PlayIcon from "../../../../components/icons/PlayIcon";
import useMediaQuery from "../../../../hooks/useMediaQuery";
import { LobbyPlayers } from "../../Types/GameTypes_dep";
// import useUpdateEffect from "../../../../hooks/useUpdateEffect";
import { MdOutlineKeyboardReturn } from "react-icons/md";
import { useTranslationContext } from "../../../../context/TranslationContextProvider";
import { useScreenReaderContext } from "../../../accessibility";

export interface IGamePlayMenuProps {
  gameData: GameData;
  // setGameData: React.Dispatch<React.SetStateAction<GameData>>;
  questions: (Question | null | undefined)[] | undefined;
  onChange?: (gameData: GameData) => void | Promise<void>;
  onSubmitAnswer?: (answerResults: AnswerResult[]) => void | Promise<void>;
  lobby?: LobbyPlayers;
  isMyTurn?: boolean;
  turnIndex?: number;
}

export function GamePlayMenu(props: IGamePlayMenuProps) {
  const {
    gameData,
    // setGameData,
    questions,
    onSubmitAnswer,
    onChange,
    lobby,
    isMyTurn,
    turnIndex,
  } = props;

  // const [submittingAnswer, setSubmittingAnswer] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(1);
  // const [isAnswered, setIsAnswered] = useState(false);

  const isMedium = useMediaQuery("(min-width: 768px)");

  const questionIndex = useMemo(
    () => gameData.questionIndex,
    [gameData.questionIndex]
  );

  const question = useMemo(
    () => questions?.[questionIndex],
    [questions, questionIndex]
  );

  const isAnswered = useMemo(() => {
    return questionIndex + 1 === gameData.answerResults.length;
  }, [questionIndex, gameData.answerResults]);

  const isMultiplayer = useMemo(
    () =>
      lobby !== undefined && isMyTurn !== undefined && turnIndex !== undefined,
    [lobby, isMyTurn, turnIndex]
  );

  const { stop } = useScreenReaderContext();

  const { next, submit, show_answer, show_solution } =
    useTranslationContext().translations;

  const getFeedback = useCallback(
    (answer: number[] | string) => {
      try {
        // if (gameData.currentAnswer.length === 0) throw new Error("Current answer length is 0");
        if (!question) throw new Error("No question");

        const solution = question.solution as string;
        let isCorrect = false;
        let acceptedAnswers;
        switch (question.type) {
          case QuestionType.TYPED_ANSWER:
            if (typeof answer !== "string") {
              const newGameData = { ...gameData, currentAnswer: "" };
              onChange?.(newGameData);
              // setGameData((prev) => {
              //   const newGameData = { ...prev, currentAnswer: "" };
              //   onChange?.(newGameData);
              //   return newGameData;
              // });
              throw new Error("Current answer is not a string");
            }
            isCorrect = question.answers
              .map((a) => {
                return (a as string).toLowerCase();
              })
              .includes((answer as string).toLowerCase());
            acceptedAnswers = question.correctIndices.map(
              (ci) => question.answers[ci as number] as string
            );
            break;
          default:
            isCorrect =
              question.correctIndices.length === answer.length &&
              question.correctIndices.every((ci) =>
                (answer as number[]).includes(ci !== null ? ci : -1)
              );
            acceptedAnswers = [...(question.correctIndices as number[])];
            break;
        }
        const feedback: Feedback = { solution, isCorrect, acceptedAnswers };

        return feedback;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    [question, gameData]
  );

  // const handleSubmitAnswer = useCallback(
  //   async (answerResults: AnswerResult[]) => {
  //     // setSubmittingAnswer(true);
  //     await onSubmitAnswer?.(answerResults);
  //     // setSubmittingAnswer(false);
  //   },
  //   []
  // );

  const submitAnswer = useCallback(
    async (answer: number[] | string) => {
      const feedback = getFeedback(answer);

      if (feedback === undefined) throw new Error("Feedback is undefined");
      if (question === undefined || question === null)
        throw new Error("Question is undefined");

      let acceptedAnswers: string[] | number[];

      switch (question.type) {
        case QuestionType.TYPED_ANSWER:
          acceptedAnswers = question.correctIndices.map((index) => {
            return question?.answers[index as number] as string;
          });
          break;
        default:
          acceptedAnswers = question?.correctIndices as number[];
          break;
      }

      const result: AnswerResult = {
        questionIndex,
        submittedAnswer: answer,
        isCorrect: feedback.isCorrect,
        acceptedAnswers,
      };

      const newAnswerResults = [...gameData.answerResults];
      newAnswerResults.push(result);

      onSubmitAnswer?.(newAnswerResults);

      onChange?.({
        ...gameData,
        answerResults: newAnswerResults,
      });
    },
    [question, questionIndex, gameData]
  );

  useEffect(() => {
    const keyDownHandler = async (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        if (isAnswered) return;
        if (
          (!isMultiplayer ? true : isMyTurn) &&
          gameData.currentAnswer.length > 0
        ) {
          await submitAnswer(gameData.currentAnswer);
        }
      }
    };

    window.addEventListener("keydown", keyDownHandler);

    // Cleanup: remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", keyDownHandler);
    };
  }, [
    isAnswered,
    isMultiplayer,
    isMyTurn,
    gameData.currentAnswer,
    submitAnswer,
  ]);

  const NextQuestion = useCallback(() => {
    setCarouselIndex(1);
    const newGameData = {
      ...gameData,
      questionIndex: gameData.questionIndex + 1,
      currentAnswer: [],
    };
    onChange?.(newGameData);
  }, [gameData]);

  return (
    <Grid
      width={"100%"}
      height={"100%"}
      flex={1}
      gap={"small"}
      templateRows={{
        base: `.5fr 30px .75fr`,
        medium: `1fr 50px 1fr`,
      }}
    >
      <View paddingLeft={"small"} paddingRight={"small"} height={"100%"}>
        <QuestionCard question={question} />
      </View>

      <Flex justifyContent={"center"} alignItems={"center"}>
        {isAnswered ? (
          <>
            <RaisedButton
              backgroundColor={"rgb(255,255,255, 0.5)"}
              size={isMedium ? "small" : undefined}
              fontSize={{ base: "xxs", medium: "medium" }}
              padding={"xs"}
              textAlign={"center"}
              onClick={() =>
                setCarouselIndex((prev) => {
                  if (prev === 0) return 1;
                  return prev - 1;
                })
              }
            >
              {carouselIndex === 0 ? show_solution : show_answer}
            </RaisedButton>
            <>
              {(!isMultiplayer ? true : isMyTurn) && (
                <RaisedButton
                  backgroundColor={"white"}
                  size={isMedium ? "small" : undefined}
                  padding={"xs"}
                  textAlign={"center"}
                  fontSize={{ base: "xxs", medium: "medium" }}
                  // isLoading={submittingAnswer}
                  color={"black"}
                  gap={"xs"}
                  onClick={() => {
                    NextQuestion();
                    stop();
                  }}
                >
                  {next}
                  <PlayIcon />
                </RaisedButton>
              )}
            </>
          </>
        ) : (
          <>
            {(isMyTurn !== undefined
              ? isMyTurn && gameData.currentAnswer.length > 0
              : gameData.currentAnswer.length > 0) && (
              <RaisedButton
                backgroundColor={"white"}
                size={isMedium ? "small" : undefined}
                fontSize={{ base: "xxs", medium: "medium" }}
                // isLoading={submittingAnswer}
                color={"black"}
                onClick={async () => {
                  // setSubmittingAnswer(true);
                  await submitAnswer(gameData.currentAnswer);
                  // await handleSubmitAnswer({gameData.answerResults});
                  // setSubmittingAnswer(false);
                  stop();
                }}
                gap={"xs"}
              >
                {submit} <MdOutlineKeyboardReturn />
              </RaisedButton>
            )}
          </>
        )}
      </Flex>
      <Flex padding={"small"} flex={1} height={"100%"}>
        {!isAnswered ? (
          <Flex flex={1} alignContent={"end"}>
            {question && (
              <AnswerSelect
                question={question}
                gameData={gameData}
                isMyTurn={isMyTurn}
                onChange={(value) => {
                  onChange?.({ ...gameData, currentAnswer: value });
                  // setGameData((prev) => {
                  //   onChange?.({ ...prev, currentAnswer: value });
                  //   return { ...prev, currentAnswer: value };
                  // });
                }}
              />
            )}
          </Flex>
        ) : (
          <View height={"100%"} width={"100%"}>
            {carouselIndex === 0 && question && (
              <AnswerSelect
                question={question}
                gameData={gameData}
                isMyTurn={isMyTurn}
                onChange={(value) => {
                  onChange?.({ ...gameData, currentAnswer: value });
                  // setGameData((prev) => {
                  //   onChange?.({ ...prev, currentAnswer: value });
                  //   return { ...prev, currentAnswer: value };
                  // });
                }}
              />
            )}
            {carouselIndex === 1 && (
              <FeedbackView
                feedback={getFeedback(gameData.currentAnswer)}
                questionType={question?.type}
              />
            )}
          </View>
        )}
      </Flex>
    </Grid>
  );
}
