import { ButtonProps } from "@aws-amplify/ui-react";
import React, { useEffect } from "react";

import { RaisedButton } from "../../../components";
import { SlSpeech } from "react-icons/sl";
import { useQuery } from "@tanstack/react-query";
import { useTranslationContext, useVolumeContext } from "../../../context";
import { synthesizeSpeechAudio } from "../services";
import useAudio from "../../../hooks/useAudio";
import { FaStop } from "react-icons/fa6";

type Props = Omit<ButtonProps, "onClick" | "size"> & {
  text: string;
  size?: string;
};

export function ReadAloudButton(props: Props) {
  const { text, size, ...buttonProps } = props;
  const { lang } = useTranslationContext();

  const [loadText, setLoadText] = React.useState<string>("");

  const {
    data: url,
    isFetching,
    refetch,
  } = useQuery(
    ["read-aloud-audio", "url", loadText, lang],
    async () => {
      const audioStream = await synthesizeSpeechAudio(loadText, lang, "medium");

      const byteArray = await audioStream.transformToByteArray();

      const url = URL.createObjectURL(new Blob([byteArray]));
      return url;
    },
    {
      enabled: loadText !== "",
    }
  );

  const { setMultiplier } = useVolumeContext();

  const { play, playing, stop } = useAudio({
    src: url,
    volume: 1,
    volumeMultiplier: 1,
    onPlay: () => {
      setMultiplier(0.15);
    },
    onPause: () => setMultiplier(1),
  });

  useEffect(() => {
    stop();
  }, [text]);

  return (
    <RaisedButton
      borderRadius={"200px"}
      // backgroundColor={"rgba(255,255,255,0.6)"}
      variation="link"
      padding={"xs xs small xs"}
      justifyContent={"center"}
      alignItems={"center"}
      isLoading={isFetching}
      tooltip={"Play Message"}
      height={"30px"}
      style={{
        aspectRatio: "1/1",
      }}
      {...buttonProps}
      onClick={async () => {
        if (text !== loadText) {
          setLoadText(text);
          await refetch({ queryKey: ["read-aloud-audio", "url", text, lang] });
          await new Promise((resolve) => setTimeout(resolve, 200));
        }
        if (playing) stop();
        else play();
      }}
    >
      {playing ? (
        <FaStop fontSize={"xs"} size={size} />
      ) : (
        <SlSpeech fontSize={"xs"} size={size} />
      )}
    </RaisedButton>
  );
}
