import { LessonLink, LessonLinkKey } from "../../../types/lesson/lesson-link";
import { createDatabaseItem } from "../../database/createDatabaseItem";
import { deleteDatabaseItem } from "../../database/deleteDatabaseItem";
import { getDatabaseItem } from "../../database/getDatabaseItem";
import { queryDatabaseItems } from "../../database/queryDatabaseItems";

export async function createLessonLink(lessonId: string) {
  return await createDatabaseItem<Omit<LessonLink, "linkId">>("/lesson-links", {
    lessonId,
  });
}

export async function getLessonLink(linkId: string, lessonId: string) {
  return await getDatabaseItem<LessonLink, LessonLinkKey>("/lesson-links", {
    lessonId,
    linkId,
  });
}
export async function listLessonLinks(lessonId: string) {
  return await queryDatabaseItems<LessonLink>("/lesson-links", lessonId);
}

export async function deleteLessonLink(linkId: string, lessonId: string) {
  return await deleteDatabaseItem<LessonLink, LessonLinkKey>("/lesson-links", {
    lessonId,
    linkId,
  });
}
