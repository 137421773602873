import RestAPI from "../../../../util/RestAPI";
import { getQuizForGame } from "../../../quiz/utils/getQuizForGame";
import { Assignment, PostAssignmentInput } from "../../types";

export async function createAssignment(
  classId: string,
  quizId: string,
  dueDate: number,
  assignDate?: number
) {
  const quiz = await getQuizForGame(quizId);

  const input: PostAssignmentInput = {
    classId,
    quiz,
    dueDate,
    assignDate,
  };

  try {
    const classData = await RestAPI.post("/assignments", {
      body: input,
    }).then((response) => response.json() as any as Assignment);
    return classData;
  } catch (err) {
    return;
  }
}
