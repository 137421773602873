import React, { useCallback } from "react";
import EnterNicknameForm from "../../game/pages/v4/EnterNicknameForm";
import { useNavigate, useParams } from "react-router-dom";
import { createStudent, listStudents } from "../util/student";
import { Card, Flex, Text } from "@aws-amplify/ui-react";
import useModal from "../../../hooks/useModal";
import { toast } from "react-toastify";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getStudentByIdentityId } from "../util/student/getStudentByIdentityId";
import { CenteredLoader } from "../../../components/CenteredLoader";
import { getIdentityId } from "../../game/util";

export default function ClassroomLogin() {
  const { classId } = useParams();
  const navigate = useNavigate();

  const [ErrorModal, setShow] = useModal(
    {
      size: "lg",
      ReactComponent: () => (
        <>
          <Flex direction="column" gap={"small"}>
            <Text>
              If you are the student who used this nickname before, please use a
              similar nickname for now.
            </Text>
            <Card
              padding={"small"}
              margin={"small"}
              backgroundColor={"background.tertiary"}
            >
              <Text>
                <b>Example:</b>
              </Text>
              <Text>Old - John</Text>
              <Text>New - John1</Text>
            </Card>
          </Flex>
        </>
      ),
      title: "Nickname in use",
    },
    []
  );

  // const student = useContext(StudentContext);

  const queryClient = useQueryClient();

  const submitStudentNickname = useCallback(
    async (studentNickname: string) => {
      try {
        if (classId === undefined) throw new Error("ClassId is undefined");

        const students = await listStudents(classId);
        if (students === undefined) throw new Error("Students is undefined");
        const student = students.find(
          (student) => student.nickname === studentNickname
        );
        if (student) {
          setShow(true);
          throw new Error("Student already exists");
        } else {
          await createStudent(classId, studentNickname);
          queryClient.invalidateQueries(["student", classId]);
          navigate(`/classroom/${classId}`);
          return;
        }
      } catch (e) {
        console.error(e);
        toast.error("Something went wrong. Please try again.");
        return undefined;
      }
    },
    [classId]
  );

  const { isLoading: isCheckingExistingUser } = useQuery(
    ["existing-student", classId],
    async () => {
      const identityId = await getIdentityId();


      if (classId && identityId) {
        try {
          const student = await getStudentByIdentityId(classId, identityId);
          return student;
        } catch (e) {
          return false;
        }
      } else return false;
    },
    {
      enabled: classId !== undefined,
      onSuccess: (data) => {
        if (data) navigate(`/classroom/${classId}`);
      },
    }
  );

  if (isCheckingExistingUser) return <CenteredLoader />;

  return (
    <>
      <ErrorModal />
      <Flex
        height="100%"
        justifyContent={"center"}
        alignItems={"center"}
        flex={1}
      >
        <Card>
          <EnterNicknameForm
            onSubmit={async (nickname) => {
              await submitStudentNickname(nickname);
            }}
            loadingText={"Logging into class..."}
          />
        </Card>
      </Flex>
    </>
  );
}
