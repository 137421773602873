import { Card, Flex, Heading } from "@aws-amplify/ui-react";
import * as React from "react";
import { LobbyOptionsForm } from "./LobbyOptionsForm";
import { LobbyOptions } from "../../Types/LobbyTypes";
import { AvatarAndNicknameForm } from "./AvatarAndNicknameForm";
import { Avatar } from "../../Types/GameTypes_dep";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../../../context/UserContextProvider";

enum MenuState {
  OPTIONS,
  NICKNAME,
}

export interface ICreateLobbyMenuProps {
  onSubmitLobbyOptions: (
    options: LobbyOptions,
    numberOfTeams: number,
    nickname?: string,
    avatar?: Avatar
  ) => void | Promise<void>;
}

export function CreateLobbyMenu(props: ICreateLobbyMenuProps) {
  const { onSubmitLobbyOptions } = props;

  const [menuState, setMenuState] = React.useState<MenuState>(
    MenuState.OPTIONS
  );
  const [numberOfTeams, setNumberOfTeams] = React.useState(4);

  const { authenticated } = useUserContext();
  const navigate = useNavigate();

  const [options, setOptions] = React.useState<LobbyOptions>({
    randomNicknames: true,
    autoTeams: true,
    playersCanSwitchTeams: false,
    hostSpectating: true,
    playerMusic: true,
  });

  switch (menuState) {
    case MenuState.OPTIONS:
      return (
        <Card>
          <Flex direction={"column"} alignItems={"center"}>
            <Heading level={4}>Lobby Settings</Heading>
            <LobbyOptionsForm
              onSubmitOptions={async (options, numberOfTeams) => {
                if (!authenticated) navigate("/auth");

                setOptions(options);
                setNumberOfTeams(numberOfTeams);
                // if (options.hostSpectating === false)
                setMenuState(MenuState.NICKNAME);

                // else
                //   await onCreateLobby(options, numberOfTeams);
              }}
              currentNumberOfTeams={numberOfTeams}
            />
          </Flex>
        </Card>
      );
    case MenuState.NICKNAME:
      return (
        <Card>
          <AvatarAndNicknameForm
            randomNicknames={options.randomNicknames}
            onSubmit={async (nickname: string, avatar) => {
              await onSubmitLobbyOptions(
                options,
                numberOfTeams,
                nickname,
                avatar
              );
            }}
          />
        </Card>
      );
    default:
      return <></>;
  }

  // async function createLobby(options: LobbyOptions, numberOfTeams: number, nickname?: string, avatar?: Avatar) {
  //   try {
  //     await onCreateLobby(options, numberOfTeams, nickname);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }
}
