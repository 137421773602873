import { Button, Card, Flex, Grid, Text, View } from "@aws-amplify/ui-react";
import React, { useMemo, useState } from "react";
import DeleteIcon from "../../../components/icons/DeleteIcon";
import SettingsIcon from "../../../components/icons/SettingsIcons";
import StarIcon from "../../../components/icons/StarIcon";

import { Assignment } from "../types";
import { deleteAssignment } from "../util/assignment";
import useConfirmationModal from "../../../hooks/useConfirmationModal";
import { formatDueDate } from "../util/formatDueDate";
import getTimeSince from "../../../util/timeSinceUpdated";
import useModal from "../../../hooks/useModal";
import AssignmentSettings from "./AssignmentSettings";
import { useNavigate } from "react-router-dom";
import { useAssignmentResults } from "../hooks/useAssignmentResults";
import { useAssignmentResultsAnalytics } from "../hooks/useAssignmentResultsAnalytics";
import Media from "react-media";
import { FaEye } from "react-icons/fa";
import { ImageComponent } from "../../../components/ImageComponent";

export default function TeacherAssignmentCard({
  assignment,
  onChange,
}: {
  assignment: Assignment;
  onChange: () => void;
}) {
  const [isDeleted, setIsDeleted] = useState(false);
  const [DeleteModal, setShowModal] = useConfirmationModal({
    headerMessage: "Delete Assignment?",
    confirmFn: async () => {
      setIsDeleted(true);
      await deleteAssignment(assignment);
      onChange();
    },
  });

  const quiz = useMemo(() => assignment.quiz, [assignment.quiz]);

  const navigate = useNavigate();

  const [SettingsModal, setShowSettings] = useModal(
    {
      // size: "lg",
      title: "Assignment Settings",
      ReactComponent: () => (
        <AssignmentSettings
          assignment={assignment}
          onSubmit={() => {
            onChange();
            setShowSettings(false);
          }}
        />
      ),
    },
    [assignment]
  );

  const assignmentResults = useAssignmentResults(assignment.id);

  const analytics = useAssignmentResultsAnalytics(assignmentResults?.all);

  return (
    <Card
      id="raised-item"
      padding={"0"}
      width={"100%"}
      opacity={isDeleted ? 0.3 : 1}
    >
      <SettingsModal />
      <DeleteModal />
      <Grid
        padding={"0"}
        templateColumns={{
          base: "1fr",
          small: "1fr 1fr",
          medium: "4fr 10fr 2fr",
        }}
        templateRows={{
          base: "minmax(0,1fr)",
          small: "minmax(0,1fr)",
          medium: "160px",
        }}
      >
        <Flex padding={"0"}>
          <ImageComponent
            canEdit={false}
            image={quiz.image}
            autoPickPrompt={""}
            width={{ base: "100%", medium: "300px" }}
            maxHeight={"200px"}
            objectFit="cover"
            alt="Quiz Image"
            borderRadius={{
              base: "large large 0 0",
              small: "large 0 large 0",
              medium: "large 0 0 large",
            }}
          ></ImageComponent>
        </Flex>

        <Grid
          padding={"small"}
          // width={{ base: '100%', medium: '50%' }}
          templateColumns={{ base: "100%", medium: "1fr 1fr" }}
          rowGap={"small"}
          columnGap={"medium"}
        >
          <Flex direction={"column"} gap={0} justifyContent={"space-between"}>
            <Text id="sectionTitle">{quiz.title}</Text>
            <View>
              <i>
                <Text
                  fontSize={"smaller"}
                  color={assignment.isOpen ? "green.80" : "red.80"}
                >
                  {assignment.isOpen ? "Open" : "Closed"}
                </Text>
                <Text fontSize={"smaller"}>
                  Assigned {getTimeSince(assignment.assignDate)}
                </Text>
              </i>
            </View>
          </Flex>

          <Flex direction={"column"} fontFamily={"Poppins"}>
            <Flex wrap="wrap" rowGap={"xxxs"}>
              <Text
                fontWeight={"bold"}
                style={{ fontStyle: "italic" }}
                fontSize={"small"}
              >
                Due date:
              </Text>
              <Text fontSize={"small"}>
                {assignment.dueDate
                  ? formatDueDate(new Date(assignment.dueDate))
                  : "Not specified"}
              </Text>
            </Flex>
            <Text fontWeight={"bold"} fontSize={"small"}>
              <StarIcon /> {analytics.numberOfParticipants} Participant
              {analytics.numberOfParticipants === 1 ? "" : "s"}
            </Text>
          </Flex>
        </Grid>

        <Flex
          padding={"small"}
          direction={{ base: "row-reverse", medium: "column" }}
          columnSpan={{ base: 1, small: 2, medium: 1 }}
        >
          <Flex justifyContent={"right"} gap="small">
            <Button
              variation="link"
              id="raised-btn"
              onClick={() => setShowSettings(true)}
            >
              <SettingsIcon />
            </Button>
            <Button
              variation="link"
              id="raised-btn"
              onClick={() => {
                setShowModal(true);
              }}
            >
              <DeleteIcon />
            </Button>
          </Flex>

          <Media queries={{ small: { maxWidth: 480 } }}>
            {(matches) =>
              matches.small ? (
                <>
                  <Button
                    hidden={analytics.numberOfParticipants === 0}
                    variation="primary"
                    aria-label="View results"
                    backgroundColor={"purple.90"}
                    textAlign={"center"}
                    size="small"
                    id="raised-btn"
                    onClick={() =>
                      navigate(
                        `/classes/${assignment.classId}/assignment/${assignment.id}/results`
                      )
                    }
                  >
                    <FaEye />
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    hidden={analytics.numberOfParticipants === 0}
                    variation="primary"
                    backgroundColor={"purple.90"}
                    textAlign={"center"}
                    fontSize={"smaller"}
                    size="small"
                    id="raised-btn"
                    onClick={() =>
                      navigate(
                        `/classes/${assignment.classId}/assignment/${assignment.id}/results`
                      )
                    }
                  >
                    View Results
                  </Button>
                </>
              )
            }
          </Media>
        </Flex>
      </Grid>
    </Card>
  );
}
