import React from "react";
import { Flex, View } from "@aws-amplify/ui-react";
import { Route, Routes } from "react-router-dom";
import { PageIndex, PageTitle } from "../../../../util/SEO";

import QuickPlay from "./QuickPlay";

// const gradient = { background: 'linear-gradient(to right, #1573cb, #5B86E5)' }
document.body.style.backgroundColor =
  "linear-gradient(to right, #1573cb, #5B86E5)";
//import { createBrowserHistory } from "history";

export default function PlaySoloRoutes() {
  //const history = createBrowserHistory();
  PageTitle("Play 🎮 | Gibbly");
  PageIndex("noindex");

  return (
    <>
      <div className="area">
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>

        <View
          as="section"
          width={"100%"}
          flex={1}
          className={"above"}
          position={"relative"}
        >
          {/* <Flex direction={'column'} width={'100%'} alignContent={'center'} minHeight={'100dvh'}  top={0} justifyContent={'center'}> */}
          <Flex
            alignContent={"center"}
            flex={1}
            grow={1}
            direction={"row"}
            minHeight={"100vh"}
          >
            <View as="section" className={"above"} flex={1} height={"100%"}>
              <Routes>
                <Route path=":quizId" element={<QuickPlay />} />
              </Routes>
            </View>
          </Flex>
        </View>
      </div>
    </>
  );
}
