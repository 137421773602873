import RestAPI from "../../../../util/RestAPI";
import { Assignment } from "../../types";

export async function listAssignments(classId: string) {
  try {
    const classList: Assignment[] = (await RestAPI.get(
      "/assignments/" + classId,
      {}
    ).then((res) => res.json())) as any as Assignment[];
    return classList;
  } catch (err) {

    return;
  }
}
