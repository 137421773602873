import generateRandomAlphanumericCode from "../generateRandomAlphanumericCode";
import listUsersWithReferralCode from "./listUsersWithReferralCode";

export async function getAvailableReferralCode() {
  let referralCodeInUse = false;
  let code = "";
  do {
    // Generate a random referral code
    code = generateRandomAlphanumericCode(6);
    // Check if it's in use
    const usersWithReferralCode = await listUsersWithReferralCode(code);

    // If it is, generate another one
    if (usersWithReferralCode.length > 0) referralCodeInUse = true;
    else referralCodeInUse = false;
  } while (referralCodeInUse);

  return code;
}
