import RestAPI from "../../../../util/RestAPI";
import { Student } from "../../types";

export async function listStudents(classId: string) {
  try {
    const students: Student[] = (await RestAPI.get(
      "/students/" + classId,
      {}
    ).then((res) => res.json())) as any as Student[];
    return students;
  } catch (err) {

    return;
  }
}
