import { TableRow, TableCell, Flex, View } from "@aws-amplify/ui-react";
import * as React from "react";
import { useAnswerResultsAnalytics } from "../../hooks/useAnswerResults";
import { AssignmentResult } from "../../types";
import useAsync from "../../../../hooks/useAsync";
import { getStudent } from "../../util/student/getStudent";
import { useParams } from "react-router-dom";
import { ResultsTableAnswerButton } from "./ResultsTableAnswerButton";
import { Question } from "../../../../API";

export interface IResultsTableRowProps {
  questions: (Question | null | undefined)[] | undefined;
  assignmentResult: AssignmentResult;
}

export function ResultsTableRow(props: IResultsTableRowProps) {
  const { assignmentResult, questions } = props;

  const { classId } = useParams();

  const student = useAsync(async () => {
    if (classId) return getStudent(classId, assignmentResult.studentId);
    return;
  }, [classId, assignmentResult.studentId]);

  const { score, numCorrect, total } = useAnswerResultsAnalytics(
    assignmentResult.answerResults
  );

  const color = React.useMemo(() => {
    if (score < 50) return "var(--amplify-colors-red-60)";
    if (score < 80) return "var(--amplify-colors-orange-60)";
    return "var(--amplify-colors-green-80)";
  }, []);

  return (
    <TableRow>
      <TableCell as="td" textAlign={"center"}>
        <Flex
          justifyContent={"center"}
          borderRadius={"xxl"}
          color="white"
          id="raised-item"
          backgroundColor={color}
          padding={'xs'}
          grow={1}
          fontWeight={"semibold"}
        >
          {student.value?.nickname}
        </Flex>

      </TableCell>
      <TableCell as="td" textAlign={"center"}>
        {assignmentResult.attempt}
      </TableCell>
      <TableCell as="td" textAlign={"center"}>
        {numCorrect}/{total}
      </TableCell>
      <TableCell as="td" width={"40px"}>
        <Flex justifyContent={"start"}>
          {assignmentResult.answerResults.map((answerResult, i) => (
            <View key={i}>
              <ResultsTableAnswerButton
                answerResult={answerResult}
                question={questions?.[i]}
              />
            </View>
          ))}
        </Flex>
      </TableCell>
    </TableRow>
  );
}
