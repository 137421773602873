import RestAPI from "../../../util/RestAPI";
import { Slide } from "../types/slideTypes";

export async function createSlide(
  slide: Omit<Slide, "createdAt" | "updatedAt" | "id">
) {
  const { lessonId, orderIndex, elements, background } = slide;

  const slideItem: Omit<Slide, "createdAt" | "updatedAt" | "id"> = {
    lessonId,
    orderIndex,
    elements,
    background,
  };

  try {
    await RestAPI.post("/slides", {
      body: slideItem,
    });
  } catch (err) {
    throw err;
  }
}
