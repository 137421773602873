import { User } from "../../types/User";
import RestAPI from "../RestAPI";
import { fetchUserAttributes } from "aws-amplify/auth";

export default async function getUser(creatorId?: string) {
  try {
    if (creatorId) {
      const user: User = (await RestAPI.get(`/users/${creatorId}`).then((res) =>
        res.json()
      )) as any as User;
      return user;
    } else {
      const attributes = await fetchUserAttributes();

      const cId = `${attributes.sub}::${attributes.sub}`;
      const user: User = (await RestAPI.get(`/users/${cId}`).then(
        async (res) => await res.json()
      )) as any as User;
      return user;
    }
  } catch (err) {

    throw err;
  }
}
