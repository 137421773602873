import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Assignment } from "../types";
import { getAssignment } from "../util/assignment/getAssignment";
import useAsync from "../../../hooks/useAsync";
import { AnswerResult, GameData, GameState } from "../../game/Types/GameTypes";
import useUpdateEffect from "../../../hooks/useUpdateEffect";
import { createAssignmentResult } from "../util/assignmentResult/createAssignmentResult";
import { getStudentByIdentityId } from "../util/student/getStudentByIdentityId";
import { getIdentityId } from "../../game/util";
import { updateAssignmentResult } from "../util/assignmentResult/updateAssignmentResult";
import { useAssignmentResultsForStudent } from "../hooks/useStudentAssignmentResults";
import StudentContext from "../context/StudentContext";
import GibblyLoader from "../../../components/GibblyLoader";
import { Flex, Text } from "@aws-amplify/ui-react";
import GameView from "../../game/pages/v3/GameView";
import TranslationContextProvider, {
  useTranslationContext,
} from "../../../context/TranslationContextProvider";
import { ScreenReaderContextProvider } from "../../accessibility";

export default function AssignmentPlay() {
  const { classId, assignmentId } = useParams();

  const student = useContext(StudentContext);
  const navigate = useNavigate();
  const [gameData, setGameData] = useState<GameData>({
    gameState: GameState.WAITING,
    currentAnswer: [],
    questionIndex: 0,
    answerResults: [],
  });

  const { translations } = useTranslationContext();

  const assignmentResultIdRef = useRef<string>();

  const assignmentAsync = useAsync<Assignment>(async () => {
    if (classId === undefined || assignmentId === undefined) {
      throw new Error("classId or assignmentId is undefined");
    }
    const assignment = await getAssignment(classId, assignmentId);

    if (assignment === undefined) {
      throw new Error("assignment is undefined");
    }
    return assignment;
  }, [classId, assignmentId]);

  const quiz = useMemo(() => {
    if (assignmentAsync.value === undefined) return undefined;
    return assignmentAsync.value.quiz;
  }, [assignmentAsync.value]);

  const assignmentResults = useAssignmentResultsForStudent(
    assignmentId,
    student?.studentId
  );

  useUpdateEffect(() => {}, [assignmentResultIdRef.current]);

  useUpdateEffect(() => {
    if (assignmentResults.hasIncomplete) {
      const result = assignmentResults.incomplete;
      setGameData((prev) => ({
        ...prev,
        answerResults: result?.answerResults ?? [],
        questionIndex: result?.answerResults.length ?? 0,
      }));
    }
  }, [assignmentResults.value]);

  const onSubmitAnswer = useCallback(
    async (answerResults: AnswerResult[]) => {
      if (
        assignmentResultIdRef.current === undefined ||
        assignmentId === undefined
      )
        throw new Error("assignmentResult ID or assignment ID is undefined");
      await updateAssignmentResult(
        assignmentResultIdRef.current,
        assignmentId,
        {
          answerResults,
        }
      );
    },
    [assignmentResultIdRef.current]
  );

  // const initialAnswerResults = useMemo(() => {
  //   if (assignmentResults.value === undefined) return [];
  //   if (assignmentResults.incomplete)
  //     return assignmentResults.incomplete.answerResults;
  //   return [];
  // }, [assignmentResults.value]);

  const onStart = useCallback(async () => {
    if (assignmentResults.hasIncomplete) {
      assignmentResultIdRef.current = assignmentResults.incomplete?.id;
    } else {
      if (classId === undefined || assignmentId === undefined)
        throw new Error("classId or assignmentId is undefined");
      const identityId = await getIdentityId();
      const student = await getStudentByIdentityId(classId, identityId);
      if (student === undefined) throw new Error("student is undefined");
      const result = await createAssignmentResult(
        assignmentId,
        student.studentId
      );

      assignmentResultIdRef.current = result?.id;
    }
  }, [classId, assignmentId, assignmentResults]);

  const onComplete = useCallback(async () => {
    // update answerResults to have complete = true
    if (assignmentResultIdRef.current === undefined || !assignmentId) return;
    await updateAssignmentResult(assignmentResultIdRef.current, assignmentId, {
      complete: true,
    });
  }, [assignmentResultIdRef.current]);

  if (
    assignmentAsync.initializing ||
    assignmentAsync.loading ||
    assignmentResults.loading
  )
    return <GibblyLoader />;

  return (
    <>
      {quiz ? (
        <TranslationContextProvider lang={quiz.lang || "en"}>
          <ScreenReaderContextProvider>
            <GameView
              quiz={quiz}
              gameData={gameData}
              // setGameData={setGameData}
              onChange={(gameData) => setGameData(gameData)}
              onStart={onStart}
              onSubmitAnswer={async (answerResults) =>
                await onSubmitAnswer(answerResults)
              }
              onComplete={onComplete}
              onPlayAgain={() => {
                //refresh page
                // window.location.reload();
                navigate("/classroom/" + classId);
              }}
              onExit={() => {
                navigate("/classroom/" + classId);
              }}
              completeGameButtonText={translations.classroom}
            />
          </ScreenReaderContextProvider>
        </TranslationContextProvider>
      ) : (
        // <Game
        //   quiz={quiz}
        //   gameData={gameData}
        //   playMode="single-player"
        //   players={undefined}
        //   player={undefined}
        //   team={undefined}
        //   // setGameData={setGameData}
        //   onChange={(gameData) => setGameData(gameData)}
        //   onStart={onStart}
        //   onSubmitAnswer={async (answerResults) =>
        //     await onSubmitAnswer(answerResults)
        //   }
        //   onComplete={onComplete}
        //   onPlayAgain={() => {
        //     //refresh page
        //     window.location.reload();
        //     // navigate("/classroom/" + classId);
        //   }}
        //   onExit={() => {
        //     navigate("/classroom/" + classId);
        //   }}
        // />
        <Flex height="100vh" alignItems="center" justifyContent={"center"}>
          <Text fontSize={"large"} color={"white"} textAlign="center">
            We couldn't find that quiz
            <Text fontSize={"xxl"}> 🤷‍♀️ </Text>
          </Text>
        </Flex>
      )}
    </>
  );
}
