import RestAPI from "../../../util/RestAPI";

export async function listStripeSubscriptions(customerId: string) {
  return await RestAPI.get(`/stripe/subscriptions/${customerId}`)
    .then(async (res) => {
      return (await res.json()) as any[];
    })
    .catch((err) => {
      throw new Error("Couldn't list Subcscriptions: ", err);
    });
}
