import { Lesson } from "../types/lessonTypes";
import { getIdentityId } from "../../game/util";
import { copyS3Item } from "../../../services/s3/copyS3Item";
import { createLesson } from "./createLesson";
import { querySlides } from "./querySlides";
import { createSlide } from "./createSlide";

export async function duplicateLesson(lesson: Lesson) {
  const identityId = await getIdentityId();

  const { updatedAt, createdAt, privacy, id, image, userId, ...rest } = lesson;
  try {
    if (image.identityId && image.key) {
      await copyS3Item({
        identityId: image.identityId,
        fileName: image.key,
      });
    }

    const newLesson = await createLesson({
      ...rest,
      userId: identityId,
      title: `Copy of ${lesson.title}`,
      image: {
        key: image.key ?? "",
        alt: image?.alt ?? "",
        identityId: identityId,
      },
    });

    const slides = await querySlides(lesson.id);



    const slidePromises = slides.map(async (slide) => {
      for (let i = 0; i < slide.elements.length; i++) {
        const element = slide.elements[i];
        if (element.elementType === "image") {
          if (element.s3Item.identityId && element.s3Item.key)
            await copyS3Item({
              identityId: element.s3Item.identityId,
              fileName: element.s3Item.key ?? "",
            });
          element.s3Item.identityId = identityId;
        }
      }

      createSlide({ ...slide, lessonId: newLesson.id });
    });

    await Promise.all(slidePromises);

    return newLesson;
  } catch (err) {
    throw err;
  }
}
