import * as React from "react";
import { AvatarPicker } from "./AvatarPicker";
import RandomizeNicknameForm from "./RandomizeNicknameForm";
import EnterNicknameForm from "./EnterNicknameForm";
import { Flex } from "@aws-amplify/ui-react";
import { Avatar } from "../../Types/GameTypes_dep";
import { avatarColors, avatarGraphics } from "../../../../assets/avatars";
import { useTranslationContext } from "../../../../context/TranslationContextProvider";

export interface IAvatarAndNicknameFormProps {
  randomNicknames: boolean;
  onSubmit: (nickname: string, avatar: Avatar) => void | Promise<void>;
  initAvatar?: Avatar;
}

export function AvatarAndNicknameForm(props: IAvatarAndNicknameFormProps) {
  const { randomNicknames, onSubmit, initAvatar } = props;

  const { translations } = useTranslationContext();

  const [avatar, setAvatar] = React.useState<Avatar>(
    initAvatar ?? {
      index: Math.floor(Math.random() * avatarGraphics.length),
      backgroundColor:
        avatarColors[Math.floor(Math.random() * avatarColors.length)],
    }
  );

  return (
    <Flex direction={"column"}>
      <AvatarPicker avatar={avatar} setAvatar={setAvatar} />
      {randomNicknames ? (
        <RandomizeNicknameForm
          loadingText={translations.creating_player}
          onSubmit={async (nickname) => {
            await onSubmit(nickname, avatar);
          }}
        />
      ) : (
        <EnterNicknameForm
          loadingText={translations.creating_player}
          onSubmit={async (nickname) => {
            await onSubmit(nickname, avatar);
          }}
        />
      )}
    </Flex>
  );
}
