export function extractVideoId(url: string): string | null {
  try {
    const parsedUrl = new URL(url);
    if (["www.youtube.com", "youtube.com"].includes(parsedUrl.hostname)) {

      if (parsedUrl.pathname.includes("/embed/")) {
        return parsedUrl.pathname.split("/")[2];
      }
      // if shorts video url
      if (parsedUrl.pathname.includes("/shorts/")) {
        return parsedUrl.pathname.split("/")[2];
      }
      return parsedUrl.searchParams.get("v");
    } else if (["youtu.be", "www.youtu.be"].includes(parsedUrl.hostname)) {
      return parsedUrl.pathname.split("/")[1];
    }
  } catch (e) {
    //   console.error(`Failed to parse URL: ${url}`);
  }
  return null;
}
