import { StyleSheet } from "@react-pdf/renderer";

// Create styles
export const styles = StyleSheet.create({
  image: {
    // alignSelf: 'flex-start',
    maxHeight: "100px",
    flexShrink: 1,
    width: "100%",
    objectFit: "initial",
    // paddingX: 8,
    // paddingY: 2,
    borderRadius: "20%",
  },

  imageContainer: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "flex-start",
    display: "flex",
    flexShrink: 1,
    // width: '80px',
    // backgroundColor: 'green',
  },

  page: {
    fontFamily: "Poppins",
    fontSize: 13,
    fontStyle: "normal",
    padding: "0.25in 1in",
  },

  section: {
    padding: 10,
    // margin: 10,
    flexGrow: 1,
  },
  title: {
    fontSize: "20px",
    fontStyle: "bold",
    paddingTop: "30px",
    paddingBottom: "20px",
    borderBottom: "1px solid #b3b3b3",
    // backgroundColor: '#DDD',
  },
  questionGroup: {
    // backgroundColor: '#DDD',
    // paddingLeft: '10px',
    width: "100%",
    flexDirection: "column",
  },
  question: {
    // backgroundColor: 'purple',
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "flex-start",
    paddingTop: "15px",
    minHeight: "50px",
    fontStyle: "bold",
    fontWeight: "bold",
  },
  questionText: {
    // backgroundColor: '#AAA',
    // paddingLeft: 2,
    // flexWrap: 'wrap',
    // paddingRight: 5,
  },
  answerGroup: {
    // backgroundColor: 'orange',
    // flexGrow: 1,
    display: "flex",
    gap: 2,
    flexDirection: "column",
    fontStyle: "normal",
    fontWeight: "normal",
    width: "100%",
    paddingTop: "5px",
  },
  description: {
    padding: "0px",
    paddingBottom: "10px",
  },
  inputGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  inputGroupIem: {
    flex: "1",
    borderBottom: "1px solid #000",
    paddingRight: "20px",
  },
  "inputGroup label": {
    fontSize: "14px",
    fontWeight: 700,
    paddingBottom: "0px",
    paddingRight: "20px",
    borderBottom: "1px solid #000000",
  },
  correct: {
    fontStyle: "bold",
    backgroundColor: "#bdfcc6",
    borderRadius: 6,
    flexShrink: 0,
  },
  incorrect: {
    color: "#555",
  },

  centered: {
    textAlign: "center",
    display: "flex",
    color: "grey",
  },

  answerKeyBox: {
    // padding: 'auto',
    padding: 0,
    // width: 300,
    height: 35,
    backgroundColor: "#000",
    color: "#FFF",
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center",
    fontSize: "24px",
    fontStyle: "bold",
    borderRadius: 6,
    display: "flex",
    flexDirection: "row",
    // justifyContent: 'flex-start',
  },

  rowFlex: {
    display: "flex",
    gap: 5,
    flexDirection: "row",
    paddingRight: 15,
    width: "100%",
    // backgroundColor: 'yellow',
    alignItems: "flex-start",
  },
  columnFlex: {
    flexDirection: "column",
    // backgroundColor: 'red',
  },

  bulletPoint: {
    width: 13,
    fontSize: 10,
    color: "#AAA",
  },
  info: {
    paddingLeft: "28px",
    color: "#AAA",
  },

  typedAnswerBox: {
    // create a line
    borderBottom: "1px solid #000",
    // make it extend to the full width of the page
    width: "90%",
    height: "40px",
    flexGrow: 1,
    paddingTop: "30px",
    paddingLeft: "8px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
  },

  circle: {
    alignSelf: "center",
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: "#FFF",
    borderColor: "#000",
    border: 1.5,
    paddingRight: 10,
  },
  square: {
    alignSelf: "center",
    width: 15,
    height: 15,
    borderRadius: 2,
    backgroundColor: "#FFF",
    borderColor: "#000",
    border: 1.5,
    paddingRight: 10,
  },

  itemContent: {
    // flex: 1,
    paddingLeft: 5,
    paddingRight: 5,
    flexWrap: "wrap",
    // fontSize: 10,
  },

  // pageBreak: {
  //   pageBreakAfter: 'ALWAYS'
  // },
});
