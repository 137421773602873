import avatar0 from './avatar0.png';
import avatar1 from './avatar1.png';
import avatar2 from './avatar2.png';
import avatar3 from './avatar3.png';
import avatar4 from './avatar4.png';
import avatar5 from './avatar5.png';
import avatar6 from './avatar6.png';
import avatar7 from './avatar7.png';
import avatar8 from './avatar8.png';
import avatar9 from './avatar9.png';
import avatar10 from './avatar10.png';
import avatar11 from './avatar11.png';
import avatar12 from './avatar12.png';
import avatar13 from './avatar13.png';
import avatar14 from './avatar14.png';
import avatar15 from './avatar15.png';
import avatar16 from './avatar16.png';
import avatar17 from './avatar17.png';
import avatar18 from './avatar18.png';
import avatar19 from './avatar19.png';
import avatar20 from './avatar20.png';
import avatar21 from './avatar21.png';
import avatar22 from './avatar22.png';
import avatar23 from './avatar23.png';
import avatar24 from './avatar24.png';
import avatar25 from './avatar25.png';
import avatar26 from './avatar26.png';
import avatar27 from './avatar27.png';
import avatar28 from './avatar28.png';
import avatar29 from './avatar29.png';

export const avatarColors = [
    "var(--amplify-colors-red-60)",
    "var(--amplify-colors-green-40)",
    "var(--amplify-colors-blue-60)",
    "var(--amplify-colors-yellow-60)",
    "var(--amplify-colors-pink-60)",
    "var(--amplify-colors-blue-10)",
    "var(--amplify-colors-teal-20)",
    "var(--amplify-colors-pink-20)",
    "var(--amplify-colors-purple-10)",
    "var(--amplify-colors-purple-40)",
    "var(--amplify-colors-teal-60)",
    "var(--amplify-colors-purple-20)",
    "var(--amplify-colors-yellow-10)",
    "var(--amplify-colors-red-90)",
    "var(--amplify-colors-pink-90)",
    "var(--amplify-colors-blue-80)",
    "var(--amplify-colors-orange-60)",
    "var(--amplify-colors-purple-60)",
    "var(--amplify-colors-white)",
]


export const avatarGraphics = [avatar0, avatar1, avatar2, avatar3, avatar4, avatar5, avatar6, avatar7, avatar8, avatar9, avatar10, avatar11, avatar12, avatar13, avatar14, avatar15, avatar16, avatar17, avatar18, avatar19, avatar20, avatar21, avatar22, avatar23, avatar24, avatar25, avatar26, avatar27, avatar28, avatar29];