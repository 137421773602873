import { Button } from "@aws-amplify/ui-react";
import * as React from "react";
import { AnswerResult } from "../../../game/Types/GameTypes";
import useModal from "../../../../hooks/useModal";
import { AnswerPreview } from "./AnswerPreview";
import { Question } from "../../../../API";

export interface IResultsTableAnswerButtonProps {
  question: Question | null | undefined;
  answerResult: AnswerResult;
}

export function ResultsTableAnswerButton(
  props: IResultsTableAnswerButtonProps
) {
  const { question, answerResult } = props;

  const [PreviewModal, setPreviewModal] = useModal(
    {
      title: `Answer Preview`,
      ReactComponent: () => <AnswerPreview question={question} answerResult={answerResult}/>,
      hasBackButton: false,
      hasExitButton: true,
      size:"lg"
    },
    []
  );

  return (
    <>
      <PreviewModal />
      <Button
        size="small"
        width={"25px"}
        height={"25px"}
        borderRadius={"50%"}
        id="raised-btn"
        border={'none'}
        color={"white"}
        backgroundColor={answerResult.isCorrect ? "green.60" : "red.60"}
        onClick={() => setPreviewModal(true)}
      >
        {answerResult.isCorrect ? "✔" : "✖"}
      </Button>
    </>
  );
}
