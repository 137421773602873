import {
  Button,
  Card,
  // FileUploader,
  Flex,
  Loader,
  SelectField,
  Text,
  View,
} from "@aws-amplify/ui-react";
import React from "react";
import RaisedCard from "../../../components/RaisedCard";
import EditIcon from "../../../components/icons/EditIcon";
import { OpenAIDocument } from "./OpenAIDocument";

import useDocumentsQuery from "../../../hooks/useDocumentsQuery";
import { StorageManager } from "@aws-amplify/ui-react-storage";
import { uploadS3Item } from "../../../services/s3/uploadS3Item";
import { createFile } from "../../../services/openai/createFile";
import { getS3Url } from "../../../services/s3/getS3Url";
import { removeS3Item } from "../../../services/s3/removeS3Item";

type OpenAIDocSelectorProps = {
  doc: OpenAIDocument | undefined;
  onChange: (doc: OpenAIDocument | undefined) => void | Promise<void>;
};

export default function OpenAIDocSelector(props: OpenAIDocSelectorProps) {
  const { doc, onChange } = props;

  // const [state, setState] = React.useState<
  //   "processing" | "uploading" | undefined
  // >();
  const [isUploading, setIsUploading] = React.useState(false);

  const { docs, refetch } = useDocumentsQuery();
  // const { createFileMutation: createFile } = useOpenAiFileCreateMutation();

  if (isUploading) {
    return (
      <Card width={"100%"} height={"259px"}>
        <Flex
          width={"100%"}
          height={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Loader /> <Text className="bouncy">Finishing Upload ...</Text>
        </Flex>
      </Card>
    );
  } else if (doc) {
    return (
      <RaisedCard width={"100%"}>
        <View>
          <Flex alignItems={"center"}>
            <Text fontSize={"small"} fontWeight={"bold"}>
              Selected Document:
            </Text>
            <Button
              size="small"
              variation="menu"
              fontSize={"small"}
              onClick={() => onChange(undefined)}
            >
              <EditIcon />
            </Button>
          </Flex>
          <Text fontSize={"small"} isTruncated>
            {doc.path?.split("/").pop()}
          </Text>
        </View>
      </RaisedCard>
    );
  }

  return (
    <Flex direction={"column"} paddingTop={"small"}>
      <SelectField
        size="small"
        label={undefined}
        defaultValue="Select a document"
        onChange={(e) => {
          const doc = docs?.find((doc) => doc.path === e.target.value);
          onChange(doc);
        }}
      >
        <option value={undefined} disabled>
          Select a document
        </option>
        {docs?.map((doc, i) => (
          <option key={i} value={doc.path}>
            {doc.path?.split("/").pop()}
          </option>
        ))}
      </SelectField>
      <StorageManager
        // isPreviewerVisible={false}
        // accessLevel={"protected"}
        // shouldAutoProceed={true}

        autoUpload
        acceptedFileTypes={[".docx", ".pdf", ".md", ".html", ".txt", ".pptx"]}
        maxFileCount={1}
        path={({ identityId }) => `protected/${identityId}/documents/tmp/`}
        // processFile={async ({ file, key }) => {

        //   const { path } = await uploadS3Item({
        //     level: "protected",
        //     fileName: `tmp/${key}`,
        //     data: file,
        //   });

        //   const { id } = await createFile(path);

        //   await removeS3Item({
        //     level: "protected",
        //     fileName: `tmp/${key}`,
        //   });

        //   return {
        //     file,
        //     key,
        //     metadata: {
        //       fileId: id,
        //     },
        //   };
        // }}
        onUploadSuccess={async ({ key: path }) => {
          setIsUploading(true);

          const key = path?.split("/").pop();
          const { file: openAIFile } = await createFile(path as string);

          const { url } = await getS3Url({
            version: 1,
            level: "protected",
            key: `documents/tmp/${key}`,
          });

          const blob = await fetch(url).then((res) => res.blob());
          const file = new File([blob], key as string);

          await uploadS3Item({
            level: "protected",
            fileName: `documents/${key}`,
            data: file,
            options: {
              metadata: {
                fileId: openAIFile.id,
              },
            },
          });

          await removeS3Item({
            level: "protected",
            fileName: `documents/tmp/${key}`,
          });

          const { data: refetchDocs } = await refetch();

          const doc = refetchDocs?.find(
            (doc) => doc.path?.split("/").pop() === key
          );
          onChange(doc);
          setIsUploading(false);
        }}
      />
    </Flex>
  );
}
