import * as React from "react";

import { useRichTextContext } from "../RichTextContextProvider";

import { Button, Flex, TextField, View } from "@aws-amplify/ui-react";

import "../../../../styles/quill.css";
import { TooltipView } from "../../../../components/TooltipView";
import useClickOutsideDetection from "../../../../hooks/useClickOutsideDetection";
import { MdLink } from "react-icons/md";
import { FaTimes } from "react-icons/fa";

export interface HyperlinkProps {}

export default function Hyperlink(props: HyperlinkProps) {
  const {} = props;
  const { setCurrentFormats, currentEditor, currentFormats } =
    useRichTextContext();

  const [isOpen, setIsOpen] = React.useState(false);

  const [url, setUrl] = React.useState("");

  React.useEffect(() => {
    setUrl(currentFormats?.link || "");
  }, [currentFormats]);

  const onLink = React.useCallback(
    (link: string) => {
      currentEditor?.format("link", link);


      setCurrentFormats?.(currentEditor?.getFormat());
      // }
    },
    [currentEditor]
  );

  const buttonRef = React.useRef<HTMLButtonElement | null>(null);

  const tooltipRef = useClickOutsideDetection(
    (e) => {
      if (buttonRef.current?.contains(e.target as Node)) return;
      if (isOpen) setIsOpen(false);
    },
    [isOpen]
  );

  return (
    <TooltipView
      show={isOpen}
      placement="bottom"
      trigger={"click"}
      tooltipChildren={
        <View ref={tooltipRef}>
          {
            <Flex
              as={"form"}
              direction={"column"}
              gap={"xxxs"}
              onSubmit={(e) => {
                e.preventDefault();
                if (!url.startsWith("http://") && !url.startsWith("https://")) {
                  const fixedUrl = "https://" + url;
                  setUrl(fixedUrl);
                  onLink(fixedUrl);
                } else onLink(url);
              }}
            >
              <TextField
                label={"URL"}
                labelHidden
                // type="url"
                isRequired
                textAlign={"left"}
                 
                size="small"
                placeholder="https://www.example.com"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              ></TextField>
              <Flex gap={"2px"}>
                <Button
                  flex={1}
                  padding={"xxxs"}
                  backgroundColor={"transparent"}
                  variation="link"
                  size="small"
                  type="submit"
                >
                  Apply
                </Button>
                <TooltipView tooltipChildren={"Clear link"} placement="bottom">
                  <Button
                    flex={1}
                    padding={"xxxs small"}
                    backgroundColor={"transparent"}
                    height={"100%"}
                    variation="warning"
                    size="small"
                    type="reset"
                    onClick={() => {
                      onLink("");
                    }}
                  >
                    <FaTimes color="red" />
                  </Button>
                </TooltipView>
              </Flex>
            </Flex>
          }
        </View>
      }
      onToggle={(isOpen) => {
        setIsOpen(isOpen);
      }}
    >
      <Button
        ref={buttonRef}
        size="small"
        height={"100%"}
        padding={"xxs"}
        variation="link"
      >
        <MdLink />
      </Button>
    </TooltipView>
  );
}
