import { Flex, Grid, Text } from "@aws-amplify/ui-react";
import * as React from "react";
import ExitButton from "../../../../layouts/ExitButton";
import FullScreenButton from "../../../../layouts/FullScreenButton";
import { useGameContext } from "./GameContextProvider";
import AudioController from "../../../../layouts/AudioController";

// import gameMusic from "../../../../assets/audio/Music/bgm.wav";
// import endMusic from "../../../../assets/audio/Music/Casual - Main Theme (Loop).wav";

import useLobbyContext from "../../hooks/useLobbyContext";
import { GameState } from "../../Types/GameTypes";
import { useVolumeContext } from "../../../../context";

const waitingMusic = "/audio/music/Lobby - CG_BGM_1_3.wav";
const playingMusic = "/audio/music/Game - ArcadeGameBGM_2_Ingame.wav";
const completeMusic = "/audio/music/Podium - Happiness (Loopable).wav";

export interface IGameHeaderProps {}

export function GameFooter(props: IGameHeaderProps) {
  const {} = props;
  const { gameData, questions, onExit } = useGameContext();

  const { lobby, isHost } = useLobbyContext();

  const [music, setMusic] = React.useState(playingMusic);
  const { volume, setVolume, multiplier } = useVolumeContext();

  React.useEffect(() => {
    if (gameData.gameState === GameState.WAITING) {
      setMusic(waitingMusic);
    } else if (gameData.gameState === GameState.PLAYING) {
      setMusic(playingMusic);
    } else if (gameData.gameState === GameState.COMPLETE) {
      setMusic(completeMusic);
    }
  }, [gameData.gameState]);

  const questionIndex = React.useMemo(
    () => gameData.questionIndex,
    [gameData.questionIndex]
  );

  return (
    <Grid
      backgroundColor={"rgba(0,0,0,.5)"}
      width={"100%"}
      boxShadow={"0 5px 1px rgba(0, 0, 0, 0.1)"}
      justifyContent={"space-around"}
      templateColumns={"1fr 1fr"}
    >
      <Flex alignItems={"center"} padding={"xs"}>
        {questions ? (
          <Text
            id="displayText"
            color="white"
            fontWeight={"black"}
            fontSize={{ base: "xs", small: "larger" }}
          >
            {Math.min(questionIndex + 1, questions.length)}/{questions.length}
          </Text>
        ) : null}
      </Flex>

      <Flex gap={"large"} alignContent={"center"} justifyContent={"right"}>
        <AudioController
          src={lobby?.playerMusic || isHost ? music : undefined}
          loop
          autoPlay
          volume={volume}
          volumeMultiplier={multiplier}
          setVolume={setVolume}
        ></AudioController>
        <Flex alignContent={"center"}>
          {onExit && <ExitButton onExit={onExit} />}
          <FullScreenButton />
        </Flex>
      </Flex>
    </Grid>
  );
}
