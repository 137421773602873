import * as React from "react";
import RaisedCard from "../../../../components/RaisedCard";
import { Flex } from "@aws-amplify/ui-react";
import DeleteIcon from "../../../../components/icons/DeleteIcon";
import RaisedButton from "../../../../components/RaisedButton";
import useConfirmationModal from "../../../../hooks/useConfirmationModal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { OpenAIDocument } from "../../../generate/components/OpenAIDocument";
import RestAPI from "../../../../util/RestAPI";
import { removeS3Item } from "../../../../services/s3/removeS3Item";

export interface IDocumentListItemProps {
  doc: OpenAIDocument;
}

export function DocumentListItem({ doc }: IDocumentListItemProps) {
  const queryClient = useQueryClient();

  const { mutateAsync: deleteDocument, isLoading: isDeleting } = useMutation({
    mutationFn: async () => {
      const key = doc.path?.split("/").pop();
      if (key)
        await removeS3Item({
          level: "protected",
          fileName: `documents/${key}`,
        });
      else throw new Error("No key to delete");

      await RestAPI.del(`/openai/files/${doc.metadata?.fileid}`).catch(() => {
        throw new Error("Failed to delete file from OpenAI");
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(["documents"]);
    },
  });

  // const { mutateAsync: downloadDocument, isLoading: isDownloading } =
  //   useMutation({
  //     mutationFn: async () => {
  //       if (doc.key) {
  //         await getUrl(doc.key, { level: "protected", download: true });
  //         // trigger download

  //       } else throw new Error("No key to download");
  //     },
  //     onSettled: () => {
  //       queryClient.invalidateQueries(["documents"]);
  //     },
  //   });

  const [ConfirmationModal, setModal] = useConfirmationModal({
    confirmFn: async () => {
      await deleteDocument();
    },
  });

  return (
    <>
      <ConfirmationModal />
      <Flex opacity={isDeleting ? 0.5 : 1}>
        <RaisedCard fontSize={{ base: "xs", medium: "small" }} flex={1}>
          {" "}
          {doc.path?.split("/").pop()}
          <Flex alignItems={"center"} justifyContent={"end"}>
            <RaisedButton
              variation="destructive"
              size="small"
              onClick={() => setModal(true)}
              isLoading={isDeleting}
            >
              <DeleteIcon />
            </RaisedButton>
          </Flex>
        </RaisedCard>
        {/* <RaisedButton
          variation="primary"
          size="small"
          isLoading={isDownloading}
          onClick={() => downloadDocument()}
        >
          <FaFileDownload />
        </RaisedButton> */}
      </Flex>
    </>
  );
}
