import React from "react";
import { MdInfo } from "react-icons/md";
import { TooltipView } from "../TooltipView";
import { Text } from "@aws-amplify/ui-react";

interface IInfoIconProps extends React.SVGProps<SVGSVGElement> {
  message?: string;
}

export default function InfoIcon(props: IInfoIconProps) {
  const { message, ...iconProps } = props;

  return (
    <TooltipView
      tooltipProps={{
        id: `info-icon-tooltip-${message}`,
        style: {
          zIndex: 999999999,
        },
      }}
      tooltipChildren={<Text>{message}</Text>}
    >
      <MdInfo {...iconProps} />
    </TooltipView>
  );
}
