import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import GameView from "./GameView";
import { GameData, GameState } from "../../Types/GameTypes";
import useAsync from "../../../../hooks/useAsync";
import { getQuizForGame } from "../../../quiz/utils/getQuizForGame";
import { Flex, Text } from "@aws-amplify/ui-react";
import { CopyToClipboard } from "../../../../util/CopyToClipboard";
import isAuthenticated from "../../../../util/isAuthenticated";
import GibblyLoader from "../../../../components/GibblyLoader";
import Game from "./Game";
import { Quiz } from "../../../../API";
import { useQuery } from "@tanstack/react-query";

export default function QuickPlay() {
  const { quizId } = useParams();

  const navigate = useNavigate();

  const { data: quiz, isLoading } = useQuery<Quiz>(
    ["quiz", "game", quizId],
    async () => {
      return await getQuizForGame(quizId as string);
    },
    {
      refetchOnMount: true,
    }
  );

  const authenticated = useAsync(async () => await isAuthenticated(), []);

  const [gameData, setGameData] = useState<GameData>({
    gameState: GameState.WAITING,
    currentAnswer: [],
    questionIndex: 0,
    answerResults: [],
  });

  return (
    <>
      {isLoading ? (
        <Flex
          justifyContent="center"
          alignItems="center"
          flex={1}
          grow={1}
          width={"100%"}
          height={"100dvh"}
          minHeight={"100vh"}
        >
          <GibblyLoader />
        </Flex>
      ) : quiz ? (
        <Game
          quiz={quiz}
          gameData={gameData}
          playMode="single-player"
          players={undefined}
          player={undefined}
          team={undefined}
          // setGameData={setGameData}
          onChange={(gameData) => setGameData(gameData)}
          onStart={(gameData) => setGameData(gameData)}
          onPlayAgain={() => {
            setGameData({
              gameState: GameState.WAITING,
              currentAnswer: [],
              questionIndex: 0,
              answerResults: [],
            });
          }}
          onShare={() => {
            CopyToClipboard(window.location.href, "Game Link Copied! 🚀");
          }}
          onExit={() => {
            if (authenticated.value) navigate("/dashboard/library");
            else navigate("/");
          }}
        />
      ) : (
        <Flex height="100vh" alignItems="center" justifyContent={"center"}>
          <Text fontSize={"large"} color={"white"} textAlign="center">
            We couldn't find that quiz
            <Text fontSize={"xxl"}> 🤷‍♀️ </Text>
          </Text>
        </Flex>
      )}
    </>
  );
}
