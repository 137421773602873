import { SlideElement } from "../types/slideTypes";
import { v4 as uuidv4 } from "uuid";
import { Op } from "quill-delta";

export enum SlideElementsTemplate {
  Title = "Title Slide",
  TitleImage = "Title & Image",
  HeaderText = "Text Only",
  ImageRight = "Text & Image",
  ImageLeft = "Image & Text",
  Image = "Image Only",
  DiscussionQuestion = "Question & Image",
}

export interface TemplateContent {
  headerText?: string;
  bodyText?: string;
  imagePath?: string;
  bodyOps?: Op[];
}

export function getTemplatedElements(
  template: SlideElementsTemplate,
  content: TemplateContent
): SlideElement[] {
  const { headerText, bodyText, imagePath = "", bodyOps } = content;

  switch (template) {
    case SlideElementsTemplate.Title:
      return [
        {
          id: uuidv4(),
          elementType: "text",
          styleDefaults: {
            font: "tahoma",
            size: "36pt",
            bold: true,
          },
          props: {
            valign: "middle",
            ops: [
              {
                insert: headerText,
                attributes: { bold: true, font: "tahoma", size: "36pt" },
              },
              { insert: "\n", attributes: { align: "center" } },
            ],
          },
          positionProps: {
            x: 0.5,
            y: 0.5,
            w: 9,
            h: 4.625,
          },
        },
      ];
    case SlideElementsTemplate.TitleImage:
      return [
        {
          id: uuidv4(),
          elementType: "text",
          styleDefaults: {
            font: "tahoma",
            size: "36pt",
            bold: true,
          },
          props: {
            valign: "middle",
            align: "center",
            ops: [
              {
                insert: headerText,
                attributes: { bold: true, font: "tahoma", size: "36pt" },
              },
              { insert: "\n", attributes: { align: "center" } },
            ],
          },
          positionProps: {
            x: 0.5,
            y: 0.299,
            w: 9,
            h: 1.087,
          },
        },
        {
          id: uuidv4(),
          elementType: "image",
          props: {
            path: imagePath,
            x: 0.5,
            y: 1.497,
            w: 9,
            h: 3.57,
            sizing: {
              type: "contain",
              x: 0.5,
              y: 1.497,
              w: 9,
              h: 3.57,
            },
          },
          s3Item: {
            name: "default-slide-img.png",
            level: "public",
          },
        },
      ];
    case SlideElementsTemplate.ImageRight:
      return [
        {
          id: uuidv4(),
          elementType: "text",
          styleDefaults: {
            font: "tahoma",
            size: "36pt",
            bold: true,
          },
          props: {
            valign: "middle",
            ops: [
              {
                insert: headerText,
                attributes: { bold: true, font: "tahoma", size: "36pt" },
              },
              { insert: "\n", attributes: { align: "center" } },
            ],
          },
          positionProps: {
            x: 0.5,
            y: 0.299,
            w: 9,
            h: 1.087,
          },
        },
        {
          id: uuidv4(),
          elementType: "text",
          styleDefaults: {
            font: "tahoma",
            size: "18pt",
          },
          props: {
            valign: "top",
            ops: bodyOps?.map((op) => ({
              ...op,
              attributes: { ...op.attributes, font: "tahoma", size: "18pt" },
            })) ?? [
              {
                insert: bodyText,
                attributes: { font: "tahoma", size: "18pt" },
              },
              { insert: "\n", attributes: { align: "left" } },
            ],
          },
          positionProps: {
            x: 0.5,
            y: 1.497,
            w: 4.76,
            h: 3.57,
          },
        },
        {
          id: uuidv4(),
          elementType: "image",
          props: {
            path: imagePath,
            x: 5.5,
            y: 1.497,
            w: 4,
            h: 3.57,
            sizing: {
              type: "contain",
              x: 5.5,
              y: 1.497,
              w: 4,
              h: 3.57,
            },
          },
          s3Item: {
            name: "default-slide-img.png",
            level: "public",
          },
        },
      ];
    case SlideElementsTemplate.ImageLeft:
      return [
        {
          id: uuidv4(),
          elementType: "text",
          styleDefaults: {
            font: "tahoma",
            size: "36pt",
            bold: true,
          },
          props: {
            valign: "middle",
            align: "center",
            ops: [
              {
                insert: headerText,
                attributes: { bold: true, font: "tahoma", size: "36pt" },
              },
              { insert: "\n", attributes: { align: "center" } },
            ],
          },
          positionProps: {
            x: 0.5,
            y: 0.299,
            w: 9,
            h: 1.087,
          },
        },
        {
          id: uuidv4(),
          elementType: "image",
          props: {
            path: imagePath,
            x: 0.5,
            y: 1.497,
            w: 4,
            h: 3.57,
            sizing: {
              type: "contain",
              x: 0.5,
              y: 1.497,
              w: 4,
              h: 3.57,
            },
          },
          s3Item: {
            name: "default-slide-img.png",
            level: "public",
          },
        },
        {
          id: uuidv4(),
          elementType: "text",
          styleDefaults: {
            font: "tahoma",
            size: "18pt",
          },
          props: {
            valign: "top",
            ops: bodyOps?.map((op) => ({
              ...op,
              attributes: { ...op.attributes, font: "tahoma", size: "18pt" },
            })) ?? [
              {
                insert: bodyText,
                attributes: { font: "tahoma", size: "18pt" },
              },
              { insert: "\n", attributes: { align: "left" } },
            ],
          },
          positionProps: {
            x: 5.5 - 0.76,
            y: 1.497,
            w: 4.76,
            h: 3.57,
          },
        },
      ];
    case SlideElementsTemplate.HeaderText:
      return [
        {
          id: uuidv4(),
          elementType: "text",
          styleDefaults: {
            font: "tahoma",
            size: "36pt",
            bold: true,
          },
          props: {
            valign: "middle",
            ops: [
              {
                insert: headerText,
                attributes: { bold: true, font: "tahoma", size: "36pt" },
              },
              { insert: "\n", attributes: { align: "center" } },
            ],
          },
          positionProps: {
            x: 0.5,
            y: 0.299,
            w: 9,
            h: 1.087,
          },
        },
        {
          id: uuidv4(),
          elementType: "text",
          styleDefaults: {
            font: "tahoma",
            size: "18pt",
          },
          props: {
            // fontSize: 13.5,
            // fontFace: "Calibri",
            valign: "top",
            ops: [
              {
                insert: bodyText,
                attributes: { font: "tahoma", size: "18pt" },
              },
              { insert: "\n", attributes: { align: "left" } },
            ],
          },
          positionProps: {
            x: 0.5,
            y: 1.497,
            w: 9,
            h: 3.57,
          },
        },
      ];
    case SlideElementsTemplate.Image:
      return [
        {
          id: uuidv4(),
          elementType: "image",
          s3Item: {
            name: "default-slide-img.png",
            level: "public",
          },
          props: {
            path: imagePath,
            x: 0,
            y: 0,
            w: 10,
            h: 5.625,
            sizing: {
              type: "cover",
              x: 0, // Add x
              y: 0, // Add y
              w: 10, // Add width
              h: 5.625, // Add height
            },
          },
        },
      ];
    case SlideElementsTemplate.DiscussionQuestion:
      return [
        {
          id: uuidv4(),
          elementType: "text",
          styleDefaults: {
            font: "tahoma",
            size: "28pt",
            bold: true,
          },
          props: {
            // fontSize: 24,
            // fontFace: "Calibri",
            valign: "middle",
            ops: [
              {
                insert: headerText,
                attributes: { bold: true, font: "tahoma", size: "28pt" },
              },
              { insert: "\n", attributes: { align: "left" } },
            ],
          },
          positionProps: {
            x: 0.5,
            y: 0.497,
            w: 4.76,
            h: 4.57,
          },
        },
        {
          id: uuidv4(),
          elementType: "image",
          props: {
            path: imagePath,
            x: 5.5,
            y: 0.497,
            w: 4,
            h: 4.57,
            sizing: {
              type: "contain",
              x: 5.5,
              y: 0.497,
              w: 4,
              h: 4.57,
            },
          },
          s3Item: {
            name: "default-slide-img.png",
            level: "public",
          },
        },
      ];
  }
}
