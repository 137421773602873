import * as React from "react";
import { FaCog } from "react-icons/fa";
import { Button } from "@aws-amplify/ui-react";
import { useBillingPortal } from "../hooks/useBillingPortal";

interface IBillingPortalButtonProps {}

const BillingPortalButton: React.FunctionComponent<
  IBillingPortalButtonProps
> = ({}) => {
  const { openBillingPortal, isLoading, isError } = useBillingPortal();

  if (isError || isLoading) return null;

  return (
    <Button
      size="small"
      id="raised-btn"
      padding={"xs"}
      color={"black"}
      backgroundColor={"white"}
      gap={"xs"}
      fontSize={"smaller"}
      onClick={openBillingPortal}
      // variation="link"
    >
      <FaCog />
      Billing & Plan
    </Button>
  );
};

export default BillingPortalButton;
