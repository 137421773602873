import {
  Flex,
  Breadcrumbs,
  Menu,
  MenuItem,
  Accordion,
  Button,
  Badge,
} from "@aws-amplify/ui-react";
import React, { useMemo, useState } from "react";
import { CurriculumBreadcrumItem } from "./CurriculumBreadcrumItem";
import { DocumentSelector } from "./DocumentSelector";
import { useQuery } from "@tanstack/react-query";
import { list } from "aws-amplify/storage";
import { formatCurriculumFiles, getFilesAtBreadcrumb } from "../util";
import { ConfigFile, CurriculumFile } from "../types";
import { FaChevronRight } from "react-icons/fa6";

type Props = {
  selectedFiles: string[];
  setSelectedFiles: (files: string[]) => void;
  breadcrumbs: string[];
  setBreadcrumbs: React.Dispatch<React.SetStateAction<string[]>>;
};

export default function CurriculumAccordian({
  selectedFiles,
  setSelectedFiles,
  breadcrumbs,
  setBreadcrumbs,
}: Props) {
  const [accordionValue, setAccordionValue] = useState<string[]>(["item"]);

  const { data: curriculumFiles } = useQuery({
    queryKey: ["curriculum-files"],
    queryFn: async () => {
      let fileStructure: CurriculumFile[] = [];
      // let nextToken: string | undefined = undefined;
      // do {
      const results = await list({
        path: "public/curriculum/",
        options: {
          listAll: true,
        },
      });

      const paths = results.items
        .filter((file) => file.path.match(/\.[0-9a-z]+$/i))
        .map((f) => f.path.replace("public/curriculum/", ""))
        .filter((fn) => fn !== "");

      fileStructure = [
        ...fileStructure,
        ...(await formatCurriculumFiles(paths)),
      ];

      // nextToken = results.nextToken;
      // } while (nextToken);

      return fileStructure;
    },
  });

  const currentFiles = useMemo(
    () =>
      curriculumFiles ? getFilesAtBreadcrumb(curriculumFiles, breadcrumbs) : [],
    [curriculumFiles, breadcrumbs]
  );

  const currentFolders = currentFiles.filter((f) => f.type === "folder");
  // const currentDocuments = currentFiles.filter((f) => f.type === "document");
  const currentConfig = currentFiles.find((f) => f.type === "config");

  return (
    <Accordion.Container
      backgroundColor={"background.primary"}
      value={accordionValue}
    >
      <Accordion.Item value="item">
        <Accordion.Trigger
          backgroundColor={"background.primary"}
          fontSize={"small"}
          onClick={() => {
            setAccordionValue((prev) =>
              prev.includes("item")
                ? accordionValue
                : [...accordionValue, "item"]
            );
          }}
        >
          <Flex gap="0">
            <Button
              padding={"xxxs"}
              variation="link"
              height={"28px"}
              onClick={(e) => {
                e.stopPropagation();
                setAccordionValue((prev) =>
                  prev.includes("item")
                    ? accordionValue.filter((item) => item !== "item")
                    : [...accordionValue, "item"]
                );
              }}
            >
              <Accordion.Icon />
            </Button>
            <Breadcrumbs.Container padding={"0"}>
              {breadcrumbs.map((_, i) => (
                <React.Fragment key={i}>
                  <CurriculumBreadcrumItem
                    index={i}
                    breadcrumbs={breadcrumbs}
                    onClick={(index) => {
                      setBreadcrumbs((prev) => prev.slice(0, index));
                    }}
                  />
                  <Breadcrumbs.Separator>
                    <FaChevronRight />
                  </Breadcrumbs.Separator>
                </React.Fragment>
              ))}
              {currentFolders.length !== 0 ? (
                <>
                  {/* <Breadcrumbs.Separator /> */}
                  <Breadcrumbs.Item>
                    <Menu
                      isOpen={currentFolders.length !== 0}
                      trigger={
                        <Badge borderRadius={"small"} padding={"xxs"}>
                          Make a selection
                        </Badge>
                      }
                      className="curriculum-select"
                      size="small"
                      // value={autoCompleteValue}
                      // onChange={(e) => {
                      //   e.preventDefault();
                      //   e.stopPropagation();
                      //   // setAutoCompleteValue(e.target.value);
                      // }}
                      // onClear={() => setAutoCompleteValue("")}
                      // options={currentFolders.map((f) => ({
                      //   id: f.name,
                      //   label: f.name,
                      // }))}
                      // onSelect={(option) => {
                      //   setBreadcrumbs((prev) => [...prev, option.id]);
                      // }}
                      // onClick={(e) => {
                      //   e.stopPropagation();
                      // }}
                    >
                      {currentFolders.map((f) => (
                        <MenuItem
                          size="small"
                          key={f.name}
                          onClick={() => {
                            setBreadcrumbs((prev) => [...prev, f.name]);
                            setSelectedFiles([]);
                          }}
                        >
                          {f.name}
                        </MenuItem>
                      ))}
                    </Menu>
                  </Breadcrumbs.Item>
                </>
              ) : null}
            </Breadcrumbs.Container>
          </Flex>
        </Accordion.Trigger>
        {currentConfig ? (
          <Accordion.Content>
            <DocumentSelector
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              setBreadcrumbs={setBreadcrumbs}
              configFile={
                currentConfig as {
                  name: string;
                } & ConfigFile
              }
            ></DocumentSelector>
          </Accordion.Content>
        ) : null}
      </Accordion.Item>
    </Accordion.Container>
  );
}
