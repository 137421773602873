import React from 'react'
import { formatDueDate } from '../util/formatDueDate';
import { Flex, Text, Button } from '@aws-amplify/ui-react';
import CalenderButton from '../../../components/CalenderButton';
import useUpdateEffect from '../../../hooks/useUpdateEffect';
import CloseIcon from '../../../components/icons/CloseIcon';

export default function DateTimePicker({ dateName, value, onChange}: { dateName: string, value?: Date, onChange?: (date: Date) => void, flex?: number }) {


  const [date, setDate] = React.useState<Date>(new Date(0))

  useUpdateEffect(() => {

    if (onChange) onChange(date)
  }, [date])

  useUpdateEffect(() => {
    if (value) setDate(value)
  }, [value])

  return (
    <Flex flex={2} alignItems={"baseline"} rowGap={"smaller"} justifyContent={'space-between'} direction={{ base: 'column', medium: 'row' }}>
      <Text fontSize={"small"} fontWeight={'bolder'}>{dateName}</Text>
      <i><Text fontWeight={"semibold"} fontSize={"small"}>
        {value !== undefined && value.getTime() !== 0 ? formatDueDate(value) : date.getTime() !== 0 ? formatDueDate(date) : "Not specified "}
         <Button color={'red.60'} fontSize={"small"} padding={"xxs"} onClick={() => { setDate(new Date(0)) }} variation='link'><CloseIcon /></Button>
      </Text></i>
      <Flex alignItems={'center'}>

        <CalenderButton
          onChangeDate={(date) => setDate(date)}
        />
      </Flex>
    </Flex>

  )
}
