import { useCallback, useEffect } from "react";

export default function useUnloadPrevention(active?: boolean) {
  const callback = useCallback(
    (event: BeforeUnloadEvent) => {

      if (active !== undefined && !active) return;
      event.preventDefault();
      event.returnValue = "";
    },
    [active]
  );

  useEffect(() => {
    window.addEventListener("beforeunload", callback);

    return () => {
      window.removeEventListener("beforeunload", callback);
    };
  }, [callback]);
}
