import { GetQuizQuery, Quiz } from "../../../API";
import RestAPI from "../../../util/RestAPI";

export async function getQuizForGame(quizId: string) {
  // const query = /* GraphQL */ `
  // query GetQuestionsFromQuiz {
  //   getQuiz(id: "${quizId}") {
  //    title
  //    description
  //    lang
  //    image{
  //      key
  //      identityId
  //    }
  //     Questions {
  //       items {
  //         question {
  //           id
  //           image {
  //            key
  //            identityId
  //           }
  //           type
  //           text
  //           answers
  //           correctIndices
  //           solution
  //         }
  //       }
  //     }
  //   }
  // }
  // `;

  try {
    // const client = generateClient();

    // const result = await client.graphql<GraphQLQuery<GetQuizQuery>>({
    //   query,
    // });
    const result = (await RestAPI.post(`/quiz`, {
      body: {
        quizId,
      },
    }).then((response) => response.json())) as any as { data: GetQuizQuery };

    return result.data.getQuiz as Quiz;
  } catch (err) {

    throw err;
  }
}
