import * as React from "react";

import { sizes, useRichTextContext } from "../RichTextContextProvider";

import { SelectField } from "@aws-amplify/ui-react";

import "../../../../styles/quill.css";

export interface FontSizeProps {}

export default function FontSize(props: FontSizeProps) {
  const {} = props;
  const { setCurrentFormats, currentEditor, currentFormats } =
    useRichTextContext();

  const onSize = React.useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {

      currentEditor?.format("size", e.target.value);
      setCurrentFormats?.(currentEditor?.getFormat());
    },
    [currentFormats, currentEditor]
  );

  return (
    <SelectField
      size="small"
      label={"size"}
      width={"100px"}
      inputStyles={{ border: "1px solid var(--amplify-colors-neutral-20" }}
      labelHidden
      defaultValue="18pt"
      value={currentFormats?.size}
      onChange={onSize}
    >
      <option hidden value={undefined}></option>
      {sizes.map((size, i) => (
        <option key={i} value={size + "pt"}>
          {size}
        </option>
      ))}
    </SelectField>
  );
}
