import { DependencyList, useEffect } from "react";
import useTimeout from "./useTimeout";

export default function useDebounce(
  callback: () => any | Promise<any>,
  delay: number,
  dependencies: DependencyList
) {
  const { reset, clear } = useTimeout(callback, delay);
  useEffect(reset, [...dependencies, reset]);
  useEffect(clear, [clear]);
}
