import React from "react";
import { Image, ImageProps } from "@aws-amplify/ui-react";

type MedalProps = Omit<ImageProps, "alt" | "src"> & {
  type: "gold" | "silver" | "bronze";
};

export default function Medal({ type, ...imageProps }: MedalProps) {
  return (
    <Image
      alt={type + " medal"}
      src={`/images/game/${type}-medal.png`}
      {...imageProps}
    />
  );
}
