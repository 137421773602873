import { Button, Card, Flex, Heading, View } from "@aws-amplify/ui-react";
import * as React from "react";
import PlayIcon from "../../../../components/icons/PlayIcon";
import { Quiz } from "../../../../API";
import { GameData } from "../../Types/GameTypes";
import { useMemo } from "react";
import { useTranslationContext } from "../../../../context/TranslationContextProvider";
import { useScreenReaderContext } from "../../../accessibility";

export interface IGameStartMenuProps {
  quiz: Quiz | null | undefined;
  gameData: GameData;
  isStarting: boolean;
  startGame?: () => void;
}

export function GameStartMenu(props: IGameStartMenuProps) {
  const { quiz, isStarting, startGame, gameData } = props;

  const questionIndex = useMemo(
    () => gameData.questionIndex,
    [gameData.questionIndex]
  );
  const { ScreenReadButton, elementRef, stop } = useScreenReaderContext();

  const { start, continue_ } = useTranslationContext().translations;
  return (
    <Flex
      ref={elementRef}
      justifyContent={"center"}
      alignItems={"center"}
      direction={"column"}
      gap={"2rem"}
      position={"relative"}
    >
      <View position={"absolute"} top={"small"} left={"small"}>
        <ScreenReadButton />
      </View>
      <Card
        width={"80%"}
        minHeight={{ base: "50px", medium: "100px" }}
        maxWidth={"800px"}
        id="raised-item"
        borderRadius={"2px"}
      >
        <Flex direction={"column"}>
          <Heading
            id="displayText"
            textAlign={"center"}
            fontWeight={"bold"}
            fontSize={{ base: "large", medium: "xxl" }}
          >
            {quiz?.title}
          </Heading>
        </Flex>
      </Card>

      <Flex justifyContent={"center"}>
        {startGame && (
          <Button
            isLoading={isStarting}
            id={"raised-btn"}
            backgroundColor={"teal.60"}
            variation="primary"
            size="large"
            gap={"small"}
            onClick={() => {
              startGame();
              stop();
            }}
          >
            <PlayIcon />
            {questionIndex === 0 ? start : continue_}
          </Button>
        )}
      </Flex>
    </Flex>
  );
}
