import { Flex, Heading, Grid } from "@aws-amplify/ui-react";
import * as React from "react";
import AddSlideTemplatedButton from "./AddSlideTemplatedButton";
import { SlideElementsTemplate } from "../../../util/getTemplatedElements";

export interface IAddSlideOptionsProps { }

export default function AddSlideOptions(props: IAddSlideOptionsProps) {
  const { } = props;

  return (
    <Flex direction={"column"} paddingTop={"small"}>
      {/* <Flex direction={"column"} gap={"xs"}>
        <Heading>Question Types</Heading>
        <RaisedButton>Single Select</RaisedButton>
        <RaisedButton>Multi Select</RaisedButton>
        <RaisedButton>True False</RaisedButton>
        <RaisedButton>Typed Answer</RaisedButton>
      </Flex> */}
      <Flex direction={"column"} gap={"xs"}>
        <Heading fontSize={"small"} color={"neutral.10"}>Slide Types</Heading>

        <Grid templateColumns={"1fr 1fr"} gap={"xs"} textAlign={"center"} >
          {Object.values(SlideElementsTemplate).map((template, i) => (
            <AddSlideTemplatedButton key={i} template={template} >
              {template}
            </AddSlideTemplatedButton>
          ))}
        </Grid>


      </Flex>
    </Flex>
  );
}
