import React from "react";
import {
  Flex,
  Authenticator,
  Text,
  Link,
  Badge,
  Card
} from "@aws-amplify/ui-react";
import { PageIndex, PageTitle } from "../util/SEO";

export default function GibblyAcademy() {
  PageTitle("Academy | Gibbly");
  PageIndex("noindex");

  return (
    <>
      <Authenticator>
        <Flex direction={"column"} padding={"medium"} paddingTop={"large"}>
          <Flex
            justifyContent={"space-between"}
            padding={"zero"}
            margin={"zero"}
          >
            <Text id="pageTitle">Gibbly Academy</Text>
          </Flex>

          <Flex direction={"column"} padding={"large 0 0 0"}>
            <i><Text fontSize={"small"}>Check out these videos on how to use Gibbly! Still have questions? Let us know at <Link href="mailto:support@gibbly.app">support@gibbly.app</Link>.</Text></i>

            <Flex wrap="wrap" gap="large" justifyContent={{ base: "center", xl: "left" }}>


              <Card id="raised-item">
                <Flex direction={"column"} gap="xs">
                  <Flex alignItems="center">
                    <Badge id="raised-item" borderRadius={"100%"} color={"white"} backgroundColor={"teal.60"} fontSize={"xs"}>1</Badge>
                    <i>  <Text fontWeight={"medium"} fontSize={"small"} style={{ fontFamily: "Poppins" }}>Navigating the Gibbly Site</Text></i>
                  </Flex>
                  <iframe width="280" height="158" src="https://www.youtube-nocookie.com/embed/C9V8e_CVTZY?si=TmLIIzE87sRuQTGk" title="Navigating the Gibbly Site" frameBorder="0" data-allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </Flex>
              </Card>

              <Card id="raised-item">
                <Flex direction={"column"} gap="xs" >
                  <Flex alignItems="center">
                    <Badge id="raised-item" borderRadius={"100%"} color={"white"} backgroundColor={"teal.60"} fontSize={"xs"}>2</Badge>
                    <i> <Text fontWeight={"medium"} fontSize={"small"} style={{ fontFamily: "Poppins" }}>Creating Your First Quiz</Text></i>
                  </Flex>
                  <iframe width="280" height="158" src="https://www.youtube-nocookie.com/embed/nAwBlCjh2CY?si=DbJKH2QPXl4_M-75" title="Creating Your First Quiz" frameBorder="0" data-allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </Flex>
              </Card>


              <Card id="raised-item">
                <Flex direction={"column"} gap="xs" >
                  <Flex alignItems="center">
                    <Badge id="raised-item" borderRadius={"100%"} color={"white"} backgroundColor={"teal.60"} fontSize={"xs"}>3</Badge>
                    <i>   <Text fontWeight={"medium"} fontSize={"small"} style={{ fontFamily: "Poppins" }}>Creating Your First Lesson</Text></i>
                  </Flex>
                  <iframe width="280" height="158" src="https://www.youtube-nocookie.com/embed/pwJPSFXhMus?si=uYcn6lNxDRc9ht7Y" title="Creating Your First Lesson" frameBorder="0" data-allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </Flex>
              </Card>

              <Card id="raised-item">
                <Flex direction={"column"} gap="xs" >
                  <Flex alignItems="center">
                    <Badge id="raised-item" borderRadius={"100%"} color={"white"} backgroundColor={"teal.60"} fontSize={"xs"}>4</Badge>
                    <i>  <Text fontWeight={"medium"} fontSize={"small"} style={{ fontFamily: "Poppins" }}>Creating Your First Class</Text></i>
                  </Flex>
                  <iframe width="280" height="158" src="https://www.youtube-nocookie.com/embed/_OvSt_TCohc?si=Ge10fIPYLCgG9PrH" title="Creating Your First Class" frameBorder="0" data-allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </Flex>
              </Card>

              <Card id="raised-item">
                <Flex direction={"column"} gap="xs" >
                  <Flex alignItems="center">
                    <Badge id="raised-item" borderRadius={"100%"} color={"white"} backgroundColor={"teal.60"} fontSize={"xs"}>5</Badge>
                    <i> <Text fontWeight={"medium"} fontSize={"small"} style={{ fontFamily: "Poppins" }}>Hosting Your First Game</Text></i>
                  </Flex>
                  <iframe width="280" height="158" src="https://www.youtube-nocookie.com/embed/RMWYJEIYb4w?si=bTh03PQemJL7hllD" title="Hosting Your First Game" frameBorder="0" data-allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </Flex>
              </Card>

              <Card id="raised-item">
                <Flex direction={"column"} gap="xs" >


                  <Flex alignItems="center">
                    <Badge id="raised-item" borderRadius={"100%"} color={"white"} backgroundColor={"teal.60"} fontSize={"xs"}>6</Badge>
                    <i>  <Text fontWeight={"medium"} fontSize={"small"} style={{ fontFamily: "Poppins" }}>Get Gibbly Premium for FREE</Text></i>
                  </Flex>

                  <iframe width="280" height="158" src="https://www.youtube-nocookie.com/embed/AfSUj8LJVtM?si=h5I4enIkrDqHD2-w" title="Get Gibbly Premium for FREE" frameBorder="0" data-allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </Flex>
              </Card>

            </Flex>
          </Flex>
        </Flex>
      </Authenticator>
    </>
  );
}
