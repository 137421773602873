import { DynamoDBItem } from "../../types/DynamoDBTypes";
import RestAPI from "../../util/RestAPI";

export async function getDatabaseItem<
  T extends DynamoDBItem,
  K extends Partial<T>
>(endpoint: string, keys: K): Promise<T> {
  const keyString = Object.values(keys).join("/");
  try {
    const item: T = (await RestAPI.get(`${endpoint}/object/${keyString}`).then(
      async (res) => await res.json()
    )) as any as T;

    if ((item as any).$metadata) throw item;
    if (Object.keys(item).length === 0) throw new Error("Item not found");
    return item;
  } catch (err) {

    throw err;
  }
}

// Usage:
// const student = await getDatabaseItem<Student, StudentKey>("/students", { classId, studentId });
// const classroom = await getDatabaseItem<Classroom, ClassroomKey>("/classrooms", { teacherId, id });
