import * as React from "react";
import { StorageManager } from "@aws-amplify/ui-react-storage";
import { useUserContext } from "../../../context";
import { S3ObjectProtected } from "../../../API";
import { v4 } from "uuid";
import {
  Alert,
  Card,
  Divider,
  Flex,
  Link,
  Loader,
  Text,
} from "@aws-amplify/ui-react";

import { useQuery } from "@tanstack/react-query";
import { getAudioUrl } from "../services";
import { FaArrowLeft, FaMicrophone, FaX } from "react-icons/fa6";
import { removeS3Item } from "../../../services/s3/removeS3Item";
import AudioRecorder from "./AudioRecorder";
import { uploadS3Item } from "../../../services/s3/uploadS3Item";
import { RaisedButton } from "../../../components";

export interface IAudioUploaderProps {
  audio?: S3ObjectProtected | null | undefined;

  onUploadSuccess?: ({
    key,
    identityId,
  }: {
    key: string;
    identityId: string;
  }) => void | Promise<void>;

  onRemoveAudio?: ({
    key,
    identityId,
  }: {
    key: string;
    identityId: string;
  }) => void | Promise<void>;
}

export function AudioUploader({
  audio,
  onUploadSuccess,
  onRemoveAudio,
}: IAudioUploaderProps) {
  const { user, isPremium } = useUserContext();

  const [state, setState] = React.useState<"edit" | "preview" | "record">(
    audio ? "preview" : "edit"
  );

  const [removing, setRemoving] = React.useState(false);
  const [error, setError] = React.useState<Error | null>(null);
  const { data: audioData } = useQuery(
    ["audio-data", audio?.key, audio?.identityId],
    async () => {
      const url = await getAudioUrl(audio as S3ObjectProtected);
      return url;
    },
    {
      enabled: !!audio,
    }
  );

  if (removing) {
    return (
      <Flex>
        <Loader />
        <Text>Removing Audio</Text>
      </Flex>
    );
  } else if (error) {
    return (
      <Alert hasIcon variation="error" heading={error.message}>
        <Link onClick={() => setError(null)}>
          <FaArrowLeft /> Go back
        </Link>
      </Alert>
    );
  }

  switch (state) {
    case "edit":
      return (
        <Flex direction={"column"}>
          {user?.customerTier !== "Premium" ? (
            <Alert
              hasIcon
              variation="info"
              heading={"Audio upload is a premium feature"}
            >
              <Link href="/dashboard/upgrade" isExternal>
                Upgrade now
              </Link>
            </Alert>
          ) : null}
          <StorageManager
            acceptedFileTypes={["audio/*"]}
            maxFileSize={10 * 1024 * 1024}
            maxFileCount={1}
            path={({ identityId }) => `protected/${identityId}/`}
            autoUpload={true}
            processFile={({ file }) => {
              if (!isPremium()) {
                const error = new Error("Audio upload is a premium feature");
                setError(error);
                throw error;
              }
              const fileExtension = file.name.split(".").pop();

              return {
                file,
                key: `audio-${v4()}.${fileExtension}`,
              };
            }}
            onUploadError={(error) => {
              console.error("Upload error", error);
            }}
            onUploadSuccess={async ({ key }) => {
              const shortKey = key?.split("/").pop();
              const identityId = user?.identityId;
              if (!shortKey || !identityId)
                throw new Error("No key or identityId");
              await onUploadSuccess?.({ key: shortKey, identityId });
              setState("preview");
            }}
          />
          {/* <Flex direction={"column"}> */}
          <Flex alignItems={"center"}>
            <Divider />
            <Text alignSelf={"center"}>
              <b>OR</b>
            </Text>
            <Divider />
          </Flex>
          <RaisedButton
            variation="menu"
            gap={"xs"}
            size="large"
            alignSelf={"center"}
            onClick={() => setState("record")}
          >
            <FaMicrophone /> Record Audio
          </RaisedButton>
          {/* </Flex> */}

          {audio ? (
            <Link
              alignSelf={"end"}
              onClick={() => {
                setState("preview");
              }}
            >
              Preview Audio
            </Link>
          ) : null}
        </Flex>
      );
    case "record":
      return (
        <Flex direction={"column"}>
          {user?.customerTier !== "Premium" ? (
            <Alert
              hasIcon
              variation="info"
              heading={"Audio upload is a premium feature"}
            >
              <Link href="/dashboard/upgrade" isExternal>
                Upgrade now
              </Link>
            </Alert>
          ) : null}
          <Flex direction={"column"} width={"100%"}>
            <AudioRecorder
              onRecordingStop={async (file) => {
                const key = `audio-${v4()}.wav`;
                if (!user?.identityId) throw new Error("No identityId");
                uploadS3Item({
                  level: "protected",
                  fileName: key,
                  data: file,
                });

                await onUploadSuccess?.({ key, identityId: user.identityId });
                setState("preview");
              }}
            />
            <Flex justifyContent={"space-between"} width={"100%"}>
              <Link onClick={() => setState("edit")}>
                <FaArrowLeft />
                Go Back
              </Link>
            </Flex>
          </Flex>
        </Flex>
      );
    case "preview":
      return (
        <Flex direction={"column"}>
          {audio ? (
            <Card
              backgroundColor={"background.tertiary"}
              borderRadius={"medium"}
            >
              <Flex direction={"column"}>
                <Text>
                  <b>Uploaded Audio</b>
                  <br />
                  {/* {audio.key} */}
                </Text>
                {/* <Card
                  backgroundColor={"neutral.90"}
                  borderRadius={"100px"}
                  padding={"medium xl"}
                > */}
                <audio
                  controls
                  src={audioData}
                  style={{ width: "100%" }}
                  onError={(e) => {
                    console.error(e);
                  }}
                ></audio>
                {/* <AudioController src={audioUrl} /> */}
                {/* </Card> */}
              </Flex>
            </Card>
          ) : (
            <Text>No audio found 😕</Text>
          )}
          <Flex justifyContent={"space-between"}>
            <Link
              color={"font.error"}
              onClick={async () => {
                setRemoving(true);

                await removeS3Item({
                  fileName: audio?.key as string,
                  level: "protected",
                });

                await onRemoveAudio?.({
                  key: audio?.key as string,
                  identityId: audio?.identityId as string,
                });
                setState("edit");
                setRemoving(false);
              }}
            >
              <FaX /> Remove Audio
            </Link>
            <Link
              onClick={() => {
                setState("edit");
              }}
            >
              Change Audio
            </Link>
          </Flex>
        </Flex>
      );
    default:
      return null;
  }
}
