import { queryDatabaseItems } from "../../../services/database/queryDatabaseItems";
import generateRandomNumericCode from "../../../util/generateRandomNumericString";
import { Lobby } from "../Types/LobbyTypes";

export async function getAvailableLobbyPin() {
    let pin = "";
    let lobbies: Lobby[] | undefined = [];
    do {
        try {
            pin = generateRandomNumericCode(6);
            lobbies = await queryDatabaseItems<Lobby>('/lobbies', pin)
            if (lobbies === undefined) throw new Error('Error getting lobbies')
        } catch (err) {
            console.error(err)
            break;
        }
    } while (lobbies?.length > 0)
    
    if (pin === "") throw new Error('Error finding available lobby pin')
    return pin;
}