import { Icon } from '@aws-amplify/ui-react'
import React from 'react'

export default function CloseIcon() {
    return (
        <Icon ariaLabel="Close" fill="currentcolor"
      pathData="M5.479 19.784c-0.566 -0.566 -1.117 -1.117 -1.668 -1.668 -0.604 -0.605 -0.596 -1.341 -0.024 -1.984 1.187 -1.333 2.355 -2.683 3.54 -4.038 -0.884 -1.011 -1.761 -2.014 -2.639 -3.016 -0.332 -0.379 -0.677 -0.748 -1 -1.134 -0.482 -0.576 -0.439 -1.309 0.095 -1.844a2064.375 2064.375 0 0 1 2.321 -2.321c0.56 -0.559 1.319 -0.557 1.917 -0.027 1.344 1.191 2.698 2.37 4.071 3.573 0.674 -0.589 1.343 -1.171 2.009 -1.756 0.718 -0.63 1.425 -1.274 2.155 -1.89 0.575 -0.486 1.302 -0.427 1.843 0.114a2659.697 2659.697 0 0 1 2.299 2.299c0.577 0.578 0.573 1.324 0.028 1.938 -1.192 1.342 -2.37 2.698 -3.566 4.063 0.927 1.054 1.847 2.099 2.767 3.145 0.291 0.331 0.584 0.659 0.868 0.996 0.484 0.572 0.449 1.313 -0.082 1.845a1847.221 1847.221 0 0 1 -2.321 2.321c-0.573 0.572 -1.328 0.564 -1.94 0.02 -1.342 -1.193 -2.698 -2.37 -4.057 -3.561 -1.193 1.046 -2.377 2.084 -3.562 3.121 -0.237 0.207 -0.467 0.425 -0.722 0.607 -0.533 0.379 -1.205 0.314 -1.668 -0.142 -0.218 -0.214 -0.432 -0.431 -0.664 -0.663z">
      
         </Icon>
  )
}