// Custom CSS
import "@aws-amplify/ui-react/styles.css";
import "react-toastify/dist/ReactToastify.css";
import "react-calendar/dist/Calendar.css";
import "bootstrap/dist/css/bootstrap.css";

import "./index.css";
import "./App.css";

// React
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
// Components
import App from "./App";
// MISC
import reportWebVitals from "./reportWebVitals";
// AWS
import {
  Authenticator,
  createTheme,
  ThemeProvider,
} from "@aws-amplify/ui-react";
import studioTheme from "./ui-components/studioTheme";

// Amplify.configure(awsExports); // configures amplify
// As you add or remove categories and make updates
// to your backend configuration using the Amplify CLI,
// the configuration in aws-exports.js will update automatically.

// const queryClient = new QueryClient({
//   defaultOptions: { queries: { staleTime: 1000 * 600 } } // 10 min staleTime
// });

const newThemObject = studioTheme;
newThemObject.tokens.fonts.default.variable.value = "Poppins";

const theme = createTheme(newThemObject);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <Authenticator.Provider>
      <ThemeProvider theme={theme}>
        {/* <QueryClientProvider client={queryClient}> */}
        <App />
        {/* <ReactQueryDevtools /> */}
        {/* </QueryClientProvider> */}
      </ThemeProvider>
    </Authenticator.Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
