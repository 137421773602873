import { Button, Flex } from '@aws-amplify/ui-react'
import React from 'react'
import { FaPlusCircle } from 'react-icons/fa'



interface Props {
  index: number
  onClick?: (index: number) => void | Promise<void>
}

export default function AddQuestionHereButton(props: Props) {
  const { index, onClick } = props

  return (
    <Flex justifyContent={'center'} >
      <Button width={'100%'} border={'0'} backgroundColor={'transparent'} color={"purple.60"} padding={'0'} onClick={async () => {

        await onClick?.(index)
      }}>
        <FaPlusCircle className="add-question-icon" />
      </Button>
    </Flex>
  )
}
