import React, { useEffect, useState } from "react";
import { View, Flex, ScrollView, Grid, Card } from "@aws-amplify/ui-react";

import StopGameButton from "./StopGameButton";
import LobbyInfoCard from "./LobbyInfoCard";
import useLobbyContext from "../../hooks/useLobbyContext";
// import leaderboardMusic from "../../../../assets/audio/Music/bgm.wav";
import AudioController from "../../../../layouts/AudioController";
import PodiumView from "./PodiumView";

import "../../../../styles/leaderboard.css";
import LeaderboardView from "./LeaderboardView";
import LobbyHeaderButtons from "./LobbyHeaderButtons";
import { useVolumeContext } from "../../../../context";

enum AnimationPhase {
  Idle = 0,
  LBOut,
}

type Menu = "leaderboard" | "podium";

export default function Leaderboard() {
  const { lobby, updateLobby, teams } = useLobbyContext();

  const [animationPhase, setAnimationPhase] = useState<AnimationPhase>(0);

  const [menu, setMenu] = useState<Menu>("leaderboard");

  const { volume, setVolume, multiplier } = useVolumeContext();

  useEffect(() => {
    if (lobby?.lobbyState === "PODIUM") {
      showPodium();
    }
  }, [lobby?.lobbyState]);

  useEffect(() => {
    if (teams.length === 0) return;
    const allTeamsFinished = teams.every(
      (team) =>
        team.gameData.answerResults.length ===
        lobby?.quiz.Questions?.items.length
    );
    if (allTeamsFinished) {
      setAnimationPhase(0);
      showPodium();
    }
  }, [teams]);

  const showPodium = () => {
    // setTimeout(() => setAnimationPhase(1), 0); // Wait for the screen elements to animate out

    setMenu("podium");
    setTimeout(() => {
      setAnimationPhase(0);
    }, 1000); // Show Podium
  };

  return (
    <>
      <ScrollView>
        <Flex
          direction={"column"}
          height={"100dvh"}
          minHeight={"100vh"}
          width={"100%"}
          gap={"medium"}
          rowGap={"xl"}
          alignItems={"center"}
        >
          {menu === "leaderboard" ? (
            <>
              <View
                className={animationPhase >= 1 ? " animate-out" : ""}
                alignSelf={"stretch"}
                position="sticky"
                top={"xs"}
                style={{
                  zIndex: "100",
                  pointerEvents: "none",
                }}
              >
                <Flex direction={"column"} gap={"0"}>
                  <LobbyHeaderButtons exitTo="/dashboard/library" />
                  <LobbyInfoCard />
                </Flex>
              </View>{" "}
              <View
                className={
                  "leaderboard " + (animationPhase >= 1 ? "animate-out" : "")
                }
                width={"100%"}
              >
                <LeaderboardView />
              </View>
              <Grid
                flex={1}
                templateColumns={"1fr auto 1fr"}
                justifyContent={"center"}
                alignItems={"end"}
                padding={"small xl small xl"}
                position={"sticky"}
                width={"100%"}
                bottom={"0"}
              >
                <Flex gap={"small"} column={2}>
                  <StopGameButton
                    onConfirm={async () => {
                      if (lobby)
                        await updateLobby({
                          lobby: { ...lobby, lobbyState: "PODIUM" },
                        });
                    }}
                  />
                </Flex>
                <Flex justifyContent={"end"}>
                  <Card backgroundColor={"rgba(0,0,0,0.5)"}>
                    <AudioController
                      src={"/audio/music/Game - ArcadeGameBGM_2_Ingame.wav"}
                      loop
                      autoPlay
                      volume={volume}
                      setVolume={setVolume}
                      volumeMultiplier={multiplier}
                    />
                  </Card>
                </Flex>
              </Grid>
            </>
          ) : (
            <PodiumView />
          )}
        </Flex>
      </ScrollView>
    </>
  );
}
