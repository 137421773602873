import { Icon } from '@aws-amplify/ui-react'
import React from 'react'

export default function YouTubeIcon() {
    return (
        <Icon ariaLabel="YouTube" fill="currentcolor"
      pathData="M12 2.76C0.206 2.76 0 3.809 0 12s0.206 9.24 12 9.24 12 -1.049 12 -9.24 -0.206 -9.24 -12 -9.24zm3.846 9.641l-5.388 2.515c-0.472 0.218 -0.858 -0.026 -0.858 -0.547V9.631c0 -0.52 0.386 -0.766 0.858 -0.547l5.388 2.515c0.472 0.221 0.472 0.581 0 0.802z">
      
         </Icon>
  )
}