import * as React from "react";

import { useRichTextContext } from "../RichTextContextProvider";

import { Button } from "@aws-amplify/ui-react";
import { MdFormatItalic } from "react-icons/md";

export interface ItalicsProps { }

export default function Italics(props: ItalicsProps) {
  const { } = props;
  const { setCurrentFormats, currentEditor, currentFormats } =
    useRichTextContext();

  const onItalics = React.useCallback(() => {

    if (!currentFormats || !currentEditor) {
      return;
    }

    currentEditor.format("italic", !currentFormats.italic);
    setCurrentFormats?.(currentEditor.getFormat());
  }, [currentFormats, currentEditor]);

  return (
    <Button
      size="small"
      variation="link"
      padding={"xxs"}
      backgroundColor={currentFormats?.italic ? "#eee" : undefined}
      onClick={onItalics}
    >
      <MdFormatItalic />
    </Button>
  );
}
