import * as React from "react";

export enum MenuState {
  OVERVIEW,
  ADD,
  MEDIA,
  TEXT,
  BACKGROUND,
  PREVIEW,
}

interface IMenuContext {
  menuState: MenuState;
  setMenuState: React.Dispatch<React.SetStateAction<MenuState>>;
}

export const MenuContext = React.createContext({} as IMenuContext);

export interface ICreatePageProps {
  children?: React.ReactNode;
}

export default function CreatePage(props: ICreatePageProps) {
  const { children } = props;

  const [menuState, setMenuState] = React.useState<MenuState>(
    MenuState.OVERVIEW
  );

  return (
    <MenuContext.Provider
      value={{
        menuState,
        setMenuState,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
}
