import React, { useMemo, useState } from "react";

import { Card, Flex } from "@aws-amplify/ui-react";

import { Question } from "../../../API";
import QuestionItemType from "./QuestionItemType";
import QuestionItemText from "./QuestionItemText";
import QuestionItemAnswers from "./QuestionItemAnswers";

import QuestionItemSolution from "./QuestionItemSolution";
import QuestionItemButtons from "./QuestionItemButtons";
import { ImageComponent } from "../../../components/ImageComponent";

export default function QuestionView({
  question,
}: {
  question: Question | null | undefined;
}): JSX.Element {
  const [showAnswers, setShowAnswers] = useState(false);

  const answers = useMemo(() => {
    if (question) return question.answers as string[];
    else return;
  }, [question?.answers]);

  if (!question) return <></>;

  return (
    <Card
      boxShadow={"0px -5px rgb(0 0 0 / 20%) inset, 0px 0px 4px rgb(0 0 0 / 15%"}
    >
      <Flex direction={{ base: "column", medium: "row" }}>
        <Flex direction="column" flex={1}>
          <Card backgroundColor={"background.secondary"} padding={"small"}>
            {question && (
              <Flex
                direction={"column"}
                gap={"medium"}
                justifyContent={"space-between"}
              >
                <Flex
                  justifyContent={"space-between"}
                  direction={{ base: "column", xl: "row" }}
                >
                  <Flex direction={"column"} gap={"small"} flex={1}>
                    <QuestionItemType />
                    <QuestionItemText />
                  </Flex>
                  {question.image && question.image !== null && (
                    <ImageComponent
                      alt={`Question Image`}
                      image={question.image}
                      width={"160px"}
                      borderRadius={"large"}
                      height={"90px"}
                      objectFit={"cover"}
                      autoPickPrompt={""}
                    ></ImageComponent>
                  )}
                </Flex>
                {showAnswers && answers && (
                  <>
                    <QuestionItemAnswers
                      answers={answers}
                      question={question}
                    />

                    <QuestionItemSolution
                      solution={question.solution ? question.solution : ""}
                    />
                  </>
                )}
              </Flex>
            )}
          </Card>

          <QuestionItemButtons
            showAnswers={showAnswers}
            setShowAnswers={setShowAnswers}
            question={question}
          />
        </Flex>
      </Flex>
    </Card>
  );
}
