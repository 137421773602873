import RestAPI from "../util/RestAPI";

async function getQRCode(text: string): Promise<string> {
  try {
    const response = await RestAPI.get("/qr-code", {
      queryParams: {
        text,
      },
    });
    return response.text();
  } catch (error) {
    console.error("Error fetching QR code:", error);
    throw error;
  }
}

export default getQRCode;
