import { Flex, Card, ScrollView, Text, Grid } from "@aws-amplify/ui-react";
import * as React from "react";
import { Feedback } from "./GameView";
import { QuestionType } from "../../../../API";
import { useTranslationContext } from "../../../../context/TranslationContextProvider";

export interface IFeedbackViewProps {
  feedback: Feedback | undefined;
  questionType: QuestionType | null | undefined;
}

export function FeedbackView(props: IFeedbackViewProps) {
  const { feedback, questionType } = props;

  const { translations } = useTranslationContext();

  return (
    <Card
      backgroundColor={feedback?.isCorrect ? "green.80" : "red.80"}
      id="raised-item"
      height={"100%"}
      padding={"xxs"}
    >
      <Grid
        templateRows={{
          base: "25px minmax( 0, .9fr )",
          medium: "50px minmax( 0, 1fr )",
        }}
        height={"100%"}
      >
        <Text
          row={1}
          textAlign={"center"}
          fontWeight={"bold"}
          fontSize={{ base: "smaller", medium: "medium", large: "large" }}
          color={"white"}
          id="displayText"
        >
          {feedback?.isCorrect ? translations.correct : translations.incorrect}
        </Text>

        <ScrollView
          padding={"xs"}
          borderRadius={"medium"}
          backgroundColor={"rgba(0,0,0,0.3)"}
          maxHeight={"20vh"}
          // height={{base:"20dvh",medium:"200px"}}
          row={2}
        >
          {questionType === "TYPED_ANSWER" && (
            <Card backgroundColor={"rgba(0,0,0,0.3)"} padding={"xxs"}>
              <Text
                textAlign={"center"}
                color={"white"}
                opacity={"100"}
                fontSize={{
                  base: "smaller",
                  medium: "medium",
                  // large: "large"
                }}
              >
                {translations.accepted_answers}:
              </Text>
              <Flex direction={"row"} justifyContent={"center"}>
                {feedback?.acceptedAnswers.map((answer, i) => (
                  <Text
                    textAlign={"center"}
                    color={"white"}
                    opacity={"100"}
                    fontSize={{
                      base: "smaller",
                      medium: "larger",
                    }}
                    fontWeight={"bold"}
                    key={i}
                  >
                    {answer}
                  </Text>
                ))}
              </Flex>
            </Card>
          )}
          <Text
            padding={"xxxs"}
            color={"white"}
            opacity={"100"}
            textAlign={"center"}
            fontSize={{
              base: "smaller",
              medium: "medium",
              large: "larger",
            }}
          >
            {feedback?.solution}
          </Text>
        </ScrollView>
      </Grid>
    </Card>
  );
}
