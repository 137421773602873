import { Button, Flex, Grid } from "@aws-amplify/ui-react";
import * as React from "react";
import RaisedCard from "../../../../components/RaisedCard";
import { PlayerAvatar } from "../v4/PlayerAvatar";
import { useGameContext } from "./GameContextProvider";
import SettingsIcon from "../../../../components/icons/SettingsIcons";
import { TooltipView } from "../../../../components/TooltipView";
import { useTranslationContext } from "../../../../context/TranslationContextProvider";

export interface IGameHeaderProps {}

export default function GameHeader(props: IGameHeaderProps) {
  const {} = props;
  const { players, isMyTurn, turnIndex, teamNumber, playMode, settings } =
    useGameContext();
  const [show, setShow] = React.useState(false);

  const { translations } = useTranslationContext();

  if (playMode !== "multi-player") return <></>;

  return (
    <Grid
      templateColumns={"1fr auto 1fr"}
      justifyContent={"center"}
      alignItems={"center"}
      paddingLeft={"small"}
      paddingRight={"small"}
    >
      <Flex justifyContent={"start"}>
        {isMyTurn ? (
          <RaisedCard
            className="bouncy"
            fontWeight={"black"}
            textAlign={"center"}
            padding={{ base: "small", medium: undefined }}
            fontSize={{ base: "xs", medium: "medium" }}
          >
            {translations.its_your_turn}
          </RaisedCard>
        ) : null}
      </Flex>
      <Flex justifyContent={"center"} alignItems={"center"} gap={"0"}>
        {players.map((player, index) => (
          <PlayerAvatar
            key={player.userId}
            player={player}
            size={
              turnIndex === index
                ? { base: "35px", medium: "45px" }
                : { base: "30px", medium: "40px" }
            }
            opacity={turnIndex === index ? 1 : 0.5}
            showName={false}
          />
        ))}
      </Flex>
      <Flex justifyContent={"end"} alignItems={"center"}>
        {settings ? (
          <TooltipView show={show} placement="auto" tooltipChildren={settings}>
            <Button
              variation="menu"
              color={"white"}
              padding={"0"}
              size="large"
              onClick={() => {
                setShow(!show);
              }}
            >
              <SettingsIcon />
            </Button>
          </TooltipView>
        ) : null}
        <RaisedCard
          fontWeight={"bold"}
          borderRadius={"50px"}
          textAlign={"center"}
          padding={{ small: "xs large xs large", medium: undefined }}
          fontSize={{ base: "xs", medium: "medium" }}
        >
          {translations.team} {teamNumber}
        </RaisedCard>
      </Flex>
    </Grid>
  );
}
