import * as React from "react";
import useDocumentsQuery from "../../../../hooks/useDocumentsQuery";
import { Flex, Grid, Text } from "@aws-amplify/ui-react";
import { DocumentListItem } from "./DocumentListItem";

export interface IDocumentCollectionProps {}

export function DocumentCollection({}: IDocumentCollectionProps) {
  const { docs } = useDocumentsQuery();

  return (
    <>
      <Flex direction={"column"} paddingTop={"medium"}>
        <Text fontSize={"smaller"} textAlign={"center"}>
          You can upload documents from the quiz generator.
        </Text>
        <Grid
          gap={"small"}
          templateColumns={{
            base: "1fr",
            small: "1fr 1fr",
            large: "1fr 1fr 1fr",
          }}
        >
          {docs?.map((doc) => (
            <DocumentListItem key={doc.path} doc={doc} />
          ))}
        </Grid>
      </Flex>
    </>
  );
}
