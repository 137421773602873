import * as React from "react";
import { Divider, Flex } from "@aws-amplify/ui-react";

import "../../../../styles/quill.css";
import Color from "./RichTextToolbar.Color";
import Highlight from "./RichTextToolbar.Highlight";
import Hyperlink from "./RichTextToolbar.Hyperlink";
import FontSize from "./RichTextToolbar.FontSize";
import FontFamily from "./RichTextToolbar.FontFamily";
import Bold from "./RichTextToolbar.Bold";
import Italics from "./RichTextToolbar.Italics";
import Underline from "./RichTextToolbar.Underline";
import OrderedList from "./RichTextToolbar.OrderedList";
import BulletedListButton from "./RichTextToolbar.BulletedList";

export interface IRichTextToolbarProps { }

export default function RichTextToolbar(props: IRichTextToolbarProps) {
  const { } = props;

  return (
    <>

      <Flex wrap={"wrap"} gap={"xxxs"} padding={"xs"} backgroundColor={"white"}
        style={{ borderBottom: "1px var(--amplify-colors-neutral-20) solid" }}>
        <Flex gap={"xxxs"}>
          <FontFamily />
          <FontSize />
        </Flex>

        <Flex gap={"xxxs"}>
          <Bold />
          <Italics />
          <Underline />
          <Highlight />
          <Color />
        </Flex>
        <Flex alignItems={"center"}>
          <Divider height={"large"} size="small" orientation="vertical" style={{ borderColor: "var(--amplify-colors-neutral-60" }} />
        </Flex>
        <Flex gap={"xxxs"}>
          <BulletedListButton />
          <OrderedList />
          <Hyperlink />
        </Flex>
      </Flex>

    </>
  );
}
