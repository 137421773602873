import { CheckboxField, Grid, View, Text } from "@aws-amplify/ui-react";
import * as React from "react";
import RaisedButton from "../../../../components/RaisedButton";
import { Question } from "../../../../API";
import { AnswerColours } from "./AnswerSelect";

export interface IMultiSelectButtonGroupProps {
  question: Question;
  answer: number[] | string;
  setAnswer: React.Dispatch<React.SetStateAction<number[] | string>>;
  isAnswered: boolean;
  onChange?: (answer: number[] | string) => void;
  isMyTurn?: boolean;
}

export function MultiSelectButtonGroup(props: IMultiSelectButtonGroupProps) {
  const { question, onChange, answer, setAnswer, isAnswered, isMyTurn } = props;

  return (
    <View height={"100%"} width={"100%"}>
      <Grid
        templateColumns={"1fr 1fr"}
        templateRows={"1fr 1fr"}
        height={"100%"}
        width={"100%"}
        gap={"xs"}
      >
        {question?.answers.map((optionText, i) => (
          <RaisedButton
            justifyContent={"center"}
            textAlign={"left"}
            gap={"small"}
            alignItems={"center"}
            size="large"
            borderRadius={"xxxs"}
            fontSize={{
              base: "small",
              medium: "medium",
              xxl: "large",
            }}
            padding="xs"
            key={i}
            isDisabled={isAnswered || (isMyTurn !== undefined && !isMyTurn)}
            color={"white"}
            backgroundColor={
              !answer || !isAnswered
                ? AnswerColours[i]
                : isAnswered && question.correctIndices.includes(i)
                ? "green.60"
                : "red.80"
            }
            border={
              (answer as number[]).includes(i) ? `3px solid white` : "none"
            }
            // opacity={
            //   !answer ||
            //   answer.length === 0 ||
            //   (answer.length > 0 &&
            //     ((answer as number[]).includes(i) ||
            //       (isAnswered && question.correctIndices[0] === i)))
            //     ? 1
            //     : 0.8
            // }
            onClick={() => {
              setAnswer((prev) => {
                if (prev === undefined) return [i];
                if ((prev as number[]).includes(i)) {
                  //remove all occurences of i
                  if (onChange)
                    onChange((prev as number[]).filter((item) => item !== i));
                  return (prev as number[]).filter((item) => item !== i);
                } else {
                  if (onChange) onChange([...(prev as number[]), i]);
                  return [...(prev as number[]), i];
                }
              });
            }}
          >
            <Grid
              templateColumns={"1fr 6fr"}
              width={"100%"}
              columnGap={"xxs"}
              height={"100%"}
              alignContent={"center"}
              alignItems={"center"}
              // pointer-events={"none"}
            >
              <CheckboxField
                label={undefined}
                name={"option " + i}
                value={"option " + i}
                column={1}
                width={"100%"}
                height={"100%"}
                fontSize={{ base: "small", medium: "xl", large: "xxl" }}
                justifyContent={"center"}
                checked={(answer as number[]).includes(i)}
                isDisabled={isAnswered || (isMyTurn !== undefined && !isMyTurn)}
                onClick={() => {
                  setAnswer((prev) => {
                    if (prev === undefined) return [i];
                    if ((prev as number[]).includes(i)) {
                      //remove all occurences of i
                      if (onChange)
                        onChange(
                          (prev as number[]).filter((item) => item !== i)
                        );
                      return (prev as number[]).filter((item) => item !== i);
                    } else {
                      if (onChange) onChange([...(prev as number[]), i]);
                      return [...(prev as number[]), i];
                    }
                  });
                }}
              />
              <Text color={"white"} column={2}>
                {optionText}
              </Text>
            </Grid>
          </RaisedButton>
        ))}
      </Grid>
    </View>
  );
}
