import { CurriculumFile } from "../types";

export async function formatCurriculumFiles(
  paths: string[]
): Promise<CurriculumFile[]> {
  const root: CurriculumFile[] = [];

  for (const path of paths) {
    await addPathToStructure(root, path);
  }

  return root;
}

async function addPathToStructure(
  parent: CurriculumFile[],
  originalPath: string,
  localPath = originalPath
) {
  const pathParts = localPath.split("/");
  const [head, ...tail] = pathParts;
  let node = parent.find((f) => f.name === head);

  if (!node) {
    if (tail.length === 0) {
      if (originalPath.endsWith("config.json")) {

        node = {
          name: head,
          type: "config",
          path: originalPath,
        };
      } else node = { name: head, type: "document", path: originalPath };
    } else {
      node = { name: head, type: "folder", children: [] };
    }
    parent.push(node);
  }

  if (node.type === "folder" && tail.length > 0) {
    await addPathToStructure(node.children, originalPath, tail.join("/"));
  }
}
