import { Flex, TextField, Text, Heading, Link } from "@aws-amplify/ui-react";
import React from "react";
import { RaisedButton } from "../../../components";
import {
  confirmSignUp,
  ConfirmSignUpInput,
  ConfirmSignUpOutput,
  resendSignUpCode,
  ResendSignUpCodeInput,
} from "aws-amplify/auth";
import { useMutation } from "@tanstack/react-query";

type Props = {
  username: string;
  codeDeliveryDetails: { destination: string | undefined };
  // onSetMenuState: (menuState: "signup" | "confirmation" | "login") => void;
  onNextStep: (nextStep: ConfirmSignUpOutput["nextStep"]) => void;
};

export function ConfirmSignUp({
  username,
  codeDeliveryDetails,
  onNextStep,
}: Props) {
  const [formData, setFormData] = React.useState<ConfirmSignUpInput>({
    username,
    confirmationCode: "",
  });

  const { destination } = codeDeliveryDetails;
  const { confirmationCode } = formData;

  const { isError, error, isLoading, mutateAsync } = useMutation(
    ["ConfirmSignUp"],
    async (args: ConfirmSignUpInput) => {
      const { nextStep } = await confirmSignUp({
        ...args,
        options: { autoSignIn: true },
      });

      if (nextStep) {
        onNextStep(nextStep);
      }
    }
  );

  const { isLoading: resendingCode, mutateAsync: resendCode } = useMutation(
    ["ResendSignUpCode"],
    async (args: ResendSignUpCodeInput) => {
      await resendSignUpCode(args);
    }
  );

  if (!username) {
    throw new Error("Username is required");
  }

  return (
    <Flex direction={"column"} gap={"large"} paddingTop={"small"}>
      <Heading level={5} textAlign={"center"}>
        Confirm Account
      </Heading>
      <Text fontSize={"small"}>
        Your code is on the way. To log in, enter the code we emailed to{" "}
        <b>{destination}</b>. It may take a minute to arrive. Please check your
        spam folder just in case!
      </Text>

      <Flex
        as="form"
        direction={"column"}
        gap={"small"}
        onSubmit={async (e) => {
          e.preventDefault();
          await mutateAsync({ username, confirmationCode });
        }}
      >
        <TextField
          isRequired
          label={<Text fontSize="small">Confirmation Code</Text>}
          placeholder="Enter your confirmation code"
          type="text"
          value={confirmationCode}
          onChange={(e) => {
            setFormData({
              ...formData,
              confirmationCode: e.target.value.trim(),
            });
          }}
        ></TextField>
        <RaisedButton
          type="submit"
          isLoading={isLoading || resendingCode}
          loadingText={
            resendingCode ? "Resending Code..." : "Confirming Account..."
          }
        >
          Confirm Account
        </RaisedButton>
        {isError ? (
          <Text variation="error">{(error as Error).message}</Text>
        ) : null}
        <Text fontSize={"small"} textAlign={"center"}>
          <Link onClick={() => resendCode({ username })}>Resend Code</Link>
        </Text>
      </Flex>
    </Flex>
  );
}
