import {
  Button,
  ButtonGroup,
  Card,
  CheckboxField,
  Divider,
  Flex,
  Text,
  View,
} from "@aws-amplify/ui-react";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import getTimeSince from "../../../../util/timeSinceUpdated";
import ControllerIcon from "../../../../components/icons/ControllerIcon";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteQuiz } from "../../../../graphql/mutations";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import useConfirmationModal from "../../../../hooks/useConfirmationModal";
import useQuizDetails from "../../hooks/useQueryQuizDetails";
import useQuizImage from "../../hooks/useQuizImage";
import useQuizQuestionIds from "../../hooks/useQueryQuizQuestions";
import PlayIcon from "../../../../components/icons/PlayIcon";
import { IQuizCardProps } from "../../types/IQuizCardProps";
import RaisedCard from "../../../../components/RaisedCard";
import { useUserContext } from "../../../../context/UserContextProvider";
import { generateClient } from "aws-amplify/api";
import { ImageComponent } from "../../../../components/ImageComponent";
import { TooltipView } from "../../../../components/TooltipView";
import KebabIcon from "../../../../components/icons/KebabIcon";
import { duplicateQuiz } from "../../../../services/quiz";
import useClickOutsideDetection from "../../../../hooks/useClickOutsideDetection";

//const quizthumbnail = require("../../../assets/default-quiz-img.png");

export default function LibraryQuizCard({
  quizId,
  width,
  hasButtons,
  onClick,
  selectable,
  onSelect,
  onDeselect,
  selected,
  onChange,
}: IQuizCardProps): JSX.Element {
  const navigate = useNavigate();

  const [isDeleting, setIsDeleting] = React.useState(false);

  const { user } = useUserContext();

  const queryClient = useQueryClient();

  const [DeleteQuizModal, setShowDeleteQuizModal] = useConfirmationModal({
    confirmFn: () => delelteQuizMutation.mutate(),
    headerMessage: "Delete Quiz",
  });

  const id = useMemo(() => (quizId ? quizId : ""), [quizId]);

  const { title, updatedAt } = useQuizDetails(id);

  const { image } = useQuizImage(id);

  const { questionIds } = useQuizQuestionIds(id);

  const [showActions, setShowActions] = React.useState(false);
  const [duping, setDuping] = React.useState(false);

  const tooltipRef = useClickOutsideDetection<HTMLDivElement>(
    (e) => {
      if (tooltipRef.current?.contains(e.target as Node)) return;
      if (showActions) {
        setShowActions(false);
      }
    },
    [showActions]
  );

  async function tryDeleteQuiz(): Promise<any | undefined> {
    setIsDeleting(true);
    try {
      const client = generateClient();
      // create
      const result = (await client.graphql({
        query: deleteQuiz,
        variables: { input: { id } },
      })) as GraphQLResult;

      if (result.data) {

        return result;
      } else throw new Error();
    } catch (err) {
      setIsDeleting(false);
      throw new Error("Quiz delete Error: " + err);
    }
  }

  const delelteQuizMutation = useMutation({
    mutationFn: async () => await tryDeleteQuiz(),
    onSuccess: async () => {

      await onChange?.();
    },

    onError: (error) => {
      console.error(error);
    },
  });

  return (
    <RaisedCard
      opacity={isDeleting ? "50%" : "100%"}
      width={width}
      padding={"0"}
      position={"relative"}
      id="raised-btn"
      style={{ cursor: "pointer" }}
    >
      <Flex
        direction={"column"}
        grow={1}
        justifyContent={"end"}
        minHeight={"100%"}
        onClick={() => {
          onClick?.(id);
        }}
      >
        <Flex
          flex={1}
          grow={1}
          direction={"column"}
          style={{ position: "relative" }}
        >
          <ImageComponent
            alt={title + " quiz image"}
            image={image}
            autoPickPrompt=""
            objectFit="cover"
            objectPosition="50% 50%"
            backgroundColor="initial"
            height={"200px"}
            opacity="100%"
            borderRadius={"small small 0 0"}
          />
          <View position={"absolute"} top={"5px"} right={"5px"}>
            <TooltipView
              show={showActions}
              placement="left-start"
              tooltipChildren={
                <ButtonGroup ref={tooltipRef} direction={"column"} gap={"0"}>
                  <Button
                    justifyContent={"start"}                                                            
                    variation="menu"
                    size="small"
                    color={"black"}
                    backgroundColor={"transparent"}
                    isLoading={duping}
                    loadingText="Making a copy..."
                    data-attr="make-a-copy"
                    data-ph-capture-attribute-type={"library-quiz"}
                    data-ph-capture-attribute-id={id}
                    data-ph-capture-attribute-title={title}
                    data-ph-capture-attribute-n-questions={questionIds?.length}
                    onClick={async (e) => {
                      e.stopPropagation();
                      setDuping(true);
                      const newQuiz = await duplicateQuiz(id);
                      window.open(`/quiz/${newQuiz.id}`, "_blank");
                      queryClient.invalidateQueries(["Library Quiz Ids"]);
                      setDuping(false);
                      setShowActions(false);
                    }}
                  >
                    Make a copy
                  </Button>
                  {hasButtons && (
                    <Button
                      justifyContent={"start"}
                      variation="destructive"
                      size="small"
                      color={"red.80"}
                      backgroundColor={"transparent"}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowDeleteQuizModal(true);
                      }}
                    >
                      Delete
                    </Button>
                  )}
                </ButtonGroup>
              }
            >
              <Button
                border={"none"}
                borderRadius={"5rem"}
                padding={"xxs"}
                backgroundColor={"overlay.70"}
                color={"white"}
                position={"relative"}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowActions(!showActions);
                }}
              >
                <KebabIcon />
              </Button>
            </TooltipView>
          </View>
          <Card
            width="60%"
            borderRadius={"5rem"}
            padding={"xxxs"}
            marginTop={"zero"}
            backgroundColor={"overlay.70"}
            textAlign={"center"}
            fontSize={"smaller"}
            fontWeight={"semibold"}
            color={"white"}
            style={{ position: "absolute", bottom: "5px", right: "5px" }}
          >
            {questionIds ? questionIds.length : "?"} Questions
          </Card>
        </Flex>

        <Flex
          padding={"xxxs"}
          paddingLeft={"small"}
          paddingRight={"small"}
          paddingBottom={"small"}
          direction={"column"}
          gap={"xxxs"}
        >
          <Text fontSize={"medium"} fontWeight={"bold"} isTruncated>
            {title}
          </Text>
          {/* <Text padding={'0'} fontSize={'xs'} fontWeight={'bold'} as='span'>0 plays</Text> */}
          <Text padding={"0"} fontSize={"xs"}>
            Updated {getTimeSince(updatedAt)}
          </Text>
        </Flex>

        {hasButtons && (
          <>
            <Flex
              height={"3rem"}
              padding={"xs"}
              justifyContent={"center"}
              direction="column"
            >
              <Divider
                alignSelf={"center"}
                position={"absolute"}
                bottom={"60px"}
                style={{ borderColor: "var(--amplify-colors-neutral-40)" }}
              />
              <Flex
                position={"absolute"}
                id="top"
                bottom={"9px"}
                alignSelf={"center"}
              >
                <Button
                  id={"raised-btn"}
                  gap={"xxs"}
                  variation="primary"
                  backgroundColor={"purple.60"}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/game/${id}`);
                  }}
                  data-attr="share-or-play"
                  data-ph-capture-attribute-type={"solo-library"}
                  data-ph-capture-attribute-quiz-id={quizId}
                  data-ph-capture-attribute-quiz-title={title}
                  data-ph-capture-attribute-quiz-username={user?.username}
                  data-ph-capture-attribute-quiz-creator={user?.creatorId}
                  data-ph-capture-attribute-number-of-questions={
                    questionIds?.length
                  }
                >
                  <ControllerIcon />
                  Solo
                </Button>

                <Button
                  id={"raised-btn"}
                  gap={"xxs"}
                  variation="primary"
                  backgroundColor={"teal.60"}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/play/${id}`);
                  }}
                  data-attr="share-or-play"
                  data-ph-capture-attribute-type={"host-library"}
                  data-ph-capture-attribute-quiz-id={quizId}
                  data-ph-capture-attribute-quiz-title={title}
                  data-ph-capture-attribute-quiz-username={user?.username}
                  data-ph-capture-attribute-quiz-creator={user?.creatorId}
                  data-ph-capture-attribute-number-of-questions={
                    questionIds?.length
                  }
                >
                  <PlayIcon />
                  Host
                </Button>
              </Flex>
            </Flex>
          </>
        )}
      </Flex>

      {selectable && (
        <CheckboxField
          backgroundColor={"white"}
          name={"index"}
          value={"yes"}
          label={"Selected"}
          labelHidden
          position={"absolute"}
          size={"large"}
          top={"15px"}
          left={"15px"}
          checked={selected}
          onChange={(e) => {
            if (e.target.checked) onSelect?.(id);
            else onDeselect?.(id);
          }}
        />
      )}
      <DeleteQuizModal />
    </RaisedCard>
  );
}
