import { Button } from "@aws-amplify/ui-react";
import React, { ReactNode } from "react";
import { TooltipView } from "./TooltipView";
// import { TooltipView } from "./TooltipView_new";

type AnalyicsOptions = {
  label: string;
  data?: Record<string, string>;
};

interface IRaisedButtonProps extends React.ComponentProps<typeof Button> {
  tooltip?: ReactNode;
  placement?: "top" | "bottom" | "left" | "right";
  analyticsOptions?: AnalyicsOptions;
}

export const RaisedButton = React.forwardRef<
  HTMLButtonElement,
  IRaisedButtonProps
>((props, ref) => {
  const { analyticsOptions } = props;

  // change the key name of each field in the data object to match the key name in the data object
  const analyticsData = Object.entries(analyticsOptions?.data ?? {}).reduce(
    (acc, [key, value]) => {
      acc[`data-ph-capture-attribute-${key}`] = value;
      return acc;
    },
    {} as Record<string, string>
  );

  if (props.tooltip) {
    const { tooltip, placement } = props;
    return (
      <TooltipView
        placement={placement}
        trigger={tooltip ? ["hover", "focus"] : []}
        tooltipChildren={tooltip}
      >
        <Button
          id={"raised-btn"}
          variation="primary"
          style={{
            outline: "none",
            border: "none",
            padding: "0.75em",
            paddingTop: "0.5em",
          }}
          data-attr={analyticsOptions?.label}
          {...analyticsData}
          ref={ref}
          {...props}
        />
      </TooltipView>
    );
  }
  return (
    <Button
      id={"raised-btn"}
      variation="primary"
      style={{
        outline: "none",
        border: "none",
        padding: "0.75em",
        paddingTop: "0.5em",
      }}
      data-attr={analyticsOptions?.label}
      {...analyticsData}
      ref={ref}
      {...props}
    />
  );
});

export { RaisedButton as default };
