import { GraphQLQuery, generateClient } from "aws-amplify/api";
import { UpdateQuestionInput, UpdateQuestionMutation } from "../../../API";

export const updateQuestion_query = /* GraphQL */ `
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
    }
  }
`;
export async function updateQuestion(input: UpdateQuestionInput) {
  const client = generateClient();

  await client.graphql<GraphQLQuery<UpdateQuestionMutation>>({
    query: updateQuestion_query,
    variables: { input },
  });
}
