import RestAPI from "../../../../util/RestAPI";
import { AssignmentResult } from "../../types";

export async function listAssignmentResultsForStudent(
  assignmentId: string,
  studentId: string
) {
  try {
    const resultsList: AssignmentResult[] = (await RestAPI.get(
      "/assignmentResults/" + assignmentId + "/student/" + studentId,
      {}
    ).then((res) => res.json())) as any as AssignmentResult[];

    return resultsList;
  } catch (err) {

    return;
  }
}
