import { Icon } from '@aws-amplify/ui-react'
import React from 'react'

export default function ShareIcon() {
    return (
        <Icon ariaLabel="Share" fill="currentcolor"
      pathData="M6.001 11.143c0.645 -0.715 1.45 -1.137 2.361 -1.275 0.533 -0.081 1.098 0.01 1.644 0.063 0.601 0.058 1.117 0.347 1.617 0.67 0.065 0.042 0.206 0.03 0.28 -0.012 0.617 -0.346 1.226 -0.704 1.838 -1.06 0.756 -0.44 1.512 -0.883 2.268 -1.322 0.229 -0.133 0.462 -0.26 0.673 -0.377 -0.019 -0.911 0.168 -1.743 0.766 -2.437 0.419 -0.487 0.918 -0.87 1.549 -1.056 0.581 -0.172 1.16 -0.15 1.75 -0.033 1.056 0.21 1.79 0.824 2.248 1.773 0.559 1.159 0.391 2.778 -0.611 3.736 -0.569 0.544 -1.205 0.868 -1.991 0.976 -0.649 0.089 -1.231 -0.052 -1.816 -0.283 -0.086 -0.034 -0.216 -0.042 -0.291 0 -0.725 0.405 -1.444 0.822 -2.163 1.238 -0.765 0.442 -1.529 0.887 -2.293 1.33 -0.13 0.075 -0.285 0.126 -0.385 0.229 -0.072 0.075 -0.094 0.218 -0.097 0.333 -0.013 0.401 -0.014 0.802 -0.006 1.203 0.002 0.086 0.034 0.213 0.095 0.249 0.936 0.552 1.879 1.092 2.821 1.634 0.597 0.344 1.192 0.692 1.794 1.026 0.083 0.046 0.231 0.067 0.304 0.023 0.641 -0.378 1.342 -0.436 2.054 -0.372 0.924 0.083 1.659 0.528 2.232 1.255 0.584 0.74 0.748 1.6 0.643 2.498 -0.103 0.882 -0.555 1.595 -1.258 2.153C21.284 23.892 20.426 24.051 19.528 23.943c-1.273 -0.153 -2.425 -1.127 -2.737 -2.496 -0.043 -0.19 -0.103 -0.387 -0.088 -0.576 0.028 -0.332 -0.214 -0.408 -0.422 -0.529 -1.536 -0.893 -3.071 -1.788 -4.611 -2.675 -0.055 -0.032 -0.167 -0.02 -0.225 0.016 -0.589 0.367 -1.224 0.571 -1.917 0.643 -1.463 0.152 -2.667 -0.365 -3.631 -1.425 -0.661 -0.727 -0.998 -1.609 -1.039 -2.615 -0.045 -1.114 0.289 -2.083 0.958 -2.953 0.05 -0.064 0.114 -0.118 0.185 -0.19z">
      
         </Icon>
  )
}