import { v4 } from "uuid";
import { createDatabaseItem } from "../../../services/database/createDatabaseItem";
import { getIdentityId } from "../../game/util";
import { Classroom } from "../types/classroomTypes";

export async function createClassroom(name: string) {
  const teacherId = await getIdentityId();
  // await createClassroom(name);
  const classroom = await createDatabaseItem<Classroom>("/classrooms", {
    title: name,
    teacherId,
    id: v4(),
  });
  return classroom;
}
