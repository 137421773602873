import { Flex } from "@aws-amplify/ui-react";
import * as React from "react";
import GibblyLoader from "./GibblyLoader";

export interface ICenteredLoaderProps {}

export function CenteredLoader(props: ICenteredLoaderProps) {
  const {} = props;

  return (
    <Flex
      width={"100%"}
      height={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <GibblyLoader />
    </Flex>
  );
}
