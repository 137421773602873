import { Grid, ScrollView } from "@aws-amplify/ui-react";
import React from "react";
import SideNavbar from "./SideNavbar";
import TopNavbar from "./TopNavbar";
import useMediaQuery from "../hooks/useMediaQuery";
import { FreeTrialToast } from "../features/premium/components";

export default function DashboardLayout({
  children,
  useAuthentication = true,
}: {
  children?: React.ReactNode;
  useAuthentication?: boolean;
}) {
  const isSmall = useMediaQuery("(max-width: 992px)");

  return (
    <>
      <FreeTrialToast />
      <Grid
        height={"100dvh"}
        minHeight={"100vh"}
        columnGap="0"
        rowGap="0"
        templateColumns={"auto 1fr"}
        templateRows={"auto 1fr"}
        templateAreas={
          isSmall
            ? '"header header" "main main"'
            : '"header header" "sidebar main"'
        }
      >
        <TopNavbar useAuthentication={useAuthentication} area={"header"} />

        <SideNavbar area={"sidebar"} hidden={isSmall} />

        <ScrollView
          backgroundColor={"#f5f5f5"}
          area={"main"}
          position={"relative"}
        >
          {children}
        </ScrollView>
      </Grid>
    </>
  );
}
