// A simple deep clone function for your objects
export function deepClone(value: any): any {
    if (Array.isArray(value)) {
        return value.map(el => deepClone(el));
    } else if (typeof value === 'object' && value !== null) {
        const clone: { [key: string]: any } = {};
        for (const key in value) {
            clone[key] = deepClone(value[key]);
        }
        return clone;
    }
    return value;
}


// // Use the deepClone function when pushing onto the stack
// stack.push(deepClone(yourArray));
