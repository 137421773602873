import React, { useCallback, useMemo } from "react";
import {
  Flex,
  Loader,
  Divider,
  ScrollView,
  Tabs,
  Text,
  Badge,
} from "@aws-amplify/ui-react";
import useModalBackButton from "../../../hooks/useModalBackButton";
import useAsync from "../../../hooks/useAsync";

import { QuestionsList } from "../../quiz";
import fetchQuizQuestionsFromQuiz from "../../quiz/utils/fetchQuizQuestionsFromQuiz";
import { QuizQuestion } from "../../../API";
import RaisedButton from "../../../components/RaisedButton";
import { createAssignment } from "../util/assignment";
import useUpdateEffect from "../../../hooks/useUpdateEffect";
import DateTimePicker from "./DateTimePicker";

import SearchableQuizCollection from "../../quiz/components/Discovery/SearchableQuizCollection";
import DiscoveryQuizCard from "../../quiz/components/Discovery/DiscoveryQuizCard";
import fetchPaginatedListUsersQuizzes from "../../quiz/utils/fetchPaginatedListUsersQuizzes";
import fetchPaginatedSearchUsersQuizzes from "../../quiz/utils/fetchPaginatedSearchUsersQuizzes";
import fetchPaginatedListPublicQuizzes from "../../quiz/utils/fetchPaginatedListPublicQuizzes";
import fetchPaginatedSearchPublicQuizzes from "../../quiz/utils/fetchPaginatedSearchPublicQuizzes";
import TranslationContextProvider from "../../../context/TranslationContextProvider";
import useQuizDetails from "../../quiz/hooks/useQueryQuizDetails";

export default function AddGameMenu({
  classId,
  onComplete,
}: {
  classId?: string;
  onComplete: () => void;
}) {
  const [dueDate, setDueDate] = React.useState<number>(0);

  const [currentQuizId, setCurrentQuizId] = React.useState<string | undefined>(
    undefined
  );

  const [userQuizIds, setUserQuizIds] = React.useState<string[]>([]);
  const [publicQuizIds, setPublicQuizIds] = React.useState<string[]>([]);

  const [menuState, setMenuState] = React.useState<"QUIZZES" | "QUESTIONS">(
    "QUIZZES"
  );

  const [assigning, setAssigning] = React.useState(false);

  const allQuizIds = useMemo(() => {
    return [...userQuizIds, ...publicQuizIds];
  }, [userQuizIds, publicQuizIds]);

  useModalBackButton(() => {
    switch (menuState) {
      case "QUESTIONS": // set state to QUIZZES
        setMenuState("QUIZZES");
        break;
      case "QUIZZES": // close modal
        onComplete();
        break;
    }
  }, [menuState]);

  useUpdateEffect(() => {}, [userQuizIds]);

  const { lang } = useQuizDetails(currentQuizId);

  const quizQuestionsAsync = useAsync<QuizQuestion[]>(async () => {
    // fetch quizQuestions from quizId
    return fetchQuizQuestionsFromQuiz(currentQuizId);
  }, [currentQuizId]);

  const assignQuizzes = useCallback(async () => {
    if (classId === undefined) return;
    setAssigning(true);

    await Promise.all(
      allQuizIds.map(async (quizId) => {
        await createAssignment(classId, quizId, dueDate, Date.now());
      })
    );
    setAssigning(false);
    onComplete();
  }, [classId, allQuizIds, dueDate]);

  switch (menuState) {
    case "QUIZZES":
      return (
        <Flex direction={"column"} gap={"xxs"}>
          <Tabs.Container defaultValue="library">
            <Tabs.List>
              <Tabs.Item value="library">
                <Flex alignItems={"center"}>
                  {userQuizIds.length !== 0 && (
                    <Badge variation="info">{userQuizIds.length}</Badge>
                  )}
                  <Text>Library</Text>
                </Flex>
              </Tabs.Item>
              <Tabs.Item value="discover" disabled={classId === undefined}>
                <Flex alignItems={"center"}>
                  {publicQuizIds.length !== 0 && (
                    <Badge variation="info">{publicQuizIds.length}</Badge>
                  )}{" "}
                  <Text>Discover</Text>
                </Flex>
              </Tabs.Item>
            </Tabs.List>
            <Tabs.Panel value="library">
              <ScrollView height={"55dvh"} minHeight={"55vh"}>
                <SearchableQuizCollection
                  collectionType="library-classroom"
                  queryKey="Library Quiz Ids"
                  CardComponent={DiscoveryQuizCard}
                  fetchPaginatedList={async (pageParam) =>
                    fetchPaginatedListUsersQuizzes(pageParam)
                  }
                  fetchPaginatedSearch={async (search, pageParam) =>
                    fetchPaginatedSearchUsersQuizzes(search, pageParam)
                  }
                  cardFunctionalProps={{
                    onClick: async (quizId) => {
                      if (classId === undefined) return;
                      setCurrentQuizId(quizId);
                      setMenuState("QUESTIONS");
                    },
                    width: {},
                    hasButtons: false,
                    onSelect: (quizId) => {
                      // add quizId to selectedQuizIds if not already there
                      if (!userQuizIds.includes(quizId)) {
                        setUserQuizIds([...userQuizIds, quizId]);
                      }
                    },
                    onDeselect: (quizId) => {
                      // remove quizId from selectedQuizIds
                      setUserQuizIds(
                        userQuizIds.filter(
                          (userQuizId) => userQuizId !== quizId
                        )
                      );
                    },
                    selectable: true,
                    selectedIds: userQuizIds,
                  }}
                  templateColumns={{
                    base: "100%",
                    small: "minmax(0,1fr) minmax(0,1fr) ",
                    large: "minmax(0,1fr) minmax(0,1fr) minmax(0,1fr)",
                  }}
                />
              </ScrollView>
            </Tabs.Panel>
            <Tabs.Panel value="discover">
              <ScrollView height={"55dvh"} minHeight={"55vh"}>
                <SearchableQuizCollection
                  collectionType="discovery-classroom"
                  queryKey="Discovery Quiz Ids"
                  CardComponent={DiscoveryQuizCard}
                  fetchPaginatedList={async (pageParam) =>
                    fetchPaginatedListPublicQuizzes(pageParam)
                  }
                  fetchPaginatedSearch={async (search, pageParam) =>
                    fetchPaginatedSearchPublicQuizzes(search, pageParam)
                  }
                  cardFunctionalProps={{
                    onClick: async (quizId) => {
                      if (classId === undefined) return;
                      setCurrentQuizId(quizId);
                      setMenuState("QUESTIONS");
                    },
                    width: {},
                    hasButtons: false,
                    onSelect: (quizId) => {
                      // add quizId to selectedQuizIds if not already there
                      if (!publicQuizIds.includes(quizId)) {
                        setPublicQuizIds([...publicQuizIds, quizId]);
                      }
                    },
                    onDeselect: (quizId) => {
                      // remove quizId from selectedQuizIds
                      setPublicQuizIds(
                        publicQuizIds.filter(
                          (publicQuizIds) => publicQuizIds !== quizId
                        )
                      );
                    },
                    selectable: true,
                    selectedIds: publicQuizIds,
                  }}
                  templateColumns={{
                    base: "100%",
                    small: "minmax(0,1fr) minmax(0,1fr) ",
                    large: "minmax(0,1fr) minmax(0,1fr) minmax(0,1fr)",
                  }}
                />
              </ScrollView>
            </Tabs.Panel>
          </Tabs.Container>
          <Divider />
          <Flex
            gap={"large"}
            justifyContent={"space-between"}
            padding={"xs"}
            direction={{ base: "column", medium: "row" }}
          >
            <RaisedButton
              flex={1}
              borderRadius={"medium"}
              color="white"
              fontSize={"small"}
              backgroundColor={"#1a90ff"}
              isDisabled={allQuizIds.length === 0}
              isLoading={assigning}
              onClick={async () => {
                await assignQuizzes();
              }}
            >
              Assign {allQuizIds.length}{" "}
              {allQuizIds.length !== 1 ? "Games" : "Game"}
            </RaisedButton>
            <DateTimePicker
              flex={2}
              dateName={"Due Date:"}
              value={new Date(dueDate)}
              onChange={(date) => setDueDate(date.getTime())}
            />
          </Flex>
        </Flex>
      );
    case "QUESTIONS":
      if (quizQuestionsAsync.loading) return <Loader />;
      return (
        <TranslationContextProvider lang={lang}>
          <QuestionsList
            quizQuestions={quizQuestionsAsync.value}
            isCreator={false}
          />
        </TranslationContextProvider>
      );
  }
}
