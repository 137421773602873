import * as React from "react";
import { Team } from "../../Types/TeamTypes";
import RaisedButton from "../../../../components/RaisedButton";
import { Flex, Heading, Loader, Text } from "@aws-amplify/ui-react";
import { useTranslationContext } from "../../../../context/TranslationContextProvider";

export interface ITeamSelectProps {
  teams: Team[];
  currentGroupId: string;
  onSelectTeam: (team: Team) => void | Promise<void>;
}

export function TeamSelect(props: ITeamSelectProps) {
  const { teams, onSelectTeam, currentGroupId } = props;

  const [isLoading, setIsLoading] = React.useState(false);

  const { translations } = useTranslationContext();
  if (!teams) return <Loader />;

  if (teams.length === 0) return <Text>No teams yet</Text>;

  return (
    <>
      {!isLoading ? (
        <Flex direction={"column"} gap={"xs"}>
          {teams.map((team, i) => (
            <RaisedButton
              key={team.id}
              variation="primary"
              textAlign={"center"}
              isLoading={isLoading}
              isDisabled={team.id === currentGroupId}
              onClick={async () => {
                setIsLoading(true);
                await onSelectTeam(team);
                setIsLoading(false);
              }}
            >
              {translations.team} {i + 1}
            </RaisedButton>
          ))}
        </Flex>
      ) : (
        <Flex direction={"column"} alignItems={"center"}>
          <Heading>Switching Teams</Heading>
          <Loader />
        </Flex>
      )}
    </>
  );
}
