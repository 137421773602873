export type Referral = {
  referrerId: string;
  referredId: string;
  status: ReferralStatus;
  createdAt: string;
  updatedAt: string;
}

export enum ReferralStatus {
  NONE = "NONE",
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  CONSUMED = "CONSUMED",
}

export interface PostReferralInput {
  referrerId: string;
  referredId: string;
  status: ReferralStatus;
}

export interface GetReferralInput {
  referrerId: string;
  referredId: string;
}

export interface PutReferralInput {
  Key: {
    referrerId: string;
    referredId: string;
  };
  ReturnValues: "NONE" | "ALL_OLD" | "UPDATED_OLD" | "ALL_NEW" | "UPDATED_NEW";
  UpdateExpression: string;
  ExpressionAttributeValues: Object;
}
export interface DeleteReferralInput {
  referrerId: string;
  referredId: string;
}

export interface UpdateReferralObject {
  status?: string;
  [key: string]: string | undefined; // Index signature
}
