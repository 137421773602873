import { Button, Card, Flex, Grid, Text } from "@aws-amplify/ui-react";
import * as React from "react";
import DeleteIcon from "../../../../components/icons/DeleteIcon";
import { Team } from "../../Types/TeamTypes";
import { Player } from "../../Types/GameTypes_dep";
import useLobbyContext from "../../hooks/useLobbyContext";
import PlayerAvatarControlled from "./PlayerAvatarControlled";
import { useTranslationContext } from "../../../../context/TranslationContextProvider";

export interface ITeamCardProps {
  players: Player[];
  team: Team;
  index: number;
  borderColor?: string;
}

export function TeamCardLobby(props: ITeamCardProps) {
  const { team, index, players, borderColor } = props;

  const { removeTeam, teams } = useLobbyContext();

  const [isDeleting, setIsDeleting] = React.useState(false);

  const {translations} = useTranslationContext()


  return (
    <Card
      opacity={isDeleting ? 0.5 : 1}
      backgroundColor={"transparent"}
      width={"100%"}
      minHeight="90px"
      borderRadius={"xl"}
      border={`8px double ${borderColor ?? "rgba(255, 255, 255, 0.2)"}`}
    >
      <Grid
        width={"100%"}
        height={"100%"}
        templateColumns={{ base: "auto", medium: "auto 1fr auto" }}
        gap={"small"}
        rowGap={"medium"}
      >
        <Flex alignItems={"center"}>
          <Text
            fontSize={{ base: "smaller", medium: "large" }}
            id="raised-item"
            backgroundColor={"white"}
            color={"black"}
            borderRadius={"large"}
            fontWeight={"bold"}
          >
            {translations.team} {index + 1}
          </Text>
        </Flex>
        <Flex wrap={"wrap"} alignItems={"center"}>
          {players.map((player) => (
            <PlayerAvatarControlled
              key={player.userId}
              player={player}
              size={{
                small: "50px",
                medium: "55px",
                large: "60px",
                xl: "70px",
              }}
            />
          ))}
        </Flex>
        <Flex justifyContent={"end"} alignItems={"center"}>
          <Button
            variation={"destructive"}
            id="raised-btn"
            fontSize={"medium"}
            isDisabled={teams.length === 1}
            isLoading={isDeleting}
            onClick={async () => {
              setIsDeleting(true);
              removeTeam(team.id);
              setIsDeleting(false);
            }}
          >
            <DeleteIcon />
          </Button>
        </Flex>
      </Grid>
    </Card>
  );
}
