import { DynamoDBItem } from "../../types/DynamoDBTypes";
import RestAPI from "../../util/RestAPI";

export async function queryDatabaseItems<T extends DynamoDBItem>(
  endpoint: string,
  partitionKeyValue?: string | number,
  params?: any
): Promise<T[]> {
  const path = `${endpoint}${
    partitionKeyValue !== undefined ? "/" + partitionKeyValue : ""
  }`;



  try {
    const itemList: T[] = (await RestAPI.get(path, params).then(
      async (res) => await res.json()
    )) as any as T[];
    return itemList;
  } catch (err) {

    throw err;
  }
}

// Usage:
// const students = await listDatabaseItems<Student>("/students", classId);
// const classrooms = await listDatabaseItems<Classroom>("/classrooms", teacherId);
