import React from 'react'
import { QuestionType } from '../../../API'
import OptionRadio from './OptionRadio'
import OptionCheckbox from './OptionCheckbox'
import OptionNumbered from './OptionNumbered'

export default function OptionCardLabel({ questionType, index, correctIndices, updateCorrectIndices }: { questionType: QuestionType | null | undefined, index: number, correctIndices: (number | null)[] | null | undefined, updateCorrectIndices: (index: number, value: boolean) => void }) {
  switch (questionType) {

    case QuestionType.SINGLE_SELECT: return <OptionRadio index={index} correctIndices={correctIndices} onChange={e => updateCorrectIndices(index, e.target.checked)} />
    case QuestionType.TRUE_OR_FALSE: return <OptionRadio index={index} correctIndices={correctIndices} onChange={e => updateCorrectIndices(index, e.target.checked)} />
    case QuestionType.MULTIPLE_SELECT: return <OptionCheckbox index={index} correctIndices={correctIndices} onChange={e => updateCorrectIndices(index, e.target.checked)} />
    case QuestionType.TYPED_ANSWER: return <OptionNumbered index={index} />

    default: return <></>
  }

}
