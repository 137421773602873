import React from "react";

import RaisedButton from "../../../../components/RaisedButton";
import { FaStop } from "react-icons/fa";
import useConfirmationModal from "../../../../hooks/useConfirmationModal";
import { useTranslationContext } from "../../../../context/TranslationContextProvider";

type StopGameButtonProps = {
  onConfirm: () => void | Promise<void>;
};

export default function StopGameButton({ onConfirm }: StopGameButtonProps) {
  const { translations } = useTranslationContext();

  const [StopModal, setStopModal] = useConfirmationModal({
    headerMessage: translations.end_game,
    bodyMessage: translations.are_you_sure_you_want_to_stop_game,
    confirmFn: async () => {
      await onConfirm();
    },
  });

  return (
    <>
      <StopModal />

      <RaisedButton
        fontSize={{ base: "medium", medium: "large" }}
        variation={"destructive"}
        gap={"small"}
        onClick={() => setStopModal(true)}
      >
        {translations.end_game} <FaStop />
      </RaisedButton>
    </>
  );
}
