import React, { useState } from "react";
import { generateClient, GraphQLQuery } from "aws-amplify/api";
import useEffectOnce from "../../../../hooks/useEffectOnce";
import { useNavigate, useParams } from "react-router-dom";
import { Text, Card, Flex, Grid, Button } from "@aws-amplify/ui-react";
import FullScreenButton from "../../../../layouts/FullScreenButton";
import ExitButton from "../../../../layouts/ExitButton";
import { createDatabaseItem } from "../../../../services/database/createDatabaseItem";
import { Lobby } from "../../Types/LobbyTypes";
import { getIdentityId } from "../../util";
import { getAvailableLobbyPin } from "../../services/getAvailableLobbyPin";
import { v4 } from "uuid";
import { CreateLobbyMenu } from "./CreateLobbyMenu";
import { Team } from "../../Types/TeamTypes";
import { createPlayerAutoGrouped } from "../../services/createPlayerAutoGrouped";
import { createPlayerUngrouped } from "../../services/createPlayerUngrouped";
import { GameState } from "../../Types/GameTypes";
import { GetQuizQuery, Quiz } from "../../../../API";
import { getQuizForAssignment_query } from "../../../../services/custom-queries";
import GibblyLoader from "../../../../components/GibblyLoader";
import useModal from "../../../../hooks/useModal";
import LobbyTutorial from "./LobbyTutorial";
import { FaQuestion } from "react-icons/fa";
import TranslationContextProvider from "../../../../context/TranslationContextProvider";
import RestAPI from "../../../../util/RestAPI";

export default function CreateLobby() {
  const { quizId } = useParams();
  const navigate = useNavigate();
  const [quizIsValid, setQuizIsValid] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [quiz, setQuiz] = useState<Quiz | null>(null);

  useEffectOnce(() => {
    fetchQuiz();
  });

  const client = generateClient();

  async function fetchQuiz(): Promise<void | undefined> {
    try {
      const promise = await RestAPI.post("/quiz", {
        body: { quizId },
      }).then((response) => response.json() as any);

      setIsLoading(false);
      if (!promise.data.getQuiz) {
        setQuizIsValid(false);
      } else {
        setQuiz(promise.data.getQuiz as Quiz);
      }
    } catch (err) {
      console.error(err);
    }
  }

  const [HowToModal, setHowToModal] = useModal(
    {
      title: "How To Play",
      size: "lg",
      ReactComponent: () => <LobbyTutorial />,
    },
    []
  );

  return (
    <>
      <TranslationContextProvider lang={quiz?.lang || "en"}>
        <HowToModal />
        <Grid templateRows={"auto 1fr"} height={"100%"}>
          <Flex justifyContent={"space-between"} padding={"small"}>
            <Flex justifyContent={"start"}>
              <ExitButton
                onExit={() => {
                  navigate(`/dashboard/library`);
                }}
              />
            </Flex>

            <Flex justifyContent={"end"}>
              <Button
                variation={"link"}
                color="white"
                size="large"
                padding={"0"}
                onClick={() => setHowToModal(true)}
              >
                <FaQuestion />
              </Button>
              <FullScreenButton />
            </Flex>
          </Flex>
          <Flex justifyContent={"center"} alignItems={"center"}>
            {isLoading ? (
              <GibblyLoader />
            ) : (
              <>
                {quizIsValid && quizId ? (
                  <CreateLobbyMenu
                    onSubmitLobbyOptions={async (
                      options,
                      numberOfTeams,
                      nickname,
                      avatar
                    ) => {
                      try {
                        const hostId = await getIdentityId();

                        const pin = await getAvailableLobbyPin();
                        const id = `lb-${v4()}`;

                        const result = await client.graphql<
                          GraphQLQuery<GetQuizQuery>
                        >({
                          query: getQuizForAssignment_query,
                          variables: { quizId },
                        });
                        const quiz = result.data?.getQuiz as Quiz;

                        await createDatabaseItem<Lobby>("/lobbies", {
                          pin,
                          id,
                          quiz,
                          hostId,
                          lobbyState: "LOBBY",
                          ...options,
                        });

                        for (let i = 0; i < numberOfTeams; i++) {
                          await createDatabaseItem<Team>(`/teams`, {
                            lobbyId: id,
                            id: `tm-${v4()}`,
                            teamState: "LOBBY",
                            gameData: {
                              gameState: GameState.WAITING,
                              questionIndex: 0,
                              currentAnswer: [],
                              answerResults: [],
                            },
                          });
                        }

                        if (nickname !== undefined && avatar !== undefined) {
                          if (options.autoTeams && !options.hostSpectating)
                            await createPlayerAutoGrouped(
                              id,
                              hostId,
                              nickname,
                              avatar
                            );
                          else
                            await createPlayerUngrouped(
                              id,
                              hostId,
                              nickname,
                              avatar
                            );
                        }
                        navigate(`/play/lobby/${pin}`);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  />
                ) : (
                  <>
                    <Card>
                      <Text>This quiz does not exist</Text>
                    </Card>
                  </>
                )}
              </>
            )}
          </Flex>
        </Grid>
      </TranslationContextProvider>
    </>
  );
}
